import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  sendMessageAction,
  reloadOneEventplannerRequestForArtistAction,
} from '../../stores/gig-request-eventplanner/actions'

import GigRequestNewMessage from '../../components/GigRequestNewMessage'
import { postJSON } from '../../libs/data'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  sendMessage: (gigRequestId, artistId, message, date) => {
    dispatch(sendMessageAction(gigRequestId, artistId, message, date))
  },
  completedMessage: (gigRequestId, artistId) => {
    dispatch(reloadOneEventplannerRequestForArtistAction(gigRequestId, artistId))
  },
})

const GigRequestEventplannerNewMessageContainer = React.memo(
  ({ gigRequestId, artistId, completedMessage, sendMessage }) => {
    const [message, setMessage] = React.useState('')
    const [errorMessages, setErrorMessages] = React.useState([])
    const [isSending, setIsSending] = React.useState(false)

    const handleMessageChange = e => {
      setMessage(e.currentTarget.value)
    }

    const handleSubmit = () => {
      if (!message) {
        setErrorMessages(['Du kan ikke sende tom melding'])
      } else {
        sendMessage(gigRequestId, artistId, message, new Date().toString())
        setErrorMessages([])
        setIsSending(true)
        postJSON('/api/v1/gigRequest/sendMessage', {
          gigRequestId,
          artistId,
          message,
        }).then(
          () => {
            setIsSending(false)
            setMessage('')
            completedMessage(gigRequestId, artistId)
          },
          error => {
            setIsSending(false)
            let serverErrorMessage = ['Feil på serveren']
            if (error && error.response && error.response.message) {
              serverErrorMessage = [error.response.message]
            }
            setErrorMessages(serverErrorMessage)
          },
        )
      }
    }

    return (
      <GigRequestNewMessage
        placeholder="Send melding om du har spørsmål til artisten"
        errorMessages={errorMessages}
        message={message}
        onChange={handleMessageChange}
        onSubmit={handleSubmit}
        isSending={isSending}
      />
    )
  },
)

GigRequestEventplannerNewMessageContainer.propTypes = {
  // normal props
  gigRequestId: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,

  // state props

  // dispatch props
  sendMessage: PropTypes.func.isRequired,
  completedMessage: PropTypes.func.isRequired,
}

const GigRequestNewMessageContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GigRequestEventplannerNewMessageContainer)

export default GigRequestNewMessageContainerWrapper
