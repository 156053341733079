import styled, { css } from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'

const HEIGHT = '20px'
const THIN_HEIGHT = '10px'
export const Container = styled.div`
  height: ${HEIGHT};

  ${({ isThin }) =>
    isThin &&
    css`
      height: ${THIN_HEIGHT};
    `};
`

export const Placeholder = styled.div`
  display: block;
  font-size: 0;
  height: 0;
  overflow: hidden;

  ${({ isSticky }) =>
    isSticky &&
    css`
      height: ${HEIGHT};
    `};
`

export const Bar = styled.div`
  background: ${gigplanetTheme.component.progressbar.default.background};
  border-radius: 4px;
  height: ${HEIGHT};
  overflow: hidden;
  position: relative;
  transition: top left right width 0.4s ease;
  user-select: none;
  width: 100%;
  z-index: 98;

  ${({ isSticky }) =>
    isSticky &&
    css`
      border-radius: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: ${gigplanetTheme.zIndex.progressbar};
    `};

  ${({ isThin }) =>
    isThin &&
    css`
      border-radius: 5px;
      height: ${THIN_HEIGHT};
    `};
`

export const Progress = styled.div`
  background: ${gigplanetTheme.component.progressbar.success.bar};
  border-radius: 4px;
  border-width: 1px;
  height: 100%;
  max-width: 100%;
  transition: width 0.4s ease;
  width: 0%;
  ${({ isSticky }) =>
    isSticky &&
    css`
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `};

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      background: ${gigplanetTheme.component.progressbar.success.bar};
      border-radius: 0;
    `};

  ${({ isThin }) =>
    isThin &&
    css`
      border-radius: 5px;
    `};
`

export const Label = styled.div`
  bottom: 0;
  color: ${gigplanetTheme.component.progressbar.default.text};
  font-size: 14px;
  left: 0;
  line-height: ${HEIGHT};
  min-width: 50px;
  padding: 0 5px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  transition: width 0.4s ease;

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      color: ${gigplanetTheme.component.progressbar.success.text};
      font-weight: bold;
      text-align: center;
    `};
`
