import * as React from 'react'
import PropTypes from 'prop-types'
import { Actions } from './style'

const UserAuthActions = ({ children }) => <Actions>{children}</Actions>

UserAuthActions.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}
UserAuthActions.defaultProps = {}

export default UserAuthActions
