import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import PublicGenresList from './components/PublicGenresList'
import { getGenres } from '../../stores/Options/OptionsReducer'

const mapStateToProps = state => ({
  genresList: getGenres(state),
})

const mapDispatchToProps = () => ({})

const PulicGenresListContainer = React.memo(
  ({ genres, genresList, leftAlign, large, mobileCenter, pipes, height }) => {
    if (!genres) {
      return null
    }
    if (!genresList) {
      return null
    }

    return (
      <PublicGenresList
        leftAlign={leftAlign}
        large={large}
        mobileCenter={mobileCenter}
        pipes={pipes}
        height={height}
        genres={genres}
        genresList={genresList}
      />
    )
  },
)

PulicGenresListContainer.propTypes = {
  leftAlign: PropTypes.bool,
  mobileCenter: PropTypes.bool,
  large: PropTypes.bool,
  pipes: PropTypes.bool,
  height: PropTypes.number,
  genres: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  genresList: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

PulicGenresListContainer.defaultProps = {
  leftAlign: false,
  mobileCenter: false,
  large: false,
  pipes: false,
  genres: [],
  genresList: {},
  height: 0,
}

const PulicGenresListContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PulicGenresListContainer)

export default PulicGenresListContainerWrapper
