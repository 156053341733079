import * as React from 'react'
import PropTypes from 'prop-types'

import Button from '../../../ui/Button'

const ModalWindowButton = ({ theme, id, isOpen, onClick, children, renderButton, ...rest }) =>
  renderButton ? (
    renderButton({
      'aria-haspopup': true,
      ...(isOpen ? { 'aria-controls': id } : {}),
      'aria-expanded': isOpen,
      onClick,
      ...rest,
    })
  ) : (
    <Button
      {...rest}
      type="button"
      theme={theme}
      aria-haspopup
      aria-controls={id}
      aria-expanded={isOpen}
      onClick={e => {
        e.preventDefault()
        onClick()
      }}
    >
      {children}
    </Button>
  )

ModalWindowButton.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  theme: PropTypes.string,
  children: PropTypes.node,
  renderButton: PropTypes.func,
}

ModalWindowButton.defaultProps = {
  theme: '',
  children: null,
  renderButton: null,
}

export default ModalWindowButton
