import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Label from './Label'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
`

const Element = styled.input`
  appearance: none;
  background-color: ${gigplanetTheme.component.form.radio.background};
  border: 1px solid ${gigplanetTheme.component.form.radio.border};
  border-radius: 100%;
  cursor: pointer;
  height: 18px;
  margin-right: 5px;
  margin-top: 4px;
  position: relative;
  transition: border-color 0.2s;
  vertical-align: top;
  width: 18px;
  min-width: 18px;

  &:checked {
    &:before {
      position: absolute;
      content: ' ';
      border-radius: 100%;
      left: 4px;
      top: 4px;
      width: 8px;
      height: 8px;
      background: ${gigplanetTheme.component.form.radio.color};
      font-size: 1px;
    }
  }
`

const HelpTextElement = styled.span`
  color: #82888a;
  display: block;
  font-size: 12px;
`

const LabelContainer = styled.div``

const Radio = ({ label, name, id, value, onChange, checked, disabled, smallText, helpText }) => (
  <Container>
    <Element
      type="radio"
      id={id}
      name={name}
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      className="form__radio__element"
    />
    <LabelContainer>
      {label && (
        <Label htmlFor={id} helpText={helpText} radio>
          {label}
        </Label>
      )}
      {helpText && <HelpTextElement>{helpText}</HelpTextElement>}
    </LabelContainer>
  </Container>
)

Radio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  smallText: PropTypes.bool,
  helpText: PropTypes.string,
}

Radio.defaultProps = {
  label: '',
  name: '',
  value: '',
  onChange: null,
  checked: false,
  disabled: false,
  smallText: false,
  helpText: '',
}

export default Radio
