import * as React from 'react'
import PropTypes from 'prop-types'

import { ArtistShape } from '../../../../../models/Artist'
import { includedOptionInGigRequestForm } from '../../../../../models/GigRequest'
import { FormWrapper, Included } from './style'

import GigRequestFormWrapper from '../../GigRequestFormWrapper'
import GigRequestFormProgress from '../../GigRequestFormProgress'
import GigRequestFormActionsWrapper from '../../GigRequestFormActionsWrapper'
import GigRequestFormHeader from '../../GigRequestFormHeader'
import GigRequestFormSubHeading from '../../GigRequestFormSubHeading'
import GigRequestFormError from '../../GigRequestFormError'
import Row from '../../../../FormRow'
import Column from '../../../../FormColumn'
import Button from '../../../../../ui/Button'
import { Input, Radio, Textarea, Label } from '../../../../../ui/Form'

const GigRequestFormEventDetails = React.memo(
  ({
    artist,
    included = [],
    onIncludedChange,
    venue = '',
    onVenueChange,
    city = '',
    onCityChange,
    notes = '',
    onNotesChange,
    onNext,
    onPrevious,
    errorMessages = null,
  }) => {
    return (
      <GigRequestFormWrapper>
        <GigRequestFormProgress step={2} stepCount={3} />
        <GigRequestFormHeader
          heading={`Kontakt ${artist ? artist.name : 'artisten'}`}
          intro={`Fyll ut ekstra informasjon om arrangementet ditt. Dette hjelper ${
            artist ? artist.name : 'artisten'
          } å svare deg bedre`}
          artist={artist}
        />
        <GigRequestFormSubHeading heading="Fortell mer om ditt arrangement" />
        <FormWrapper>
          <Row>
            <Column>
              <Label htmlFor="included-sound-yes">Artisten må stille med lydanlegg/PA</Label>
              <Included>
                <Radio
                  label="Ja"
                  name={includedOptionInGigRequestForm.SOUNDSYSTEM}
                  id="included-sound-yes"
                  checked={included.indexOf(includedOptionInGigRequestForm.SOUNDSYSTEM) > -1}
                  value="included"
                  onChange={onIncludedChange}
                />
                <Radio
                  label="Nei"
                  name={includedOptionInGigRequestForm.SOUNDSYSTEM}
                  id="included-sound-no"
                  checked={included.indexOf(includedOptionInGigRequestForm.SOUNDSYSTEM) === -1}
                  value="not-included"
                  onChange={onIncludedChange}
                />
              </Included>
            </Column>
          </Row>
          <Row>
            <Column>
              <Label htmlFor="included-scenelight-yes">Artisten må stille med scenelys</Label>
              <Included>
                <Radio
                  label="Ja"
                  name={includedOptionInGigRequestForm.SCENELIGHTS}
                  id="included-scenelight-yes"
                  checked={included.indexOf(includedOptionInGigRequestForm.SCENELIGHTS) > -1}
                  value="included"
                  onChange={onIncludedChange}
                />
                <Radio
                  label="Nei"
                  name={includedOptionInGigRequestForm.SCENELIGHTS}
                  id="included-scenelight-no"
                  checked={included.indexOf(includedOptionInGigRequestForm.SCENELIGHTS) === -1}
                  value="not-included"
                  onChange={onIncludedChange}
                />
              </Included>
            </Column>
          </Row>
          <Row>
            <Column>
              <Input
                id="venue"
                name="venue"
                label="Adresse eller navn på lokalet"
                placeholder="Hus, hotell, restaurant..."
                type="text"
                value={venue}
                onChange={onVenueChange}
                errorMessage={errorMessages && errorMessages.venue}
                expanded
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Input
                id="event-city"
                name="event-city"
                label="Kommune, by eller sted"
                placeholder="Så beskrivende som mulig"
                type="text"
                value={city}
                onChange={onCityChange}
                errorMessage={errorMessages && errorMessages['event-city']}
                expanded
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Textarea
                label="Ytterligere informasjon om arrangementet"
                id="notes"
                name="notes"
                value={notes}
                onChange={onNotesChange}
                placeholder="Oppgi informasjon du tror er relevant"
                errorMessage={errorMessages && errorMessages.notes}
                expanded
              />
            </Column>
          </Row>
        </FormWrapper>
        <GigRequestFormError errorMessages={errorMessages} />
        <GigRequestFormActionsWrapper>
          <Button type="button" theme="primary" onClick={onNext}>
            Neste: Arrangørinfo
          </Button>
          <Button type="button" theme="transparent" onClick={onPrevious}>
            Tilbake
          </Button>
        </GigRequestFormActionsWrapper>
      </GigRequestFormWrapper>
    )
  },
)

GigRequestFormEventDetails.propTypes = {
  artist: ArtistShape,
  included: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  onIncludedChange: PropTypes.func.isRequired,
  venue: PropTypes.string,
  onVenueChange: PropTypes.func.isRequired,
  city: PropTypes.string,
  onCityChange: PropTypes.func.isRequired,
  notes: PropTypes.string,
  onNotesChange: PropTypes.func.isRequired,
  errorMessages: PropTypes.objectOf(PropTypes.string),
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
}

export default GigRequestFormEventDetails
