import styled, { css } from 'styled-components'
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const ProfileDetailsMenu = styled.div``
export const ProfileDetailsMenuPlaceholder = styled.div`
  height: 0;
  overflow: hidden;

  ${mediaQueries.tabletPortraitUp`
  ${({ isSticky }) =>
    isSticky &&
    css`
      height: 80px;
    `};
  `}
`
export const ProfileDetailsMenuContent = styled.div`
  background: ${gigplanetTheme.color.white};
  transition: background 0.2s;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  ${mediaQueries.tabletPortrait``}

  ${({ isSticky }) =>
    isSticky &&
    css`
      background: ${gigplanetTheme.color.white};
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 200;
      border-top: 0;
      border-bottom: 1px solid #dce0e0;
    `};

  ${mediaQueries.mobile`
    justify-content: space-between;
    
    background: ${gigplanetTheme.color.white};
    left: 0;
    position: fixed;
    right: 0;
    top: auto;
    bottom: 0px;
    z-index: 200;
    border-bottom: 0;
    border-top: 1px solid #dce0e0;
  `};
`

export const ProfileDetailsMenuContainer = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 80px;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  transition: height 0.2s;
  margin-right: 10px;

  ${mediaQueries.mobile`
    height: 70px;
    margin-right: 0;
  `};
`

export const ProfileDetailsMenuItemLogo = styled.li`
  width: 0px;
  margin-right: 0px;
  margin-left: 0px;
  transition: all 0.2s;
  display: inline-block;
  ${({ isSticky }) =>
    isSticky &&
    css &&
    `
    width: 40px;
    margin-right: 10px;
    margin-left: 10px;
  `};
`

export const ProfileDetailsMenuItemLogoLink = styled.a`
  cursor: pointer;
  width: 0;
  height: 0;
  opacity: 0;
  transition: all 0.2s;
  display: block;
  ${({ isSticky }) =>
    isSticky &&
    css &&
    `
    width: 40px;
    height: 40px;
    opacity: 1;
  `};
  svg {
    width: 0;
    height: 0;
    opacity: 0;
    transition: all 0.2s;
    ${({ isSticky }) =>
      isSticky &&
      css &&
      `
        width: 40px;
        height: 40px;
        opacity: 1;
    `};
  }
`

export const ProfileDetailsMenuItemLogoImage = styled.img`
  width: 0;
  height: 0;
  opacity: 0;
  transition: all 0.2s;
  ${({ isSticky }) =>
    isSticky &&
    css &&
    `
    width: 50px;
    height: 50px;
    opacity: 1;
  `};
`
export const ProfileDetailsMenuLinkText = styled.span`
  display: inline-block;
  /* ptransition: color font-size 0.2s; */
  text-decoration: none;
  display: block;
  position: relative;
`

export const ProfileDetailsMenuLinkCount = styled.span`
  display: inline-block;
  margin-left: 5px;

  ${mediaQueries.mobile`
    display: none;
  `};
`

export const ProfileDetailsMenuItem = styled.li`
  display: inline-block;
  ${mediaQueries.mobile`
    display: none;
  `};
  &:last-child {
    .ProfileDetailsMenu__menu__link__wrapper:after {
      content: '';
    }
  }
  ${({ isActive }) =>
    isActive &&
    css &&
    `
      a {
        color: #8805BB;
      }
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css &&
    `
      color: #bbb;
      cursor: default;
    `};
`

export const ProfileDetailsMenuItemText = styled.span`
  transition: color font-size 0.2s;
  text-decoration: none;
  display: block;
  position: relative;
`
export const ProfileDetailsMenuItemLink = styled.a`
  color: #484848;
  transition: color font-size 0.2s;
  text-decoration: none;
  display: block;
  position: relative;
`

export const ProfileDetailsMenuLinkWrapper = styled.span`
  display: flex;
  line-height: 80px;
  font-size: 16px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  ${({ isActive }) =>
    isActive &&
    css &&
    `
    &:after {
        content: '';
        position: absolute;
        background: #8805BB;
        left: 0;
        right: 0;
        bottom: -1px;
        height: 4px;
    }
  `};

  ${mediaQueries.mobile`
    display: flex;
    font-size: 14px;
    line-height: 70px;
    padding: 0 10px;
    &:after {
      content: ' · ';
      position: absolute;
      right: 0;
    }
  `};

  ${mediaQueries.tabletPortrait`
    font-size: 16px;
    
    &:after {
      content: ' · ';
      position: absolute;
      right: 0;
    }
  `};

  ${mediaQueries.tabletLandscape`
    font-size: 16px;
  `};
`
