import styled from 'styled-components' // { css }
import mediaQueries from '../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../styles/gigplanetTheme'

export const ReviewContainer = styled.section`
  background: #fff;
  border-bottom: 1px solid #dce0e0;

  padding: ${gigplanetTheme.spacing.medium};

  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.xsmall};
  `};
`
export const Reviews = styled.div``

export const Review = styled.div`
  max-width: 800px;
  margin: 0 auto 50px auto;
  :first-child {
    ${mediaQueries.mobile`
      padding-top: 30px;
    `};
  }
  ${mediaQueries.mobile`
    margin-bottom: 30px;
  `};
  :last-child {
    margin-bottom: 0;
  }
`

export const ReviewTop = styled.div``

export const ReviewHeading = styled.h3`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 25px;
  font-weight: 400; /* 300 */
  margin: 0 0 15px;
  ${mediaQueries.mobile`
    font-size: 20px;
  `};
`
export const ReviewHeadingText = styled.span`
  margin-right: 5px;
`
export const Intro = styled.h4`
  font-size: 18px;
  margin: 0;
  font-weight: bold;
  ${mediaQueries.mobile`
    font-size: 16px;
  `};
`
export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`
export const ReviewBottom = styled.div`
  padding: 2.35765%;
  background: #f4f4f4;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 15px;
  background: #f4f4f4;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 15px;
  ${mediaQueries.mobile`
    flex-direction: column;
  `};
`
export const RatingContainer = styled.div`
  flex: 1 1 auto;
  width: 50%;
  ${mediaQueries.mobile`
    width: 100%;
  `};
`
export const Rating = styled.dl`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin: 5px 0;
  ${mediaQueries.mobile`
    flex-direction: row-reverse;
  `};
  dt {
    width: 80px;
  }
`
export const RatingStarsContainer = styled.dt`
  width: 80px;
  ${mediaQueries.mobile`
    width: auto;
    flex: 1 1 auto;
  `};
`

export const RatingStarDescription = styled.dd`
  flex: 1 1 auto;
  font-weight: bold;
  margin: 0 0 0 10px;
  ${mediaQueries.mobile`
    flex: 0 0 auto;
    font-size: 12px;
    width: 160px;
    margin: 0;
  `};
`
export const DetailsContainer = styled.div`
  flex: 1 1 auto;
  width: 50%;
  ${mediaQueries.mobile`
    width: 100%;
  `};
`
export const ReviewDetail = styled.dl`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 5px 0;
  ${mediaQueries.mobile`
    font-size: 12px;
  `};
`
export const ReviewDetailDescription = styled.dt`
  width: 170px;
  min-width: 170px;
  font-weight: bold;

  ${mediaQueries.mobile`
    width: 160px;
    min-width: 160px;
    margin: 0;
    flex: 0 0 auto;
  `};
`
export const ReviewDetailValue = styled.dd`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ShowMore = styled.div`
  margin-top: 2.35765%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${mediaQueries.mobile`
    justify-content: flex-start;
  `};
`
