import * as React from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'

import Layout from '../../layouts/main-layout'
import MetaData from '../../components/MetaData'
import useCurrentUser from '../../hooks/use-current-user'
import UserAuthWindow from '../../containers/user-auth-window'
import { H1 } from '../../ui/Headings'
import gigplanetTheme from '../../styles/gigplanetTheme'
import WriteReviewForm from '../../components/WriteReviewForm'

export const Container = styled.div`
  margin: 0 auto;
  max-width: 450px;
  min-width: 290px;
  padding: ${gigplanetTheme.spacing.medium} 15px;
  width: 100%;

  ${mediaQueries.mobile`
    padding-top: 0;
  `};
`

const HeadingWrapper = styled.div`
  padding-bottom: ${gigplanetTheme.spacing.medium};

  ${mediaQueries.mobile`
    padding-top: 0;
  `};
`

const WriteReviewRoute = React.memo(() => {
  const params = useParams()
  const history = useHistory()
  const location = useLocation()
  const currentUser = useCurrentUser()

  let successUrl = `/write-review/${params.gigId}`
  const query = queryString.parse(location.search)
  if (query && query.url) {
    successUrl = query.url
  }

  if (!{}.hasOwnProperty.call(currentUser, 'id')) {
    return (
      <>
        <MetaData title="Ny anmeldelse" description="" noindex />
        <Layout>
          <Container>
            <HeadingWrapper>
              <H1>Du må være logget inn for å skrive en anmeldelse</H1>
            </HeadingWrapper>
            <UserAuthWindow
              hideHeading
              showLogin
              continueMode
              onClose={() => {
                history.push('/')
              }}
              successUrl={successUrl}
            />
          </Container>
        </Layout>
      </>
    )
  }

  return (
    <>
      <MetaData title="Ny anmeldelse" description="" noindex />
      <Layout simpleMenu>
        <Container>
          <WriteReviewForm
            gigId={params.gigId}
            onClose={() => {
              history.push('/')
            }}
          />
        </Container>
      </Layout>
    </>
  )
})

WriteReviewRoute.propTypes = {}

export default WriteReviewRoute
