import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const FacebookIcon = props => (
  <SVGWrapper width="512" height="512" viewBox="0 0 512 512" {...props}>
    <path d="M304 96h80v-96h-80c-61.757 0-112 50.243-112 112v48h-64v96h64v256h96v-256h80l16-96h-96v-48c0-8.673 7.327-16 16-16z" />
  </SVGWrapper>
)

export default FacebookIcon
