import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { Wrapper } from './style'

import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import extractUrlPath from '../../../../helpers/extract-url-path'
import Button from '../../../../ui/Button'

const RegisterArtistButtonBlock = React.memo(({ theme, anchorId, buttonText, buttonUrl }) => (
  <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
    <Wrapper>
      <Button theme="primary" to={extractUrlPath(buttonUrl)}>
        {buttonText}
      </Button>
    </Wrapper>
  </LandingPageBlockWrapper>
))

export default RegisterArtistButtonBlock
