import * as React from 'react'
import PropTypes from 'prop-types'

import { H1 } from '../../ui/Headings'
import Button from '../../ui/Button'
import Intro from '../../ui/user-auth-intro'
import IntroWrapper from '../../ui/user-auth-intro-wrapper'
import HeadingWrapper from '../../ui/user-auth-heading-wrapper'
import Actions from '../../ui/user-auth-actions'

const UserAuthVerifyEmailSuccess = ({ successUrl, showContinue = false }) => (
  <>
    <H1>E-postadressen din er verifisert</H1>
    <IntroWrapper>
      <Intro>
        Du kan nå bruke alle funksjoner hos Gigplanet. Klikk på knappen under for å starte.
      </Intro>
    </IntroWrapper>
    <Actions>
      <Button type="button" theme="secondary" to={successUrl}>
        {showContinue ? 'Fortsett' : 'Tilbake til Gigplanet'}
      </Button>
    </Actions>
  </>
)

UserAuthVerifyEmailSuccess.propTypes = {
  successUrl: PropTypes.string.isRequired,
  showContinue: PropTypes.bool,
}

export default React.memo(UserAuthVerifyEmailSuccess)
