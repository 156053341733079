import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const StyledGigRequestEventplannerEdit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

export const Wrapper = styled.div`
  max-width: ${gigplanetTheme.measure.maxWidth};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 50px 30px;

  ${mediaQueries.mobile`
    padding: 30px 15px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `};
`

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  padding-left: 15px;

  ${mediaQueries.mobile`
    padding: 0;
  `};
`

export const FormDescription = styled.p`
  padding: 5px 0 30px;
  margin: 0;
`

export const FormError = styled.div`
  padding-bottom: 30px;
`

export const CancelEventWrapper = styled.div`
  background: ${gigplanetTheme.color.lightGray};
  margin-top: ${gigplanetTheme.spacing.xlarge};
  padding: ${gigplanetTheme.spacing.medium};
  width: 100%;

  ${mediaQueries.mobile`
    margin-top: ${gigplanetTheme.spacing.medium};
    padding: ${gigplanetTheme.spacing.small};
  `};
`

export const CancelText = styled.p`
  margin: 15px 0;
`
