/* globals window */
import * as React from 'react'
import PropTypes from 'prop-types'

import {
  MobileMenu,
  ExtraSpacer,
  SupportUnreadCount,
  ButtonInnerContent,
  FavoriteCount,
} from './style'
import { showIntercom, shutdownIntercom } from './util'

import HeaderMenuList from '../../../ui/header-menu-list'
import HeaderMenuItem from '../../../ui/header-menu-list-item'
import Button from '../../../ui/Button'

import HeaderBurgerButton from '../../../ui/header-burger-button'
import SiteNavigationMobileLoggedOut from '../../../components/SiteNavigationMobile/SiteNavigationMobileLoggedOut'

const LoggedOutMobileMenu = ({ showSearch = false, favoriteCount, hasDarkBg, focusCallback }) => {
  const [showingSiteNav, setShowSiteNav] = React.useState(false)

  React.useEffect(() => {
    focusCallback(showingSiteNav)
  }, [showingSiteNav])

  const openSiteNav = () => {
    setShowSiteNav(true)
  }

  const closeSiteNav = () => {
    setShowSiteNav(false)
  }

  const toggleSiteNav = () => {
    setShowSiteNav(!showingSiteNav)
  }

  return (
    <MobileMenu>
      <HeaderMenuList>
        {showSearch ? (
          <HeaderMenuItem>
            <ExtraSpacer>
              <Button
                type="button"
                theme="primary"
                to={{
                  pathname: '/s',
                  state: { fromHeaderMenu: true },
                }}
                title="Finn musikere"
                aria-label="Finn musikere"
              >
                Finn musikere
              </Button>
            </ExtraSpacer>
          </HeaderMenuItem>
        ) : null}
        <HeaderMenuItem onClick={toggleSiteNav}>
          <HeaderBurgerButton
            aria-label="Hovednavigasjonsmeny"
            aria-haspopup="true"
            isOpen={showingSiteNav}
            hasDarkBg={hasDarkBg}
          />
        </HeaderMenuItem>
      </HeaderMenuList>
      <SiteNavigationMobileLoggedOut
        isOpen={showingSiteNav}
        closeSiteNav={closeSiteNav}
        favoriteCount={favoriteCount}
      />
    </MobileMenu>
  )
}

LoggedOutMobileMenu.propTypes = {
  showSearch: PropTypes.bool,
  favoriteCount: PropTypes.number.isRequired,
  hasDarkBg: PropTypes.bool,
}

export default React.memo(LoggedOutMobileMenu)
