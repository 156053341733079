import * as React from 'react'
import PropTypes from 'prop-types'

import { TIMELINE_EVENTS, QUOTE_STATUS, REQUEST_STATUS } from '../../models/GigRequest'

import { LoaderWrapper, EmptyText, Timeline, Item } from './style'

import Loader from '../../ui/Loader'
import GigRequestTimelineElementWrapper from '../GigRequestTimelineElementWrapper'
import GigRequestTimelineRequestUpdate from '../gig-request-timeline-request-update'
import GigRequestTimelineRequest from '../GigRequestTimelineRequest'
import GigRequestTimelineMessage from '../GigRequestTimelineMessage'
import GigRequestTimelineQuote from '../GigRequestTimelineQuote'

const GigRequestEventplannerTimeline = ({
  gigRequestId,
  artistId,
  isLoading,
  hasLoaded,
  artist,
  timeline,
  eventTypes,
}) => {
  if (isLoading || !hasLoaded) {
    return (
      <LoaderWrapper>
        <Loader text="Laster tidslinje" />
      </LoaderWrapper>
    )
  }
  if (!timeline || !timeline.length) {
    return <EmptyText>Ingen elementer i tidslinjen</EmptyText>
  }

  const timelineElements = []
  timeline.forEach(element => {
    let timelineItem = null
    switch (element.type) {
      case TIMELINE_EVENTS.REQUEST: {
        timelineItem = (
          <GigRequestTimelineElementWrapper
            user={element.data.user}
            artist={artist}
            fromArtist={element.data.fromArtist}
            activeUser={!element.data.fromArtist}
            time={element.time}
            systemMessage={element.data.status !== REQUEST_STATUS.ARTIST_DECLINED}
          >
            <GigRequestTimelineRequest
              status={element.data.status}
              element={element.data.values}
              eventTypes={eventTypes}
              time={element.time}
            />
          </GigRequestTimelineElementWrapper>
        )
        break
      }
      case TIMELINE_EVENTS.REQUEST_UPDATE: {
        timelineItem = (
          <GigRequestTimelineElementWrapper
            user={element.data.user}
            artist={artist}
            fromArtist={element.data.fromArtist}
            activeUser={!element.data.fromArtist}
            time={element.time}
          >
            <GigRequestTimelineRequestUpdate
              changes={element.data.changes}
              eventTypes={eventTypes}
            />
          </GigRequestTimelineElementWrapper>
        )
        break
      }
      case TIMELINE_EVENTS.MESSAGE: {
        timelineItem = (
          <GigRequestTimelineElementWrapper
            user={element.data.user}
            artist={artist}
            fromArtist={element.data.fromArtist}
            activeUser={!element.data.fromArtist}
            time={element.time}
          >
            <GigRequestTimelineMessage
              fromArtist={element.data.fromArtist}
              messageId={element.data.id}
              message={element.data.message}
            />
          </GigRequestTimelineElementWrapper>
        )
        break
      }
      case TIMELINE_EVENTS.QUOTE: {
        timelineItem = (
          <GigRequestTimelineElementWrapper
            user={element.data.user}
            artist={artist}
            fromArtist={element.data.fromArtist}
            activeUser={!element.data.fromArtist}
            time={element.time}
            systemMessage={
              element.data.status === QUOTE_STATUS.INVALIDATED ||
              element.data.status === QUOTE_STATUS.EXPIRED ||
              element.data.status === QUOTE_STATUS.ACCEPTED ||
              element.data.status === QUOTE_STATUS.REPLACED
            }
          >
            <GigRequestTimelineQuote
              gigRequestId={gigRequestId}
              artistId={artistId}
              quote={element.data}
              artist={artist}
              time={element.time}
            />
          </GigRequestTimelineElementWrapper>
        )
        break
      }
      default:
        timelineItem = <div>Not implemented: {element.type}</div>
        break
    }

    if (timelineItem) {
      timelineElements.push(<Item key={`${element.time}-${element.type}`}>{timelineItem}</Item>)
    }
  })

  return <Timeline>{timelineElements}</Timeline>
}

GigRequestEventplannerTimeline.propTypes = {
  gigRequestId: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  artist: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  timeline: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}
GigRequestEventplannerTimeline.defaultProps = {}

export default GigRequestEventplannerTimeline
