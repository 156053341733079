import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`
export const ProfileFormWrapper = styled.div`
  padding: 10px 10px 0;
  max-width: ${gigplanetTheme.measure.maxWidth};
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`

export const ProfileFormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 30px;
  h1 {
    font-size: 36px;
    font-weight: 300;
    line-height: 38px;
    text-align: center;
    text-transform: uppercase;
  }
`
export const UserError = styled.div``
export const UserActions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  ${mediaQueries.mobile`
    flex-direction: column-reverse;
  `};
`
export const UserActionButtons = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto;
`
export const UserLoading = styled.span`
  display: inline-block;
  height: 40px;
  position: relative;
  vertical-align: top;
`
export const UserActionSaveInfo = styled.div``
export const UserActionSaveInfoText = styled.span``
