import * as React from 'react'
import PropTypes from 'prop-types'

import { Button, Content, IconWrapper, TextWrapper } from './style'

const EmailButton = ({ onClick }) => (
  <Button type="button" onClick={onClick}>
    <Content>
      <IconWrapper>
        <svg
          viewBox="0 0 24 24"
          role="presentation"
          aria-hidden="true"
          focusable="false"
          style={{ display: 'block', fill: 'currentcolor', height: '18px', width: '18px' }}
        >
          <path
            fillRule="evenodd"
            d="M22.497 4H1.503C.665 4 0 4.673 0 5.507v12.985C0 19.326.672 20 1.503 20h20.994A1.5 1.5 0 0 0 24 18.492V5.507C24 4.674 23.328 4 22.497 4zM23 18.203l-6.141-7.907L23 5.628v12.575zM22.174 5l-9.685 7.362c-.259.196-.719.196-.977 0L1.827 5h20.347zM1 5.628l6.14 4.667L1 18.185V5.629zM1.634 19l6.302-8.1 2.97 2.258c.616.468 1.572.468 2.188 0l2.969-2.257L22.353 19H1.633z"
          />
        </svg>
      </IconWrapper>
      <TextWrapper>Registrer deg med e-post</TextWrapper>
    </Content>
  </Button>
)

EmailButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default EmailButton
