import styled from 'styled-components'
import { Link } from 'react-router-dom'
import gigplanetTheme from '../../../styles/gigplanetTheme'
import mediaQueries from '../../../styles/mediaQueries'

export const StyledEventplannerDashboardRequests = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`

export const Item = styled.li`
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  ${mediaQueries.mobile`
    margin-bottom: ${gigplanetTheme.spacing.xsmall};
  `};
`

export const Request = styled.div`
  padding: 15px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
`

export const RequestContent = styled.div`
  color: ${gigplanetTheme.element.default.text};
  display: flex;
  flex-direction: row;

  ${mediaQueries.mobile`
    flex-direction: column;
  `};
`
export const RequestStatus = styled.div`
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const RequestDetails = styled.div`
  flex: 1 0 auto;
  width: 50%;

  ${mediaQueries.mobile`
    width: 100%;
  `};
`
export const RequestLocation = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  line-height: 20px;
`

export const RequestDate = styled.p`
  line-height: 20px;
  margin: 0;
  &:first-letter {
    text-transform: uppercase;
  }
`

export const RequestArtists = styled.div`
  flex: 1 0 auto;
  width: 50%;

  ${mediaQueries.mobile`
    width: 100%;
    margin-top: 15px;
  `};
`

export const ChoosenArtistContainer = styled.div`
  margin-bottom: 15px;
`

export const ArtistList = styled.ul`
  list-style: none;
  padding-left: 0;
`
export const ArtistItem = styled.li`
  line-height: 20px;
  font-weight: 300;
  padding: 5px 0;
  ${mediaQueries.mobile`
    border-bottom: 1px solid ${gigplanetTheme.element.default.border};

    &:last-child {
      border-bottom: 0;
    }
  `};
`

export const ArtistLink = styled(Link)`
  color: ${gigplanetTheme.color.primary};
  text-decoration: underline;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  &:hover {
    text-decoration: none;
  }
`
export const ArtistName = styled.span`
  margin-left: 5px;
`

export const Unread = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -12px;
  border-radius: 100%;
  background: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  width: 10px;

  ${mediaQueries.mobile`
    left: auto;
    right: 10px;
  `};
`
