import * as React from 'react'
import PropTypes from 'prop-types'

import Button from '../../ui/Button'
import { H1 } from '../../ui/Headings'
import UserAuthIntro from '../../ui/user-auth-intro'
import IntroWrapper from '../../ui/user-auth-intro-wrapper'
import Actions from '../../ui/user-auth-actions'

const UserAuthVerifyEmailConfirmation = ({ email, onClose }) => (
  <>
    <H1>Vi har sendt deg en e-post</H1>
    <IntroWrapper>
      <UserAuthIntro>{`Sjekk ${email} og klikk på lenken for å verifisere kontoen.`}</UserAuthIntro>
    </IntroWrapper>
    <Actions>
      <Button type="button" theme="secondary" onClick={onClose}>
        Ok
      </Button>
    </Actions>
  </>
)

UserAuthVerifyEmailConfirmation.propTypes = {
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default UserAuthVerifyEmailConfirmation
