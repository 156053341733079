import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'
import generateColorCode from '../../helpers/generateColorCode'
import { getImgixCroppedUrl } from '../../models/Artist'

const Container = styled.div`
  border-radius: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;

  ${mediaQueries.mobile`
    font-size: 16px;
  `};
`

const Image = styled.img`
  border-radius: 100%;
`

const ArtistAvatar = ({ artist, size }) => {
  if (artist && artist.images && artist.images.length > 0) {
    const artistImage = artist.images[0]
    return (
      <Container
        style={{
          height: `${size}px`,
          width: `${size}px`,
          lineHeight: `${size}px`,
          minWidth: `${size}px`,
          minHeight: `${size}px`,
        }}
      >
        <Image
          style={{
            height: `${size}px`,
            width: `${size}px`,
          }}
          src={getImgixCroppedUrl(artistImage.src, `&w=${size}&h=${size}`, artistImage.crop)}
          alt={artist.name}
        />
      </Container>
    )
  }
  const colors = generateColorCode(artist.name)
  return (
    <Container
      style={{
        backgroundColor: colors.bg,
        color: colors.fg,
        height: `${size}px`,
        width: `${size}px`,
        lineHeight: `${size}px`,
        minWidth: `${size}px`,
        minHeight: `${size}px`,
      }}
    >
      {artist.name.charAt(0)}
    </Container>
  )
}

ArtistAvatar.propTypes = {
  artist: PropTypes.shape({
    name: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        crop: PropTypes.object,
      }),
    ),
    avatar: PropTypes.string,
  }).isRequired,
  size: PropTypes.number,
}
ArtistAvatar.defaultProps = {
  size: 100,
}

export default ArtistAvatar
