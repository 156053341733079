import styled from 'styled-components'

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
`

export const Separator = styled.div`
  padding: 15px 0;
`

export const Terms = styled.div`
  padding: 10px 0 0;
  text-align: center;
`
