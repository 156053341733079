import * as React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './style'

const LandingpageHeadingWrapper = React.memo(({ children, noPadding }) => (
  <Wrapper noPadding={noPadding}>{children}</Wrapper>
))

LandingpageHeadingWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  noPadding: PropTypes.bool,
}

LandingpageHeadingWrapper.defaultProps = {
  noPadding: false,
}

export default LandingpageHeadingWrapper
