import * as React from 'react'
import PropTypes from 'prop-types'

import { SECURITY_TIPS_ARTIST_URL, SECURITY_TIPS_EVENTPLANNER_URL } from '../../models/GigRequest'

import { Message, Link } from './style'
import TextSeparator from '../../ui/TextSeparator'

const GigRequestSecurityWarning = ({ isArtist }) => (
  <>
    <Message>
      For din egen sikkerhet og beskyttelse, bør du bare kommuniser gjennom Gigplanet.{' '}
      <Link
        href={isArtist ? SECURITY_TIPS_ARTIST_URL : SECURITY_TIPS_EVENTPLANNER_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        Les mer
      </Link>
    </Message>
  </>
)

GigRequestSecurityWarning.propTypes = {
  isArtist: PropTypes.bool,
}
GigRequestSecurityWarning.defaultProps = {
  isArtist: false,
}

export default GigRequestSecurityWarning
