/* globals window */

export const showIntercom = () => {
  if (window && window.Intercom) {
    window.Intercom('show')
  }
}

export const shutdownIntercom = () => {
  if (window && window.Intercom) {
    window.Intercom('shutdown')
  }
}
