// global isNaN */
/* eslint-disable import/prefer-default-export */
export const printCurrency = (input, options = {}) => {
  const defaults = {
    removeSign: false,
    signOnly: false,
  }

  const actual = { ...defaults, ...options }

  const sign = actual.removeSign ? '' : ' kr'

  if (actual.signOnly) return sign.trim()

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(input)) {
    return `0${sign}`
  }

  const formattedOutput = []
  const pieces = parseFloat(input).toFixed(0).split('')
  const reversedPieces = pieces.reverse()
  reversedPieces.forEach((digit, index) => {
    formattedOutput.push(digit)
    if ((index + 1) % 3 === 0 && index < reversedPieces.length - 1) {
      formattedOutput.push(' ')
    }
  })
  return `${formattedOutput.reverse().join('')}${sign}`
}
