import * as React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'

import { Placeholder, Wrapper } from './style'
import { VideoIcon } from '../../../../icons'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import Video from '../../../../ui/Video'
import { H2 } from '../../../../ui/landing-page-headings'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'

const VideoBlock = React.memo(({ theme, heading = '', anchorId = '', videoUrl }) => {
  const [visible, setIsVisible] = React.useState(false)

  const handleVisibilityChange = isVisible => {
    if (visible || !isVisible) {
      return
    }
    setIsVisible(true)
  }

  return (
    <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
      <VisibilitySensor
        onChange={handleVisibilityChange}
        partialVisibility
        active={!visible}
        offset={{ bottom: -150 }}
      >
        <>
          {heading ? (
            <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
          ) : null}

          {visible && videoUrl ? (
            <Wrapper noMargin={!Boolean(heading)}>
              <Video video={videoUrl} />
            </Wrapper>
          ) : (
            <Placeholder>
              <VideoIcon />
            </Placeholder>
          )}
        </>
      </VisibilitySensor>
    </LandingPageBlockWrapper>
  )
})

VideoBlock.propTypes = {
  theme: PropTypes.string.isRequired,
  anchorId: PropTypes.string,
  heading: PropTypes.string,
  videoUrl: PropTypes.string.isRequired,
}

export default VideoBlock
