import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Wrapper, SystemMessageWrapper, Container, UserWrapper, Time, Content } from './style'

import UserAvatar from '../../ui/UserAvatar'
import ArtistAvatar from '../../ui/ArtistAvatar'
import uppercaseFirstLetter from '../../helpers/uppercase-first-letter'

const GigRequestTimelineElementWrapper = ({
  systemMessage,
  activeUser,
  user,
  artist,
  fromArtist,
  hideUserOnMobile,
  children,
  time,
}) => {
  let avatar = null
  let mobileAvatar = null
  if (fromArtist && artist) {
    avatar = <ArtistAvatar artist={artist} size={50} />
    mobileAvatar = <ArtistAvatar artist={artist} size={30} />
  } else if (user && (user.firstName || user.avatar)) {
    avatar = <UserAvatar user={user} size={50} />
    mobileAvatar = <UserAvatar user={user} size={30} />
  }

  if (systemMessage) {
    return <SystemMessageWrapper>{children}</SystemMessageWrapper>
  }

  return (
    <Wrapper>
      <UserWrapper isActiveUser={activeUser} isHideUserOnMobile={hideUserOnMobile}>
        {avatar}
      </UserWrapper>
      <UserWrapper isMobile isActiveUser={activeUser} isHideUserOnMobile={hideUserOnMobile}>
        {mobileAvatar}
      </UserWrapper>
      <Container isActiveUser={activeUser} isHideUserOnMobile={hideUserOnMobile}>
        <Content
          isActiveUser={activeUser}
          isNoUser={!user && !artist}
          isHideUserOnMobile={hideUserOnMobile}
        >
          {children}
        </Content>
        {time && (
          <Time>
            {uppercaseFirstLetter(moment(time).format('dddd D. MMMM'))} Kl.{' '}
            {moment(time).format('HH:mm')}
          </Time>
        )}
      </Container>
    </Wrapper>
  )
}

GigRequestTimelineElementWrapper.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    avatar: PropTypes.string,
  }),
  artist: PropTypes.shape({
    name: PropTypes.string,
  }),
  fromArtist: PropTypes.bool,
  time: PropTypes.string,
  children: PropTypes.element.isRequired,
  systemMessage: PropTypes.bool,
  activeUser: PropTypes.bool,
  hideUserOnMobile: PropTypes.bool,
}

GigRequestTimelineElementWrapper.defaultProps = {
  user: null,
  fromArtist: false,
  artist: null,
  time: '',
  activeUser: false,
  systemMessage: false,
  hideUserOnMobile: false,
}

export default GigRequestTimelineElementWrapper
