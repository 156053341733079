import * as React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Container, ImageWrapper } from './style'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import isExternalLink from '../../../../helpers/is-external-link'
import Image from './components/Image'
import extractUrlPath from '../../../../helpers/extract-url-path'

const StyledExternalLink = styled.a`
  border: 0;
  text-decoration: 0;
  display: block;
  width: 100%;
`

const StyledInternalLink = styled(Link)`
  border: 0;
  text-decoration: 0;
  display: block;
  width: 100%;
`

const ResponsiveImageBlock = React.memo(
  ({
    theme,
    anchorId,
    imageMobile,
    imageTabletPortrait,
    imageTabletLandscape,
    imageDesktop,
    imageDesktopBig,
    altText,
    url,
    layout = 'full',
  }) => {
    const [visible, setIsVisible] = React.useState(false)

    const handleVisibilityChange = (isVisible) => {
      if (visible || !isVisible) {
        return
      }
      setIsVisible(true)
    }

    const isExternal = isExternalLink(url)

    const imageElement = (
      <Image
        visible={visible}
        altText={altText}
        imageMobile={imageMobile}
        imageTabletPortrait={imageTabletPortrait}
        imageTabletLandscape={imageTabletLandscape}
        imageDesktop={imageDesktop}
        imageDesktopBig={imageDesktopBig}
      />
    )
    return (
      <LandingPageBlockWrapper
        theme={theme}
        anchorId={anchorId}
        noPadding={!layout || layout === 'full'}
        fullWidth={!layout || layout === 'full'}
      >
        <Container>
          <VisibilitySensor
            onChange={handleVisibilityChange}
            partialVisibility
            active={!visible}
            offset={{ bottom: -150 }}
          >
            <ImageWrapper>
              {url ? (
                <>
                  {isExternal ? (
                    <StyledExternalLink href={url} target="_blank">
                      {imageElement}
                    </StyledExternalLink>
                  ) : (
                    <StyledInternalLink to={extractUrlPath(url)}>{imageElement}</StyledInternalLink>
                  )}
                </>
              ) : (
                imageElement
              )}
            </ImageWrapper>
          </VisibilitySensor>
        </Container>
      </LandingPageBlockWrapper>
    )
  },
)

ResponsiveImageBlock.propTypes = {
  imageMobile: PropTypes.shape({}).isRequired,
  imageTabletPortrait: PropTypes.shape({}).isRequired,
  imageTabletLandscape: PropTypes.shape({}).isRequired,
  imageDesktop: PropTypes.shape({}).isRequired,
  imageDesktopBig: PropTypes.shape({}).isRequired,
  altText: PropTypes.string.isRequired,
  url: PropTypes.string,
  layout: PropTypes.string,
  anchorId: PropTypes.string,
  theme: PropTypes.string.isRequired,
}

export default ResponsiveImageBlock
