import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import Layout from '../../layouts/main-layout'
import SystemMessageWrapper from '../../ui/system-message-wrapper'
import UserAuthWindow from '../../containers/user-auth-window'
import MetaData from '../../components/MetaData'

const PasswordResetSuccessRoute = ({ history }) => (
  <Layout showSearch>
    <MetaData
      title="Reset passord hos Gigplanet"
      description="Gigplanet hjelper deg å finne live underholdning til ditt arrangement, samtidig som vi hjelper artister å få flere spillejobber"
      noindex
    />
    <SystemMessageWrapper>
      <UserAuthWindow
        showNewPassword
        onClose={() => {
          history.push('/')
        }}
      />
    </SystemMessageWrapper>
  </Layout>
)

PasswordResetSuccessRoute.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(PasswordResetSuccessRoute)
