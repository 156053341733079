import * as React from 'react'
import PropTypes from 'prop-types'
import { ContentBlock, Heading, TextLine } from './style'

const Notice = ({ terms }) => (
  <ContentBlock>
    <Heading>Kontraktsfestet informasjon om spillejobben</Heading>
    <TextLine>{terms}</TextLine>
  </ContentBlock>
)

Notice.propTypes = {
  terms: PropTypes.string,
}

Notice.defaultProps = {
  terms: '',
}

export default Notice
