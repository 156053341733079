import * as React from 'react'
import PropTypes from 'prop-types'

import { Container, Borders, Border, Dots, Dot } from './style'

const GigRequestFormProgress = ({ step, stepCount }) => {
  const dots = []
  const borders = []
  for (let i = 1; i <= stepCount; i += 1) {
    dots.push(i)
    if (i < stepCount) {
      borders.push(i)
    }
  }
  
  return (
    <Container>
      <Borders>
        {borders.map(s => (
          <Border key={`border-${s}-${s < step ? 'active' : 'notactive'}`} isCompleted={s < step} />
        ))}
      </Borders>
      <Dots stepCount={stepCount}>
        {dots.map(s => (
          <Dot
            key={`dot-${s}-${s === step ? 'active' : 'notactive'}-${
              s <= step ? 'completed' : 'notcompleted'
            }`}
            isActive={s === step}
            isCompleted={s <= step}
          >
            {s}
          </Dot>
        ))}
      </Dots>
    </Container>
  )
}

GigRequestFormProgress.propTypes = {
  step: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
}

GigRequestFormProgress.defaultProps = {}

export default GigRequestFormProgress
