import * as React from 'react'
import PropTypes from 'prop-types'

import { Container, Intro } from './style'

import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'
import { H1 } from '../../../../ui/landing-page-headings'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'

const HeadingAndIntroBlock = React.memo(({ heading, intro, theme, anchorId }) => {
  const headingValue = formatSimpleMarkdown(heading)
  const introValue = (intro || '').replace(/(  )/g, '<br /> ')

  return (
    <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
      <Container>
        <H1 dangerouslySetInnerHTML={{ __html: `${headingValue}` }} />
        <Intro dangerouslySetInnerHTML={{ __html: `${introValue}` }} />
      </Container>
    </LandingPageBlockWrapper>
  )
})

HeadingAndIntroBlock.propTypes = {
  heading: PropTypes.string,
  intro: PropTypes.string,
  theme: PropTypes.string.isRequired,
  anchorId: PropTypes.string,
}

HeadingAndIntroBlock.defaultProps = {
  anchorId: '',
  heading: '',
  intro: '',
}

export default HeadingAndIntroBlock
