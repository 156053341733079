import styled from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const StyledGigRequestTimelineRequest = styled.div`
  text-align: center;
  width: 100%;
`
export const HelpDeskLink = styled.a`
  color: ${gigplanetTheme.color.primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
export const NewGigRequestNotice = styled.p`
  color: #484848;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  margin: 0;

  ${mediaQueries.mobile`
    font-size: 14px;
    line-height: 21px;
  `};
`

export const DeclineReason = styled.p`
  font-weight: 600;
`
export const ReasonText = styled.p``
