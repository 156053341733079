import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Slogan = styled.p`
  font-size: 15px;
  height: 19px;
  line-height: 19px;
  margin: 0;
  margin-bottom: 10px;
`

const ArtistCardSlogan = ({ children }) => <Slogan>{children}</Slogan>

ArtistCardSlogan.propTypes = {
  children: PropTypes.string,
}

ArtistCardSlogan.defaultProps = {
  children: '',
}
export default ArtistCardSlogan
