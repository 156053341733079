import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withRouter } from 'react-router'

import {
  GigRequestEventplannerShape,
  TIME_FORMAT,
  LONG_DATE_FORMAT,
  QUOTE_STATUS,
} from '../../../models/GigRequest'
import { getEventTypeName } from '../util'

import {
  StyledEventplannerDashboardRequests,
  Item,
  Request,
  RequestContent,
  RequestStatus,
  RequestDetails,
  RequestLocation,
  RequestDate,
  RequestArtists,
  ArtistList,
  ArtistItem,
  ChoosenArtistContainer,
} from './style'

import Artist from './artist'

import { H2, H3 } from '../../../ui/Headings'
import Button from '../../../ui/Button'
import ContentContainer from '../../../ui/ContentContainer'
import GigRequestStatusLabel from '../../../components/gig-request-status-label'

const EventplannerDashboardRequests = ({ requests, eventTypes, history }) => (
  <StyledEventplannerDashboardRequests>
    {requests.map(request => {
      const idx = request.artists.findIndex(
        artist => artist && artist.quote && artist.quote.status === QUOTE_STATUS.ACCEPTED,
      )
      let choosenArtist = null
      let requestArtists = [...request.artists]
      if (idx !== -1) {
        choosenArtist = { ...request.artists[idx] }
        requestArtists = [...request.artists.slice(0, idx), ...request.artists.slice(idx + 1)]
      }
      let detailsUrl = `/eventplanner/inbox/${request.id}`

      if ((request.artists || []).length === 1) {
        detailsUrl = `/eventplanner/inbox/${request.id}/${request.artists[0].artist.id}`
      }
      return (
        <Item key={request.id}>
          <ContentContainer>
            <Request>
              <RequestContent>
                <RequestDetails>
                  <H2>{getEventTypeName(request.eventType, eventTypes)}</H2>
                  <RequestLocation>
                    {request.venue}, {request.city}
                  </RequestLocation>
                  <RequestDate>{moment(request.startTime).format(LONG_DATE_FORMAT)}</RequestDate>
                  <RequestDate>
                    {moment(request.startTime).format(TIME_FORMAT)}-
                    {moment(request.endTime).format(TIME_FORMAT)}
                  </RequestDate>
                </RequestDetails>
                <RequestArtists>
                  {choosenArtist ? (
                    <ChoosenArtistContainer>
                      <H3>Du har booket:</H3>
                      <ArtistList>
                        <ArtistItem key={choosenArtist.artist.id}>
                          <Artist
                            requestId={request.id}
                            artist={choosenArtist.artist}
                            unreadCount={choosenArtist.request ? choosenArtist.request.unread : 0}
                          />
                        </ArtistItem>
                      </ArtistList>
                    </ChoosenArtistContainer>
                  ) : null}
                  {requestArtists && requestArtists.length > 0 ? (
                    <>
                      <H3>
                        {choosenArtist ? 'Forespørsel også sendt til:' : 'Forespørsel sendt til:'}
                      </H3>
                      <ArtistList>
                        {requestArtists.map(element => (
                          <ArtistItem key={element.artist.id}>
                            <Artist
                              requestId={request.id}
                              artist={element.artist}
                              unreadCount={element.request ? element.request.unread : 0}
                            />
                          </ArtistItem>
                        ))}
                      </ArtistList>
                    </>
                  ) : null}
                </RequestArtists>
              </RequestContent>
              <RequestStatus>
                <GigRequestStatusLabel requestStatus={request.status} />
                <Button
                  type="button"
                  theme="primary"
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    history.push(detailsUrl)
                  }}
                >
                  Vis forespørsel
                </Button>
              </RequestStatus>
            </Request>
          </ContentContainer>
        </Item>
      )
    })}
  </StyledEventplannerDashboardRequests>
)

EventplannerDashboardRequests.propTypes = {
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  requests: PropTypes.arrayOf(GigRequestEventplannerShape).isRequired,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}
EventplannerDashboardRequests.defaultProps = {}

export default withRouter(EventplannerDashboardRequests)
