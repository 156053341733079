import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import mediaQueries from '../../../../styles/mediaQueries'
import Heading from '../../../ProfileDetailsHeading/ProfileDetailsHeading'
import Button from '../../../../ui/Button'
import { printInstruments, printArtistRoles } from '../../../../models/Artist'
import { ProfileDetailsRow } from '../../styles'
import useOptions from '../../../../hooks/useOptions'

const Container = styled.div`
  background-color: #fff;
  padding: 2.35765%;
`
const Row = styled(ProfileDetailsRow)``

const Members = styled.ul`
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 65.88078%;
  margin-left: 17.05961%;
  list-style: none;
  ${mediaQueries.tabletPortraitUp`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  `};
  ${mediaQueries.mobile`
    width: 100%;
    margin: 0;
    padding: 0;
  `};
  :last-child {
    margin-right: 0;
  }
`
const Member = styled.li`
  padding: 2.35765%;
  width: 49%;
  align-self: flex-start;
  ${mediaQueries.mobile`
    padding: 2.35765% 2.35765% 2.35765% 0;
    width: 100%;
  `};
`
const MemberName = styled.h4`
  font-family: 'Halcom', Arial, sans-serif;
  font-size: 23px;
  margin: 0;
  padding: 0;
  text-align: center;
  ${mediaQueries.mobile`
    font-size: 18px;
    text-align: left;
  `};
`
const Instruments = styled.p`
  font-size: 14px;
  margin: 0;
  text-align: center;
  line-height: 20px;
  ${mediaQueries.mobile`
    text-align: left;
  `};
`

const Roles = styled.p`
  font-size: 14px;
  margin: 0;
  text-align: center;
  line-height: 20px;
  ${mediaQueries.mobile`
    text-align: left;
  `};
`
const ReadMore = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${mediaQueries.mobile`
    justify-content: flex-start;
  `};
`
const MemberDescription = styled.p`
  ${mediaQueries.mobile`
    font-size: 12px;
  `};
`

const ProfileDetailsMembers = React.memo(({ members }) => {
  const [readMore, setReadMore] = React.useState(false)
  const { instruments, danceTypes, artistMemberTypes } = useOptions()

  const toggleReadMore = () => {
    setReadMore(!readMore)
  }

  const gotDescription = members.findIndex(
    member => member.description && member.description.length > 0,
  )

  return (
    <Container>
      <Row>
        <Heading>Medlemmer</Heading>
      </Row>
      <Row>
        <Members>
          {members.map(member => (
            <Member
              itemProp="musicGroupMember"
              itemScope
              itemType="http://schema.org/Person"
              key={`${member.firstname}_${member.lastname}`}
            >
              <MemberName>
                {member.firstname} {member.lastname}
              </MemberName>
              {member.memberTypes &&
              Object.keys(member.memberTypes).filter(mt => member.memberTypes[mt]).length > 0 ? (
                <Roles>
                  {printArtistRoles(
                    member.memberTypes,
                    artistMemberTypes,
                    member.instruments,
                    instruments,
                    member.danceTypes,
                    danceTypes,
                  )}
                </Roles>
              ) : (
                <>
                  {member.instruments ? (
                    <Instruments>{printInstruments(member.instruments, instruments)}</Instruments>
                  ) : null}
                </>
              )}
              {readMore ? (
                <MemberDescription itemProp="description">{member.description}</MemberDescription>
              ) : null}
            </Member>
          ))}
        </Members>
      </Row>
      {gotDescription !== -1 && !readMore ? (
        <ReadMore>
          <Button type="button" theme="outline" onClick={toggleReadMore}>
            Les mer om medlemmene
          </Button>
        </ReadMore>
      ) : null}
    </Container>
  )
})

ProfileDetailsMembers.propTypes = {
  members: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default ProfileDetailsMembers
