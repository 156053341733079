import * as React from 'react'
import PropTypes from 'prop-types'

import {
  StyledGigRequestEventplannerRequestDetails,
  Options,
  Timeline,
  AdminWarning,
} from './style'
import ResponseOptions from '../../gig-request-response-options-eventplanner'
import TimelineEventplanner from '../../../components/gig-request-timeline-eventplanner'
import { USER_ROLE } from '../../../models/CurrentUser'
import AdminWarningBanner from '../../../components/admin-warning-banner'

const GigRequestEventplannerRequestDetails = React.memo(
  ({
    currentUser,
    gigRequestId,
    artistId,
    eventplannerRequest,
    isLoading,
    hasLoaded,
    eventTypes,
  }) => {
    const timeline =
      eventplannerRequest.artist && eventplannerRequest.artist.timeline
        ? eventplannerRequest.artist.timeline
        : []
    const artist =
      eventplannerRequest.artist && eventplannerRequest.artist.artist
        ? eventplannerRequest.artist.artist
        : null

    return (
      <StyledGigRequestEventplannerRequestDetails>
        {currentUser.role === USER_ROLE.ADMIN && !eventplannerRequest.userIsRequester ? (
          <AdminWarning>
            <AdminWarningBanner />
          </AdminWarning>
        ) : null}
        <Options>
          <ResponseOptions
            gigRequest={eventplannerRequest}
            gigRequestId={gigRequestId}
            artistId={artistId}
            eventTypes={eventTypes}
          />
        </Options>

        <Timeline>
          <TimelineEventplanner
            gigRequestId={gigRequestId}
            artistId={artistId}
            artist={artist}
            timeline={timeline}
            isLoading={isLoading}
            hasLoaded={hasLoaded}
            eventTypes={eventTypes}
          />
        </Timeline>
      </StyledGigRequestEventplannerRequestDetails>
    )
  },
)

GigRequestEventplannerRequestDetails.propTypes = {
  currentUser: PropTypes.shape({
    role: PropTypes.number,
  }).isRequired,
  gigRequestId: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,
  eventplannerRequest: PropTypes.shape({
    artist: PropTypes.object,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}
GigRequestEventplannerRequestDetails.defaultProps = {}

export default GigRequestEventplannerRequestDetails
