import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { Row, LegalText, Actions, InputWrapper } from './style'

import { Checkbox, Errormessage } from '../../../ui/Form'
import { H1 } from '../../../ui/Headings'
import Button from '../../../ui/Button'
import { ModalButton, ModalContent } from '../../ModalWindow'
import SanitySystemText from '../../../containers/sanity-system-text'

class JoinArtistLegal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      legal: false,
      errorMessage: [],
    }
  }

  handleNext = e => {
    const { legal } = this.state
    const { history } = this.props
    e.preventDefault()
    if (legal) {
      history.push('/join/artist/name/')
    } else {
      this.setState({
        errorMessage: ['Du må godta informasjonen'],
      })
    }
  }

  handleChangeLegal = e => {
    this.setState({
      legal: e.currentTarget.checked,
    })
  }

  render() {
    const { legal, errorMessage } = this.state
    return (
      <>
        <Row isLargePadding>
          <H1>Velkommen til Gigplanet</H1>
        </Row>
        <Row>
          <LegalText>
            <SanitySystemText id="join-artist" />
          </LegalText>
        </Row>
        <Row>
          <InputWrapper>
            <Checkbox
              id="legal"
              name="legal"
              label="Jeg har lest, forstått og akseptert informasjonen."
              value={1}
              checked={legal}
              onChange={this.handleChangeLegal}
              placeholder="Skriv inn navnet på artisten eller bandet"
              maxLength={70}
              expanded
            />
          </InputWrapper>
        </Row>
        <Row>
          <Errormessage messages={errorMessage} />
        </Row>
        <Row>
          <Actions>
            <Button type="button" theme="primary" onClick={this.handleNext}>
              Neste
            </Button>
          </Actions>
        </Row>
      </>
    )
  }
}

JoinArtistLegal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export default withRouter(JoinArtistLegal)
