import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Heading from '../ProfileDetailsHeading/ProfileDetailsHeading'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const ProfileDetailsSliderItem = styled.section`
  background-color: ${gigplanetTheme.color.white};
  width: 100%;
  padding: ${gigplanetTheme.spacing.medium} 0 0 0;

  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.xsmall} 0 0 0;
  `};
`

const HeadingWrapper = styled.div`
  padding: 0 ${gigplanetTheme.spacing.medium};

  ${mediaQueries.mobile`
    padding: 0 ${gigplanetTheme.spacing.xsmall};
  `};
`

export const ProfileDetailsSliderContainer = styled.div`
  position: relative;
  padding: 0px;
`

export const ProfileDetailsSliderSliderWrap = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  transform: translate3d(0px, 0px, 0px);
  will-change: scroll-position;
  scroll-behavior: smooth;
`

export const ProfileDetailsSliderSliderContent = styled.ul`
  margin: 0;
  position: relative;
  display: flex;
  padding-left: calc(${gigplanetTheme.spacing.medium} - ${gigplanetTheme.spacing.small});
  padding-right: calc(${gigplanetTheme.spacing.medium} - ${gigplanetTheme.spacing.small});
  padding-bottom: ${gigplanetTheme.spacing.medium};

  ${mediaQueries.mobile`
    padding-left: calc(${gigplanetTheme.spacing.xsmall} - ${gigplanetTheme.spacing.xxsmall});
    padding-right: calc(${gigplanetTheme.spacing.xsmall} - ${gigplanetTheme.spacing.xxsmall});
    padding-bottom: ${gigplanetTheme.spacing.xsmall};
  `};
`

const ProfileDetailsSlider = ({ containerId, heading, children }) => (
  <ProfileDetailsSliderItem id={containerId}>
    <HeadingWrapper>
      <Heading>{heading}</Heading>
    </HeadingWrapper>
    <ProfileDetailsSliderContainer>
      <ProfileDetailsSliderSliderWrap>
        <ProfileDetailsSliderSliderContent>{children}</ProfileDetailsSliderSliderContent>
      </ProfileDetailsSliderSliderWrap>
    </ProfileDetailsSliderContainer>
  </ProfileDetailsSliderItem>
)

ProfileDetailsSlider.propTypes = {
  containerId: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
}

export default ProfileDetailsSlider
