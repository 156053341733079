import styled from 'styled-components'
import mediaQueries from '../../../../styles/mediaQueries'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;

  ${mediaQueries.mobile`
    padding: 1rem 0;
  `};
`

export const HeadingsWrapper = styled.div`
  margin: 0;

  ${mediaQueries.mobile`
    margin: 0 0 10px;
    text-align: center;
  `};
`

export const Intro = styled.p`
  margin: 10px 10px 0;
  max-width: 600px;
  text-align: center;

  ${mediaQueries.mobile`
    text-align: center;
    margin-bottom: 10px;
  `};
`

export const AvatarWrapper = styled.div`
  padding-bottom: 1rem;

  ${mediaQueries.tabletPortraitUp`
    padding-bottom: 2rem;
  `};
`
