import * as React from 'react'
import PropTypes from 'prop-types'

import { Actions, Separator, Terms } from './style'

import UserAuthTermsInfo from '../user-auth-terms-info'
import UserAuthAlert from '../../ui/user-auth-alert'
import { H1, H2 } from '../../ui/Headings'
import Button from '../../ui/Button'
import FacebookButton from '../../ui/FacebookButton'
import GoogleButton from '../../ui/GoogleButton'
import TextSeparator from '../../ui/TextSeparator'

const UserAuthNoUser = React.memo(({ successUrl, provider, email, onClose }) => {
  let providerSet = false
  let providerText = 'leverandøren du har valgt'

  if (provider === 'facebook') {
    providerText = 'Facebook-konto'
    providerSet = true
  } else if (provider === 'google') {
    providerText = 'Google-konto'
    providerSet = true
  }

  return (
    <>
      <H1>Innlogging feilet</H1>
      <UserAuthAlert>
        Vi har ikke registrert en konto hos oss med <br />
        e-postadresse {email} fra {providerText}. <br />
        <br /> For å logge inn i Gigplanet, må du først registrere en konto hos oss.
      </UserAuthAlert>
      {providerSet ? <H2>Hva ønsker du å gjøre?</H2> : null}
      <Actions>
        <Button
          type="button"
          theme="primary"
          expanded
          to={`/login?url=${encodeURIComponent(successUrl)}`}
        >
          Logg inn med annen konto
        </Button>
        {providerSet ? (
          <>
            <Separator>
              <TextSeparator text="eller" />
            </Separator>
            {provider === 'facebook' ? <FacebookButton register redirectUrl={successUrl} /> : null}
            {provider === 'google' ? <GoogleButton register redirectUrl={successUrl} /> : null}
            <Terms>
              <UserAuthTermsInfo />
            </Terms>
          </>
        ) : null}
      </Actions>
    </>
  )
})

UserAuthNoUser.propTypes = {
  successUrl: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default UserAuthNoUser
