import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const FormatItalicIcon = props => (
  <SVGWrapper width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z" />
  </SVGWrapper>
)

export default FormatItalicIcon
