import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const Container = styled.div`
  margin: ${gigplanetTheme.spacing.large};

  ${mediaQueries.mobile`
    margin: ${gigplanetTheme.spacing.medium};
  `};
`
