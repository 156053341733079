import * as React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { trackEvent } from '../../../../libs/analytics'
import { ARTIST_EVENT_GIGREQUEST_URL } from '../../../../models/GigRequest'
import mediaQueries from '../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import { SupportChatIcon } from '../../../../icons'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${mediaQueries.mobile`
    width: 100%;
  `};
`
const Container = styled.div`
  background: #fff; /* background: $gigplanet-white-color; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  margin-left: 10px;

  ${mediaQueries.mobile`
    margin-left: 0;
    width: 100%;
    max-width: 100%;
    padding: 0 10px;
  `};
`

const Subtext = styled.span`
  height: 20px;
  line-height: 14px;
  font-size: 14px;
  padding: 3px 20px;

  ${mediaQueries.mobile`
    padding: 3px 10px;
    display: none;
  `};
`

const MessageButton = styled.button`
  background: white;
  border: 0;
  border-radius: 3px;
  color: ${gigplanetTheme.color.darkGray};
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid ${gigplanetTheme.color.gray};
  flex: 0 1 auto;

  svg {
    color: ${gigplanetTheme.color.darkGray};
  }
  ${mediaQueries.mobile`
    font-size: 14px;
    order: 1;
    margin-left: 0;
    margin-right: 5px;
    padding: 8px 12px;
  `};
`

const RequestButton = styled.button`
  background: #8805bb; /* $submit-background-color: $gigplanet-purple-color; */
  border: 1px solid #8805bb;
  border-radius: 3px;
  color: #fff; /* $submit-text-color: #fff; */
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 0 auto;

  ${mediaQueries.mobile`
    font-size: 14px;
    order: 1;
    margin-left: 0;
    margin-right: 5px;
    padding: 8px 12px;
  `};
`

const ButtonContent = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;

  svg {
    margin-right: 5px;
    width: 22px;
    height: 22px;
  }
`

const ProfileDetailsMenuButtons = ({ artistId, artistName, artistType, artistSlug }) => {
  const history = useHistory()

  const handleSendMessageForm = e => {
    e.preventDefault()
    e.stopPropagation()
    trackEvent('artist-profile', 'artist-profile-clicked-message-button', artistName)
    history.push(`/${artistType || 'artist'}/${artistSlug}/${ARTIST_EVENT_GIGREQUEST_URL}`)
  }

  const handleSendGigRequestForm = e => {
    e.preventDefault()
    e.stopPropagation()
    trackEvent('artist-profile', 'artist-profile-clicked-request-button', artistName)
    history.push(`/${artistType || 'artist'}/${artistSlug}/${ARTIST_EVENT_GIGREQUEST_URL}`)
  }

  return (
    <Wrapper>
      <Container>
        <MessageButton
          type="button"
          onClick={handleSendMessageForm}
          title={`Kontakt ${artistName}`}
        >
          <ButtonContent>
            <SupportChatIcon />
            Ta kontakt
          </ButtonContent>
        </MessageButton>
        <RequestButton
          type="button"
          onClick={handleSendGigRequestForm}
          title={`Kontakt ${artistName}`}
        >
          <ButtonContent>Send forespørsel</ButtonContent>
        </RequestButton>
      </Container>
      {/*
      <Subtext>Helt uten forpliktelser</Subtext>
      */}
    </Wrapper>
  )
}

ProfileDetailsMenuButtons.propTypes = {
  artistId: PropTypes.string.isRequired,
  artistName: PropTypes.string.isRequired,
  artistSlug: PropTypes.string.isRequired,
  artistType: PropTypes.string.isRequired,
}

export default ProfileDetailsMenuButtons
