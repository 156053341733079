import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import GigRequestQuotePrint from '../../../../../components/gig-request-quote-print'
import { getQuoteSelector } from '../../../../../stores/gig-request-eventplanner/reducer'
import { getOneArtistRequest } from '../../../../../stores/GigRequestArtist/GigRequestArtistReducer'
import { getEventTypes } from '../../../../../stores/Options/OptionsReducer'
import PageLoader from '../../../../../components/PageLoader'
import AccessDenied from '../../../../../components/AccessDenied'

const mapStateToProps = state => ({
  quote: getQuoteSelector(state),
  gigRequest: getOneArtistRequest(state),
  eventTypes: getEventTypes(state),
})

const mapDispatchToProps = dispatch => ({})

class PrintPreviewArtistQuoteRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    const {
      eventTypes,
      gigRequest,
      history: { location },
    } = this.props
    const query = queryString.parse(location.search)

    if (!gigRequest) return <PageLoader text="Laster kontrakt" />

    const { artist } = gigRequest
    if (!eventTypes || !artist) return <PageLoader text="Laster kontrakt" />

    if (query.gigRequestId !== gigRequest.id) {
      return <AccessDenied />
    }
    return (
      <GigRequestQuotePrint
        previewMode
        invoicePreviewInfo="Obs! Kontaktinformasjon vil bli synlig når og dersom kunden aksepterer kontraktsforslaget."
        invoiceOrganization=""
        invoiceName=""
        invoiceAddress=""
        invoiceZip=""
        invoiceCity=""
        invoiceReference=""
        contactName={
          gigRequest.requesterUser && gigRequest.requesterUser.firstName
            ? gigRequest.requesterUser.firstName
            : 'Navn på kontaktperson'
        }
        contactEmail="E-post til kontaktperson"
        contactPhone="Telefon til kontaktperson"
        artistName={artist.name}
        artistContactName={
          artist.contactInfo && artist.contactInfo.contactName
            ? artist.contactInfo.contactName
            : 'Navn til kontaktperson'
        }
        artistContactPhone={
          artist.contactInfo && artist.contactInfo.contactPhone
            ? artist.contactInfo.contactPhone
            : 'Telefon til kontaktperson'
        }
        artistContactEmail={
          artist.contactInfo && artist.contactInfo.contactEmail
            ? artist.contactInfo.contactEmail
            : 'E-post til kontaktperson'
        }
        artistInvoiceName={
          artist.invoiceInfo && artist.invoiceInfo.invoiceName
            ? artist.invoiceInfo.invoiceName
            : 'Navn til fakturamottaker'
        }
        artistInvoiceAddress={
          artist.invoiceInfo && artist.invoiceInfo.invoiceAddress
            ? artist.invoiceInfo.invoiceAddress
            : 'Fakturaadresse'
        }
        artistInvoiceZip={
          artist.invoiceInfo && artist.invoiceInfo.invoiceZip
            ? artist.invoiceInfo.invoiceZip
            : '0000'
        }
        artistInvoiceCity={
          artist.invoiceInfo && artist.invoiceInfo.invoiceCity
            ? artist.invoiceInfo.invoiceCity
            : 'BY'
        }
        artistOrganizationNumber={
          artist.invoiceInfo && artist.invoiceInfo.organizationNumber
            ? artist.invoiceInfo.organizationNumber
            : ''
        }
        eventTypes={eventTypes}
        eventType={gigRequest.eventType}
        audienceCount={gigRequest.audienceCount}
        venue={gigRequest.venue}
        city={gigRequest.city}
        startTime={gigRequest.startTime}
        endTime={gigRequest.endTime}
        pauseCount={query.pauseCount || ''}
        pauseDuration={query.pauseDuration || ''}
        price={query.price || ''}
        memberCount={query.members || 1}
        included={gigRequest.included}
        terms={query.terms || ''}
      />
    )
  }
}

PrintPreviewArtistQuoteRoute.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  }).isRequired,

  // state props
  quote: PropTypes.shape({}),
  gigRequest: PropTypes.shape({}),
  eventTypes: PropTypes.shape({}),
}
PrintPreviewArtistQuoteRoute.defaultProps = {}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PrintPreviewArtistQuoteRoute)

/*
 let printQuoteUrl = `/print/quote/?quoteId=${quoteId}&gigRequestId=${gigRequestId}&artistId=${artistId}`
    printQuoteUrl += `&invoiceOrganization=${invoice.organization}`
    printQuoteUrl += `&invoiceName=${invoice.name}`
    printQuoteUrl += `&invoiceAddress=${invoice.address}`
    printQuoteUrl += `&invoiceZip=${invoice.zip}`
    printQuoteUrl += `&invoiceCity=${invoice.city}`

    printQuoteUrl += `&contactName=${contact.name}`
    printQuoteUrl += `&contactPhone=${contact.phone}`
    printQuoteUrl += `&contactEmail=${contact.email}`

    */
