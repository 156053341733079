import styled from 'styled-components'
import mediaQueries from '../../../../styles/mediaQueries'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;

  ${mediaQueries.mobile`
    padding-top: 30px;
  `};

  & > * {
    margin-bottom: 10px;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`
