import styled from 'styled-components'
import gigplanetTheme from '../../../styles/gigplanetTheme'
import mediaQueries from '../../../styles/mediaQueries'

export const StyledSidebar = styled.div`
  border: 1px solid ${gigplanetTheme.element.default.border};
  min-width: 290px;
  padding: 30px 15px;
  width: 100%;
  text-align: center;

  ${mediaQueries.mobile`
    border: 0;
    padding: 15px;
  `};
`

export const LoaderWrapper = styled.div`
  ${mediaQueries.mobile`
    display: none;
  `};
`

export const Type = styled.p`
  color: ${gigplanetTheme.color.mediumGray};
  font-weight: 300;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 0 15px;
  text-align: center;
`

export const Members = styled.h3`
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
`

export const Separator = styled.hr`
  background: ${gigplanetTheme.element.default.border};
  border: 0;
  font-size: 1;
  height: 1px;
  margin: 30px;

  ${mediaQueries.mobile`
    margin: 15px 30px;
  `};
`

export const TimeInfo = styled.div`
  text-align: center;
  line-height: 20px;

  &:first-letter {
    text-transform: capitalize;
  }
`

export const Info = styled.div`
  text-align: center;
  line-height: 20px;
`

export const IncludedList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const IncludedItem = styled.li`
  text-align: center;
  line-height: 20px;
`

export const GetIn = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 12px;
`

export const Note = styled.pre`
  text-align: center;
  line-height: 20px;
  margin: 5px 0 0;
  font-family: inherit;
  white-space: pre-wrap;
`
