import * as React from 'react' // eslint-disable-line no-unused-vars
import styled, { css } from 'styled-components'

import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

const H1 = styled.h1`
  color: ${gigplanetTheme.element.default.heading};
  font-size: 36px;
  font-weight: 400;
  line-height: 38px;
  margin: 0;
  text-align: center;

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${gigplanetTheme.spacing.large};
    `}

  strong {
    font-weight: 700;
  }

  ${mediaQueries.tabletPortrait`
    font-size: 30px;
    line-height: 30px;
    
    ${({ marginBottom }) =>
      marginBottom &&
      css`
        margin-bottom: ${gigplanetTheme.spacing.medium};
      `}
  `};

  ${mediaQueries.mobile`
    font-size: 24px;
    line-height: 24px;
    
    ${({ marginBottom }) =>
      marginBottom &&
      css`
        margin-bottom: ${gigplanetTheme.spacing.small};
      `}
  `};
`

export { H1 }

const H2 = styled.h2`
  color: ${gigplanetTheme.element.default.heading};
  font-size: 30px;
  font-weight: 400;
  margin: 0;
  text-align: center;

  strong {
    font-weight: 700;
  }

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${gigplanetTheme.spacing.medium};
    `}

  ${mediaQueries.mobile`
    font-size: 22px;
    line-height: 22px;

    ${({ marginBottom }) =>
      marginBottom &&
      css`
        margin-bottom: ${gigplanetTheme.spacing.small};
      `}
  `};
`

export { H2 }
