import * as React from 'react'
import PropTypes from 'prop-types'

import { ContentBlock, Heading, TextLine } from './style'

import { printCurrency } from '../../../helpers/Currency'
import uppercaseFirstLetter from '../../../helpers/uppercase-first-letter'

const Pay = ({ price }) => (
  <ContentBlock>
    <Heading>Honorar</Heading>
    <TextLine>{uppercaseFirstLetter(printCurrency(price))}</TextLine>
    <TextLine>
      Artistens reise, sosiale og administrative utgifter er innregulert i honoraret. Honoraret blir
      fakturert av artist direkte etter endt jobb med 14 dagers betalingsfrist
    </TextLine>
  </ContentBlock>
)

Pay.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}
export default Pay
