import * as React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import debounce from 'lodash.debounce'

import { postJSON } from '../../libs/data'

import { List, Item } from './style'
import { ArtistShape } from '../../models/Artist'
import { addFavoriteAction } from '../../stores/Artist/ArtistActions'
import { getCategories } from '../../stores/Options/OptionsReducer'

import ArtistCard from '../../components/ArtistCard/ArtistCard'
import { getSearchData } from '../../stores/Artist/ArtistReducer'

const ArtistListContainer = ({ artists, searchView, hideFavoriteActions, onTrackClick }) => {
  const dispatch = useDispatch()
  const categories = useSelector(getCategories)
  const searchData = useSelector(getSearchData)
  const artistBufferRef = React.useRef([])

  const handleAddFavorite = id => {
    dispatch(addFavoriteAction(id))
  }

  const trackArtists = () => {
    postJSON('/api/v1/stat/addSearchView', { ids: artistBufferRef.current, ...searchData }).then(
      () => {},
      () => {},
    )
    artistBufferRef.current = []
  }

  const trackArtistsDebounce = debounce(trackArtists, 200)

  const handleTrackArtistView = artistId => {
    if (artistBufferRef.current.indexOf(artistId) === -1) {
      artistBufferRef.current.push(artistId)
    }
    trackArtistsDebounce()
  }

  return (
    <List isSearch={searchView}>
      {artists.map((artist, index) => (
        <Item key={artist.id} isSearch={searchView}>
          <ArtistCard
            artist={artist}
            index={index}
            categories={categories}
            hideFavoriteActions={hideFavoriteActions}
            onAddFavorite={handleAddFavorite}
            onTrackClick={onTrackClick}
            onTrackView={handleTrackArtistView}
          />
        </Item>
      ))}
    </List>
  )
}

ArtistListContainer.propTypes = {
  artists: PropTypes.arrayOf(ArtistShape), // Provided from outside
  searchView: PropTypes.bool, // provided from outside
  hideFavoriteActions: PropTypes.bool, // provided from outside
  onTrackClick: PropTypes.func,
}

ArtistListContainer.defaultProps = {
  artists: [],
  searchView: false,
  hideFavoriteActions: false,
  onTrackClick: null,
}

export default ArtistListContainer
