import styled from 'styled-components'

import mediaQueries from '../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../styles/gigplanetTheme'

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${mediaQueries.mobile`
    margin: 0;
  `};
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 315px;
  width: 100%;
  margin-left: 20px;
`

export const Item = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    fill: ${gigplanetTheme.color.purple};
  }
`

export const Text = styled.span`
  margin-left: 10px;
  line-height: 30px;
  font-weight: 300;

  strong {
    font-weight: 600;
  }
`
