import * as React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router'

import Layout from '../../layouts/main-layout'
import MetaData from '../../components/MetaData'
import Help, { EVERYONE } from '../../components/Help'

const ControlpanelHelpRoute = () => (
  <Layout>
    <MetaData title="Hjelp" description="Her kan du få hjelp" nopin />
    <Help audience={EVERYONE} />
  </Layout>
)

export default compose(withRouter)(ControlpanelHelpRoute)
