import PropTypes from 'prop-types'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { artistSearchOrderBy } from '../../../models/Artist'
import { Select } from '../../../ui/Form'
import mediaQueries from '../../../styles/mediaQueries'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  width: 100%;
  max-width: 840px;
  ${mediaQueries.tabletPortraitDown`
    padding: 10px 10px;
  `};
`
const OrderBy = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const OrderByLabel = styled.label`
  font-size: 14px;
  margin-right: 10px;
  ${mediaQueries.mobile`
    padding: 10px 10px;
  `};
`
const OrderSelectContainer = styled.div`
  min-width: 150px;
`

const SortCount = styled.div``

const SearchPageSort = ({ count, totalCount, sortField, sortDir, onChange }) => {
  const sortOptions = artistSearchOrderBy.map(sort => ({
    name: sort.name,
    value: `${sort.sortField}${sort.sortDir}`,
  }))
  let dataCount = (
    <SortCount>
      Viser <strong>{count}</strong> av <strong>{totalCount}</strong>{' '}
      {totalCount === 1 ? 'artist' : 'artister'}
    </SortCount>
  )

  if (totalCount === 0) {
    dataCount = <SortCount>Viser ingen artister</SortCount>
  }
  return (
    <Container>
      {dataCount}
      <OrderBy>
        <OrderByLabel htmlFor="filter-search-order-by">Sorter etter:</OrderByLabel>
        <OrderSelectContainer>
          <Select
            id="filter-search-order-by"
            name="filter-search-order-by"
            options={sortOptions}
            value={`${sortField}${sortDir}`}
            onChange={onChange}
            hideLabel
            expanded
            narrow
          />
        </OrderSelectContainer>
      </OrderBy>
    </Container>
  )
}

SearchPageSort.propTypes = {
  sortField: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
}

SearchPageSort.defaultProps = {}

export default SearchPageSort
