import * as React from 'react'
import PropTypes from 'prop-types'

import { H1 } from '../../ui/Headings'
import Intro from '../../ui/user-auth-intro'
import IntroWrapper from '../../ui/user-auth-intro-wrapper'
import HeadingWrapper from '../../ui/user-auth-heading-wrapper'
import Actions from '../../ui/user-auth-actions'

import { ModalButton, ModalContent } from '../ModalWindow'
import UserAuthModal from '../user-auth-modal'
import UserAuthAbort from '../user-auth-abort'
import SubmitWrapper from '../../ui/user-auth-submit-wrapper'
import Button from '../../ui/Button'

class UserAuthPasswordResetError extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showResetPassword: false,
    }
  }

  handleShowResetPassword = () => {
    this.setState({
      showResetPassword: true,
    })
  }

  handleCloseResetPassword = () => {
    const { onClose } = this.props
    this.setState({
      showResetPassword: false,
    })
    onClose()
  }

  render() {
    const { showResetPassword } = this.state
    const { onClose } = this.props
    return (
      <>
        <H1>Reset av passord feilet</H1>
        <IntroWrapper>
          <Intro>Trykk på knappen under for å prøve på nytt.</Intro>
        </IntroWrapper>
        <Actions>
          <SubmitWrapper>
            <ModalButton
              id="reset-password-window"
              isOpen={showResetPassword}
              onClick={this.handleShowResetPassword}
              renderButton={props => (
                <Button type="button" theme="primary" {...props}>
                  Tilbakestill passord
                </Button>
              )}
            />
            <ModalContent
              id="reset-password-window"
              label="Logg inn"
              isOpen={showResetPassword}
              onClose={this.handleCloseResetPassword}
              hideClose
            >
              <UserAuthModal showForgotPassword onClose={this.handleCloseResetPassword} />
            </ModalContent>
          </SubmitWrapper>
        </Actions>
        <UserAuthAbort onAbort={onClose} />
      </>
    )
  }
}

UserAuthPasswordResetError.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default UserAuthPasswordResetError
