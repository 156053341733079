import * as React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { getParams } from './util'
import {
  Container,
  Actions,
  ArtistListWrapper,
  ReadMoreWrapper,
  FilterWrapper,
  EmptyWrapper,
  HeadingWrapper,
} from './style'
import { trackEvent } from '../../../../libs/analytics'
import { searchAction } from '../../../../stores/Artist/ArtistActions'

import {
  getSearchArtists,
  isSearchArtistsLoading,
  isMoreSearchArtists,
} from '../../../../stores/Artist/ArtistReducer'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import { H2 } from '../../../../ui/landing-page-headings'
import { ArtistShape } from '../../../../models/Artist'
import ArtistList from '../../../../containers/ArtistList'
import Button from '../../../../ui/Button'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'
import generateUrlParamsFromObject from '../../../../helpers/generate-url-params-from-object'
import Loader from '../../../../ui/Loader'

import LocationFilter from './components/location-filter'
import { postJSON } from '../../../../libs/data'

const ArtistFilterListBlock = React.memo(
  ({
    theme,
    anchorId = '',
    heading = '',
    categoryFilter,
    showCategoryFilter,
    locationFilter,
    showLocationFilter,
    genreFilter,
    showGenreFilter,
    eventTypeFilter,
    showEventTypeFilter,
    limit,
    sortField,
    showMoreButtonText = '',
    showSearchButtonText = '',
  }) => {
    const dispatch = useDispatch()
    const [location, setLocationFilter] = React.useState(locationFilter)
    const [eventType, setEventTypeFilter] = React.useState(eventTypeFilter)
    const [category, setCategoryFilter] = React.useState(categoryFilter)
    const [genre, setGenreFilter] = React.useState(genreFilter)
    const [offset, setOffset] = React.useState(0)
    const artists = useSelector(getSearchArtists)
    const artistsLoading = useSelector(isSearchArtistsLoading)
    const isMore = useSelector(isMoreSearchArtists)
    const params = React.useMemo(() => {
      return getParams(location, eventType, category, genre, sortField)
    }, [location, eventType, category, genre])

    React.useEffect(() => {
      dispatch(searchAction({}, limit, offset, params))
    }, [limit, offset, params])

    const handleShowMoreArtists = () => {
      setOffset(offset + limit)
    }
    const handleTrackClick = async (id, pos) => {
      try {
        await postJSON('/api/v1/stat/addSearchClick', {
          id,
          ...params,
        })
      } catch (e) {}
    }

    const showMoreUrl = `/s${generateUrlParamsFromObject(params)}`
    return (
      <LandingPageBlockWrapper theme={theme} noPadding anchorId={anchorId}>
        <Container>
          {heading ? (
            <HeadingWrapper>
              <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
            </HeadingWrapper>
          ) : null}

          {showLocationFilter ? (
            <FilterWrapper>
              <LocationFilter filterValue={location} onSetFilter={setLocationFilter} />
            </FilterWrapper>
          ) : null}
          {showCategoryFilter ? <div>category</div> : null}
          {showGenreFilter ? <div>genre</div> : null}
          {showEventTypeFilter ? <div>eventType</div> : null}
          {artists && artists.length > 0 ? (
            <ArtistListWrapper>
              <ArtistList artists={artists} onTrackClick={handleTrackClick} />
            </ArtistListWrapper>
          ) : (
            <>
              {artistsLoading ? (
                <ArtistListWrapper>
                  <Loader text="Finner artister" />
                </ArtistListWrapper>
              ) : (
                <EmptyWrapper>Finner ingen artister</EmptyWrapper>
              )}
            </>
          )}

          {showMoreUrl &&
          artists &&
          artists.length > 0 &&
          !artistsLoading &&
          (showMoreButtonText || showSearchButtonText) ? (
            <Actions>
              {showMoreButtonText && isMore ? (
                <ReadMoreWrapper>
                  <Button size="large" theme="outline" onClick={handleShowMoreArtists} uppercase>
                    {showMoreButtonText}
                  </Button>
                </ReadMoreWrapper>
              ) : null}
              {showSearchButtonText && !isMore ? (
                <ReadMoreWrapper>
                  <Button size="large" theme="outline" to={showMoreUrl} uppercase>
                    {showSearchButtonText}
                  </Button>
                </ReadMoreWrapper>
              ) : null}
            </Actions>
          ) : null}
        </Container>
      </LandingPageBlockWrapper>
    )
  },
)

ArtistFilterListBlock.propTypes = {
  heading: PropTypes.string,
  showMoreButtonText: PropTypes.string,
  showSearchButtonText: PropTypes.string,
  anchorId: PropTypes.string,
}

export default ArtistFilterListBlock
