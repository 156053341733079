import * as React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import YoutubePlayer from '../../../YoutubePlayer'
import VimeoPlayer from '../../../VimeoPlayer'
import ProfileDetailsSlider from '../../../ProfileDetailsSlider/ProfileDetailsSlider'
import Button from '../../../../ui/Button'

import {
  ProfileDetailsVideoItems,
  ProfileDetailsVideoItem,
  ProfileDetailsVideoItemReadMoreListItem,
  ProfileDetailsVideoItemsReadMore,
  ProfileDetailsVideoItemsReadMoreButton,
} from './style'

class ProfileDetailsVideo extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      readMore: false,
      visible: false,
    }
  }

  handleVisibilityChange = isVisible => {
    const { visible } = this.state
    if (visible || !isVisible) {
      return
    }

    this.setState({
      visible: true,
    })
  }

  toggleReadMore = () => {
    const { readMore } = this.state
    this.setState({
      readMore: !readMore,
    })
  }

  render() {
    const { videos, artistName, containerId } = this.props
    const { readMore, visible } = this.state
    // const tmpvideos = [videos[0], videos[1], videos[2], videos[2], videos[1]]
    let videosElements = videos.map(video => {
      let player = null
      let videoUrl = ''
      let thumbUrl = ''
      switch (video.type) {
        case 'youtube': {
          videoUrl = `https://www.youtube.com/embed/${video.id}`
          thumbUrl = `http://img.youtube.com/vi/${video.id}/hqdefault.jpg`
          player = <YoutubePlayer id={video.id} />
          break
        }
        case 'vimeo': {
          videoUrl = `https://player.vimeo.com/video/${video.id}`
          player = <VimeoPlayer id={video.id} />
          break
        }
        default:
          break
      }
      return visible ? (
        <ProfileDetailsVideoItem
          hasOne={videos.length === 1}
          hasTwo={videos.length === 2}
          key={video.id}
          itemProp="video"
          itemScope
          itemType="http://schema.org/VideoObject"
        >
          {player}
          <meta itemProp="name" content={`Video av ${artistName}`} />
          <meta itemProp="description" content={`Denne videoen viser ${artistName} som spiller`} />
          <meta itemProp="embedURL" content={videoUrl} />
          <meta itemProp="thumbnailUrl" content={thumbUrl} />
        </ProfileDetailsVideoItem>
      ) : null
    })
    return (
      <VisibilitySensor
        onChange={this.handleVisibilityChange}
        partialVisibility
        active={!visible}
        offset={{ bottom: -100 }}
      >
        <ProfileDetailsVideoItems>
          <ProfileDetailsSlider heading="Video" containerId={containerId}>
            {videosElements}
          </ProfileDetailsSlider>
        </ProfileDetailsVideoItems>
      </VisibilitySensor>
    )
  }
}

ProfileDetailsVideo.propTypes = {
  artistName: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
}

export default ProfileDetailsVideo
