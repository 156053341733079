import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { trackEvent } from '../../../../../libs/analytics'
import mediaQueries from '../../../../../styles/mediaQueries'
import {
  getImgixCroppedUrl,
  ARTIST_IMAGE_IMGIX_FORMAT_MAIN_MOBILE,
  ARTIST_IMAGE_IMGIX_FORMAT_MAIN_TABLET,
  ARTIST_IMAGE_IMGIX_FORMAT_MAIN_DESKTOP,
} from '../../../../../models/Artist'

const ImagePlaceHolder = styled.span`
  background: #ccc;
  border-radius: 3px;
  width: 100%;
  min-height: 250px;
  display: block;
`

const ImageWrapper = styled.div`
  display: inline-block;
  width: 100%;
  height: auto;
`
const ImageElement = styled.img`
  display: inline-block;
  max-width: 100%;
  width: auto;
  height: auto;

  ${mediaQueries.mobile`
    width: 100%;
  `};
`

const ImageCC = styled.span`
  display: block;
  height: 20px;
  line-height: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* color: $cc-color; */
  font-size: 12px;
  text-align: left;
  padding: 2px;
`

const ImageButtonWrapper = styled.button`
  display: inline-block;
  width: 100%;
  padding: 0;
  background: transparent;
  cursor: pointer;
  border: 0;
`

const Image = React.memo(({ onOpenImageGallery, images, artistName }) => {
  let cc = null
  if ((images || []).length > 0) {
    const artistImage = images[0]
    const srcSet = `${getImgixCroppedUrl(
      artistImage.src,
      ARTIST_IMAGE_IMGIX_FORMAT_MAIN_MOBILE,
      artistImage.crop,
    )} 600w,
    ${getImgixCroppedUrl(
      artistImage.src,
      ARTIST_IMAGE_IMGIX_FORMAT_MAIN_TABLET,
      artistImage.crop,
    )} 900w,
    ${getImgixCroppedUrl(
      artistImage.src,
      ARTIST_IMAGE_IMGIX_FORMAT_MAIN_DESKTOP,
      artistImage.crop,
    )} 1200w`

    if (artistImage.text) {
      cc = <ImageCC>Foto: {artistImage.text}</ImageCC>
    }
    return (
      <ImageButtonWrapper
        type="button"
        onClick={() => {
          trackEvent(
            'artist-profile',
            'artist-profile-show-image-gallery-from-header-image',
            artistName,
          )
          onOpenImageGallery(0)
        }}
      >
        <ImageElement
          itemProp="image"
          src={getImgixCroppedUrl(
            artistImage.src,
            ARTIST_IMAGE_IMGIX_FORMAT_MAIN_DESKTOP,
            artistImage.crop,
          )}
          srcSet={srcSet}
          alt={`${artistName} Credits:`}
        />
        {cc}
      </ImageButtonWrapper>
    )
  }

  return (
    <ImageWrapper>
      <ImagePlaceHolder />
    </ImageWrapper>
  )
})

Image.propTypes = {
  onOpenImageGallery: PropTypes.func.isRequired,
  images: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  artistName: PropTypes.string,
}

export default Image
