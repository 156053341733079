import * as React from 'react'
import throttle from 'lodash.throttle'

import { LogoLink, StickyWrapper, StyledStickyHeader, Content } from './style'
import GigplanetLogo from '../../../../ui/GigplanetLogo'

const StickyHeader = React.memo(() => {
  const [isSticky, setIsSticky] = React.useState(false)
  const headerRef = React.useRef()
  const isSticyRef = React.useRef(isSticky)

  React.useEffect(() => {
    const checkScrollPosition = () => {
      if (headerRef && headerRef.current) {
        const rect = headerRef.current.getBoundingClientRect()
        const currentIsSticky = rect.top < 0

        if (isSticyRef.current !== currentIsSticky) {
          isSticyRef.current = currentIsSticky
          setIsSticky(currentIsSticky)
        }
      }
    }
    const checkScrollPositionThrottled = throttle(checkScrollPosition, 100, {
      leading: false,
    })
    window.addEventListener('scroll', checkScrollPositionThrottled)
    return () => {
      window.removeEventListener('scroll', checkScrollPositionThrottled)
    }
  }, [])

  return (
    <StickyWrapper ref={headerRef}>
      <StyledStickyHeader isSticky={isSticky}>
        <Content>
          <LogoLink to="/" aria-label="Gigplanet - Gå til forsiden">
            <GigplanetLogo inverted={!isSticky} role="presentation" alt="Gigplanet" />
          </LogoLink>
        </Content>
      </StyledStickyHeader>
    </StickyWrapper>
  )
})

export default StickyHeader
