import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const ProfileDetailsImageGalleryFooter = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 58px;
  text-align: center;
  font-weight: bold;
`

const Footer = React.memo(({ footerText = '' }) => (
  <ProfileDetailsImageGalleryFooter>{footerText}</ProfileDetailsImageGalleryFooter>
))

Footer.propTypes = {
  footerText: PropTypes.string,
}

export default Footer
