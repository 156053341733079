import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { trackEvent } from '../../libs/analytics'
import { ArtistShape } from '../../models/Artist'
import { addFavoriteAction, removeFavoriteAction } from '../../stores/Artist/ArtistActions'
import { getFavoriteArtistIds, isFirstFavorite } from '../../stores/Artist/ArtistReducer'

import FavoriteButton from './components/favorite-button'

const mapStateToProps = state => ({
  favoriteArtistIds: getFavoriteArtistIds(state),
  isFirstFavorite: isFirstFavorite(state),
})

const mapDispatchToProps = dispatch => ({
  onAddFavorite: id => {
    dispatch(addFavoriteAction(id))
  },
  onRemoveFavorite: id => {
    dispatch(removeFavoriteAction(id))
  },
})

class FavoriteButtonContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMessage: false,
    }
    this.handleToggleFavorite = this.handleToggleFavorite.bind(this)
  }

  handleToggleFavorite(e) {
    e.preventDefault()
    e.stopPropagation()
    const {
      favoriteArtistIds,
      artistId,
      artistName,
      isFirstFavorite: firstFavorive,
      onRemoveFavorite,
      onAddFavorite,
      round,
    } = this.props
    const isFavorited = !!favoriteArtistIds[artistId]
    let showMessage = false
    if (isFavorited) {
      trackEvent(
        'favorite-button',
        round
          ? 'artist-card-clicked-favorite-button-remove'
          : 'artist-profile-clicked-favorite-button-remove',
        artistName,
      )
      onRemoveFavorite(artistId)
    } else {
      trackEvent(
        'favorite-button',
        round
          ? 'artist-card-clicked-favorite-button-add'
          : 'artist-profile-clicked-favorite-button-add',
        artistName,
      )
      onAddFavorite(artistId)
      if (firstFavorive) {
        showMessage = true
      }
    }
    this.setState({
      showMessage,
    })
  }

  render() {
    const { favoriteArtistIds, artistId, menuView, round } = this.props
    const { showMessage } = this.state
    return (
      <FavoriteButton
        isFavorited={!!favoriteArtistIds[artistId]}
        onToggleFavorite={this.handleToggleFavorite}
        menuView={menuView}
        round={round}
        showMessage={showMessage}
      />
    )
  }
}

FavoriteButtonContainer.propTypes = {
  artistName: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,
  round: PropTypes.bool,
  menuView: PropTypes.bool,

  isFirstFavorite: PropTypes.bool.isRequired,
  onAddFavorite: PropTypes.func.isRequired,
  onRemoveFavorite: PropTypes.func.isRequired,
  favoriteArtistIds: PropTypes.objectOf(ArtistShape),
}
FavoriteButtonContainer.defaultProps = {
  round: false,
  menuView: false,
  favoriteArtistIds: {},
}

const FavoriteButtonContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FavoriteButtonContainer)

export default FavoriteButtonContainerWrapper
