/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react'
import PropTypes from 'prop-types'

import { Container, QuestionButton, Backdrop, Text, CloseButton } from './style'

import { CloseIcon } from '../../icons'

const propTypes = {
  text: PropTypes.string.isRequired,
}

class HelpPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showingHelp: false,
    }
  }

  onShowHelp = () => {
    this.setState({
      showingHelp: true,
    })
  }

  onHideHelp = () => {
    this.setState({
      showingHelp: false,
    })
  }

  render() {
    const { text } = this.props
    const { showingHelp } = this.state

    return (
      <Container>
        <QuestionButton type="button" onClick={this.onShowHelp} aria-label="Vis hjelp">
          ?
        </QuestionButton>
        {showingHelp && (
          <Text>
            <CloseButton type="button" onClick={this.onHideHelp} aria-label="Skjul hjelp">
              <CloseIcon />
            </CloseButton>
            {text}
          </Text>
        )}
        {showingHelp ? <Backdrop onClick={this.onHideHelp} /> : null}
      </Container>
    )
  }
}

HelpPopup.propTypes = propTypes

export default HelpPopup
