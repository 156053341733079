import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'

export const ProfileDetailsHeadingItem = styled.h2`
  align-items: center;
  background: transparent;
  display: flex;
  flex-direction: row;
  font-size: 30px;
  font-weight: normal;
  justify-content: center;
  margin-bottom: 2.35765%;
  margin-top: 0;
  padding: 0;
  text-align: center;
  ${mediaQueries.mobile`
    font-size: 24px;
    text-align: left;
    justify-content: flex-start;
  `};
`

const ProfileDetailsHeading = ({ children }) => (
  <ProfileDetailsHeadingItem>{children}</ProfileDetailsHeadingItem>
)

ProfileDetailsHeading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
}

export default ProfileDetailsHeading
