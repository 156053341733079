import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const ExpandMoreIcon = props => (
  <SVGWrapper width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </SVGWrapper>
)

export default ExpandMoreIcon
