import * as React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './style'

const LandingPageWrapper = React.memo(({ children }) => <Wrapper>{children}</Wrapper>)

LandingPageWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
}

export default LandingPageWrapper
