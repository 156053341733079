import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { ContentBlock, TextLine, Heading } from './style'
import { LONG_DATE_FORMAT, TIME_FORMAT } from '../../../models/GigRequest'

import getEventTypeName from '../../../helpers/getEventTypeName'
import getEventTypeCategory from '../../../helpers/getEventTypeCategory'
import getPlaytimeText from '../../../helpers/get-playtime-text'
import uppercaseFirstLetter from '../../../helpers/uppercase-first-letter'

const EventDetails = ({
  artistName,
  eventType,
  eventTypes,
  audienceCount,
  startTime,
  endTime,
  city,
  venue,
  pauseCount,
  pauseDuration,
}) => (
  <ContentBlock>
    <Heading>Arrangementets detaljer</Heading>
    <TextLine>
      Type arrangement: {getEventTypeCategory(eventType, eventTypes)},{' '}
      {getEventTypeName(eventType, eventTypes)}
    </TextLine>
    <TextLine>
      Sted: {venue}, {city}
    </TextLine>
    <TextLine>Dato: {uppercaseFirstLetter(moment(startTime).format(LONG_DATE_FORMAT))}</TextLine>
    <TextLine>
      Spilletid: Kl. {moment(startTime).format(TIME_FORMAT)} - kl.{' '}
      {moment(endTime).format(TIME_FORMAT)} (totalt {getPlaytimeText(startTime, endTime)})
    </TextLine>
    {pauseCount > 0 ? (
      <TextLine>
        Antall pauser: {pauseCount} {pauseCount === 1 ? 'pause' : 'pauser'} a. {pauseDuration}{' '}
        {pauseDuration === 1 ? 'minutt' : 'minutter'}
      </TextLine>
    ) : null}
    <TextLine>Antall gjester: {audienceCount}</TextLine>
    <TextLine>{artistName} sørger for pausemusikk mellom settene</TextLine>
  </ContentBlock>
)

EventDetails.propTypes = {
  artistName: PropTypes.string.isRequired,
  eventType: PropTypes.shape({}).isRequired,
  eventTypes: PropTypes.shape({}).isRequired,
  audienceCount: PropTypes.number.isRequired,
  venue: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  pauseCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  pauseDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

EventDetails.defaultProps = {}

export default EventDetails
