import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Heading from '../../../ProfileDetailsHeading/ProfileDetailsHeading'
import { CheckMarkCircleIcon } from '../../../../icons'
import mediaQueries from '../../../../styles/mediaQueries'
import { ProfileDetailsRow } from '../../styles'
import gigplanetTheme from '../../../../styles/gigplanetTheme'

const text = {
  acoustic: 'Spiller akustisk',
  lightSystem: 'Har eget lysanlegg',
  soundSystem: 'Har eget lydanlegg',
  pausemusic: 'Spiller pausemusikk',
  electric: 'Spiller elektrisk',
}
const Facts = styled.div`
  background-color: #f4f4f4;
  padding: ${gigplanetTheme.spacing.medium};

  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.xsmall};
  `};
`
const FactsRow = styled(ProfileDetailsRow)``

const List = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;

  list-style: none;
  ${mediaQueries.mobile`
    flex-direction: column;
  `};
`
const Item = styled.li`
  display: inline-block;
  padding: 5px;
  ${mediaQueries.mobile`
    display: block;
    padding: 0;
  `};
`
const Element = styled.span`
  display: block;
  font-size: 16px;
  padding: 10px 20px;
  ${mediaQueries.mobile`
    font-size: 14px;
    padding: 5px 0;
  `};
`
const Icon = styled.span`
  display: inline-block;
  font-size: 25px;
  line-height: 25px;
  padding-right: 5px;
  vertical-align: top;
  ${mediaQueries.mobile`
    font-size: 20px;
  `};
`
const Text = styled.span`
  display: inline-block;
  line-height: 25px;
  vertical-align: top;
`
const CircleMarkIcon = styled(CheckMarkCircleIcon)`
  width: 25px;
  height: 25px;
  ${mediaQueries.mobile`
    width: 20px;
    height: 20px;
    margin-top: 3px;
  `};
`

const ProfileDetailsFacts = React.memo(({ details }) => (
  <Facts>
    <FactsRow>
      <Heading>Fakta</Heading>
    </FactsRow>
    <FactsRow>
      <List>
        {Object.keys(details).map(fact => {
          if (text[fact] && details[fact] === true) {
            return (
              <Item key={fact}>
                <Element>
                  <Icon>
                    <CircleMarkIcon />
                  </Icon>
                  <Text>{text[fact]}</Text>
                </Element>
              </Item>
            )
          }
          return null
        })}
      </List>
    </FactsRow>
  </Facts>
))

ProfileDetailsFacts.propTypes = {
  details: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default ProfileDetailsFacts
