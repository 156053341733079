import styled from 'styled-components'

export const StyledAnchor = styled.a`
  position: relative;
  opacity: 0;
  height: 0;
  width: 0;
  top: -100px;
  display: block;
  overflow: hidden;
`
