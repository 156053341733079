import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FooterSettings } from '../../../models/FooterSettings'

import gigplanetTheme from '../../../styles/gigplanetTheme'
import mediaQueries from '../../../styles/mediaQueries'

import { JOIN_ARTIST_URL } from '../../../models/Artist'
import { ARTIST_URL } from '../../../models/Support'
import { Button } from '../../../ui'

const RegistryContainer = styled.div`
  padding: 60px ${gigplanetTheme.spacing.xsmall} 100px;
  background: #f8f8f8;
  clear: both;
  display: flex;
  justify-content: center;
`
const RegistryWrapper = styled.div`
  max-width: ${gigplanetTheme.measure.maxWidth};
  display: flex;
  flex-direction: column;
  width: 100%;
`
const RegistryHeading = styled.h2`
  font-size: 25px;
  line-height: 31px;
  font-weight: 300;
  margin-bottom: 45px;
  text-align: center;
`
const Nav = styled.nav`
  display: flex;
  align-items: flex-start;

  ${mediaQueries.mobile`
      flex-wrap: wrap;
  `};
`
const NavColumn = styled.div`
  text-align: left;
  width: 33.3%;
  padding: 0 30px;
  box-sizing: content-box;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;

  &:first-child {
    padding-left: 0;
    border: none;
  }

  &:last-child {
    padding-right: 0;
  }

  ${mediaQueries.mobile`
      width: 100%;
      margin-bottom: 30px;
      border: none;
      padding: 0;
  `};
`

const NavHeading = styled.h2`
  font-size: 13px;
  width: 100%;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: uppercase;
`
const NavLink = styled.a`
  display: block;
  width: 50%;
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 10px;
  text-decoration: none;
  color: #000;

  &:hover {
    text-decoration: underline;
    color: ${gigplanetTheme.element.footer.linkHover};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const FooterPageRegistry = ({ navigation }) => {
  return (
    <RegistryContainer>
      <RegistryWrapper>
        <RegistryHeading>Finn artister til ditt arrangement</RegistryHeading>
        <Nav>
          {navigation.map((column) => (
            <NavColumn key={column._key}>
              {column.columnItems.map((item) => {
                if (item._type === 'columnHeading') {
                  return <NavHeading key={item._key}>{item.heading}</NavHeading>
                }
                if (item._type === 'navigationItem') {
                  return (
                    <NavLink key={item._key} href={item.url}>
                      {item.text}
                    </NavLink>
                  )
                }
                return null
              })}
            </NavColumn>
          ))}
        </Nav>
      </RegistryWrapper>
    </RegistryContainer>
  )
}

export default React.memo(FooterPageRegistry)
