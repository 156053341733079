import * as React from 'react'
import PropTypes from 'prop-types'

import { Description } from './style'

const UserAuthDescription = ({ children }) => <Description>{children}</Description>

UserAuthDescription.propTypes = {
  children: PropTypes.string.isRequired,
}

export default React.memo(UserAuthDescription)
