import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const ProfileDetailsImageGalleryHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 58px;
  text-align: center;
  font-weight: bold;
`

const Header = React.memo(({ imageNumber, imageLength }) => (
  <ProfileDetailsImageGalleryHeader>
    {imageNumber} av {imageLength}
  </ProfileDetailsImageGalleryHeader>
))

Header.propTypes = {
  imageNumber: PropTypes.number.isRequired,
  imageLength: PropTypes.number.isRequired,
}

export default Header
