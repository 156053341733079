import * as React from 'react'
import PropTypes from 'prop-types'

import Button from '../../ui/Button'
import { H1 } from '../../ui/Headings'
import UserAuthIntro from '../../ui/user-auth-intro'
import IntroWrapper from '../../ui/user-auth-intro-wrapper'
import Actions from '../../ui/user-auth-actions'

const UserAuthNewPasswordConfirmation = ({ onClose }) => (
  <>
    <H1>Nytt passord</H1>
    <IntroWrapper>
      <UserAuthIntro>Passordet ditt er oppdatert, og du er logget inn</UserAuthIntro>
    </IntroWrapper>
    <Actions>
      <Button type="button" theme="secondary" onClick={onClose}>
        Ok
      </Button>
    </Actions>
  </>
)

UserAuthNewPasswordConfirmation.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default UserAuthNewPasswordConfirmation
