import styled from 'styled-components'
import gigplanetTheme from '../../../styles/gigplanetTheme'

export const Image = styled.div`
  flex: 1 0 auto;
  max-width: 90px;
  width: 90px;
  height: 60px;
  position: relative;
  overflow: hidden;
  background-position: center;
  background-size: cover;
`

export const Placeholder = styled.div`
  flex: 1 0 auto;
  max-width: 90px;
  width: 90px;
  height: 60px;
  position: relative;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${gigplanetTheme.color.lightGray};

  svg {
    fill: ${gigplanetTheme.color.white};
    height: 30px;
    width: 30px;
  }
`
