import * as React from 'react'
import PropTypes from 'prop-types'
import StyleButton from './style-button'
import { FormatListNumberedIcon, FormatListBulletedIcon } from '../../../../../icons'
import { Controls } from './style'

const BLOCK_TYPES = [
  // { label: 'H1', style: 'header-one' },
  // { label: 'H2', style: 'header-two' },
  // { label: 'H3', style: 'header-three' },
  // { label: 'H4', style: 'header-four' },
  // { label: 'H5', style: 'header-five' },
  // { label: 'H6', style: 'header-six' },
  {
    label: 'Unordered list',
    icon: <FormatListBulletedIcon />,
    style: 'unordered-list-item',
  },
  {
    label: 'Ordered list',
    icon: <FormatListNumberedIcon />,
    style: 'ordered-list-item',
  },
]

const propTypes = {
  editorState: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onToggle: PropTypes.func.isRequired,
}

const EditorBlockStyleControls = props => {
  const { editorState } = props
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  return (
    <Controls>
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          icon={type.icon}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </Controls>
  )
}

EditorBlockStyleControls.propTypes = propTypes

export default EditorBlockStyleControls
