import * as React from 'react'
import PropTypes from 'prop-types'
import { StyledHeaderMenuItem } from './style'

const HeaderMenuListItem = ({ children, inDropdown, ...rest }) => (
  <StyledHeaderMenuItem inDropdown={inDropdown} {...rest}>
    {children}
  </StyledHeaderMenuItem>
)

HeaderMenuListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  inDropdown: PropTypes.bool,
}
HeaderMenuListItem.defaultProps = {
  inDropdown: false,
}

export default HeaderMenuListItem
