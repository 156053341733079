import styled, { css } from 'styled-components'
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const FoldableRichTextContainer = styled.div`
  max-width: 750px;
  width: 100%;
  margin-bottom: ${gigplanetTheme.spacing.small};

  ${mediaQueries.mobile`
    margin-bottom: ${gigplanetTheme.spacing.small};
    padding: 0 ${gigplanetTheme.spacing.xsmall}
  `};

  ${mediaQueries.tabletPortrait`
    padding: 0 ${gigplanetTheme.spacing.small}
  `};
`

export const ToggleButton = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
  width: 100%;
  text-align: left;
`

export const ButtonContent = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Heading = styled.span`
  color: ${gigplanetTheme.component.headings.h1.text};
  font-size: 20px;
  margin: 0;
  line-height: 20px;
  font-weight: 600;

  ${mediaQueries.mobile`
    font-size: 18px;
    font-weight: 600;
  `};
`

export const IconWrapper = styled.span`
  transition: transform 0.2s ease-in-out;

  svg {
    height: 30px;
    width: 30px;
    fill: ${gigplanetTheme.color.darkGray};
  }

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      transform: rotate(180deg);
    `};
`

export const Content = styled.div`
  opacity: 0;
  transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  overflow: hidden;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      opacity: 1;
    `};
`

export const RichTextWrapper = styled.div`
  ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: 10px;
    `};

  h1 {
    color: ${gigplanetTheme.component.headings.h1.text};
    font-size: 20px;
    margin: 20px 0 5px;
    text-align: left;
  }

  h2 {
    color: ${gigplanetTheme.component.headings.h1.text};
    font-size: 18px;
    margin: 20px 0 5px;
    text-align: left;
  }

  h3 {
    color: ${gigplanetTheme.component.headings.h1.text};
    font-size: 16px;
    margin: 20px 0 5px;
    text-align: left;
  }

  ul {
    margin: 0;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 16px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    font-size: 16px;
    line-height: 18px;
  }

  img {
    max-width: 100%;
  }

  a {
    color: ${gigplanetTheme.color.primary};
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`
