import * as React from 'react'
import styled, { keyframes } from 'styled-components'

import Loader from '../../../../ui/Loader'
import noScroll from '../../../../libs/noScroll'
import gigplanetTheme from '../../../../styles/gigplanetTheme'

const enterBackdropAnimation = keyframes`
  0%   {opacity: 0; }
  100% {opacity: 1; }
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: ${gigplanetTheme.zIndex.submitting};
  animation: ${enterBackdropAnimation} 200ms linear;
`

export const LoaderWrapper = styled.div`
  background: ${gigplanetTheme.color.white};
  padding: 2rem;
  border-radius: 6px;
`

const GigRequestFormSubmitting = React.memo(() => {
  React.useEffect(() => {
    noScroll.on()
    return () => {
      noScroll.off()
    }
  }, [])

  return (
    <Container>
      <LoaderWrapper>
        <Loader text="Sender forespørsel" whiteTheme />
      </LoaderWrapper>
    </Container>
  )
})

export default GigRequestFormSubmitting
