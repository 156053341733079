/* global window */
import * as React from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash.throttle'

import { Container, Placeholder, Bar, Progress, Label } from './style'

class ProgressBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasFixedPosition: false,
    }
    this.progressBarPlaceholderRef = React.createRef()
  }

  componentDidMount() {
    const { isSticky } = this.props
    if (isSticky) {
      this.checkScrollPositionThrottled = throttle(this.checkScrollPosition, 10, {
        leading: false,
      })
      window.addEventListener('scroll', this.checkScrollPositionThrottled)
      this.checkScrollPosition()
    }
  }

  componentWillUnmount() {
    const { isSticky } = this.props
    if (isSticky) {
      window.removeEventListener('scroll', this.checkScrollPositionThrottled)
    }
  }

  checkScrollPosition = () => {
    let rect = null
    if (this.progressBarPlaceholderRef && this.progressBarPlaceholderRef.current) {
      rect = this.progressBarPlaceholderRef.current.getBoundingClientRect()
      this.setState({
        hasFixedPosition: rect.top < 0,
      })
    }
  }

  render() {
    const { progress, isPercentage, thin, isSticky, hideLabel } = this.props
    const { hasFixedPosition } = this.state
    let width = progress * 100
    if (isPercentage) {
      width = progress
    }

    width = Math.round(width * 100) / 100
    if (width > 100) {
      width = 100
    }

    return (
      <Container isThin={thin}>
        <Placeholder ref={this.progressBarPlaceholderRef} isSticky={isSticky && hasFixedPosition} />
        <Bar role="progressbar" isThin={thin} isSticky={isSticky && hasFixedPosition}>
          <Progress
            style={{ width: `${width}%` }}
            isThin={thin}
            isSticky={isSticky && hasFixedPosition}
            isSuccess={width >= 100}
          />
          {!hideLabel && !thin ? <Label style={{ width: `${width}%` }}>{width}%</Label> : null}
        </Bar>
      </Container>
    )
  }
}

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  hideLabel: PropTypes.bool,
  isPercentage: PropTypes.bool,
  isSticky: PropTypes.bool,
  thin: PropTypes.bool,
}

ProgressBar.defaultProps = {
  isPercentage: false,
}

export default ProgressBar
