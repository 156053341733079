import * as React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import getGlobals from '../../helpers/getGlobals'
import isExternalLink from '../../helpers/is-external-link'
import extractUrlPath from '../../helpers/extract-url-path'
import { OpenInNewIcon } from '../../icons'

const ExternalLink = styled.a`
  display: inline-flex;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
    margin-left: 5px;
  }
`

const InternalLink = styled(Link)``

const LinkRenderer = React.memo(({ mark, children }) => {
  // eslint-disable-next-line
  const href = mark.href
  if (!href || !children) return null
  // eslint-disable-next-line
  const isExternal = isExternalLink(href)
  if (isExternal) {
    return (
      <ExternalLink href={href} target="_blank">
        <span>{children}</span>
        <OpenInNewIcon />
      </ExternalLink>
    )
  }
  return <InternalLink to={extractUrlPath(href)}>{children}</InternalLink>
})

LinkRenderer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  mark: PropTypes.shape({
    href: PropTypes.string,
  }).isRequired,
}

const serializers = { marks: { link: LinkRenderer } }

const SanityHtmlBody = ({ body, imageOptions }) => (
  <BlockContent
    serializers={serializers}
    projectId={getGlobals().SANITY_PROJECT_ID}
    dataset={getGlobals().SANITY_DATASET}
    blocks={body}
    imageOptions={imageOptions}
  />
)

SanityHtmlBody.propTypes = {
  body: PropTypes.arrayOf(PropTypes.shape()),
  imageOptions: PropTypes.shape(),
}

SanityHtmlBody.defaultProps = {
  imageOptions: { w: 650, h: 400, fit: 'max' },
}

export default SanityHtmlBody
