import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { compose } from 'recompose'

import { Container, Content, Text } from './style'
import { H1 } from '../../ui/Headings'
import Button from '../../ui/Button'

const AccessDenied = ({ staticContext, currentUser, location: { pathname } }) => {
  staticContext.status = 403
  const login = !currentUser ? (
    <Button theme="secondary" to={`/login?url=${encodeURIComponent(pathname)}`}>
      Forsøk å logge inn
    </Button>
  ) : null

  return (
    <Container>
      <Content>
        <H1>Ikke tilgang</H1>
        <Text>Du har ikke tilgang til denne siden</Text>
        {login}
      </Content>
    </Container>
  )
}

AccessDenied.propTypes = {
  currentUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  staticContext: PropTypes.shape({}),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

AccessDenied.defaultProps = {
  currentUser: null,
  staticContext: {},
}

export default compose(withRouter)(AccessDenied)
