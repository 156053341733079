/* globals Image */
import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  img {
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;

    ${({ landscape }) =>
      landscape &&
      css`
        width: 100%;
        height: auto;
      `};

    ${({ portrait }) =>
      portrait &&
      css`
        width: auto;
        height: 100%;
      `};
  }
`

const Status = {
  PENDING: 'pending',
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
}

class ImageLoader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: props.src ? Status.LOADING : Status.PENDING,
      orientation: 'LANDSCAPE',
    }
  }

  componentDidMount() {
    const { status } = this.state
    if (status === Status.LOADING) {
      this.createLoader()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { src } = this.props
    if (src !== nextProps.src) {
      this.setState({
        status: nextProps.src ? Status.LOADING : Status.PENDING,
      })
    }
  }

  componentDidUpdate() {
    const { status } = this.state
    if (status === Status.LOADING && !this.img) {
      this.createLoader()
    }
  }

  componentWillUnmount() {
    this.destroyLoader()
  }

  createLoader = () => {
    const { src, srcSet } = this.props
    this.destroyLoader() // We can only have one loader at a time.

    this.img = new Image()
    this.img.onload = this.handleLoad
    this.img.onerror = this.handleError
    this.img.src = src
    console.log('this.img', this.img)

    if (srcSet && srcSet !== '') {
      this.img.srcset = srcSet
    }
  }

  destroyLoader = () => {
    if (this.img) {
      this.img.onload = null
      this.img.onerror = null
      this.img = null
    }
  }

  handleLoad = event => {
    const { onLoad } = this.props
    let orientation = 'LANDSCAPE'
    if (this.img) {
      if (this.img.naturalHeight > this.img.naturalWidth) {
        orientation = 'PORTRAIT'
      }
    }
    this.destroyLoader()
    this.setState({ status: Status.LOADED, orientation })

    if (onLoad) onLoad(event)
  }

  handleError = error => {
    const { onError } = this.props
    this.destroyLoader()
    this.setState({ status: Status.FAILED })

    if (onError) onError(error)
  }

  renderImg = () => {
    const { src, imgProps, srcSet } = this.props
    const props = { src, srcSet }
    Object.keys(imgProps).forEach((k, i) => {
      if (imgProps[k]) {
        props[k] = imgProps[k]
      }
    })
    return <img {...props} /> // eslint-disable-line jsx-a11y/alt-text
  }

  render() {
    const { children, preloader } = this.props
    const { status, orientation } = this.state
    let content = null

    switch (status) {
      case Status.LOADED:
        content = this.renderImg()
        break

      case Status.FAILED:
        if (children) content = children
        break

      default:
        if (preloader) content = preloader()
        break
    }

    return (
      <ImageContainer landscape={orientation === 'LANDSCAPE'} portrait={orientation === 'PORTRAIT'}>
        {content}
      </ImageContainer>
    )
  }
}

ImageLoader.propTypes = {
  wrapper: PropTypes.func,
  preloader: PropTypes.func,
  src: PropTypes.string,
  srcSet: PropTypes.string,
  onLoad: PropTypes.func,
  onError: PropTypes.func,
  imgProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.element.isRequired,
}

ImageLoader.defaultProps = {
  srcSet: '',
  onLoad: () => {},
  onError: () => {},
  imgProps: {},
  src: '',
  preloader: () => {},
}

export default ImageLoader
