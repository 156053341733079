import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getCounties } from '../../../../../stores/Locations/LocationsReducer'
import { Select } from '../../../../../ui/Form'

const mapStateToProps = state => ({
  counties: getCounties(state),
})

const mapDispatchToProps = () => ({})

const LocationFilter = React.memo(({ filterValue, onSetFilter, counties }) => {
  const performLocationOptions = []

  ;(counties || []).forEach(county => {
    performLocationOptions.push({
      value: county.id,
      name: county.name,
    })
  })

  return (
    <Select
      id="frontpage-search-location"
      name="frontpage-search-location"
      options={performLocationOptions}
      label="Sted"
      hideLabel
      value={filterValue}
      onChange={e => {
        onSetFilter(e.target.value)
      }}
      actionLabel="Viser alle steder"
    />
  )
})

LocationFilter.propTypes = {
  filterValue: PropTypes.string,
  onSetFilter: PropTypes.func.isRequired,
  counties: PropTypes.arrayOf(PropTypes.shape({})),
}
LocationFilter.defaultProps = {
  filterValue: '',
  counties: [],
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationFilter)
