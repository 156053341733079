import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Heading = styled.h2`
  font-size: 18.5px;
  line-height: 22px;
  font-weight: 600;
  margin: 0;
  padding: 10px 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ArtistCardHeading = ({ children }) => <Heading>{children}</Heading>

ArtistCardHeading.propTypes = {
  children: PropTypes.string.isRequired,
}
export default ArtistCardHeading
