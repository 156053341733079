import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const EmailOutlineIconPurple = (props) => (
  <SVGWrapper width="26" height="20" viewBox="0 0 26 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.004 18C3.561 18 3.153 17.85 2.821 17.604C3.594 16.902 7.581 13.278 10.309 10.798C11.128 11.322 12.065 11.594 13 11.594C13.936 11.594 14.872 11.322 15.692 10.798L23.179 17.604C22.847 17.85 22.44 18 21.996 18H4.004ZM2 15.648V4.004C2 3.805 2.038 3.617 2.092 3.436L8.763 9.5C7.498 10.65 5.997 12.015 4.672 13.219C3.574 14.217 2.609 15.095 2 15.648ZM21.996 2C22.153 2 22.305 2.022 22.451 2.057L15.02 8.812C14.445 9.334 13.726 9.593 13 9.594C12.274 9.593 11.555 9.334 10.981 8.812L3.549 2.057C3.695 2.022 3.847 2 4.004 2H21.996ZM24 4.004V15.648L17.237 9.5L23.908 3.436C23.962 3.617 24 3.805 24 4.004ZM21.996 0H4.004C1.793 0 0 1.793 0 4.004V15.996C0 18.207 1.793 20 4.004 20H21.996C24.207 20 26 18.207 26 15.996V4.004C26 1.793 24.207 0 21.996 0Z"
      fill="currentColor"
    />
  </SVGWrapper>
)

export default EmailOutlineIconPurple
