import * as React from 'react'
import PropTypes from 'prop-types'

import { imageUrlFor } from '../../helpers/sanity-image-builder'

import { ResponsiveImage } from './style'

const Image = React.memo(({ image, altText, width, height, children }) => {
  let src = imageUrlFor(image)
  if (width) src = src.width(width)
  if (height) src = src.height(height)

  return (
    <picture>
      {children}
      <ResponsiveImage src={src} alt={altText} />
    </picture>
  )
})

Image.propTypes = {
  image: PropTypes.shape({}).isRequired,
  altText: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
}

Image.defaultProps = {
  width: 0,
  height: 0,
}

export default Image
