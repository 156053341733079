import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const TwitterIcon = props => (
  <SVGWrapper width="416" height="448" viewBox="0 0 416 448" {...props}>
    <path d="M405 102c-11 16-24.75 30.25-40.5 41.75 0.25 3.5 0.25 7 0.25 10.5 0 106.75-81.25 229.75-229.75 229.75-45.75 0-88.25-13.25-124-36.25 6.5 0.75 12.75 1 19.5 1 37.75 0 72.5-12.75 100.25-34.5-35.5-0.75-65.25-24-75.5-56 5 0.75 10 1.25 15.25 1.25 7.25 0 14.5-1 21.25-2.75-37-7.5-64.75-40-64.75-79.25v-1c10.75 6 23.25 9.75 36.5 10.25-21.75-14.5-36-39.25-36-67.25 0-15 4-28.75 11-40.75 39.75 49 99.5 81 166.5 84.5-1.25-6-2-12.25-2-18.5 0-44.5 36-80.75 80.75-80.75 23.25 0 44.25 9.75 59 25.5 18.25-3.5 35.75-10.25 51.25-19.5-6 18.75-18.75 34.5-35.5 44.5 16.25-1.75 32-6.25 46.5-12.5z" />
  </SVGWrapper>
)

export default TwitterIcon
