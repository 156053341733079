import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { trackEvent } from '../../../../libs/analytics'
import generateUrlParamsFromObject from '../../../../helpers/generate-url-params-from-object'
import extractUrlPath from '../../../../helpers/extract-url-path'

import { getCounties } from '../../../../stores/Locations/LocationsReducer'

import {
  Container,
  Intro,
  SearchWrapper,
  BackgroundWrapper,
  Heading,
  HighlightedArtist,
  HighlightedArtistContent,
  HighlightedArtistHeading,
  HighlightedArtistLink,
  ArtistImage,
  TextColumnsWrapper,
  TextColumnsContainer,
  TextColumn,
  TextColumnHeading,
  TextColumnText,
} from './style'
import SearchForm from './components/search-form'
import HeroWave from './components/wave'

import { mediaQueriesDefinitions } from '../../../../styles/mediaQueries'
import { imageUrlFor } from '../../../../helpers/sanity-image-builder'
import SanityImage from '../../../sanity-image'

import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'
import useOptions from '../../../../hooks/useOptions'

const HeroSectionBlock = React.memo(
  ({ title = '', intro = '', theme, artists = [], textColumns = [], anchorId = '' }) => {
    const history = useHistory()
    const { eventTypes } = useOptions()
    const counties = useSelector(getCounties)
    const [eventType, setEventType] = React.useState('')
    const [location, setLocation] = React.useState('')
    const [randomNumber, setRandomNumber] = useState(undefined)

    useEffect(() => {
      setRandomNumber(Math.floor(Math.random() * artists.length))
    }, [])

    const handleEventTypeChange = (e) => {
      setEventType(e.currentTarget.value)
      trackEvent(
        'landingpage',
        'landingpage-block-header-changed-event-type',
        e.currentTarget.value,
      )
      history.push(
        `/s${generateUrlParamsFromObject({
          eventType: e.currentTarget.value,
          location,
        })}`,
      )
    }

    const handlePerformLocationChange = (e) => {
      setLocation(e.currentTarget.value)
      trackEvent(
        'landingpage',
        'landingpage-block-header-changed-perform-location',
        e.currentTarget.value,
      )
      history.push(
        `/s${generateUrlParamsFromObject({
          eventType,
          location: e.currentTarget.value,
        })}`,
      )
    }

    const handleSubmitSearch = () => {
      history.push(
        `/s${generateUrlParamsFromObject({
          eventType,
          location,
        })}`,
      )
      trackEvent('landingpage', 'landingpage-block-header-submitted-search', 'Søk', {
        eventType,
        location,
      })
    }
    const randomArtist = artists[randomNumber]
    return (
      <div>
        <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
          <BackgroundWrapper>
            <HeroWave />
          </BackgroundWrapper>
          <Container>
            {title ? (
              <Heading dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(title) }} />
            ) : null}
            {intro ? <Intro>{intro}</Intro> : null}
            {randomArtist ? (
              <HighlightedArtist
                narrow={
                  randomArtist.image && randomArtist.imageMetadata.dimensions.aspectRatio < 0.7
                    ? 1
                    : 0
                }
                medium={
                  randomArtist.image &&
                  randomArtist.imageMetadata.dimensions.aspectRatio < 0.7 &&
                  randomArtist.imageMetadata.dimensions.aspectRatio > 0.6
                    ? 1
                    : 0
                }
              >
                <ArtistImage>
                  <SanityImage
                    width={600}
                    image={randomArtist.image}
                    altText={randomArtist.image.altText}
                  >
                    <source
                      media={mediaQueriesDefinitions.mobileDown}
                      srcSet={imageUrlFor(randomArtist.image).width(600)}
                    />
                    <source
                      media={mediaQueriesDefinitions.tabletLandscapeDown}
                      srcSet={imageUrlFor(randomArtist.image).width(600)}
                    />
                    <source
                      media={mediaQueriesDefinitions.desktopDown}
                      srcSet={imageUrlFor(randomArtist.image).width(600)}
                    />
                  </SanityImage>
                </ArtistImage>
                <HighlightedArtistContent
                  color={
                    randomArtist.imageMetadata
                      ? randomArtist.imageMetadata.palette.vibrant.background
                      : undefined
                  }
                  medium={
                    randomArtist.image &&
                    randomArtist.imageMetadata.dimensions.aspectRatio < 0.7 &&
                    randomArtist.imageMetadata.dimensions.aspectRatio > 0.6
                      ? 1
                      : 0
                  }
                >
                  <HighlightedArtistHeading>Fremhevet Artist</HighlightedArtistHeading>
                  <HighlightedArtistLink to={extractUrlPath(randomArtist.url)}>
                    {randomArtist.name}
                  </HighlightedArtistLink>
                </HighlightedArtistContent>
              </HighlightedArtist>
            ) : null}
            <SearchWrapper>
              <SearchForm
                eventType={eventType}
                eventTypes={eventTypes}
                onEventTypeChange={handleEventTypeChange}
                counties={counties}
                performLocation={location}
                onPerformLocationChange={handlePerformLocationChange}
                onSubmitSearch={handleSubmitSearch}
              />
            </SearchWrapper>
          </Container>
        </LandingPageBlockWrapper>
        <TextColumnsWrapper>
          <TextColumnsContainer>
            {textColumns
              ? textColumns.map((column) => {
                  return (
                    <TextColumn key={column._key}>
                      <TextColumnHeading>{column.heading}</TextColumnHeading>
                      <TextColumnText>{column.text}</TextColumnText>
                    </TextColumn>
                  )
                })
              : null}
          </TextColumnsContainer>
        </TextColumnsWrapper>
      </div>
    )
  },
)

HeroSectionBlock.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.string.isRequired,
  anchorId: PropTypes.string,
  intro: PropTypes.string,
  artists: PropTypes.array,
  textColumns: PropTypes.array,
}

export default HeroSectionBlock
