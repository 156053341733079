import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { GigRequestEventplannerShape } from '../../models/GigRequest'
import { getEventTypes } from '../../stores/Options/OptionsReducer'

import DashboardRequests from './components/dashboard-requests'

const mapStateToProps = state => ({
  eventTypes: getEventTypes(state),
})

const mapDispatchToProps = () => ({})

const EventplannerDashboardRequestsContainer = ({ eventTypes, requests }) => (
  <DashboardRequests eventTypes={eventTypes} requests={requests} />
)

EventplannerDashboardRequestsContainer.propTypes = {
  // normal props
  requests: PropTypes.arrayOf(GigRequestEventplannerShape).isRequired,

  // state props
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}
EventplannerDashboardRequestsContainer.defaultProps = {}

const EventplannerDashboardRequestsContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventplannerDashboardRequestsContainer)

export default EventplannerDashboardRequestsContainerWrapper
