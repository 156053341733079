import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import PublicGenresList from '../../../containers/PublicGenresList/PublicGenresList'

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`

const ArtistCardGenres = ({ genres }) => (
  <Container>
    {genres && genres.length > 0 ? <PublicGenresList genres={genres} pipes /> : null}
  </Container>
)

ArtistCardGenres.propTypes = {
  genres: PropTypes.arrayOf(PropTypes.string),
}

ArtistCardGenres.defaultProps = {
  genres: [],
}
export default ArtistCardGenres
