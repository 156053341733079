import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router'

import { getCurrentUser } from '../../../stores/CurrentUser/CurrentUserReducer'

import MetaData from '../../../components/MetaData'
import Help, { EVENTPLANNER } from '../../../components/Help'

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
})

const mapDispatchToProps = () => ({})

const EventplannerHelpRoute = (props) => (
  <>
    <MetaData title="Hjelp" description="Her kan du få hjelp" nopin />
    <Help audience={EVENTPLANNER} />
  </>
)

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(EventplannerHelpRoute)
