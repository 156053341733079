import * as React from 'react'
import PropTypes from 'prop-types'

import Layout from '../../layouts/main-layout'
import SystemMessageWrapper from '../../ui/system-message-wrapper'

import UserAuthVerifyEmailSuccess from '../../components/user-auth-verify-email-success'

const VerifyEmailSuccessView = ({ successUrl, showContinue = false }) => (
  <Layout>
    <SystemMessageWrapper>
      <UserAuthVerifyEmailSuccess successUrl={successUrl} showContinue={showContinue} />
    </SystemMessageWrapper>
  </Layout>
)

VerifyEmailSuccessView.propTypes = {
  successUrl: PropTypes.string.isRequired,
  showContinue: PropTypes.bool,
}

export default React.memo(VerifyEmailSuccessView)
