import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const VolumeUpIcon = props => (
  <SVGWrapper width="256" height="256" viewBox="0 0 256 256" {...props}>
    <path d="M149.5 34.5c43 9.5 74.5 48 74.5 93.5s-31.5 84-74.5 93.5v-22c31-9 53-37.5 53-71.5s-22-62.5-53-71.5v-22zM176 128c0 19-10.5 35-26.5 43v-86c16 8 26.5 24 26.5 43zM32 96h42.5l53.5-53.5v171l-53.5-53.5h-42.5v-64z" />
  </SVGWrapper>
)

export default VolumeUpIcon
