import * as React from 'react'
import { useSelector } from 'react-redux'

import { getCurrentUser as getCurrentUserSelector } from '../stores/CurrentUser/CurrentUserReducer'

const useCurrentUser = () => {
  const currentUser = useSelector(getCurrentUserSelector)
  return currentUser
}

export default useCurrentUser
