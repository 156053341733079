import styled from 'styled-components'
import { Link } from 'react-router-dom'

import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const Container = styled.div`
  width: 100%;
  height: ${({ isLow }) => (isLow ? '320px' : '500px')};
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0;

  ${mediaQueries.mobile`
    height: ${({ isLow }) => (isLow ? '300px' : '450px')};
  `}

  ${mediaQueries.tabletLandscape`
    height: ${({ isLow }) => (isLow ? '350px' : '600px')};
  `};

  ${mediaQueries.desktopUp`
    height: ${({ isLow }) => (isLow ? '400px' : '700px')};
  `};
`

export const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: ${({ isLow }) => (isLow ? '320px' : '500px')};

  ${mediaQueries.mobile`
    height: ${({ isLow }) => (isLow ? '300px' : '450px')};
  `}

  ${mediaQueries.tabletLandscape`
    height: ${({ isLow }) => (isLow ? '350px' : '600px')};
  `};

  ${mediaQueries.desktopUp`
    height: ${({ isLow }) => (isLow ? '400px' : '700px')};
  `};

  img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: left center;
  }
`

export const ContentWrapper = styled.div`
  padding: ${gigplanetTheme.spacing.large};
  position: absolute;
  width: 100%;
  height: ${({ isLow }) => (isLow ? '320px' : '500px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: ${gigplanetTheme.measure.maxWidth};

  ${mediaQueries.mobile`
    height: ${({ isLow }) => (isLow ? '300px' : '450px')};
    padding: ${gigplanetTheme.spacing.medium} ${gigplanetTheme.spacing.xsmall};    
  `};

  ${mediaQueries.tabletLandscape`
    height: ${({ isLow }) => (isLow ? '350px' : '600px')};
    padding: ${gigplanetTheme.spacing.xlarge} ${gigplanetTheme.spacing.large};
  `};

  ${mediaQueries.desktopUp`
    height: ${({ isLow }) => (isLow ? '400px' : '700px')};
    padding: ${gigplanetTheme.spacing.xlarge} ${gigplanetTheme.spacing.large};
  `};
`

export const Heading = styled.h1`
  color: white;
  font-size: 36px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;

  strong {
    font-weight: 700;
  }

  ${mediaQueries.mobile`
    font-size: 30px
  `}

  ${mediaQueries.tabletLandscape`
    font-size: 50px
  `};

  ${mediaQueries.desktopUp`
    font-size: 64px
  `};
`

export const Intro = styled.p`
  color: white;
  padding: 0;
  padding-top: ${gigplanetTheme.spacing.large};
  margin: 0;
  max-width: 800px;

  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;

  ${mediaQueries.mobile`
    font-size: 16px;
    padding-top: ${gigplanetTheme.spacing.small};
  `};

  ${mediaQueries.tabletPortraitUp`
    font-size: 20px;
  `};

  ${mediaQueries.tabletLandscapeUp`
    font-size: 24px;
  `};

  br {
    ${mediaQueries.mobile`
      display: none;
    `};
  }
`

export const Button = styled(Link)`
  background: white;
  color: ${gigplanetTheme.color.darkGray};
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 4px;
  line-height: 22px;

  ${mediaQueries.mobile`
    font-size: 16px;
  `};
`

export const SecondaryButton = styled(Link)`
  background: transparent;
  font-size: 16px;
  color: ${gigplanetTheme.color.white};
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  padding: 10px 0;
  border-radius: 4px;
  line-height: 22px;

  ${mediaQueries.mobile`
    font-size: 14px;
    padding: 0;
  `};
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding-top: calc(${gigplanetTheme.spacing.large} + 8px);

  a {
    &:first-child {
      margin-right: ${gigplanetTheme.spacing.small};
    }
  }

  ${mediaQueries.mobile`
    flex-direction: column;  
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: calc(${gigplanetTheme.spacing.small} + 6px);
    
    a {
      &:first-child {
        margin-bottom: ${gigplanetTheme.spacing.xsmall};
      }
    }
  `};
`

export const Placeholder = styled.div`
  width: 100%;
  height: ${({ isLow }) => (isLow ? '320px' : '500px')};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${gigplanetTheme.color.gray};

  svg {
    color: ${gigplanetTheme.color.darkGray};
    height: 98px;
    width: 98px;
  }

  ${mediaQueries.mobile`
    height: ${({ isLow }) => (isLow ? '300px' : '450px')};
  `}

  ${mediaQueries.tabletLandscape`
    height: ${({ isLow }) => (isLow ? '350px' : '600px')};
  `};

  ${mediaQueries.desktopUp`
    height: ${({ isLow }) => (isLow ? '400px' : '700px')};
  `};
`
