import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { EVENTPLANNER_URL } from '../../../../models/Eventplanner'
import { GigRequestFormReceipt } from '../../../../components/GigRequestForm'

const ProfileDetailsGigRequestFormReceiptRoute = React.memo(() => {
  const history = useHistory()

  const handleNext = () => {
    history.push(EVENTPLANNER_URL)
  }

  return <GigRequestFormReceipt onNext={handleNext} />
})

export default ProfileDetailsGigRequestFormReceiptRoute
