import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { useSelector, connect } from 'react-redux'

import MetaData from '../../components/MetaData'

import {
  fetchPredefinedPageIfNeededAction,
  fetchLandingPageIfNeededAction,
} from '../../stores/cms-content/actions'
import {
  makeGetLandingPageContentSelector,
  makeGetLandingPageLoadingSelector,
  makeGetLandingPageLoadedSelector,
  getSiteSettingsSelector,
} from '../../stores/cms-content/reducer'
import { getUserArtists, getFavoriteArtists } from '../../stores/Artist/ArtistReducer'
import { getMyEventplannerRequests } from '../../stores/gig-request-eventplanner/reducer'
import { imageUrlFor } from '../../helpers/sanity-image-builder'

import Layout from '../../layouts/main-layout'
import PageLoader from '../../components/PageLoader'
import LandingPageWrapper from '../../ui/landing-page-wrapper'
import LandingPageBlocks from '../../components/LandingPageBlocks'
import PageNotFound from '../../components/PageNotFound'
import useCurrentUser from '../../hooks/use-current-user'

const makeMapStateToProps = () => {
  const getLandingPageContentSelector = makeGetLandingPageContentSelector()
  const getLandingPageLoadingSelector = makeGetLandingPageLoadingSelector()
  const getLandingPageLoadedSelector = makeGetLandingPageLoadedSelector()
  return (state, props) => ({
    content: getLandingPageContentSelector(state, props.type || props.slug),
    loading: getLandingPageLoadingSelector(state, props.type || props.slug),
    loaded: getLandingPageLoadedSelector(state, props.type || props.slug),
    siteSettings: getSiteSettingsSelector(state),
  })
}

const mapDispatchToProps = (dispatch) => ({
  fetchPredefinedPageContent: (type) => {
    dispatch(fetchPredefinedPageIfNeededAction(type))
  },
  fetchLandingPageContent: (slug) => {
    dispatch(fetchLandingPageIfNeededAction(slug))
  },
})

const LandingPageContainer = React.memo(
  ({
    fetchPredefinedPageContent,
    fetchLandingPageContent,
    showFavorites,
    removeLayout,
    type,
    slug,
    content,
    siteSettings,
    loaded,
    loading,
  }) => {
    const currentUser = useCurrentUser()
    const userArtists = useSelector(getUserArtists)
    const favoriteArtists = useSelector(getFavoriteArtists)
    const eventplannerGigRequest = useSelector(getMyEventplannerRequests)

    React.useEffect(() => {
      if (type) {
        fetchPredefinedPageContent(type)
      } else {
        fetchLandingPageContent(slug)
      }
    }, [type, slug])

    if (loading || !loaded) {
      if (removeLayout) {
        return <PageLoader text="Henter innhold" />
      }
      return (
        <Layout>
          <MetaData title="Henter innhold" description="" nopin />
          <PageLoader text="Henter innhold" />
        </Layout>
      )
    }

    if (!content || (!type && !slug)) {
      return (
        <Layout>
          <MetaData
            title="Finner ikke siden"
            description="Vi kan ikke finne siden du forsøker å vise"
          />
          <PageNotFound />
        </Layout>
      )
    }
    const isLoggedIn = {}.hasOwnProperty.call(currentUser, 'id')
    const hasArtists = userArtists && userArtists.length > 0
    const hasFavorites = favoriteArtists && favoriteArtists.length > 0
    const isEventplanner = eventplannerGigRequest && eventplannerGigRequest.length > 0

    let metaTitle = siteSettings ? siteSettings.metaTitle : ''
    if (content && content.metaTitle) {
      metaTitle = content.metaTitle
    }

    let metaDescription = ''
    if (content && content.metaDescription) {
      metaDescription = content.metaDescription
    } else if (siteSettings && siteSettings.metaDescription) {
      metaDescription = siteSettings.metaDescription
    }

    let metaImage = ''
    if (content.metaImage) {
      metaImage = imageUrlFor(content.metaImage).width(1200).height(630).quality(60).url()
    } else if (siteSettings && siteSettings.metaImage) {
      metaImage = imageUrlFor(siteSettings.metaImage).width(1200).height(630).quality(60).url()
    }

    if (removeLayout) {
      return (
        <LandingPageBlocks
          blocks={content && content.blocks ? content.blocks : null}
          isLoggedIn={isLoggedIn}
          hasFavorites={hasFavorites}
          hasArtists={hasArtists}
          isEventplanner={isEventplanner}
        />
      )
    }
    return (
      <Layout
        simpleMenu={content.menu === 'simple'}
        noMenu={content.menu === 'no-menu'}
        fullInvertedMenu={content.menu === 'full-inverted'}
        stickyMenu={content.menu === 'sticky-overlay-inverted'}
        invertedMenu={content.menu === 'sticky-overlay-inverted'}
        overlayMenu={content.menu === 'sticky-overlay-inverted'}
        includeLayout={content}
        showFavorites={showFavorites}
        showSearch
      >
        <MetaData title={metaTitle} description={metaDescription} image={metaImage} nopin />
        <LandingPageBlocks
          blocks={content && content.blocks ? content.blocks : null}
          isLoggedIn={isLoggedIn}
          hasFavorites={hasFavorites}
          hasArtists={hasArtists}
          isEventplanner={isEventplanner}
        />
      </Layout>
    )
  },
)

LandingPageContainer.propTypes = {
  // normal props
  type: PropTypes.string,
  slug: PropTypes.string,
  removeLayout: PropTypes.bool,
  showFavorites: PropTypes.bool,

  // state props
  content: PropTypes.shape({
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
  }),
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  siteSettings: PropTypes.shape({}).isRequired,

  // dispatch props
  fetchPredefinedPageContent: PropTypes.func.isRequired,
  fetchLandingPageContent: PropTypes.func.isRequired,
}

LandingPageContainer.defaultProps = {
  type: '',
  slug: '',
  removeLayout: false,
  showFavorites: false,
}

export default compose(connect(makeMapStateToProps, mapDispatchToProps))(LandingPageContainer)
