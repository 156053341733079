import styled from 'styled-components'

import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${gigplanetTheme.spacing.large};
  padding-left: ${gigplanetTheme.spacing.medium};
  padding-right: ${gigplanetTheme.spacing.medium};
  width: 100%;

  ${mediaQueries.mobile`
    padding-top: ${gigplanetTheme.spacing.medium};
    padding-bottom: ${gigplanetTheme.spacing.medium};
    padding-left: 0;
    padding-right: 0;
  `};
`

export const ArtistListWrapper = styled.div`
  margin-top: 32px;
  width: 100%;

  ${mediaQueries.mobile`
    margin-top: 16px;
  `};
`

export const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 32px;

  ${mediaQueries.mobile`
    margin-top: 16px;
  `};
`
export const ReadMoreWrapper = styled.div`
  text-align: center;
`
