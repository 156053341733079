import * as React from 'react'
import PropTypes from 'prop-types'

import { compose } from 'recompose'
import { connect } from 'react-redux'

import styled, { css } from 'styled-components'

import { Link } from 'react-router-dom'
import {
  SearchIconMobile,
  HeartIconFilled,
  ControlPanelIcon,
  ArtistProfilesIcon,
  GigplanetIcon,
} from '../../icons'

import { shutdownIntercom } from './util'
import { getLogoutUrl } from '../../models/Auth'

import RequestsMenuItem from './components/requests-menu-item'
import UserAvatar from '../../ui/UserAvatar'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

import { CONTROLPANEL_URL, ARTIST_PROFILES_URL } from '../../models/Controlpanel'
import { USER_ROLE, USER_PROFILE_URL } from '../../models/CurrentUser'
import getGlobals from '../../helpers/getGlobals'
import { getCurrentUser } from '../../stores/CurrentUser/CurrentUserReducer'

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
})

const mapDispatchToProps = (dispatch) => ({})

const LinkWrapper = React.forwardRef(({ to, children, ...rest }, ref) => (
  <Link to={to} {...rest} ref={ref}>
    {children}
  </Link>
))

const UtilityNavItem = styled(LinkWrapper)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 1.75rem;
  color: ${gigplanetTheme.color.primary};
  ${mediaQueries.tabletPortraitUp`
    margin: 0;
  `};
  &:hover {
    color: #924dff;
  }
`
const UtilityNavAnchor = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 1.75rem;
  color: ${gigplanetTheme.color.primary};
  ${mediaQueries.tabletPortraitUp`
    margin: 0;
  `};
  &:hover {
    color: #924dff;
  }
`
const UtilityNavIcon = styled.div`
  position: relative;
  height: 24px;
  width: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const UtilityNavItemText = styled.span`
  padding-left: 0.75rem;
  font-size: 17px;
  line-height: 17px;
  font-weight: 500;
  display: none;
  ${mediaQueries.mobile`
    display: block;
  `};
  ${mediaQueries.desktopUp`
    display: block;
  `};
`
const UtilityNavItemTextMobile = styled(UtilityNavItemText)`
  ${mediaQueries.desktopUp`
    display: none;
  `};
`
const StyledUtilityNav = styled.div`
  padding: 2.5rem 0 1.25rem;
  border-bottom: 1px solid ${gigplanetTheme.color.lightMediumGray};
  ${mediaQueries.tabletPortraitUp`
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    gap: 0px 2rem; 
    align-items: center; 
    padding: 0;
    border: none;
  `};

  ${({ hasDarkBg }) =>
    hasDarkBg &&
    css`
      ${UtilityNavItem}, ${UtilityNavAnchor} {
        color: #fff;
        &:hover {
          color: #ddd;
        }
      }
    `}
`
const FavoriteCount = styled.span`
  background-color: #32e08e;
  border: 0;
  border-radius: 50%;
  color: #000;
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 10px;
  height: 18px;
  min-width: 18px;
  padding: 0 0.25rem;
  right: -10px;
  top: -6px;
`
export const UserWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  padding: 1rem 0;
`

export const AvatarWrapper = styled.span`
  align-self: flex-start;
  display: block;
  margin-right: 10px;
`

const UtilityNav = ({
  showControlpanelButton,
  eventplannerGigRequestCount,
  artistGigRequestCount,
  onShowRequestOptions,
  showingRequestOptions,
  favoriteCount,
  isLoggedIn,
  currentUser,
  showControlpanelMenu,
  showEventplannerMenu,
  hasDarkBg,
}) => {
  const isArtist = currentUser.role === USER_ROLE.ARTIST
  const isAdmin = currentUser.role === USER_ROLE.ADMIN

  return (
    <StyledUtilityNav hasDarkBg={hasDarkBg}>
      <UtilityNavItem
        to={{
          pathname: '/s',
          state: { fromHeaderMenu: true },
        }}
        title="Gå til søk"
        aria-label="Gå til søk"
      >
        <UtilityNavIcon>
          <SearchIconMobile />
        </UtilityNavIcon>
        <UtilityNavItemText>Søk</UtilityNavItemText>
      </UtilityNavItem>
      <UtilityNavItem to="/favorites" title="Gå til favoritter" aria-label="Gå til favoritter">
        <UtilityNavIcon>
          <HeartIconFilled />
          {favoriteCount ? <FavoriteCount>{favoriteCount}</FavoriteCount> : null}
        </UtilityNavIcon>
        <UtilityNavItemText>Favoritter</UtilityNavItemText>
      </UtilityNavItem>
      {isLoggedIn ? (
        <RequestsMenuItem
          eventplannerGigRequestCount={eventplannerGigRequestCount}
          artistGigRequestCount={artistGigRequestCount}
          onShowRequestOptions={onShowRequestOptions}
          showingRequestOptions={showingRequestOptions}
          hasDarkBg={hasDarkBg}
          inDropdown
        />
      ) : null}
      {isArtist || isAdmin ? (
        <UtilityNavItem
          to={CONTROLPANEL_URL}
          title="Gå til kontrollpanel"
          aria-label="Gå til kontrollpanel"
        >
          <UtilityNavIcon>
            <ControlPanelIcon />
          </UtilityNavIcon>
          <UtilityNavItemText>Kontrollpanel</UtilityNavItemText>
        </UtilityNavItem>
      ) : null}
      {isArtist || isAdmin ? (
        <UtilityNavItem
          to={ARTIST_PROFILES_URL}
          title="Gå til artist-profiler"
          aria-label="Gå til artist-profiler"
        >
          <UtilityNavIcon>
            <ArtistProfilesIcon />
          </UtilityNavIcon>
          <UtilityNavItemText>Artist-profiler</UtilityNavItemText>
        </UtilityNavItem>
      ) : null}
      {isAdmin ? (
        <UtilityNavAnchor
          href={getGlobals().ADMIN_SERVER_HOST}
          title="Gå til Administrasjon"
          aria-label="Gå til Administrasjon"
        >
          <UtilityNavIcon>
            <GigplanetIcon />
          </UtilityNavIcon>
          <UtilityNavItemTextMobile>Administrasjon</UtilityNavItemTextMobile>
        </UtilityNavAnchor>
      ) : null}
    </StyledUtilityNav>
  )
}

UtilityNav.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
  favoriteCount: PropTypes.number,
  showControlpanelButton: PropTypes.bool,
  eventplannerGigRequestCount: PropTypes.number,
  artistGigRequestCount: PropTypes.number,
  onShowRequestOptions: PropTypes.func,
  showingRequestOptions: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  showControlpanelMenu: PropTypes.bool,
  showEventplannerMenu: PropTypes.bool,
}
UtilityNav.defaultProps = {
  showControlpanelButton: false,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(React.memo(UtilityNav))
