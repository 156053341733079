import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { ContentBlock, TextLine, Heading } from './style'

const Artist = ({
  artistName,
  artistPreviewInfo,
  artistContactName,
  artistContactPhone,
  artistContactEmail,
  artistInvoiceName,
  artistInvoiceAddress,
  artistInvoiceZip,
  artistInvoiceCity,
  artistOrganizationNumber,
}) => (
  <ContentBlock>
    <Heading>Tilbyder</Heading>
    <TextLine>Artistnavn: {artistName}</TextLine>
    {/* If no other info is set, we write this line */}
    {artistPreviewInfo ? <TextLine>{artistPreviewInfo}</TextLine> : null}
    {/* artistInvoiceName ? <TextLine>Firmanavn: {artistInvoiceName}</TextLine> : null */}
    {artistOrganizationNumber ? <TextLine>Org. nr: {artistOrganizationNumber}</TextLine> : null}
    {artistInvoiceAddress || (artistInvoiceZip && artistInvoiceCity) ? (
      <TextLine>
        Adresse:
        {artistInvoiceAddress ? ` ${artistInvoiceAddress}, ` : ' '}
        {artistInvoiceZip} {artistInvoiceCity}
      </TextLine>
    ) : null}
    {artistContactName ? <TextLine>Kontaktperson: {artistContactName}</TextLine> : null}
    {artistContactPhone ? <TextLine>Mobil: {artistContactPhone}</TextLine> : null}
    {artistContactEmail ? <TextLine>E-post: {artistContactEmail}</TextLine> : null}
  </ContentBlock>
)

Artist.propTypes = {
  artistName: PropTypes.string.isRequired,
  artistPreviewInfo: PropTypes.string,
  artistContactName: PropTypes.string.isRequired,
  artistContactPhone: PropTypes.string.isRequired,
  artistContactEmail: PropTypes.string.isRequired,
  artistInvoiceName: PropTypes.string.isRequired,
  artistInvoiceAddress: PropTypes.string.isRequired,
  artistInvoiceZip: PropTypes.string.isRequired,
  artistInvoiceCity: PropTypes.string.isRequired,
  artistOrganizationNumber: PropTypes.string,
}

Artist.defaultProps = {
  artistOrganizationNumber: '',
  artistPreviewInfo: '',
}

export default Artist
