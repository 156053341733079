import * as React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import Scroll from 'react-scroll'
import { withRouter } from 'react-router'

import { imageUrlFor } from '../../../../helpers/sanity-image-builder'
import { mediaQueriesDefinitions } from '../../../../styles/mediaQueries'

import {
  Container,
  ImageWrapper,
  ContentWrapper,
  Heading,
  Intro,
  Button,
  SecondaryButton,
  Placeholder,
  ButtonWrapper,
} from './style'
import { CameraIcon } from '../../../../icons'
import SanityImage from '../../../sanity-image'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'

const scrollTo = (id, pathname) => {
  window.history.replaceState({}, '', `${pathname}#${id}`)
  Scroll.scroller.scrollTo(id, {
    duration: 1000,
    delay: 0,
    smooth: 'easeInOutQuint',
  })
}

const HeadingAndIntroBlock = React.memo(
  ({
    title,
    intro,
    image,
    buttonText,
    buttonUrl,
    secondaryButtonText,
    secondaryButtonUrl,
    theme,
    anchorId,
    location: { pathname },
  }) => {
    const [visible, setIsVisible] = React.useState(false)

    const handleVisibilityChange = (isVisible) => {
      if (visible || !isVisible) {
        return
      }
      setIsVisible(true)
    }

    const scrollToId = React.useMemo(() => {
      const parts = (buttonUrl || '').split('#')
      if (parts.length > 0) {
        return parts[1]
      }
      return ''
    }, [buttonUrl])

    const secondaryScrollToId = React.useMemo(() => {
      const parts = (secondaryButtonUrl || '').split('#')
      if (parts.length > 0) {
        return parts[1]
      }
      return ''
    }, [secondaryButtonUrl])

    const headingValue = formatSimpleMarkdown(title)
    const introValue = (intro || '').replace(/(  )/g, '<br /> ')
    return (
      <LandingPageBlockWrapper
        theme={theme}
        anchorId={anchorId}
        noPadding
        fullWidth={image.layout && (image.layout === 'full' || image.layout === 'fullLowHeight')}
      >
        <Container isLow={Boolean(image.layout === 'fullLowHeight')}>
          <VisibilitySensor
            onChange={handleVisibilityChange}
            partialVisibility
            active={!visible}
            offset={{ bottom: -150 }}
          >
            <ImageWrapper isLow={Boolean(image.layout === 'fullLowHeight')}>
              {visible && image && image.asset ? (
                <SanityImage
                  width={2000}
                  height={image.layout === 'fullLowHeight' ? 400 : 700}
                  image={image}
                  altText={image.altText}
                >
                  <source
                    media={mediaQueriesDefinitions.mobileDown}
                    srcSet={imageUrlFor(image)
                      .width(600)
                      .height(image.layout === 'fullLowHeight' ? 300 : 450)}
                  />
                  <source
                    media={mediaQueriesDefinitions.tabletPortrait}
                    srcSet={imageUrlFor(image)
                      .width(900)
                      .height(image.layout === 'fullLowHeight' ? 350 : 500)}
                  />
                  <source
                    media={mediaQueriesDefinitions.tabletLandscape}
                    srcSet={imageUrlFor(image)
                      .width(1000)
                      .height(image.layout === 'fullLowHeight' ? 400 : 600)}
                  />
                  <source
                    media={mediaQueriesDefinitions.desktop}
                    srcSet={imageUrlFor(image)
                      .width(1500)
                      .height(image.layout === 'fullLowHeight' ? 400 : 700)}
                  />
                  <source
                    media={mediaQueriesDefinitions.desktopBig}
                    srcSet={imageUrlFor(image)
                      .width(1800)
                      .height(image.layout === 'fullLowHeight' ? 400 : 700)}
                  />
                </SanityImage>
              ) : (
                <Placeholder isLow={Boolean(image.layout === 'fullLowHeight')}>
                  <CameraIcon />
                </Placeholder>
              )}
            </ImageWrapper>
          </VisibilitySensor>
          <ContentWrapper isLow={Boolean(image.layout === 'fullLowHeight')}>
            {title ? <Heading dangerouslySetInnerHTML={{ __html: `${headingValue}` }} /> : null}
            {intro ? <Intro dangerouslySetInnerHTML={{ __html: `${introValue}` }} /> : null}
            <ButtonWrapper>
              {buttonText && buttonUrl ? (
                <Button
                  onClick={(e) => {
                    if (scrollToId) {
                      e.preventDefault()
                      scrollTo(scrollToId, pathname)
                    }
                  }}
                  to={buttonUrl}
                >
                  {buttonText}
                </Button>
              ) : null}
              {secondaryButtonText && secondaryButtonUrl ? (
                <SecondaryButton
                  onClick={(e) => {
                    if (secondaryScrollToId) {
                      e.preventDefault()
                      scrollTo(secondaryScrollToId, pathname)
                    }
                  }}
                  to={secondaryButtonUrl}
                >
                  {secondaryButtonText}
                </SecondaryButton>
              ) : null}
            </ButtonWrapper>
          </ContentWrapper>
        </Container>
      </LandingPageBlockWrapper>
    )
  },
)

HeadingAndIntroBlock.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  secondaryButtonUrl: PropTypes.string,
  image: PropTypes.shape({
    altText: PropTypes.string,
    layout: PropTypes.oneOf(['full', 'column', 'fullLowHeight']),
  }).isRequired,
  anchorId: PropTypes.string,
  theme: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

HeadingAndIntroBlock.defaultProps = {
  title: '',
  intro: '',
  buttonText: '',
  buttonUrl: '',
  secondaryButtonText: '',
  secondaryButtonUrl: '',
  anchorId: '',
}

export default withRouter(HeadingAndIntroBlock)
