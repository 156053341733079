import styled from 'styled-components'
import mediaQueries from '../../../styles/mediaQueries'
import gigplanetTheme from '../../../styles/gigplanetTheme'

export const FormWrapper = styled.div`
  padding: 0;
  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.small};
  `};
`

export const Intro = styled.p`
  margin: 10px 0;
`

export const HeadingWrapper = styled.div`
  margin: 20px 0;
`
export const TermsWrapper = styled.div`
  margin-top: 20px;
`

export const Terms = styled.p`
  font-size: 14px;
`

export const TermsTextContainer = styled.span``

export const TermsText = styled.span``

export const ContractButton = styled.button.attrs(props => ({ type: 'button' }))`
  appearance: none;
  padding: 0;
  display: inline-block;
  border: 0;
  cursor: pointer;
  color: ${gigplanetTheme.color.primary};
  text-decoration: underline;
  font-weight: inherit;
  background: transparent;

  &:hover {
    text-decoration: none;
  }
`

export const TermsLink = styled.a`
  color: ${gigplanetTheme.color.primary};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`
