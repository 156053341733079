import * as React from 'react'

import { fetchFrontpageArtists } from '../../stores/Artist/ArtistActions'
import { fetchPredefinedPageIfNeededAction } from '../../stores/cms-content/actions'

import LandingPageContainer from '../../containers/landing-page'

const FRONTPAGE_ARTIST_LIMIT = 6

const FrontPageRoute = React.memo(({}) => {
  return <LandingPageContainer showFavorites type="homePage" />
})

FrontPageRoute.dispatchActions = (req, dispatch, params) => [
  dispatch(fetchFrontpageArtists(req, FRONTPAGE_ARTIST_LIMIT)),
  dispatch(
    fetchPredefinedPageIfNeededAction(
      'homePage',
      params && params.previewId ? params.previewId : '',
    ),
  ),
]

export default FrontPageRoute
