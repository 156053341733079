import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'
import gigplanetTheme from '../../styles/gigplanetTheme'

const Container = styled.section`
  min-width: 320px;
  max-width: 450px;

  margin: 50px auto;
  padding: 30px;
  text-align: center;
  border: 1px solid ${gigplanetTheme.element.systemMessage.border};

  ${mediaQueries.mobile`
    border: 0;
    margin: 0;
    padding: 15px;
  `};
`

const SystemMessageWrapper = ({ children }) => <Container>{children}</Container>

SystemMessageWrapper.propTypes = {
  children: PropTypes.node,
}

export default SystemMessageWrapper
