import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { getAvgRating } from '../../../../models/review'
import Heading from '../../../ProfileDetailsHeading/ProfileDetailsHeading'
import RatingStars from '../../../../ui/RatingStars'
import Button from '../../../../ui/Button'

import {
  ReviewContainer,
  Reviews,
  Review,
  ReviewTop,
  ReviewHeading,
  ReviewHeadingText,
  Intro,
  Text,
  ReviewBottom,
  RatingContainer,
  Rating,
  RatingStarsContainer,
  RatingStarDescription,
  DetailsContainer,
  ReviewDetail,
  ReviewDetailDescription,
  ReviewDetailValue,
  ShowMore,
} from './style'
import getEventTypeName from '../../../../helpers/getEventTypeName'
import getEventTypeCategory from '../../../../helpers/getEventTypeCategory'
import useOptions from '../../../../hooks/useOptions'

const SHOW_MORE_LIMIT = 2

const ProfileDetailsReviews = React.memo(({ artist, reviews }) => {
  const [showMore, setShowMore] = React.useState(false)
  const { eventTypes, categories } = useOptions()

  const handleShowMore = () => {
    setShowMore(true)
  }

  const ratingsCount = artist && artist.rating ? artist.rating.reviews : 0
  // const ratingsAverage = artist && artist.rating ? artist.rating.average : 0
  let reviewsValues = {}
  if (ratingsCount > SHOW_MORE_LIMIT && !showMore) {
    reviewsValues = reviews.slice(0, SHOW_MORE_LIMIT)
  } else {
    reviewsValues = [...reviews]
  }
  return (
    <ReviewContainer itemType="http://schema.org/reviews" id="anmeldelser">
      <Heading>{ratingsCount === 1 ? 'Anmeldelse' : 'Anmeldelser'}</Heading>
      <Reviews>
        {reviewsValues.map(review => {
          let eventType = ''
          let title = ''
          if (review.eventType && review.eventType.eventType) {
            if (eventTypes[review.eventType.eventType]) {
              eventType = getEventTypeCategory(review.eventType, eventTypes)
              title = getEventTypeName(review.eventType, eventTypes)
            }
          }
          let category = ''
          if (review.category && review.category.category && review.category.sub) {
            if (
              categories[review.category.category] &&
              categories[review.category.category].sub[review.category.sub]
            ) {
              category = categories[review.category.category].sub[review.category.sub].name
            }
          }
          return (
            <Review key={review.id} itemProp="review" itemScope itemType="http://schema.org/Review">
              <meta itemProp="inLanguage" content="nb" />
              <span itemProp="publisher" itemScope itemType="http://schema.org/Organization">
                <meta itemProp="name" content="Gigplanet" />
              </span>
              <ReviewTop>
                <ReviewHeading
                  itemProp="reviewRating"
                  itemScope
                  itemType="http://schema.org/Rating"
                >
                  <ReviewHeadingText>{title}</ReviewHeadingText>
                  <RatingStars rating={getAvgRating(review.scores)} size="large" />
                  <meta itemProp="worstRating" content="1" />
                  <meta itemProp="ratingValue" content={getAvgRating(review.scores)} />
                  <meta itemProp="bestRating" content="5" />
                </ReviewHeading>
                {review.intro ? <Intro itemProp="name">{review.intro}</Intro> : null}
                <Text itemProp="reviewBody">{review.text}</Text>
              </ReviewTop>
              <ReviewBottom>
                <RatingContainer>
                  <Rating>
                    <RatingStarsContainer>
                      <RatingStars rating={review.scores.expectations} />
                    </RatingStarsContainer>
                    <RatingStarDescription>Innfridde forventningene</RatingStarDescription>
                  </Rating>
                  <Rating>
                    <RatingStarsContainer>
                      <RatingStars rating={review.scores.value} />
                    </RatingStarsContainer>
                    <RatingStarDescription>Valuta for pengene</RatingStarDescription>
                  </Rating>
                  <Rating>
                    <RatingStarsContainer>
                      <RatingStars rating={review.scores.professionalism} />
                    </RatingStarsContainer>
                    <RatingStarDescription>Profesjonalitet</RatingStarDescription>
                  </Rating>
                  <Rating>
                    <RatingStarsContainer>
                      <RatingStars rating={review.scores.skill} />
                    </RatingStarsContainer>
                    <RatingStarDescription>Dyktighet</RatingStarDescription>
                  </Rating>
                  <Rating>
                    <RatingStarsContainer>
                      <RatingStars rating={review.scores.recommended} />
                    </RatingStarsContainer>
                    <RatingStarDescription>Anbefales</RatingStarDescription>
                  </Rating>
                </RatingContainer>
                <DetailsContainer>
                  <ReviewDetail>
                    <ReviewDetailDescription>Dato:</ReviewDetailDescription>
                    <ReviewDetailValue>
                      {review.date ? moment(review.date).format('DD.MM.YYYY') : ''}
                      {review.date ? (
                        <meta
                          itemProp="datePublished"
                          content={moment(review.date).format('YYYY.MM.DD')}
                        />
                      ) : null}
                    </ReviewDetailValue>
                  </ReviewDetail>
                  <ReviewDetail>
                    <ReviewDetailDescription>Leid inn som:</ReviewDetailDescription>
                    <ReviewDetailValue>{category}</ReviewDetailValue>
                  </ReviewDetail>
                  <ReviewDetail>
                    <ReviewDetailDescription>Anmelder:</ReviewDetailDescription>
                    <ReviewDetailValue
                      itemProp="author"
                      itemScope
                      itemType="http://schema.org/Person"
                    >
                      <span itemProp="name">{review.reviewer}</span>
                    </ReviewDetailValue>
                  </ReviewDetail>
                  <ReviewDetail>
                    <ReviewDetailDescription>Sted:</ReviewDetailDescription>
                    <ReviewDetailValue>{review.location}</ReviewDetailValue>
                  </ReviewDetail>
                  <ReviewDetail>
                    <ReviewDetailDescription>Type arrangement:</ReviewDetailDescription>
                    <ReviewDetailValue>{eventType}</ReviewDetailValue>
                  </ReviewDetail>
                </DetailsContainer>
              </ReviewBottom>
            </Review>
          )
        })}
      </Reviews>
      {ratingsCount > SHOW_MORE_LIMIT && !showMore ? (
        <ShowMore>
          <Button type="button" theme="outline" onClick={handleShowMore}>
            Vis alle anmeldelsene
          </Button>
        </ShowMore>
      ) : null}
    </ReviewContainer>
  )
})

ProfileDetailsReviews.propTypes = {
  artist: PropTypes.shape({
    rating: PropTypes.shape({}),
  }).isRequired,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      artist: PropTypes.string,
      date: PropTypes.string,
      category: PropTypes.object,
      eventType: PropTypes.object,
      scores: PropTypes.shape({
        expectations: PropTypes.number,
        value: PropTypes.number,
        professionalism: PropTypes.number,
        skill: PropTypes.number,
        recommended: PropTypes.number,
      }),
      intro: PropTypes.string,
      text: PropTypes.string,
      reviewer: PropTypes.string,
      location: PropTypes.string,
    }),
  ),
}

export default ProfileDetailsReviews
