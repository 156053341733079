import styled, { css } from 'styled-components'

import { Link } from 'react-router-dom'
import { H1 } from '../../../../ui/landing-page-headings'

import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100;
  width: 100%;
  padding: 20rem 0 0;

  ${mediaQueries.mobile`
    padding: 17rem 0 3rem;
  `};
`
export const TextColumnsWrapper = styled.div`
  background: #000;
  padding: 2.5rem 0;
`

export const TextColumnsContainer = styled.div`
  display: flex;
  padding: 0 3rem;
  margin: 0 auto;
  max-width: 75rem;
  grid-gap: 2rem;
  ${mediaQueries.mobile`
    flex-wrap: wrap;
  `};
`

export const TextColumn = styled.div`
  flex: 1 1 33.3%;
  color: #fff;
  ${mediaQueries.tabletPortraitDown`
    flex: 1 1 100%;
  `};
`

export const TextColumnHeading = styled.h2`
  font-size: 25px;
  line-height: 31px;
  color: #32e083;
`
export const TextColumnText = styled.p`
  font-size: 17px;
  line-height: 26px;
`

export const BackgroundWrapper = styled.div`
  background: rgb(0, 0, 0);
  background: linear-gradient(210deg, #000 40%, #310173 100%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  overflow: hidden;
`

export const Intro = styled.p`
  color: #fff;
  font-size: 21px;
  line-height: 27px;
  font-weight: 300;
  margin-bottom: 2.5rem;
  max-width: 42.5rem;

  ${mediaQueries.mobile`
    font-size: 20px;
    margin: 15px 0 40px;
  `};
`

export const SearchWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mediaQueries.mobile`
    align-items: stretch;
    width: 100%;
  `};
`

export const Heading = styled(H1)`
  font-size: 38px;
  line-height: 44px;
  font-weight: bold;
  color: #fff;
  text-align: left;
  margin-bottom: 2.5rem;
  max-width: 27rem;

  ${mediaQueries.mobile`
    max-width: 100%;
    font-size: 32px;
    margin-bottom: 1rem;
  `};
`

export const HighlightedArtist = styled.div`
  position: absolute;
  right: 0;
  bottom: -3rem;
  z-index: -1;
  ${mediaQueries.tabletPortraitDown`
    top: 1rem;
    bottom: auto;
    max-width: 80%;
    right: 0.5rem;
    width: 30rem;
  `}
  ${({ narrow }) =>
    narrow &&
    css`
      padding-right: 9rem;
    `}

  ${({ medium }) =>
    medium &&
    css`
      padding-right: 5rem;
    `}
`

export const ArtistImage = styled.div`
  max-width: 100%;

  img {
    max-height: 38rem;
  }
`
export const HighlightedArtistHeading = styled.strong`
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
`
export const HighlightedArtistLink = styled(Link)`
  color: #ff785d;
  font-size: 21px;
  line-height: 27px;
`
export const HighlightedArtistContent = styled.div`
  position: absolute;
  top: 7rem;
  max-width: 10rem;
  right: 0;
  color: #ff785d;
  display: flex;
  flex-direction: column;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
      ${HighlightedArtistLink} {
        color: ${color};
      }
    `}

  ${({ medium }) =>
    medium &&
    css`
      top: 15rem;
    `}    

  ${mediaQueries.tabletPortraitDown`
    top: 7rem;
  `}
`
