import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const SearchIcon = (props) => (
  <SVGWrapper width="23" height="24" viewBox="0 0 23 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9ZM9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C10.8683 18 12.6036 17.4307 14.042 16.4562L20.793 23.2072L22.2072 21.793L15.5677 15.1535C17.0762 13.5441 18 11.3799 18 9C18 4.02944 13.9706 0 9 0Z"
      fill="currentColor"
    />
  </SVGWrapper>
)

export default SearchIcon
