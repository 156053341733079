import styled from 'styled-components'

export default styled.div`
  box-shadow: rgb(242, 242, 242) 0px 2px 5px 0px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(228, 228, 228);
  border-image: initial;
  border-radius: 4px;
`
