import sanityClient from '@sanity/client'
import getGlobals from '../helpers/getGlobals'
import isServer from '../helpers/is-server'

const getSanityClient = (previewMode = false) =>
  sanityClient({
    projectId: getGlobals().SANITY_PROJECT_ID, // you can find this in sanity.json
    dataset: getGlobals().SANITY_DATASET, // or the name you chose in step 1
    apiVersion: '2021-03-25',
    useCdn: previewMode ? false : getGlobals().IS_PRODUCTION, // `false` if you want to ensure fresh data
    token: previewMode && isServer() ? process.env.SANITY_TOKEN : '', // used to preview content. This should never be done on the client!
  })

export default getSanityClient
