import styled, { css } from 'styled-components'
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const Container = styled.div`
  max-width: 450px;
  min-width: 250px;
  position: relative;
  width: 100%;
  padding-top: 0;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      max-width: 100%;
    `};

  ${({ isError }) =>
    isError &&
    css`
      button {
        border-color: red;
      }
    `};
`

export const HelpText = styled.span`
  color: ${gigplanetTheme.component.form.input.helpText};
  font-size: 12px;
  line-height: 15px;
`

export const ErrorMessage = styled.small`
  color: ${gigplanetTheme.component.form.shared.errorText};
  font-size: 12px;
  line-height: 15px;
`

export const InputLabel = styled.span`
  line-height: 16px;
`

export const Time = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 300px;
  margin: 0 auto;

  & > * {
    &:first-child {
      margin-right: 5px;

      ${mediaQueries.mobile`
        margin-bottom: 15px;
      `};
    }

    &:last-child {
      margin-left: 5px;

      ${mediaQueries.mobile`
        margin: 0;
      `};
    }
  }
`

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 30rem;

  ${mediaQueries.mobile`
    min-width: 19rem;
    padding: ${gigplanetTheme.spacing.small}
  `};
  /* DayPicker OVERRIDES */

  .DayPicker {
    display: block;
    width: 100%;
    min-width: 16.5rem;
  }

  .DayPicker-wrapper {
    width: 100%;
  }

  .DayPicker-Month {
    width: 100%;
    margin: 0;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${gigplanetTheme.color.purple};
  }

  .DayPicker-Day {
    border: 1px solid #eaecec;
    text-align: center;
    border-radius: 0;
  }

  .DayPicker-Day--disabled {
    background-color: #eff1f1;
  }

  .DayPicker-Day--selected {
    background-color: ${gigplanetTheme.color.purple};
  }
`

export const Actions = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > * {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`
