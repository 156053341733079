import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import getEventTypeName from '../../helpers/getEventTypeName'

import { LONG_DATE_FORMAT, QUOTE_STATUS } from '../../models/GigRequest'

import {
  StyledEventplannerInbox,
  Wrapper,
  Requests,
  Item,
  Empty,
  Artists,
  ArtistItem,
} from './style'

import Loader from '../PageLoader'
import { H2 } from '../../ui/Headings'

import EventplannerInboxItem from '../EventplannerInboxItem'

const EventplannerInbox = ({ isLoading, hasLoaded, requests, eventTypes }) => {
  let content = null
  if (!hasLoaded || isLoading) {
    content = <Loader text="Laster innboks" />
  } else if (requests && requests.length > 0) {
    content = (
      <Requests>
        {requests.map(request => {
          const idx = (request.artists || []).findIndex(
            artist => artist && artist.quote && artist.quote.status === QUOTE_STATUS.ACCEPTED,
          )

          return (
            <Item key={request.id}>
              <H2>
                {getEventTypeName(request.eventType, eventTypes)},{' '}
                {moment(request.startTime).format(LONG_DATE_FORMAT)}
              </H2>
              <Artists>
                {(request.artists || []).map((artistInfo, index) => (
                  <ArtistItem key={artistInfo.artist.id}>
                    <EventplannerInboxItem
                      request={request}
                      booked={index === idx}
                      artistInfo={artistInfo}
                      eventTypes={eventTypes}
                    />
                  </ArtistItem>
                ))}
              </Artists>
            </Item>
          )
        })}
      </Requests>
    )
  } else {
    content = <Empty>Du har ingen forespørsler.</Empty>
  }
  return (
    <StyledEventplannerInbox>
      <Wrapper>{content}</Wrapper>
    </StyledEventplannerInbox>
  )
}

EventplannerInbox.propTypes = {
  requests: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}
EventplannerInbox.defaultProps = {
  requests: [],
}

export default EventplannerInbox
