import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import getVideoId from 'get-video-id'
import ReactPlayer from 'react-player'

import isVimeo from '../../utils/isVimeo'
import isYouTube from '../../utils/isYouTube'

const ContentWrapper = styled.div`
  width: 100%;
`

const VideoWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`

const reactPlayerResponsiveStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
}

const Video = ({ video }) => {
  const videoIdResult = getVideoId(video)

  if (!videoIdResult || (videoIdResult && !videoIdResult.id)) {
    return null
  }
  if (!isYouTube(video) && !isVimeo(video)) {
    return null
  }
  console.log('check', videoIdResult, isYouTube(video), isVimeo(video))

  let videoUrl = ''
  let playerConfig = {}
  if (isVimeo(video)) {
    videoUrl = `https://player.vimeo.com/video/${videoIdResult.id}`
    playerConfig = {
      vimeo: {
        playerOptions: {
          controls: 1,
        },
      },
    }
  } else if (isYouTube(video)) {
    videoUrl = `https://www.youtube.com/embed/${videoIdResult.id}`
    playerConfig = {
      youtube: {
        embedOptions: {
          modestbranding: 1,
          controls: 0,
          rel: 0,
        },
      },
    }
  }

  return (
    <ContentWrapper>
      <VideoWrapper>
        {videoUrl && (
          <ReactPlayer
            style={reactPlayerResponsiveStyle}
            width="100%"
            height="100%"
            controls
            url={videoUrl}
            config={playerConfig}
          />
        )}
      </VideoWrapper>
    </ContentWrapper>
  )
}

Video.propTypes = {
  video: PropTypes.string.isRequired,
}

export default Video
