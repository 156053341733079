import styled, { css } from 'styled-components'
import gigplanetTheme from '../../../styles/gigplanetTheme'
import mediaQueries from '../../../styles/mediaQueries'

export const Row = styled.div`
  width: 100%;
  padding-bottom: ${gigplanetTheme.spacing.small};
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${({ isLargePadding }) =>
    isLargePadding &&
    css`
      padding-bottom: ${gigplanetTheme.spacing.large};
    `};

  h1 {
    text-align: center;
  }
`

export const LegalText = styled.div`
  border: 1px solid ${gigplanetTheme.element.default.border};
  padding: 0 20px 20px;
  max-height: 400px;
  overflow-y: scroll;
  width: 100%;
  text-align: left;

  ${mediaQueries.mobile`
    max-height: 200px;
  `};

  h1 {
    font-size: 18px;
    margin: 20px 0 5px;
    text-align: left;
  }
  h2 {
    font-size: 16px;
    margin: 20px 0 5px;
    text-align: left;
  }

  h3 {
    font-size: 14px;
    margin: 20px 0 5px;
    text-align: left;
  }

  ul {
    margin: 0;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }

  li {
    font-size: 14px;
    line-height: 18px;
  }

  img {
    max-width: 100%;
  }
`

export const Actions = styled.div`
  padding-top: 0px;
  text-align: center;

  & > * {
    margin-right: 5px;
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
  ${mediaQueries.mobile`
    padding-top: 20px;
    padding-bottom: 20px;
  `};
`

export const Subheading = styled.h2`
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;

  ${mediaQueries.mobile`
    margin-bottom: 10px;
  `};
`

export const Intro = styled.p`
  font-size: 16px;
  text-align: center;
  margin: 0;
`

export const InputWrapper = styled.div`
  padding-left: ${gigplanetTheme.spacing.large};
  padding-right: ${gigplanetTheme.spacing.large};
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mediaQueries.mobile`
    padding-left: ${gigplanetTheme.spacing.small};
    padding-right: ${gigplanetTheme.spacing.small};
  `};
`
