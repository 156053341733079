var str = 'The quick brown fox jumps over the lazy dog' //replace with your string.
var maxLength = 6 // maximum number of characters to extract

const trimString = (str, maxLength) => {
  if (str.length > maxLength) {
    //trim the string to the maximum length
    let trimmedString = str.substr(0, maxLength)
    //re-trim if we are in the middle of a word and
    trimmedString = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')),
    )
    return trimmedString
  }
  return str
}

export default trimString
