import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import MetaData from '../../components/MetaData'

import VerifyEmailSuccessView from '../../views/verify-email-success'

const VerifyEmailSuccessRoute = ({ history, history: { location } }) => {
  let url = '/'
  let showContinue = false
  const query = queryString.parse(location.search)
  if (query && query.url) {
    url = query.url
    showContinue = true
  }

  return (
    <>
      <MetaData title="E-post verifisert" description="" noindex />
      <VerifyEmailSuccessView successUrl={url} showContinue={showContinue} />
    </>
  )
}

VerifyEmailSuccessRoute.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      push: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
}

export default compose(withRouter)(VerifyEmailSuccessRoute)
