import styled from 'styled-components'

import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
`

export const Intro = styled.p`
  color: ${gigplanetTheme.element.frontpage.intro};
  padding: 0;
  padding-top: ${gigplanetTheme.spacing.small};
  margin: 0 auto;
  max-width: 800px;

  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  text-align: center;

  ${mediaQueries.mobile`
    font-size: 16px;
    line-height: 22px;
    padding-top: ${gigplanetTheme.spacing.xsmall};
  `};

  ${mediaQueries.tabletPortraitUp`
    font-size: 20px;
    line-height: 26px;
  `};

  ${mediaQueries.tabletLandscapeUp`
    font-size: 22px;
    line-height: 28px;
  `};

  br {
    ${mediaQueries.mobile`
      display: none;
    `};
  }
`
