import * as React from 'react'
import PropTypes from 'prop-types'
import { SubmitWrapper } from './style'

const UserAuthSubmitWrapper = ({ children }) => <SubmitWrapper>{children}</SubmitWrapper>

UserAuthSubmitWrapper.propTypes = {
  children: PropTypes.element.isRequired,
}
UserAuthSubmitWrapper.defaultProps = {}

export default UserAuthSubmitWrapper
