import * as React from 'react'
import PropTypes from 'prop-types'

import { InputSearchContainer, SearchIconWrapper, ValueMarker, InputElement } from './style'
import { SearchIcon } from '../../icons'

const Input = ({
  autoFocus,
  id,
  placeholder,
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  expanded,
  disabled,
  showValueMarker,
}) => (
  <InputSearchContainer expanded={expanded}>
    <InputElement
      type="search"
      id={id}
      name={id}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      readOnly={disabled}
      autoFocus={autoFocus} // eslint-disable-line
    />
    <SearchIconWrapper>
      <SearchIcon />
    </SearchIconWrapper>
    {showValueMarker ? <ValueMarker /> : null}
  </InputSearchContainer>
)

Input.propTypes = {
  autoFocus: PropTypes.bool,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  showValueMarker: PropTypes.bool,
}

Input.defaultProps = {
  autoFocus: false,
  placeholder: '',
  value: '',
  onChange: null,
  onBlur: null,
  onFocus: null,
  onKeyDown: null,
  expanded: false,
  disabled: false,
  showValueMarker: false,
}

export default Input
