import * as React from 'react'
import PropTypes from 'prop-types'
import { Heading, ContentBlock, TextLine } from './style'

const Requester = ({
  invoicePreviewInfo,
  invoiceOrganization,
  invoiceName,
  invoiceAddress,
  invoiceZip,
  invoiceCity,
  invoiceReference,
}) => (
  <ContentBlock>
    <Heading>Oppdragsgiver</Heading>
    {invoicePreviewInfo ? <TextLine>{invoicePreviewInfo}</TextLine> : null}
    {invoiceOrganization ? <TextLine>Organisasjonsnavn: {invoiceOrganization}</TextLine> : null}
    {invoiceName && !invoiceOrganization ? <TextLine>Navn: {invoiceName}</TextLine> : null}
    {invoiceAddress || (invoiceZip && invoiceCity) ? (
      <TextLine>
        Adresse: {invoiceAddress}
        {invoiceAddress ? ', ' : ''}
        {invoiceZip} {invoiceCity}
      </TextLine>
    ) : null}
    {invoiceReference ? <TextLine>Referanse: {invoiceReference}</TextLine> : null}
  </ContentBlock>
)

Requester.propTypes = {
  invoicePreviewInfo: PropTypes.string,
  invoiceOrganization: PropTypes.string,
  invoiceName: PropTypes.string,
  invoiceAddress: PropTypes.string,
  invoiceZip: PropTypes.string,
  invoiceCity: PropTypes.string,
  invoiceReference: PropTypes.string,
}
Requester.defaultProps = {
  invoicePreviewInfo: '',
  invoiceOrganization: '',
  invoiceName: '',
  invoiceAddress: '',
  invoiceZip: '',
  invoiceCity: '',
  invoiceReference: '',
}

export default Requester
