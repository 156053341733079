import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import UserAuthRegisterOptions from './components/user-auth-register-options'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

class UserAuthRegisterOptionsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      intro,
      hideHeading,
      continueMode,
      successUrl,
      onShowLoginForm,
      onShowRegisterForm,
      onClose,
    } = this.props
    return (
      <UserAuthRegisterOptions
        intro={intro}
        hideHeading={hideHeading}
        continueMode={continueMode}
        successUrl={successUrl}
        onShowRegisterForm={onShowRegisterForm}
        onShowLoginForm={onShowLoginForm}
        onClose={onClose}
      />
    )
  }
}

UserAuthRegisterOptionsContainer.propTypes = {
  intro: PropTypes.string,
  hideHeading: PropTypes.bool,
  continueMode: PropTypes.bool,
  successUrl: PropTypes.string,
  onShowRegisterForm: PropTypes.func.isRequired,
  onShowLoginForm: PropTypes.func.isRequired,
  onClose: PropTypes.func,
}

UserAuthRegisterOptionsContainer.defaultProps = {
  intro: '',
  hideHeading: false,
  continueMode: false,
  onClose: null,
}

const UserAuthRegisterOptionsContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAuthRegisterOptionsContainer)

export default UserAuthRegisterOptionsContainerWrapper
