import * as React from 'react'
import PropTypes from 'prop-types'

import { Container } from './style'

const GigRequestFormActionsWrapper = ({ children }) => <Container>{children}</Container>

GigRequestFormActionsWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default GigRequestFormActionsWrapper
