import styled, { css } from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-right: 2.35765%;

  &:last-child {
    margin-right: 0;
  }

  ${({ columnCount }) =>
    columnCount === 2 &&
    css`
      width: 48.82117%;

      ${mediaQueries.mobile`
        width: 100%;
        padding-bottom: 15px;
      `};
    `}
 
    ${({ columnCount }) =>
      columnCount === 3 &&
      css`
        width: 31.76157%;

        ${mediaQueries.mobile`
          width: 100%;
          padding-bottom: 15px;
        `};
      `}

  ${({ columnCount }) =>
    columnCount === 4 &&
    css`
      width: 23.23176%;
      ${mediaQueries.mobile`
        width: 100%;
        padding-bottom: 15px;
      `};
    `}
`
