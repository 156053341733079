import PropTypes from 'prop-types'

export const USER_PROFILE_URL = '/user-profile'

export const CurrentUserShape = PropTypes.shape({
  id: PropTypes.string,
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.number,
})

export const ROLE_ADMIN = 3
export const ROLE_USER = 1
export const ROLE_ARTIST = 2
export const ROLE_CUSTOMER = 4

export const USER_ROLE = {
  ADMIN: ROLE_ADMIN,
  USER: ROLE_USER,
  ARTIST: ROLE_ARTIST,
  CUSTOMER: ROLE_CUSTOMER,
}

export const USER_ROLES = [USER_ROLE.USER, USER_ROLE.CUSTOMER, USER_ROLE.ARTIST, USER_ROLE.ADMIN]

export const getUserRoleName = role => {
  switch (role) {
    case USER_ROLE.ADMIN:
      return 'Administrator'
    case USER_ROLE.USER:
      return 'Bruker'
    case USER_ROLE.CUSTOMER:
      return 'Arrangør'
    case USER_ROLE.ARTIST:
      return 'Artist'
    default:
      return 'Ukjent rolle'
  }
}

export const profileUpdateErrors = {}
profileUpdateErrors['user-invalidemail'] = 'Ugyldig epost.'
