import * as React from 'react'
import PropTypes from 'prop-types'

import { Container, Actions } from './style'

import GigRequestSecurityWarning from '../gig-request-security-warning'
import { Textarea, Errormessage } from '../../ui/Form'
import Button from '../../ui/Button'

const GigRequestNewMessage = ({
  placeholder,
  message,
  onChange,
  onSubmit,
  isSending,
  errorMessages,
  isArtist,
}) => (
  <Container>
    <GigRequestSecurityWarning isArtist={isArtist} />
    <Textarea
      placeholder={placeholder}
      id="new-message"
      name="new-message"
      value={message}
      onChange={onChange}
      disabled={isSending}
      expanded
    />
    {errorMessages && errorMessages.length > 0 ? (
      <Actions>
        <Errormessage messages={errorMessages} />
      </Actions>
    ) : null}
    <Actions>
      <Button type="button" theme="primary" onClick={onSubmit} loading={isSending}>
        {isSending ? 'Sender melding' : 'Send melding'}
      </Button>
    </Actions>
  </Container>
)

GigRequestNewMessage.propTypes = {
  placeholder: PropTypes.string,
  message: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
  isArtist: PropTypes.bool,
  errorMessages: PropTypes.arrayOf(PropTypes.string),
}
GigRequestNewMessage.defaultProps = {
  message: '',
  isArtist: false,
  placeholder: 'Send en melding om du trenger flere opplysninger om forespørselen',
}

export default GigRequestNewMessage
