import styled, { css } from 'styled-components'
import { math } from 'polished'

import mediaQueries from '../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../styles/gigplanetTheme'

export const Content = styled.div`
  max-width: ${gigplanetTheme.measure.maxWidth};
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  margin-top: ${math(`${gigplanetTheme.spacing.large} - 2rem`)};
  margin-bottom: -2rem;

  ${mediaQueries.mobile`
    margin-top: ${gigplanetTheme.spacing.large};
    margin-bottom: 0;

  `};
`

export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  overflow-x: scroll;
  width: 100%;

  ${({ layout }) =>
    layout === 'VERTICAL' &&
    css`
      flex-direction: column;
      max-width: 30rem;
    `};

  ${mediaQueries.mobile`
    justify-content: flex-start;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    transform: translate3d(0, 0, 0);
  `};

  ${mediaQueries.tabletPortrait`
    justify-content: flex-start;
  `};
`

export const Item = styled.li`
  flex: 1 0 290px;
  margin: 0 10px;
  padding: 30px 10px;
  text-align: center;
  min-width: 290px;

  // background: white;
  // border: 1px solid ${gigplanetTheme.element.default.border};
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09), 0 3px 10px 4px rgba(0, 0, 0, 0.08);
  // border-radius: 3px;

  ${mediaQueries.tabletPortrait`
    flex: 1 0 33%;
  `};

  ${mediaQueries.tabletLandscape`
    flex: 1 0 25%;
  `};

  ${mediaQueries.mobile`
    padding: 20px;
  `};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ layout }) =>
    layout === 'VERTICAL' &&
    css`
      flex: 0 1 auto;
    `};
`

export const Logo = styled.img`
  max-height: 50px;
  max-width: 150px;
`
