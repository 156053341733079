import styled, { css } from 'styled-components'

export const ContentBlock = styled.div`
  text-align: center;
  margin-top: 20px;

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `}

  ${({ isLast }) =>
    isLast &&
    css`
      page-break-after: auto;
      break-after: auto;
    `};
`

export const Heading = styled.h1`
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
  margin: 0;
  padding: 0;
  line-height: 24px;

  @media print {
    font-size: 18px;
    line-height: 22px;
  }
`

export const TextLine = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;

  @media print {
    font-size: 12px;
    line-height: 16px;
  }
`
