import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const ArrowForwardIcon = (props) => (
  <SVGWrapper width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99989 23.0607L4.87857 20.9393L13.8179 12L4.87857 3.06067L6.99989 0.939349L18.0605 12L6.99989 23.0607Z"
      fill="currentColor"
    />
  </SVGWrapper>
)

export default ArrowForwardIcon
