import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import styled from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'

import Button from '../../ui/Button'
import { H1 } from '../../ui/Headings'

const Container = styled.section`
  max-width: 600px;
  margin: 0 auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mediaQueries.mobile`
    padding: 15px;
  `};
`

const Text = styled.p`
  font-weight: 300;
`

const Content = styled.div`
  border: 1px solid #ccc;
  padding: 50px;
  text-align: center;

  ${mediaQueries.mobile`
    padding: 15px;
  `};
`

const PageNotFound = ({ staticContext }) => {
  staticContext.status = 404
  return (
    <Container>
      <Content>
        <H1>Kunne ikke finne siden</H1>
        <Text>
          Har du funnet denne siden fordi du har fulgt en lenke? Vi setter pris på om du forteller
          oss det!
        </Text>
        <Button theme="primary" to="/">
          Gå til forsiden
        </Button>
      </Content>
    </Container>
  )
}
PageNotFound.propTypes = {
  staticContext: PropTypes.shape({}),
}

PageNotFound.defaultProps = {
  staticContext: {},
}

export default compose(withRouter)(PageNotFound)
