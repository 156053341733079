import * as React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import gigplanetTheme from '../../../styles/gigplanetTheme'
import mediaQueries from '../../../styles/mediaQueries'

import { EmailOutlineIconPurple } from '../../../icons'
import { INBOX_URL as CONTROLPANEL_REQUESTS_URL } from '../../../models/Controlpanel'
import { DASHBOARD_URL as EVENTPLANNER_REQUESTS_URL } from '../../../models/Eventplanner'

import GigRequestUnreadCount from '../../../containers/gig-request-unread-count'

const LinkWrapper = React.forwardRef(({ to, children, ...rest }, ref) => (
  <Link to={to} {...rest} ref={ref}>
    {children}
  </Link>
))

const RequestsUnreadCountWrapper = styled.span`
  position: absolute;
  right: -10px;
  top: -6px;
`
const ButtonInnerContent = styled.span`
  position: relative;
`
const UtilityNavIcon = styled.div`
  position: relative;
  height: 24px;
  width: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const UtilityNavItemText = styled.span`
  padding-left: 0.75rem;
  font-size: 17px;
  line-height: 17px;
  font-weight: 500;
  display: none;
  ${mediaQueries.mobile`
    display: block;
  `};
  ${mediaQueries.desktopUp`
    display: block;
  `};
`
const UtilityNavItem = styled(LinkWrapper)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 1.75rem;
  position: relative;
  color: ${gigplanetTheme.color.primary};
  ${mediaQueries.tabletPortraitUp`
    margin: 0;
  `}
  &:hover {
    color: #924dff;
  }

  ${({ hasDarkBg }) =>
    hasDarkBg &&
    css`
      color: #fff;
      &:hover {
        color: #ddd;
      }
    `}
`

const RequestsMenuItem = React.memo(
  ({
    artistGigRequestCount,
    eventplannerGigRequestCount,
    onShowRequestOptions,
    showingRequestOptions,
    inDropdown,
    hasDarkBg,
  }) => {
    if (artistGigRequestCount > 0 && eventplannerGigRequestCount > 0) {
      return (
        <UtilityNavItem
          id="header-request-window"
          isOpen={showingRequestOptions}
          onClick={onShowRequestOptions}
          hasDarkBg={hasDarkBg}
          title="Forespørsler"
          aria-label="Forespørsler"
        >
          <UtilityNavIcon>
            <EmailOutlineIconPurple />
            <RequestsUnreadCountWrapper inDropdown={inDropdown}>
              <GigRequestUnreadCount />
            </RequestsUnreadCountWrapper>
          </UtilityNavIcon>
          <UtilityNavItemText>Forespørsler</UtilityNavItemText>
        </UtilityNavItem>
      )
    }
    return (
      <UtilityNavItem
        hasDarkBg={hasDarkBg}
        to={eventplannerGigRequestCount > 0 ? EVENTPLANNER_REQUESTS_URL : CONTROLPANEL_REQUESTS_URL}
      >
        <UtilityNavIcon>
          <EmailOutlineIconPurple />
          <RequestsUnreadCountWrapper inDropdown={inDropdown}>
            <GigRequestUnreadCount />
          </RequestsUnreadCountWrapper>
        </UtilityNavIcon>
        <UtilityNavItemText>Forespørsler</UtilityNavItemText>
      </UtilityNavItem>
    )
  },
)

RequestsMenuItem.propTypes = {
  artistGigRequestCount: PropTypes.number.isRequired,
  eventplannerGigRequestCount: PropTypes.number.isRequired,
  onShowRequestOptions: PropTypes.func.isRequired,
  showingRequestOptions: PropTypes.bool.isRequired,
  inDropdown: PropTypes.bool,
  hasDarkBg: PropTypes.bool,
}
RequestsMenuItem.defaultProps = {
  inDropdown: false,
}

export default RequestsMenuItem
