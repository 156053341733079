import * as React from 'react'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import { fetchFrontpageArtists } from '../../../stores/Artist/ArtistActions'
import { fetchOneArticleIfNeededAction } from '../../../stores/cms-content/actions'
import {
  makeGetArticleContentSelector,
  makeGetArticleLoadedSelector,
  makeGetArticleLoadingSelector,
  getSiteSettingsSelector,
} from '../../../stores/cms-content/reducer'

import LandingPageContainer from '../../../containers/landing-page'

import MetaData from '../../../components/MetaData'
import Layout from '../../../layouts/main-layout'
import PageLoader from '../../../components/PageLoader'
import { imageUrlFor } from '../../../helpers/sanity-image-builder'
import Article from '../../../components/Article'

const FRONTPAGE_ARTIST_LIMIT = 6

const getArticleContentSelector = makeGetArticleContentSelector()
const getArticleLoadedSelector = makeGetArticleLoadedSelector()
const getArticleLoadingSelector = makeGetArticleLoadingSelector()

const ArticlePage = React.memo(() => {
  const { articleSlug } = useParams()
  const dispatch = useDispatch()

  const article = useSelector((state) => getArticleContentSelector(state, articleSlug))
  const loaded = useSelector((state) => getArticleLoadedSelector(state, articleSlug))
  const loading = useSelector((state) => getArticleLoadingSelector(state, articleSlug))
  const siteSettings = useSelector(getSiteSettingsSelector)

  React.useEffect(() => {
    dispatch(fetchOneArticleIfNeededAction(articleSlug))
  }, [articleSlug])

  const title = article ? article.metaTitle : ''
  const description = article ? article.metaDescription : ''
  let metaImage = ''
  if (article && article.metaImage) {
    metaImage = imageUrlFor(article.metaImage).width(1200).height(630).quality(60).url()
  } else if (article && article.mainImage) {
    metaImage = imageUrlFor(article.mainImage).width(1200).height(630).quality(60).url()
  } else if (siteSettings && siteSettings.metaImage) {
    metaImage = imageUrlFor(siteSettings.metaImage).width(1200).height(630).quality(60).url()
  }

  if (loading || !loaded) {
    return (
      <Layout>
        <MetaData title="Henter artikkel" description="" nopin />
        <PageLoader text="Henter artikkel" />
      </Layout>
    )
  }
  console.log('article', article)
  return (
    <Layout showSearch>
      <MetaData title={title} description={description} image={metaImage} />
      <Article
        title={article.title}
        author={article.author}
        publishedAt={article.publishedAt}
        intro={article.intro}
        categories={article.categories}
        mainImage={article.mainImage}
        body={article.body}
      />
      <LandingPageContainer removeLayout type="articleDetails" />
    </Layout>
  )
})

ArticlePage.dispatchActions = (req, dispatch, params) => {
  let slug = params && params.articleSlug ? params.articleSlug : ''
  let previewId = ''
  const parts = slug.split('?')
  if (parts.length > 1) {
    slug = parts[0]
    if (parts[1]) {
      const subParts = parts[1].split('=')
      const idx = subParts.indexOf('cms-preview')
      if (idx !== -1) {
        previewId = subParts[idx + 1]
      }
    }
  }
  return [
    dispatch(fetchFrontpageArtists(req, FRONTPAGE_ARTIST_LIMIT)),
    dispatch(
      fetchOneArticleIfNeededAction(slug, params && params.previewId ? params.previewId : ''),
    ),
  ]
}

export default ArticlePage
