import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import Layout from '../../layouts/main-layout'
import MetaData from '../../components/MetaData'
import PasswordResetErrorView from '../../views/password-reset-error'

const PasswordResetErrorRoute = ({ history, history: { location } }) => {
  const query = queryString.parse(location.search)
  const successUrl = query && query.successUrl ? query.successUrl : '/'
  return (
    <>
      <MetaData title="Reset passord feilet" description="" noindex />
      <PasswordResetErrorView
        successUrl={successUrl}
        onClose={() => {
          history.push('/')
        }}
      />
    </>
  )
}

PasswordResetErrorRoute.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      push: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
}

PasswordResetErrorRoute.defaultProps = {}

export default withRouter(PasswordResetErrorRoute)
