import * as React from 'react'
import PropTypes from 'prop-types'

import { Message } from './style'
import MessageWarning from './components/message-warning'

const GigRequestTimelineMessage = ({ message, fromArtist, isArtist }) => (
  <>
    <MessageWarning message={message} fromArtist={fromArtist} isArtist={isArtist} />
    <Message>{message}</Message>
  </>
)

GigRequestTimelineMessage.propTypes = {
  fromArtist: PropTypes.bool, // eslint-disable-line
  isArtist: PropTypes.bool, // eslint-disable-line
  messageId: PropTypes.string, // eslint-disable-line
  message: PropTypes.string,
}
GigRequestTimelineMessage.defaultProps = {
  fromArtist: false,
  isArtist: false,
}

export default GigRequestTimelineMessage
