import * as React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import throttle from 'lodash.throttle'
import debounce from 'lodash.debounce'

import { trackEvent } from '../../libs/analytics'

import { setSearchDataAction } from '../../stores/Artist/ArtistActions'
import {
  getSearchData,
  totalCountSearchArtists,
  getSearchFilters,
} from '../../stores/Artist/ArtistReducer'
import {
  getCategories,
  getArtistTypes,
  getEventTypes,
  getGenres,
} from '../../stores/Options/OptionsReducer'
import { getCounties } from '../../stores/Locations/LocationsReducer'
import SearchFilters from './components/search-filters'

const mapStateToProps = state => ({
  filters: getSearchFilters(state),
  artistTypes: getArtistTypes(state),
  categories: getCategories(state),
  eventTypes: getEventTypes(state),
  genres: getGenres(state),
  counties: getCounties(state),
  searchData: getSearchData(state),
  totalCount: totalCountSearchArtists(state),
})

const mapDispatchToProps = dispatch => ({
  setSearchData: data => {
    dispatch(setSearchDataAction(data))
  },
})

class SearchFiltersContainer extends React.Component {
  componentDidMount() {
    this.updateSearchThrotteled = throttle(this.throtteledNameChange, 300, { leading: false })
    this.updatePriceFromThrotteled = debounce(this.throtteledPriceFromChange, 300, {
      leading: false,
    })
    this.updatePriceToThrotteled = debounce(this.throtteledPriceToChange, 300, { leading: false })
  }

  throtteledNameChange = () => {
    const { updateSearch, searchData } = this.props
    trackEvent('search-filter', 'search-filter-changed-artist-name', searchData.name)
    updateSearch()
  }

  throtteledPriceFromChange = () => {
    const { updateSearch, searchData } = this.props
    trackEvent('search-filter', 'search-filter-changed-price-from', searchData.priceFrom)
    updateSearch()
  }

  throtteledPriceToChange = () => {
    const { updateSearch, searchData } = this.props
    trackEvent('search-filter', 'search-filter-changed-price-to', searchData.priceTo)
    updateSearch()
  }

  handleArtistTypeChange = value => {
    const { updateSearch, setSearchData } = this.props
    setSearchData({
      artistType: value,
    })
    updateSearch()
    trackEvent('search-filter', 'search-filter-changed-artist-type', value)
  }

  handleCategoryChange = value => {
    const { updateSearch, setSearchData } = this.props
    setSearchData({
      category: value,
    })
    updateSearch()
    trackEvent('search-filter', 'search-filter-changed-category', value)
  }

  handlePerformLocationChange = value => {
    const { updateSearch, setSearchData } = this.props
    setSearchData({
      location: value,
    })
    updateSearch()
    trackEvent('search-filter', 'search-filter-changed-perform-location', value)
  }

  handleEventTypeChange = value => {
    const { updateSearch, setSearchData } = this.props
    setSearchData({
      eventType: value,
    })
    updateSearch()
    trackEvent('search-filter', 'search-filter-changed-event-type', value)
  }

  handleGenreChange = value => {
    const { updateSearch, setSearchData } = this.props
    setSearchData({
      genre: value,
    })
    updateSearch()
    trackEvent('search-filter', 'search-filter-changed-genre', value)
  }

  handleNameChange = e => {
    const { setSearchData } = this.props
    setSearchData({
      name: e.currentTarget.value,
    })
    this.updateSearchThrotteled()
  }

  handlePriceFromChange = value => {
    const { setSearchData } = this.props
    setSearchData({
      priceFrom: value,
    })
    this.updatePriceFromThrotteled()
  }

  handlePriceToChange = value => {
    const { setSearchData } = this.props
    setSearchData({
      priceTo: value,
    })
    this.updatePriceToThrotteled()
  }

  render() {
    const {
      filters,
      searchData,
      categories,
      artistTypes,
      counties,
      eventTypes,
      totalCount,
      resetSearch,
      genres,
      startWithOpenFilter,
    } = this.props

    return (
      <SearchFilters
        filters={filters}
        category={searchData.category}
        categories={categories}
        onCategoryChange={this.handleCategoryChange}
        artistTypes={artistTypes}
        artistType={searchData.artistType}
        onArtistTypeChange={this.handleArtistTypeChange}
        counties={counties}
        performLocation={searchData.location}
        onPerformLocationChange={this.handlePerformLocationChange}
        eventType={searchData.eventType}
        eventTypes={eventTypes}
        onEventTypeChange={this.handleEventTypeChange}
        totalCount={totalCount}
        onResetSearch={resetSearch}
        genres={genres}
        genre={searchData.genre}
        onGenreChange={this.handleGenreChange}
        name={searchData.name}
        onNameChange={this.handleNameChange}
        priceFrom={searchData.priceFrom}
        onPriceFromChange={this.handlePriceFromChange}
        priceTo={searchData.priceTo}
        onPriceToChange={this.handlePriceToChange}
        startWithOpenFilter={startWithOpenFilter}
      />
    )
  }
}

SearchFiltersContainer.propTypes = {
  filters: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  genres: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  categories: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  artistTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  counties: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  setSearchData: PropTypes.func.isRequired,
  updateSearch: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  searchData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  totalCount: PropTypes.number.isRequired,
  startWithOpenFilter: PropTypes.bool,
}

const SearchFiltersContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchFiltersContainer)

export default SearchFiltersContainerWrapper
