import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from '../../ui/Button'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 25px;
`
const UserAuthAbort = ({ onAbort }) => (
  <Container>
    <Button onClick={onAbort} type="button" theme="text">
      AVBRYT
    </Button>
  </Container>
)

UserAuthAbort.propTypes = {
  onAbort: PropTypes.func.isRequired,
}

export default UserAuthAbort
