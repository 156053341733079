import * as React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { validateEventDetailsForm, validateEventForm } from '../../../../models/GigRequest'
import { setEventFormDataAction } from '../../../../stores/GigRequest/GigRequestActions'
import {
  getEventFormData,
  getArtistFormData,
} from '../../../../stores/GigRequest/GigRequestReducer'

import PageNotFound from '../../../PageNotFound'
import GigRequestFormEventDetails from './components/GigRequestFormEventDetails'

const UPDATE_FORM_ACTION = 'update'

const formReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM_ACTION:
      return {
        ...state,
        ...action.payload,
      }
    default:
      throw new Error()
  }
}

const GigRequestFormEventContainer = React.memo(({ onNext, onPrevious }) => {
  const dispatch = useDispatch()
  const eventFormData = useSelector(getEventFormData)
  const artist = useSelector(getArtistFormData)
  const [formState, formDispatch] = React.useReducer(formReducer, {
    included: eventFormData.included,
    venue: eventFormData.venue,
    city: eventFormData.city,
    notes: eventFormData.notes,
  })
  const [errorMessages, setErrorMessages] = React.useState(null)
  const isValidStep = React.useMemo(() => {
    const errors = validateEventForm(eventFormData)
    return Boolean(Object.keys(errors).length === 0)
  }, [eventFormData])

  const updateState = (value, name, id) => {
    formDispatch({
      type: UPDATE_FORM_ACTION,
      payload: {
        [name]: value,
      },
    })

    if (errorMessages && errorMessages['included'] && value) {
      const newErrorMessages = { ...errorMessages }
      delete newErrorMessages[id]
      setErrorMessages(newErrorMessages)
    }
  }
  const handleIncludedChange = e => {
    const included = [...formState.included]
    if (e.currentTarget.value === 'included') {
      included.push(e.currentTarget.name)
    } else {
      included.splice(included.indexOf(e.currentTarget.name), 1)
    }
    updateState(included, 'included', 'included')
  }

  const handleVenueChange = e => {
    updateState(e.currentTarget.value, 'venue', 'venue')
  }

  const handleEventCityChange = e => {
    updateState(e.currentTarget.value, 'city', 'event-city')
  }

  const handleNotesChange = e => {
    updateState(e.currentTarget.value, 'notes', 'notes')
  }

  const handlePrevious = () => {
    dispatch(setEventFormDataAction(formState))
    onPrevious()
  }

  const handleNext = () => {
    const errorMessages = validateEventDetailsForm(formState)
    if (Object.keys(errorMessages).length > 0) {
      setErrorMessages(errorMessages)
      return
    }
    dispatch(setEventFormDataAction(formState))
    onNext()
  }

  if (!isValidStep) {
    return <PageNotFound />
  }
  return (
    <GigRequestFormEventDetails
      artist={artist}
      included={formState.included}
      onIncludedChange={handleIncludedChange}
      venue={formState.venue}
      onVenueChange={handleVenueChange}
      city={formState.city}
      onCityChange={handleEventCityChange}
      notes={formState.notes}
      onNotesChange={handleNotesChange}
      errorMessages={errorMessages}
      onNext={handleNext}
      onPrevious={handlePrevious}
    />
  )
})

GigRequestFormEventContainer.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
}

export default GigRequestFormEventContainer
