import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { compose } from 'recompose'
import { withRouter } from 'react-router'

import { gigRequestType, GIGREQUEST_NOT_SET_ID, REQUEST_STATUS } from '../../../models/GigRequest'
import { H1, H2, H4 } from '../../../ui/Headings'
import {
  Intro,
  HeadingWrapper,
  Terms,
  TermsWrapper,
  TermsTextContainer,
  TermsText,
  TermsLink,
  FormWrapper,
  ContractButton,
} from './style'
import Row from '../../../components/FormRow'
import Column from '../../../components/FormColumn'
import { Input, Checkbox, Label, Errormessage } from '../../../ui/Form'
import Action from '../../../ui/form-action'
import Button from '../../../ui/Button'
import { EVENT_PLANNER_TERMS_URL } from '../../../models/Legal'
import validateEmail from '../../../helpers/validate-email'
import { ModalContent } from '../../../components/ModalWindow'
import { ModalContentWrapper } from '../../../ui'
import Loader from '../../../ui/Loader'
import validatePhone from '../../../helpers/validate-phone'
import GigRequestQuotePrintModal from '../../../components/gig-request-quote-print-modal'

const LONG_DATE_FORMAT = 'D. MMMM YYYY'

const GigRequestBookingForm = React.memo(
  ({
    quoteId,
    gigRequestId,
    artistId,
    artistName,
    eventTypeCategory,
    eventDate,
    invoiceOrganization,
    invoiceName,
    invoiceAddress,
    invoiceZip,
    invoiceCity,
    invoiceRef,
    contactName,
    contactPhone,
    contactEmail,
    onChangeContactForm,
    onChangeInvoiceForm,
    acceptedTerms,
    onChangeAcceptedTerms,
    onSubmit,
    errorMessages,
    submitting,
    submitted,
    history,
    printQuoteUrl,
    status,
  }) => {
    const startTime = moment(eventDate)
    const backUrl = `/eventplanner/inbox/${gigRequestId}/${artistId}`
    const errors =
      Object.keys(errorMessages).length > 0
        ? Object.keys(errorMessages).map(e => errorMessages[e])
        : []

    if (status !== REQUEST_STATUS.ACTIVE) {
      return (
        <FormWrapper>
          <H1>Forespørsel</H1>
          <Intro>Forespørselen til {artistName} er allerede fullført.</Intro>
          <Action>
            <Button to={backUrl} theme="secondary" disabled={submitting}>
              Tilbake
            </Button>
          </Action>
        </FormWrapper>
      )
    }
    return (
      <FormWrapper>
        <H1>Book {artistName}</H1>
        <Intro>
          Du er nå klar til å booke {artistName}. Pris er endelig og inkluderer artistens reise,
          sosiale og administrative utgifter. Beløpet blir i sin helhet fakturert av {artistName}{' '}
          {startTime.format(LONG_DATE_FORMAT)} med 14 dagers forfall.
        </Intro>
        <GigRequestQuotePrintModal
          id="print-preview-quote"
          url={printQuoteUrl}
          renderButton={buttonProps => (
            <Button type="button" theme="secondary" {...buttonProps}>
              Forhåndsvis kontrakt
            </Button>
          )}
        />
        <HeadingWrapper>
          <H2>Fakturainformasjon</H2>
        </HeadingWrapper>
        {eventTypeCategory === gigRequestType.BUSINESS ? (
          <Row>
            <Input
              type="text"
              id="invoice-organization"
              label="Organisasjonsnavn"
              value={invoiceOrganization}
              name="organization"
              onChange={onChangeInvoiceForm}
              expanded
              required
              errorMessage={errorMessages['invoice-organization'] || ''}
              validated={!!(invoiceOrganization && invoiceOrganization.length > 2)}
            />
          </Row>
        ) : null}
        <Row>
          <Input
            type="text"
            id="invoice-name"
            name="name"
            label="Fullt navn"
            value={invoiceName}
            onChange={onChangeInvoiceForm}
            expanded
            required
            errorMessage={errorMessages['invoice-name'] || ''}
            validated={!!(invoiceName && invoiceName.length > 2)}
          />
        </Row>
        <Row>
          <Input
            type="text"
            id="invoice-address"
            label="Adresse"
            value={invoiceAddress}
            name="address"
            onChange={onChangeInvoiceForm}
            expanded
            required
            errorMessage={errorMessages['invoice-address'] || ''}
            validated={!!(invoiceAddress && invoiceAddress.length > 2)}
          />
        </Row>
        <Row>
          <Column>
            <Input
              type="text"
              id="invoice-zip"
              label="Postnummer"
              value={invoiceZip}
              name="zip"
              onChange={onChangeInvoiceForm}
              expanded
              required
              errorMessage={errorMessages['invoice-zip'] || ''}
              validated={!!(invoiceZip && invoiceZip.length === 4)}
            />
          </Column>
          <Column>
            <Input
              type="text"
              id="invoice-city"
              label="Poststed"
              value={invoiceCity}
              name="city"
              onChange={onChangeInvoiceForm}
              expanded
              required
              errorMessage={errorMessages['invoice-city'] || ''}
              validated={!!(invoiceCity && invoiceCity.length > 2)}
            />
          </Column>
        </Row>
        {eventTypeCategory === gigRequestType.BUSINESS ? (
          <Row>
            <Input
              type="text"
              id="invoice-ref"
              label="Merk faktura"
              value={invoiceRef}
              name="ref"
              onChange={onChangeInvoiceForm}
              expanded
              showNotRequired
            />
          </Row>
        ) : null}
        <HeadingWrapper>
          <H2>Kontaktinformasjon for arrangementet</H2>
        </HeadingWrapper>
        <Row>
          <Input
            type="text"
            id="contact-name"
            label="Fullt navn"
            value={contactName}
            name="name"
            onChange={onChangeContactForm}
            expanded
            required
            errorMessage={errorMessages['contact-name'] || ''}
            validated={!!(contactName && contactName.length > 2)}
          />
        </Row>
        <Row>
          <Input
            type="phone"
            id="contact-phone"
            label="Telefon"
            value={contactPhone}
            name="phone"
            onChange={onChangeContactForm}
            expanded
            required
            errorMessage={errorMessages['contact-phone'] || ''}
            validated={!!(contactPhone && contactPhone.length > 2 && validatePhone(contactPhone))}
          />
        </Row>
        <Row>
          <Input
            type="email"
            id="contact-email"
            label="E-post"
            value={contactEmail}
            name="email"
            onChange={onChangeContactForm}
            expanded
            required
            errorMessage={errorMessages['contact-email'] || ''}
            validated={!!(contactEmail && contactEmail.length > 2 && validateEmail(contactEmail))}
          />
        </Row>

        <Row>
          <Checkbox
            id="accept-terms"
            name="accept-terms"
            value="1"
            checked={acceptedTerms}
            onChange={onChangeAcceptedTerms}
            renderLabel={props => (
              <Label {...props}>
                <TermsTextContainer>
                  <TermsText>Jeg har lest igjennom og godkjenner </TermsText>
                  <GigRequestQuotePrintModal
                    id="print-preview-quote"
                    url={printQuoteUrl}
                    renderButton={buttonProps => (
                      <ContractButton {...buttonProps}>kontrakten</ContractButton>
                    )}
                  />
                  <TermsText>, samt Gigplanet sine </TermsText>
                  <TermsLink href={EVENT_PLANNER_TERMS_URL} target="_blank">
                    vilkår
                  </TermsLink>
                  .
                </TermsTextContainer>
              </Label>
            )}
          />
        </Row>
        {errors.length > 0 ? (
          <Row>
            <Errormessage messages={errors} />
          </Row>
        ) : null}
        {!submitted ? (
          <Action>
            <Button
              type="button"
              theme="primary"
              onClick={onSubmit}
              loading={submitting}
              disabled={submitting}
            >
              Book nå
            </Button>
            <Button to={backUrl} theme="secondary" disabled={submitting}>
              Avbryt
            </Button>
          </Action>
        ) : null}
        <TermsWrapper>
          <H4>Avlysning og verneting</H4>
          <Terms>
            Dersom {contactName || 'kunde'} avlyser skal artisten godgjøres som om arrangementet var
            blitt gjennomført. Artisten plikter likevel å søke å begrense sitt tap. Ved sykdom som
            hindrer gjennomgøring av oppdraget skal artisten snarest mulig fremlegge sykmelding. Ved
            udokumentert uteblivelse fra artistens side kan oppdragsgiver kreve erstatning for
            direkte og dokumenterbart tap dersom uteblivelsen ikke skyldes forhold som ligger
            utenfor artistens kontroll. Lokale hendelser gjelder ikke som avlysningsgrunn med mindre
            politiet stopper arrangementet.
          </Terms>
        </TermsWrapper>
        {submitting ? (
          <ModalContent id="submitting-modal" isOpen label="Submitting quote" disableFocus>
            <ModalContentWrapper>
              <Loader text={`Booker ${artistName}`} />{' '}
            </ModalContentWrapper>
          </ModalContent>
        ) : null}
        {submitted ? (
          <ModalContent id="submitting-modal" isOpen label="Submitting quote">
            <ModalContentWrapper>
              <H1>Takk!</H1>
              <Intro>
                Du har nå booket {artistName}. Lykke til med ditt arrangement, og ikke nøl med å ta
                kontakt om det er noe du lurer på.
              </Intro>
              <Button theme="primary" onClick={() => history.push(backUrl)}>
                Lukk
              </Button>
            </ModalContentWrapper>
          </ModalContent>
        ) : null}
      </FormWrapper>
    )
  },
)

GigRequestBookingForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  eventDate: PropTypes.string.isRequired,
  quoteId: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,
  gigRequestId: PropTypes.string.isRequired,
  artistName: PropTypes.string.isRequired,
  eventTypeCategory: PropTypes.string.isRequired,
  invoiceOrganization: PropTypes.string.isRequired,
  invoiceName: PropTypes.string.isRequired,
  invoiceAddress: PropTypes.string.isRequired,
  invoiceZip: PropTypes.string.isRequired,
  invoiceCity: PropTypes.string.isRequired,
  invoiceRef: PropTypes.string.isRequired,
  onChangeInvoiceForm: PropTypes.func.isRequired,
  contactName: PropTypes.string.isRequired,
  contactPhone: PropTypes.string.isRequired,
  contactEmail: PropTypes.string.isRequired,
  onChangeContactForm: PropTypes.func.isRequired,
  errorMessages: PropTypes.shape({}),
  submitting: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  acceptedTerms: PropTypes.bool.isRequired,
  onChangeAcceptedTerms: PropTypes.func.isRequired,
  printQuoteUrl: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}

GigRequestBookingForm.defaultProps = {}

export default compose(withRouter)(GigRequestBookingForm)
