import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const FacebookIcon = (props) => (
  <SVGWrapper width="32" height="32" viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.266 27.2684H22.525V19.8431C22.525 18.0701 22.49 15.793 20.058 15.793C17.587 15.793 17.21 17.7211 17.21 19.7131V27.2684H12.469V11.9969H17.02V14.083H17.083C17.717 12.8829 19.266 11.6169 21.575 11.6169C26.379 11.6169 27.266 14.779 27.266 18.8931V27.2684ZM7.119 9.90882C5.594 9.90882 4.365 8.67578 4.365 7.15574C4.365 5.63869 5.594 4.40465 7.119 4.40465C8.636 4.40465 9.87 5.63869 9.87 7.15574C9.87 8.67578 8.636 9.90882 7.119 9.90882ZM4.742 27.2684H9.494V11.9969H4.742V27.2684ZM29.63 -0.000488281H2.36C1.058 -0.000488281 0 1.03254 0 2.30558V29.6924C0 30.9685 1.058 31.9995 2.36 31.9995H29.63C30.934 31.9995 32 30.9685 32 29.6924V2.30558C32 1.03254 30.934 -0.000488281 29.63 -0.000488281Z"
      fill="currentColor"
    />
  </SVGWrapper>
)

export default FacebookIcon
