import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const SortIcon = props => (
  <SVGWrapper width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </SVGWrapper>
)

export default SortIcon
