import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  font-size: 12px;
  text-align: center;
`

const ArtistCardMembers = ({ artistType, members }) => {
  const memberCount = members ? members.length : 0
  let memberCountText = ''
  if (artistType === 'solo') {
    memberCountText = '1 medlem'
  } else if (memberCount > 0) {
    memberCountText = memberCount === 1 ? `${memberCount} medlem` : `${memberCount} medlemmer`
  }
  return <Container>{memberCountText}</Container>
}

ArtistCardMembers.propTypes = {
  artistType: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
    }),
  ),
}

ArtistCardMembers.defaultProps = {
  members: [],
}

export default ArtistCardMembers
