import styled from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const Message = styled.p`
  font-size: 16px;
  margin: 0;
  line-height: 20px;
  font-weight: 300;
  text-align: left;
  padding-bottom: 15px;
  color: ${gigplanetTheme.element.default.text};
  ${mediaQueries.mobile`
    font-size: 14px;
    line-height: 16px;
  `};
`

export const Link = styled.a`
  color: ${gigplanetTheme.element.default.text};
  font-weight: 400;
`
