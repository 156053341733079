import * as React from 'react'
import PropTypes from 'prop-types'

import { Column } from './style'

const FormColumn = ({ className, columnCount, id, children }) => (
  <Column columnCount={columnCount} id={id}>
    {children}
  </Column>
)

FormColumn.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  columnCount: PropTypes.number,
  children: PropTypes.node,
}

export default FormColumn
