import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const HeartIcon = (props) => (
  <SVGWrapper width="28" height="24" viewBox="0 0 28 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.387 2.16576C15.0679 2.48015 14.0189 3.57786 13.7749 3.93303C13.5298 3.57786 12.482 2.48015 12.1628 2.16576C9.44292 -0.513585 5.0336 -0.513585 2.31481 2.16576C-0.405067 4.8451 -0.405067 9.18762 2.31481 11.867L4.62077 14.1386L8.85036 18.3062L13.7749 23.1562L18.0916 18.9028L22.929 14.1386L25.235 11.867C27.9549 9.18762 27.9549 4.8451 25.235 2.16576C22.5151 -0.513585 18.1069 -0.513585 15.387 2.16576Z"
      fill="#FF785D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7786 7.45978L12.1288 5.06892C12.1316 5.07305 12.1303 5.07136 12.1238 5.06332C12.1072 5.04268 12.057 4.98017 11.9577 4.86634C11.8383 4.72961 11.6894 4.56558 11.5309 4.3949C11.2101 4.04933 10.8902 3.71959 10.7592 3.59055C8.81776 1.678 5.65867 1.6784 3.71865 3.59027C1.79403 5.4862 1.79375 8.54624 3.71837 10.4422L6.02433 12.7138L13.7746 20.3488L16.6879 17.4782L21.5255 12.7138L23.8314 10.4422C25.7561 8.54624 25.7561 5.48648 23.8314 3.59055C21.8901 1.67815 18.7319 1.67815 16.7906 3.59055C16.6592 3.71997 16.3388 4.04996 16.0182 4.3952C15.8597 4.56582 15.711 4.72963 15.5919 4.86606C15.495 4.97723 15.4454 5.0388 15.4285 5.05986C15.4213 5.06881 15.42 5.07045 15.4234 5.0655L13.7786 7.45978ZM13.7749 23.1562L2.31481 11.867C-0.405067 9.18762 -0.405067 4.8451 2.31481 2.16576C5.0336 -0.513585 9.44292 -0.513585 12.1628 2.16576C12.2243 2.22638 12.313 2.31612 12.4177 2.42406C12.8562 2.87587 13.5771 3.64634 13.7749 3.93303C13.9718 3.64637 14.6931 2.87603 15.1318 2.4242C15.2367 2.3162 15.3254 2.22641 15.387 2.16576C18.1069 -0.513585 22.5151 -0.513585 25.235 2.16576C27.9549 4.8451 27.9549 9.18762 25.235 11.867L13.7749 23.1562Z"
      fill="#FA5C3C"
    />
  </SVGWrapper>
)

export default HeartIcon
