import styled from 'styled-components'

import mediaQueries from '../../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../../styles/gigplanetTheme'

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 10px 10px 0;
`

export const Artists = styled.div`
  background: ${gigplanetTheme.color.white};
  margin-top: 50px;
  width: 100%;

  ${mediaQueries.mobile`
    margin-top: 30px;
  `};
`

export const ArtistsContent = styled.div`
  width: 100%;
  max-width: ${gigplanetTheme.measure.maxWidth};
  margin: 0 auto;
`
