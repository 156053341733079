import * as React from 'react'
import PropTypes from 'prop-types'
import { InputWrapper } from './style'

const UserAuthInputWrapper = ({ children }) => <InputWrapper>{children}</InputWrapper>

UserAuthInputWrapper.propTypes = {
  children: PropTypes.element.isRequired,
}

export default React.memo(UserAuthInputWrapper)
