import styled, { keyframes } from 'styled-components'

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.1); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.1); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
`

export const UnreadCount = styled.span`
  animation: ${pulseAnimation} 2s linear 0s infinite;
  height: 18px;
  min-width: 18px;
  padding: 0 0.25rem;
  background: #32e08e;
  border-radius: 100%;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
`

export const UnreadCountMark = styled.span`
  height: 10px;
  width: 10px;
  background: #32e08e;
  border-radius: 100%;
  display: block;
`
