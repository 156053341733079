import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const ArrowBackIcon = (props) => (
  <SVGWrapper width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9396 0.939331L14.0609 3.06065L5.12155 12L14.0609 20.9393L11.9396 23.0606L0.878906 12L11.9396 0.939331Z"
      fill="currentColor"
    />
  </SVGWrapper>
)

export default ArrowBackIcon
