import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { ArtistShape } from '../../../models/Artist'

import { ArtistLink, ArtistName, Unread } from './style'
import ArtistAvatar from '../../../ui/ArtistAvatar'

const Artist = ({ requestId, artist, unreadCount, history }) => (
  <ArtistLink
    to={`/eventplanner/inbox/${requestId}/${artist.id}`}
    onClick={e => {
      e.preventDefault()
      e.stopPropagation()
      history.push(`/eventplanner/inbox/${requestId}/${artist.id}`)
    }}
  >
    <ArtistAvatar artist={artist} size={40} />
    <ArtistName>{artist.name}</ArtistName>
    {unreadCount > 0 ? <Unread /> : null}
  </ArtistLink>
)

Artist.propTypes = {
  artist: ArtistShape.isRequired,
  requestId: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  unreadCount: PropTypes.number,
}

Artist.defaultProps = {
  unreadCount: 0,
}

export default withRouter(Artist)
