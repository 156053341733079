import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const Container = styled.div`
  text-align: left;
  background: #f3d6d6;
  padding: ${gigplanetTheme.spacing.small};
  border: 1px solid #ecc1c1;
  width: 100%;
`

const Heading = styled.h2`
  color: #c33333;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  padding: 0;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
const Message = styled.li`
  font-size: 14px;
  color: #c33333;
`

const Errormessage = ({ messages }) => {
  if (!messages || messages.length === 0) {
    return null
  }
  const messagesElements = messages.map(message => <Message key={message}>{message}</Message>)

  return (
    <Container>
      <Heading>Feilmelding</Heading>
      <List>{messagesElements}</List>
    </Container>
  )
}

Errormessage.propTypes = {
  messages: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default Errormessage
