import styled from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'

export const UserItem = styled.div`
  position: relative;

  ${mediaQueries.mobile`
    display: none;
  `};
`

export const AvatarWrapper = styled.span`
  align-self: center;
  display: block;
  margin-right: 10px;

  ${mediaQueries.tabletPortraitDown`
    display: none;
  `};
`
