import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const StickyWrapper = styled.div``

export const StyledStickyHeader = styled.header`
  position: absolute;
  transition: background 0.3s;
  top: 0;
  width: 100%;
  z-index: ${gigplanetTheme.zIndex.header};
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-bottom: 1px solid transparent;

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: fixed;
      background: white;
      border-bottom: 1px solid ${gigplanetTheme.color.lightGray};
    `};

  ${mediaQueries.mobile`
    height: 50px;
  `};
`

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 ${gigplanetTheme.spacing.large};
  max-width: ${gigplanetTheme.measure.maxWidth};

  ${mediaQueries.mobile`
    padding: 0 ${gigplanetTheme.spacing.xsmall};
  `}
`

export const LogoLink = styled(Link)`
  height: 40px;

  svg {
    height: 40px;
    width: 130px;

    * {
      transition: fill 0.3s;
    }
  }

  ${mediaQueries.tabletPortraitDown`
    height: 30px;

    svg {
      height: 30px;
      width: 100px;
     
    }
  `};
`
