import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import MainImage from './components/MainImage'
import Author from './components/Author'

import { H1 } from '../../ui/Headings'
import mediaQueries from '../../styles/mediaQueries'
import Body from './components/Body'
import Categories from './components/Categories'

const Container = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Content = styled.div`
  max-width: 42.5rem;
  padding: 2rem;

  ${mediaQueries.mobile`
    padding: 1rem;
  `};
`
const AuthorWrapper = styled.div`
  padding: 1rem 0;
`
const Intro = styled.p`
  margin: 0;
  line-height: 1.5;
  font-size: 1.25rem;

  ${mediaQueries.mobile`
    font-size: 1rem;
  `};
`

const Article = React.memo(({ title, intro, mainImage, author, publishedAt, body, categories }) => {
  return (
    <Container>
      <MainImage image={mainImage} altText={title} />
      <Content>
        <H1>{title}</H1>
        <AuthorWrapper>
          <Author authorImage={author.image} name={author.name} date={publishedAt} />
        </AuthorWrapper>
        <Intro>{intro}</Intro>
        <Body content={body} />
        <Categories categories={categories} />
      </Content>
    </Container>
  )
})

Article.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  author: PropTypes.shape({
    image: PropTypes.shape({}),
    name: PropTypes.string,
  }).isRequired,
  mainImage: PropTypes.shape({}).isRequired,
  publishedAt: PropTypes.string.isRequired,
}

export default Article
