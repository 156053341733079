import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router'

import { fetchLandingPageIfNeededAction } from '../../stores/cms-content/actions'

import LandingPageContainer from '../../containers/landing-page'

const CMSContentRoute = React.memo(({ match: { params } }) => (
  <LandingPageContainer slug={params.slug} />
))

CMSContentRoute.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }),
}

CMSContentRoute.defaultProps = {}

CMSContentRoute.dispatchActions = (req, dispatch, params) => {
  let slug = params && params.slug ? params.slug : ''
  let previewId = ''
  const parts = slug.split('?')
  if (parts.length > 1) {
    slug = parts[0]
    if (parts[1]) {
      const subParts = parts[1].split('=')
      const idx = subParts.indexOf('cms-preview')
      if (idx !== -1) {
        previewId = subParts[idx + 1]
      }
    }
  }

  return [dispatch(fetchLandingPageIfNeededAction(slug, previewId))]
}

export default compose(withRouter)(CMSContentRoute)
