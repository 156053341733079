import styled from 'styled-components'

import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const Container = styled.div`
  min-width: 320px;
  max-width: 450px;
  width: 450px;
  margin: 0;
  padding: ${gigplanetTheme.spacing.medium};
  text-align: center;
  border: 0;

  ${mediaQueries.mobile`
    border: 0;
    margin: 0;
    padding: ${gigplanetTheme.spacing.small};
    width: 100%
  `};
`
