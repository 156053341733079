import styled, { css } from 'styled-components'
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

/* $border-color: $gigplanet-menu-border-color;
$cc-background-color: rgba(0, 0, 0, 0.5);
$cc-color: $gigplanet-white-color; */

const borderColor = gigplanetTheme.element.default.border
export const ProfileDetailsImageContainer = styled.div`
  border-bottom: 1px solid ${borderColor};
`

export const ProfileDetailsImageItem = styled.li`
  display: inline-block;
  max-width: 600px;

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }

  ${mediaQueries.mobile`
    padding: 0 ${gigplanetTheme.spacing.xxsmall};
    width: 90%;
    flex: 1 0 90%;   
  `};

  ${mediaQueries.tabletPortraitUp`
    padding: 0 ${gigplanetTheme.spacing.small};
    width: 30%;
    flex: 1 0 30%;
  `};
`
export const ProfileDetailsImage = styled.img`
  display: inline-block;
  max-width: 100%;
  width: auto;
  height: auto;
`

export const ProfileDetailsImageButton = styled.button`
  border: 0;
  background: transparent;
  padding: 0;
  display: inline-block;
  max-width: 100%;
  width: auto;
  height: auto;
  position: relative;
  cursor: pointer;
`

export const ProfileDetailsImageCC = styled.span`
  display: block;
  height: 20px;
  line-height: 16px;
  color: white;
  background: $cc-background-color;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: $cc-color;
  font-size: 12px;
  text-align: left;
  padding: 2px;
`
