import * as React from 'react'
import PropTypes from 'prop-types'

import FocusTrap from 'focus-trap-react'
import noScroll from '../../libs/noScroll'
import { ArtistShape } from '../../models/Artist'
import { CurrentUserShape } from '../../models/CurrentUser'

import { HeaderWrapper } from './components/style'

import Logo from './components/logo'
import LoggedOutMenu from '../../containers/header-menu-logged-out'
import ControlpanelMenu from '../../containers/header-menu-controlpanel'
import EventplannerMenu from '../../containers/header-menu-eventplanner'
import LoggedInMenu from '../../containers/header-menu-logged-in'

const Header = ({
  currentUser,
  showControlpanelMenu,
  showEventplannerMenu,
  showSearch,
  fullInvertedMenu,
}) => {
  const [focus, setFocus] = React.useState(false)

  const focusCallback = (focusState) => {
    setFocus(focusState)
  }

  React.useEffect(() => {
    if (focus) {
      noScroll.on()
    } else {
      noScroll.off()
    }
  }, [focus])

  return (
    <FocusTrap active={focus}>
      <HeaderWrapper role="banner" inverted={fullInvertedMenu} isOpen={focus}>
        <Logo
          inverted={focus ? false : fullInvertedMenu}
          onClick={() => {
            setFocus(false)
          }}
        />
        {!{}.hasOwnProperty.call(currentUser, 'id') ? (
          <LoggedOutMenu
            showSearch={showSearch}
            hasDarkBg={focus ? false : fullInvertedMenu}
            focusCallback={focusCallback}
          />
        ) : (
          <>
            <LoggedInMenu
              showSearch={showSearch}
              showControlpanelMenu={showControlpanelMenu}
              showEventplannerMenu={showEventplannerMenu}
              hasDarkBg={focus ? false : fullInvertedMenu}
              focusCallback={focusCallback}
            />
          </>
        )}
      </HeaderWrapper>
    </FocusTrap>
  )
}

Header.propTypes = {
  currentUser: CurrentUserShape,
  showControlpanelMenu: PropTypes.bool,
  showEventplannerMenu: PropTypes.bool,
  showSearch: PropTypes.bool,
  fullInvertedMenu: PropTypes.bool,
}

Header.defaultProps = {
  currentUser: {},
  showControlpanelMenu: false,
  showEventplannerMenu: false,
  showSearch: false,
  fullInvertedMenu: false,
}

export default Header
