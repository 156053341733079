import * as React from 'react'
import PropTypes from 'prop-types'

import { Image, Placeholder } from './style'
import { getImgixCroppedUrl, ARTIST_IMAGE_IMGIX_FORMAT_THUMB } from '../../../models/Artist'
import { CameraIcon } from '../../../icons'

const ArtistCardImage = ({ artistImage, artistName }) => {
  if (artistImage && artistImage.src) {
    return (
      <Image
        style={{
          backgroundImage: `url(${getImgixCroppedUrl(
            artistImage.src,
            ARTIST_IMAGE_IMGIX_FORMAT_THUMB,
            artistImage.crop,
          )})`,
        }}
        title={artistName}
      />
    )
  }

  return (
    <Placeholder>
      <CameraIcon />
    </Placeholder>
  )
}

ArtistCardImage.propTypes = {
  artistImage: PropTypes.shape({
    src: PropTypes.string,
    crop: PropTypes.object,
  }).isRequired,
  artistName: PropTypes.string.isRequired,
}

export default ArtistCardImage
