import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import styled, { css } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'

import gigplanetTheme from '../../styles/gigplanetTheme'

import { ArtistShape, getShortSlogan } from '../../models/Artist'
import { CategoryShape } from '../../models/Category'

import ArtistCardImage from './components/ArtistCardImage'
import ArtistCardHeading from './components/ArtistCardHeading'
import ArtistCardSlogan from './components/ArtistCardSlogan'
import ArtistCardGenres from './components/ArtistCardGenres'
import ArtistCardMembers from './components/ArtistCardMembers'
import ArtistCardRatings from './components/ArtistCardRatings'
import ArtistCardPrice from './components/ArtistCardPrice'
import ArtistCardFeatures from './components/ArtistCardFeatures'

import FavoriteButton from '../../containers/favorite-button'

const ReactRouterLink = ({ to, children, ...rest }) => (
  <Link to={to} {...rest}>
    {children}
  </Link>
)

ReactRouterLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
}

const Container = styled(ReactRouterLink)`
  background: ${gigplanetTheme.element.artistCard.background};
  color: ${gigplanetTheme.element.artistCard.text};
  border: 1px solid ${gigplanetTheme.element.artistCard.border};
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 300px;
  position: relative;
  text-decoration: none;
  border-radius: 4px;
  height: 100%;
  transition: all 0.1s ease-in-out;

  &:hover {
    box-shadow: 0 5px 20px rgba(57, 0, 135, 0.3);
    transform: translateY(-1px);
  }

  ${({ isMenu }) =>
    isMenu &&
    css`
      align-items: flex-start;
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      color: $text-color;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      min-width: 100px;
      position: relative;
      text-decoration: none;
      flex: 0 1 auto;
    `};
`

const Content = styled.div`
  padding: 0 20px;
  position: relative;
`

const ArtistCardFooter = styled.div`
  border: 0;
  border-top: 1px solid #d8d8d8;
  font-size: 0;
  margin: auto 0 0;
  padding: 14px 20px 14px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const FavoriteWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  ${({ isMenu }) =>
    isMenu &&
    css`
      right: 0;
      top: 5px;
    `};
`

const ArtistCard = ({
  artist,
  categories,
  hideFavoriteActions,
  onTrackClick,
  onTrackView,
  index,
}) => {
  const [visible, setVisible] = React.useState(false)

  const handleVisibilityChange = (isVisible) => {
    if (visible || !isVisible) {
      return
    }
    setVisible(true)
    if (onTrackView) {
      onTrackView(artist.id)
    }
  }

  const handleClick = () => {
    if (onTrackClick) {
      onTrackClick(artist.id, index)
    }
  }

  let artistImage = {}
  if (artist.images && artist.images.length > 0) artistImage = artist.images[0]

  let minPrice = ''
  if (artist && artist.facts && artist.facts.priceMin) {
    minPrice = artist.facts.priceMin
  }

  return (
    <VisibilitySensor onChange={handleVisibilityChange} active={!visible}>
      <Container to={`/${artist.artistType || 'artist'}/${artist.slug}`} onClick={handleClick}>
        <ArtistCardImage artistImage={artistImage} artistName={artist.name} />
        {!hideFavoriteActions ? (
          <FavoriteWrapper>
            <FavoriteButton artistName={artist.name} artistId={artist.id} round />
          </FavoriteWrapper>
        ) : null}

        <Content>
          <ArtistCardFeatures
            gotImages={artist.hasMultiImages}
            gotVideo={artist.hasVideo}
            gotAudio={artist.hasAudio}
          />

          <ArtistCardHeading>{artist.name}</ArtistCardHeading>

          <ArtistCardSlogan>{getShortSlogan(artist, categories) || ' '}</ArtistCardSlogan>

          <ArtistCardGenres genres={artist.genres} />

          {artist.rating && artist.rating.reviews > 0 ? (
            <ArtistCardRatings artistRating={artist.rating} />
          ) : null}
        </Content>
        <ArtistCardFooter>
          <ArtistCardMembers artistType={artist.artistType} members={artist.members} />
          <ArtistCardPrice minPrice={minPrice} />
        </ArtistCardFooter>
      </Container>
    </VisibilitySensor>
  )
}

ArtistCard.propTypes = {
  artist: ArtistShape.isRequired,
  categories: PropTypes.objectOf(CategoryShape).isRequired,
  hideFavoriteActions: PropTypes.bool,
  onTrackClick: PropTypes.func,
  onTrackView: PropTypes.func,
  index: PropTypes.number,
}

ArtistCard.defaultProps = {
  hideFavoriteActions: false,
  onTrackClick: null,
  onTrackView: null,
  index: 0,
}

export default withRouter(ArtistCard)
