import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { useSelector } from 'react-redux'

import LoggedOutDektopMenu from './components/logged-out-desktop-menu'
import LoggedOutMobileMenu from './components/logged-out-mobile-menu'
import { getFavoriteArtists } from '../../stores/Artist/ArtistReducer'

const HeaderMenuLoggedOutContainer = React.memo(
  ({ location, showSearch = false, hasDarkBg, focusCallback }) => {
    const favoriteArtists = useSelector(getFavoriteArtists)
    const favoriteCount = favoriteArtists && favoriteArtists.length

    const successUrl = location.pathname
    return (
      <>
        <LoggedOutMobileMenu
          successUrl={successUrl}
          favoriteCount={favoriteCount}
          showSearch={showSearch}
          hasDarkBg={hasDarkBg}
          focusCallback={focusCallback}
        />
        <LoggedOutDektopMenu
          successUrl={successUrl}
          favoriteCount={favoriteCount}
          showSearch={showSearch}
          hasDarkBg={hasDarkBg}
          focusCallback={focusCallback}
        />
      </>
    )
  },
)

HeaderMenuLoggedOutContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  showSearch: PropTypes.bool,
  hasDarkBg: PropTypes.bool,
  focusCallback: PropTypes.func,
}

export default withRouter(HeaderMenuLoggedOutContainer)
