import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { resetFormsAction } from '../../stores/Auth/AuthActions'

import UserAuthLoginForm from '../user-auth-login-form'
import UserAuthRegisterOptions from '../user-auth-register-options'
import UserAuthRegisterForm from '../user-auth-register-form'
import UserAuthForgotPasswordForm from '../user-auth-forgot-password-form'
import UserAuthNewPasswordForm from '../user-auth-new-password-form'
import UserAuthVerifyEmailForm from '../user-auth-verify-email-form'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  resetForms: () => {
    dispatch(resetFormsAction())
  },
})

const SHOW_OPTIONS = {
  LOGIN: 1,
  REGISTER_OPTIONS: 2,
  REGISTER_FORM: 3,
  NEW_PASSWORD: 4,
  FORGOT_PASSWORD: 5,
  VERIFY: 6,
}

class UserAuthWindowContainer extends React.Component {
  constructor(props) {
    super(props)
    let showOption = SHOW_OPTIONS.LOGIN
    let registerFormNext = false
    const { showRegister, showNewPassword, showForgotPassword, showVerify } = this.props
    if (showRegister) {
      showOption = SHOW_OPTIONS.REGISTER_OPTIONS
      registerFormNext = true
    } else if (showNewPassword) {
      showOption = SHOW_OPTIONS.NEW_PASSWORD
    } else if (showForgotPassword) {
      showOption = SHOW_OPTIONS.FORGOT_PASSWORD
    } else if (showVerify) {
      showOption = SHOW_OPTIONS.VERIFY
    }

    this.state = {
      showOption,
      registerFormNext,
    }
  }

  componentDidMount() {
    const { resetForms } = this.props
    resetForms()
  }

  handleShowNewPassword = () => {
    this.setState({
      showOption: SHOW_OPTIONS.NEW_PASSWORD,
    })
  }

  handleShowForgotPassword = () => {
    this.setState({
      showOption: SHOW_OPTIONS.FORGOT_PASSWORD,
    })
  }

  handleShowVerify = () => {
    this.setState({
      showOption: SHOW_OPTIONS.VERIFY,
    })
  }

  handleShowRegister = () => {
    const { registerFormNext } = this.state
    this.setState({
      showOption: registerFormNext ? SHOW_OPTIONS.REGISTER_FORM : SHOW_OPTIONS.REGISTER_OPTIONS,
      registerFormNext: true,
    })
  }

  handleShowLogin = () => {
    this.setState({
      showOption: SHOW_OPTIONS.LOGIN,
    })
  }

  render() {
    const { intro, errorCode, hideHeading, continueMode, successUrl, onClose } = this.props
    const { showOption } = this.state
    switch (showOption) {
      case SHOW_OPTIONS.REGISTER_OPTIONS: {
        return (
          <UserAuthRegisterOptions
            intro={intro}
            hideHeading={hideHeading}
            continueMode={continueMode}
            successUrl={successUrl}
            onShowRegisterForm={this.handleShowRegister}
            onShowLoginForm={this.handleShowLogin}
            onClose={onClose}
          />
        )
      }
      case SHOW_OPTIONS.REGISTER_FORM: {
        return (
          <UserAuthRegisterForm
            hideHeading={hideHeading}
            continueMode={continueMode}
            successUrl={successUrl}
            onShowLoginForm={this.handleShowLogin}
            onClose={onClose}
          />
        )
      }

      case SHOW_OPTIONS.FORGOT_PASSWORD: {
        return (
          <UserAuthForgotPasswordForm
            hideHeading={hideHeading}
            continueMode={continueMode}
            onShowLoginForm={this.handleShowLogin}
            onClose={onClose}
          />
        )
      }
      case SHOW_OPTIONS.NEW_PASSWORD: {
        return (
          <UserAuthNewPasswordForm
            hideHeading={hideHeading}
            continueMode={continueMode}
            onClose={onClose}
          />
        )
      }
      case SHOW_OPTIONS.VERIFY: {
        return (
          <UserAuthVerifyEmailForm
            hideHeading={hideHeading}
            continueMode={continueMode}
            onShowLoginForm={this.handleShowLogin}
            onClose={onClose}
          />
        )
      }
      case SHOW_OPTIONS.LOGIN:
      default:
        return (
          <UserAuthLoginForm
            intro={intro}
            errorCode={errorCode}
            hideHeading={hideHeading}
            continueMode={continueMode}
            successUrl={successUrl}
            onShowRegisterForm={this.handleShowRegister}
            onShowForgotPassword={this.handleShowForgotPassword}
            onShowVerify={this.handleShowVerify}
            onClose={onClose}
          />
        )
    }
  }
}

UserAuthWindowContainer.propTypes = {
  // normal props
  intro: PropTypes.string,
  errorCode: PropTypes.string,
  onClose: PropTypes.func,
  hideHeading: PropTypes.bool,
  continueMode: PropTypes.bool,
  successUrl: PropTypes.string,
  showRegister: PropTypes.bool,
  showNewPassword: PropTypes.bool,
  showForgotPassword: PropTypes.bool,
  showVerify: PropTypes.bool,
  // state props

  // dispatch props
  resetForms: PropTypes.func.isRequired,
}

UserAuthWindowContainer.defaultProps = {
  hideHeading: false,
  continueMode: false,
  intro: '',
  successUrl: '/',
  onClose: null,
  showRegister: false,
  showNewPassword: false,
  showForgotPassword: false,
  showVerify: false,
  errorCode: '',
}

const UserAuthWindowContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAuthWindowContainer)

export default UserAuthWindowContainerWrapper
