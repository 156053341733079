import * as React from 'react'
import PropTypes from 'prop-types'

import { USER_PROFILE_URL } from '../../models/CurrentUser'

import {
  StyledEventplannerUserProfile,
  Info,
  Separator,
  UserAvatarWrapper,
  InfoElement,
  InfoName,
} from './style'

import UserAvatar from '../../ui/UserAvatar'
import ContentContainer from '../../ui/ContentContainer'
import { H2 } from '../../ui/Headings'
import Button from '../../ui/Button'

const EventplannerUserProfile = ({ currentUser }) => (
  <ContentContainer>
    <StyledEventplannerUserProfile>
      <H2>Din profil</H2>
      <Separator />
      <Info>
        <UserAvatarWrapper>
          <UserAvatar size={100} user={currentUser} />
        </UserAvatarWrapper>
        <InfoName>{currentUser.firstName}</InfoName>
        <Separator />
        <InfoElement>{currentUser.email}</InfoElement>
        <InfoElement>{currentUser.phone}</InfoElement>
      </Info>
      <Button theme="secondary" to={USER_PROFILE_URL}>
        Endre profil
      </Button>
    </StyledEventplannerUserProfile>
  </ContentContainer>
)

EventplannerUserProfile.propTypes = {
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
  }),
}

EventplannerUserProfile.defaultProps = {}

export default EventplannerUserProfile
