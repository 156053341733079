/* globals window */
import * as React from 'react'
import PropTypes from 'prop-types'

import { ArtistShape } from '../../../../../models/Artist'
import { CurrentUserShape } from '../../../../../models/CurrentUser'
import {
  gigRequestType,
  getGigRequestFormArtists,
  translateServerErrorMessage,
} from '../../../../../models/GigRequest'

import { FormWrapper, Artists, ArtistsContent } from './style'

import GigRequestFormWrapper from '../../GigRequestFormWrapper'

import GigRequestFormProgress from '../../GigRequestFormProgress'
import GigRequestFormActionsWrapper from '../../GigRequestFormActionsWrapper'
import GigRequestFormHeader from '../../GigRequestFormHeader'
import GigRequestFormSubHeading from '../../GigRequestFormSubHeading'
import GigRequestFormError from '../../GigRequestFormError'

import Row from '../../../../FormRow'
import Column from '../../../../FormColumn'
import Button from '../../../../../ui/Button'
import { Input } from '../../../../../ui/Form'

const GigRequestFormRequester = React.memo(
  ({
    artist,
    eventTypeCategory,
    organization = '',
    onOrganizationChange,
    currentUser,
    phone = '',
    onPhoneChange,
    email = '',
    onEmailChange,
    onNext,
    onPrevious,
    errorMessages = null,
  }) => {
    return (
      <GigRequestFormWrapper>
        <GigRequestFormProgress step={3} stepCount={3} />
        <GigRequestFormHeader
          heading={`Kontakt ${artist ? artist.name : 'artisten'}`}
          intro={`Oppgi kontaktinformasjon, så vi kan varsle deg når ${
            artist ? artist.name : 'artisten'
          } svarer`}
          artist={artist}
        />
        <GigRequestFormSubHeading heading="Kontaktinformasjon" />
        <FormWrapper>
          {eventTypeCategory === gigRequestType.BUSINESS ? (
            <Row>
              <Column>
                <Input
                  id="organization"
                  name="organization"
                  label="Organisasjonsnavn"
                  placeholder="Skriv inn navnet til organisasjonen eller bedriften"
                  type="text"
                  value={organization}
                  onChange={onOrganizationChange}
                  errorMessage={errorMessages && errorMessages.organization}
                  expanded
                />
              </Column>
            </Row>
          ) : null}
          <Row>
            <Column>
              <Input
                id="phone"
                name="phone"
                label={currentUser.phone ? 'Bekreft mobil' : 'Mobil'}
                placeholder="Skriv inn mobil"
                type="tel"
                value={phone}
                onChange={onPhoneChange}
                errorMessage={errorMessages && errorMessages.phone}
                expanded
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Input
                id="email"
                name="email"
                label={currentUser.email ? 'Bekreft e-post' : 'E-post'}
                placeholder="Skriv inn e-post"
                type="email"
                value={email}
                onChange={onEmailChange}
                errorMessage={errorMessages && errorMessages.email}
                expanded
              />
            </Column>
          </Row>
        </FormWrapper>
        <GigRequestFormError errorMessages={errorMessages} />
        <GigRequestFormActionsWrapper>
          <Button type="button" theme="primary" onClick={onNext}>
            Send inn og fullfør
          </Button>
          <Button type="button" theme="transparent" onClick={onPrevious}>
            Tilbake
          </Button>
        </GigRequestFormActionsWrapper>
      </GigRequestFormWrapper>
    )
  },
)

GigRequestFormRequester.propTypes = {
  currentUser: CurrentUserShape.isRequired,
  artist: ArtistShape,
  eventTypeCategory: PropTypes.string.isRequired,
  organization: PropTypes.string,
  onOrganizationChange: PropTypes.func.isRequired,
  phone: PropTypes.string,
  onPhoneChange: PropTypes.func.isRequired,
  email: PropTypes.string,
  onEmailChange: PropTypes.func.isRequired,
  errorMessages: PropTypes.objectOf(PropTypes.string),
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
}

export default GigRequestFormRequester
