import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const ArrowUpwardIcon = props => (
  <SVGWrapper width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
  </SVGWrapper>
)

export default ArrowUpwardIcon
