/* globals window */
import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { compose } from 'recompose'

import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

import { getCurrentUser } from '../../stores/CurrentUser/CurrentUserReducer'
import { getUserArtists } from '../../stores/Artist/ArtistReducer'
import { getMyEventplannerRequests } from '../../stores/gig-request-eventplanner/reducer'
import { getMyArtistRequests } from '../../stores/GigRequestArtist/GigRequestArtistReducer'

import { trackEvent } from '../../libs/analytics'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Button from '../../ui/Button'
import GigplanetLogo from '../../ui/GigplanetLogo'
import StickyHeader from './components/sticky-header'

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  userArtists: getUserArtists(state),

  artistGigRequest: getMyArtistRequests(state),
  eventplannerGigRequest: getMyEventplannerRequests(state),
})

const mapDispatchToProps = () => ({})

const showIntercom = () => {
  trackEvent('simple-layout', 'simple-layout-clicked-need-help')
  if (window && window.Intercom) {
    window.Intercom('showNewMessage')
  }
}

const MainLayoutWrapper = styled.div`
  max-width: 100%;
`

const MainLayoutContent = styled.div`
  z-index: 1;
`

const SimpleHeader = styled.header`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 15px;

  ${({ gray }) =>
    gray &&
    css`
      background-color: ${gigplanetTheme.element.header.background};
    `};

  ${mediaQueries.tabletPortraitDown`
    height: 50px;
    padding: 0 5px;
  `};
`

const LogoLink = styled(Link)`
  svg {
    height: 40px;
    width: 130px;
  }

  ${mediaQueries.tabletPortraitDown`
    svg {
      height: 30px;
      width: 100px;
    }
  `};
`

const MainLayout = React.memo(
  ({
    children,
    simpleMenu,
    noMenu,
    stickyMenu,
    fullInvertedMenu,
    invertedMenu,
    overlayMenu,
    gray,
    currentUser,
    userArtists,
    artistGigRequest,
    eventplannerGigRequest,
    hideFooter,
    showControlpanelMenu,
    showEventplannerMenu,
    showSearch,
  }) => (
    <MainLayoutWrapper>
      <MainLayoutContent>
        {!noMenu ? (
          <>
            {simpleMenu ? (
              <SimpleHeader gray={gray}>
                <LogoLink to="/">
                  <GigplanetLogo role="presentation" alt="Gigplanet" />
                </LogoLink>
                <Button type="button" theme="secondary" onClick={showIntercom}>
                  Trenger du hjelp?
                </Button>
              </SimpleHeader>
            ) : null}
            {stickyMenu ? (
              <StickyHeader invertedMenu={invertedMenu} overlayMenu={overlayMenu} />
            ) : null}
            {!simpleMenu && !stickyMenu ? (
              <Header
                fullInvertedMenu={fullInvertedMenu}
                currentUser={currentUser}
                userArtists={userArtists}
                artistGigRequest={artistGigRequest}
                eventplannerGigRequest={eventplannerGigRequest}
                showControlpanelMenu={showControlpanelMenu}
                showEventplannerMenu={showEventplannerMenu}
                showSearch={showSearch}
              />
            ) : null}
          </>
        ) : null}
        <main>{children}</main>
        {!hideFooter ? <Footer /> : null}
      </MainLayoutContent>
    </MainLayoutWrapper>
  ),
)

MainLayout.propTypes = {
  children: PropTypes.node,
  hideFooter: PropTypes.bool,
  currentUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  userArtists: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  artistGigRequest: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  eventplannerGigRequest: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  simpleMenu: PropTypes.bool,
  noMenu: PropTypes.bool,
  stickyMenu: PropTypes.bool,
  invertedMenu: PropTypes.bool,
  overlayMenu: PropTypes.bool,
  gray: PropTypes.bool,
  showControlpanelMenu: PropTypes.bool,
  showEventplannerMenu: PropTypes.bool,
  showSearch: PropTypes.bool,
}

MainLayout.defaultProps = {
  simpleMenu: false,
  noMenu: false,
  stickyMenu: false,
  invertedMenu: false,
  overlayMenu: false,
  gray: false,
  showControlpanelMenu: false,
  showEventplannerMenu: false,
  showSearch: false,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(MainLayout)
