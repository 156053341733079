import * as React from 'react'
import PropTypes from 'prop-types'

import {
  HeadingWrapper,
  IntroWrapper,
  RegisterWrapper,
  SeparatorWrapper,
  ExistingAccountWrapper,
  LoginButton,
  TermsWrapper,
} from './style'
import FacebookButton from '../../../ui/FacebookButton'
import GoogleButton from '../../../ui/GoogleButton'
import EmailButton from '../../../ui/EmailButton'
import TextSeparator from '../../../ui/TextSeparator'
import { H1 } from '../../../ui/Headings'
import UserAuthIntro from '../../../ui/user-auth-intro'
import UserAuthTermsInfo from '../../../components/user-auth-terms-info'
import UserAuthAbort from '../../../components/user-auth-abort'

const UserAuthRegisterOptions = ({
  hideHeading,
  continueMode,
  intro,
  successUrl,
  onShowRegisterForm,
  onShowLoginForm,
  onClose,
}) => (
  <>
    {!hideHeading ? (
      <HeadingWrapper>
        <H1>Registrer deg</H1>
      </HeadingWrapper>
    ) : null}
    {intro ? (
      <IntroWrapper>
        <UserAuthIntro>{intro}</UserAuthIntro>
      </IntroWrapper>
    ) : null}

    <RegisterWrapper>
      <EmailButton onClick={onShowRegisterForm} />
    </RegisterWrapper>
    <SeparatorWrapper>
      <TextSeparator text="eller" />
    </SeparatorWrapper>
    <RegisterWrapper>
      <FacebookButton
        redirectUrl={successUrl}
        register={!continueMode}
        showContinue={continueMode}
      />
    </RegisterWrapper>
    <RegisterWrapper>
      <GoogleButton redirectUrl={successUrl} register={!continueMode} showContinue={continueMode} />
    </RegisterWrapper>

    <TextSeparator />
    <ExistingAccountWrapper>
      Har du allerede Gigplanet-konto?{' '}
      <LoginButton type="button" onClick={onShowLoginForm}>
        Logg inn
      </LoginButton>
    </ExistingAccountWrapper>

    <TermsWrapper>
      <UserAuthTermsInfo />
    </TermsWrapper>
    {onClose ? <UserAuthAbort onAbort={onClose} /> : null}
  </>
)

UserAuthRegisterOptions.propTypes = {
  intro: PropTypes.string,
  hideHeading: PropTypes.bool,
  continueMode: PropTypes.bool,
  successUrl: PropTypes.string.isRequired,
  onShowRegisterForm: PropTypes.func.isRequired,
  onShowLoginForm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}
UserAuthRegisterOptions.defaultProps = {
  intro: '',
  hideHeading: false,
  continueMode: false,
}

export default UserAuthRegisterOptions
