import styled from 'styled-components'

export const Container = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 40px 20px;
`

export const Content = styled.div`
  border: 1px solid #ccc;
  padding: 40px;
  text-align: center;
`

export const Text = styled.p`
  margin: 0;
  padding: 10px 0 30px;
`
