import styled, { css } from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0 30px;

  ${({ smallPadding }) =>
    smallPadding &&
    css`
      padding: 0 0 15px;
    `}
  ${mediaQueries.mobile`
    padding: 0 0 15px;
    flex-direction: column;
  `};
`
