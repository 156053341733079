import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import mediaQueries from '../../../../styles/mediaQueries'

import { getEditUrl } from '../../../../models/ProfileAdmin'
import { PUBLISH_STATUS } from '../../../../models/Artist'
import { ROLE_ADMIN } from '../../../../models/CurrentUser'

const Container = styled.div`
  /* TODO: theme colors */
  /* $background-color: $gigplanet-status-background-color;
  $text-color: white;
  $button-background-color: $gigplanet-purple-color; */
  background: #c29bd2;
  color: #fff;
  position: relative;
  padding: 15px;
  ${mediaQueries.mobile`
    text-align: center;
    padding: 5px;
  `};
`
const PreviewInfo = styled.div`
  margin: 0 120px;
  line-height: 20px;
  text-align: center;
  ${mediaQueries.mobile`
    margin: 0;
    padding-bottom: 10px;
    font-size: 12px;
  `};
`
const PreviewIntro = styled.span``
const PreviewStatus = styled.span`
  font-weight: bold;
  margin-left: 5px;
  ${mediaQueries.mobile`
    margin-left: 0;
    display: block;
  `};
`
const PreviewActions = styled.div`
  position: absolute;
  right: 10px;
  top: 5px;
  margin: 5px;
  ${mediaQueries.tabletPortraitDown`
    font-size: 14px;
    position: relative;
    right: auto;
    text-align: center;
  `};
`

const PreviewEdit = styled(Link)`
  padding: 5px 15px;
  line-height: 30px;
  /* background: $button-background-color; */
  background: #8805bb;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
`
const PreviewEditText = styled.span``

const PreviewEditPublish = styled(Link)`
  margin: 5px;
  padding: 5px 15px;
  line-height: 30px;
  background: #8805bb;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
`

const ProfileDetailsPreviewLine = React.memo(({ currentUser, artist }) => {
  if (!currentUser) return null
  if (!artist.isOwner && currentUser.role !== ROLE_ADMIN) return null
  const editUrl = getEditUrl(artist.slug)
  let statusText = ''

  if (artist.status === PUBLISH_STATUS.DRAFT) {
    if (artist.score.publishable) {
      statusText = 'Profilen er klar til publisering'
    } else {
      statusText = 'Profilen er ikke publisert'
    }
  } else if (artist.status === PUBLISH_STATUS.AWAITING_APPROVAL) {
    statusText = 'Profilen venter på godkjennelse'
  } else if (artist.status === PUBLISH_STATUS.PUBLISHED) {
    statusText = 'Profilen er publisert'
  }
  return (
    <Container>
      <PreviewInfo>
        {artist.status === PUBLISH_STATUS.DRAFT ? (
          <PreviewIntro>Dette er en forhåndsvisning av profilen din.</PreviewIntro>
        ) : null}
        <PreviewStatus>{statusText}</PreviewStatus>
      </PreviewInfo>
      <PreviewActions>
        {/* TODO: check on stage/prod - artist.score - url problably wrong */}
        {artist.score && artist.score.publishable && artist.status === PUBLISH_STATUS.DRAFT ? (
          <PreviewEditPublish to={editUrl}>
            <PreviewEditText>Publiser</PreviewEditText>
          </PreviewEditPublish>
        ) : null}
        <PreviewEdit to={editUrl}>
          {/* TODO: icon--edit <span className="profile-details-preview-line__edit-button__icon">
            <span className="icon icon--edit" />
          </span> */}
          <PreviewEditText>Rediger</PreviewEditText>
        </PreviewEdit>
      </PreviewActions>
    </Container>
  )
})

ProfileDetailsPreviewLine.propTypes = {
  currentUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  artist: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default ProfileDetailsPreviewLine
