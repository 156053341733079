import styled, { css } from 'styled-components'
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'
import { ProfileDetailsRow } from '../../styles'

export const Container = styled.div`
  padding: 0;
`
export const Content = styled.div`
  padding: 50px 2.35765% 20px;
  ${mediaQueries.mobile`
    padding: 10px 10px 10px;
  `};
`
export const Row = styled(ProfileDetailsRow)`
  ${mediaQueries.mobile`
    
  `};
`

export const FavoriteWrapper = styled.div`
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  ${({ isMenu }) =>
    isMenu &&
    css`
      right: 0;
      top: 5px;
    `};
`

export const Info = styled.div`
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 48.82117%;
  margin-top: 10px;
  :last-child {
    margin-right: 0;
  }
  ${mediaQueries.mobile`
    float: left;
    display: block;
    width: 100%;
  `};
  ${mediaQueries.tabletPortraitUp`
    float: left;
    display: block;
    width: 48.82117%;
    margin-top: 10px;
  `};
`
export const Heading = styled.h1`
  color: ${gigplanetTheme.element.default.heading};
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  line-height: 45px;
  margin: 0;
  ${mediaQueries.tabletPortrait`
    text-align: center;
  `};
`
export const Slogan = styled.h2`
  display: block;
  color: ${gigplanetTheme.element.default.heading};
  font-size: 24px;
  line-height: 38px;
  margin: 0;
  font-weight: normal;
  ${mediaQueries.tabletPortrait`
    text-align: center;
  `};
  br {
    display: none;
    ${mediaQueries.mobile`
      display: block;
    `};
  }
`
export const MemberText = styled.h3`
  display: block;
  color: ${gigplanetTheme.element.default.heading};
  font-size: 20px;
  line-height: 26px;
  margin: 0;
  font-weight: normal;
  ${mediaQueries.mobile`
    display: none;
  `};
`
export const RatingContainer = styled.a`
  align-items: center;
  color: ${gigplanetTheme.color.darkerGray};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 5px 0 10px;
  text-decoration: none;
  ${mediaQueries.tabletPortrait`
    justify-content: center;
  `};
`
export const RatingStarsContainer = styled.span`
  margin-right: 5px;
`
export const RatingDescription = styled.span`
  margin-left: 5px;
  font-size: 16px;
  color: ${gigplanetTheme.color.primary};
`

export const GenresContainer = styled.div`
  padding: 10px 0;
  ${mediaQueries.tabletPortrait`
    text-align: center;
  `};
`
export const GenresContainerNoMargin = styled.div`
  padding: 10px 0;
  ${mediaQueries.tabletPortrait`
    text-align: center;
  `};
  padding-top: 0;
`

export const HeaderIntro = styled.p`
  color: $intro-color;
  font-size: 16px;
  line-height: 25px;
  margin: 0;
  ${mediaQueries.tabletPortrait`
    margin: 0 auto 25px;
    max-width: 400px;
  `};
`
