/* eslint-disable import/prefer-default-export */
import emailIsValid from '../helpers/validate-email'
import passwordIsValid from '../helpers/validate-password'
import getGlobals from '../helpers/getGlobals'

const GOOGLE_LOGIN_URL = '/api/v1/auth/googlelogin'
const FACEBOOK_LOGIN_URL = '/api/v1/auth/facebooklogin'
const GOOGLE_REGISTER_URL = '/api/v1/auth/googleregister'
const FACEBOOK_REGISTER_URL = '/api/v1/auth/facebookregister'

const LOGOUT_URL = '/api/v1/auth/logout'

export const getFacebookLoginUrl = () => {
  const GLOBALS = getGlobals()
  return `${GLOBALS.API_SERVER_HOST}${FACEBOOK_LOGIN_URL}`
}
export const getFacebookRegisterUrl = () => {
  const GLOBALS = getGlobals()
  return `${GLOBALS.API_SERVER_HOST}${FACEBOOK_REGISTER_URL}`
}

export const getGoogleLoginUrl = () => {
  const GLOBALS = getGlobals()
  return `${GLOBALS.API_SERVER_HOST}${GOOGLE_LOGIN_URL}`
}

export const getGoogleRegisterUrl = () => {
  const GLOBALS = getGlobals()
  return `${GLOBALS.API_SERVER_HOST}${GOOGLE_REGISTER_URL}`
}

export const getLogoutUrl = () => {
  const GLOBALS = getGlobals()
  return `${GLOBALS.API_SERVER_HOST}${LOGOUT_URL}`
}

export const validateFirstName = (firstName, returnErrorMessage = false) => {
  const errorMessages = []
  if (!firstName) {
    errorMessages.push({
      id: 'firstName',
      message: 'Fyll ut fornavn',
    })
  } else if (firstName.length < 2) {
    errorMessages.push({
      id: 'firstName',
      message: 'Fornavn må inneholde minst to tegn',
    })
  }

  if (returnErrorMessage) {
    return errorMessages
  }

  return errorMessages.length === 0
}

export const validateLastName = (lastName, returnErrorMessage = false) => {
  const errorMessages = []
  if (!lastName) {
    errorMessages.push({
      id: 'lastName',
      message: 'Fyll ut etternavn',
    })
  } else if (lastName.length < 2) {
    errorMessages.push({
      id: 'lastName',
      message: 'Etternavn må inneholde minst to tegn',
    })
  }

  if (returnErrorMessage) {
    return errorMessages
  }

  return errorMessages.length === 0
}

export const validateEmail = (email, returnErrorMessage = false) => {
  const errorMessages = []
  if (!email) {
    errorMessages.push({
      id: 'email',
      message: 'Fyll ut e-post',
    })
  } else if (!emailIsValid(email)) {
    errorMessages.push({
      id: 'email',
      message: 'Fyll ut gyldig e-post',
    })
  }

  if (returnErrorMessage) {
    return errorMessages
  }

  return errorMessages.length === 0
}

export const validatePassword = (password, returnErrorMessage = false) => {
  const errorMessages = []
  if (!password) {
    errorMessages.push({
      id: 'password',
      message: 'Fyll ut passord',
    })
  } else if (!passwordIsValid(password)) {
    errorMessages.push({
      id: 'password',
      message:
        'Passord må være minst 6 tegn, ikke inneholde mellomrom, ha minst 1 stor bokstav, 1 liten bokstav og 1 tall.',
    })
  }

  if (returnErrorMessage) {
    return errorMessages
  }

  return errorMessages.length === 0
}

export const validateRegisterUser = form => {
  let errorMessages = []
  errorMessages = [...validateFirstName(form.firstName, true)]
  errorMessages = [...errorMessages, ...validateLastName(form.lastName, true)]
  errorMessages = [...errorMessages, ...validateEmail(form.email, true)]
  return [...errorMessages, ...validatePassword(form.password, true)]
}

export const validateLogin = form => [...validateEmail(form.email, true)]
export const validateForgotPassword = form => [...validateEmail(form.email, true)]
export const validateNewPassword = form => [...validatePassword(form.password, true)]
export const validateVerifyEmail = form => [...validateEmail(form.email, true)]
