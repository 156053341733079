import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const CheckMarkCircleIcon = props => (
  <SVGWrapper width="256" height="256" viewBox="0 0 256 256" {...props}>
    <path d="M106.5 181.5l96-96-15-15.5-81 81-38-38-15 15zM128 21.5c59 0 106.5 47.5 106.5 106.5s-47.5 106.5-106.5 106.5-106.5-47.5-106.5-106.5 47.5-106.5 106.5-106.5z" />
  </SVGWrapper>
)

export default CheckMarkCircleIcon
