import styled, { css } from 'styled-components'

export const StyledRichText = styled.div`
  width: 100%;
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  color: #565a5c;
  cursor: text;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  min-height: 120px;
  transition: border-color 0.2s;

  ${({ hasFocus }) =>
    hasFocus &&
    css`
      outline: initial;
    `};
`

export const StyledRichTextEditor = styled.div`
  border-top: 1px solid #c4c4c4;
  padding: 8px 10px;

  ${({ hidePlaceHolder }) =>
    hidePlaceHolder &&
    css`
      & .public-DraftEditorPlaceholder-root {
        display: none;
      }
    `};
`

export const HelpText = styled.small`
  font-size: 12px;
  line-height: 15px;
  color: #82888a;
`
