import * as React from 'react'
import PropTypes from 'prop-types'

import Button from '../../ui/Button'
import { H1 } from '../../ui/Headings'
import UserAuthIntro from '../../ui/user-auth-intro'
import IntroWrapper from '../../ui/user-auth-intro-wrapper'
import Actions from '../../ui/user-auth-actions'

const UserAuthRegisterConfirmation = ({ onClose }) => (
  <>
    <H1>Vi har sendt deg en e-post</H1>
    <IntroWrapper>
      <UserAuthIntro>
        Verifiser din konto ved å følge lenken i e-posten vi har sendt deg.
      </UserAuthIntro>
    </IntroWrapper>
    <Actions>
      <Button type="button" theme="secondary" onClick={onClose}>
        Ok
      </Button>
    </Actions>
  </>
)

UserAuthRegisterConfirmation.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default UserAuthRegisterConfirmation
