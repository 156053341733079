import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { validateNewPassword } from '../../models/Auth'

import {
  updateNewPasswordFormAction,
  newPasswordAction,
  resetFormsAction,
} from '../../stores/Auth/AuthActions'
import {
  getNewPasswordForm,
  getNewPasswordIsSaving,
  getNewPasswordHasSaved,
  getNewPasswordErrorMessages,
} from '../../stores/Auth/AuthReducer'

import UserAuthNewPasswordConfirmation from '../../components/user-auth-new-password-confirmation'
import NewPasswordForm from './components/user-auth-new-password-form'

const mapStateToProps = state => ({
  form: getNewPasswordForm(state),
  isSaving: getNewPasswordIsSaving(state),
  hasSaved: getNewPasswordHasSaved(state),
  serverErrorMessages: getNewPasswordErrorMessages(state),
})

const mapDispatchToProps = dispatch => ({
  updateForm: form => {
    dispatch(updateNewPasswordFormAction(form))
  },
  newPassword: () => {
    dispatch(newPasswordAction())
  },
  resetForms: () => {
    dispatch(resetFormsAction())
  },
})

class UserAuthNewPasswordFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessages: [],
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { serverErrorMessages } = this.props
    if (serverErrorMessages !== nextProps.serverErrorMessages) {
      this.setState({
        errorMessages: nextProps.serverErrorMessages,
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { form, newPassword } = this.props
    const errorMessages = validateNewPassword(form)
    if (errorMessages.length === 0) {
      newPassword()
    } else {
      this.setState({
        errorMessages,
      })
    }
  }

  handleChangePassword = e => {
    const { form, updateForm } = this.props
    updateForm({
      ...form,
      password: e.currentTarget.value,
    })
  }

  handleClose = () => {
    const { resetForms, onClose } = this.props
    resetForms()
    onClose()
  }

  render() {
    const { hasSaved, onClose, hideHeading, form, isSaving, serverErrorMessages } = this.props
    const { errorMessages } = this.state
    if (hasSaved) {
      return <UserAuthNewPasswordConfirmation onClose={onClose} />
    }
    return (
      <NewPasswordForm
        hideHeading={hideHeading}
        onClose={this.handleClose}
        onSubmit={this.handleSubmit}
        password={form.password}
        onChangePassword={this.handleChangePassword}
        isSaving={isSaving}
        errorMessages={errorMessages}
        serverError={serverErrorMessages.length > 0}
      />
    )
  }
}

UserAuthNewPasswordFormContainer.propTypes = {
  hideHeading: PropTypes.bool,
  form: PropTypes.shape({
    password: PropTypes.string,
  }).isRequired,
  resetForms: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  newPassword: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  hasSaved: PropTypes.bool.isRequired,
  serverErrorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
  ),
  onClose: PropTypes.func.isRequired,
}

UserAuthNewPasswordFormContainer.defaultProps = {
  hideHeading: false,
  serverErrorMessages: [],
}

const UserAuthNewPasswordFormContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAuthNewPasswordFormContainer)

export default UserAuthNewPasswordFormContainerWrapper
