import * as React from 'react'
import PropTypes from 'prop-types'

import { Container, Content } from './style'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import { H2 } from '../../../../ui/landing-page-headings'
import Button from '../../../../ui/Button'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'

const showIntercom = () => {
  if (window && window.Intercom) {
    window.Intercom('showNewMessage')
  }
}

const IntercomContactBlock = React.memo(
  ({ theme, anchorId, heading, intro, contactButtonText }) => (
    <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
      <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
      <Container>
        <Content>{intro}</Content>
        <Button onClick={showIntercom} theme="primary">
          {contactButtonText}
        </Button>
      </Container>
    </LandingPageBlockWrapper>
  ),
)

IntercomContactBlock.propTypes = {
  theme: PropTypes.string.isRequired,
  anchorId: PropTypes.string,
  heading: PropTypes.string,
  intro: PropTypes.string,
  contactButtonText: PropTypes.string,
}

IntercomContactBlock.defaultProps = {
  anchorId: '',
  heading: '',
  intro: '',
  contactButtonText: '',
}

export default IntercomContactBlock
