import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { includedOptionInGigRequestForm, REQUEST_STATUS } from '../../../models/GigRequest'
import { getSlogan } from '../../../models/Artist'

import {
  LoaderWrapper,
  Request,
  Type,
  Separator,
  EventType,
  Artist,
  ArtistImage,
  ArtistLink,
  TimeInfo,
  Info,
  CustomerNote,
  IncludedList,
  IncludedItem,
  LinkWrapper,
  ContactInfo,
  EmailLink,
  Stars,
  StarsWrapper,
  RatingCount,
} from './style'
import Loader from '../../../ui/Loader'
import ArtistAvatar from '../../../ui/ArtistAvatar'
import Button from '../../../ui/Button'
import { H2, H3 } from '../../../ui/Headings'

import getPlaytimeText from '../../../helpers/get-playtime-text'
import RatingStars from '../../../ui/RatingStars'

const LONG_DATE_FORMAT = 'dddd, D. MMMM YYYY'
const TIME_FORMAT = 'HH:mm'

const GigRequestEventplannerDetailsSidebar = ({
  isLoading,
  hasLoaded,
  eventplannerRequest,
  eventTypes,
  categories,
}) => {
  if (isLoading || !hasLoaded || !{}.hasOwnProperty.call(eventplannerRequest, 'id')) {
    return (
      <LoaderWrapper>
        <Loader text="Laster arrangement" />
      </LoaderWrapper>
    )
  }
  let eventType = ''
  let subEventType = ''
  if (eventplannerRequest.eventType && eventTypes[eventplannerRequest.eventType.eventType]) {
    const eventTypeData = eventTypes[eventplannerRequest.eventType.eventType]
    eventType = eventTypeData.name

    if (eventTypeData.sub[eventplannerRequest.eventType.sub]) {
      subEventType = eventTypeData.sub[eventplannerRequest.eventType.sub].name
    }
  }
  const startTime = moment(eventplannerRequest.startTime)
  const endTime = moment(eventplannerRequest.endTime)

  const gotArtist =
    eventplannerRequest.artist &&
    eventplannerRequest.artist.artist &&
    {}.hasOwnProperty.call(eventplannerRequest.artist.artist, 'id')
  const artist =
    eventplannerRequest.artist && eventplannerRequest.artist.artist
      ? eventplannerRequest.artist.artist
      : null

  const soundSystemIncluded =
    eventplannerRequest.included.indexOf(includedOptionInGigRequestForm.SOUNDSYSTEM) !== -1
  const sceneLightsIncluded =
    eventplannerRequest.included.indexOf(includedOptionInGigRequestForm.SCENELIGHTS) !== -1

  let gigRequestEditUrl = `/eventplanner/inbox/edit/${eventplannerRequest.id}`
  if (gotArtist) {
    gigRequestEditUrl = `/eventplanner/inbox/edit/${eventplannerRequest.id}/${artist.id}`
  }

  return (
    <>
      {gotArtist ? (
        <Artist>
          <Type>Forespørsel sendt til</Type>
          <H2>{artist.name}</H2>
          <EventType>{getSlogan(artist, categories)}</EventType>
          {artist.rating && artist.rating.reviews > 0 ? (
            <StarsWrapper>
              <Stars>
                <RatingStars rating={artist.rating.average} />
              </Stars>
              <RatingCount>({artist.rating.reviews})</RatingCount>
            </StarsWrapper>
          ) : null}
          <ArtistImage>
            <ArtistAvatar artist={artist} size={120} />
          </ArtistImage>
          {artist.contactInfo ? (
            <ContactInfo>
              {artist.contactInfo && artist.contactInfo.contactName ? (
                <Info>{artist.contactInfo.contactName}</Info>
              ) : null}
              {artist.contactInfo && artist.contactInfo.contactPhone ? (
                <Info>{artist.contactInfo.contactPhone}</Info>
              ) : null}
              {artist.contactInfo && artist.contactInfo.contactEmail ? (
                <Info>
                  <EmailLink href={`mailto:${artist.contactInfo.contactEmail}`}>
                    {artist.contactInfo.contactEmail}
                  </EmailLink>
                </Info>
              ) : null}
              {artist.contactInfo ? <Info>{}</Info> : null}
            </ContactInfo>
          ) : (
            <ContactInfo>Øvrig informasjon blir tilgjengelig etter bestilling</ContactInfo>
          )}
          <ArtistLink>
            <Button
              type="button"
              theme="primary"
              to={`/${artist.artistType || 'artist'}/${artist.slug}`}
            >
              Se profil
            </Button>
          </ArtistLink>
        </Artist>
      ) : null}

      <Request>
        <Type>Ditt arrangement</Type>
        <H2>{subEventType}</H2>
        <EventType>{eventType}</EventType>

        <Separator />

        <TimeInfo>{startTime.format(LONG_DATE_FORMAT)}</TimeInfo>
        <TimeInfo>
          Fra kl. {startTime.format(TIME_FORMAT)} - {endTime.format(TIME_FORMAT)}
        </TimeInfo>
        <TimeInfo>
          Varighet:{' '}
          <strong>
            {getPlaytimeText(eventplannerRequest.startTime, eventplannerRequest.endTime)}
          </strong>
        </TimeInfo>

        <Separator />

        <Info>{eventplannerRequest.city}</Info>
        <Info>{eventplannerRequest.venue}</Info>

        <Separator />

        <Info>Antall gjester: ca {eventplannerRequest.audienceCount}</Info>

        {eventplannerRequest.notes ? (
          <>
            <Separator />
            <H3>Ytterligere informasjon</H3>
            <CustomerNote>{eventplannerRequest.notes}</CustomerNote>
          </>
        ) : null}

        <Separator />

        <IncludedList>
          <IncludedItem>
            Lydanlegg medbringes: <strong>{soundSystemIncluded ? 'Ja' : 'Nei'}</strong>
          </IncludedItem>
          <IncludedItem>
            Lys medbringes: <strong>{sceneLightsIncluded ? 'Ja' : 'Nei'}</strong>
          </IncludedItem>
          <IncludedItem>
            Pausemusikk: <strong>Ja</strong>
          </IncludedItem>
        </IncludedList>
        {eventplannerRequest.status === REQUEST_STATUS.ACTIVE ? (
          <LinkWrapper>
            <Button type="button" theme="primary" to={gigRequestEditUrl}>
              Endre forespørsel
            </Button>
          </LinkWrapper>
        ) : null}
      </Request>
    </>
  )
}

GigRequestEventplannerDetailsSidebar.propTypes = {
  categories: PropTypes.object.isRequired, // eslint-disable-line  react/forbid-prop-types
  eventplannerRequest: PropTypes.object.isRequired, // eslint-disable-line  react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line  react/forbid-prop-types
}
GigRequestEventplannerDetailsSidebar.defaultProps = {}

export default GigRequestEventplannerDetailsSidebar
