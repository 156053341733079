import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Route, Switch, withRouter } from 'react-router'
import { connect } from 'react-redux'

import RequestBookingRoute from './booking'

import { getOneEventplannerRequestForArtistAction } from '../../../../../stores/gig-request-eventplanner/actions'
import {
  getOneEventplannerRequest,
  getOneEventplannerRequestForArtist,
  getOneEventplannerRequestHasLoaded,
  getOneEventplannerRequestIsLoading,
} from '../../../../../stores/gig-request-eventplanner/reducer'
import { getEventTypes } from '../../../../../stores/Options/OptionsReducer'

import MetaData from '../../../../../components/MetaData'
import PageNotFound from '../../../../../components/PageNotFound'
import EventplannerInboxDetailsRequest from '../../../../../views/gig-request-inbox-details-eventplanner'

const mapStateToProps = state => ({
  eventplannerRequest: getOneEventplannerRequest(state),
  eventplannerRequestWithArtist: getOneEventplannerRequestForArtist(state),
  isLoading: getOneEventplannerRequestIsLoading(state),
  hasLoaded: getOneEventplannerRequestHasLoaded(state),
  eventTypes: getEventTypes(state),
})

const mapDispatchToProps = dispatch => ({
  getOneRequest: (gigRequestId, artistId) => {
    dispatch(getOneEventplannerRequestForArtistAction(gigRequestId, artistId))
  },
})

class EventplannerInboxRequestRoute extends React.Component {
  componentDidMount() {
    const {
      match: { params },
      getOneRequest,
    } = this.props
    if (params.gigRequestId && params.artistId) {
      getOneRequest(params.gigRequestId, params.artistId)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: { params },
      getOneRequest,
    } = this.props
    if (
      params.gigRequestId !== nextProps.match.params.gigRequestId ||
      params.artistId !== nextProps.match.params.artistId
    ) {
      getOneRequest(nextProps.match.params.gigRequestId, nextProps.match.params.artistId)
    }
  }

  render() {
    const { eventplannerRequestWithArtist, isLoading, hasLoaded, eventTypes } = this.props

    if (hasLoaded && !{}.hasOwnProperty.call(eventplannerRequestWithArtist, 'id')) {
      return <PageNotFound />
    }

    return (
      <Switch>
        <Route
          path="/eventplanner/inbox/:gigRequestId/:artistId/booking/:quoteId"
          component={RequestBookingRoute}
        />
        <Route
          path="/eventplanner/inbox/:gigRequestId/:artistId"
          render={({ match: { params } }) => (
            <>
              <MetaData title="Forespørsel" description="Forespørsler" nopin />
              <EventplannerInboxDetailsRequest
                eventplannerRequestWithArtist={eventplannerRequestWithArtist}
                artistId={params.artistId}
                gigRequestId={params.gigRequestId}
                isLoading={isLoading}
                hasLoaded={hasLoaded}
                eventTypes={eventTypes}
              />
            </>
          )}
        />
        <Route component={PageNotFound} />
      </Switch>
    )
  }
}

EventplannerInboxRequestRoute.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      gigRequestId: PropTypes.string,
      artistId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  getOneRequest: PropTypes.func.isRequired,
  eventplannerRequestWithArtist: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

EventplannerInboxRequestRoute.defaultProps = {}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EventplannerInboxRequestRoute)
