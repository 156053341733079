import * as React from 'react'
import PropTypes from 'prop-types'
import { ContentBlock, Heading, TextLine } from './style'
import { getIncludedOptionSentence } from '../../../models/GigRequest'

const Included = ({ artistName, memberCount, included }) => {
  const includedText = getIncludedOptionSentence(included)
  return (
    <ContentBlock>
      <Heading>{artistName} stiller med</Heading>
      <TextLine>
        {memberCount} {memberCount === 1 ? 'medlem' : 'medlemmer'}
        {includedText ? `${included.length === 1 ? ' og ' : ', '} ${includedText}` : ''}
      </TextLine>
    </ContentBlock>
  )
}

Included.propTypes = {
  artistName: PropTypes.string.isRequired,
  memberCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  included: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Included
