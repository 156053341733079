import * as React from 'react'
import styled from 'styled-components'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const Container = styled.div`
  padding-top: 1rem;
`

const TagList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
`

const TagItem = styled.li`
  margin: 0;
  padding: 0.2rem;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`

const Tag = styled.div`
  background: ${gigplanetTheme.color.lightestGray};
  color: ${gigplanetTheme.color.darkerGray};
  padding: 0.5rem;
  line-height: 1.5rem;
  border-radius: 3px;
`

const Categories = React.memo(({ categories }) => {
  return (
    <Container>
      <TagList>
        {categories.map((category) => (
          <TagItem key={category._id}>
            <Tag>{category.title}</Tag>
          </TagItem>
        ))}
      </TagList>
    </Container>
  )
})

export default Categories
