/* eslint-disable max-len */
import * as React from 'react'
import { ContentBlock, Heading, TextLine } from './style'

const Legal = () => (
  <ContentBlock>
    <Heading>Avlysning og verneting</Heading>
    <TextLine>
      Ved avlysning fra oppdragsgivers side skal artisten stilles økonomisk som om arrangementet var
      blitt gjennomført. Artisten plikter likevel å søke å begrense sitt tap. Ved sykdom som hindrer
      gjennomføring av oppdraget skal artisten snarest mulig framlegge sykmelding. Ved udokumentert
      uteblivelse fra artistens side kan oppdragsgiver kreve erstatning for direkte og
      dokumenterbart tap dersom uteblivelsen ikke skyldes forhold som ligger utenfor artistens
      kontroll. Lokale hendelser gjelder ikke som avlysningsgrunn med mindre politiet stopper
      arrangementet.
    </TextLine>
  </ContentBlock>
)

export default Legal
