import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  QuoteShape,
  getQuoteStatusName,
  QUOTE_STATUS,
  getEventplannerDeclineReasonName,
  getArtistWithdrawReasonName,
  SHORT_DATE_FORMAT,
  DATE_FORMAT,
} from '../../models/GigRequest'
import { printCurrency } from '../../helpers/Currency'

import {
  StyledGigRequestTimelineQuote,
  InvalidatedNote,
  TermsText,
  ExpiresText,
  QuoteDetails,
  DeclinedReason,
  WithdrawnReason,
  ReasonText,
  Congrats,
  StyledInfo,
} from './style'

import { H2, H3 } from '../../ui/Headings'
import TextSeparator from '../../ui/TextSeparator'

const GigRequestTimelineQuote = ({ quote, isArtist, time }) => {
  if (quote.status === QUOTE_STATUS.INVALIDATED) {
    return (
      <>
        <TextSeparator
          text={`${getQuoteStatusName(quote.status, isArtist)} - ${moment(time).format(
            SHORT_DATE_FORMAT,
          )}`}
          hoverText={moment(time).format(DATE_FORMAT)}
          uppercase
        />
        {!isArtist ? (
          <InvalidatedNote>
            Du har endret forespørsel og må eventuelt be om nytt tilbud.
          </InvalidatedNote>
        ) : (
          <InvalidatedNote>
            Arrangør endret forespørselen, og du må eventuelt sende nytt tilbud.
          </InvalidatedNote>
        )}
      </>
    )
  }

  if (quote.status === QUOTE_STATUS.ACCEPTED) {
    return (
      <>
        {isArtist ? (
          <StyledInfo>
            All kontaktinformasjon til arrangøren er nå tilgjengelig. Du kan også dele lenker,
            telefonnummer og e-post i dialogen.
          </StyledInfo>
        ) : (
          <StyledInfo>
            All kontaktinformasjon til artisten er nå tilgjengelig. Du kan også dele lenker,
            telefonnummer og e-post i dialogen.
          </StyledInfo>
        )}
        <TextSeparator
          text={`${getQuoteStatusName(quote.status, isArtist)} - ${moment(time).format(
            SHORT_DATE_FORMAT,
          )}`}
          hoverText={moment(time).format(DATE_FORMAT)}
          uppercase
        />
        {isArtist ? <Congrats>Gratulerer fra alle oss i Gigplanet!</Congrats> : null}
      </>
    )
  }
  if (quote.status === QUOTE_STATUS.REPLACED || quote.status === QUOTE_STATUS.EXPIRED) {
    return (
      <>
        <TextSeparator
          text={`${getQuoteStatusName(quote.status, isArtist)} - ${moment(time).format(
            SHORT_DATE_FORMAT,
          )}`}
          hoverText={moment(time).format(DATE_FORMAT)}
          uppercase
        />
      </>
    )
  }

  const pauseText = quote.pauseCount === 1 ? '1 pause' : `${quote.pauseCount} pauser`
  const pauseDurationText = quote.pauseCount > 0 ? ` á ${quote.pauseDuration} minutter` : ''
  return (
    <StyledGigRequestTimelineQuote
      isDeclined={quote.status === QUOTE_STATUS.DECLINED}
      isWithdrawn={quote.status === QUOTE_STATUS.WITHDRAWN}
    >
      <H2>{getQuoteStatusName(quote.status, isArtist)}</H2>
      {quote.status === QUOTE_STATUS.WITHDRAWN ? (
        <>
          <WithdrawnReason>
            {getArtistWithdrawReasonName(quote.artistWithdrawReason)}
          </WithdrawnReason>
          {quote.artistWithdrawReasonText ? (
            <ReasonText>{quote.artistWithdrawReasonText}</ReasonText>
          ) : null}
        </>
      ) : null}
      {quote.status === QUOTE_STATUS.DECLINED ? (
        <>
          <DeclinedReason>
            {getEventplannerDeclineReasonName(quote.customerDeclineReason)}
          </DeclinedReason>
          {quote.customerDeclineReasonText ? (
            <ReasonText>{quote.customerDeclineReasonText}</ReasonText>
          ) : null}
        </>
      ) : null}

      {quote.status !== QUOTE_STATUS.WITHDRAWN && quote.status !== QUOTE_STATUS.DECLINED ? (
        <>
          {quote.actionable &&
          quote.expiresTime &&
          moment().isBefore(quote.expiresTime) &&
          quote.status === QUOTE_STATUS.PENDING ? (
            <ExpiresText>
              Må besvares innen {moment().to(moment(quote.expiresTime), true)}
            </ExpiresText>
          ) : null}
          <QuoteDetails>
            Pris: {printCurrency(quote.price)}
            {', '}
            {quote.members === 1 ? '1 medlem' : `${quote.members} medlemmer`}
            {' og '}
            {pauseText}
            {pauseDurationText}
          </QuoteDetails>
          <H3>Tilleggsopplysninger</H3>
          <TermsText>{quote.terms || 'Ingen tilleggsopplysninger'}</TermsText>
        </>
      ) : null}
    </StyledGigRequestTimelineQuote>
  )
}

GigRequestTimelineQuote.propTypes = {
  quote: QuoteShape.isRequired,
  isArtist: PropTypes.bool,
  time: PropTypes.string.isRequired,
}

GigRequestTimelineQuote.defaultProps = {
  isArtist: false,
}

export default GigRequestTimelineQuote
