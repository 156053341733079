import * as React from 'react'
import PropTypes from 'prop-types'

import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePassword,
  getFacebookRegisterUrl,
  getGoogleRegisterUrl,
} from '../../../models/Auth'

import { Input, Errormessage } from '../../../ui/Form'
import Button from '../../../ui/Button'
import TextSeparator from '../../../ui/TextSeparator'
import { H1 } from '../../../ui/Headings'
import UserAuthTermsInfo from '../../../components/user-auth-terms-info'
import UserAuthAbort from '../../../components/user-auth-abort'
import {
  HeadingWrapper,
  LoginLink,
  ExternalLogin,
  SeparatorWrapper,
  InputWrapper,
  Terms,
  ExistingAccount,
} from './style'

const UserAuthRegisterForm = React.memo(
  ({
    successUrl,
    firstName,
    lastName,
    email,
    password,
    errorMessages,
    hideHeading,
    onSubmit,
    onChangeLastName,
    onChangeEmail,
    onChangePassword,
    serverError,
    onClose,
    onChangeFirstName,
    isSubmitting,
    onShowLoginForm,
  }) => {
    let facebookRegisterUrl = getFacebookRegisterUrl()
    let googleRegisterUrl = getGoogleRegisterUrl()
    if (successUrl) {
      facebookRegisterUrl = `${facebookRegisterUrl}?url=${encodeURIComponent(successUrl)}`
      googleRegisterUrl = `${googleRegisterUrl}?url=${encodeURIComponent(successUrl)}`
    }

    const validFirstName = validateFirstName(firstName)
    const validLastName = validateLastName(lastName)
    const validEmail = validateEmail(email)
    const validPassword = validatePassword(password)

    let emailErrorMessage = ''
    let firstNameErrorMessage = ''
    let lastNameErrorMessage = ''
    let passwordErrorMessage = ''

    if (errorMessages && errorMessages.length > 0) {
      const firstNameIdx = errorMessages.findIndex(element => element.id === 'firstName')
      if (firstNameIdx !== -1 && (!validFirstName || serverError)) {
        firstNameErrorMessage = errorMessages[firstNameIdx].message
      }

      const lastNameIdx = errorMessages.findIndex(element => element.id === 'lastName')
      if (lastNameIdx !== -1 && (!validFirstName || serverError)) {
        lastNameErrorMessage = errorMessages[lastNameIdx].message
      }

      const emailIdx = errorMessages.findIndex(element => element.id === 'email')
      if (emailIdx !== -1 && (!validEmail || serverError)) {
        emailErrorMessage = errorMessages[emailIdx].message
      }

      const passwordIdx = errorMessages.findIndex(element => element.id === 'password')
      if (passwordIdx !== -1 && (!validPassword || serverError)) {
        passwordErrorMessage = errorMessages[passwordIdx].message
      }
    }
    return (
      <>
        {!hideHeading ? (
          <HeadingWrapper>
            <H1>Registrer deg</H1>
          </HeadingWrapper>
        ) : null}
        <ExternalLogin>
          Registrer deg med <LoginLink href={facebookRegisterUrl}>Facebook</LoginLink> eller{' '}
          <LoginLink href={googleRegisterUrl}>Google</LoginLink>
        </ExternalLogin>
        <SeparatorWrapper>
          <TextSeparator text="eller" />
        </SeparatorWrapper>
        <form
          className="UserAuthRegisterForm__form"
          action="/v1/api/auth/register"
          method="POST"
          onSubmit={onSubmit}
        >
          <InputWrapper>
            <Input
              type="text"
              id="firstName"
              name="firstName"
              label="Fornavn"
              placeholder="Fornavn"
              required
              expanded
              value={firstName}
              onChange={onChangeFirstName}
              errorMessage={firstNameErrorMessage}
              validated={validFirstName}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="text"
              id="lastName"
              name="lastName"
              label="Etternavn"
              placeholder="Etternavn"
              required
              expanded
              value={lastName}
              onChange={onChangeLastName}
              errorMessage={lastNameErrorMessage}
              validated={validLastName}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="email"
              id="email"
              name="email"
              label="E-post"
              placeholder="E-post"
              required
              expanded
              value={email}
              onChange={onChangeEmail}
              errorMessage={emailErrorMessage}
              validated={validEmail}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="password"
              id="password"
              name="password"
              label="Passord"
              placeholder="Opprett et passord"
              required
              expanded
              value={password}
              onChange={onChangePassword}
              errorMessage={passwordErrorMessage}
              validated={validPassword}
              helpText={
                !validPassword && password
                  ? 'Passord må være minst 6 tegn, ikke inneholde mellomrom, ha minst 1 stor bokstav, 1 liten bokstav og 1 tall.'
                  : ''
              }
            />
          </InputWrapper>
          {errorMessages.length > 0 &&
          (serverError || !validFirstName || !validLastName || !validEmail || !validPassword) ? (
            <InputWrapper>
              <Errormessage messages={errorMessages.map(message => message.message)} />
            </InputWrapper>
          ) : null}
          <Terms>
            <UserAuthTermsInfo />
          </Terms>
          <Button type="submit" theme="primary" loading={isSubmitting} wide>
            Registrér deg
          </Button>
        </form>
        <TextSeparator />
        <ExistingAccount>
          Har du allerede en Gigplanet-konto?{' '}
          <Button type="button" theme="transparent" onClick={onShowLoginForm}>
            Logg inn
          </Button>
        </ExistingAccount>
        {onClose ? <UserAuthAbort onAbort={onClose} /> : null}
      </>
    )
  },
)

UserAuthRegisterForm.propTypes = {
  hideHeading: PropTypes.bool,
  successUrl: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  onChangeFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  onChangeLastName: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onShowLoginForm: PropTypes.func.isRequired,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
    }),
  ),
  serverError: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
UserAuthRegisterForm.defaultProps = {
  hideHeading: false,
  errorMessages: [],
}

export default UserAuthRegisterForm
