import * as React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { trackEvent } from '../../../../libs/analytics'

import { fetchFavoriteArtistsAction } from '../../../../stores/Artist/ArtistActions'

import {
  getFavoriteArtists,
  isFavoriteArtistsLoading,
} from '../../../../stores/Artist/ArtistReducer'

import { Container, ArtistListWrapper, EmptyWrapper } from './style'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import { H2 } from '../../../../ui/landing-page-headings'
import { ArtistShape } from '../../../../models/Artist'
import ArtistList from '../../../../containers/ArtistList'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'
import Loader from '../../../../ui/Loader'

const ArtistFavoriteListBlock = React.memo(({ theme, anchorId, heading, removeMargin }) => {
  const dispatch = useDispatch()
  const artists = useSelector(getFavoriteArtists)
  const artistsLoading = useSelector(isFavoriteArtistsLoading)

  React.useEffect(() => {
    dispatch(fetchFavoriteArtistsAction())
  }, [])

  return (
    <LandingPageBlockWrapper theme={theme} noPadding anchorId={anchorId}>
      <Container removeMargin={removeMargin}>
        {heading ? (
          <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
        ) : null}
        {artists && artists.length > 0 ? (
          <ArtistListWrapper removeMargin={removeMargin && !heading}>
            <ArtistList artists={artists} />
          </ArtistListWrapper>
        ) : (
          <>
            {artistsLoading ? (
              <ArtistListWrapper removeMargin={removeMargin && !heading}>
                <Loader text="Henter favoritter" />
              </ArtistListWrapper>
            ) : (
              <EmptyWrapper>Ingen favoritter</EmptyWrapper>
            )}
          </>
        )}
      </Container>
    </LandingPageBlockWrapper>
  )
})

ArtistFavoriteListBlock.propTypes = {
  heading: PropTypes.string,
  showMoreButtonText: PropTypes.string,
  artists: PropTypes.arrayOf(ArtistShape),
  artistsLoading: PropTypes.bool.isRequired,
  artistsIsMore: PropTypes.bool.isRequired,
  anchorId: PropTypes.string,
  removeMargin: PropTypes.bool,
}

ArtistFavoriteListBlock.defaultProps = {
  heading: '',
  showMoreButtonText: '',
  artists: [],
  anchorId: '',
  removeMargin: false,
}

export default ArtistFavoriteListBlock
