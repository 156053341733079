import * as React from 'react'
import PropTypes from 'prop-types'
import PreviewLine from './components/ProfileDetailsPreviewLine'
import Header from './components/ProfileDetailsHeader'
import Menu from './components/ProfileDetailsMenu'
import Video from './components/ProfileDetailsVideo'
import Reviews from './components/ProfileDetailsReview'
import Audio from './components/ProfileDetailsAudio'
import Images from './components/ProfileDetailsImage'
import Repertoire from './components/ProfileDetailsRepertoire'
import About from './components/ProfileDetailsAbout'
import Members from './components/ProfileDetailsMembers'
import Facts from './components/ProfileDetailsFacts'
import Categories from './components/ProfileDetailsCategories'
import Share from './components/ProfileDetailsShare'
import ImageGalleryFullscreen from '../ProfileDetailsImageGalleryFullscreen'
import { ArtistShape, getSlogan, getMemberText } from '../../models/Artist'

const ProfileDetails = React.memo(
  ({ isLoading, artist, categories, currentUser = null, eventTypes, instruments, location }) => {
    const [imageGalleryOpen, setImageGalleryOpen] = React.useState(false)
    const [imageIndex, setImageIndex] = React.useState(0)

    const onOpenImageGallery = index => {
      setImageGalleryOpen(true)
      setImageIndex(index)
    }

    const onCloseImageGallery = () => {
      setImageGalleryOpen(false)
      setImageIndex(0)
    }

    if (isLoading) return null
    let images = []
    let gotImages = false
    let gotAudio = false
    let gotVideo = false
    let gotRepertoire = false
    let gotReviews = false
    let gotAbout = false
    let gotMembers = false
    let gotFacts = false
    let gotCategories = false
    let artistCategories = []
    const reviewContainerId = 'anmeldelser'
    const imageContainerId = 'bilder'
    const audioContainerId = 'lyd'
    const videoContainerId = 'video'
    const repertoireContainerId = 'repertoar'
    const aboutContainerId = 'om'

    const headings = []

    const url = `/${artist.artistType || 'artist'}/${artist.slug}`

    if (artist.images && artist.images.length > 0) {
      images = artist.images
    }

    if (artist.images && artist.images.length > 1) {
      gotImages = true
    }

    if (artist.video && artist.video.length > 0) {
      gotVideo = true
    }

    if (artist.audio && artist.audio.length > 0) {
      gotAudio = true
    }

    if (artist.repertoire && artist.repertoire.length > 0) {
      gotRepertoire = true
    } else if (
      artist.showRepertoireIntro &&
      artist.repertoireIntro.replace(/<\/?[^>]+(>|$)/g, '')
    ) {
      gotRepertoire = true
    }

    if (artist.rating && artist.rating.reviews > 0) {
      gotReviews = true
    }

    if (artist.description && artist.description.replace(/<\/?[^>]+(>|$)/g, '')) {
      gotAbout = true
    }
    if (artist.artistType !== 'solo') {
      if (artist.members && artist.members.length > 0) {
        gotMembers = true
      }
    }

    if (artist.facts && artist.facts.details) {
      Object.keys(artist.facts.details).forEach(key => {
        if (artist.facts.details[key] === true) {
          gotFacts = true
        }
      })
    }

    if (artist.otherCategories && artist.otherCategories.length > 0) {
      if (artist.mainCategory && artist.artistType) {
        artistCategories = [
          {
            category: artist.artistType,
            sub: artist.mainCategory,
          },
        ].concat(artist.otherCategories)
        gotCategories = true
      }
    }

    headings.push({
      heading: 'Video',
      count: artist.video ? artist.video.length : 0,
      id: videoContainerId,
      disabled: !gotVideo,
    })

    headings.push({
      heading: 'Lyd',
      count: artist.audio ? artist.audio.length : 0,
      id: audioContainerId,
      disabled: !gotAudio,
    })

    headings.push({
      heading: 'Repertoar',
      count: artist.repertoire ? artist.repertoire.length : '',
      id: repertoireContainerId,
      disabled: !gotRepertoire,
    })

    headings.push({
      heading: 'Bilder',
      count: artist.images && artist.images.length > 1 ? artist.images.length - 1 : 0,
      id: imageContainerId,
      disabled: !gotImages,
    })

    headings.push({
      heading: 'Om',
      count: 0,
      id: aboutContainerId,
      disabled: !gotAbout,
    })

    const slogan = getSlogan(artist, categories, true)

    const memberText = getMemberText(artist)

    // memberCounts
    return (
      <div itemScope itemType="http://schema.org/MusicGroup">
        <meta itemProp="url" content={url} />
        <PreviewLine currentUser={currentUser} artist={artist} />
        <Header
          artistId={artist.id}
          artistType={artist.artistType}
          artistName={artist.name}
          slogan={slogan}
          memberText={memberText}
          intro={artist.intro}
          images={images}
          onOpenImageGallery={onOpenImageGallery}
          artistGenres={artist.genres}
          rating={artist.rating}
          homeLocation={artist.homeLocation}
          memberCount={artist.members ? artist.members.length : 0}
          playedSince={isNaN(artist.facts.playedSince) ? 0 : parseInt(artist.facts.playedSince, 10)}
          minPrice={isNaN(artist.facts.priceMin) ? 0 : parseInt(artist.facts.priceMin, 10)}
        />
        <Menu
          headings={headings}
          artistName={artist.name}
          artistId={artist.id}
          artistSlug={artist.slug}
          artistType={artist.artistType}
          location={location}
        />
        {gotVideo ? (
          <Video artistName={artist.name} containerId={videoContainerId} videos={artist.video} />
        ) : null}
        {gotAudio ? <Audio containerId={audioContainerId} audio={artist.audio} /> : null}
        {gotRepertoire ? (
          <Repertoire
            containerId={repertoireContainerId}
            repertoire={artist.repertoire}
            showIntro={artist.showRepertoireIntro}
            intro={artist.repertoireIntro}
          />
        ) : null}
        {gotImages ? (
          <Images
            artistName={artist.name}
            containerId={imageContainerId}
            images={artist.images.slice(1)}
            onOpenImageGallery={onOpenImageGallery}
          />
        ) : null}
        {gotReviews ? (
          <Reviews containerId={reviewContainerId} artist={artist} reviews={artist.reviews} />
        ) : null}
        {gotAbout ? (
          <About
            containerId={aboutContainerId}
            artistName={artist.name}
            description={artist.description}
          />
        ) : null}
        {gotMembers ? <Members members={artist.members} instruments={instruments} /> : null}
        {gotFacts ? <Facts details={artist.facts.details} /> : null}
        {gotCategories ? (
          <Categories artistCategories={artistCategories} categories={categories} />
        ) : null}
        <Share artistName={artist.name} />
        {imageGalleryOpen ? (
          <ImageGalleryFullscreen
            index={imageIndex}
            onClose={onCloseImageGallery}
            images={artist.images}
          />
        ) : null}
      </div>
    )
  },
)

ProfileDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  currentUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  artist: ArtistShape.isRequired,
  categories: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  instruments: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default ProfileDetails
