import * as React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { trackEvent } from '../../../../libs/analytics'
import generateUrlParamsFromObject from '../../../../helpers/generate-url-params-from-object'

import { getCounties } from '../../../../stores/Locations/LocationsReducer'

import { Container, Intro, SearchWrapper } from './style'
import SearchForm from './components/search-form'

import { H1 } from '../../../../ui/landing-page-headings'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'
import useOptions from '../../../../hooks/useOptions'

const HeaderMainSearchBlock = React.memo(({ title = '', intro = '', theme, anchorId = '' }) => {
  const history = useHistory()
  const { eventTypes } = useOptions()
  const counties = useSelector(getCounties)
  const [eventType, setEventType] = React.useState('')
  const [location, setLocation] = React.useState('')

  const handleEventTypeChange = e => {
    setEventType(e.currentTarget.value)
    trackEvent('landingpage', 'landingpage-block-header-changed-event-type', e.currentTarget.value)
    history.push(
      `/s${generateUrlParamsFromObject({
        eventType: e.currentTarget.value,
        location,
      })}`,
    )
  }

  const handlePerformLocationChange = e => {
    setLocation(e.currentTarget.value)
    trackEvent(
      'landingpage',
      'landingpage-block-header-changed-perform-location',
      e.currentTarget.value,
    )
    history.push(
      `/s${generateUrlParamsFromObject({
        eventType,
        location: e.currentTarget.value,
      })}`,
    )
  }

  const handleSubmitSearch = () => {
    history.push(
      `/s${generateUrlParamsFromObject({
        eventType,
        location,
      })}`,
    )
    trackEvent('landingpage', 'landingpage-block-header-submitted-search', 'Søk', {
      eventType,
      location,
    })
  }
  return (
    <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
      <Container>
        {title ? <H1 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(title) }} /> : null}
        {intro ? <Intro>{intro}</Intro> : null}
        <SearchWrapper>
          <SearchForm
            eventType={eventType}
            eventTypes={eventTypes}
            onEventTypeChange={handleEventTypeChange}
            counties={counties}
            performLocation={location}
            onPerformLocationChange={handlePerformLocationChange}
            onSubmitSearch={handleSubmitSearch}
          />
        </SearchWrapper>
      </Container>
    </LandingPageBlockWrapper>
  )
})

HeaderMainSearchBlock.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.string.isRequired,
  anchorId: PropTypes.string,
  intro: PropTypes.string,
}

export default HeaderMainSearchBlock
