import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const Container = styled.div`
  width: 100%;
  padding: 0;
`

export const Actions = styled.div`
  padding-top: ${gigplanetTheme.spacing.medium};
`
