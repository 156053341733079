import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import mediaQueries from '../../../../../styles/mediaQueries'

import { printCurrency } from '../../../../../helpers/Currency'

const ProfileDetailsHeaderInfolineContainer = styled.div`
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  ${mediaQueries.tabletPortrait`
    justify-content: center;
  `};
  ${mediaQueries.mobile`
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `};
`

const ProfileDetailsHeaderInfolineList = styled.dl`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  ${mediaQueries.mobile`
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
    width: auto;
  `};
  &:after {
    content: ' · ';
    padding: 0 5px;
    ${mediaQueries.mobile`
      content: '';
    `};
  }
  &:last-child:after {
    content: '';
    padding: 0;
  }
`
const ProfileDetailsHeaderInfolineDescription = styled.dt`
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  text-align: left;
  line-height: 20px;
`
const ProfileDetailsHeaderInfolineValue = styled.dd`
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 0 5px;
  text-align: left;
  &:last-child {
    &:after {
      content: '';
      padding: 0;
    }
  }
`
const ProfileDetailsHeaderInfoline = ({
  homeLocation,
  artistType,
  memberCount,
  playedSince,
  minPrice,
}) => {
  let homeElement = null
  if (homeLocation && homeLocation.length === 2) {
    const homeLocationText = `${homeLocation[0].name}`
    homeElement = (
      <ProfileDetailsHeaderInfolineList
        itemProp="address"
        itemScope
        itemType="http://schema.org/PostalAddress"
      >
        <meta itemProp="addressLocality" content={homeLocation[1].name} />
        <meta itemProp="addressRegion" content={homeLocation[0].name} />
        <meta itemProp="addressCountry" content="no" />
        <ProfileDetailsHeaderInfolineDescription>Fylke:</ProfileDetailsHeaderInfolineDescription>
        <ProfileDetailsHeaderInfolineValue>{homeLocationText}</ProfileDetailsHeaderInfolineValue>
      </ProfileDetailsHeaderInfolineList>
    )
  }
  /*
  let memberElement = null
  if (artistType !== 'solo') {
    if (memberCount > 0) {
      memberElement = (
        <dl className="ProfileDetailsHeaderInfoline__list">
          <dt className="ProfileDetailsHeaderInfoline__list__description">Medlemmer:</dt>
          <dd className="ProfileDetailsHeaderInfoline__list__value">{memberCount}</dd>
        </dl>
      )
    }
  }
  */
  let playedSinceElement = null
  if (playedSince) {
    playedSinceElement = (
      <ProfileDetailsHeaderInfolineList>
        <ProfileDetailsHeaderInfolineDescription>
          {artistType !== 'solo' ? 'Sammen siden:' : 'Spilt siden:'}
        </ProfileDetailsHeaderInfolineDescription>
        <ProfileDetailsHeaderInfolineValue>{playedSince}</ProfileDetailsHeaderInfolineValue>
      </ProfileDetailsHeaderInfolineList>
    )
  }

  let minPriceElement = null
  if (minPrice) {
    minPriceElement = (
      <ProfileDetailsHeaderInfolineList>
        <ProfileDetailsHeaderInfolineDescription>
          Minstepris:
        </ProfileDetailsHeaderInfolineDescription>
        <ProfileDetailsHeaderInfolineValue>
          {printCurrency(minPrice)}
        </ProfileDetailsHeaderInfolineValue>
      </ProfileDetailsHeaderInfolineList>
    )
  }

  if (!minPriceElement && !playedSinceElement && !homeElement) {
    return null
  }

  return (
    <ProfileDetailsHeaderInfolineContainer>
      {minPriceElement}
      {playedSinceElement}
      {homeElement}
    </ProfileDetailsHeaderInfolineContainer>
  )
}

ProfileDetailsHeaderInfoline.propTypes = {
  artistType: PropTypes.string.isRequired,
  homeLocation: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  memberCount: PropTypes.number,
  playedSince: PropTypes.number,
  minPrice: PropTypes.number,
}

export default ProfileDetailsHeaderInfoline
