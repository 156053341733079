import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import moment from 'moment'

import { CameraIcon } from '../../../icons'
import SanityImage from '../../sanity-image'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1rem;
`

const ImageWrapper = styled.div`
  img {
    border-radius: 100%;
    width: 3rem;
    height: 3rem;
  }
  margin-right: 0.5rem;
`
const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
`

const Placeholder = styled.div`
  width: 100%;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${gigplanetTheme.color.lightGray};
  border-radius: 100%;
  border: 1px solid white;

  svg {
    color: #fff;
    height: 1rem;
    width: 1rem;
  }
`

const Author = React.memo(({ authorImage, name, date, wide = false }) => {
  const [visible, setVisible] = React.useState(false)

  const handleVisibilityChange = (isVisible) => {
    if (visible || !isVisible) {
      return
    }
    setVisible(true)
  }
  return (
    <Container>
      <ImageWrapper>
        <VisibilitySensor
          onChange={handleVisibilityChange}
          partialVisibility
          active={!visible}
          offset={{ bottom: -150 }}
        >
          {visible && authorImage ? (
            <SanityImage width={60} height={60} image={authorImage} altText={name} />
          ) : (
            <Placeholder>
              <CameraIcon />
            </Placeholder>
          )}
        </VisibilitySensor>
      </ImageWrapper>
      <NameWrapper>
        <span>{name}</span>
        {/* <span>{moment(date).format('DD.MM.yyyy')}</span> */}
      </NameWrapper>
    </Container>
  )
})

Author.propTypes = {
  authorImage: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  wide: PropTypes.bool,
}

export default Author
