import * as React from 'react'
import PropTypes from 'prop-types'
import { StyledWarning } from './style'

const LINK_PATTERN = '[Lenke skjult av Gigplanet]'
const EMAIL_PATTERN = '[Lenke skjult av Gigplanet]'
const PHONE_PATTERN = '[Tlf skjult av Gigplanet]'

const MessageWarning = React.memo(({ message, fromArtist, isArtist }) => {
  if (!isArtist) return null // Let's not bother the eventplanner with this info

  if ((message || '').indexOf(LINK_PATTERN) !== -1) {
    if (fromArtist) {
      return (
        <StyledWarning>
          Du har forsøkt å sende en lenke til arrangøren. Vi oppfordrer til å holde dialogen her. På
          den måten ønsker vi å bidra til at all dialog dokumenteres og misforståelser unngås. Når
          en booking er gjort vil all kontaktinfo bli synlig for begge parter.
        </StyledWarning>
      )
    }
    return (
      <StyledWarning>
        Arrangøren har forsøkt å sende en lenke til deg. Vi oppfordrer til å be arrangøren om å
        holde dialogen her. På den måten ønsker vi å bidra til at all dialog dokumenteres og
        misforståelser unngås. Når en booking er gjort vil all kontaktinfo bli synlig for begge
        parter.
      </StyledWarning>
    )
  }
  if ((message || '').indexOf(EMAIL_PATTERN) !== -1) {
    if (fromArtist) {
      return (
        <StyledWarning>
          Du har forsøkt å sende en e-postadresse til arrangøren. Vi oppfordrer til å holde dialogen
          her. På den måten ønsker vi å bidra til at all dialog dokumenteres og misforståelser
          unngås. Når en booking er gjort vil all kontaktinfo bli synlig for begge parter.
        </StyledWarning>
      )
    }
    return (
      <StyledWarning>
        Arrangøren har forsøkt å sende en e-postadresse til deg. Vi oppfordrer til å be arrangøren
        om å holde dialogen her. På den måten ønsker vi å bidra til at all dialog dokumenteres og
        misforståelser unngås. Når en booking er gjort vil all kontaktinfo bli synlig for begge
        parter.
      </StyledWarning>
    )
  }
  if ((message || '').indexOf(PHONE_PATTERN) !== -1) {
    if (fromArtist) {
      return (
        <StyledWarning>
          Du har forsøkt å sende et telefonnummer til arrangøren. Vi oppfordrer til å holde dialogen
          her. På den måten ønsker vi å bidra til at all dialog dokumenteres og misforståelser
          unngås. Når en booking er gjort vil all kontaktinfo bli synlig for begge parter.
        </StyledWarning>
      )
    }
    return (
      <StyledWarning>
        Arrangøren har forsøkt å sende et telefonnummer til deg. Vi oppfordrer til å be arrangøren
        om å holde dialogen her. På den måten ønsker vi å bidra til at all dialog dokumenteres og
        misforståelser unngås. Når en booking er gjort vil all kontaktinfo bli synlig for begge
        parter.
      </StyledWarning>
    )
  }
  return null
})

MessageWarning.propTypes = {
  message: PropTypes.string.isRequired,
  fromArtist: PropTypes.bool,
  isArtist: PropTypes.bool,
}

MessageWarning.defaultProps = {
  fromArtist: false,
  isArtist: false,
}

export default MessageWarning
