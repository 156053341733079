import * as React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { compose } from 'recompose'
import queryString from 'query-string'
import { withRouter } from 'react-router'

import { trackEvent } from '../../libs/analytics'
import { ArtistShape, artistSearchOrderBy } from '../../models/Artist'
import {
  searchAction,
  setSearchDataAction,
  resetSearchDataAction,
  restoreUrlAction,
} from '../../stores/Artist/ArtistActions'
import {
  getFavoriteArtists,
  getSearchArtists,
  isSearchArtistsLoading,
  isMoreSearchArtists,
  hasSearchArtistsLoaded,
  totalCountSearchArtists,
  getSearchData,
} from '../../stores/Artist/ArtistReducer'

import SearchPage from '../../components/search-page'
import MetaData from '../../components/MetaData'
import Layout from '../../layouts/main-layout'
import { postJSON } from '../../libs/data'

const SEARCH_ARTIST_LIMIT = 12

const SearchRoute = React.memo(({ history: { location } }) => {
  const dispatch = useDispatch()
  const [offset, setOffset] = React.useState(0)
  const favoriteArtists = useSelector(getFavoriteArtists)
  const artists = useSelector(getSearchArtists)
  const isLoading = useSelector(isSearchArtistsLoading)
  const isMore = useSelector(isMoreSearchArtists)
  const hasLoaded = useSelector(hasSearchArtistsLoaded)
  const totalCount = useSelector(totalCountSearchArtists)
  const searchData = useSelector(getSearchData)
  const startWithOpenFilter = Boolean(location && location.state && location.state.fromHeaderMenu)
  React.useEffect(() => {
    const query = queryString.parse(location.search)
    dispatch(restoreUrlAction({}, query, SEARCH_ARTIST_LIMIT, 0))
  }, [])

  const handleTrackClick = (id, pos) => {
    postJSON('/api/v1/stat/addSearchClick', {
      id,
      pos,
      offset,
      ...searchData,
    }).then(
      () => {},
      () => {},
    )
  }

  const performSearch = newOffset => {
    dispatch(searchAction({}, SEARCH_ARTIST_LIMIT, newOffset))
    setOffset(newOffset)
  }
  const setSearchData = data => {
    dispatch(setSearchDataAction(data))
  }
  const resetSearch = () => {
    dispatch(resetSearchDataAction())
    dispatch(searchAction({}, SEARCH_ARTIST_LIMIT, 0))
    setOffset(0)
  }

  const handleLoadMore = () => {
    const newOffset = offset + SEARCH_ARTIST_LIMIT
    trackEvent('search', 'search-show-more-artists', newOffset)
    performSearch(newOffset)
  }

  const handleSortChange = e => {
    const sortData = e.currentTarget.value
    const sort = artistSearchOrderBy.find(s => `${s.sortField}${s.sortDir}` === sortData)
    setSearchData({
      sortField: sort.sortField,
      sortDir: sort.sortDir,
    })
    trackEvent('search', 'search-sort-change', sortData)
    performSearch(0)
  }

  const handleUpdateSearch = () => {
    performSearch(0)
  }

  const handleResetSearch = () => {
    resetSearch()
    trackEvent('search', 'search-reset-search')
  }

  return (
    <Layout>
      <MetaData
        title="Søkeresultater fra Gigplanet"
        description="Hos Gigplanet kan du søke etter artister og finne den perfekte underholdningen til ditt event"
      />
      <SearchPage
        artists={artists}
        isLoading={isLoading}
        isMore={isMore}
        onLoadMore={handleLoadMore}
        hasLoaded={hasLoaded}
        totalCount={totalCount}
        sortField={searchData.sortField}
        sortDir={searchData.sortDir}
        onSortChange={handleSortChange}
        updateSearch={handleUpdateSearch}
        resetSearch={handleResetSearch}
        hasFavorites={favoriteArtists && favoriteArtists.length > 0}
        onTrackClick={handleTrackClick}
        startWithOpenFilter={startWithOpenFilter}
      />
    </Layout>
  )
})

SearchRoute.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default withRouter(SearchRoute)
