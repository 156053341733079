import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getUnreadCount as getUnreadCountForArtist } from '../../stores/GigRequestArtist/GigRequestArtistReducer'
import { getUnreadCount as getUnreadCountForEventplanner } from '../../stores/gig-request-eventplanner/reducer'

import { UnreadCount, UnreadCountMark } from './style'

const mapStateToProps = state => ({
  artistUnreadCount: getUnreadCountForArtist(state),
  eventplannerUnreadCount: getUnreadCountForEventplanner(state),
})

const GigRequestUnreadCount = ({
  hideCount,
  forArtist,
  forEventplanner,
  artistUnreadCount,
  eventplannerUnreadCount,
}) => {
  let unreadCount = 0
  if (forArtist) {
    unreadCount = artistUnreadCount
  } else if (forEventplanner) {
    unreadCount = eventplannerUnreadCount
  } else {
    unreadCount = artistUnreadCount + eventplannerUnreadCount
  }

  if (unreadCount === 0) return null

  if (hideCount) return <UnreadCountMark />
  return <UnreadCount>{unreadCount}</UnreadCount>
}

GigRequestUnreadCount.propTypes = {
  artistUnreadCount: PropTypes.number.isRequired,
  eventplannerUnreadCount: PropTypes.number.isRequired,
  hideCount: PropTypes.bool,
  forArtist: PropTypes.bool,
  forEventplanner: PropTypes.bool,
}

GigRequestUnreadCount.defaultProps = {
  hideCount: false,
  forArtist: false,
  forEventplanner: false,
}

export default connect(mapStateToProps)(GigRequestUnreadCount)
