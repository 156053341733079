import styled from 'styled-components'
import gigplanetTheme from '../../../styles/gigplanetTheme'

export const HeadingWrapper = styled.div`
  margin-bottom: 25px;
  text-align: center;
`

export const IntroWrapper = styled.div`
  margin-bottom: 25px;
`

export const RegisterWrapper = styled.div`
  padding: 5px 0;
`

export const SeparatorWrapper = styled.div`
  padding: 15px 0;
`

export const ExistingAccountWrapper = styled.div`
  padding-top: 10px;
  line-height: 20px;
  text-align: center;
`

export const LoginButton = styled.button`
  border: 0;
  padding: 0;
  line-height: 20px;
  background: transparent;
  color: ${gigplanetTheme.color.purple};
  cursor: pointer;
`

export const TermsWrapper = styled.div`
  padding: 30px 0 10px;
`
