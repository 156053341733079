import * as React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'

import { imageUrlFor } from '../../../../helpers/sanity-image-builder'
import { mediaQueriesDefinitions } from '../../../../styles/mediaQueries'
import { Placeholder } from './style'
import { CameraIcon } from '../../../../icons'
import SanityImage from '../../../sanity-image'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'

const ImageBlock = React.memo(({ theme, anchorId, image }) => {
  const [visible, setIsVisible] = React.useState(false)

  const handleVisibilityChange = isVisible => {
    if (visible || !isVisible) {
      return
    }
    setIsVisible(true)
  }

  return (
    <LandingPageBlockWrapper
      noPadding
      fullWidth={image.layout && image.layout === 'full'}
      theme={theme}
      anchorId={anchorId}
    >
      <VisibilitySensor
        onChange={handleVisibilityChange}
        partialVisibility
        active={!visible}
        offset={{ bottom: -150 }}
      >
        {visible && image && image.asset ? (
          <SanityImage width={2000} height={600} image={image} altText={image.altText}>
            <source
              media={mediaQueriesDefinitions.mobileDown}
              srcSet={imageUrlFor(image)
                .width(600)
                .height(450)}
            />
            <source
              media={mediaQueriesDefinitions.tabletLandscapeDown}
              srcSet={imageUrlFor(image)
                .width(1200)
                .height(500)}
            />
            <source
              media={mediaQueriesDefinitions.desktopDown}
              srcSet={imageUrlFor(image)
                .width(1800)
                .height(500)}
            />
          </SanityImage>
        ) : (
          <Placeholder>
            <CameraIcon />
          </Placeholder>
        )}
      </VisibilitySensor>
    </LandingPageBlockWrapper>
  )
})
/*
imageUrlFor(siteSettings.metaImage)
  .width(1200)
  .height(630)
  .quality(60)
  .url()
  */
ImageBlock.propTypes = {
  theme: PropTypes.string.isRequired,
  anchorId: PropTypes.string,
  image: PropTypes.shape({
    altText: PropTypes.string,
    layout: PropTypes.oneOf(['full', 'column']),
  }).isRequired,
}
ImageBlock.defaultProps = {
  anchorId: '',
}

export default ImageBlock
