import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

const LinkWrapper = React.forwardRef(({ to, inDropdown = false, children, ...rest }, ref) => (
  <Link to={to} {...rest} ref={ref}>
    {children}
  </Link>
))

LinkWrapper.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  inDropdown: PropTypes.bool,
  children: PropTypes.element.isRequired,
}

const buttonStyles = css`
  appearance: none;
  display: block;
  margin: 0 5px 0 0;

  background: transparent;
  border: 0;
  color: ${gigplanetTheme.color.primary};
  cursor: pointer;
  font-size: 16px;
  margin: 0;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  height: 70px;
  position: relative;

  &:hover {
    color: #924dff;
  }

  ${({ hasDarkBg }) =>
    hasDarkBg &&
    css`
      color: #fff;
      &:hover {
        color: #ddd;
      }
    `}

  ${mediaQueries.mobile`
    height: 50px;
    padding: 0 10px;
    ${({ inDropdown }) =>
      inDropdown &&
      css`
        padding: 0;
      `};
`};

  ${({ inDropdown }) =>
    inDropdown &&
    css`
      margin: 0;
      padding: 0;
      width: 100%;
      text-align: left;
      height: 50px;
    `};
`

export const StyledHeaderMenuButton = styled.button`
  ${buttonStyles};
`
export const StyledHeaderMenuLink = styled(LinkWrapper)`
  ${buttonStyles};
`

export const Content = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  height: 70px;

  ${mediaQueries.mobile`
    height: 50px;
  `};

  ${({ inDropdown }) =>
    inDropdown &&
    css`
      text-align: left;
      height: auto;
      width: 100%;
      justify-content: flex-start;
      height: 50px;
      padding: 0 10px;
    `};
`
