import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect, useSelector } from 'react-redux'

import { INBOX_URL as CONTROLPANEL_REQUESTS_URL } from '../../models/Controlpanel'
import { DASHBOARD_URL as EVENTPLANNER_REQUESTS_URL } from '../../models/Eventplanner'

import { getCurrentUser } from '../../stores/CurrentUser/CurrentUserReducer'
import { getUserArtists, getFavoriteArtists } from '../../stores/Artist/ArtistReducer'
import { getMyEventplannerRequests } from '../../stores/gig-request-eventplanner/reducer'
import { getMyArtistRequests } from '../../stores/GigRequestArtist/GigRequestArtistReducer'

import LoggedInDektopMenu from './components/logged-in-desktop-menu'
import LoggedInMobileMenu from './components/logged-in-mobile-menu'
import { ModalContent } from '../../components/ModalWindow'
import { ModalContentWrapper } from '../../ui'
import Button from '../../ui/Button'
import { H1 } from '../../ui/Headings'
import FormAction from '../../ui/form-action'

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  userArtists: getUserArtists(state),
  favoriteArtists: getFavoriteArtists(state),
  artistGigRequest: getMyArtistRequests(state),
  eventplannerGigRequest: getMyEventplannerRequests(state),
})

const mapDispatchToProps = (dispatch) => ({})

const HeaderMenuLoggedInContainer = React.memo(
  ({
    currentUser,
    userArtists,
    artistGigRequest,
    eventplannerGigRequest,
    hasDarkBg,
    focusCallback,
    showControlpanelMenu,
    showEventplannerMenu,
  }) => {
    const [showingOptions, setShowingOptions] = React.useState(false)
    const [unreadCount, setUnreadCount] = React.useState(0)
    const favoriteArtists = useSelector(getFavoriteArtists)
    const favoriteCount = favoriteArtists && favoriteArtists.length

    const showOptions = () => {
      setShowingOptions(true)
      focusCallback(false)
    }

    const hideOptions = () => {
      setShowingOptions(false)
      focusCallback(true)
    }

    React.useEffect(() => {
      if (window && window.Intercom) {
        window.Intercom('onUnreadCountChange', (newUnreadCount) => {
          setUnreadCount(newUnreadCount)
        })
      }
    }, [])

    const showControlpanelButton = userArtists && userArtists.length > 0
    const artistGigRequestCount =
      artistGigRequest && artistGigRequest.length > 0 ? artistGigRequest.length : 0
    const eventplannerGigRequestCount =
      eventplannerGigRequest && eventplannerGigRequest.length > 0
        ? eventplannerGigRequest.length
        : 0

    return (
      <>
        <LoggedInMobileMenu
          currentUser={currentUser}
          unreadCount={unreadCount}
          showControlpanelButton={showControlpanelButton}
          artistGigRequestCount={artistGigRequestCount}
          eventplannerGigRequestCount={eventplannerGigRequestCount}
          onShowRequestOptions={showOptions}
          showingRequestOptions={showingOptions}
          favoriteCount={favoriteCount}
          hasDarkBg={hasDarkBg}
          focusCallback={focusCallback}
          showControlpanelMenu={showControlpanelMenu}
          showEventplannerMenu={showEventplannerMenu}
        />
        <LoggedInDektopMenu
          unreadCount={unreadCount}
          showControlpanelButton={showControlpanelButton}
          artistGigRequestCount={artistGigRequestCount}
          eventplannerGigRequestCount={eventplannerGigRequestCount}
          onShowRequestOptions={showOptions}
          showingRequestOptions={showingOptions}
          favoriteCount={favoriteCount}
          hasDarkBg={hasDarkBg}
          focusCallback={focusCallback}
          showControlpanelMenu={showControlpanelMenu}
          showEventplannerMenu={showEventplannerMenu}
        />
        <ModalContent
          id="header-request-window"
          label="Forespørsler"
          isOpen={showingOptions}
          onClose={hideOptions}
          hideClose
        >
          <ModalContentWrapper>
            <H1>Velg rolle</H1>
            <FormAction>
              <Button
                type="button"
                theme="primary"
                to={EVENTPLANNER_REQUESTS_URL}
                onClick={hideOptions}
              >
                Arrangør
              </Button>
              <Button
                type="button"
                theme="primary"
                to={CONTROLPANEL_REQUESTS_URL}
                onClick={hideOptions}
              >
                Artist
              </Button>
            </FormAction>
          </ModalContentWrapper>
        </ModalContent>
      </>
    )
  },
)

HeaderMenuLoggedInContainer.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
  userArtists: PropTypes.arrayOf(PropTypes.shape({})),
  artistGigRequest: PropTypes.arrayOf(PropTypes.shape({})),
  eventplannerGigRequest: PropTypes.arrayOf(PropTypes.shape({})),
  hasDarkBg: PropTypes.bool,
  focusCallback: PropTypes.func,
  showControlpanelMenu: PropTypes.bool,
  showEventplannerMenu: PropTypes.bool,
}

HeaderMenuLoggedInContainer.defaultProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(HeaderMenuLoggedInContainer)
