import * as React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { GigRequestFormEvent } from '../../../../components/GigRequestForm'
import { getArtist } from '../../../../stores/Artist/ArtistReducer'

const ProfileDetailsGigRequestFormEventRoute = React.memo(() => {
  const artist = useSelector(getArtist)
  const history = useHistory()

  const handleSubmit = () => {
    history.push(`/${artist.artistType || 'artist'}/${artist.slug}/gigrequest/receipt`)
  }

  const handleNext = () => {
    history.push(`/${artist.artistType || 'artist'}/${artist.slug}/gigrequest/event-details`)
  }

  const handlePrevious = () => {
    history.push(`/${artist.artistType || 'artist'}/${artist.slug}`)
  }

  return (
    <GigRequestFormEvent onSubmit={handleSubmit} onNext={handleNext} onPrevious={handlePrevious} />
  )
})

export default ProfileDetailsGigRequestFormEventRoute
