import * as React from 'react'
import PropTypes from 'prop-types'
import { Container, OuterWrapper, InnerWrapper, Sidebar, Content } from './style'

const GigReQuestInboxWrapper = ({ renderSidebar, children }) => (
  <Container>
    <OuterWrapper>
      <InnerWrapper>
        <Sidebar>{renderSidebar ? renderSidebar() : null}</Sidebar>
        <Content>{children}</Content>
      </InnerWrapper>
    </OuterWrapper>
  </Container>
)

GigReQuestInboxWrapper.propTypes = {
  renderSidebar: PropTypes.func,
  children: PropTypes.element,
}
GigReQuestInboxWrapper.defaultProps = {
  renderSidebar: null,
  children: null,
}

export default GigReQuestInboxWrapper
