import * as React from 'react'
import PropTypes from 'prop-types'

import { ModalButton, ModalContent } from '../ModalWindow'

import { PrintQuoteIframe, IframeWrapper } from './style'

const GigRequestQuotePrintModal = ({ id, url, renderButton }) => {
  const [showingModal, setShowingModal] = React.useState(false)

  const openModal = () => {
    setShowingModal(true)
  }

  const closeModal = () => {
    setShowingModal(false)
  }
  return (
    <>
      <ModalButton
        id={id}
        isOpen={showingModal}
        onClick={openModal}
        renderButton={props => renderButton(props)}
      />
      <ModalContent id={id} isOpen={showingModal} onClose={closeModal}>
        <IframeWrapper>
          <PrintQuoteIframe src={url} />
        </IframeWrapper>
      </ModalContent>
    </>
  )
}

GigRequestQuotePrintModal.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  renderButton: PropTypes.func.isRequired,
}
GigRequestQuotePrintModal.defaultProps = {}

export default GigRequestQuotePrintModal
