const generateSearchUrlFromObject = paramsObject => {
  let params = ''
  let isFirst = true
  Object.keys(paramsObject).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(paramsObject, key)) {
      params += `${isFirst ? '?' : '&'}${key}=${paramsObject[key]}`
      isFirst = false
    }
  })
  return params
}

export default generateSearchUrlFromObject
