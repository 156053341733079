import moment from 'moment'

const getPlaytimeText = (startTimeString, endTimeString, pauseCount = 0, pauseDuration = 0) => {
  let durationText = ''

  const startTime = moment(startTimeString)
  const endTime = moment(endTimeString)
  const diff = endTime.diff(startTime, 'hours', true)

  if (diff < 1) {
    durationText = `${moment.duration(diff, 'hours').asMinutes()} minutter`
  } else {
    durationText =
      diff === 1 || diff % 1 !== 0
        ? `${diff.toString().replace('.', ',')} time`
        : `${diff.toString().replace('.', ',')} timer`
  }

  if (pauseCount === 0 && pauseDuration === 0) {
    return durationText
  }

  let playTimeText = ''
  const playTimeDiff = diff - (pauseCount * pauseDuration) / 60

  if (playTimeDiff < 1) {
    playTimeText = `${moment.duration(playTimeDiff, 'hours').asMinutes()} minutter`
  } else {
    playTimeText =
      playTimeDiff === 1 || playTimeDiff % 1 !== 0
        ? `${playTimeDiff.toString().replace('.', ',')} time`
        : `${playTimeDiff.toString().replace('.', ',')} timer`
  }
  return playTimeText
}

export default getPlaytimeText
