import * as React from 'react'
import PropTypes from 'prop-types'

import Layout from '../../layouts/main-layout'
import SystemMessageWrapper from '../../ui/system-message-wrapper'
import UserAuthPasswordResetError from '../../components/user-auth-password-reset-error'

const PasswordResetErrorView = ({ successUrl, onClose }) => (
  <Layout>
    <SystemMessageWrapper>
      <UserAuthPasswordResetError successUrl={successUrl} onClose={onClose} />
    </SystemMessageWrapper>
  </Layout>
)

PasswordResetErrorView.propTypes = {
  successUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(PasswordResetErrorView)
