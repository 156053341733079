import styled, { css } from 'styled-components'

import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const ListItem = styled.li`
  width: 100%;
  padding: 0;
  margin: 0;
  padding: 10px 5px;
  border-top: 1px solid #e6e9ee;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  max-height: 60px;

  ${({ isFirst }) =>
    isFirst &&
    css`
      border-top: 0;
    `};

  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 1000px;
    `};
`

export const ToggleButton = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
  width: 100%;
  height: 40px;
  text-align: left;
`

export const Content = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Question = styled.span`
  color: ${gigplanetTheme.color.purple};
  font-size: 20px;
  margin: 0;
  line-height: 20px;
  font-weight: 300;

  ${mediaQueries.mobile`
    font-size: 16px;
    font-weight: 300;
  `};
`

export const IconWrapper = styled.span`
  transition: transform 0.2s ease-in-out;

  svg {
    height: 30px;
    width: 30px;
    fill: ${gigplanetTheme.color.darkGray};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `};
`

export const Answer = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  ${mediaQueries.mobile`
    font-size: 14px;
  `};

  p {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0;
    padding: 10px 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  h1 {
    color: ${gigplanetTheme.component.headings.h1.text};
    font-size: 20px;
    margin: 20px 0 5px;
    text-align: left;
  }

  h2 {
    color: ${gigplanetTheme.component.headings.h1.text};
    font-size: 18px;
    margin: 20px 0 5px;
    text-align: left;
  }

  h3 {
    color: ${gigplanetTheme.component.headings.h1.text};
    font-size: 16px;
    margin: 20px 0 5px;
    text-align: left;
  }

  ul {
    margin: 0;
  }

  li {
    font-size: 16px;
    line-height: 18px;
  }

  img {
    max-width: 100%;
  }

  a {
    color: ${gigplanetTheme.color.primary};
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
    `};
`
