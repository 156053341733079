import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { CurrentUserShape } from '../../models/CurrentUser'
import { getCurrentUser } from '../../stores/CurrentUser/CurrentUserReducer'

import {
  getOneEventplannerRequestForArtistAction,
  updateUnreadCountAction,
  resetUnreadCountAction,
} from '../../stores/gig-request-eventplanner/actions'
import {
  getOneEventplannerRequestForArtist,
  hasOneEventplannerRequestForArtistLoaded,
  isOneEventplannerRequestForArtistLoading,
} from '../../stores/gig-request-eventplanner/reducer'
import { getEventTypes } from '../../stores/Options/OptionsReducer'

import PageNotFound from '../../components/PageNotFound'
import GigRequestEventplannerDetails from './components/gig-request-details-eventplanner'
import { postJSON } from '../../libs/data'

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  eventplannerRequest: getOneEventplannerRequestForArtist(state),
  isLoading: isOneEventplannerRequestForArtistLoading(state),
  hasLoaded: hasOneEventplannerRequestForArtistLoaded(state),
  eventTypes: getEventTypes(state),
})

const mapDispatchToProps = dispatch => ({
  getOneRequest: (gigRequestId, artistId) => {
    dispatch(getOneEventplannerRequestForArtistAction(gigRequestId, artistId))
  },
  updateUnreadCount: () => {
    dispatch(updateUnreadCountAction())
  },
  resetUnreadCount: (gigRequestId, artistId) => {
    dispatch(resetUnreadCountAction(gigRequestId, artistId))
  },
})

const EventplannerInboxRequestDetailsContainer = React.memo(
  ({
    gigRequestId,
    artistId,
    getOneRequest,
    eventplannerRequest,
    hasLoaded,
    currentUser,
    isLoading,
    eventTypes,
    updateUnreadCount,
    resetUnreadCount,
  }) => {
    React.useEffect(() => {
      if (gigRequestId && artistId) {
        getOneRequest(gigRequestId, artistId)
      }
      postJSON('/api/v1/gigRequest/unreadReset', { gigRequestId, artistId })
        .then(json => updateUnreadCount())
        .catch(() => {
          // catch
        })
      resetUnreadCount(gigRequestId, artistId)
    }, [gigRequestId, artistId])

    if (hasLoaded && !{}.hasOwnProperty.call(eventplannerRequest, 'id')) {
      return <PageNotFound />
    }

    return (
      <GigRequestEventplannerDetails
        currentUser={currentUser}
        eventplannerRequest={eventplannerRequest}
        gigRequestId={gigRequestId}
        artistId={artistId}
        isLoading={isLoading}
        hasLoaded={hasLoaded}
        eventTypes={eventTypes}
      />
    )
  },
)

EventplannerInboxRequestDetailsContainer.propTypes = {
  // normal props
  gigRequestId: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,

  // state props
  currentUser: CurrentUserShape.isRequired,
  eventplannerRequest: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  eventTypes: PropTypes.shape({}).isRequired,

  // dispatch props
  getOneRequest: PropTypes.func.isRequired,
  updateUnreadCount: PropTypes.func.isRequired,
  resetUnreadCount: PropTypes.func.isRequired,
}

EventplannerInboxRequestDetailsContainer.defaultProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventplannerInboxRequestDetailsContainer)
