import * as React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { imageUrlFor } from '../../../../helpers/sanity-image-builder'

import { Content, List, Item } from './style'

import { H2 } from '../../../../ui/landing-page-headings'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'
import isExternalLink from '../../../../helpers/is-external-link'
import extractUrlPath from '../../../../helpers/extract-url-path'
import { OpenInNewIcon } from '../../../../icons'
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import { Loader } from '../../../../ui'

const QuoteName = styled.h3`
  margin: 5px 0;
`

const QuoteImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  border: 0;
`

const QuoteText = styled.blockquote`
  font-style: italic;
  margin: 20px 0;
`
const ExternalLink = styled.a`
  cursor: pointer;
  color: ${gigplanetTheme.color.primary};
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  svg {
    height: 16px;
    width: 16px;
    margin-left: 5px;
  }

  &:hover {
    text-decoration: underline;
  }
`
const InternalLink = styled(Link)`
  cursor: pointer;
  color: ${gigplanetTheme.color.primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const LinkRenderer = React.memo(({ href, children }) => {
  const isExternal = isExternalLink(href)
  if (isExternal) {
    return (
      <ExternalLink href={href} target="_blank">
        <span>{children}</span>
        <OpenInNewIcon />
      </ExternalLink>
    )
  }
  return <InternalLink to={extractUrlPath(href)}>{children}</InternalLink>
})

const LAYOUT_HORIZONTAL = 'HORIZONTAL'
const LAYOUT_VERTICAL = 'VERTICAL'

const QuotesListBlock = React.memo(({ theme, layout, anchorId, heading, quotesList }) => {
  const [visible, setVisibility] = React.useState(false)

  const handleVisibilityChange = (isVisible) => {
    if (visible || !isVisible) {
      return
    }
    setVisibility(true)
  }

  return (
    <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
      <VisibilitySensor
        onChange={handleVisibilityChange}
        partialVisibility
        active={!visible}
        offset={{ bottom: -150 }}
      >
        <>
          {heading ? (
            <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
          ) : null}
          <Content>
            <List layout={layout}>
              {(quotesList || []).map((quote) => (
                <Item key={quote.id} layout={layout}>
                  {quote.quoteType === 'artist' ? (
                    <>
                      {quote.quoteImage && visible ? (
                        <QuoteImage
                          src={imageUrlFor(quote.quoteImage)
                            .height(240)
                            .width(240)
                            .quality(60)
                            .url()}
                          alt={quote.name}
                          role="presentation"
                        />
                      ) : null}
                      <QuoteText>"{quote.quoteText}"</QuoteText>
                      <QuoteName>{quote.name}</QuoteName>
                      <LinkRenderer href={quote.artistUrl}>{quote.artistName}</LinkRenderer>
                    </>
                  ) : (
                    <>
                      {quote.quoteImage ? (
                        <QuoteImage
                          src={imageUrlFor(quote.quoteImage)
                            .height(240)
                            .width(240)
                            .quality(60)
                            .url()}
                          alt={quote.name}
                          role="presentation"
                        />
                      ) : null}
                      <QuoteText>"{quote.quoteText}"</QuoteText>
                      <QuoteName>{quote.name}</QuoteName>
                    </>
                  )}
                </Item>
              ))}
            </List>
          </Content>
        </>
      </VisibilitySensor>
    </LandingPageBlockWrapper>
  )
})

QuotesListBlock.propTypes = {
  heading: PropTypes.string,
  layout: PropTypes.oneOf([LAYOUT_HORIZONTAL, LAYOUT_VERTICAL]),
  quotesList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  theme: PropTypes.oneOf(['gray', 'white']).isRequired,
  anchorId: PropTypes.string,
}

QuotesListBlock.defaultProps = {
  heading: '',
  anchorId: '',
}

export default QuotesListBlock
