/* globals window */
import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ImageLoader from '../ImageLoader'
import imagePreloader from '../../helpers/ImagePreloader'

import Loader from '../../ui/Loader'
import {
  getImgixUrl,
  ARTIST_IMAGE_IMGIX_FORMAT_GALLERY_DESKTOP,
  ARTIST_IMAGE_IMGIX_FORMAT_GALLERY_TABLET,
  ARTIST_IMAGE_IMGIX_FORMAT_GALLERY_MOBILE,
} from '../../models/Artist'

import Header from './components/Header'
import NavigateButton from './components/NavigateButton'
import Footer from './components/Footer'

export const ProfileDetailsImageGalleryContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-between;
`

export const ProfileDetailsImageGalleryCarusel = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  min-height: 412px;
  width: 100%;
`

export const ProfileDetailsImageGalleryPreloader = styled.div`
  color: white;
`

const preloader = () => (
  <ProfileDetailsImageGalleryPreloader>
    <Loader text="Laster bilde" size="medium" />
  </ProfileDetailsImageGalleryPreloader>
)

const LEFT = 37
const RIGHT = 39

const getImageSrc = src => getImgixUrl(src, ARTIST_IMAGE_IMGIX_FORMAT_GALLERY_DESKTOP)
const getImageSrcSet = src =>
  `${getImgixUrl(src, ARTIST_IMAGE_IMGIX_FORMAT_GALLERY_DESKTOP)} 1500w,
  ${getImgixUrl(src, ARTIST_IMAGE_IMGIX_FORMAT_GALLERY_TABLET)} 1200w,
  ${getImgixUrl(src, ARTIST_IMAGE_IMGIX_FORMAT_GALLERY_MOBILE)} 500w`

class ProfileDetailsImageGallery extends React.Component {
  constructor(props) {
    super(props)
    const { startAtIndex } = this.props
    this.state = {
      index: startAtIndex,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', this.handleKeydown)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('keydown', this.handleKeydown)
    }
  }

  onShowNextImage = () => {
    const { index } = this.state
    const { images, onNavigate } = this.props
    let newIndex = index + 1
    if (newIndex >= images.length) {
      newIndex = 0
    }
    console.log('onShowNextImage', newIndex)
    this.setState(
      {
        index: newIndex,
      },
      () => {
        onNavigate(index)
      },
    )
  }

  onShowPrevImage = () => {
    const { index } = this.state
    const { images, onNavigate } = this.props
    let newIndex = index - 1
    if (newIndex < 0) {
      newIndex = images.length - 1
    }
    console.log('onShowPrevImage', newIndex)
    this.setState(
      {
        index: newIndex,
      },
      () => {
        onNavigate(index)
      },
    )
  }

  handleKeydown = event => {
    switch (event.keyCode) {
      case RIGHT: {
        this.onShowNextImage()
        break
      }
      case LEFT: {
        this.onShowPrevImage()
        break
      }
      default:
        break
    }
  }

  render() {
    const { images, showHeader, showFooter } = this.props
    const { index } = this.state
    const img = images[index]

    const src = getImageSrc(img.src)
    const srcSet = getImageSrcSet(img.src)

    let nextIndex = index + 1
    if (nextIndex >= images.length) {
      nextIndex = 0
    }
    const nextImg = images[nextIndex]
    imagePreloader(getImageSrc(nextImg.src), getImageSrcSet(nextImg.src))

    const altText = img.text ? `Foto: ${img.text}` : ''
    return (
      <ProfileDetailsImageGalleryContainer>
        {showHeader ? <Header imageNumber={index + 1} imageLength={images.length} /> : null}
        <ProfileDetailsImageGalleryCarusel>
          {images.length > 0 ? (
            <NavigateButton onClick={this.onShowPrevImage} direction="left" />
          ) : null}
          <ImageLoader
            src={src}
            srcSet={srcSet}
            preloader={preloader}
            imgProps={{
              alt: altText,
            }}
          >
            <span>Feil ved lasting av bilde</span>
          </ImageLoader>
          {images.length > 0 ? (
            <NavigateButton onClick={this.onShowNextImage} direction="right" />
          ) : null}
        </ProfileDetailsImageGalleryCarusel>
        {showFooter ? <Footer footerText footerText={img.text ? `Foto: ${img.text}` : ''} /> : null}
      </ProfileDetailsImageGalleryContainer>
    )
  }
}

ProfileDetailsImageGallery.propTypes = {
  startAtIndex: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onNavigate: PropTypes.func.isRequired,
  showHeader: PropTypes.bool.isRequired,
  showFooter: PropTypes.bool.isRequired,
}

export default ProfileDetailsImageGallery
