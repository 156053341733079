import * as React from 'react'
import PropTypes from 'prop-types'

import { ArtistShape } from '../../../models/Artist'
import { CurrentUserShape } from '../../../models/CurrentUser'
import {
  EVENTPLANNER_RESPONSE_OPTIONS,
  REQUEST_STATUS,
  QUOTE_STATUS,
  QuoteShape,
  getEventplannerRequestStatusHeading,
  REVIEW_STATUS,
} from '../../../models/GigRequest'

import {
  Container,
  NewMessageWrapper,
  ActionsWrapper,
  ButtonWrapper,
  Message,
  User,
  Alert,
  AlertText,
  AlertActions,
  QuoteWrapper,
  ContactInfo,
} from './style'
import SystemDialog from '../../../components/SystemDialog'
import DeclineQuote from '../../gig-request-quote-decline-eventplanner'

import GigRequestNewMessage from '../../gig-request-new-message-eventplanner'

import Button from '../../../ui/Button'
import { H2 } from '../../../ui/Headings'
import GigRequestResponseOptionsQuoteDetails from '../../../components/gig-request-response-options-quote-details'
import GigRequestResponseOptionsContactInfo from '../../../components/gig-request-response-options-contact-info'
import UserAvatar from '../../../ui/UserAvatar'
import GigRequestQuotePrintModal from '../../../components/gig-request-quote-print-modal'
import GigRequestOptionsMatchingArtists from '../../GigRequestOptionsMatchingArtists'

class GigRequestArtistResponseOptions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showConfirmWindow: false,
    }
  }

  handleConfirmNavigation = e => {
    const { onCompletedAction } = this.props
    onCompletedAction()
    this.setState({
      showConfirmWindow: false,
    })
  }

  handleCancelNavigation = () => {
    this.setState({
      showConfirmWindow: false,
    })
  }

  handleShowDeclineQuote = e => {
    const { formIsDirty, onShowDeclineQuote } = this.props
    if (!formIsDirty) {
      onShowDeclineQuote(e)
    } else {
      this.setState({
        showConfirmWindow: true,
      })
    }
  }

  handleCancel = e => {
    const { formIsDirty, onCompletedAction } = this.props
    if (formIsDirty) {
      this.setState({
        showConfirmWindow: true,
      })
    } else {
      onCompletedAction()
    }
  }

  render() {
    const {
      gigRequestId,
      artistId,
      activeId,
      artist,
      artistRequest,
      artistQuote,
      onMarkFormAsDirty,
      onCompletedAction,
      currentUser,
      gigRequest,
      eventTypes,
      printQuoteUrl,
    } = this.props
    const { showConfirmWindow } = this.state
    const artistStatus = artistRequest ? artistRequest.artistStatus : ''
    const quoteStatus = artistQuote ? artistQuote.status : ''
    return (
      <Container>
        {showConfirmWindow ? (
          <SystemDialog
            id="confirm-navigation"
            onClose={this.handleCancelNavigation}
            hideClose
            heading="Sikker på at du vil lukke skjemaet?"
          >
            <Alert>
              <AlertText>Du vil miste innholdet i skjemaet hvis du går videre.</AlertText>
              <AlertActions>
                <Button type="button" theme="abort" onClick={this.handleConfirmNavigation}>
                  Lukk skjemaet
                </Button>
                <Button type="button" theme="secondary" onClick={this.handleCancelNavigation}>
                  Ikke lukk skjemaet
                </Button>
              </AlertActions>
            </Alert>
          </SystemDialog>
        ) : null}

        <ActionsWrapper>
          {!activeId ? (
            <>
              <H2>{getEventplannerRequestStatusHeading(artistStatus, quoteStatus)}</H2>
              <>
                {artistStatus === REQUEST_STATUS.QUOTE_ACCEPTED ||
                artistStatus === REQUEST_STATUS.COMPLETED ? (
                  <>
                    {artist && artist.artist && artist.artist.contactInfo ? (
                      <ContactInfo>
                        <GigRequestResponseOptionsContactInfo
                          artistName={artist.artist.name}
                          name={artist.artist.contactInfo.contactName}
                          phone={artist.artist.contactInfo.contactPhone}
                          email={artist.artist.contactInfo.contactEmail}
                        />
                      </ContactInfo>
                    ) : null}
                    <ButtonWrapper>
                      {artistStatus === REQUEST_STATUS.COMPLETED &&
                      artist.gig &&
                      artist.gig.reviewStatus === REVIEW_STATUS.NOT_REVIEWED ? (
                        <Button theme="primary" to={`/write-review/${artist.gig.id}`}>
                          Skriv en anmeldelse
                        </Button>
                      ) : null}
                      <GigRequestQuotePrintModal
                        id="print-quote"
                        url={printQuoteUrl}
                        renderButton={props => (
                          <Button type="button" theme="secondary" {...props}>
                            Vis kontrakt
                          </Button>
                        )}
                      />
                    </ButtonWrapper>
                  </>
                ) : null}
                {artistStatus === REQUEST_STATUS.ARTIST_DECLINED ? (
                  <GigRequestOptionsMatchingArtists
                    gigRequestId={gigRequestId}
                    artistName={artist && artist.artist ? artist.artist.name : ''}
                    artistId={artist && artist.artist ? artist.artist.id : null}
                  />
                ) : null}
                {artistStatus === REQUEST_STATUS.ACTIVE ? (
                  <>
                    {quoteStatus === QUOTE_STATUS.PENDING ? (
                      <>
                        <QuoteWrapper>
                          <GigRequestResponseOptionsQuoteDetails
                            quote={artistQuote}
                            gigRequest={gigRequest}
                            eventTypes={eventTypes}
                          />
                        </QuoteWrapper>
                        <ButtonWrapper>
                          <Button
                            theme="primary"
                            to={`/eventplanner/inbox/${gigRequestId}/${artistId}/booking/${artistQuote.id}`}
                          >
                            Vis kontraktsforslag
                          </Button>
                          <Button
                            type="button"
                            theme="secondary"
                            onClick={this.handleShowDeclineQuote}
                          >
                            Avslå tilbudet
                          </Button>
                        </ButtonWrapper>
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            </>
          ) : (
            <>
              {activeId === EVENTPLANNER_RESPONSE_OPTIONS.DECLINE_QUOTE ? (
                <DeclineQuote
                  gigRequestId={gigRequestId}
                  artistId={artistId}
                  quoteId={artistQuote.id}
                  onCompleted={onCompletedAction}
                  onCancel={this.handleCancel}
                  onMarkFormAsDirty={onMarkFormAsDirty}
                />
              ) : null}
            </>
          )}
        </ActionsWrapper>

        <NewMessageWrapper>
          <Message>
            <GigRequestNewMessage gigRequestId={gigRequestId} artistId={artistId} />
          </Message>
          <User>
            <UserAvatar user={currentUser} size={50} />
          </User>
        </NewMessageWrapper>
      </Container>
    )
  }
}

GigRequestArtistResponseOptions.propTypes = {
  currentUser: CurrentUserShape,
  artistQuote: QuoteShape,
  artistRequest: PropTypes.shape({}),
  artist: ArtistShape,
  gigRequestId: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,
  activeId: PropTypes.string,
  onShowDeclineQuote: PropTypes.func.isRequired,
  onCompletedAction: PropTypes.func.isRequired,
  onMarkFormAsDirty: PropTypes.func.isRequired,
  formIsDirty: PropTypes.bool.isRequired,
  gigRequest: PropTypes.shape({}).isRequired,
  eventTypes: PropTypes.shape({}).isRequired,
  printQuoteUrl: PropTypes.string,
}
GigRequestArtistResponseOptions.defaultProps = {
  activeId: '',
  artist: null,
  printQuoteUrl: '',
}

export default GigRequestArtistResponseOptions
