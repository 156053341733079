import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'

import { StyledHeaderMenuButton } from './style'

const BurgerWrapper = styled.svg`
  vertical-align: middle;
  margin-top: 5px;
  color: ${gigplanetTheme.color.primary};
  transition: all 0.15s ease-in-out;
  ${({ hasDarkBg }) =>
    hasDarkBg &&
    css`
      color: #fff;
    `}
  ${StyledHeaderMenuButton}:hover & {
    transform: scale(1.1);
  }
`

const sharedLineStyles = `
  transition: all 0.15s ease-in-out;
  transform-origin: center center;
`

const BurgerLineOne = styled.path`
  ${sharedLineStyles}
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotateZ(45deg) translateX(-6px) translateY(10px) scale(1.1);
    `}
`

const BurgerLineTwo = styled.path`
  ${sharedLineStyles}
  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 0;
    `}
`

const BurgerLineThree = styled.path`
  ${sharedLineStyles}
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotateZ(135deg) translateX(-5px) translateY(11px) scale(1.1);
    `}
`

const ClosedText = styled.text`
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 0;
    `}
`

const OpenText = styled.text`
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
    `}
`

const HeaderBurgerButton = ({ isOpen, hasDarkBg }) => {
  return (
    <StyledHeaderMenuButton type="button">
      <BurgerWrapper
        width="28"
        height="30"
        viewBox="0 0 28 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        hasDarkBg={hasDarkBg}
      >
        <BurgerLineOne isOpen={isOpen} d="M6 1H22" stroke="currentColor" strokeWidth="2" />
        <BurgerLineTwo isOpen={isOpen} d="M6 6H22" stroke="currentColor" strokeWidth="2" />
        <BurgerLineThree isOpen={isOpen} d="M6 11H22" stroke="currentColor" strokeWidth="2" />
        <ClosedText
          isOpen={isOpen}
          x="1"
          y="25"
          fill="currentColor"
          fontSize="10px"
          fontWeight="400"
        >
          Meny
        </ClosedText>
        <OpenText isOpen={isOpen} x="2" y="25" fill="currentColor" fontSize="10px" fontWeight="400">
          Lukk
        </OpenText>
      </BurgerWrapper>
    </StyledHeaderMenuButton>
  )
}

HeaderBurgerButton.propTypes = {
  isOpen: PropTypes.bool,
  hasDarkBg: PropTypes.bool,
}

HeaderBurgerButton.defaultProps = {
  isOpen: false,
  hasDarkBg: false,
}

export default HeaderBurgerButton
