import * as React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

import { Row, Subheading, Intro, InputWrapper, Actions } from './style'
import { getUserArtists } from '../../../stores/Artist/ArtistReducer'
import { Input, Errormessage } from '../../../ui/Form'
import Button from '../../../ui/Button'
import { H1 } from '../../../ui/Headings'

const ENTER = 13

const JoinAristName = React.memo(({ artistForm, setData, saveData, currentUser }) => {
  const [errorMessage, setErrorMessage] = React.useState([])
  const [artistExists, setArtistExists] = React.useState(false)
  const artists = useSelector(getUserArtists)

  const history = useHistory()

  // What name does your act or service go by?
  const handleNext = e => {
    e.preventDefault()
    const idx = (artists || []).findIndex(artist => {
      return artist.name === artistForm.name
    })

    if (!artistForm.name || artistForm.name.length <= 2) {
      setErrorMessage(['Du må skrive inn et band/artistnavn'])
    } else if (idx !== -1) {
      setErrorMessage(['Du har allerede registrert en artist med dette navnet'])
      setArtistExists(true)
    } else {
      saveData()
      history.push('/join/artist/type/')
    }
  }

  const handlePrevious = e => {
    e.preventDefault()
    saveData()
    history.push('/join/artist/legal/')
  }

  const handleChangeName = e => {
    setArtistExists(false)
    setData({ name: e.currentTarget.value })
  }

  const handleKeyDown = e => {
    switch (e.keyCode) {
      case ENTER: {
        handleNext(e)
        break
      }
      default:
        break
    }
  }

  return (
    <>
      <Row>
        <H1>Hyggelig å møte deg {currentUser.firstName}</H1>
      </Row>
      <Row>
        <Subheading>Alle artister hos Gigplanet trenger ett navn.</Subheading>
      </Row>
      <Row>
        <Intro>
          Det er viktig at du skriver inn riktig navn her. Du vil nemlig får en egen lenke til
          aristsiden basert på navnet{' '}
          <span role="img" aria-label="Smiling face">
            😊
          </span>
        </Intro>
      </Row>
      <Row>
        <InputWrapper>
          <Input
            autoFocus
            id="artist-name"
            label="Hva heter artisten?"
            value={artistForm.name}
            onChange={handleChangeName}
            onKeyDown={handleKeyDown}
            placeholder="Skriv inn navnet på artisten eller bandet"
            maxLength={70}
            expanded
          />
        </InputWrapper>
      </Row>
      <Row>
        <Errormessage messages={errorMessage} />
      </Row>
      {artistExists ? (
        <Row>
          <Actions>
            <Button theme="secondary" to="/controlpanel/artist-profiles/dashboard">
              Gå til dine artistprofiler
            </Button>
          </Actions>
        </Row>
      ) : null}
      <Row>
        <Actions>
          <Button type="button" theme="primary" onClick={handleNext}>
            Neste
          </Button>
          <Button type="button" theme="secondary" onClick={handlePrevious}>
            Forrige
          </Button>
        </Actions>
      </Row>
    </>
  )
})

JoinAristName.propTypes = {
  currentUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  artistForm: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  setData: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired,
}

export default JoinAristName
