import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { Row, Actions, Subheading, Intro, InputWrapper } from './style'
import { Select, Errormessage } from '../../../ui/Form'
import { H1 } from '../../../ui/Headings'
import Button from '../../../ui/Button'

class JoinArtistLocation extends React.Component {
  constructor(props) {
    super(props)
    const { artistForm } = props
    this.state = {
      countyId:
        artistForm.homeLocation && artistForm.homeLocation.length === 2
          ? artistForm.homeLocation[0].id
          : '',
      municipalityId:
        artistForm.homeLocation && artistForm.homeLocation.length === 2
          ? artistForm.homeLocation[1].id
          : '',
      errorMessage: [],
    }
  }

  // What name does your act or service go by?
  handleNext = e => {
    const { countyId, municipalityId } = this.state
    const { history } = this.props

    e.preventDefault()
    if (countyId && municipalityId) {
      history.push('/join/artist/contact/')
    } else {
      this.setState({
        errorMessage: ['Du må velge fylke og kommune'],
      })
    }
  }

  handlePrevious = e => {
    const { history } = this.props
    e.preventDefault()
    history.push('/join/artist/type/')
  }

  handleCountyChange = e => {
    const { onCountyChange } = this.props
    onCountyChange(e.currentTarget.value)
    this.setState({ countyId: e.currentTarget.value })
  }

  handleMunicipalityChange = e => {
    const { onSetHomeLocation, onUnsetHomeLocation } = this.props
    const municipalityId = e.currentTarget.value
    if (municipalityId) {
      onSetHomeLocation(e.currentTarget.value)
    } else {
      onUnsetHomeLocation()
    }
    this.setState({ municipalityId })
  }

  render() {
    const { counties, currentUser, artistTypes, artistForm } = this.props
    const { countyId, municipalityId, errorMessage } = this.state
    let municipalitiesOptions = []
    const countyOptions = counties.map(county => {
      if (countyId === county.id && county.children && county.children.length > 0) {
        municipalitiesOptions = county.children.map(municipality => ({
          name: municipality.name,
          value: municipality.id,
        }))
      }
      return {
        name: county.name,
        value: county.id,
      }
    })

    let municipalities = null
    if (municipalitiesOptions.length > 0) {
      municipalities = (
        <Row>
          <InputWrapper>
            <Select
              label="Og i hvilke kommune?"
              actionLabel="Velg kommune"
              id="home-location-id"
              value={municipalityId}
              options={municipalitiesOptions}
              onChange={this.handleMunicipalityChange}
              autoFocus
              expanded
            />
          </InputWrapper>
        </Row>
      )
    } else {
      municipalities = (
        <Row>
          <InputWrapper>
            <Select
              label="Og i hvilke kommune?"
              actionLabel="Du må velge fylke først"
              disabled
              id="home-location-id"
              value={municipalityId}
              options={[]}
              onChange={this.handleMunicipalityChange}
              expanded
            />
          </InputWrapper>
        </Row>
      )
    }

    return (
      <>
        <Row>
          <H1>
            Okey {currentUser.firstName}, så du er {artistTypes[artistForm.artistType].name}
          </H1>
        </Row>
        <Row>
          <Subheading>Nå trenger vi å vite hvor du holder til</Subheading>
        </Row>
        <Row>
          <Intro />
        </Row>
        <Row>
          <InputWrapper>
            <Select
              label="I hvilke fylke holder du til?"
              actionLabel="Velg fylke"
              id="county-id"
              value={countyId}
              options={countyOptions}
              onChange={this.handleCountyChange}
              autoFocus={municipalitiesOptions.length === 0}
              expanded
            />
          </InputWrapper>
        </Row>
        {municipalities}
        <Row>
          <Errormessage messages={errorMessage} />
        </Row>
        <Row>
          <Actions>
            <Button type="button" theme="primary" onClick={this.handleNext}>
              Neste
            </Button>
            <Button type="button" theme="secondary" onClick={this.handlePrevious}>
              Forrige
            </Button>
          </Actions>
        </Row>
      </>
    )
  }
}

JoinArtistLocation.propTypes = {
  currentUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  artistTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  artistForm: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  counties: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onSetHomeLocation: PropTypes.func.isRequired,
  onUnsetHomeLocation: PropTypes.func.isRequired,
  onCountyChange: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(JoinArtistLocation)
