import * as React from 'react'
import PropTypes from 'prop-types'

import {
  EVENTPLANNER_DECLINE_REASON_STATUS,
  getEventplannerDeclineReasonName,
} from '../../../models/GigRequest'

import { Select, Textarea, Errormessage } from '../../../ui/Form'
import Button from '../../../ui/Button'
import FormRow from '../../../components/FormRow'
import FormAction from '../../../ui/form-action'
import { H1 } from '../../../ui/Headings'

const declineReasonOptions = Object.keys(EVENTPLANNER_DECLINE_REASON_STATUS).map(reason => ({
  name: getEventplannerDeclineReasonName(reason),
  value: reason,
}))

const GigRequestEventplannerDeclineQuote = ({
  declineReason,
  onDeclineReasonChange,
  errorMessages,
  declineReasonText,
  onDeclineReasonTextChange,
  onSubmit,
  isSending,
  onCancel,
}) => (
  <>
    <FormRow>
      <H1>Avslå tilbudet</H1>
    </FormRow>
    <FormRow>
      <Select
        id="decline-reason"
        name="decline-reason"
        label="Grunn for å avslå tilbudet"
        actionLabel="Velg grunn for å avslå tilbudet"
        value={declineReason}
        onChange={onDeclineReasonChange}
        errorMessage={errorMessages['decline-reason'] || ''}
        expanded
        options={declineReasonOptions}
      />
    </FormRow>
    <FormRow>
      <Textarea
        id="decline-text"
        name="decline-text"
        label="Melding"
        placeholder={
          declineReason === EVENTPLANNER_DECLINE_REASON_STATUS.OTHER
            ? 'Skriv melding'
            : 'Skriv melding (valgfritt)'
        }
        required={declineReason === EVENTPLANNER_DECLINE_REASON_STATUS.OTHER}
        value={declineReasonText}
        onChange={onDeclineReasonTextChange}
        errorMessage={errorMessages['decline-text'] || ''}
        expanded
      />
    </FormRow>
    {errorMessages && Object.keys(errorMessages).length > 0 ? (
      <FormRow>
        <Errormessage
          messages={Object.keys(errorMessages).map(message => errorMessages[message])}
        />
      </FormRow>
    ) : null}
    <FormAction>
      <Button type="button" theme="primary" onClick={onSubmit} loading={isSending}>
        {isSending ? 'Avslår tilbudet' : 'Avslå tilbudet'}
      </Button>
      <Button type="button" theme="secondary" onClick={onCancel} disabled={isSending}>
        Avbryt
      </Button>
    </FormAction>
  </>
)

GigRequestEventplannerDeclineQuote.propTypes = {
  declineReason: PropTypes.string.isRequired,
  onDeclineReasonChange: PropTypes.func.isRequired,
  declineReasonText: PropTypes.string,
  onDeclineReasonTextChange: PropTypes.func.isRequired,
  errorMessages: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
}

GigRequestEventplannerDeclineQuote.defaultProps = {
  declineReasonText: '',
  errorMessages: {},
}

export default GigRequestEventplannerDeclineQuote
