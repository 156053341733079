import * as React from 'react'
import PropTypes from 'prop-types'

import WaveImage from './wave-small.png'
import { Container, Wave } from './style'

const HeroWave = () => {
  return (
    <Container>
      <Wave src={WaveImage} />
    </Container>
  )
}

HeroWave.propTypes = {}

HeroWave.defaultProps = {}

export default HeroWave
