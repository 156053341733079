import * as React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Container, Details, LoaderWrapper } from './style'
import { getUserArtists, isUserArtistsLoading } from '../../../../stores/Artist/ArtistReducer'
import { getRequestCount } from '../../../../stores/GigRequestArtist/GigRequestArtistReducer'

import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'
import { H1 } from '../../../../ui/landing-page-headings'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import Loader from '../../../../ui/Loader'
import Button from '../../../../ui/Button'

const ControlpanelArtistBlock = React.memo(({ heading, theme, anchorId }) => {
  const artists = useSelector(getUserArtists)
  const artistsLoading = useSelector(isUserArtistsLoading)
  const requestCount = useSelector(getRequestCount)
  const headingValue = formatSimpleMarkdown(heading)

  return (
    <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
      <H1 dangerouslySetInnerHTML={{ __html: `${headingValue}` }} />
      {artistsLoading ? (
        <LoaderWrapper>
          <Loader text="Laster detaljer" />
        </LoaderWrapper>
      ) : (
        <>
          <Container>
            {requestCount > 0 ? (
              <>
                <Details>Antall forespørsler: {requestCount}</Details>
                <Button theme="secondary" to="/controlpanel/inbox">
                  Gå til dine forespørsler
                </Button>
              </>
            ) : null}
            <Details>Antall artist-profiler: {artists.length}</Details>
            <Button theme="secondary" to="/controlpanel/artist-profiles/dashboard">
              Gå til dine artistprofiler
            </Button>
          </Container>
        </>
      )}
    </LandingPageBlockWrapper>
  )
})

ControlpanelArtistBlock.propTypes = {
  heading: PropTypes.string,
  anchorId: PropTypes.string,
  theme: PropTypes.string.isRequired,
}

ControlpanelArtistBlock.defaultProps = {
  anchorId: '',
  heading: '',
}

export default ControlpanelArtistBlock
