/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react'
import PropTypes from 'prop-types'
import { Direction, Slider, FormattedTime } from 'react-player-controls'
// import { Play } from 'react-player-controls/dist/components/icons'
import ReactHowler from 'react-howler'
import PlayBar from './components/playbar'
import { trackEvent } from '../../../../libs/analytics'
import Heading from '../../../ProfileDetailsHeading/ProfileDetailsHeading'
import Button from '../../../../ui/Button'
import { PlayIcon, PauseIcon } from '../../../../icons'

import {
  ProfileDetailsAudioSection,
  ProfileDetailsAudioRow,
  ProfileDetailsAudioItems,
  ProfileDetailsAudioItem,
  ProfileDetailsAudioItemContainer,
  ProfileDetailsAudioItemWrapper,
  ProfileDetailsAudioItemButtons,
  ProfileDetailsAudioItemButton,
  ProfileDetailsAudioItemContent,
  ProfileDetailsAudioItemContentName,
  ProfileDetailsAudioItemBar,
  ProfileDetailsAudioItemBarTime,
  ProfileDetailsAudioItemBarTimeItem,
  ProfileDetailsAudioReadmore,
} from './styles'

class ProfileDetailsAudio extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      currentSong: null,
      playing: false,
      index: null,
      showMore: false,
      duration: 0.1,
      seek: 0.0,
      seekId: null,
      loaded: false,
    }
  }

  componentWillUnmount = () => {
    const { seekId } = this.state
    clearInterval(seekId)
    this.setState({ seekId: null })
  }

  onPlaybyIndex = index => {
    const { seekId } = this.state
    const { audio } = this.props
    if (seekId) {
      clearInterval(seekId)
    }
    const currentSong = audio[index]
    this.setState({
      index,
      currentSong,
      playing: true,
      seek: 0.0,
      seekId: null,
      loaded: false,
    })
  }

  onPauseBtnClick = () => {
    const { playing } = this.state
    this.setState({
      playing: !playing,
    })
  }

  handleOnEnd = () => {
    const { index, playing } = this.state
    const { audio } = this.props
    if (index < audio.length) {
      this.onPlaybyIndex(index + 1)
    } else {
      this.setState({
        playing: !playing,
      })
    }
  }

  handleAudioChange = i => {
    const { audio } = this.props
    let index = i
    if (index < 0) {
      index = audio.length - 1
    }
    if (index === audio.length) {
      index = 0
    }
    this.onPlaybyIndex(index)
  }

  handleOnLoad = () => {
    this.setState(
      {
        loaded: true,
        playing: true,
        seek: 0.1,
        duration: this.player.duration(),
      },
      () => {
        this.renderSeekPos()
      },
    )
  }

  toggleShowMore = () => {
    const { readMore } = this.state
    this.setState({
      showMore: !readMore,
    })
  }

  seek = time => {
    const { duration } = this.state
    const jumpTo = parseInt(time * duration, 10)
    if (jumpTo) {
      this.setState(
        {
          seek: jumpTo,
        },
        () => {
          this.player.seek(jumpTo)
        },
      )
    }
  }

  renderSeekPos = () => {
    const { loaded, playing } = this.state
    const { player } = this
    const intId = setInterval(() => {
      const seek = player.seek()
      if (loaded && playing) {
        this.setState({
          seek,
          seekId: intId,
        })
      }
    }, 1000)
  }

  render() {
    const { audio, containerId } = this.props
    const { showMore, currentSong, loaded, playing, duration, seek } = this.state
    if (!audio) {
      return null
    }
    const showMoreButton = audio.length > 4 && !showMore
    const AudioItems = audio.map((song, i) => (
      <ProfileDetailsAudioItem
        key={`song-${song.id}`}
        itemScope
        itemType="http://schema.org/AudioObject"
      >
        <ProfileDetailsAudioItemContainer
          role="button"
          onClick={() => {
            if (currentSong && currentSong.id === song.id) {
              // its the current song.. do nothing
              return false
            }
            trackEvent('artist-profile', 'artist-profile-play-audio', song.name)
            return this.onPlaybyIndex(i)
          }}
        >
          <ProfileDetailsAudioItemWrapper>
            <ProfileDetailsAudioItemButtons>
              <ProfileDetailsAudioItemButton
                aria-label="Spill av"
                type="button"
                onClick={() => {
                  if (currentSong && currentSong.id === song.id) {
                    trackEvent('artist-profile', 'artist-profile-pause-audio', song.name)
                    return this.onPauseBtnClick()
                  }
                  if (!playing) {
                    trackEvent('artist-profile', 'artist-profile-play-audio', song.name)
                    return this.onPlaybyIndex(i)
                  }
                  return false
                }}
              >
                {currentSong && currentSong.id === song.id && playing ? (
                  <PauseIcon />
                ) : (
                  <PlayIcon />
                )}
              </ProfileDetailsAudioItemButton>
            </ProfileDetailsAudioItemButtons>
            <ProfileDetailsAudioItemContent>
              <ProfileDetailsAudioItemContentName itemProp="name">
                {song.name}
              </ProfileDetailsAudioItemContentName>
              <ProfileDetailsAudioItemBar isExpanded={currentSong && currentSong.id === song.id}>
                {currentSong && currentSong.id === song.id ? (
                  <div>
                    <Slider
                      direction={Direction.HORIZONTAL}
                      style={{
                        cursor: 'pointer',
                        position: 'relative',
                        height: '10px',
                        borderRadius: '4px',
                        overflow: 'hidden',
                      }}
                      isEnabled={currentSong && currentSong.id === song.id}
                      onChange={value => this.seek(value)}
                    >
                      {currentSong && currentSong.id === song.id && !loaded ? (
                        <PlayBar style={{}} isLoading />
                      ) : (
                        <div>
                          <PlayBar style={{ background: '#eee', width: '100%' }} />
                          <PlayBar
                            style={{
                              background: '#8805BB',
                              width: `${(100 * parseFloat(!isNaN(seek) ? seek.toFixed(2) : 0.0)) /
                                parseFloat(!isNaN(duration) ? duration.toFixed(2) : 0.0)}%`,
                            }}
                          />
                        </div>
                      )}
                    </Slider>
                    <ProfileDetailsAudioItemBarTime>
                      <ProfileDetailsAudioItemBarTimeItem>
                        <FormattedTime numSeconds={seek} />
                      </ProfileDetailsAudioItemBarTimeItem>
                      <ProfileDetailsAudioItemBarTimeItem>
                        <FormattedTime numSeconds={seek - duration} />
                      </ProfileDetailsAudioItemBarTimeItem>
                    </ProfileDetailsAudioItemBarTime>
                  </div>
                ) : null}
              </ProfileDetailsAudioItemBar>
            </ProfileDetailsAudioItemContent>
          </ProfileDetailsAudioItemWrapper>
        </ProfileDetailsAudioItemContainer>
        <meta itemProp="encodingFormat" content="mp3" />
        <meta itemProp="contentUrl" content={song.url} />
      </ProfileDetailsAudioItem>
    ))
    return (
      <ProfileDetailsAudioSection isMore={showMoreButton} id={containerId}>
        {currentSong ? (
          <div>
            <ReactHowler
              src={currentSong.url}
              onLoad={this.handleOnLoad}
              playing={playing}
              loop={false}
              html5
              onEnd={this.handleOnEnd}
              // preload={false}
              ref={r => {
                this.player = r
              }}
            />
          </div>
        ) : null}
        <ProfileDetailsAudioRow>
          <Heading>Lyd</Heading>
        </ProfileDetailsAudioRow>
        <ProfileDetailsAudioRow>
          <ProfileDetailsAudioItems isMore={showMoreButton}>{AudioItems}</ProfileDetailsAudioItems>
        </ProfileDetailsAudioRow>
        {showMoreButton ? (
          <ProfileDetailsAudioReadmore>
            <Button type="button" theme="outline" onClick={this.toggleShowMore}>
              Vis alle
            </Button>
          </ProfileDetailsAudioReadmore>
        ) : null}
      </ProfileDetailsAudioSection>
    )
  }
}

ProfileDetailsAudio.propTypes = {
  containerId: PropTypes.string.isRequired,
  audio: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default ProfileDetailsAudio
