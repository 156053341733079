import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const FacebookIcon = (props) => (
  <SVGWrapper width="32" height="32" viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.234 0H1.766C0.791 0 0 0.791 0 1.766V30.234C0 31.209 0.791 32 1.766 32H17.092V19.608H12.922V14.778H17.092V11.217C17.092 7.083 19.617 4.833 23.304 4.833C25.07 4.833 26.588 4.965 27.03 5.023V9.343L24.473 9.344C22.468 9.344 22.08 10.297 22.08 11.695V14.778H26.862L26.239 19.608H22.08V32H30.234C31.209 32 32 31.209 32 30.234V1.766C32 0.791 31.209 0 30.234 0Z"
      fill="currentColor"
    />
  </SVGWrapper>
)

export default FacebookIcon
