import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const StyledEventplannerDashboard = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

export const Wrapper = styled.div`
  max-width: ${gigplanetTheme.measure.maxWidth};
  width: 100%;
  margin: 0;
  padding: 0 30px;

  ${mediaQueries.mobile`
    margin: ${gigplanetTheme.spacing.small} 0 0;
    padding: 0 ${gigplanetTheme.spacing.xsmall};
  `};
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  margin: 50px 0;

  ${mediaQueries.mobile`
    margin: ${gigplanetTheme.spacing.small} 0;
    flex-direction: column;
    justify-content: flex-start;
  `};

  ${mediaQueries.tabletPortrait`
    margin: 30px 0;
    flex-direction: column;
    justify-content: flex-start;
  `};
`

export const UserProfileWrapper = styled.div`
  order: 2;
  width: 33.33333%;
  max-width: 320px;

  ${mediaQueries.tabletPortraitDown`
    margin-top: ${gigplanetTheme.spacing.xsmall};
    width: 100%;
    max-width: 100%;
    order: 2;
  `};
`

export const RequestsWrapper = styled.div`
  order: 1;
  flex: 1 1 auto;
  margin-right: 50px;

  ${mediaQueries.mobile`
    width: 100%;
    order: 1;
    margin-right: 0;
  `};

  ${mediaQueries.tabletPortrait`
    width: 100%;
    order: 1;
    margin-right: 0;
  `};
`
