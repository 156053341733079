import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  StyledSidebar,
  LoaderWrapper,
  Type,
  Members,
  Separator,
  GetIn,
  Note,
  IncludedList,
  IncludedItem,
} from './style'
import { includedOptionInGigRequestForm } from '../../../models/GigRequest'
import Loader from '../../../ui/Loader'
import { H3, H4 } from '../../../ui/Headings'
import { TimeInfo, Info } from '../../GigRequestEventplannerDetailsSidebar/components/style'
import { printCurrency } from '../../../helpers/Currency'
import getPlaytimeText from '../../../helpers/get-playtime-text'

const LONG_DATE_FORMAT = 'dddd, D. MMMM YYYY'
const TIME_FORMAT = 'HH:mm'

const GigRequestEventplannerBookingSidebar = ({
  isLoading,
  hasLoaded,
  gigRequest,
  eventTypes,
  artist,
  quote,
}) => {
  if (isLoading || !hasLoaded || !{}.hasOwnProperty.call(gigRequest, 'id')) {
    return (
      <StyledSidebar>
        <LoaderWrapper>
          <Loader text="Laster arrangement" />
        </LoaderWrapper>
      </StyledSidebar>
    )
  }

  let eventType = '' // eslint-disable-line
  let subEventType = '' // eslint-disable-line
  if (gigRequest.eventType && eventTypes[gigRequest.eventType.eventType]) {
    const eventTypeData = eventTypes[gigRequest.eventType.eventType]
    eventType = eventTypeData.name

    if (eventTypeData.sub[gigRequest.eventType.sub]) {
      subEventType = eventTypeData.sub[gigRequest.eventType.sub].name
    }
  }

  const soundSystemIncluded =
    gigRequest.included.indexOf(includedOptionInGigRequestForm.SOUNDSYSTEM) !== -1
  const sceneLightsIncluded =
    gigRequest.included.indexOf(includedOptionInGigRequestForm.SCENELIGHTS) !== -1

  const startTime = moment(gigRequest.startTime)
  const endTime = moment(gigRequest.endTime)
  return (
    <StyledSidebar>
      <Type>Valgt artist</Type>
      <H3>{artist.name}</H3>
      <Members>stiller med {quote.members} medlemmer</Members>

      <Separator />
      <TimeInfo>{startTime.format(LONG_DATE_FORMAT)}</TimeInfo>
      <TimeInfo>
        Fra kl. {startTime.format(TIME_FORMAT)} - {endTime.format(TIME_FORMAT)}
      </TimeInfo>
      <TimeInfo>
        Varighet: <strong>{getPlaytimeText(gigRequest.startTime, gigRequest.endTime)}</strong>
      </TimeInfo>

      <TimeInfo>
        {quote.pauseCount > 0 ? (
          <>
            Pauser:{' '}
            <strong>
              {quote.pauseCount} á {quote.pauseDuration}{' '}
              {quote.pauseDuration === 1 ? 'minutt' : 'minutter'}
            </strong>
          </>
        ) : (
          <>Ingen pauser</>
        )}
      </TimeInfo>
      <Separator />

      <Info>{gigRequest.city}</Info>
      <Info>{gigRequest.venue}</Info>

      <Separator />
      <Info>
        Pris: <strong>{printCurrency(quote.price)}</strong>
      </Info>
      <Separator />
      <Info>Antall gjester: ca {gigRequest.audienceCount}</Info>
      <Separator />
      <IncludedList>
        <IncludedItem>
          Lydanlegg inludert: <strong>{soundSystemIncluded ? 'Ja' : 'Nei'}</strong>
        </IncludedItem>
        <IncludedItem>
          Lys inkludert: <strong>{sceneLightsIncluded ? 'Ja' : 'Nei'}</strong>
        </IncludedItem>
        <IncludedItem>
          Pausemusikk inkludert: <strong>Ja</strong>
        </IncludedItem>
      </IncludedList>
      <Separator />
      <H4>Tilleggsopplysninger</H4>
      <Note>{quote.terms || 'Ingen tilleggsopplysninger'}</Note>
      <Separator />
      <GetIn>
        Tidspunkter for når musiker og utstyr (Get-in) ankommer, samt når lydprøve senest må være
        ferdig, avtales mellom partene.
      </GetIn>
    </StyledSidebar>
  )
}

GigRequestEventplannerBookingSidebar.propTypes = {
  quote: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  gigRequest: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  artist: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}
GigRequestEventplannerBookingSidebar.defaultProps = {}

export default GigRequestEventplannerBookingSidebar
