/* globals Image */

const ImagePreloader = (src, srcSet = '') => {
  const img = new Image()
  img.src = src
  if (srcSet && srcSet !== '') {
    img.srcset = srcSet
  }
}

export default ImagePreloader
