import styled, { css } from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const SearchWrapper = styled.div`
  ${mediaQueries.mobile`
    margin: 0;
  `};
`
export const Content = styled.div`
  align-items: flex-start;
  background: #fff; /*$results-background-color */
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px 0;
  width: 100%;
`
export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: stretch;
  max-width: ${gigplanetTheme.measure.maxWidth};
  width: 100%;
  ${mediaQueries.tabletPortraitDown`
    flex-direction: column;
  `};
`

export const Filter = styled.div`
  background-color: #f5f5f5; /* $filter-background-color; */
  flex: 0 0 auto;
  padding: 15px;
  width: 300px;
  ${mediaQueries.tabletPortraitDown`
    width: 100%;
    padding: 10px;
  `};
`

export const SearchResults = styled.div`
  background: #fff;
  padding: 0 15px;
  width: 100%;
  position: relative;
  ${mediaQueries.tabletPortraitDown`
    padding: 0;
  `};
`

export const Actions = styled.div`
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 840px;
`
export const Results = styled.div`
  background: #fff;
  padding: 0 15px 0 0;
  width: 100%;
  position: relative;
  ${mediaQueries.tabletPortraitDown`
    padding: 0;
  `};
  ${({ isLoading }) =>
    isLoading &&
    css`
      position: relative;
      &:after {
        content: '';
        position: absolute;
        background: rgba(255, 255, 255, 0.6);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    `};
`
export const Empty = styled.div`
  font-size: 16px;
  margin: 50px auto;
  text-align: center;
`
export const ResultLoader = styled.div`
  margin: 50px auto;
`
