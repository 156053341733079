import * as React from 'react'

import styled, { css } from 'styled-components'

import gigplanetTheme from '../../../styles/gigplanetTheme'
import mediaQueries from '../../../styles/mediaQueries'
import extractUrlPath from '../../../helpers/extract-url-path'
import { ArrowBackIcon, ArrowForwardIcon } from '../../../icons'

const StyledNavBlock = styled.div`
  position: fixed;
  width: 100%;
  left: 100%;
  top: 50px;
  bottom: 0;
  background: ${gigplanetTheme.element.siteNavigation.background};
  transition: all 0.2s ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    css`
      left: 0;
    `}
`
const NavHeadingClickable = styled.a`
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  color: ${gigplanetTheme.color.primary};
  cursor: pointer;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const NavHeading = styled.h2`
  font-size: 22px;
  line-height: 22px;
  flex-basis: auto;
  font-weight: 400;
`
const NavLink = styled.a`
  display: block;
  font-size: 17px;
  line-height: 26px;
  text-decoration: none;
  padding: 0.5rem 1rem;
  color: ${gigplanetTheme.element.footer.link};

  &:hover {
    text-decoration: underline;
    color: ${gigplanetTheme.element.footer.linkHover};
  }

  &:last-child {
    margin-bottom: 0;
  }
`
const NavBlockHeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid ${gigplanetTheme.color.lightMediumGray};
`
const NavBlockBackButton = styled.button`
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0 1rem 0 0;
  margin-right: 1rem;
  border-right: 1px solid ${gigplanetTheme.color.lightMediumGray};
`
const NavBlockBackButtonText = styled.span`
  display: block;
  margin-left: 5px;
`
const ArrowForward = styled(ArrowForwardIcon)``

const NavBlock = ({ item, isMainNavOpen }) => {
  const [visibility, setVisibility] = React.useState(false)

  const handleHeadingClick = () => {
    setVisibility(true)
  }
  const handleCloseClick = () => {
    setVisibility(false)
  }

  React.useEffect(() => {
    if (!isMainNavOpen) {
      handleCloseClick()
    }
  }, [isMainNavOpen])

  return (
    <div>
      <NavHeadingClickable role="button" onClick={handleHeadingClick} key={item._key}>
        {item.heading}
        <ArrowForward />
      </NavHeadingClickable>
      <StyledNavBlock isOpen={visibility}>
        <NavBlockHeadingWrapper>
          <NavBlockBackButton onClick={handleCloseClick}>
            <ArrowBackIcon />
            <NavBlockBackButtonText>Tilbake</NavBlockBackButtonText>
          </NavBlockBackButton>
          <NavHeading key={item._key}>{item.heading}</NavHeading>
        </NavBlockHeadingWrapper>
        {item.children.map((child) => {
          return (
            <NavLink key={child._key} href={extractUrlPath(child.url)}>
              {child.text}
            </NavLink>
          )
        })}
      </StyledNavBlock>
    </div>
  )
}

export default React.memo(NavBlock)
