import styled from 'styled-components'

import mediaQueries from '../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../styles/gigplanetTheme'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Content = styled.p`
  line-height: 20px;
  text-align: center;
  margin: ${gigplanetTheme.spacing.small} 0;

  ${mediaQueries.mobile`
    margin: ${gigplanetTheme.spacing.xsmall} 0;
  `};
`
