import * as React from 'react'

import {
  PRIVACY_URL,
  TERMS_URL,
  ARTIST_TERMS_URL,
  EVENT_PLANNER_TERMS_URL,
} from '../../models/Legal'

import { Info, StyledLink } from './style'

const UserAuthTermsInfo = () => (
  <Info>
    Ved å registrere deg bekrefter du at du sier deg enig i Gigplanet sine{' '}
    <StyledLink to={PRIVACY_URL}>retningslinjer for personvern</StyledLink>,{' '}
    <StyledLink to={TERMS_URL}>bruksvilkår</StyledLink>,{' '}
    <StyledLink to={ARTIST_TERMS_URL}>vilkår for artister</StyledLink> og{' '}
    <StyledLink to={EVENT_PLANNER_TERMS_URL}>vilkår for arrangører</StyledLink>.
  </Info>
)

export default UserAuthTermsInfo
