import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const ArtistProfilesIcon = (props) => (
  <SVGWrapper width="22" height="24" viewBox="0 0 22 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9576 17.716C12.8738 16.6174 14.0793 15.7302 15.5713 15.7302C17.0628 15.7302 18.2684 16.6175 19.1846 17.7159C20.1092 18.8245 20.863 20.289 21.4583 21.7831C21.581 22.0911 21.5431 22.44 21.357 22.7144C21.1709 22.9889 20.8609 23.1532 20.5293 23.1532H10.6133C10.2817 23.1532 9.97169 22.9889 9.7856 22.7144C9.59952 22.44 9.56158 22.0911 9.6843 21.7831C10.2795 20.2891 11.033 18.8246 11.9576 17.716ZM12.1473 21.1532H18.9952C18.5838 20.3158 18.1322 19.5766 17.6487 18.997C16.9067 18.1074 16.2097 17.7302 15.5713 17.7302C14.9323 17.7302 14.2353 18.1075 13.4935 18.997C13.0101 19.5765 12.5586 20.3158 12.1473 21.1532Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5712 12.1531C14.5835 12.1531 13.7822 12.9544 13.7822 13.9421C13.7822 14.9293 14.583 15.7301 15.5712 15.7301C16.5594 15.7301 17.3602 14.9293 17.3602 13.9421C17.3602 12.9544 16.5589 12.1531 15.5712 12.1531ZM11.7822 13.9421C11.7822 11.8498 13.4789 10.1531 15.5712 10.1531C17.6635 10.1531 19.3602 11.8498 19.3602 13.9421C19.3602 16.0348 17.663 17.7301 15.5712 17.7301C13.4794 17.7301 11.7822 16.0348 11.7822 13.9421Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 9.6521C4 10.4811 3.328 11.1521 2.5 11.1521C1.672 11.1521 1 10.4811 1 9.6521C1 8.8241 1.672 8.1521 2.5 8.1521C3.328 8.1521 4 8.8241 4 9.6521Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 10C2.22428 10 2 10.2243 2 10.5C2 10.7763 2.22385 11 2.5 11C2.77615 11 3 10.7763 3 10.5C3 10.2243 2.77572 10 2.5 10ZM0 10.5C0 9.11972 1.11972 8 2.5 8C3.88028 8 5 9.11972 5 10.5C5 11.8817 3.87985 13 2.5 13C1.12015 13 0 11.8817 0 10.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8.6521C11 9.4811 10.328 10.1521 9.5 10.1521C8.672 10.1521 8 9.4811 8 8.6521C8 7.8241 8.672 7.1521 9.5 7.1521C10.328 7.1521 11 7.8241 11 8.6521Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 9C9.22428 9 9 9.22428 9 9.5C9 9.77628 9.22385 10 9.5 10C9.77615 10 10 9.77628 10 9.5C10 9.22428 9.77572 9 9.5 9ZM7 9.5C7 8.11972 8.11972 7 9.5 7C10.8803 7 12 8.11972 12 9.5C12 10.8817 10.8799 12 9.5 12C8.12015 12 7 10.8817 7 9.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0V9.65201H10V2.30602L5 3.02031V10.653H3V1.28571L12 0Z"
      fill="currentColor"
    />
  </SVGWrapper>
)

export default ArtistProfilesIcon
