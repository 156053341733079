import styled from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'

export const Wrapper = styled.div`
  padding: 30px;

  ${mediaQueries.mobile`
    padding: 15px;
  `}
`
