import styled from 'styled-components'

import mediaQueries from '../../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../../styles/gigplanetTheme'

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 10px 10px 0;
`

export const Included = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  > * {
    margin-right: 10px;
  }
`
