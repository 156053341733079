import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'
import gigplanetTheme from '../../styles/gigplanetTheme'

import Button from '../../ui/Button'
import Modal from '../../ui/Modal'

const Content = styled.div``

const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;

  ${mediaQueries.mobile`
    margin-top: 10px;
  `};

  & > * {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

class SystemDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialog: true,
    }
  }

  handleHide = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    const { onClose } = this.props
    this.setState({
      showDialog: false,
    })
    if (onClose) {
      onClose()
    }
  }

  render() {
    const { showDialog } = this.state
    const { id, heading, children, hideClose, showOk } = this.props
    if (showDialog) {
      return (
        <Modal id={id} isOpen={showDialog} onRequestClose={this.handleHide} label={heading}>
          <Content>{children}</Content>
          {showOk || !hideClose ? (
            <Actions>
              {showOk ? (
                <Button type="button" theme="primary" onClick={this.handleHide}>
                  OK, jeg forstår
                </Button>
              ) : null}
              {!hideClose ? (
                <Button type="button" theme="secondary" onClick={this.handleHide}>
                  Lukk
                </Button>
              ) : null}
            </Actions>
          ) : null}
        </Modal>
      )
    }
    return null
  }
}

SystemDialog.propTypes = {
  id: PropTypes.string.isRequired,
  heading: PropTypes.string,
  showOk: PropTypes.bool,
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func,
  hideClose: PropTypes.bool,
}

SystemDialog.defaultProps = {
  showOk: false,
  hideClose: false,
  onClose: null,
  heading: '',
}

export default SystemDialog
