import * as React from 'react'
import PropTypes from 'prop-types'

import { Label } from '../../ui/Form'
import { ContainerButton, Content, IconWrapper, Value } from './style'
import { CloseIcon } from '../../icons'

const SearchFiltersSelectedElement = ({ id, value, onReset, label }) => (
  <>
    <Label htmlFor={id} showValueMarker>
      {label}
    </Label>
    <ContainerButton onClick={onReset}>
      <Content>
        <Value>{value}</Value>
        <IconWrapper>
          <CloseIcon />
        </IconWrapper>
      </Content>
    </ContainerButton>
  </>
)

SearchFiltersSelectedElement.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onReset: PropTypes.func.isRequired,
}
SearchFiltersSelectedElement.defaultProps = {}

export default SearchFiltersSelectedElement
