import * as React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { trackEvent } from '../../../../libs/analytics'

import * as gigRequestEventplannerActions from '../../../../stores/gig-request-eventplanner/actions'
import * as currentUserActions from '../../../../stores/CurrentUser/CurrentUserActions'
import {
  validateRequesterForm,
  validateEventForm,
  validateEventDetailsForm,
  translateServerErrorMessage,
} from '../../../../models/GigRequest'
import validateEmail from '../../../../helpers/validate-email'

import { setRequesterFormDataAction, setGigRequestIdFormDataAction } from '../../../../stores/GigRequest/GigRequestActions'
import {
  getRequesterFormData,
  getEventFormData,
  getArtistFormData,
} from '../../../../stores/GigRequest/GigRequestReducer'
import useCurrentUser from '../../../../hooks/use-current-user'

import GigRequestFormRequester from './components/GigRequestFormRequester'
import PageNotFound from '../../../../components/PageNotFound'
import SystemDialog from '../../../SystemDialog'
import GigRequestFormSubmitting from '../GigRequestFormSubmitting'
import { Button } from '../../../../ui'
import { postJSON } from '../../../../libs/data'

const reportError = message => {
  if (window.Intercom) {
    window.Intercom(
      'showNewMessage',
      `Det har skjedd en serverfeil når jeg skulle sende forespørselen.
      Feilen sier: ${message}`,
    )
  }
}

const UPDATE_FORM_ACTION = 'update'

const formReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM_ACTION:
      return {
        ...state,
        ...action.payload,
      }
    default:
      throw new Error()
  }
}

const GigRequestFormRequesterStep = React.memo(({ onNext, onPrevious }) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const requesterFormData = useSelector(getRequesterFormData)
  const eventFormData = useSelector(getEventFormData)
  const artist = useSelector(getArtistFormData)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [formState, formDispatch] = React.useReducer(formReducer, {
    organization: requesterFormData.organization,
    phone: requesterFormData.phone || currentUser.phone,
    email: requesterFormData.email || currentUser.email,
  })
  const [errorMessages, setErrorMessages] = React.useState(null)
  const [serverErrorMessage, setServerErrorMessage] = React.useState(null)
  const isValidStep = React.useMemo(() => {
    const errors = {
      ...validateEventForm(eventFormData),
      ...validateEventDetailsForm(eventFormData),
    }
    return Boolean(Object.keys(errors).length === 0)
  }, [requesterFormData, eventFormData])

  const updateState = (value, name, id) => {
    formDispatch({
      type: UPDATE_FORM_ACTION,
      payload: {
        [name]: value,
      },
    })

    if (errorMessages && errorMessages['included'] && value) {
      const newErrorMessages = { ...errorMessages }
      delete newErrorMessages[id]
      setErrorMessages(newErrorMessages)
    }
  }

  const handleOrganizationChange = e => {
    updateState(e.currentTarget.value, 'organization', 'organization')
  }

  const handlePhoneChange = e => {
    updateState(e.currentTarget.value, 'phone', 'phone')
  }

  const handleEmailChange = e => {
    formDispatch({
      type: UPDATE_FORM_ACTION,
      payload: {
        email: e.currentTarget.value,
      },
    })

    if (errorMessages && errorMessages['included'] && email && validateEmail(email)) {
      const newErrorMessages = { ...errorMessages }
      delete newErrorMessages.email
      setErrorMessages(newErrorMessages)
    }
  }

  const handlePrevious = () => {
    dispatch(setRequesterFormDataAction(formState))
    onPrevious()
  }

  const handleNext = async () => {
    const newErrorMessages = validateRequesterForm(formState, eventFormData)
    if (Object.keys(newErrorMessages).length > 0) {
      setErrorMessages(newErrorMessages)
      return
    }
    setErrorMessages(null)
    dispatch(setRequesterFormDataAction(formState))

    try {
      setIsSubmitting(true)
      const result = await postJSON('/api/v1/gigRequest/requestQuote', {
        ...eventFormData,
        requester: {
          ...formState,
        },
        artistIds: [artist.id],
      })
      dispatch(setGigRequestIdFormDataAction(result.id))
      dispatch(currentUserActions.fetchMe())
      dispatch(gigRequestEventplannerActions.getMyEventplannerRequestsAction())
      dispatch(gigRequestEventplannerActions.getMyEventplannerRequestsCountAction())
      setIsSubmitting(false)
      setServerErrorMessage(null)
      trackEvent('gig-request', 'gig-request-submitted-gig-request', 'submitted')
      onNext()
    } catch (error) {
      console.log('error', error)
      setServerErrorMessage(
        error.response && error.response.message
          ? error.response.message
          : 'Feil på serveren. Vennligst prøv igjen',
      )

      trackEvent(
        'gig-request',
        'gig-request-server-error-submit-gig-request',
        'server-error',
        JSON.stringify({
          artistData: {
            ...artist,
          },
          requesterData: {
            ...formState,
          },
          eventData: {
            ...eventFormData,
          },
        }),
      )
      setIsSubmitting(false)
    }
  }

  if (!isValidStep) {
    return <PageNotFound />
  }
  return (
    <>
      <GigRequestFormRequester
        currentUser={currentUser}
        artist={artist}
        eventTypeCategory={eventFormData.eventType.eventType}
        organization={formState.organization}
        onOrganizationChange={handleOrganizationChange}
        phone={formState.phone}
        onPhoneChange={handlePhoneChange}
        email={formState.email}
        onEmailChange={handleEmailChange}
        errorMessages={errorMessages}
        isSubmitting={isSubmitting}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
      {isSubmitting ? <GigRequestFormSubmitting /> : null}
      {serverErrorMessage && !isSubmitting ? (
        <SystemDialog id="gigrequest-form-server-error" heading="Feilmelding">
          <div>
            <p>{translateServerErrorMessage(serverErrorMessage)}</p>
            <Button
              type="button"
              theme="primary"
              onClick={() => {
                reportError(serverErrorMessage)
              }}
            >
              Meld fra om feil
            </Button>
          </div>
        </SystemDialog>
      ) : null}
    </>
  )
})

GigRequestFormRequesterStep.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
}

export default GigRequestFormRequesterStep
