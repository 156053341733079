import * as React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router'

import { postJSON } from '../../libs/data'
import {
  getMetaTitle,
  getMetaDescription,
  ARTIST_IMAGE_IMGIX_FORMAT_SHARING,
  getImgixCroppedUrl,
} from '../../models/Artist'

import {
  fetchOneArtistBySlugIfNeeded,
  fetchOneArtistBySlug,
} from '../../stores/Artist/ArtistActions'

import { getArtist, isLoading, hasLoaded } from '../../stores/Artist/ArtistReducer'
import { getCategories, getInstruments, getEventTypes } from '../../stores/Options/OptionsReducer'
import { fetchPredefinedPageIfNeededAction } from '../../stores/cms-content/actions'
import useCurrentUser from '../../hooks/use-current-user'
import Layout from '../../layouts/main-layout'
import PageLoader from '../../components/PageLoader'
import MetaData from '../../components/MetaData'

import PageNotFound from '../../components/PageNotFound'
import ProfileDetails from '../../components/ProfileDetails'
import LandingPageContainer from '../../containers/landing-page'

import GigRequestRoute from './gigrequest'

// fetchOneArtistBySlug

const ProfileDetailsRoute = React.memo(({ location, match: { params, url } }) => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const artist = useSelector(getArtist)
  const artistLoading = useSelector(isLoading)
  const artistLoaded = useSelector(hasLoaded)
  const categories = useSelector(getCategories)
  const eventTypes = useSelector(getEventTypes)
  const instruments = useSelector(getInstruments)

  React.useEffect(() => {
    if (params.artistSlug) {
      dispatch(fetchOneArtistBySlugIfNeeded(null, params.artistSlug))
    }
  }, [params.artistSlug, dispatch])

  React.useEffect(() => {
    if (artist && artist.id && !artist.isOwner && artist.slug === params.artistSlug) {
      postJSON('/api/v1/stat/addArtistPageView', {
        id: artist.id,
      }).then(
        () => {},
        () => {},
      )
    }
  }, [artist, params.artistSlug])

  if (artistLoading || !artistLoaded) {
    return (
      <Layout>
        <MetaData title="Laster artist" description="Vi henter artisten" />
        <PageLoader text="Laster artist" />
      </Layout>
    )
  }

  if (!{}.hasOwnProperty.call(artist, 'id')) {
    return (
      <Layout>
        <MetaData
          title="Kunne ikke finne artisten"
          description="Du har forsøkt å vise en artist som ikke finnes"
        />
        <PageNotFound />
      </Layout>
    )
  }
  const title = getMetaTitle(artist, categories)
  const description = getMetaDescription(artist)
  let imageUrl = ''
  if (artist.images && artist.images.length > 0) {
    const artistImage = artist.images[0]
    imageUrl = getImgixCroppedUrl(
      artistImage.src,
      ARTIST_IMAGE_IMGIX_FORMAT_SHARING,
      artistImage.crop,
    )
  }

  return (
    <Switch>
      <Route path={`${url}/gigrequest`} component={GigRequestRoute} />
      <Route
        render={() => (
          <Layout showSearch>
            <MetaData title={title} description={description} image={imageUrl} />
            <ProfileDetails
              location={location}
              artist={artist}
              isLoading={artistLoading}
              currentUser={currentUser}
              categories={categories}
              eventTypes={eventTypes}
              instruments={instruments}
            />
            <LandingPageContainer removeLayout type="artistDetails" />
          </Layout>
        )}
      />
    </Switch>
  )
})

ProfileDetailsRoute.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      artistSlug: PropTypes.string,
    }),
    url: PropTypes.string.isRequired,
  }),
  location: PropTypes.shape({}).isRequired,
}

ProfileDetailsRoute.defaultProps = {
  match: {
    params: {
      artistSlug: '',
    },
    url: '',
  },
}

ProfileDetailsRoute.dispatchActions = (req, dispatch, params) => [
  dispatch(fetchOneArtistBySlug(req, params.artistSlug, true)),
  dispatch(fetchPredefinedPageIfNeededAction('artistDetails')),
]

export default withRouter(ProfileDetailsRoute)
