import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { Row, Subheading, Intro, InputWrapper, Actions } from './style'
import { Input, Checkbox, Errormessage } from '../../../ui/Form'
import validateEmail from '../../../helpers/validate-email'
import validateOrganizationNumber from '../../../helpers/validate-organization-number'
import { getEditWelcomeUrl } from '../../../models/ProfileAdmin'
import { H1 } from '../../../ui/Headings'
import Button from '../../../ui/Button'

class JoinArtistContact extends React.Component {
  constructor(props) {
    super(props)
    const { currentUser, artistForm } = props
    const contactName = currentUser.name
    this.state = {
      errorMessage: [],
      invoiceOrganizationNumber: artistForm.invoiceInfo.organizationNumber
        ? artistForm.invoiceInfo.organizationNumber
        : '',
      invoiceName: artistForm.invoiceInfo.invoiceName ? artistForm.invoiceInfo.invoiceName : '',
      contactName: artistForm.contactInfo.contactName
        ? artistForm.contactInfo.contactName
        : contactName, // eslint-disable-line
      contactPhone: artistForm.contactInfo.contactPhone ? artistForm.contactInfo.contactPhone : '',
      contactEmail: artistForm.contactInfo.contactEmail ? artistForm.contactInfo.contactEmail : '',
      contactIsRepresenting: artistForm.contactInfo.contactIsRepresenting,
    }
  }

  setData = () => {
    const {
      contactName,
      contactPhone,
      contactEmail,
      contactIsRepresenting,
      invoiceOrganizationNumber,
      invoiceName,
    } = this.state
    const { setData } = this.props
    const contactInfo = {
      contactName,
      contactPhone,
      contactEmail,
      contactIsRepresenting,
    }
    const invoiceInfo = {
      organizationNumber: invoiceOrganizationNumber,
      invoiceName,
    }

    setData({
      contactInfo,
      invoiceInfo,
    })
  }

  handleContactNameChange = e => {
    this.setState({
      contactName: e.currentTarget.value,
    })
  }

  handleContactPhoneChange = e => {
    this.setState({
      contactPhone: e.currentTarget.value,
    })
  }

  handleContactEmailChange = e => {
    this.setState({
      contactEmail: e.currentTarget.value,
    })
  }

  handleContactIsRepresentingChange = e => {
    this.setState({
      contactIsRepresenting: e.currentTarget.checked,
    })
  }

  handleInvoiceOrganizationNumberChange = e => {
    const invoiceOrganizationNumber = e.currentTarget.value.replace(/\s+/g, '')
    this.setState({
      invoiceOrganizationNumber,
    })
  }

  handleInvoiceNameChange = e => {
    this.setState({
      invoiceName: e.currentTarget.value,
    })
  }

  handleNext = e => {
    e.preventDefault()
    const {
      contactName,
      contactPhone,
      contactEmail,
      contactIsRepresenting,
      invoiceOrganizationNumber,
      invoiceName,
    } = this.state
    const { artistForm, saveData, history, artistAdmin } = this.props
    let validData = true
    const errorMessage = []

    if (!invoiceOrganizationNumber) {
      validData = false
      errorMessage.push('Fyll ut organisasjonsnummer')
    } else if (!validateOrganizationNumber(invoiceOrganizationNumber)) {
      validData = false
      errorMessage.push('Fyll ut gyldig organisasjonsnummer')
    }

    if (!invoiceName) {
      validData = false
      errorMessage.push('Fyll ut firmanavn')
    }

    if (!contactName) {
      validData = false
      errorMessage.push('Fyll ut navn på kontaktperson')
    }

    if (!contactPhone) {
      validData = false
      errorMessage.push('Fyll ut telefonnummer til kontaktperson')
    } else if (contactPhone.length !== 8) {
      validData = false
      errorMessage.push('Fyll ut gyldig telefonnummer')
    }

    if (!contactEmail) {
      validData = false
      errorMessage.push('Fyll ut e-post til kontaktperson')
    } else if (!validateEmail(contactEmail)) {
      validData = false
      errorMessage.push('Fyll ut gyldig e-post')
    }

    if (artistForm.artistType !== 'solo') {
      if (!contactIsRepresenting) {
        validData = false
        errorMessage.push(
          `${contactName || 'Kontaktpersonen'} må kunne representere ${artistForm.name}`,
        )
      }
    }

    if (validData) {
      this.setData()
      saveData()
      history.push(getEditWelcomeUrl(artistAdmin.slug))
    } else {
      this.setState({
        errorMessage,
      })
    }
  }

  handlePrevious = e => {
    const { saveData, history } = this.props
    e.preventDefault()
    this.setData()
    saveData()
    history.push('/join/artist/location/')
  }

  render() {
    const { artistForm } = this.props
    const {
      contactName,
      contactPhone,
      contactEmail,
      contactIsRepresenting,
      invoiceOrganizationNumber,
      invoiceName,
      errorMessage,
    } = this.state
    return (
      <>
        <Row>
          <H1>{`Kontaktinformasjon for ${artistForm.name}`}</H1>
        </Row>
        <Row>
          <Subheading>
            Når vi har kontaktinformasjonen til {artistForm.name} kan vi ta kontakt når det kommer
            forespørsler om spillejobber
          </Subheading>
        </Row>
        <Row>
          <Intro>
            Vi vil verifisere denne informasjonen senere før du får din første spillejobb.
          </Intro>
        </Row>
        <Row>
          <InputWrapper>
            <Input
              id="artist-invoice-organization-number"
              label="Organisasjonsnummer"
              value={invoiceOrganizationNumber}
              onChange={this.handleInvoiceOrganizationNumberChange}
              placeHolder="Fyll ut organisasjonsnummer"
              helpText="Alle artister i Gigplanet må være registrerte foretak med eget organisasjonsnummer"
              labelHelp="Bruk organisasjonsnummeret til den bedriften som sender ut faktura når du gjør spillejobber."
              maxLength={9}
              maxTmpLength={11}
              expanded
              required
              autoFocus
            />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <Input
              id="artist-invoice-name"
              label="Firmanavn"
              value={invoiceName}
              onChange={this.handleInvoiceNameChange}
              placeholder="Fyll ut firmanavnet som organisasjonsnummereret tilhører"
              expanded
              required
            />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <Input
              id="artist-contact-name"
              label="Kontaktperson"
              value={contactName}
              onChange={this.handleContactNameChange}
              placeholder="Skriv inn for- og etternavn"
              helpText="For- og etternavn på kontaktperson"
              maxLength={70}
              expanded
            />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <Input
              id="artist-contact-email"
              label="E-post"
              value={contactEmail}
              onChange={this.handleContactEmailChange}
              placeholder="Skriv inn e-post"
              helpText="E-post til kontaktperson"
              expanded
            />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <Input
              id="artist-contact-phone"
              label="Telefon"
              value={contactPhone}
              onChange={this.handleContactPhoneChange}
              placeholder="Skriv inn telefonnummer"
              helpText="Telfonnummer til kontaktperson"
              expanded
            />
          </InputWrapper>
        </Row>
        {artistForm.artistType !== 'solo' ? (
          <Row>
            <InputWrapper>
              <Checkbox
                label={`${contactName || 'Kontaktpersonen'} har fullmakt til å representere ${
                  artistForm.name
                } ved inngåelse av kontrakt.`}
                id="isRepresenting"
                checked={contactIsRepresenting}
                onChange={this.handleContactIsRepresentingChange}
              />
            </InputWrapper>
          </Row>
        ) : null}
        <Row>
          <Errormessage messages={errorMessage} />
        </Row>
        <Row>
          <Actions>
            <Button type="button" theme="primary" onClick={this.handleNext}>
              Neste
            </Button>
            <Button type="button" theme="secondary" onClick={this.handlePrevious}>
              Forrige
            </Button>
          </Actions>
        </Row>
      </>
    )
  }
}

JoinArtistContact.propTypes = {
  currentUser: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  artistAdmin: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  artistForm: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  setData: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export default withRouter(JoinArtistContact)
