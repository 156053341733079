import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { ContentBlock, TextLine } from './style'
import { LONG_DATE_FORMAT, TIME_FORMAT } from '../../../models/GigRequest'

const Event = ({ acceptedTime }) => (
  <ContentBlock noMargin>
    {acceptedTime ? (
      <TextLine>
        Avtale om spillejobb er inngått {moment(acceptedTime).format(LONG_DATE_FORMAT)}, kl.{' '}
        {moment(acceptedTime).format(TIME_FORMAT)} mellom:
      </TextLine>
    ) : null}
  </ContentBlock>
)

Event.propTypes = {
  acceptedTime: PropTypes.string,
}
Event.defaultProps = {
  acceptedTime: '',
}

export default Event
