import * as React from 'react'
import styled, { css } from 'styled-components'

import FocusTrap from 'focus-trap-react'
import { useSelector } from 'react-redux'

import { getSiteSettingsSelector } from '../../stores/cms-content/reducer'

import gigplanetTheme from '../../styles/gigplanetTheme'

import SiteNavigationNavigation from './components/SiteNavigationNavigation'
import SiteNavigationContact from './components/SiteNavigationContact'

const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 70px;
  z-index: -10;
  outline: none;
`

const VisibilityHandler = styled.div`
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  ${({ isOpen }) =>
    !isOpen &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`

const Container = styled.section`
  background: ${gigplanetTheme.element.siteNavigation.background};
  position: absolute;
  top: 70px;
  z-index: 0;
  left: 0;
  right: 0;
  padding: 0 ${gigplanetTheme.spacing.xsmall};
  display: flex;
  justify-content: center;
`

const Wrapper = styled.div`
  max-width: ${gigplanetTheme.measure.maxWidth};
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SiteNavigation = ({ isOpen, closeSiteNav }) => {
  const siteSettings = useSelector(getSiteSettingsSelector)

  return (
    <VisibilityHandler isOpen={isOpen} aria-modal={isOpen} role="dialog">
      <Backdrop onClick={closeSiteNav} />
      <Container>
        <Wrapper>
          <SiteNavigationNavigation navigation={siteSettings.header.navigation} />
          <SiteNavigationContact settings={siteSettings} />
        </Wrapper>
      </Container>
    </VisibilityHandler>
  )
}

export default React.memo(SiteNavigation)
