import * as React from 'react'
import PropTypes from 'prop-types'

import { Row } from './style'

const FormRow = ({ id, children, smallPadding }) => (
  <Row id={id} smallPadding>
    {children}
  </Row>
)

FormRow.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  smallPadding: PropTypes.bool,
}

FormRow.defaultProps = {
  smallPadding: false,
}
export default FormRow
