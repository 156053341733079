import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const RichTextContainer = styled.div`
  max-width: 750px;
  width: 100%;
`

export const RichTextWrapper = styled.div`
  ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: 10px;
    `};

  h1 {
    color: ${gigplanetTheme.component.headings.h1.text};
    font-size: 20px;
    margin: 20px 0 5px;
    text-align: left;

    ${({ textSize }) =>
      textSize === 'large' &&
      css`
        font-size: 26px;
        font-weight: 400;
      `}
  }

  h2 {
    color: ${gigplanetTheme.component.headings.h1.text};
    font-size: 20px;
    margin: 0 0 5px;
    text-align: left;

    ${({ textSize }) =>
      textSize === 'large' &&
      css`
        font-size: 26px;
        font-weight: 400;
      `}

    ${mediaQueries.mobile`
      font-weight: 400;
    `}
  }

  h3 {
    color: ${gigplanetTheme.component.headings.h1.text};
    font-size: 16px;
    margin: 20px 0 5px;
    text-align: left;

    ${({ textSize }) =>
      textSize === 'large' &&
      css`
        font-size: 22px;
        font-weight: 400;
      `}
  }

  ul {
    margin: 0;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 16px 0;

    &:last-child {
      margin-bottom: 0;
    }

    ${mediaQueries.mobile`
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;  
    `}

    ${({ textSize }) =>
      textSize === 'large' &&
      css`
        font-size: 20px;
        line-height: 30px;
        font-weight: 300;
        margin-bottom: 30px;

        ${mediaQueries.mobile`
          font-size: 16px;
          line-height: 22px;  
        `}
      `}
  }

  blockquote {
    border: 0;
    margin: 0;
    padding: 15px 15px 15px 65px;

    position: relative;
    display: block;
    font-family: serif;
    font-size: 20px;
    line-height: 30px;

    &:after {
      content: '\\201C';
      position: absolute;
      top: 40px;
      left: 0;
      color: ${rgba(gigplanetTheme.color.primary, 0.5)};
      font-size: 100px;
      font-weight: bold;
      z-index: 2;

      ${({ textSize }) =>
        textSize === 'large' &&
        css`
          top: 70px;
          font-size: 200px;

          ${mediaQueries.mobile`
            top: 40px;
            font-size: 100px;
          `}
        `}
    }

    ${({ textSize }) =>
      textSize === 'large' &&
      css`
        font-size: 26px;
        line-height: 30px;
        font-weight: 300;
        padding: 30px 30px 30px 130px;

        ${mediaQueries.mobile`
        font-size: 20px;
          padding: 15px 15px 15px 65px;
        `}
      `}

    ${mediaQueries.mobile`
    
    `}
  }

  li {
    font-size: 16px;
    line-height: 18px;

    ${mediaQueries.mobile`
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;  
    `}

    ${({ textSize }) =>
      textSize === 'large' &&
      css`
        font-size: 20px;
        line-height: 30px;
        font-weight: 300;

        ${mediaQueries.mobile`
          font-size: 16px;
          line-height: 22px;  
        `}
      `}
  }

  img {
    max-width: 100%;
  }

  a {
    color: ${gigplanetTheme.color.primary};
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`
