import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router'

import {
  getOneEventplannerRequestAction,
  reloadOneEventplannerRequestAction,
} from '../../../../stores/gig-request-eventplanner/actions'

import {
  getOneEventplannerRequest,
  getOneEventplannerRequestIsLoading,
  getOneEventplannerRequestHasLoaded,
  getRequestFormData,
} from '../../../../stores/gig-request-eventplanner/reducer'
import { getEventTypes } from '../../../../stores/Options/OptionsReducer'

import PageNotFound from '../../../../components/PageNotFound'
import PageLoader from '../../../../components/PageLoader'
import GigRequestEdit from '../../../../components/gig-request-edit-eventplanner'
import { postJSON } from '../../../../libs/data'

const mapStateToProps = state => ({
  eventplannerRequest: getOneEventplannerRequest(state),
  isLoading: getOneEventplannerRequestIsLoading(state),
  hasLoaded: getOneEventplannerRequestHasLoaded(state),
  form: getRequestFormData(state),
  eventTypes: getEventTypes(state),
})

const mapDispatchToProps = dispatch => ({
  getOneRequest: gigRequestId => {
    dispatch(getOneEventplannerRequestAction(gigRequestId))
  },
  completedUpdate: gigRequestId => {
    dispatch(reloadOneEventplannerRequestAction(gigRequestId))
  },
})

class EventplannerInboxRequestEditRoute extends React.Component {
  constructor(props) {
    super(props)
    const { form } = props
    this.state = {
      form: {
        eventType: form.eventType,
        audienceCount: form.audienceCount,
        venue: form.venue,
        city: form.city,
        startTime: form.startTime,
        endTime: form.endTime,
        notes: form.notes,
        included: form.included,
      },
      formIsDirty: false,
      errorMessages: {},
      isSending: false,
      hasSent: false,
      isCancelingRequest: false,
    }
  }

  componentDidMount() {
    const {
      match: { params },
      getOneRequest,
    } = this.props
    if (params.gigRequestId) {
      getOneRequest(params.gigRequestId)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: { params },
      getOneRequest,
      form,
    } = this.props
    if (params.gigRequestId !== nextProps.match.params.gigRequestId) {
      getOneRequest(nextProps.match.params.gigRequestId)
    }

    if (form !== nextProps.form) {
      this.setState({
        form: {
          eventType: nextProps.form.eventType,
          audienceCount: nextProps.form.audienceCount,
          venue: nextProps.form.venue,
          city: nextProps.form.city,
          startTime: nextProps.form.startTime,
          endTime: nextProps.form.endTime,
          notes: nextProps.form.notes,
          included: nextProps.form.included,
        },
        formIsDirty: false,
      })
    }
  }

  handleCancelRequest = () => {
    // request/cancelRequest
    const {
      match: { params },
      history,
      getOneRequest,
    } = this.props

    this.setState({
      isCancelingRequest: true,
    })

    postJSON('/api/v1/gigRequest/cancelRequest', { gigRequestId: params.gigRequestId }).then(
      json => {
        getOneRequest(params.gigRequestId)
        this.setState({
          isCancelingRequest: false,
        })

        let gigRequestEditUrl = `/eventplanner/inbox/${params.gigRequestId}`
        if (params.artistId) {
          gigRequestEditUrl = `/eventplanner/inbox/${params.gigRequestId}/${params.artistId}`
        }
        history.push(gigRequestEditUrl)
      },
      error => {
        const errorMessages = { server: 'Kunne ikke avlyse arrangementet' }
        this.setState({
          errorMessages,
          isCancelingRequest: false,
        })
      },
    )
  }

  updateFormState = (key, value) => {
    const { errorMessages, form } = this.state
    const newErrorMessages = { ...errorMessages }

    if (newErrorMessages.dirty) {
      delete newErrorMessages.dirty
    }

    if (newErrorMessages[key] && key === 'audienceCount') {
      if (!Number.isNaN(value) && value > 0) {
        delete newErrorMessages.audienceCount
      }
    }
    if (newErrorMessages[key] && key === 'city') {
      if (value) {
        delete newErrorMessages.city
      }
    }

    if (newErrorMessages[key] && key === 'venue') {
      if (value) {
        delete newErrorMessages.venue
      }
    }
    this.setState({
      form: {
        ...form,
        [key]: value,
      },
      formIsDirty: true,
      errorMessages: newErrorMessages,
    })
  }

  handleVenueChange = e => {
    this.updateFormState('venue', e.currentTarget.value)
  }

  handleCityChange = e => {
    this.updateFormState('city', e.currentTarget.value)
  }

  handleAudienceCountChange = e => {
    this.updateFormState('audienceCount', parseInt(e.currentTarget.value, 10))
  }

  handleEventTypeChange = e => {
    const { errorMessages, form } = this.state
    const newErrorMessages = { ...errorMessages }

    if (newErrorMessages.dirty) {
      delete newErrorMessages.dirty
    }

    this.setState({
      form: {
        ...form,
        eventType: {
          ...form.eventType,
          sub: e.currentTarget.value,
        },
      },
      formIsDirty: true,
      errorMessages: newErrorMessages,
    })
  }

  handleTimeChange = time => {
    const { errorMessages, form } = this.state
    const newErrorMessages = { ...errorMessages }
    const startTime = time[0]
    const endTime = time[1]
    if (newErrorMessages.dirty) {
      delete newErrorMessages.dirty
    }

    if (startTime && endTime) {
      delete newErrorMessages['event-date']
    }
    this.setState({
      form: {
        ...form,
        startTime,
        endTime,
      },
      formIsDirty: true,
      errorMessages: newErrorMessages,
    })
  }

  handleNotesChange = e => {
    this.updateFormState('notes', e.currentTarget.value)
  }

  handleIncludedChange = e => {
    const { form } = this.state
    const included = [...form.included]
    if (e.currentTarget.value === 'included') {
      included.push(e.currentTarget.name)
    } else {
      included.splice(included.indexOf(e.currentTarget.name), 1)
    }
    this.updateFormState('included', included)
  }

  handleSubmit = e => {
    const { form, formIsDirty } = this.state
    const {
      match: { params },
      history,
      completedUpdate,
    } = this.props
    e.preventDefault()
    const errorMessages = {}
    if (!formIsDirty) {
      errorMessages.dirty = 'Du må gjøre en endring i skjemaet før du lagrer'
    }
    if (!form.audienceCount || form.audienceCount === 0) {
      errorMessages.audienceCount = 'Antall gjester må være et tall og mer enn 0'
    }

    if (!form.venue) {
      errorMessages.venue = 'Du må fylle ut adresse eller navn på lokalet'
    }
    if (!form.city) {
      errorMessages.city = 'Du må fylle ut kommune, by eller sted'
    }

    if (!form.startTime || !form.endTime) {
      errorMessages.date = 'Fyll ut dato og tid'
    }

    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        errorMessages,
      })
    } else {
      this.setState({
        isSending: true,
        hasSent: false,
        errorMessages,
      })
      postJSON('/api/v1/gigRequest/updateRequestInfo', {
        gigRequestId: params.gigRequestId,
        ...form,
      }).then(
        () => {
          this.setState({
            isSending: false,
            hasSent: true,
          })
          completedUpdate(params.gigRequestId)
          let gigRequestEditUrl = `/eventplanner/inbox/${params.gigRequestId}`
          if (params.artistId) {
            gigRequestEditUrl = `/eventplanner/inbox/${params.gigRequestId}/${params.artistId}`
          }
          history.push(gigRequestEditUrl)
        },
        error => {
          const errors = {}
          const message = error.response && error.response.message ? error.response.message : null

          if (message === 'gigrequest-starttimenotinfuture') {
            errors.other = 'Arrangementet sin dato må settes frem i tid.'
          } else if (message === 'gigrequest-alreadyacceptedquote') {
            errors.other = 'Man kan ikke endre en forespørsel etter at man har godtatt et tilbud.'
          } else {
            errors.other = 'Det har oppstått en feil'
          }
          this.setState({
            isSending: false,
            errorMessages: errors,
          })
        },
      )
    }
  }

  render() {
    const {
      match: { params },
      hasLoaded,
      eventplannerRequest,
      isLoading,
      eventTypes,
    } = this.props
    const { errorMessages, form, isCancelingRequest, isSending, hasSent } = this.state
    if (!params.gigRequestId) {
      return <PageNotFound />
    }

    if (isLoading) {
      return <PageLoader text="Laster forespørsel" />
    }

    if (hasLoaded && !{}.hasOwnProperty.call(eventplannerRequest, 'id')) {
      return <PageNotFound />
    }

    return (
      <GigRequestEdit
        gigRequestId={params.gigRequestId}
        artistId={params.artistId}
        venue={form.venue}
        onVenueChange={this.handleVenueChange}
        city={form.city}
        onCityChange={this.handleCityChange}
        audienceCount={form.audienceCount}
        onAudienceCountChange={this.handleAudienceCountChange}
        startTime={form.startTime}
        endTime={form.endTime}
        onTimeChange={this.handleTimeChange}
        eventType={form.eventType}
        onEventTypeChange={this.handleEventTypeChange}
        notes={form.notes}
        onNotesChange={this.handleNotesChange}
        included={form.included}
        onIncludedChange={this.handleIncludedChange}
        isSending={isSending}
        isCancelingRequest={isCancelingRequest}
        hasSent={hasSent}
        eventTypes={eventTypes}
        errorMessages={errorMessages}
        onSubmit={this.handleSubmit}
        onCancelRequest={this.handleCancelRequest}
      />
    )
  }
}

EventplannerInboxRequestEditRoute.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      gigRequestId: PropTypes.string,
      artistId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  form: PropTypes.shape({
    eventType: PropTypes.shape({
      eventType: PropTypes.string.isRequired,
      sub: PropTypes.string,
    }).isRequired,
    audienceCount: PropTypes.number.isRequired,
    venue: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
    included: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  getOneRequest: PropTypes.func.isRequired,
  eventplannerRequest: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,

  // dispatch props
  completedUpdate: PropTypes.func.isRequired,
}

EventplannerInboxRequestEditRoute.defaultProps = {}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EventplannerInboxRequestEditRoute)
