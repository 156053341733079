import * as React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, withRouter } from 'react-router'

import QuoteRoute from './quote'
import PageNotFoundRoute from '../404'

const PrintRoute = () => (
  <Switch>
    <Route path="/print/quote" component={QuoteRoute} />
    <Route component={PageNotFoundRoute} />
  </Switch>
)

PrintRoute.propTypes = {}
PrintRoute.defaultProps = {}

export default PrintRoute
