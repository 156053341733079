import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import gigplanetTheme from '../../../styles/gigplanetTheme'

import Label from './Label'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
`

const Element = styled.input`
  appearance: none;
  background-color: ${gigplanetTheme.component.form.checkbox.background};
  border: 1px solid ${gigplanetTheme.component.form.checkbox.border};
  border-radius: 2px;
  color: ${gigplanetTheme.component.form.checkbox.color};
  cursor: pointer;
  height: 18px;
  margin: 0;
  position: relative;
  transition: border-color 0.2s;
  vertical-align: top;
  width: 18px;
  min-width: 18px;

  &:checked {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24'><path d='M0 0h24v24H0z' fill='none' /><path d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' style='fill: %238805bb' /></svg>");
    background-origin: content-box;
    background-position: -1px 0;
    background-repeat: no-repeat;
  }

  ${({ isNoLabel }) =>
    isNoLabel &&
    css`
      margin: 0;
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: default;
    `};

  ${({ isNoBorder }) =>
    isNoBorder &&
    css`
      border: 1px solid transparent;
    `};
`

const ElementContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
`

const LabelContainer = styled.div`
  padding-left: 5px;
`

const HelpTextElement = styled.span`
  color: #82888a;
  display: block;
  font-size: 12px;
  margin-left: 23px;
`

const Checkbox = ({
  smallText,
  label,
  renderLabel,
  disabled,
  hideBorder,
  id,
  name,
  value,
  checked,
  onChange,
  helpText,
  lineThrough,
}) => (
  <Container>
    <ElementContainer>
      <Element
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        isNoLabel={!label}
        isDisabled={!disabled}
        isNoBorder={hideBorder}
      />
      <LabelContainer>
        {renderLabel
          ? renderLabel({ helpText, htmlFor: id, radio: true, lineThrough, disabled })
          : null}
        {label && (
          <Label
            htmlFor={id}
            helpText={helpText}
            radio
            lineThrough={lineThrough}
            disabled={disabled}
            isCheckboxLabel
          >
            {label}
          </Label>
        )}
      </LabelContainer>
    </ElementContainer>
    {helpText && <HelpTextElement>{helpText}</HelpTextElement>}
  </Container>
)

Checkbox.propTypes = {
  label: PropTypes.string,
  renderLabel: PropTypes.func,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  smallText: PropTypes.bool,
  lineThrough: PropTypes.bool,
  hideBorder: PropTypes.bool,
  helpText: PropTypes.string,
}

Checkbox.defaultProps = {
  label: '',
  renderLabel: null,
  value: '',
  onChange: null,
  checked: false,
  disabled: false,
  smallText: false,
  helpText: '',
  lineThrough: false,
  hideBorder: false,
}

export default Checkbox
