import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { Route, Switch, withRouter } from 'react-router'

import { fetchFrontpageArtists } from '../../stores/Artist/ArtistActions'
import { fetchPredefinedPageIfNeededAction } from '../../stores/cms-content/actions'

import JoinArtistRoute from './artist'
import PageNotFound from '../../components/PageNotFound'
import LandingPageContainer from '../../containers/landing-page'

const FRONTPAGE_ARTIST_LIMIT = 6

const JoinRoute = React.memo(({}) => (
  <Switch>
    <Route exact path="/join" render={() => <LandingPageContainer type="joinPage" />} />
    <Route path="/join/artist" exact render={() => <Redirect to="/join/artist/legal" />} />
    <Route path="/join/artist/:step" component={JoinArtistRoute} />
    <Route component={PageNotFound} />
  </Switch>
))

JoinRoute.dispatchActions = (req, dispatch, params) => [
  dispatch(fetchFrontpageArtists(req, FRONTPAGE_ARTIST_LIMIT)),
  dispatch(
    fetchPredefinedPageIfNeededAction(
      'joinPage',
      params && params.previewId ? params.previewId : '',
    ),
  ),
]

export default withRouter(JoinRoute)
