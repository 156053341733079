import styled from 'styled-components'
import { Link } from 'react-router-dom'

import gigplanetTheme from '../../styles/gigplanetTheme'

export const Info = styled.p`
  font-size: 14px;
  color: ${gigplanetTheme.color.mediumGray};
  margin: 0;
`

export const StyledLink = styled(Link)`
  color: ${gigplanetTheme.color.purple};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
