import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'

import { imageUrlFor } from '../../../helpers/sanity-image-builder'
import SanityImage from '../../sanity-image'
import mediaQueries, { mediaQueriesDefinitions } from '../../../styles/mediaQueries'

import { CameraIcon } from '../../../icons'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const ImageWrapper = styled.div`
  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      flex: 1 0 64%;
      max-width: 49rem;
      margin-right: 2rem;

      ${mediaQueries.tabletPortraitDown`
        margin-right: 0;
      `};
    `};

  img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    ${({ isHighlighted }) =>
      isHighlighted &&
      css`
        ${mediaQueries.tabletLandscapeUp`
          border-radius: 4px;
        `};
      `};
  }
`
const Placeholder = styled.div`
  width: 100%;
  height: 202px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${gigplanetTheme.color.lightGray};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid white;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      flex: 1 0 64%;
      max-width: 49rem;
      margin-right: 2rem;
      min-height: 350px;

      ${mediaQueries.tabletPortrait`
        margin-right: 0;
        min-height: 400px;
      `};
    `};

  svg {
    color: #fff;
    height: 98px;
    width: 98px;
  }
`

const ArtistCardImage = React.memo(({ articleImage, altText, highlighted = false }) => {
  const [visible, setVisible] = React.useState(false)

  const handleVisibilityChange = (isVisible) => {
    if (visible || !isVisible) {
      return
    }
    setVisible(true)
  }

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      active={!visible}
      offset={{ bottom: -150 }}
    >
      {visible && articleImage ? (
        <ImageWrapper isHighlighted={highlighted}>
          <SanityImage
            width={highlighted ? 800 : 430}
            height={highlighted ? 350 : 250}
            image={articleImage}
            altText={altText}
          >
            <source
              media={mediaQueriesDefinitions.tabletLandscape}
              srcSet={imageUrlFor(articleImage)
                .width(highlighted ? 800 : 430)
                .height(highlighted ? 500 : 250)}
            />
            <source
              media={mediaQueriesDefinitions.tabletPortraitDown}
              srcSet={imageUrlFor(articleImage)
                .width(highlighted ? 800 : 430)
                .height(highlighted ? 500 : 250)}
            />
          </SanityImage>
        </ImageWrapper>
      ) : (
        <Placeholder isHighlighted={highlighted}>
          <CameraIcon />
        </Placeholder>
      )}
    </VisibilitySensor>
  )
})

ArtistCardImage.propTypes = {
  articleImage: PropTypes.shape({}).isRequired,
  highlighted: PropTypes.bool,
  altText: PropTypes.string.isRequired,
}

export default ArtistCardImage
