import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const Button = styled.button`
  background: ${gigplanetTheme.color.purple};
  border: 2px solid ${gigplanetTheme.color.purple};
  border-radius: 4px;
  color: ${gigplanetTheme.color.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
`

export const Content = styled.span`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
`

export const IconWrapper = styled.span`
  padding-right: 5px;
`

export const TextWrapper = styled.span`
  padding-left: 5px;
`
