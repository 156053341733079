import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Route, Switch, withRouter } from 'react-router'

import { CurrentUserShape } from '../../models/CurrentUser'
import { getCurrentUser } from '../../stores/CurrentUser/CurrentUserReducer'
import MetaData from '../../components/MetaData'
import Layout from '../../layouts/main-layout'
import AccessDenied from '../../components/AccessDenied'

import DashBoardRoute from './dashboard'
import EventplannerInboxRoute from './inbox'
import HelpRoute from './help'
import PageNotFound from '../../components/PageNotFound'

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
})

const mapDispatchToProps = () => ({})

const EventplannerRoute = ({ currentUser, history: { location } }) => {
  if (!{}.hasOwnProperty.call(currentUser, 'id')) {
    return (
      <Layout>
        <MetaData
          title="Ingen tilgang"
          description="Man må logge inn for å få tilgang til arrangementer hos Gigplanet"
          nopin
          noindex
        />
        <AccessDenied />
      </Layout>
    )
  }
  return (
    <Layout showEventplannerMenu>
      <Switch>
        <Route
          path="/eventplanner"
          exact
          render={() => <Redirect to="/eventplanner/dashboard" />}
        />
        <Route path="/eventplanner/dashboard" component={DashBoardRoute} />
        <Route path="/eventplanner/inbox" component={EventplannerInboxRoute} />
        <Route path="/eventplanner/help" component={HelpRoute} />
        <Route component={PageNotFound} />
      </Switch>
    </Layout>
  )
}

EventplannerRoute.propTypes = {
  currentUser: CurrentUserShape,
  history: PropTypes.shape(),
}

EventplannerRoute.defaultProps = {
  currentUser: {},
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EventplannerRoute)
