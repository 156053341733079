import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const GigplanetIcon = (props) => (
  <SVGWrapper width="18" height="28" viewBox="0 0 18 26" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0V22.693C16 23.488 15.883 24.044 15 24.044C14.116 24.044 14 23.488 14 22.693V6.622C14 4.891 12.766 3.681 10.999 3.681C9.233 3.681 8 4.891 8 6.622V17.555C8 18.422 7.883 19.028 7 19.028C6.117 19.028 6 18.422 6 17.555V11.778C6 9.945 4.767 8.664 3.001 8.664C1.234 8.664 0 9.945 0 11.778V17H2V11.978C2 11.183 2.117 10.621 3 10.621C3.884 10.621 4 11.183 4 11.978V17.896V17.901C4.004 19.63 5.236 20.836 7 20.836C8.764 20.836 9.996 19.63 10 17.902V6.971C10 6.103 10.117 5.488 11 5.488C11.883 5.488 12 6.103 12 6.971V22.886C12 24.72 13.233 26 15 26C16.767 26 18 24.72 18 22.886V0H16Z"
      fill="currentColor"
    />
  </SVGWrapper>
)

export default GigplanetIcon
