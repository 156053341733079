import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
import { Redirect, useLocation, useHistory, useRouteMatch } from 'react-router-dom'

import {
  resetGigRequestFormDataAction,
  setArtistFormDataAction,
} from '../../../stores/GigRequest/GigRequestActions'
import { fetchPredefinedPageIfNeededAction } from '../../../stores/cms-content/actions'
import { addFavoriteAction } from '../../../stores/Artist/ArtistActions'
import {
  getCurrentUser,
  getIsFetching as getIsFetchingCurrentUser,
} from '../../../stores/CurrentUser/CurrentUserReducer'
import { getArtist } from '../../../stores/Artist/ArtistReducer'

import MetaData from '../../../components/MetaData'

import { GigRequestFormLogin } from '../../../components/GigRequestForm'
import LandingPageContainer from '../../../containers/landing-page'
import Layout from '../../../layouts/main-layout'

import PageNotFoundRoute from '../../404'
import EventRoute from './event'
import EventDetailsRoute from './event-details'
import RequesterRoute from './requester'
import ReceiptRoute from './receipt'

const ProfileDetailsGigRequestFormRoute = React.memo(() => {
  const dispatch = useDispatch()
  const artist = useSelector(getArtist)
  const currentUser = useSelector(getCurrentUser)
  const loadingUser = useSelector(getIsFetchingCurrentUser)
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()

  React.useEffect(() => {
    dispatch(resetGigRequestFormDataAction())
    dispatch(setArtistFormDataAction(artist.id))
    dispatch(addFavoriteAction(artist.id))
  }, [artist])

  const handleClose = () => {
    dispatch(resetGigRequestFormDataAction())
    history.push('/')
  }

  let showSimple = true
  if (
    location.pathname === `${match.url}/receipt` ||
    location.pathname === `${match.url}/receipt/`
  ) {
    showSimple = false
  }

  return (
    <Layout simpleMenu={showSimple} gray>
      <MetaData
        title={`Send forespørsel til ${artist.name}`}
        description="Hos Gigplanet kan du samle dine valgte artister og sende forespørsel til alle samtidig"
      />
      {!{}.hasOwnProperty.call(currentUser, 'id') && !loadingUser ? (
        <GigRequestFormLogin onAbort={handleClose} successUrl={`${match.url}`} artist={artist} />
      ) : (
        <Switch>
          <Route
            exact
            path={`${match.url}`}
            render={() => <Redirect to={`${match.url}/event`} />}
          />
          <Route path={`${match.url}/event`} component={EventRoute} />
          <Route path={`${match.url}/event-details`} component={EventDetailsRoute} />
          <Route path={`${match.url}/requester`} component={RequesterRoute} />
          <Route path={`${match.url}/receipt`} component={ReceiptRoute} />
          <Route component={PageNotFoundRoute} />
        </Switch>
      )}
      {showSimple ? <LandingPageContainer removeLayout type="gigRequestForm" /> : null}
    </Layout>
  )
})

ProfileDetailsGigRequestFormRoute.dispatchActions = (req, dispatch, params) => [
  dispatch(fetchPredefinedPageIfNeededAction('gigRequestForm')),
]

export default ProfileDetailsGigRequestFormRoute
