import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getEventTypes } from '../../stores/Options/OptionsReducer'

import GigRequestEventplannerBookingSidebar from './components/gig-request-sidebar-booking'

const mapStateToProps = state => ({
  eventTypes: getEventTypes(state),
})

const mapDispatchToProps = () => ({})

class GigRequestEventplannerBookingSidebarContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { artist, gigRequest, isLoading, quote, hasLoaded, eventTypes } = this.props
    return (
      <GigRequestEventplannerBookingSidebar
        artist={artist}
        gigRequest={gigRequest}
        quote={quote}
        isLoading={isLoading}
        hasLoaded={hasLoaded}
        eventTypes={eventTypes}
      />
    )
  }
}

GigRequestEventplannerBookingSidebarContainer.propTypes = {
  // normal props
  artist: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  gigRequest: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  quote: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,

  // state props
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

GigRequestEventplannerBookingSidebarContainer.defaultProps = {}

const GigRequestEventplannerBookingSidebarContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GigRequestEventplannerBookingSidebarContainer)

export default GigRequestEventplannerBookingSidebarContainerWrapper
