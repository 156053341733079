import styled from 'styled-components'

import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${gigplanetTheme.spacing.large};
  padding-left: ${gigplanetTheme.spacing.medium};
  padding-right: ${gigplanetTheme.spacing.medium};
  width: 100%;

  ${mediaQueries.mobile`
    padding-top: ${gigplanetTheme.spacing.medium};
    padding-bottom: ${gigplanetTheme.spacing.medium};
    padding-left: 0;
    padding-right: 0;
  `};
`

export const ArtistListWrapper = styled.div`
  margin-top: 32px;
  width: 100%;

  ${mediaQueries.mobile`
    margin-top: 16px;
  `};
`

export const EmptyWrapper = styled.p`
  margin: 32px 0 0;
  width: 100%;
  text-align: center;

  ${mediaQueries.mobile`
    margin: 16px 0 0;
  `};
`

export const FilterWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${mediaQueries.mobile`
    margin-top: 16px;
    padding-left: 10px;
    padding-right: 10px;
  `};
`
export const HeadingWrapper = styled.div`
  ${mediaQueries.mobile`
    padding-left: 10px;
    padding-right: 10px;
  `};
`

export const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 32px;

  ${mediaQueries.mobile`
    margin-top: 16px;
    flex-direction: column;
  `};
`
export const ReadMoreWrapper = styled.div`
  text-align: center;

  &:first-child {
    margin-left: 5px;
  }

  &:last-child {
    margin-right: 5px;
  }

  ${mediaQueries.mobile`
    &:first-child {
      margin-bottom: 5px;
    }

    &:last-child {
      margin-top: 5px;
    }
  `};
`
