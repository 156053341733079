import * as React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './style'

const ModalContentWrapper = ({ children }) => <Wrapper>{children}</Wrapper>

ModalContentWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
}
ModalContentWrapper.defaultProps = {}

export default ModalContentWrapper
