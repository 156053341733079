import styled from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const StyledEventplannerUserProfile = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${mediaQueries.mobile`
    padding: 15px;
  `};
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 15px;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
`

export const Separator = styled.hr`
  margin: 15px 0;
  width: 100%;
  height: 1px;
  border: 0;
  background: ${gigplanetTheme.element.default.border};
`

export const UserAvatarWrapper = styled.div`
  padding-bottom: 15px;
`

export const InfoElement = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 0 5px;
  font-weight: 300;
  max-width: 100%;
  width: 100%;
  text-align: center;
  line-height: 20px;
`

export const InfoName = styled(InfoElement)`
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
`
