import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FooterSettings } from '../../../models/FooterSettings'

import gigplanetTheme from '../../../styles/gigplanetTheme'
import mediaQueries from '../../../styles/mediaQueries'
import extractUrlPath from '../../../helpers/extract-url-path'

import { JOIN_ARTIST_URL } from '../../../models/Artist'
import { ARTIST_URL } from '../../../models/Support'
import { Button } from '../../../ui'

const Nav = styled.nav`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 100px 0 50px;

  ${mediaQueries.mobile`
      flex-wrap: wrap;
  `};
`
const NavColumn = styled.div`
  text-align: center;
  width: 25%;
  max-width: 100%;

  ${mediaQueries.mobile`
      width: 100%;
      margin-bottom: 30px;
  `};
`

const NavHeading = styled.h2`
  font-size: 25px;
  line-height: 31px;
  font-weight: 400;
  margin-bottom: 25px;
`
const NavLink = styled.a`
  display: block;
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 10px;
  text-decoration: none;
  color: ${gigplanetTheme.element.footer.link};

  &:hover {
    text-decoration: underline;
    color: ${gigplanetTheme.element.footer.linkHover};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const FooterNavigation = ({ navigation }) => {
  return (
    <Nav>
      {navigation.map((column) => (
        <NavColumn key={column._key}>
          {column.columnItems.map((item) => {
            if (item._type === 'columnHeading') {
              return <NavHeading key={item._key}>{item.heading}</NavHeading>
            }
            if (item._type === 'navigationItem') {
              return (
                <NavLink key={item._key} href={extractUrlPath(item.url)}>
                  {item.text}
                </NavLink>
              )
            }
            return null
          })}
        </NavColumn>
      ))}
    </Nav>
  )
}

export default React.memo(FooterNavigation)
