import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSlider from 'react-slider'

import gigplanetTheme from '../../../styles/gigplanetTheme'
import media from '../../../styles/mediaQueries'
import CurrencyInput from '../../../ui/CurrencyInput'
import { Label } from '../../../ui/Form'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`

const SliderRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`
const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
`

const InputColumn = styled.div`
  flex: 1 0 auto;
  &:first-child {
    margin-right: 5px;
  }
  &:last-child {
    margin-left: 5px;
  }
`

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;

  ${media.tabletPortraitUp`
    margin-right: 10px;
  `};
`
const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 6px;
`

const StyledThumb = styled.button`
  height: 36px;
  line-height: 36px;
  width: 36px;
  text-align: center;
  background-color: ${gigplanetTheme.color.primary};
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  top: -15px;
  border: 0;

  &[disabled] {
    background: green;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: red;
    `}
`

const Thumb = (props, state) => <StyledThumb {...props}></StyledThumb>

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${props =>
    props.index === 2
      ? gigplanetTheme.color.gray
      : props.index === 1
      ? gigplanetTheme.color.gray
      : gigplanetTheme.color.mediumGray};
  border-radius: 999px;
`

const Track = (props, state) => <StyledTrack {...props} index={state.index} />

const PriceFilter = React.memo(({ priceFrom, priceTo, onPriceFromChange, onPriceToChange }) => {
  const handlePriceFromChange = e => {
    onPriceFromChange(e.currentTarget.value)
  }

  const handlePriceToChange = e => {
    onPriceToChange(e.currentTarget.value)
  }

  return (
    <Container>
      <Label>Pris</Label>
      <SliderRow>
        <SliderWrapper>
          <StyledSlider
            min={0}
            max={75000}
            step={1000}
            aria-labelledby={['Pris fra', 'Pris til']}
            value={[parseInt(priceFrom || 0, 10), parseInt(priceTo || 0, 10)]}
            renderTrack={Track}
            renderThumb={Thumb}
            onChange={price => {
              if (price[0] !== priceFrom) {
                onPriceFromChange(price[0])
              } else if (price[1] !== priceTo) {
                onPriceToChange(price[1])
              }
            }}
          />
        </SliderWrapper>
      </SliderRow>
      <InputRow>
        <InputColumn>
          <CurrencyInput
            name="priceFrom"
            id="priceFrom"
            label="Pris fra"
            min={0}
            max={75000}
            onChange={handlePriceFromChange}
            value={priceFrom || 0}
            expanded
          />
        </InputColumn>
        <InputColumn>
          <CurrencyInput
            name="priceTo"
            id="priceTo"
            label="Pris til"
            min={0}
            max={75000}
            onChange={handlePriceToChange}
            value={priceTo || 0}
            expanded
          />
        </InputColumn>
      </InputRow>
    </Container>
  )
})

PriceFilter.propTypes = {
  priceFrom: PropTypes.number,
  onPriceFromChange: PropTypes.func.isRequired,
  priceTo: PropTypes.number,
  onPriceToChange: PropTypes.func.isRequired,
}

export default PriceFilter
