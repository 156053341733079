import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const ControlPanelIcon = (props) => (
  <SVGWrapper width="24" height="22" viewBox="0 0 24 22" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 5.625C15.103 5.623 14.377 4.897 14.375 4C14.377 3.103 15.103 2.377 16 2.375C16.897 2.377 17.623 3.103 17.625 4C17.623 4.897 16.897 5.623 16 5.625ZM19.207 3C18.778 1.628 17.514 0.625 16 0.625C14.486 0.625 13.222 1.628 12.793 3H0V5H12.793C13.222 6.372 14.486 7.375 16 7.375C17.514 7.375 18.778 6.372 19.207 5H24V3H19.207Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 19.625C15.103 19.623 14.377 18.897 14.375 18C14.377 17.103 15.103 16.377 16 16.375C16.897 16.377 17.623 17.103 17.625 18C17.623 18.897 16.897 19.623 16 19.625ZM16 14.625C14.486 14.625 13.222 15.628 12.793 17H0V19H12.793C13.222 20.372 14.486 21.375 16 21.375C17.514 21.375 18.778 20.372 19.207 19H24V17H19.207C18.778 15.628 17.514 14.625 16 14.625Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12.625C7.103 12.623 6.377 11.897 6.375 11C6.377 10.103 7.103 9.377 8 9.375C8.897 9.377 9.623 10.103 9.625 11C9.623 11.897 8.897 12.623 8 12.625ZM8 7.625C6.486 7.625 5.222 8.628 4.793 10H0V12H4.793C5.222 13.372 6.486 14.375 8 14.375C9.514 14.375 10.778 13.372 11.207 12H24V10H11.207C10.778 8.628 9.514 7.625 8 7.625Z"
      fill="currentColor"
    />
  </SVGWrapper>
)

export default ControlPanelIcon
