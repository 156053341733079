/* globals window */
import * as React from 'react'
import PropTypes from 'prop-types'

import { getLogoutUrl } from '../../../models/Auth'

import { MobileMenu } from './style'
import { shutdownIntercom } from './util'

import HeaderMenuList from '../../../ui/header-menu-list'
import HeaderMenuSpacer from '../../../ui/header-menu-list-item-spacer'
import HeaderMenuItem from '../../../ui/header-menu-list-item'
import HeaderMenuButton from '../../../ui/header-menu-button'
import { SearchIcon, HeartEmptyIcon } from '../../../icons'
import HeaderMenuMobileWrapper from '../../../components/header-menu-mobile-wrapper'
import { SupportUnreadCount } from '../../header-menu-logged-out/components/style'
import { CONTROLPANEL_URL } from '../../../models/Controlpanel'
import RequestsMenuItem from './requests-menu-item'

import HeaderMenuItemUserMobile from '../../header-menu-item-user-mobile'
import HeaderBurgerButton from '../../../ui/header-burger-button'
import SiteNavigationMobileLoggedIn from '../../../components/SiteNavigationMobile/SiteNavigationMobileLoggedIn'

const LoggedInMobileMenu = ({
  unreadCount,
  showControlpanelButton,
  eventplannerGigRequestCount,
  artistGigRequestCount,
  onShowRequestOptions,
  showingRequestOptions,
  currentUser,
  favoriteCount,
  hasDarkBg,
  focusCallback,
  showControlpanelMenu,
  showEventplannerMenu,
}) => {
  const [showingSiteNav, setShowSiteNav] = React.useState(false)

  React.useEffect(() => {
    focusCallback(showingSiteNav)
  }, [showingSiteNav])

  const openSiteNav = () => {
    setShowSiteNav(true)
  }

  const closeSiteNav = () => {
    setShowSiteNav(false)
  }

  const toggleSiteNav = () => {
    setShowSiteNav(!showingSiteNav)
  }

  return (
    <MobileMenu>
      <HeaderMenuList>
        <HeaderMenuItem onClick={toggleSiteNav}>
          <HeaderBurgerButton
            aria-label="Hovednavigasjonsmeny"
            aria-haspopup="true"
            isOpen={showingSiteNav}
            hasDarkBg={hasDarkBg}
          />
        </HeaderMenuItem>
      </HeaderMenuList>
      <SiteNavigationMobileLoggedIn
        isOpen={showingSiteNav}
        closeSiteNav={closeSiteNav}
        favoriteCount={favoriteCount}
        eventplannerGigRequestCount={eventplannerGigRequestCount}
        artistGigRequestCount={artistGigRequestCount}
        onShowRequestOptions={onShowRequestOptions}
        showingRequestOptions={showingRequestOptions}
        showControlpanelButton={showControlpanelButton}
        showControlpanelMenu={showControlpanelMenu}
        showEventplannerMenu={showEventplannerMenu}
      />
    </MobileMenu>
  )
}

LoggedInMobileMenu.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
  unreadCount: PropTypes.number.isRequired,
  favoriteCount: PropTypes.number.isRequired,
  showControlpanelButton: PropTypes.bool,
  eventplannerGigRequestCount: PropTypes.number.isRequired,
  artistGigRequestCount: PropTypes.number.isRequired,
  onShowRequestOptions: PropTypes.func.isRequired,
  showingRequestOptions: PropTypes.bool.isRequired,
  hasDarkBg: PropTypes.bool,
  showControlpanelMenu: PropTypes.bool,
  showEventplannerMenu: PropTypes.bool,
}
LoggedInMobileMenu.defaultProps = {
  showControlpanelButton: false,
}

export default LoggedInMobileMenu
