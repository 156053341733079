import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const AddIcon = props => (
  <SVGWrapper width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </SVGWrapper>
)

export default AddIcon
