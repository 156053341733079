import styled from 'styled-components'
import gigplanetTheme from '../../../styles/gigplanetTheme'
import mediaQueries from '../../../styles/mediaQueries'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
export const StatusLine = styled.div`
  width: 100%;
  margin-bottom: ${gigplanetTheme.spacing.medium};

  ${mediaQueries.mobile`
    margin-bottom: ${gigplanetTheme.spacing.small};
  `};
`

export const ActionsWrapper = styled.div`
  width: 100%;
  border: 1px solid ${gigplanetTheme.element.default.border};
  padding: ${gigplanetTheme.spacing.medium};
  margin: 0 0 ${gigplanetTheme.spacing.medium} 0;

  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.small};
    margin: 0 0 ${gigplanetTheme.spacing.small} 0;
  `};
`
export const ButtonWrapper = styled.div`
  margin-top: ${gigplanetTheme.spacing.medium};

  ${mediaQueries.mobile`
    margin-top: ${gigplanetTheme.spacing.small};
  `};

  & > * {
    margin-right: 5px;
    margin-bottom: 5px;

    &:last-child {
      margin: 0;
    }
  }
`
export const NewMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0;
`

export const Message = styled.div`
  border: 1px solid ${gigplanetTheme.element.default.border};
  flex: 1 1 auto;
  padding: ${gigplanetTheme.spacing.medium};
  position: relative;

  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.small};
  `};

  ${mediaQueries.tabletLandscapeUp`
    &:before {
      right: -10px;
      top: 13px;
      border: 10px solid transparent;
      border-right: 0;
      border-left-color: ${gigplanetTheme.element.default.border};
      content: '';
      display: inline-block;
      position: absolute;
    }

    &:after {
      border-left-color: ${gigplanetTheme.element.default.border};
      right: -9px;
      top: 14px;
      border: 9px solid transparent;
      border-right: 0;
      border-left-color: #fff;
      content: '';
      display: inline-block;
      position: absolute;
    }
  `};
`

export const User = styled.div`
  padding-left: 20px;

  ${mediaQueries.tabletPortraitDown`
    display: none;
    padding: 0;
  `};
`

export const Alert = styled.div``

export const AlertText = styled.p``

export const AlertActions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  & > * {
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
`

export const StatusDescription = styled.p`
  margin: 0;
`

export const QuoteWrapper = styled.div`
  padding-top: ${gigplanetTheme.spacing.small};

  ${mediaQueries.mobile`
    padding-top: ${gigplanetTheme.spacing.small};
  `};
`

export const ContactInfo = styled.div`
  padding-top: 30px;
  width: 80%;

  ${mediaQueries.tabletPortraitDown`
    width: 100%;
  `};

  ${mediaQueries.mobile`
    padding-bottom: 15px;
  `};
`
