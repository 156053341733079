import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { postJSON } from '../../libs/data'
import { gigRequestType } from '../../models/GigRequest'
import GigRequestBookingForm from './components/gig-request-booking-form'
import validateEmail from '../../helpers/validate-email'
import validatePhone from '../../helpers/validate-phone'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

class GigRequestEventplannerBookingFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      invoice: {
        organization: '',
        name: '',
        address: '',
        zip: '',
        city: '',
        ref: '',
      },
      contact: {
        name: '',
        phone: '',
        email: '',
      },
      acceptedTerms: false,
      errorMessages: {},
      submitting: false,
      submitted: false,
    }
  }

  handleAcceptQuote = () => {
    const { quoteId, gigRequest } = this.props
    const { invoice, contact, acceptedTerms } = this.state
    const errorMessages = {}

    // acceptQuote(quoteId, invoice, contact)
    const eventType = gigRequest && gigRequest.eventType ? gigRequest.eventType.eventType : ''
    if (eventType === gigRequestType.BUSINESS) {
      if (!invoice.organization) {
        errorMessages['invoice-organization'] = 'Du må fylle ut bedriftsnavn'
      }
    } else if (eventType === gigRequestType.PRIVATE) {
      // move invoice.name check here
    }

    if (!invoice.name) {
      errorMessages['invoice-name'] = 'Du må fylle ut fullt fakturanavn'
    }

    if (!invoice.address) {
      errorMessages['invoice-address'] = 'Du må fylle ut fakturaadresse'
    }
    if (!invoice.zip) {
      errorMessages['invoice-zip'] = 'Du må fylle ut postnummer'
    }
    if (!invoice.city) {
      errorMessages['invoice-city'] = 'Du må fylle ut poststed'
    }

    if (!contact.name) {
      errorMessages['contact-name'] = 'Du må fylle navn til kontaktperson'
    }
    if (!contact.phone) {
      errorMessages['contact-phone'] = 'Du må fylle ut telefon til kontaktperson'
    }

    if (!validatePhone(contact.phone)) {
      errorMessages['contact-phone'] = 'Du må fylle ut et gyldig telefonnummer'
    }

    if (!contact.email) {
      errorMessages['contact-email'] = 'Du må fylle ut epost til kontaktperson'
    }

    if (!validateEmail(contact.email)) {
      errorMessages['contact-email'] = 'Du må fylle ut gyldig epost'
    }

    if (!acceptedTerms) {
      errorMessages['accepted-terms'] = 'Du må godkjenne kontrakt og vilkår'
    }

    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        errorMessages,
      })
      return
    }

    this.setState({
      submitting: true,
      errorMessages,
    })
    const url = '/api/v1/gigRequest/acceptQuote'
    postJSON(url, {
      quoteId,
      invoice: {
        organization: invoice.organization,
        name: invoice.name,
        address: invoice.address,
        zip: invoice.zip,
        city: invoice.city,
        reference: invoice.ref,
      },
      contact: {
        name: contact.name,
        phone: contact.phone,
        email: contact.email,
      },
    }).then(
      result => {
        this.setState({
          submitting: false,
          submitted: true,
        })
      },
      error => {
        let message = ''
        const serverCode = error.response && error.response.message ? error.response.message : ''
        switch (serverCode) {
          case 'quote-invalidstatus':
            message = 'Tilbudet har feil status'
            break
          case 'gigquote-invoicenamerequired':
            message = 'Mangler fullt navn i fakturainfo'
            break
          default:
            message = `Feil fra server (${serverCode})`
        }
        errorMessages['server-error'] = message

        this.setState({ submitting: false, submitted: false, errorMessages })
      },
    )
  }

  handleChangeAcceptedTerms = e => {
    this.setState({
      acceptedTerms: e.currentTarget.checked,
    })
  }

  handleChangeInvoiceForm = e => {
    const { invoice } = this.state
    this.setState({
      invoice: {
        ...invoice,
        [e.target.name]: e.target.value,
      },
    })
  }

  handleChangeContactForm = e => {
    const { contact } = this.state
    this.setState({
      contact: {
        ...contact,
        [e.target.name]: e.target.value,
      },
    })
  }

  render() {
    const { quoteId, gigRequestId, artistId, gigRequest, artist } = this.props
    const { invoice, contact, acceptedTerms, errorMessages, submitted, submitting } = this.state

    let printQuoteUrl = `/print/quote/eventplanner/preview/?quoteId=${quoteId}&gigRequestId=${gigRequestId}&artistId=${artistId}`
    printQuoteUrl += `&invoiceOrganization=${invoice.organization}`
    printQuoteUrl += `&invoiceName=${invoice.name}`
    printQuoteUrl += `&invoiceAddress=${invoice.address}`
    printQuoteUrl += `&invoiceZip=${invoice.zip}`
    printQuoteUrl += `&invoiceCity=${invoice.city}`

    printQuoteUrl += `&contactName=${contact.name}`
    printQuoteUrl += `&contactPhone=${contact.phone}`
    printQuoteUrl += `&contactEmail=${contact.email}`

    return (
      <GigRequestBookingForm
        quoteId={quoteId}
        gigRequestId={gigRequestId}
        artistId={artistId}
        eventDate={gigRequest.startTime}
        eventTypeCategory={gigRequest && gigRequest.eventType ? gigRequest.eventType.eventType : ''}
        artistName={artist.name}
        invoiceOrganization={invoice.organization}
        invoiceName={invoice.name}
        invoiceAddress={invoice.address}
        invoiceZip={invoice.zip}
        invoiceCity={invoice.city}
        invoiceRef={invoice.ref}
        contactName={contact.name}
        contactPhone={contact.phone}
        contactEmail={contact.email}
        onChangeInvoiceForm={this.handleChangeInvoiceForm}
        onChangeContactForm={this.handleChangeContactForm}
        acceptedTerms={acceptedTerms}
        onChangeAcceptedTerms={this.handleChangeAcceptedTerms}
        onSubmit={this.handleAcceptQuote}
        errorMessages={errorMessages}
        submitting={submitting}
        submitted={submitted}
        printQuoteUrl={printQuoteUrl}
        status={gigRequest.status}
      />
    )
  }
}

GigRequestEventplannerBookingFormContainer.propTypes = {
  quoteId: PropTypes.string.isRequired,
  gigRequestId: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,
  artist: PropTypes.shape({}).isRequired,
  gigRequest: PropTypes.shape({
    eventType: PropTypes.shape({
      eventType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  quote: PropTypes.shape({}).isRequired,
}

GigRequestEventplannerBookingFormContainer.defaultProps = {}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(GigRequestEventplannerBookingFormContainer)
