import * as React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

/** Portal components used to mount modals, tooltips etc */
const Portal = React.memo(({ children }) => {
  const [mounted, setMounted] = React.useState(false)
  const portalRootRef = React.useRef(null)
  const domNodeRef = React.useRef(null)

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      portalRootRef.current = document.getElementById('modal')
      domNodeRef.current = document.createElement('div')
      portalRootRef.current.appendChild(domNodeRef.current)
      setMounted(true)
    }
    return () => {
      portalRootRef.current.removeChild(domNodeRef.current)
    }
  }, [])
  if (domNodeRef.current === null || mounted === false) {
    return null
  }

  return ReactDOM.createPortal(children, domNodeRef.current)
})

Portal.propTypes = {
  children: PropTypes.element,
}

export default Portal
