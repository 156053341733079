import styled, { css } from 'styled-components'

import { H2 } from '../../ui/Headings'

import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const StyledGigRequestTimelineQuote = styled.div`
  text-align: left;
  width: 100%;

  ${({ isDeclined }) =>
    isDeclined &&
    css`
      ${H2} {
        color: red;
      }
    `};
`

export const InvalidatedNote = styled.p`
  font-weight: 600;
`

export const QuoteDetails = styled.div`
  margin: 0 0 15px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;

  ${mediaQueries.mobile`
    font-size: 14px;
    line-height: 16px;
  `};
`

export const QuoteDetailsLine = styled.p`
  margin: 0;
  padding: 0;
`

export const QuoteDetailsLabel = styled.span`
  font-weight: 600;
`

export const ExpiresText = styled.p`
  margin: 0 0 ${gigplanetTheme.spacing.small};
  font-weight: 300;
  font-style: italic;
`
export const QuoteTerms = styled.pre`
  font-size: 16px;
  margin: 0;
  line-height: 20px;
  font-weight: 300;
  text-align: left;
  font-family: inherit;
  white-space: pre-wrap;
`
