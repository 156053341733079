/* globals window */
import { trackEvent } from '../../libs/analytics'

export const chatWithUs = (prefix) => {
  trackEvent(`${prefix}help`, `${prefix}help-launch-intercom`)
  if (window && window.Intercom) {
    window.Intercom('showNewMessage')
  }
}

export const trackControlpanelHelpdesk = (prefix) => {
  trackEvent(`${prefix}help`, `${prefix}help-launch-helpcenter`)
}

export const trackEmailUs = (prefix) => {
  trackEvent(`${prefix}help`, `${prefix}help-launch-email`)
}
