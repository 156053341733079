/* eslint-disable import/prefer-default-export */
export const getCropUrl = crop => {
  if (
    {}.hasOwnProperty.call(crop, 'x') &&
    {}.hasOwnProperty.call(crop, 'y') &&
    {}.hasOwnProperty.call(crop, 'w') &&
    {}.hasOwnProperty.call(crop, 'h')
  ) {
    if (crop.x !== 0 || crop.y !== 0 || crop.w !== 0 || crop.h !== 0) {
      return `${crop.x},${crop.y},${crop.w},${crop.h}`
    }
  }
  return ''
}
