import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  getRequesterFormData,
  getArtistFormData,
} from '../../../../stores/GigRequest/GigRequestReducer'

import mediaQueries from '../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import useCurrentUser from '../../../../hooks/use-current-user'
import GigRequestFormHeader from '../GigRequestFormHeader'
import GigRequestFormWrapper from '../GigRequestFormWrapper'
import Button from '../../../../ui/Button'
import { FIND_MORE_ARTISTS_URL } from '../../../../models/GigRequest'

export const ReceiptWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 0 10px 30px;
  width: 100%;
  min-width: 300px;
  max-width: 600px;

  ${mediaQueries.mobile`
    width: 100%;
    padding: 30px 10px;
  `};
`
export const Spacer = styled.div`
  height: ${gigplanetTheme.spacing.small};
  width: 100%;
`

export const Intro = styled.p`
  font-size: 16px;
  margin: 0;

  ${mediaQueries.mobile`
    font-size: 14px;
  `};
`

export const From = styled.p`
  font-size: 16px;
  font-weight: bold;

  ${mediaQueries.mobile`
    font-size: 14px;
  `};
`

export const PS = styled.p`
  font-size: 16px;
  margin: ${gigplanetTheme.spacing.small} 0;

  ${mediaQueries.mobile`
    font-size: 14px;
  `};
`

export const ReceiptActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${mediaQueries.mobile`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `};

  & > button {
    margin-left: 5px;
    margin-right: 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    ${mediaQueries.mobile`
      width: 100%;
      margin: 5px 0;
    `};
  }
`

const GigRequestFormReceiptStep = React.memo(({ onNext }) => {
  const requesterData = useSelector(getRequesterFormData)
  const artist = useSelector(getArtistFormData)
  const currentUser = useCurrentUser()
  const history = useHistory()
  let email = requesterData.email
  if (!requesterData.email) {
    email = currentUser.email
  }

  return (
    <GigRequestFormWrapper white>
      <GigRequestFormHeader
        heading="Takk for din forespørsel"
        intro={`Forespørselen er nå sendt direkte til ${artist ? artist.name : 'artisten'}.`}
        artist={artist}
      />
      <ReceiptWrapper>
        <Intro>
          <strong>VIKTIG!</strong> Det er nå opprettet et &quot;digitalt rom&quot; hvor du kan se
          forespørselen, chatte med bandets eller artistens kontaktperson, samt motta, akseptere
          eller avslå tilbud.
        </Intro>

        {email ? <PS>PS. En kopi er også sendt til {email}.</PS> : null}
        <From>Hilsen oss i Gigplanet</From>
      </ReceiptWrapper>
      <ReceiptActionsWrapper>
        <Button type="button" theme="secondary" onClick={onNext}>
          Se forespørsel
        </Button>
        <Button
          type="button"
          theme="primary"
          onClick={() => {
            history.push(FIND_MORE_ARTISTS_URL)
          }}
        >
          Kontakt flere artister
        </Button>
      </ReceiptActionsWrapper>
    </GigRequestFormWrapper>
  )
})

GigRequestFormReceiptStep.propTypes = {
  onNext: PropTypes.func.isRequired,
}

export default GigRequestFormReceiptStep
