import styled from 'styled-components'
import { Link } from 'react-router-dom'

import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
`
