import styled, { css } from 'styled-components'
import gigplanetTheme from '../../../../styles/gigplanetTheme'

export const Container = styled.div`
  max-width: 300px;
  width: 100%;
  height: 26px;
  margin: 0 auto;
  position: relative;
`

export const Borders = styled.div`
  position: absolute;
  top: 12px;
  left: 5px;
  right: 5px;
  height: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
`

export const Border = styled.div`
  background: ${gigplanetTheme.element.default.border};
  height: 3px;
  flex: 1 0 auto;

  ${({ isCompleted }) =>
    isCompleted &&
    css`
      background: ${gigplanetTheme.color.purple};
    `};
`

export const Dots = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  ${({ stepCount }) =>
    stepCount === 1 &&
    css`
      justify-content: center;
    `};
`

export const Dot = styled.div`
  background: ${gigplanetTheme.element.default.border};
  color: ${gigplanetTheme.element.default.text};
  height: 26px;
  width: 26px;
  border-radius: 100%;
  text-align: center;
  line-height: 26px;
  font-size: 14px;

  ${({ isCompleted }) =>
    isCompleted &&
    css`
      background: ${gigplanetTheme.color.purple};
      color: ${gigplanetTheme.color.white};
    `};

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${gigplanetTheme.color.purple};
      color: ${gigplanetTheme.color.white};
      font-weight: 600;
      height: 32px;
      width: 32px;
      line-height: 32px;
      font-size: 16px;
    `};
`
