import * as React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'

import { fetchFrontpageArtists } from '../../stores/Artist/ArtistActions'
import { fetchPredefinedPageIfNeededAction } from '../../stores/cms-content/actions'

import LandingPageContainer from '../../containers/landing-page'
import Article from './Article'

const FRONTPAGE_ARTIST_LIMIT = 6

const ArticlesPage = React.memo(() => {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${url}/:articleSlug`} component={Article} />
      <Route>
        <LandingPageContainer showFavorites type="articlesPage" />)
      </Route>
    </Switch>
  )
})

ArticlesPage.dispatchActions = (req, dispatch) => {
  const { path } = req
  let previewId = ''
  const parts = path.split('?')
  if (parts.length > 1) {
    if (parts[1]) {
      const subParts = parts[1].split('=')
      const idx = subParts.indexOf('cms-preview')
      if (idx !== -1) {
        previewId = subParts[idx + 1]
      }
    }
  }
  return [
    dispatch(fetchFrontpageArtists(req, FRONTPAGE_ARTIST_LIMIT)),
    dispatch(fetchPredefinedPageIfNeededAction('articlesPage', previewId)),
  ]
}
export default ArticlesPage
