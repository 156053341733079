import * as React from 'react'
import PropTypes from 'prop-types'

import Layout from '../../layouts/main-layout'
import SystemMessageWrapper from '../../ui/system-message-wrapper'
import UserAuthVerifyEmailError from '../../components/user-auth-verify-email-error'

const VerifyEmailErrorView = ({ successUrl, onClose }) => (
  <Layout>
    <SystemMessageWrapper>
      <UserAuthVerifyEmailError successUrl={successUrl} onClose={onClose} />
    </SystemMessageWrapper>
  </Layout>
)

VerifyEmailErrorView.propTypes = {
  successUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

VerifyEmailErrorView.defaultProps = {}

export default React.memo(VerifyEmailErrorView)
