import * as React from 'react'
import PropTypes from 'prop-types'

import { validateEmail } from '../../../models/Auth'

import { Input, Errormessage } from '../../../ui/Form'
import Button from '../../../ui/Button'
import { H1 } from '../../../ui/Headings'
import UserAuthAbort from '../../../components/user-auth-abort'
import { InputWrapper, Description, HeadingWrapper } from './style'

const UserAuthVerifyEmailForm = ({
  email,
  errorMessages,
  serverError,
  hideHeading,
  onChangeEmail,
  onSubmit,
  isSending,
  onClose,
}) => {
  const validEmail = validateEmail(email)
  let emailErrorMessage = ''
  if (errorMessages && errorMessages.length > 0) {
    const emailIdx = errorMessages.findIndex(element => element.id === 'email')
    if (emailIdx !== -1 && (!validEmail || serverError)) {
      emailErrorMessage = errorMessages[emailIdx].message
    }
  }
  return (
    <>
      {!hideHeading ? (
        <HeadingWrapper>
          <H1>Verifiser e-post</H1>
        </HeadingWrapper>
      ) : null}
      <Description>
        Skriv inn e-postadressen som er tilknyttet kontoen din, og vi vil sende deg en lenke for å
        verifisere e-posten.
      </Description>
      <form action="/v1/api/sendVerifyEmail" method="POST" onSubmit={onSubmit}>
        <InputWrapper>
          <Input
            id="email"
            type="email"
            label="E-post"
            placeholder="Din e-post"
            required
            expanded
            value={email}
            onChange={onChangeEmail}
            errorMessage={emailErrorMessage}
            validated={validEmail}
          />
        </InputWrapper>
        {errorMessages && errorMessages.length > 0 ? (
          <InputWrapper>
            <Errormessage messages={errorMessages.map(message => message.message)} />
          </InputWrapper>
        ) : null}
        <Button type="submit" theme="primary" wide loading={isSending}>
          Send e-post
        </Button>
        {onClose ? <UserAuthAbort onAbort={onClose} /> : null}
      </form>
    </>
  )
}

UserAuthVerifyEmailForm.propTypes = {
  hideHeading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
    }),
  ),
  isSending: PropTypes.bool.isRequired,
  serverError: PropTypes.bool.isRequired,
}
UserAuthVerifyEmailForm.defaultProps = {
  hideHeading: false,
  errorMessages: [],
}

export default UserAuthVerifyEmailForm
