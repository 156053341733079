import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { shutdownIntercom } from './util'

import { getLogoutUrl } from '../../models/Auth'
import { CONTROLPANEL_URL } from '../../models/Controlpanel'
import { USER_ROLE, USER_PROFILE_URL } from '../../models/CurrentUser'
import getGlobals from '../../helpers/getGlobals'

import { getCurrentUser } from '../../stores/CurrentUser/CurrentUserReducer'
import { getMyEventplannerRequests } from '../../stores/gig-request-eventplanner/reducer'
import { getMyArtistRequests } from '../../stores/GigRequestArtist/GigRequestArtistReducer'

import { UserItem, AvatarWrapper } from './style'

import { DropdownMenuButton, DropdownMenuContent } from '../../ui/dropdown-menu'
import HeaderMenuItem from '../../ui/header-menu-list-item'
import HeaderMenuButton from '../../ui/header-menu-button'
import UserAvatar from '../../ui/UserAvatar'
import HeaderMenuList from '../../ui/header-menu-list'

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  artistGigRequest: getMyArtistRequests(state),
  eventplannerGigRequest: getMyEventplannerRequests(state),
})

const mapDispatchToProps = (dispatch) => ({})

const HeaderMenuItemUserDesktop = React.memo(
  ({
    currentUser,
    showEventplannerMenu,
    showControlpanelMenu,
    artistGigRequest,
    eventplannerGigRequest,
    hasDarkBg,
  }) => {
    const [showingMenu, setShowingMenu] = React.useState(false)
    const openMenu = () => {
      setShowingMenu(true)
    }

    const closeMenu = () => {
      setShowingMenu(false)
    }

    return (
      <HeaderMenuItem>
        <UserItem>
          <DropdownMenuButton
            id="profile-menu"
            isOpen={showingMenu}
            onClick={openMenu}
            title="Vis brukermeny"
            aria-label="Vis brukermeny"
            renderButton={(props) => (
              <HeaderMenuButton hasDarkBg={hasDarkBg} {...props}>
                <AvatarWrapper>
                  <UserAvatar user={currentUser} size={30} />
                </AvatarWrapper>
                <span>{currentUser.firstName ? currentUser.firstName : null}</span>
              </HeaderMenuButton>
            )}
          />
          <DropdownMenuContent
            id="profile-menu"
            label="Profilmeny"
            isOpen={showingMenu}
            onClose={closeMenu}
            rightSide
          >
            <HeaderMenuList inDropdown>
              {currentUser && currentUser.role === USER_ROLE.ADMIN ? (
                <HeaderMenuItem inDropdown>
                  <HeaderMenuButton
                    inDropdown
                    href={getGlobals().ADMIN_SERVER_HOST}
                    onClick={closeMenu}
                  >
                    Administrasjon
                  </HeaderMenuButton>
                </HeaderMenuItem>
              ) : null}
              <HeaderMenuItem inDropdown>
                <HeaderMenuButton inDropdown to={USER_PROFILE_URL}>
                  Rediger profil
                </HeaderMenuButton>
              </HeaderMenuItem>

              {showEventplannerMenu && artistGigRequest && artistGigRequest.length > 0 ? (
                <HeaderMenuItem inDropdown>
                  <HeaderMenuButton inDropdown to={CONTROLPANEL_URL}>
                    Bytt til artist
                  </HeaderMenuButton>
                </HeaderMenuItem>
              ) : null}
              {showControlpanelMenu &&
              eventplannerGigRequest &&
              eventplannerGigRequest.length > 0 ? (
                <HeaderMenuItem inDropdown>
                  <HeaderMenuButton inDropdown to="/eventplanner">
                    Bytt til arrangør
                  </HeaderMenuButton>
                </HeaderMenuItem>
              ) : null}
              <HeaderMenuItem inDropdown>
                <HeaderMenuButton
                  inDropdown
                  onClick={shutdownIntercom}
                  href={getLogoutUrl()}
                  title="Logg ut"
                  aria-label="Logg ut"
                >
                  Logg ut
                </HeaderMenuButton>
              </HeaderMenuItem>
            </HeaderMenuList>
          </DropdownMenuContent>
        </UserItem>
      </HeaderMenuItem>
    )
  },
)

HeaderMenuItemUserDesktop.propTypes = {
  // state props
  currentUser: PropTypes.shape({}).isRequired,
  artistGigRequest: PropTypes.arrayOf(PropTypes.shape({})),
  eventplannerGigRequest: PropTypes.arrayOf(PropTypes.shape({})),

  // normal props
  showEventplannerMenu: PropTypes.bool,
  showControlpanelMenu: PropTypes.bool,
}
HeaderMenuItemUserDesktop.defaultProps = {
  showEventplannerMenu: false,
  showControlpanelMenu: false,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(HeaderMenuItemUserDesktop)
