import * as React from 'react'
import PropTypes from 'prop-types'

import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'

import { Content, List, Item, Text } from './style'

import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import LandingPageHeadingWrapper from '../../../../ui/landing-page-heading-wrapper'
import { H2 } from '../../../../ui/landing-page-headings'
import { CheckmarkIcon } from '../../../../icons'

const ChecklistBlock = ({ theme, anchorId, heading, items }) => (
  <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
    {heading ? (
      <LandingPageHeadingWrapper>
        <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
      </LandingPageHeadingWrapper>
    ) : null}
    <Content>
      <List>
        {(items || []).map(item => (
          // eslint-disable-next-line react/no-danger
          <Item key={item._key}>
            <CheckmarkIcon />
            <Text dangerouslySetInnerHTML={{ __html: `${formatSimpleMarkdown(item.itemText)}` }} />
          </Item>
        ))}
      </List>
    </Content>
  </LandingPageBlockWrapper>
)

ChecklistBlock.propTypes = {
  theme: PropTypes.string.isRequired,
  anchorId: PropTypes.string,
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      itemText: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
ChecklistBlock.defaultProps = {
  anchorId: '',
  heading: '',
}

export default ChecklistBlock
