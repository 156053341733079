import * as types from './GigRequestTypes'

/*
 * action creators
 */
export const setGigRequestIdFormDataAction = gigRequestId => ({
  type: types.SET_FORM_REQUEST_ID,
  data: {
    gigRequestId,
  },
})
export const setRequesterFormDataAction = requesterData => ({
  type: types.SET_FORM_REQUESTER,
  data: {
    requesterData,
  },
})
export const setEventFormDataAction = eventData => ({
  type: types.SET_FORM_EVENT,
  data: {
    eventData,
  },
})

export const setArtistFormDataAction = artistId => ({
  type: types.SET_FORM_ARTIST,
  data: {
    artistId,
  },
})

export const resetGigRequestFormDataAction = () => ({
  type: types.RESET_GIGREQUEST_FORM,
})
