import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'

const move = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: 50px 50px; }
`

const PlayBarWrapper = styled.div`
  height: 10px;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  ${({ isLoading }) =>
    isLoading &&
    css`
      background-image: linear-gradient(
        -45deg,
        rgba(136, 5, 187, 0.3) 25%,
        transparent 25%,
        transparent 50%,
        rgba(136, 5, 187, 0.3) 50%,
        rgba(136, 5, 187, 0.3) 75%,
        transparent 75%,
        transparent
      );
      background-size: 50px 50px;
      animation: ${move} 2s linear infinite;
      /* border-radius: 20px; */
      overflow: hidden;
      box-shadow: inset 0 10px 0 rgba(255, 255, 255, 0.2);
    `};
`
const PlayBar = ({ style, children, isLoading } /* , ...props */) => (
  <PlayBarWrapper
    isLoading={isLoading}
    style={{
      ...style,
    }}
  >
    {children}
  </PlayBarWrapper>
)

PlayBar.propTypes = {
  style: PropTypes.shape().isRequired,
  children: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool,
}
PlayBar.defaultProps = {
  isLoading: false,
}

export default PlayBar
