import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { Row, Subheading, InputWrapper, Actions, Intro } from './style'
import { Select, Errormessage } from '../../../ui/Form'
import Button from '../../../ui/Button'
import { H1 } from '../../../ui/Headings'

class JoinAristType extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: [],
    }
  }

  handleNext = e => {
    const { artistForm, saveData, history } = this.props
    e.preventDefault()
    if (artistForm.artistType) {
      saveData()
      history.push('/join/artist/location/')
    } else {
      this.setState({
        errorMessage: ['Du må velge type artist'],
      })
    }
  }

  handlePrevious = e => {
    const { saveData, history } = this.props
    e.preventDefault()
    saveData()
    history.push('/join/artist/name/')
  }

  handleArtistTypeChange = e => {
    const { setData } = this.props
    setData({ artistType: e.currentTarget.value })
  }

  render() {
    const { artistTypes, currentUser, artistForm } = this.props
    const { errorMessage } = this.state
    const artistTypeOptions = Object.keys(artistTypes).map(artistType => ({
      value: artistType,
      name: artistTypes[artistType].name,
    }))

    return (
      <>
        <Row>
          <H1>Hyggelig å møte deg {currentUser.firstName}</H1>
        </Row>
        <Row>
          <Subheading>Fortell kundene dine hvilke type artist du er</Subheading>
        </Row>
        <Row>
          <Intro />
        </Row>
        <Row>
          <InputWrapper>
            <Select
              id="artist"
              label="Hvilke type artist registrerer du?"
              actionLabel="Velg"
              value={artistForm.artistType}
              options={artistTypeOptions}
              onChange={this.handleArtistTypeChange}
              autoFocus
              expanded
            />
          </InputWrapper>
        </Row>
        <Row>
          <Errormessage messages={errorMessage} />
        </Row>
        <Row>
          <Actions>
            <Button type="button" theme="primary" onClick={this.handleNext}>
              Neste
            </Button>
            <Button type="button" theme="secondary" onClick={this.handlePrevious}>
              Forrige
            </Button>
          </Actions>
        </Row>
      </>
    )
  }
}

JoinAristType.propTypes = {
  currentUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  artistTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  artistForm: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  setData: PropTypes.func.isRequired,
  saveData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withRouter(JoinAristType)
