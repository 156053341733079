import * as React from 'react'
import PropTypes from 'prop-types'

import { List } from './style'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'

import Question from '../../../FAQ'
import { H2 } from '../../../../ui/landing-page-headings'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import LandingPageHeadingWrapper from '../../../../ui/landing-page-heading-wrapper'

const FAQBlock = React.memo(({ theme, anchorId, heading, faqs }) => (
  <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
    <LandingPageHeadingWrapper>
      <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
    </LandingPageHeadingWrapper>
    <List>
      {faqs.map((faq, index) => (
        <Question
          key={faq.id}
          id={faq.id}
          question={faq.question}
          answer={faq.answer}
          isFirst={index === 0}
        />
      ))}
    </List>
  </LandingPageBlockWrapper>
))

FAQBlock.propTypes = {
  anchorId: PropTypes.string,
  theme: PropTypes.string.isRequired,
  heading: PropTypes.string,
  intro: PropTypes.string,
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }),
  ),
}

FAQBlock.defaultProps = {
  anchorId: '',
  heading: '',
  intro: '',
  content: [],
}

export default FAQBlock
