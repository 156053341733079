import styled from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  margin: 50px 0;
  padding: 0 30px;
  background: #fff;
  max-width: ${gigplanetTheme.measure.maxWidth};

  ${mediaQueries.mobile`
    margin: 30px 0;
    padding: 0 15px;
  `};
`

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;

  ${mediaQueries.mobile`
    flex-direction: column;
    margin-top: 30px;
  `};
`

export const Item = styled.li`
  background-color: #f4f4f4;
  margin: 15px;
  flex: 1 0 30%;

  ${mediaQueries.mobile`
    margin: 0 0 15px;
    flex: 1 0 100%;

    &:last-child {
      margin-bottom: 0;
    }
  `};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

export const ListLink = styled.a`
  display: block;
  padding: 30px;
  margin: 0;
  width: 100%;
  color: ${gigplanetTheme.color.darkGray};
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  svg {
    height: 48px;
    width: 48px;
    margin-bottom: 20px;
  }
`

export const ListButton = styled.button`
  padding: 30px;
  width: 100%;
  background: transparent;
  margin: 0;
  border: 0;
  color: ${gigplanetTheme.color.darkGray};
  text-align: center;
  cursor: pointer;

  svg {
    height: 48px;
    width: 48px;
    margin-bottom: 20px;
  }
`

export const Intro = styled.p`
  margin: 10px 0;
  min-height: 40px;
  line-height: 20px;
`

export const LinkText = styled.span`
  font-weight: 600;
  color: ${gigplanetTheme.color.purple};
  text-decoration: none;
`
