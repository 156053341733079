import styled from 'styled-components'
import mediaQueries from '../../../../styles/mediaQueries'

export const Container = styled.div`
  margin: 40px auto 0;
  max-width: 500px;
  padding: 0 10px;

  ${mediaQueries.mobile`
    width: 100%;
    margin-top: 30px;
  `};
`
