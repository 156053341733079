/* eslint-disable react/no-danger */
import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import mediaQueries from '../../../../styles/mediaQueries'
import Heading from '../../../ProfileDetailsHeading/ProfileDetailsHeading'
import Button from '../../../../ui/Button'
import { ProfileDetailsRow } from '../../styles'
import gigplanetTheme from '../../../../styles/gigplanetTheme'

const createDescriptionMarkup = markup => ({
  __html: markup,
})
const Container = styled.div`
  background-color: #fff;
  padding: ${gigplanetTheme.spacing.medium};

  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.xsmall};
  `};
`
const Row = styled(ProfileDetailsRow)``

const Description = styled.div`
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 65.88078%;
  margin-left: 17.05961%;
  ${mediaQueries.mobile`
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    margin-left: 0;
  `};
  :last-child {
    margin-right: 0;
  }
`

const DescriptionContent = styled.div`
  margin: 0;
  font-family: 'Halcom', Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
  ${mediaQueries.mobile`
    font-size: 14px;
    line-height: 20px;
  `};
  p {
    margin: 0;
  }
  ${({ isMore }) =>
    isMore &&
    css`
      max-height: 200px;
      overflow-y: hidden;
      position: relative;
      &:after {
        overflow: hidden;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.99) 80%
        );
        height: 50px;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    `};
`
const Readmore = styled.div`
  margin-top: 2.35765%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${mediaQueries.mobile`
    justify-content: flex-start;
  `};
`

class ProfileDetailsAbout extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showReadMoreButton: false,
      readMore: false,
    }
    this.contentRef = null
    this.toggleReadMore = this.toggleReadMore.bind(this)
  }

  componentDidMount() {
    const { showReadMoreButton } = this.state
    if (this.contentRef) {
      const rect = this.contentRef.getBoundingClientRect()
      if (rect.height > 150 && !showReadMoreButton) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
          showReadMoreButton: true,
        })
      }
    }
  }

  toggleReadMore = () => {
    const { readMore } = this.state
    this.setState({
      readMore: !readMore,
    })
  }

  render() {
    const { showReadMoreButton, readMore } = this.state
    const { containerId, artistName, description } = this.props
    return (
      <Container id={containerId}>
        <Row>
          <Heading>{`Om ${artistName}`}</Heading>
        </Row>
        <Row>
          <Description>
            <DescriptionContent
              isMore={showReadMoreButton && !readMore}
              ref={r => {
                this.contentRef = r
              }}
              dangerouslySetInnerHTML={createDescriptionMarkup(description)}
            />
          </Description>
        </Row>
        {showReadMoreButton && !readMore ? (
          <Readmore>
            <Button type="button" theme="outline" onClick={this.toggleReadMore}>
              Les Mer
            </Button>
          </Readmore>
        ) : null}
      </Container>
    )
  }
}

ProfileDetailsAbout.propTypes = {
  containerId: PropTypes.string.isRequired,
  artistName: PropTypes.string.isRequired,
  description: PropTypes.string,
}

ProfileDetailsAbout.defaultProps = {
  description: '',
}

export default ProfileDetailsAbout
