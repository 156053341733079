import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router'

import { fetchFrontpageArtists } from '../../stores/Artist/ArtistActions'
import { fetchPredefinedPageIfNeededAction } from '../../stores/cms-content/actions'

import LandingPageContainer from '../../containers/landing-page'

const FRONTPAGE_ARTIST_LIMIT = 6

const FavoritePageRoute = React.memo(({}) => {
  return <LandingPageContainer showFavorites type="favoritePage" />
})

FavoritePageRoute.dispatchActions = (req, dispatch, params) => [
  dispatch(fetchFrontpageArtists(req, FRONTPAGE_ARTIST_LIMIT)),
  dispatch(
    fetchPredefinedPageIfNeededAction(
      'favoritePage',
      params && params.previewId ? params.previewId : '',
    ),
  ),
]

export default compose(withRouter)(FavoritePageRoute)
