import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${gigplanetTheme.element.gigrequest.background};
  padding: 2rem 0;

  ${mediaQueries.mobile`
    padding-top: 1rem;
  `};

  ${({ isWhite }) =>
    isWhite &&
    css`
      background: #fff;
    `};
`

const GigRequestFormWrapper = ({ white, children }) => (
  <Container isWhite={white}>{children}</Container>
)

GigRequestFormWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  white: PropTypes.bool,
}

GigRequestFormWrapper.defaultProps = {
  white: false,
}

export default GigRequestFormWrapper
