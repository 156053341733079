/* globals window */

import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'

import useOptions from '../../../../../hooks/useOptions'
import { ArtistShape } from '../../../../../models/Artist'
import { gigRequestType, GIGREQUEST_NOT_SET_ID } from '../../../../../models/GigRequest'

import { RequestInfo, FormContainer, RadioWrapper, TimeWrapper } from './style'

import GigRequestFormWrapper from '../../GigRequestFormWrapper'
import GigRequestFormProgress from '../../GigRequestFormProgress'
import GigRequestFormActionsWrapper from '../../GigRequestFormActionsWrapper'
import GigRequestFormHeader from '../../GigRequestFormHeader'
import GigRequestFormSubHeading from '../../GigRequestFormSubHeading'
import GigRequestFormError from '../../GigRequestFormError'

import Row from '../../../../FormRow'
import Column from '../../../../FormColumn'
import { Button, FancyRadioButton } from '../../../../../ui'
import { Select, Input, DatePicker, Radio, Label } from '../../../../../ui/Form'
import gigplanetTheme from '../../../../../styles/gigplanetTheme'

const StyledErrorMessage = styled.small`
  color: ${gigplanetTheme.component.form.shared.errorText};
  font-size: 12px;
  line-height: 15px;
`

const WARNING_TIME = 180

const GigRequestFormEvent = React.memo(
  ({
    artist,
    eventTypeCategory,
    errorMessages = null,
    startTime = '',
    endTime = '',
    gigRequestId = GIGREQUEST_NOT_SET_ID,
    onRequestIdChange,
    filteredGigRequestsEventplanner = [],
    gigRequestsEventplannerHasLoaded = false,
    onEventTypeCategoryChange,
    eventType = '',
    onEventTypeChange,
    audienceCount = '',
    onAudienceCountChange,
    onTimeChange,
    onSubmit,
    onNext,
    onPrevious,
  }) => {
    const { eventTypes } = useOptions()

    let eventTypesOptions = []
    if (eventTypeCategory && eventTypes[eventTypeCategory]) {
      eventTypesOptions = Object.keys(eventTypes[eventTypeCategory].sub).map(key => ({
        value: key,
        name: eventTypes[eventTypeCategory].sub[key].name,
      }))
    }

    let diff = null
    if (startTime && endTime) {
      const timeDiff = moment(endTime).diff(startTime, 'minutes')
      const hours = parseInt(timeDiff / 60, 10)
      const minutes = timeDiff % 60
      let warning = null
      let timeText = `Total spilletid: ${hours} timer`
      if (minutes > 0) {
        timeText += ` og ${minutes} minutter`
      }
      if (timeDiff > WARNING_TIME) {
        warning = <span>Normal spilletid er maks 3 timer totalt.</span>
        timeText += '.'
      }
      diff = (
        <TimeWrapper>
          <span>{timeText}</span>
          {warning}
        </TimeWrapper>
      )
    }
    const showAddArtist = gigRequestId !== '' && gigRequestId !== GIGREQUEST_NOT_SET_ID
    return (
      <GigRequestFormWrapper>
        <GigRequestFormProgress step={1} stepCount={showAddArtist ? 1 : 3} />
        <GigRequestFormHeader
          heading={`Kontakt ${artist ? artist.name : 'artisten'}`}
          intro={`Fyll ut skjemaet under, og motta et gratis, uforpliktende tilbud fra ${
            artist ? artist.name : 'artisten'
          }`}
          artist={artist}
        />

        <GigRequestFormSubHeading heading="Fortell om ditt arrangement" />
        {gigRequestsEventplannerHasLoaded &&
        filteredGigRequestsEventplanner &&
        filteredGigRequestsEventplanner.length > 0 ? (
          <RequestInfo>
            <Row>
              <Column>
                <Label id="eventcategoryHeading">
                  Opprette nytt, eller legge til på eksisterende arrangement?
                </Label>
                <RadioWrapper>
                  <Radio
                    label="Nytt arrangement"
                    name="gigRequestId"
                    id="request-new"
                    checked={gigRequestId === ''}
                    value=""
                    onChange={onRequestIdChange}
                  />
                </RadioWrapper>
                {filteredGigRequestsEventplanner.map(request => (
                  <RadioWrapper key={request.id}>
                    <Radio
                      label={`${
                        eventTypes[request.eventType.eventType].sub[request.eventType.sub].name
                      } (${moment(request.startTime).format('DD.MM.YYYY')}) - ${request.city}`}
                      name="gigRequestId"
                      id={`request-existing-${request.id}`}
                      checked={gigRequestId === request.id}
                      value={request.id}
                      onChange={onRequestIdChange}
                    />
                  </RadioWrapper>
                ))}
              </Column>
            </Row>
          </RequestInfo>
        ) : null}
        {gigRequestId === '' ||
        (gigRequestsEventplannerHasLoaded &&
          filteredGigRequestsEventplanner &&
          filteredGigRequestsEventplanner.length === 0) ? (
          <FormContainer>
            <Row>
              <Column>
                <Label id="eventcategoryHeading">Velg type arrangør</Label>
                <RadioWrapper>
                  <FancyRadioButton
                    label="Privat"
                    name="eventtypecategory"
                    id="eventtypecategory-private"
                    checked={eventTypeCategory === gigRequestType.PRIVATE}
                    value={gigRequestType.PRIVATE}
                    onChange={onEventTypeCategoryChange}
                    helpText="Privat arrangement"
                    expanded
                  />
                  <FancyRadioButton
                    label="Bedrift"
                    name="eventtypecategory"
                    id="eventtypecategory-business"
                    checked={eventTypeCategory === gigRequestType.BUSINESS}
                    value={gigRequestType.BUSINESS}
                    onChange={onEventTypeCategoryChange}
                    helpText="Du har et org. nummer"
                    expanded
                  />
                </RadioWrapper>
                {errorMessages && errorMessages.eventtypecategory ? (
                  <StyledErrorMessage>{errorMessages.eventtypecategory}</StyledErrorMessage>
                ) : null}
              </Column>
            </Row>
            <Row>
              <Column>
                <Select
                  label="Type arrangement"
                  id="event-type"
                  name="event-type"
                  actionLabel={
                    eventTypesOptions.length === 0
                      ? 'Velg type arrangør først'
                      : 'Velg type arrangement'
                  }
                  options={eventTypesOptions}
                  value={eventType}
                  onChange={onEventTypeChange}
                  errorMessage={errorMessages && errorMessages['event-type']}
                  expanded
                  disabled={eventTypesOptions.length === 0}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Input
                  id="audience-count"
                  name="audience-count"
                  label="Forventet antall gjester"
                  placeholder="Skriv ca antall"
                  type="number"
                  value={audienceCount}
                  onChange={onAudienceCountChange}
                  errorMessage={errorMessages && errorMessages['audience-count']}
                  expanded
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <DatePicker
                  id="event-date"
                  name="event-date"
                  label="Dato og tid"
                  actionLabel="Velg dato og tid"
                  value={startTime}
                  startTime={startTime}
                  endTime={endTime}
                  onChange={onTimeChange}
                  hasError={Boolean(errorMessages && errorMessages['event-date'])}
                  errorMessage={errorMessages && errorMessages['event-date']}
                  showTime
                  showDuration
                  disablePast
                  expanded
                  required
                />
                {diff}
              </Column>
            </Row>
          </FormContainer>
        ) : null}

        <GigRequestFormError errorMessages={errorMessages} />
        <GigRequestFormActionsWrapper>
          {showAddArtist ? (
            <Button type="button" theme="primary" onClick={onSubmit}>
              Send forespørsel
            </Button>
          ) : (
            <Button type="button" theme="primary" onClick={onNext}>
              Neste: Arrangementsdetaljer
            </Button>
          )}
          <Button type="button" theme="transparent" onClick={onPrevious}>
            Tilbake
          </Button>
        </GigRequestFormActionsWrapper>
      </GigRequestFormWrapper>
    )
  },
)

GigRequestFormEvent.propTypes = {
  gigRequestId: PropTypes.string,
  onRequestIdChange: PropTypes.func.isRequired,
  artists: ArtistShape,
  eventTypeCategory: PropTypes.string.isRequired,
  onEventTypeCategoryChange: PropTypes.func.isRequired,
  eventType: PropTypes.string,
  onEventTypeChange: PropTypes.func.isRequired,
  audienceCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAudienceCountChange: PropTypes.func.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  onTimeChange: PropTypes.func.isRequired,
  errorMessages: PropTypes.objectOf(PropTypes.string),
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  filteredGigRequestsEventplanner: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      startTime: PropTypes.string.isRequired,
      eventType: PropTypes.shape({
        eventType: PropTypes.string.isRequired,
        sub: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  gigRequestsEventplannerHasLoaded: PropTypes.bool,
}

export default GigRequestFormEvent
