import styled, { css } from 'styled-components'

import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const StyledContactInfo = styled.div`
  text-align: left;
`

export const ContactInfoLine = styled.p`
  margin: 0;
  padding: 0;
  line-height: 20px;
`

export const ContactInfoLabel = styled.span`
  font-weight: 600;
`

export const EmailLink = styled.a`
  color: ${gigplanetTheme.color.primary};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`
