import * as React from 'react'
import PropTypes from 'prop-types'

const Button = ({ className, id, isOpen, onClick, children, renderButton, ...rest }) =>
  renderButton ? (
    renderButton({
      type: 'button',
      'aria-haspopup': true,
      'aria-controls': id,
      'aria-expanded': isOpen,
      onClick,
      ...rest,
    })
  ) : (
    <button
      type="button"
      className={className}
      aria-haspopup
      aria-controls={id}
      aria-expanded={isOpen}
      onClick={event => {
        event.preventDefault()
        onClick(event)
      }}
    >
      {children}
    </button>
  )

Button.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  renderButton: PropTypes.func,
}

Button.defaultProps = {
  renderButton: null,
}

export default Button
