import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router'

import { getCurrentUser } from '../../../../../../stores/CurrentUser/CurrentUserReducer'
import {
  getQuoteHasSentSelector,
  getQuoteIsSendingSelector,
  getQuoteSelector,
  getOneEventplannerRequestIsLoading,
  getOneEventplannerRequestHasLoaded,
  getOneEventplannerRequestForArtist,
} from '../../../../../../stores/gig-request-eventplanner/reducer'
import { getQuoteAction } from '../../../../../../stores/gig-request-eventplanner/actions'

import MetaData from '../../../../../../components/MetaData'
import Book from '../../../../../../views/gig-request-booking-eventplanner'

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  quote: getQuoteSelector(state),
  quoteIsLoading: getQuoteIsSendingSelector(state),
  quoteHasLoaded: getQuoteHasSentSelector(state),
  requestIsLoading: getOneEventplannerRequestIsLoading(state),
  requestHasLoaded: getOneEventplannerRequestHasLoaded(state),
  requestWithArtist: getOneEventplannerRequestForArtist(state),
})

const mapDispatchToProps = dispatch => ({
  getQuote: quoteId => {
    dispatch(getQuoteAction(quoteId))
  },
})

class EventplannerInboxBookingRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const {
      getQuote,
      match: { params },
    } = this.props
    getQuote(params.quoteId)
  }

  render() {
    const {
      quote,
      quoteIsLoading,
      quoteHasLoaded,
      requestIsLoading,
      requestHasLoaded,
      requestWithArtist,
      match: { params },
    } = this.props
    return (
      <>
        <MetaData title="Booking" description="" nopin />
        <Book
          quoteId={params.quoteId}
          gigRequestId={params.gigRequestId}
          artistId={params.artistId}
          artist={
            requestWithArtist && requestWithArtist.artist && requestWithArtist.artist.artist
              ? requestWithArtist.artist.artist
              : null
          }
          gigRequest={requestWithArtist}
          quote={quote}
          isLoading={quoteIsLoading && requestIsLoading}
          hasLoaded={quoteHasLoaded && requestHasLoaded}
        />
      </>
    )
  }
}

EventplannerInboxBookingRoute.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      gigRequestId: PropTypes.string,
      artistId: PropTypes.string,
      quoteId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  quote: PropTypes.shape({}),
  quoteIsLoading: PropTypes.bool.isRequired,
  quoteHasLoaded: PropTypes.bool.isRequired,
  requestIsLoading: PropTypes.bool.isRequired,
  requestHasLoaded: PropTypes.bool.isRequired,
  requestWithArtist: PropTypes.shape({}).isRequired,
  getQuote: PropTypes.func.isRequired,
}

EventplannerInboxBookingRoute.defaultProps = {
  quote: {},
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EventplannerInboxBookingRoute)
