import * as React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import mediaQueries from '../../../../styles/mediaQueries'
import { ProfileDetailsRow } from '../../styles'

const Categories = styled.div`
  background: #fff;
  padding: 2.35765%;
`
const Row = styled(ProfileDetailsRow)``

const List = styled.ul`
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 65.88078%;
  margin-left: 17.05961%;
  list-style: none;
  padding: 0;
  text-align: center;
  ${mediaQueries.mobile`
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    margin-left: 0;
  `};
  :last-child {
    margin-right: 0;
  }
`
const Item = styled.li`
  display: inline-block;
  padding: 5px;
`
const Tag = styled.span`
  background: #565a5c;
  border-radius: 5px;
  color: #fff;
  display: block;
  font-size: 16px;
  padding: 10px 20px;
  ${mediaQueries.mobile`
    font-size: 14px;
    padding: 5px 10px;
  `};
`

const ProfileDetailsOtherCategories = React.memo(({ artistCategories, categories }) => (
  <Categories>
    <Row>
      <List>
        {artistCategories.map(category => {
          if (categories[category.category] && categories[category.category].sub[category.sub]) {
            return (
              <Item key={category.sub}>
                <Tag>{categories[category.category].sub[category.sub].name}</Tag>
              </Item>
            )
          }
          return null
        })}
      </List>
    </Row>
  </Categories>
))

ProfileDetailsOtherCategories.propTypes = {
  artistCategories: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  categories: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default ProfileDetailsOtherCategories
