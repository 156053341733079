import * as React from 'react'
import PropTypes from 'prop-types'

import PrintLayout from '../../layouts/print-layout'
import Event from './components/event'
import Artist from './components/artist'
import Requester from './components/requester'
import Contact from './components/contact'
import EventDetails from './components/event-details'
import Pay from './components/pay'
import Included from './components/included'
import Terms from './components/terms'
import Legal from './components/legal'
import Notice from './components/notice'

const GigRequestQuotePrint = ({
  acceptedTime,
  previewMode,
  invoicePreviewInfo,
  invoiceOrganization,
  invoiceName,
  invoiceAddress,
  invoiceZip,
  invoiceCity,
  invoiceReference,
  contactName,
  contactEmail,
  contactPhone,
  audienceCount,
  artistName,
  artistPreviewInfo,
  artistContactName,
  artistContactPhone,
  artistContactEmail,
  artistInvoiceName,
  artistInvoiceAddress,
  artistInvoiceZip,
  artistInvoiceCity,
  artistOrganizationNumber,
  venue,
  city,
  startTime,
  endTime,
  pauseCount,
  pauseDuration,
  price,
  memberCount,
  included,
  terms,
  eventType,
  eventTypes,
}) => (
  <PrintLayout isPreview={previewMode}>
    <Event acceptedTime={acceptedTime} />
    <Requester
      invoicePreviewInfo={invoicePreviewInfo}
      invoiceOrganization={invoiceOrganization}
      invoiceName={invoiceName}
      invoiceAddress={invoiceAddress}
      invoiceZip={invoiceZip}
      invoiceCity={invoiceCity}
      invoiceReference={invoiceReference}
    />

    <Artist
      artistName={artistName}
      artistPreviewInfo={artistPreviewInfo}
      artistContactName={artistContactName}
      artistContactPhone={artistContactPhone}
      artistContactEmail={artistContactEmail}
      artistInvoiceName={artistInvoiceName}
      artistInvoiceAddress={artistInvoiceAddress}
      artistInvoiceZip={artistInvoiceZip}
      artistInvoiceCity={artistInvoiceCity}
      artistOrganizationNumber={artistOrganizationNumber}
    />
    <EventDetails
      artistName={artistName}
      eventType={eventType}
      eventTypes={eventTypes}
      audienceCount={audienceCount}
      venue={venue}
      city={city}
      startTime={startTime}
      endTime={endTime}
      pauseCount={pauseCount}
      pauseDuration={pauseDuration}
      price={price}
    />
    <Contact contactName={contactName} contactEmail={contactEmail} contactPhone={contactPhone} />
    <Pay price={price} />
    <Included artistName={artistName} memberCount={memberCount} included={included} />
    {terms ? <Terms terms={terms} /> : null}
    <Legal />
    <Notice />
  </PrintLayout>
)

GigRequestQuotePrint.propTypes = {
  acceptedTime: PropTypes.string,
  previewMode: PropTypes.bool,
  invoicePreviewInfo: PropTypes.string,
  invoiceOrganization: PropTypes.string,
  invoiceName: PropTypes.string,
  invoiceAddress: PropTypes.string,
  invoiceZip: PropTypes.string,
  invoiceCity: PropTypes.string,
  invoiceReference: PropTypes.string,
  contactName: PropTypes.string.isRequired,
  contactEmail: PropTypes.string.isRequired,
  contactPhone: PropTypes.string.isRequired,
  artistName: PropTypes.string.isRequired,
  artistPreviewInfo: PropTypes.string,
  artistContactName: PropTypes.string.isRequired,
  artistContactPhone: PropTypes.string.isRequired,
  artistContactEmail: PropTypes.string.isRequired,
  artistInvoiceName: PropTypes.string.isRequired,
  artistInvoiceAddress: PropTypes.string.isRequired,
  artistInvoiceZip: PropTypes.string.isRequired,
  artistInvoiceCity: PropTypes.string.isRequired,
  artistOrganizationNumber: PropTypes.string,
  eventType: PropTypes.shape({}).isRequired,
  eventTypes: PropTypes.shape({}).isRequired,
  audienceCount: PropTypes.number.isRequired,
  venue: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  pauseCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  pauseDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  memberCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  included: PropTypes.arrayOf(PropTypes.string).isRequired,
  terms: PropTypes.string,
}
GigRequestQuotePrint.defaultProps = {
  previewMode: false,
  invoicePreviewInfo: '',
  acceptedTime: '',
  invoiceOrganization: '',
  invoiceName: '',
  invoiceAddress: '',
  invoiceZip: '',
  invoiceCity: '',
  invoiceReference: '',
  terms: '',
  artistOrganizationNumber: '',
  artistPreviewInfo: '',
}

export default GigRequestQuotePrint
