import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import { CurrentUserShape } from '../../../models/CurrentUser'
import { GigRequestEventplannerShape } from '../../../models/GigRequest'
import {
  getMyEventplannerRequests,
  hasMyEventplannerRequestsLoaded,
  isMyEventplannerRequestsLoading,
} from '../../../stores/gig-request-eventplanner/reducer'
import {
  getMyEventplannerRequestsAction,
  getMyEventplannerRequestsCountAction,
} from '../../../stores/gig-request-eventplanner/actions'
import { getCurrentUser } from '../../../stores/CurrentUser/CurrentUserReducer'

import MetaData from '../../../components/MetaData'
import PageLoader from '../../../components/PageLoader'
import EventplannerDashboard from '../../../components/eventplanner-dashboard'

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  requests: getMyEventplannerRequests(state),
  requestsIsLoading: isMyEventplannerRequestsLoading(state),
  requestsHasLoaded: hasMyEventplannerRequestsLoaded(state),
})

const mapDispatchToProps = dispatch => ({
  getMyRequests: () => {
    dispatch(getMyEventplannerRequestsCountAction())
    dispatch(getMyEventplannerRequestsAction())
  },
})

class EventplannerDashboardRoute extends React.PureComponent {
  componentDidMount() {
    const { getMyRequests } = this.props
    getMyRequests()
  }

  render() {
    const { requestsIsLoading, currentUser, requests } = this.props
    if (requestsIsLoading) {
      return (
        <>
          <MetaData title="Laster forespørsler" description="Laster forespørsler" nopin />
          <PageLoader text="Laster forespørsler" />
        </>
      )
    }

    return (
      <>
        <MetaData
          title={`Min side for ${currentUser.name}`}
          description="Min side i Gigplanet"
          nopin
        />
        <EventplannerDashboard currentUser={currentUser} requests={requests} />
      </>
    )
  }
}

EventplannerDashboardRoute.propTypes = {
  currentUser: CurrentUserShape.isRequired,
  requests: PropTypes.arrayOf(GigRequestEventplannerShape),
  requestsIsLoading: PropTypes.bool.isRequired,
  getMyRequests: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EventplannerDashboardRoute)
