import * as React from 'react'
import PropTypes from 'prop-types'
import StyleButton from './style-button'

import { FormatBoldIcon, FormatItalicIcon } from '../../../../../icons'
import { Controls } from './style'

const INLINE_STYLES = [
  { label: 'Bold', icon: <FormatBoldIcon />, style: 'BOLD' },
  { label: 'Italic', icon: <FormatItalicIcon />, style: 'ITALIC' },
]

const propTypes = {
  editorState: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onToggle: PropTypes.func.isRequired,
}

const EditorInlineStyleControls = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle()
  return (
    <Controls>
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          icon={type.icon}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </Controls>
  )
}

EditorInlineStyleControls.propTypes = propTypes

export default EditorInlineStyleControls
