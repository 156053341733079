import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

export const StyledFormAction = styled.div`
  margin: 15px 0 0 0;
  text-align: left;

  & > * {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  ${({ center }) =>
    center &&
    css`
      display: flex;
      justify-content: center;
    `};
`

const FormAction = ({ children, center = false }) => (
  <StyledFormAction center={center}>{children}</StyledFormAction>
)

FormAction.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  center: PropTypes.bool,
}

export default FormAction
