import styled from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'

export const Message = styled.pre`
  font-size: 16px;
  margin: 0;
  line-height: 24px;
  font-weight: 300;
  text-align: left;
  font-family: inherit;
  white-space: pre-wrap;

  ${mediaQueries.mobile`
    font-size: 16px;
    line-height: 24px;
  `};
`
