import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const Heading = styled.h2`
  text-align: center;
  margin: 1rem auto;
  padding: 0 0.5rem;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 1.5;
  text-transform: uppercase;
  color: ${gigplanetTheme.element.default.text};
  max-width: 50rem;
  border-bottom: 1px solid ${gigplanetTheme.element.default.border};
  width: 100%;

  ${mediaQueries.mobile`
    font-size: 1.25rem;
    margin: 1rem 0;
  `};
`

const GigRequestFormSubHeading = ({ heading }) => <Heading>{heading}</Heading>

GigRequestFormSubHeading.propTypes = {
  heading: PropTypes.string.isRequired,
}

export default GigRequestFormSubHeading
