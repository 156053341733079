import * as React from 'react'
import PropTypes from 'prop-types'
import { StyledAdminBannerWarning } from './style'

const AdminWarningBanner = () => (
  <StyledAdminBannerWarning>HUSK: DU ER ADMIN. IKKE GJØR NOEN ENDRINGER!</StyledAdminBannerWarning>
)

AdminWarningBanner.propTypes = {}
AdminWarningBanner.defaultProps = {}

export default AdminWarningBanner
