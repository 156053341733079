import * as React from 'react'

import SVGWrapper from './SVGWrapper'
// TODO: fix thos icon width/height
const PauseIcon = props => (
  <SVGWrapper width="24" height="20" viewBox="0 0 24 12" {...props}>
    <path d="M11 22h-4v-20h4v20zm6-20h-4v20h4v-20z" />
  </SVGWrapper>
)

export default PauseIcon
