import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import mediaQueries from '../../styles/mediaQueries'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const SystemMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${gigplanetTheme.spacing.small} 0;
`

export const Container = styled.div`
  order: 2;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding-right: 0;
  padding-left: 0;

  ${mediaQueries.tabletPortraitDown`
    padding-right: 0;
    padding-left: 0;
  `};

  ${({ isHideUserOnMobile }) =>
    isHideUserOnMobile &&
    css`
      ${mediaQueries.mobile`
        padding-left: 0;
        padding-right: 0;
      `};
    `};

  ${({ isActiveUser }) =>
    isActiveUser &&
    css`
      order: 1;
      padding-left: 0;
      padding-right: 0;
    `};
`

export const UserWrapper = styled.div`
  order: 1;
  padding: 5px 20px 5px 0;
  width: 70px;
  display: block;

  ${mediaQueries.mobile`
    display: none;
  `};

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: none;
      padding: 15px 20px 5px 0;
      width: 45px;

      ${mediaQueries.mobile`
        display: block;
      `};
    `};

  ${({ isActiveUser }) =>
    isActiveUser &&
    `
    order: 2;
    padding: 5px 0 5px 20px;
    
  `};
  ${({ isMobile, isActiveUser }) =>
    isMobile &&
    isActiveUser &&
    css`
      padding: 15px 0 5px 20px;
    `};

  ${({ isHideUserOnMobile }) =>
    isHideUserOnMobile &&
    css`
      ${mediaQueries.mobile`
        display: none;
      `};
    `};
`

export const Time = styled.div`
  font-weight: 400;
  text-align: left;
  color: ${rgba(gigplanetTheme.element.default.text, 0.7)};
  padding-top: 2px;
  font-size: 14px;

  ${mediaQueries.mobile`
    font-size: 12px;
    font-weight: 300;
  `};
`

export const Content = styled.div`
  width: 100%;
  border: 1px solid ${gigplanetTheme.element.timeline.border};
  background-color: ${gigplanetTheme.element.timeline.background};
  color: ${gigplanetTheme.element.timeline.text};
  padding: 15px;
  position: relative;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &:before {
    left: -10px;
    top: 20px;
    border: 10px solid transparent;
    border-left: 0;
    border-right-color: ${gigplanetTheme.element.timeline.border};
    content: '';
    display: inline-block;
    position: absolute;
  }

  &:after {
    left: -9px;
    top: 21px;
    border: 9px solid transparent;
    border-left: 0;
    border-right-color: ${gigplanetTheme.element.timeline.background};
    content: '';
    display: inline-block;
    position: absolute;
  }

  ${({ isActiveUser }) =>
    isActiveUser &&
    css`
      background-color: ${gigplanetTheme.element.timeline.activeBackground};

      &:before {
        border: 10px solid transparent;
        border-left-color: ${gigplanetTheme.element.timeline.border};
        border-right: 0;
        left: auto;
        right: -10px;
      }

      &:after {
        border: 9px solid transparent;
        border-left-color: ${gigplanetTheme.element.timeline.activeBackground};
        border-right: 0;
        left: auto;
        right: -9px;
      }
    `};

  ${({ isNoUser }) =>
    isNoUser &&
    css`
      &:after {
        border: 0;
      }

      &:before {
        border: 0;
      }
    `};

  ${({ isHideUserOnMobile }) =>
    isHideUserOnMobile &&
    css`
      ${mediaQueries.mobile`
        &:after {
          border: 0;
        }

        &:before {
          border: 0;
        }
      `};
    `};

  ${mediaQueries.mobile`
    padding: 10px;
  `};
`
