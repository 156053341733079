import mod11OfNumberWithControlDigit from './mod11-of-number-with-control-digit'

const validateOrganizationNumber = (orgNumber = '') => {
  if (!orgNumber || orgNumber.length !== 9) {
    return false
  }
  return (
    parseInt(orgNumber.charAt(orgNumber.length - 1), 10) ===
    mod11OfNumberWithControlDigit(orgNumber)
  )
}

export default validateOrganizationNumber
