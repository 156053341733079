import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const LoaderWrapper = styled.div`
  padding: ${gigplanetTheme.spacing.large};
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.small};
  `};
`

export const EmptyText = styled.p`
  padding: ${gigplanetTheme.spacing.large};
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;

  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.small};
  `};
`

export const Timeline = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Item = styled.li`
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }
`
