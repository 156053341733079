import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const Card = styled(Link)`
  background: ${gigplanetTheme.color.white};
  border: 1px solid ${gigplanetTheme.color.purple};
  color: ${gigplanetTheme.color.darkGray};
  border-radius: 2px;
  width: 280px;
  display: block;
  padding: 15px;
  cursor: pointer;
  text-decoration: none;

  ${mediaQueries.mobile`
    width: 100%;
  `};
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`

export const Info = styled.div`
  margin-left: 10px;
  overflow: hidden;
`
export const Heading = styled.h2`
  line-height: 20px;
  height: 20px;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Slogan = styled.p`
  font-size: 12px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  height: 20px;
`

export const Members = styled.p`
  font-size: 12px;
  text-align: left;
  line-height: 20px;
  height: 20px;
  margin: 0;
`

export const Ratings = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  height: 20px;
  margin: 0;
`

export const Stars = styled.div`
  height: 20px;
`

export const Reviews = styled.div`
  height: 20px;
  padding-left: 5px;
  text-align: left;
  font-size: 12px;
  width: 30px;
`
