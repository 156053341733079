import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import GigRequestQuotePrint from '../../../../../components/gig-request-quote-print'
import {
  getQuoteSelector,
  getOneEventplannerRequestForArtist,
} from '../../../../../stores/gig-request-eventplanner/reducer'
import { getEventTypes } from '../../../../../stores/Options/OptionsReducer'
import PageLoader from '../../../../../components/PageLoader'
import AccessDenied from '../../../../../components/AccessDenied'

const mapStateToProps = state => ({
  quote: getQuoteSelector(state),
  gigRequest: getOneEventplannerRequestForArtist(state),
  eventTypes: getEventTypes(state),
})

const mapDispatchToProps = dispatch => ({})

class PrintSignedQuoteRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    const {
      eventTypes,
      gigRequest,
      quote,
      history: { location },
    } = this.props
    const query = queryString.parse(location.search)

    if (!gigRequest || !quote) return <PageLoader text="Laster kontrakt" />

    let { artist } = gigRequest
    if (!eventTypes || !artist || !artist.artist) return <PageLoader text="Laster kontrakt" />

    artist = artist.artist

    if (query.gigRequestId !== gigRequest.id) {
      return <AccessDenied />
    }

    return (
      <GigRequestQuotePrint
        acceptedTime={quote.acceptedTime || ''}
        invoiceOrganization={quote.invoice.organization}
        invoiceName={quote.invoice.name}
        invoiceAddress={quote.invoice.address}
        invoiceZip={quote.invoice.zip}
        invoiceCity={quote.invoice.city}
        invoiceReference={quote.invoice.reference}
        contactName={quote.contact.name}
        contactEmail={quote.contact.email}
        contactPhone={quote.contact.phone}
        artistName={artist.name}
        artistContactName={artist.contactInfo ? artist.contactInfo.contactName : ''}
        artistContactPhone={artist.contactInfo ? artist.contactInfo.contactPhone : ''}
        artistContactEmail={artist.contactInfo ? artist.contactInfo.contactEmail : ''}
        artistInvoiceName={artist.invoiceInfo ? artist.invoiceInfo.invoiceName : ''}
        artistInvoiceAddress={artist.invoiceInfo ? artist.invoiceInfo.invoiceAddress : ''}
        artistInvoiceZip={artist.invoiceInfo ? artist.invoiceInfo.invoiceZip : ''}
        artistInvoiceCity={artist.invoiceInfo ? artist.invoiceInfo.invoiceCity : ''}
        artistOrganizationNumber={artist.invoiceInfo ? artist.invoiceInfo.organizationNumber : ''}
        eventTypes={eventTypes}
        eventType={gigRequest.eventType}
        audienceCount={gigRequest.audienceCount}
        venue={gigRequest.venue}
        city={gigRequest.city}
        startTime={gigRequest.startTime}
        endTime={gigRequest.endTime}
        pauseCount={quote.pauseCount || 0}
        pauseDuration={quote.pauseDuration || 0}
        price={quote.price || 0}
        memberCount={quote.members || 1}
        included={gigRequest.included}
        terms={quote.terms || ''}
      />
    )
  }
}

PrintSignedQuoteRoute.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  }).isRequired,

  // state props
  quote: PropTypes.shape({}),
  gigRequest: PropTypes.shape({}),
  eventTypes: PropTypes.shape({}),
}
PrintSignedQuoteRoute.defaultProps = {}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PrintSignedQuoteRoute)
