import { artistSearchOrderBy } from '../../../../models/Artist'

export const getParams = (location, eventType, category, genre, sortField) => {
  const tmpParams = {}
  if (location) {
    tmpParams.location = location
  }
  if (eventType) {
    tmpParams.eventType = eventType
  }
  if (category) {
    tmpParams.category = category
  }

  if (genre) {
    tmpParams.genre = genre
  }

  if (sortField) {
    tmpParams.sortField = sortField
    const idx = artistSearchOrderBy.findIndex(order => order.sortField === sortField)
    if (idx !== -1) {
      tmpParams.sortDir = artistSearchOrderBy[idx].sortDir
    }
  }
  return tmpParams
}
