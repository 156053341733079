import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

const Container = styled.div`
  height: 21px;
  overflow: hidden;
  width: 100%;
  text-align: center;
  position: relative;
`

const Content = styled.span`
  display: none;
  padding: 0 15px;
  position: relative;

  ${mediaQueries.mobile`
    padding: 0 10px;
  `};

  ${({ hasText }) =>
    hasText &&
    css`
      display: inline-block;
    `};

  &:before {
    content: '';
    position: absolute;
    width: 5000px;
    background: ${gigplanetTheme.component.textSeparator.border};
    height: 1px;
    font-size: 1px;
    top: 50%;
    right: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    width: 5000px;
    background: ${gigplanetTheme.component.textSeparator.border};
    height: 1px;
    font-size: 1px;
    top: 50%;
    left: 100%;
  }
`

const HR = styled.hr`
  position: absolute;
  width: 5000px;
  background: ${gigplanetTheme.component.textSeparator.border};
  height: 1px;
  font-size: 1px;
  top: 50%;
  left: 0;
  right: 0;
  border: 0;
`

const Text = styled.span`
  color: ${gigplanetTheme.component.textSeparator.text};
  line-height: 24px;
  font-size: 16px;
  font-weight: 300;

  ${mediaQueries.mobile`
    font-size: 14px;
    line-height: 21px;
  `};

  ${({ isUppercase }) =>
    isUppercase &&
    css`
      text-transform: uppercase;
    `};
`

const TextSeparator = ({ text, uppercase, showFlag, hoverText }) => (
  <Container>
    {text || showFlag ? (
      <Content hasText={text}>
        <Text isUppercase={uppercase} title={hoverText}>
          {text}
        </Text>
      </Content>
    ) : (
      <HR />
    )}
  </Container>
)

TextSeparator.propTypes = {
  text: PropTypes.string,
  hoverText: PropTypes.string,
  showFlag: PropTypes.bool,
  uppercase: PropTypes.bool,
}

TextSeparator.defaultProps = {
  text: '',
  hoverText: '',
  showFlag: false,
  uppercase: false,
}

export default TextSeparator
