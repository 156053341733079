import * as React from 'react'
import PropTypes from 'prop-types'
import { Container, Content, List, Item, ListLink, ListButton, Intro, LinkText } from './style'
import { trackControlpanelHelpdesk, chatWithUs, trackEmailUs } from './utils'
import { H1, H2 } from '../../ui/Headings'
import { EmailOutlineIcon, SupportChatIcon, LightbulbIcon } from '../../icons'

export const EVENTPLANNER = 'EVENTPLANNER'
export const CONTROLPANEL = 'CONTROLPANEL'
export const EVERYONE = 'EVERYONE'

const Help = ({ audience }) => {
  let heading = 'Hjelp'
  let url = 'http://hjelp.gigplanet.no'
  let trackingPrefix = ''

  switch (audience) {
    case CONTROLPANEL: {
      heading = 'Hjelp for artister'
      url = 'http://hjelp.gigplanet.no/for-artister'
      trackingPrefix = 'controlpanel-'
      break
    }
    case EVENTPLANNER: {
      heading = 'Hjelp for arrangører'
      url = 'http://hjelp.gigplanet.no/for-arrangorer-og-arrangementer'
      trackingPrefix = 'eventplanner-'
      break
    }
    default:
      break
  }
  return (
    <Container>
      <Content>
        <H1>{heading}</H1>
        <List>
          <Item>
            <ListLink
              onClick={() => trackControlpanelHelpdesk(trackingPrefix)}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LightbulbIcon />
              <H2>Hjelpesenter</H2>
              <Intro>Finn svarene du leter etter i vårt hjelpesenter</Intro>
              <LinkText>Gå til hjelpesenteret</LinkText>
            </ListLink>
          </Item>
          <Item>
            <ListButton type="button" onClick={() => chatWithUs(trackingPrefix)}>
              <SupportChatIcon />
              <H2>Chat med oss</H2>
              <Intro>Hvis vi ikke er online, får vi varsel og kontakter deg så fort vi kan</Intro>
              <LinkText>Start chat</LinkText>
            </ListButton>
          </Item>
          <Item>
            <ListLink
              href="mailto:support@gigplanet.no"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => trackEmailUs(trackingPrefix)}
            >
              <EmailOutlineIcon />
              <H2>Kontakt Gigplanet</H2>
              <Intro>Ring oss på telefon 906 40 887 eller send oss en e-post</Intro>
              <LinkText>support@gigplanet.no</LinkText>
            </ListLink>
          </Item>
        </List>
      </Content>
    </Container>
  )
}

Help.propTypes = {
  audience: PropTypes.oneOf([EVENTPLANNER, CONTROLPANEL, EVERYONE]).isRequired,
}

export default Help
