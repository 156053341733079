import * as React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, withRouter } from 'react-router'

import { ArtistList, Artist } from './style'
import Loader from '../../components/PageLoader'
import EventplannerInboxItem from '../../components/EventplannerInboxItem'
import GigRequestEventplannerDetailsSidebar from '../../containers/GigRequestEventplannerDetailsSidebar'
import GigRequestRequestDetailsEventplanner from '../../containers/gig-request-details-eventplanner'
import GigReQuestInboxWrapper from '../../components/gig-request-details-wrapper'
import { QUOTE_STATUS } from '../../models/GigRequest'

const GigRequestInboxEventplanner = ({
  isLoading,
  hasLoaded,
  eventplannerRequest,
  eventTypes,
  gigRequestId,
}) => {
  if (isLoading || !hasLoaded || gigRequestId !== eventplannerRequest.id) {
    return <Loader text="Laster innboks" />
  }

  if (!eventplannerRequest) {
    return <div className="EventplannerInboxRequest__empty">Du har ingen forespørsler.</div>
  }

  const idx = (eventplannerRequest.artists || []).findIndex(
    artist => artist && artist.quote && artist.quote.status === QUOTE_STATUS.ACCEPTED,
  )
  return (
    <GigReQuestInboxWrapper
      renderSidebar={() => (
        <GigRequestEventplannerDetailsSidebar
          eventplannerRequest={eventplannerRequest}
          isLoading={isLoading}
          hasLoaded={hasLoaded}
          eventTypes={eventTypes}
        />
      )}
    >
      <ArtistList>
        {(eventplannerRequest.artists || []).map((artistInfo, index) => (
          <Artist key={artistInfo.artist.id}>
            <EventplannerInboxItem
              request={eventplannerRequest}
              booked={index === idx}
              artistInfo={artistInfo}
              eventTypes={eventTypes}
            />
          </Artist>
        ))}
      </ArtistList>
    </GigReQuestInboxWrapper>
  )
}

GigRequestInboxEventplanner.propTypes = {
  gigRequestId: PropTypes.string.isRequired,
  eventplannerRequest: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default withRouter(GigRequestInboxEventplanner)
