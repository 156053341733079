import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import gigplanetTheme from '../../styles/gigplanetTheme'

const Container = styled.span`
  height: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${({ isSmall }) =>
    isSmall &&
    css`
      height: 12px;
    `};

  ${({ isMedium }) =>
    isMedium &&
    css`
      height: 14px;
    `};

  ${({ isLarge }) =>
    isLarge &&
    css`
      height: 18px;
    `};
`

const Star = styled.span`
  position: relative;
  height: 14px;
  width: 14px;
  margin-right: 2px;

  &:last-child {
    margin-right: 0;
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      height: 12px;
      width: 12px;
      margin-right: 2px;
    `};

  ${({ isMedium }) =>
    isMedium &&
    css`
      height: 14px;
      width: 14px;
      margin-right: 2px;
    `};

  ${({ isLarge }) =>
    isLarge &&
    css`
      height: 18px;
      width: 18px;
      margin-right: 2px;
    `};
`

const IconWrapper = styled.span`
  position: absolute;

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      color: ${gigplanetTheme.component.ratingStars.empty};
    `};

  ${({ isFilled }) =>
    isFilled &&
    css`
      overflow: hidden;
      color: ${gigplanetTheme.component.ratingStars.filled};
      path {
        stroke: ${gigplanetTheme.component.ratingStars.filledBorder};
      }
    `};
`

const RatingStars = ({ size, rating }) => {
  let sizeValue = '14px'
  let isSmall = false
  let isMedium = false
  let isLarge = false
  switch (size) {
    case 'small':
      sizeValue = '12px'
      isSmall = true
      break
    case 'medium':
      sizeValue = '14px'
      isMedium = true
      break
    case 'large':
      sizeValue = '18px'
      isLarge = true
      break
    default:
      sizeValue = '14px'
  }
  const ratings = []
  let width = ''
  let percentage = 0
  for (let i = 1; i <= 5; i += 1) {
    if (i <= rating) {
      width = '100%'
    } else if (i - 1 < rating) {
      percentage = (rating - (i - 1)) * 100
      width = `${percentage}%`
    } else {
      width = '0%'
    }
    ratings.push(
      <Star key={`rating_${i}`} isSmall={isSmall} isMedium={isMedium} isLarge={isLarge}>
        <IconWrapper isEmpty>
          <svg
            role="presentation"
            aria-hidden="true"
            focusable="false"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            style={{
              display: 'block',
              fill: 'currentcolor',
              height: sizeValue,
              width: sizeValue,
            }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 0L5.348 5.086H0L4.326 8.229L2.674 13.315L7 10.172L11.326 13.315L9.674 8.229L14 5.086H8.652L7 0Z"
              fill="#C0C0C0"
            />
          </svg>
        </IconWrapper>
        <IconWrapper isFilled style={{ width }}>
          <svg
            role="presentation"
            aria-hidden="true"
            focusable="false"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            style={{
              display: 'block',
              fill: 'currentcolor',
              height: sizeValue,
              width: sizeValue,
            }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.348 5.586H5.71131L5.82354 5.24046L7 1.61851L8.17646 5.24046L8.28869 5.586H8.652H12.4611L9.38011 7.82449L9.08624 8.03799L9.19846 8.38346L10.3752 12.0061L7.29389 9.76749L7 9.55397L6.70611 9.76749L3.62485 12.0061L4.80154 8.38346L4.91376 8.03799L4.61989 7.82449L1.53885 5.586H5.348Z"
              fill="#32E08E"
              stroke="#00AA5A"
            />
          </svg>
        </IconWrapper>
      </Star>,
    )
  }
  return (
    <Container isSmall={isSmall} isMedium={isMedium} isLarge={isLarge}>
      {ratings}
    </Container>
  )
}

RatingStars.propTypes = {
  rating: PropTypes.number.isRequired,
  size: PropTypes.string,
}

RatingStars.defaultProps = {
  size: 'small',
}

export default RatingStars
