import * as React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

import Layout from '../../layouts/main-layout'
import SystemMessageWrapper from '../../ui/system-message-wrapper'
import UserAuthWindow from '../../containers/user-auth-window'
import MetaData from '../../components/MetaData'

const RegisterRoute = React.memo(() => {
  const history = useHistory()
  const location = useLocation()
  let url = '/'
  let errorCode = ''
  const query = queryString.parse(location.search)
  if (query && query.url) {
    url = query.url
  }
  if (query && query.errorCode) {
    errorCode = query.errorCode
  } else if (query && query.error) {
    errorCode = query.error
  }

  const handleComplete = () => {}

  return (
    <Layout showSearch>
      <MetaData
        title="Registrer deg hos Gigplanet"
        description="Gigplanet hjelper deg å finne live underholdning til ditt arrangement, samtidig som vi hjelper artister å få flere spillejobber"
      />
      <SystemMessageWrapper>
        <UserAuthWindow
          errorCode={errorCode}
          showRegister
          successUrl={url}
          onClose={() => {
            history.push('/')
          }}
        />
      </SystemMessageWrapper>
    </Layout>
  )
})

export default RegisterRoute
