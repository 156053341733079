import * as React from 'react'
import PropTypes from 'prop-types'

// http://dev.gigplanet.no:8080/band/test7#repertoar
import {
  sortRepSongByArtist,
  sortRepSongByArtistDesc,
  sortRepSongBySong,
  sortRepSongBySongDesc,
  sortRepSongByYear,
  sortRepSongByYearDesc,
} from '../../../../helpers/Repertoire'
import Heading from '../../../ProfileDetailsHeading/ProfileDetailsHeading'
import Button from '../../../../ui/Button'

import {
  Container,
  ProfileDetailsRepertoireSection,
  ProfileDetailsRepertoireRow,
  ProfileDetailsRepertoireOrderby,
  ProfileDetailsRepertoireOrderbyHeading,
  ProfileDetailsRepertoireOrderbyButton,
  ProfileDetailsRepertoireOrderbyItem,
  ProfileDetailsRepertoireWrapper,
  ProfileDetailsRepertoireColumn,
  ProfileDetailsRepertoireList,
  ProfileDetailsRepertoireListItem,
  ProfileDetailsRepertoireShowmore,
  ProfileDetailsRepertoireIntro,
  ProfileDetailsRepertoireMedleys,
} from './style'

const SPLIT = 10
const SHOW_MORE_LIMIT = 20

const orderBys = [
  {
    name: 'Artist',
    id: 'artist',
  },
  {
    name: 'Sang',
    id: 'song',
  },
  {
    name: 'Utgivelsesår',
    id: 'year',
  },
]

class ProfileDetailsRepertoire extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      orderBy: 'artist',
      sortBy: 'asc',
      showMore: false,
    }
  }

  handleToggleShowMore = () => {
    const { showMore } = this.state
    this.setState({
      showMore: !showMore,
    })
  }

  handleOrderByChange = id => {
    const { orderBy, sortBy } = this.state
    const newSortBy = orderBy === id && sortBy === 'asc' ? 'desc' : 'asc'
    this.setState({
      orderBy: id,
      sortBy: newSortBy,
      showMore: true,
    })
  }

  render() {
    const { intro, showIntro, repertoire, containerId } = this.props
    const { orderBy, sortBy, showMore } = this.state
    const repertoires = []
    let sortedRepertoire = []
    if (repertoire) {
      /* TODO: Should this be done in a selector? */
      switch (orderBy) {
        case 'artist':
          sortedRepertoire = [...repertoire].sort(
            sortBy === 'asc' ? sortRepSongByArtist : sortRepSongByArtistDesc,
          )
          break
        case 'song':
          sortedRepertoire = [...repertoire].sort(
            sortBy === 'asc' ? sortRepSongBySong : sortRepSongBySongDesc,
          )
          break
        case 'year':
          sortedRepertoire = [...repertoire].sort(
            sortBy === 'asc' ? sortRepSongByYear : sortRepSongByYearDesc,
          )
          break
        default:
          sortedRepertoire = [...repertoire].sort(
            sortBy === 'asc' ? sortRepSongByArtist : sortRepSongByArtistDesc,
          )
      }

      const list = sortedRepertoire.map((rep, index) => {
        if (!rep) return null
        let content = ''
        const artist = <span itemProp="name">{rep.artist}</span>
        const song = (
          <span itemProp="byArtist" itemScope itemType="http://schema.org/MusicGroup">
            <span itemProp="name">{rep.song}</span>
          </span>
        )
        switch (orderBy) {
          case 'artist':
            content = (
              <span itemProp="item" itemScope itemType="http://schema.org/MusicRecording">
                {artist} - {song} - {rep.year}
              </span>
            )
            break
          case 'song':
            content = (
              <span itemProp="item" itemScope itemType="http://schema.org/MusicRecording">
                {song} - {artist} - {rep.year}
              </span>
            )
            break
          case 'year':
            content = (
              <span itemProp="item" itemScope itemType="http://schema.org/MusicRecording">
                {rep.year} - {song} - {artist}
              </span>
            )
            break
          default:
            content = (
              <span itemProp="item" itemScope itemType="http://schema.org/MusicRecording">
                {artist} - {song} - {rep.year}
              </span>
            )
        }
        return (
          <ProfileDetailsRepertoireListItem
            key={`${rep.artistId}-${rep.songId}`}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
          >
            {content}
            <meta itemProp="position" content={index} />
          </ProfileDetailsRepertoireListItem>
        )
      })

      // If the list is bigger than SPLIT, split the list in two.
      let half = parseInt(list.length / 2, 10)
      half = half === list.length / 2 ? half : half + 1
      const split = list.length > SPLIT ? half : list.length
      for (let i = 0; i < list.length; i += split) {
        repertoires.push(list.slice(i, i + split))
      }
    }
    return (
      <ProfileDetailsRepertoireSection
        id={containerId}
        itemScope
        itemType="http://schema.org/ItemList http://schema.org/CreativeWork"
      >
        {repertoires.length > 0 ? (
          <Container>
            <ProfileDetailsRepertoireRow>
              <Heading>Repertoar</Heading>
              <meta itemProp="name" content="Repertoar" />
            </ProfileDetailsRepertoireRow>
            <ProfileDetailsRepertoireRow>
              <ProfileDetailsRepertoireOrderby>
                <ProfileDetailsRepertoireOrderbyHeading>
                  Sortering:
                </ProfileDetailsRepertoireOrderbyHeading>
                {orderBys.map(ob => (
                  <ProfileDetailsRepertoireOrderbyItem key={ob.id}>
                    <ProfileDetailsRepertoireOrderbyButton
                      type="button"
                      isSelected={ob.id === orderBy}
                      onClick={e => {
                        e.preventDefault()
                        this.handleOrderByChange(ob.id)
                      }}
                    >
                      {ob.name}
                    </ProfileDetailsRepertoireOrderbyButton>
                  </ProfileDetailsRepertoireOrderbyItem>
                ))}
              </ProfileDetailsRepertoireOrderby>
            </ProfileDetailsRepertoireRow>
            <ProfileDetailsRepertoireRow>
              <ProfileDetailsRepertoireWrapper
                hasMore={sortedRepertoire.length > SHOW_MORE_LIMIT && !showMore}
              >
                {repertoires.map((r, i) => (
                  <ProfileDetailsRepertoireColumn
                    isSingle={repertoires.length === 1}
                    key={i === 1 ? 'column1' : 'column2'}
                  >
                    <ProfileDetailsRepertoireList isSingle={repertoires.length === 1}>
                      {r}
                    </ProfileDetailsRepertoireList>
                  </ProfileDetailsRepertoireColumn>
                ))}
              </ProfileDetailsRepertoireWrapper>
            </ProfileDetailsRepertoireRow>
            {sortedRepertoire.length > SHOW_MORE_LIMIT && !showMore ? (
              <ProfileDetailsRepertoireRow>
                <ProfileDetailsRepertoireShowmore>
                  <Button type="button" theme="outline" onClick={this.handleToggleShowMore}>
                    Vis hele repertoaret
                  </Button>
                </ProfileDetailsRepertoireShowmore>
              </ProfileDetailsRepertoireRow>
            ) : null}
          </Container>
        ) : null}

        {showIntro && intro.replace(/<\/?[^>]+(>|$)/g, '') ? (
          <ProfileDetailsRepertoireMedleys hasSpacing={repertoires.length > 0}>
            <ProfileDetailsRepertoireRow>
              <Heading>Medleys</Heading>
            </ProfileDetailsRepertoireRow>
            <ProfileDetailsRepertoireRow>
              <ProfileDetailsRepertoireIntro
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: intro,
                }}
              />
            </ProfileDetailsRepertoireRow>
          </ProfileDetailsRepertoireMedleys>
        ) : null}
      </ProfileDetailsRepertoireSection>
    )
  }
}

ProfileDetailsRepertoire.propTypes = {
  containerId: PropTypes.string.isRequired,
  repertoire: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  showIntro: PropTypes.bool.isRequired,
  intro: PropTypes.string,
}

ProfileDetailsRepertoire.defaultProps = {
  intro: '',
  repertoire: [],
}

export default ProfileDetailsRepertoire
