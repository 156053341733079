import styled from 'styled-components'

import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const Details = styled.h2`
  padding-top: 20px;
  font-size: 20px;
  font-weight: bold;
`

export const LoaderWrapper = styled.div`
  padding-top: 20px;
`

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 100%;
`
