import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { validateForgotPassword } from '../../models/Auth'

import {
  updateForgotPasswordFormAction,
  sendForgotPasswordAction,
} from '../../stores/Auth/AuthActions'
import {
  getForgotPasswordForm,
  getForgotPasswordIsSending,
  getForgotPasswordHasSent,
  getForgotPasswordErrorMessages,
} from '../../stores/Auth/AuthReducer'

import UserAuthForgotPasswordConfirmation from '../../components/user-auth-forgot-password-confirmation'
import ForgotPasswordForm from './components/user-auth-forgot-password-form'

const mapStateToProps = state => ({
  form: getForgotPasswordForm(state),
  isSending: getForgotPasswordIsSending(state),
  hasSent: getForgotPasswordHasSent(state),
  serverErrorMessages: getForgotPasswordErrorMessages(state),
})

const mapDispatchToProps = dispatch => ({
  updateForm: form => {
    dispatch(updateForgotPasswordFormAction(form))
  },
  sendForgotPassword: () => {
    dispatch(sendForgotPasswordAction())
  },
})

class UserAuthForgotPasswordFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessages: [],
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { serverErrorMessages } = this.props
    if (serverErrorMessages !== nextProps.serverErrorMessages) {
      this.setState({
        errorMessages: nextProps.serverErrorMessages,
      })
    }
  }

  handleSubmit = e => {
    const { sendForgotPassword, form } = this.props
    e.preventDefault()
    const errorMessages = validateForgotPassword(form)
    if (errorMessages.length === 0) {
      sendForgotPassword()
    } else {
      this.setState({
        errorMessages,
      })
    }
  }

  handleChangeEmail = e => {
    const { updateForm, form } = this.props
    updateForm({
      ...form,
      email: e.currentTarget.value,
    })
  }

  render() {
    const {
      hasSent,
      form,
      onClose,
      hideHeading,
      onShowLoginForm,
      isSending,
      serverErrorMessages,
    } = this.props
    const { errorMessages } = this.state
    if (hasSent) {
      return <UserAuthForgotPasswordConfirmation email={form.email} onClose={onClose} />
    }
    return (
      <ForgotPasswordForm
        hideHeading={hideHeading}
        onShowLoginForm={onShowLoginForm}
        onSubmit={this.handleSubmit}
        email={form.email}
        onChangeEmail={this.handleChangeEmail}
        isSending={isSending}
        errorMessages={errorMessages}
        serverError={serverErrorMessages.length > 0}
      />
    )
  }
}

UserAuthForgotPasswordFormContainer.propTypes = {
  hideHeading: PropTypes.bool,
  onShowLoginForm: PropTypes.func,
  form: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
  sendForgotPassword: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
  hasSent: PropTypes.bool.isRequired,
  serverErrorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
  ),
  onClose: PropTypes.func.isRequired,
}

UserAuthForgotPasswordFormContainer.defaultProps = {
  hideHeading: false,
  onShowLoginForm: null,
  serverErrorMessages: [],
}

const UserAuthForgotPasswordFormContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAuthForgotPasswordFormContainer)

export default UserAuthForgotPasswordFormContainerWrapper
