import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { validateRegisterUser } from '../../models/Auth'
import { updateRegisterFormAction, createUserAction } from '../../stores/Auth/AuthActions'
import {
  getRegisterForm,
  getRegisterIsSaving,
  getRegisterHasSaved,
  getRegisterErrorMessages,
} from '../../stores/Auth/AuthReducer'

import UserAuthRegisterConfirmation from '../../components/user-auth-register-confirmation'
import UserAuthRegisterForm from './components/user-auth-register-form'

const mapStateToProps = state => ({
  form: getRegisterForm(state),
  isSaving: getRegisterIsSaving(state),
  hasSaved: getRegisterHasSaved(state),
  serverErrorMessages: getRegisterErrorMessages(state),
})

const mapDispatchToProps = dispatch => ({
  updateForm: form => {
    dispatch(updateRegisterFormAction(form))
  },
  createUser: successUrl => {
    dispatch(createUserAction(successUrl))
  },
})

class UserAuthRegisterFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessages: [],
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { serverErrorMessages } = this.props
    if (serverErrorMessages !== nextProps.serverErrorMessages) {
      this.setState({
        errorMessages: nextProps.serverErrorMessages,
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { form, successUrl, createUser } = this.props
    const errorMessages = validateRegisterUser(form)
    if (errorMessages.length === 0) {
      createUser(successUrl)
    } else {
      this.setState({
        errorMessages,
      })
    }
  }

  handleChangeFirstName = e => {
    const { form, updateForm } = this.props
    updateForm({
      ...form,
      firstName: e.currentTarget.value,
    })
  }

  handleChangeLastName = e => {
    const { form, updateForm } = this.props
    updateForm({
      ...form,
      lastName: e.currentTarget.value,
    })
  }

  handleChangeEmail = e => {
    const { form, updateForm } = this.props
    updateForm({
      ...form,
      email: e.currentTarget.value,
    })
  }

  handleChangePassword = e => {
    const { form, updateForm } = this.props
    updateForm({
      ...form,
      password: e.currentTarget.value,
    })
  }

  render() {
    const {
      hasSaved,
      onShowLoginForm,
      isSaving,
      hideHeading,
      successUrl,
      form,
      serverErrorMessages,
      onClose,
    } = this.props
    const { errorMessages } = this.state
    if (hasSaved) {
      return <UserAuthRegisterConfirmation onClose={onShowLoginForm} />
    }

    return (
      <UserAuthRegisterForm
        isSubmitting={isSaving}
        hideHeading={hideHeading}
        successUrl={successUrl}
        onSubmit={this.handleSubmit}
        firstName={form.firstName}
        onChangeFirstName={this.handleChangeFirstName}
        lastName={form.lastName}
        onChangeLastName={this.handleChangeLastName}
        email={form.email}
        onChangeEmail={this.handleChangeEmail}
        password={form.password}
        onChangePassword={this.handleChangePassword}
        errorMessages={errorMessages}
        serverError={serverErrorMessages.length > 0}
        onShowLoginForm={onShowLoginForm}
        onClose={onClose}
      />
    )
  }
}

UserAuthRegisterFormContainer.propTypes = {
  form: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  hideHeading: PropTypes.bool,
  successUrl: PropTypes.string,
  onShowLoginForm: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  hasSaved: PropTypes.bool.isRequired,
  serverErrorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
  ),
}

UserAuthRegisterFormContainer.defaultProps = {
  hideHeading: false,
  successUrl: '/',
  serverErrorMessages: [],
}

const UserAuthRegisterFormContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAuthRegisterFormContainer)

export default UserAuthRegisterFormContainerWrapper
