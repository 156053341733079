/* globals window */
import * as React from 'react'
import PropTypes from 'prop-types'

import { CONTROLPANEL_URL } from '../../../models/Controlpanel'

import { DesktopMenu, FavoriteCount } from './style'

import HeaderMenuList from '../../../ui/header-menu-list'
import HeaderMenuItem from '../../../ui/header-menu-list-item'
import HeaderMenuButton from '../../../ui/header-menu-button'
import { SearchIcon, HeartEmptyIcon } from '../../../icons'
import HeaderMenuItemUserDesktop from '../../header-menu-item-user-desktop'
import HeaderBurgerButton from '../../../ui/header-burger-button'
import SiteNavigation from '../../../components/SiteNavigation'
import RequestsMenuItem from './requests-menu-item'
import UtilityNav from '../../../components/UtilityNav'

const LoggedInDektopMenu = React.memo(
  ({
    showControlpanelButton,
    artistGigRequestCount,
    eventplannerGigRequestCount,
    onShowRequestOptions,
    showingRequestOptions,
    favoriteCount,
    hasDarkBg,
    focusCallback,
    currentUser,
    showControlpanelMenu,
    showEventplannerMenu,
  }) => {
    const [showingSiteNav, setShowSiteNav] = React.useState(false)

    React.useEffect(() => {
      focusCallback(showingSiteNav)
    }, [showingSiteNav])

    const openSiteNav = () => {
      setShowSiteNav(true)
    }

    const closeSiteNav = () => {
      setShowSiteNav(false)
    }

    const toggleSiteNav = () => {
      setShowSiteNav(!showingSiteNav)
    }

    return (
      <DesktopMenu>
        <UtilityNav
          eventplannerGigRequestCount={eventplannerGigRequestCount}
          artistGigRequestCount={artistGigRequestCount}
          onShowRequestOptions={onShowRequestOptions}
          showingRequestOptions={showingRequestOptions}
          showControlpanelButton={showControlpanelButton}
          showControlpanelMenu={showControlpanelMenu}
          showEventplannerMenu={showEventplannerMenu}
          isLoggedIn
          hasDarkBg={hasDarkBg}
          currentUse={currentUser}
          favoriteCount={favoriteCount}
        />
        <HeaderMenuList>
          <HeaderMenuItemUserDesktop hasDarkBg={hasDarkBg} />
          <HeaderMenuItem onClick={toggleSiteNav}>
            <HeaderBurgerButton
              aria-label="Hovednavigasjonsmeny"
              aria-haspopup="true"
              hasDarkBg={hasDarkBg}
              isOpen={showingSiteNav}
            />
          </HeaderMenuItem>
        </HeaderMenuList>
        <SiteNavigation isOpen={showingSiteNav} closeSiteNav={closeSiteNav} />
      </DesktopMenu>
    )
  },
)

LoggedInDektopMenu.propTypes = {
  showControlpanelButton: PropTypes.bool.isRequired,
  unreadCount: PropTypes.number.isRequired,
  favoriteCount: PropTypes.number.isRequired,
  artistGigRequestCount: PropTypes.number.isRequired,
  eventplannerGigRequestCount: PropTypes.number.isRequired,
  onShowRequestOptions: PropTypes.func.isRequired,
  showingRequestOptions: PropTypes.bool.isRequired,
  hasDarkBg: PropTypes.bool,
  focusCallback: PropTypes.func,
  currentUser: PropTypes.shape({}).isRequired,
  showControlpanelMenu: PropTypes.bool,
  showEventplannerMenu: PropTypes.bool,
}

export default LoggedInDektopMenu
