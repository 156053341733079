import styled from 'styled-components'
import gigplanetTheme from '../../../styles/gigplanetTheme'

export const HeadingWrapper = styled.div`
  margin-bottom: 25px;
  text-align: center;
`

export const ExternalLogin = styled.div`
  text-align: center;
  padding: 5px 0;
`

export const LoginLink = styled.a`
  color: ${gigplanetTheme.color.purple};
  text-decoration: none;
`

export const SeparatorWrapper = styled.div`
  padding: 15px 0;
`

export const InputWrapper = styled.div`
  padding-bottom: 10px;
`

export const Terms = styled.div`
  padding: 10px 0 20px;
  text-align: center;
`

export const ExistingAccount = styled.div`
  padding-top: 10px;
  line-height: 20px;
  text-align: center;
`
