import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { validateVerifyEmail } from '../../models/Auth'

import { updateVerifyEmailFormAction, sendVerifyEmailAction } from '../../stores/Auth/AuthActions'
import {
  getVerifyEmailForm,
  getVerifyEmailIsSending,
  getVerifyEmailHasSent,
  getVerifyEmailErrorMessages,
} from '../../stores/Auth/AuthReducer'

import UserAuthVerifyEmailConfirmation from '../../components/user-auth-verify-email-confirmation'
import VerifyEmailForm from './components/user-auth-verify-email-form'

const mapStateToProps = state => ({
  form: getVerifyEmailForm(state),
  isSending: getVerifyEmailIsSending(state),
  hasSent: getVerifyEmailHasSent(state),
  serverErrorMessages: getVerifyEmailErrorMessages(state),
})

const mapDispatchToProps = dispatch => ({
  updateForm: form => {
    dispatch(updateVerifyEmailFormAction(form))
  },
  sendVerifyEmail: () => {
    dispatch(sendVerifyEmailAction())
  },
})

class UserAuthVerifyEmailFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessages: [],
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { serverErrorMessages } = this.props
    if (serverErrorMessages !== nextProps.serverErrorMessages) {
      this.setState({
        errorMessages: nextProps.serverErrorMessages,
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { form, sendVerifyEmail } = this.props
    const errorMessages = validateVerifyEmail(form)
    if (errorMessages.length === 0) {
      sendVerifyEmail()
    } else {
      this.setState({
        errorMessages,
      })
    }
  }

  handleChangeEmail = e => {
    const { form, updateForm } = this.props
    updateForm({
      ...form,
      email: e.currentTarget.value,
    })
  }

  render() {
    const {
      form,
      hasSent,
      onClose,
      hideHeading,
      onShowLoginForm,
      isSending,
      serverErrorMessages,
    } = this.props
    const { errorMessages } = this.state
    if (hasSent) {
      return <UserAuthVerifyEmailConfirmation email={form.email} onClose={onClose} />
    }
    return (
      <VerifyEmailForm
        hideHeading={hideHeading}
        onShowLoginForm={onShowLoginForm}
        onSubmit={this.handleSubmit}
        email={form.email}
        onChangeEmail={this.handleChangeEmail}
        isSending={isSending}
        errorMessages={errorMessages}
        serverError={serverErrorMessages.length > 0}
        onClose={onClose}
      />
    )
  }
}

UserAuthVerifyEmailFormContainer.propTypes = {
  hideHeading: PropTypes.bool,
  onShowLoginForm: PropTypes.func,
  form: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
  sendVerifyEmail: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
  hasSent: PropTypes.bool.isRequired,
  serverErrorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
  ),
  onClose: PropTypes.func.isRequired,
}

UserAuthVerifyEmailFormContainer.defaultProps = {
  hideHeading: false,
  onShowLoginForm: null,
  serverErrorMessages: [],
}

const UserAuthVerifyEmailFormContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAuthVerifyEmailFormContainer)

export default UserAuthVerifyEmailFormContainerWrapper
