import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import mediaQueries from '../../../styles/mediaQueries'
import { NavigateNextIcon, NavigateBeforeIcon } from '../../../icons'

export const ProfileDetailsImageGalleryButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;

  ${mediaQueries.mobile`
    position: absolute;
  
    ${({ isLeft }) =>
      isLeft &&
      css`
        left: 0;
        top: 50%;
        transform: translateY(-35px);
      `};
  
      ${({ isRight }) =>
        isRight &&
        css`
          right: 0;
          top: 50%;
          transform: translateY(-35px);
        `};
  `};
`

export const ProfileDetailsImageGalleryButtonNavButton = styled.button`
  margin: 0;
  padding: 0;
  border: 1px solid white;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  color: white;
  width: 40px;
  height: 40px;
  outline: none;

  :focus {
    outline: none;
  }

  ${mediaQueries.mobile`
    background: rgba(0, 0, 0, 0.3);
  `};
`

const IconWrapper = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;

  svg {
    width: 30px;
    height: 30px;
  }
`

const NavigateButton = React.memo(({ onClick, direction }) => (
  <ProfileDetailsImageGalleryButtonContainer
    isLeft={direction === 'left'}
    isRight={direction === 'right'}
  >
    <ProfileDetailsImageGalleryButtonNavButton
      isLeft={direction === 'left'}
      type="button"
      onClick={() => {
        console.log('onClick')
        onClick()
      }}
    >
      <IconWrapper>
        {direction === 'left' ? <NavigateBeforeIcon /> : null}
        {direction === 'right' ? <NavigateNextIcon /> : null}
      </IconWrapper>
    </ProfileDetailsImageGalleryButtonNavButton>
  </ProfileDetailsImageGalleryButtonContainer>
))

NavigateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
}

export default NavigateButton
