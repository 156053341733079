import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import HelpPopup from '../../../components/HelpPopup'
import gigplanetTheme from '../../../styles/gigplanetTheme'
import mediaQueries from '../../../styles/mediaQueries'

const Element = styled.label`
  color: ${gigplanetTheme.component.form.label.text};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  padding: 0 0 2px;
  text-align: left;
  font-weight: 600;
  white-space: normal;

  ${mediaQueries.mobile`
    font-size: 16px;
  `};

  ${({ isCheckbox }) =>
    isCheckbox &&
    css`
      font-weight: 300;
    `};

  ${({ isRadio }) =>
    isRadio &&
    css`
      font-weight: 300;
    `};

  ${({ isPositionLeft }) =>
    isPositionLeft &&
    css`
      line-height: 20px;
      padding: 8px 10px 8px 0;
    `};

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${gigplanetTheme.component.form.label.textDisabled};
      cursor: default;
    `};

  ${({ isCheckboxLabel }) =>
    isCheckboxLabel &&
    css`
      color: ${gigplanetTheme.component.form.label.text};
    `};

  ${({ isLineThrough }) =>
    isLineThrough &&
    css`
      text-decoration: line-through;
    `};
`

const HelpPopupWrapper = styled.span`
  margin-left: 5px;
`

const GotLabel = styled.span`
  background-color: ${gigplanetTheme.color.purple};
  border-radius: 3px;
  display: inline-block;
  height: 6px;
  width: 6px;
  position: relative;
  margin-left: 5px;
`

const Label = ({
  label,
  htmlFor,
  children,
  showValueMarker,
  hide,
  position,
  checkbox,
  radio,
  disabled,
  lineThrough,
  labelHelp,
  onClick,
  isCheckboxLabel,
}) => (
  <Element
    isCheckbox={checkbox}
    isRadio={radio}
    isPositionLeft={position === 'left'}
    isHidden={hide}
    isDisabled={disabled}
    isCheckboxLabel={isCheckboxLabel}
    isLineThrough={lineThrough}
    htmlFor={htmlFor}
    onClick={onClick}
  >
    {label || children}
    {showValueMarker ? <GotLabel /> : null}
    {labelHelp ? (
      <HelpPopupWrapper>
        <HelpPopup text={labelHelp} />
      </HelpPopupWrapper>
    ) : null}
  </Element>
)

Label.propTypes = {
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  showValueMarker: PropTypes.bool,
  hide: PropTypes.bool,
  labelHelp: PropTypes.string,
  position: PropTypes.string,
  checkbox: PropTypes.bool,
  radio: PropTypes.bool,
  disabled: PropTypes.bool,
  lineThrough: PropTypes.bool,
  onClick: PropTypes.func,
  isCheckboxLabel: PropTypes.bool,
}

Label.defaultProps = {
  label: '',
  labelHelp: '',
  htmlFor: '',
  children: '',
  showValueMarker: false,
  hide: false,
  position: 'top',
  checkbox: false,
  radio: false,
  disabled: false,
  lineThrough: false,
  onClick: null,
  isCheckboxLabel: false,
}

export default Label
