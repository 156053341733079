import styled from 'styled-components'

import mediaQueries from '../../../styles/mediaQueries'

export const StyledWarning = styled.div`
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
  color: #8a6d3b;
  margin-bottom: 15px;
  padding: 15px;
  text-align: left;
  width: 100%;
  line-height: 24px;

  ${mediaQueries.mobile`
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  `};
`
