import * as React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import throttle from 'lodash.throttle'
import { compose } from 'recompose'
import { withRouter } from 'react-router'

import { CurrentUserShape, profileUpdateErrors } from '../../models/CurrentUser'
import {
  getCurrentUser,
  getIsUpdating,
  getHasUpdated,
  getErrorMessage,
} from '../../stores/CurrentUser/CurrentUserReducer'
import { updateMe } from '../../stores/CurrentUser/CurrentUserActions'

import MetaData from '../../components/MetaData'
import Layout from '../../layouts/main-layout'
import AccessDenied from '../../components/AccessDenied'
import Settings from '../../components/currentuser-settings/CurrentuserSettings'

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  isUpdating: getIsUpdating(state),
  hasUpdated: getHasUpdated(state),
  errorMessage: getErrorMessage(state),
})

const mapDispatchToProps = dispatch => ({
  updateUser: me => {
    dispatch(updateMe(me))
  },
})

class UserProfileRoute extends React.Component {
  constructor(props) {
    super(props)
    const { currentUser } = props
    this.state = {
      currentUser,
      hasUpdated: false,
    }
    this.handleUserChange = this.handleUserChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isUpdating } = this.props
    if (nextProps.hasUpdated === true && isUpdating === true) {
      this.setState({ hasUpdated: true })
      throttle(
        () => {
          this.setState({ hasUpdated: false })
        },
        3000,
        { leading: false, trailing: true },
      )()
    }
  }

  handleUserChange(e, propName) {
    const { currentUser } = this.state
    e.preventDefault()
    if ({}.hasOwnProperty.call(currentUser, propName)) {
      currentUser[propName] = e.currentTarget.value
      this.setState({
        currentUser,
      })
    }
  }

  handleSave(e) {
    const { currentUser } = this.state
    const { updateUser } = this.props
    e.preventDefault()
    updateUser(currentUser)
  }

  render() {
    const { currentUser, isUpdating, errorMessage } = this.props
    const { currentUser: currentUserFromState, hasUpdated } = this.state
    const renderErrorMessage = error => {
      if ({}.hasOwnProperty.call(profileUpdateErrors, error)) {
        return profileUpdateErrors[error]
      }
      return 'Noe feilet under oppdatering, vennligst prøv igjen senere.'
    }
    if (!{}.hasOwnProperty.call(currentUser, 'id')) {
      return (
        <Layout>
          <MetaData
            title="Ingen tilgang"
            description="Man må logge inn for å få tilgang til bruker-profilen din hos Gigplanet"
            nopin
            noindex
          />
          <AccessDenied />
        </Layout>
      )
    }
    return (
      <Layout>
        <MetaData title="Rediger profil" description="" nopin noindex />
        <Settings
          currentUser={currentUserFromState}
          handleUserChange={this.handleUserChange}
          handleSave={this.handleSave}
          isUpdating={isUpdating}
          hasUpdated={hasUpdated}
          errorMessage={errorMessage ? renderErrorMessage(errorMessage) : null}
        />
      </Layout>
    )
  }
}
const propTypes = {
  currentUser: CurrentUserShape.isRequired,
  isUpdating: PropTypes.bool,
  hasUpdated: PropTypes.bool,
  updateUser: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
}

UserProfileRoute.defaultProps = {
  errorMessage: null,
  hasUpdated: false,
  isUpdating: false,
}

UserProfileRoute.propTypes = propTypes

const UserProfileRouteWrapper = connect(mapStateToProps, mapDispatchToProps)(UserProfileRoute)

export default compose(withRouter)(UserProfileRouteWrapper)
