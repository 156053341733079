import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { EVENTPLANNER_DECLINE_REASON_STATUS } from '../../models/GigRequest'

import { reloadOneEventplannerRequestForArtistPendingAction } from '../../stores/gig-request-eventplanner/actions'

import GigRequestQuoteDecline from './components/gig-request-quote-decline'
import { postJSON } from '../../libs/data'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  completedDeclineQuote: (gigRequestId, artistId) => {
    reloadOneEventplannerRequestForArtistPendingAction(gigRequestId, artistId)
  },
})

const GigRequestEventplannerQuoteDeclineContainer = React.memo(
  ({
    gigRequestId,
    artistId,
    quoteId,
    onCancel,
    onCompleted,
    completedDeclineQuote,
    onMarkFormAsDirty,
  }) => {
    const [isSending, setIsSending] = React.useState(false)
    const [hasSent, setHasSent] = React.useState(false)
    const [declineReason, setDeclineReason] = React.useState('')
    const [declineReasonText, setDeclineReasonText] = React.useState('')
    const [errorMessages, setErrorMessages] = React.useState({})

    const handleDeclineReasonChange = e => {
      const errors = { ...errorMessages }
      if (declineReason) {
        delete errors['decline-reason']
        delete errors['decline-text']
      }

      setDeclineReason(e.currentTarget.value)
      setErrorMessages(errors)
      onMarkFormAsDirty()
    }

    const handleDeclineReasonTextChange = e => {
      const errors = { ...errorMessages }
      if (declineReasonText && declineReasonText.length > 0) {
        delete errors['decline-text']
      }
      setDeclineReasonText(e.currentTarget.value)
      setErrorMessages(errors)
      onMarkFormAsDirty()
    }

    const handleSubmit = () => {
      const errors = {}
      if (!declineReason) {
        errors['decline-reason'] = 'Du må velge en grunn for å trekke tilbudet'
      }

      if (declineReason === EVENTPLANNER_DECLINE_REASON_STATUS.OTHER && !declineReasonText) {
        errors['decline-text'] = 'Du må skrive en melding'
      }

      if (Object.keys(errors).length > 0) {
        setErrorMessages(errors)
      } else {
        setErrorMessages(errors)
        setIsSending(true)

        postJSON('/api/v1/gigRequest/declineQuote', {
          gigRequestId,
          artistId,
          quoteId,
          customerDeclineReason: declineReason,
          customerDeclineReasonText: declineReasonText,
        }).then(
          () => {
            setIsSending(false)
            setHasSent(true)
            setDeclineReasonText('')
            setDeclineReason('')
            completedDeclineQuote(gigRequestId, artistId)
            onCompleted()
          },
          error => {
            setIsSending(false)
            let serverErrorMessage = 'Feil på server'
            if (error && error.response && error.response.message) {
              switch (error.response.message) {
                case 'quote-invalidstatus':
                  serverErrorMessage = 'Tilbudet har feil status. Forsøk å laste siden på nytt.'
                  break
                default:
                  serverErrorMessage = 'Feil på server'
              }
            }
            setErrorMessages({ serverError: serverErrorMessage })
          },
        )
      }
    }

    return (
      <GigRequestQuoteDecline
        declineReason={declineReason}
        onDeclineReasonChange={handleDeclineReasonChange}
        declineReasonText={declineReasonText}
        onDeclineReasonTextChange={handleDeclineReasonTextChange}
        isSending={isSending}
        hasSent={hasSent}
        errorMessages={errorMessages}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    )
  },
)

GigRequestEventplannerQuoteDeclineContainer.propTypes = {
  // normal props
  gigRequestId: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,
  quoteId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
  onMarkFormAsDirty: PropTypes.func.isRequired,

  // dispatch props
  completedDeclineQuote: PropTypes.func.isRequired,
}

GigRequestEventplannerQuoteDeclineContainer.defaultProps = {}

const GigRequestEventplannerQuoteDeclineContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GigRequestEventplannerQuoteDeclineContainer)

export default GigRequestEventplannerQuoteDeclineContainerWrapper
