import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const StyledEventplannerInbox = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

export const Wrapper = styled.div`
  padding: 50px 30px;
  max-width: ${gigplanetTheme.measure.maxWidth};
  width: 100%;

  ${mediaQueries.mobile`
    padding: 30px 0;  
  `};
`

export const Requests = styled.ul`
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
`

export const Item = styled.li`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ArtistItem = styled.li`
  padding: 15px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -1px;
    height: 1px;
    left: 15px;
    right: 15px;
    border-bottom: 1px solid ${gigplanetTheme.element.default.border};
  }

  &:last-child {
    &:before {
      border-bottom: 0;
    }
  }
`

export const Empty = styled.div`
  width: 100%;
  padding: ${gigplanetTheme.spacing.medium};
  text-align: center;
`

export const Artists = styled.ul`
  list-style: none;
  width: 100%;
  border: 1px solid ${gigplanetTheme.element.default.border};
  margin: 15px 0 0 0;
  padding: 0;

  ${mediaQueries.mobile`
    border-left: 0;
    border-right: 0;
  `};
`
