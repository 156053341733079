import * as React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { SiteSettings } from '../../../models/SiteSettings'

import gigplanetTheme from '../../../styles/gigplanetTheme'
import mediaQueries from '../../../styles/mediaQueries'

import { TwitterIcon, FacebookFilledIcon, LinkedinFilledIcon } from '../../../icons'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 58px 0;
  border-top: 3px solid rgba(0, 0, 0, 0.0485);
  ${mediaQueries.mobile`
      flex-wrap: wrap;
  `};
`

const ContactContainer = styled.div`
  text-align: center;
  ${mediaQueries.mobile`
      width: 100%;
      margin-bottom: 30px;
  `};
`

const SoMeContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 ${gigplanetTheme.spacing.xsmall};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  ${mediaQueries.mobile`
    width: 100%;
    margin-bottom: 30px;
    order: -1;
  `};
`

const SoMeLink = styled.a`
  color: ${gigplanetTheme.element.footer.link};
  border-radius: 100%;
  width: 50px;
  height: 50px;
  margin: 5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  transition: all 1s ease;

  ${mediaQueries.mobile`
    margin-left: 0;
    margin-right: 10px;
  `};

  &:hover {
    color: ${gigplanetTheme.element.footer.linkHover};
  }
`

const ContactHeading = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 6px;
`

const ContactLink = styled.a`
  color: ${gigplanetTheme.element.footer.link};
  text-decoration: none;
  font-size: 21px;
  font-weight: 500;
  line-height: 27px;
  transition: all 0.2s ease-in-out;

  &:hover {
    text-decoration: underline;
    color: ${gigplanetTheme.element.footer.linkHover};
  }
`

const SiteNavigationContact = ({ settings }) => (
  <Container>
    {settings.phone ? (
      <ContactContainer>
        <ContactHeading>Telefon</ContactHeading>
        <ContactLink href="tel:+4790640887">{settings.phone}</ContactLink>
      </ContactContainer>
    ) : null}
    <SoMeContainer>
      {settings.facebookUrl ? (
        <li>
          <SoMeLink
            href={settings.facebookUrl}
            target="_blank"
            rel="noopener noreferrer"
            title="Lik oss på Facebook"
          >
            <FacebookFilledIcon height="32" width="32" />
          </SoMeLink>
        </li>
      ) : null}
      {settings.twitterUrl ? (
        <li>
          <SoMeLink
            href={settings.twitterUrl}
            target="_blank"
            rel="noopener noreferrer"
            title="Følg oss på Twitter"
          >
            <TwitterIcon height="32" width="39" />
          </SoMeLink>
        </li>
      ) : null}
      {settings.linkedinUrl ? (
        <li>
          <SoMeLink
            href={settings.linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            title="Følg oss på LinkedIn"
          >
            <LinkedinFilledIcon height="32" width="32" />
          </SoMeLink>
        </li>
      ) : null}
    </SoMeContainer>
    {settings.email ? (
      <ContactContainer>
        <ContactHeading>E-Post</ContactHeading>
        <ContactLink href={`mailto:${settings.email}`}>{settings.email}</ContactLink>
      </ContactContainer>
    ) : null}
  </Container>
)

SiteNavigationContact.propTypes = {
  settings: SiteSettings,
}
SiteNavigationContact.defaultProps = {}

export default SiteNavigationContact
