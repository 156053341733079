import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { getFacebookLoginUrl, getFacebookRegisterUrl } from '../../models/Auth'

const LinkButton = styled.a`
  align-items: center;
  background: #4568b2;
  border: 2px solid #4568b2;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 24px;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
`

const Logo = styled.span`
  padding-right: 5px;
`

const Text = styled.span`
  padding-left: 5px;
`

const FacebookButton = ({ redirectUrl, register, showContinue }) => {
  let url = getFacebookLoginUrl()

  if (register || showContinue) {
    url = getFacebookRegisterUrl()
  }

  if (redirectUrl) {
    url = `${url}?url=${encodeURIComponent(redirectUrl)}`
  }

  let buttonText = 'Logg inn med Facebook'
  if (register) {
    buttonText = 'Registrer deg med Facebook'
  } else if (showContinue) {
    buttonText = 'Fortsett med Facebook'
  }
  return (
    <LinkButton href={url} title={buttonText}>
      <Logo>
        <svg
          viewBox="0 0 32 32"
          role="presentation"
          aria-hidden="true"
          focusable="false"
          style={{ display: 'block', fill: 'currentcolor', height: '18px', width: '18px' }}
        >
          <path
            fillRule="evenodd"
            d="M8 14.408v-4.165c0-.424.35-.812.77-.812h2.519V7.347c0-4.84 2.484-7.311 7.42-7.347 1.645 0 3.219.212 4.692.636.455.141.63.424.595.883l-.56 4.062c-.035.178-.14.354-.315.531-.21.105-.42.176-.63.14-.875-.247-1.784-.352-2.799-.352-1.399 0-1.61.283-1.61 1.73v1.8H22.6c.42 0 .805.423.805.883l-.349 4.17c0 .422-.35.705-.77.705H18.08v16c0 .424-.349.812-.769.812h-5.213c-.42 0-.804-.388-.804-.812V15.185h-2.52A.781.781 0 0 1 8 14.408"
          />
        </svg>
      </Logo>
      <Text>{buttonText}</Text>
    </LinkButton>
  )
}

FacebookButton.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  register: PropTypes.bool,
  showContinue: PropTypes.bool,
}

FacebookButton.defaultProps = {
  register: false,
  showContinue: false,
}

export default FacebookButton
