export const getEventTypeName = (eventType, eventTypes) => {
  if (
    eventTypes[eventType.eventType] &&
    eventTypes[eventType.eventType].sub &&
    eventTypes[eventType.eventType].sub[eventType.sub]
  ) {
    return eventTypes[eventType.eventType].sub[eventType.sub].name
  }
  return ''
}
