import styled from 'styled-components'

export const HeadingWrapper = styled.div`
  margin-bottom: 25px;
  text-align: center;
`

export const InputWrapper = styled.div`
  padding-bottom: 10px;
`

export const Description = styled.p`
  text-align: left;
  margin: 0 0 25px;
`
