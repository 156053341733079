import * as React from 'react'
import PropTypes from 'prop-types'

import { StyledAnchor } from './style'

const LandingPageAnchor = ({ id }) => (
  <StyledAnchor id={id} name={id}>
    &nbsp;
  </StyledAnchor>
)

LandingPageAnchor.propTypes = {
  id: PropTypes.string.isRequired,
}

export default LandingPageAnchor
