import * as React from 'react'
import PropTypes from 'prop-types'
import SanityHtmlBody from '../sanity-html-body'

const SystemTextBlocks = React.memo(({ blocks }) => {
  if (!blocks || !blocks.length === 0) {
    return null
  }
  return (
    <>
      {(blocks || []).map(block => {
        switch (block._type) {
          case 'systemTextRichTextBlock':
            // console.log('block.body', block.body)
            return (
              <SanityHtmlBody
                key={block._key}
                body={block.body}
                imageOptions={{ w: 650, h: 400, fit: 'max' }}
              />
            )
          default:
            return null
        }
      })}
    </>
  )
})

SystemTextBlocks.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape({})),
}
SystemTextBlocks.defaultProps = {
  blocks: null,
}

export default SystemTextBlocks
