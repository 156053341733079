import * as React from 'react'
import PropTypes from 'prop-types'

import {
  FoldableRichTextContainer,
  ToggleButton,
  Heading,
  ButtonContent,
  IconWrapper,
  Content,
  RichTextWrapper,
} from './style'

import SanityHtmlBody from '../../../sanity-html-body'

import { ExpandMoreIcon } from '../../../../icons'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'

const FoldableRichTextBlock = React.memo(({ theme, anchorId, heading, expanded, body }) => {
  const [isExpanded, setIsExpanded] = React.useState(expanded)
  const [height, setHeight] = React.useState('auto')
  const contentRef = React.useRef(null)

  React.useEffect(() => {
    if (contentRef && contentRef.current) {
      const rect = contentRef.current.getBoundingClientRect()
      if (rect) {
        setHeight(rect.height)
      }
    }
  }, [isExpanded])

  const handleToggleOpen = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <LandingPageBlockWrapper theme={theme} noPadding anchorId={anchorId}>
      <FoldableRichTextContainer>
        <ToggleButton type="button" onClick={handleToggleOpen} value="on">
          <ButtonContent>
            <Heading>{heading}</Heading>
            <IconWrapper isExpanded={isExpanded}>
              <ExpandMoreIcon />
            </IconWrapper>
          </ButtonContent>
        </ToggleButton>
        <Content
          style={{
            height: isExpanded ? height : 0,
          }}
          isExpanded={isExpanded}
        >
          <RichTextWrapper ref={contentRef} paddingTop>
            <SanityHtmlBody body={body} imageOptions={{ w: 650, h: 400, fit: 'max' }} />
          </RichTextWrapper>
        </Content>
      </FoldableRichTextContainer>
    </LandingPageBlockWrapper>
  )
})

FoldableRichTextBlock.propTypes = {
  theme: PropTypes.string.isRequired,
  anchorId: PropTypes.string,
  heading: PropTypes.string,
  expanded: PropTypes.bool,
  body: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

FoldableRichTextBlock.defaultProps = {
  anchorId: '',
  heading: '',
  expanded: false,
}

export default FoldableRichTextBlock
