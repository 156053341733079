import styled, { css } from 'styled-components'

export const StyledHeaderMenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  ${({ inDropdown }) =>
    inDropdown &&
    css`
      flex-direction: column;
    `};
`
