import * as React from 'react'
import PropTypes from 'prop-types'
import DayPicker, { DateUtils } from 'react-day-picker'
import moment from 'moment'

import {
  Container,
  ErrorMessage,
  HelpText,
  InputLabel,
  Time,
  ModalContentWrapper,
  Actions,
} from './style'
import Button from '../../../Button'
import { H1 } from '../../../Headings'

import { Errormessage, Label, Select } from '../../index'
import { ModalContent, ModalButton } from '../../../../components/ModalWindow'
import Modal from '../../../Modal'

const MONTHS = [
  'Januar',
  'Februar',
  'Mars',
  'April',
  'mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Desember',
]

const WEEKDAYS_SHORT = ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø']
const WEEKDAYS_LONG = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag']

class DatePicker extends React.Component {
  constructor(props) {
    super(props)
    const { value, startTime, endTime } = props
    this.state = {
      isOpen: false,
      initialMonth: value ? moment(value) : moment(),
      selectedDay: value ? moment(value) : null,
      startTime: startTime
        ? moment(startTime)
        : moment()
            .hour(21)
            .minute(0),
      endTime: endTime
        ? moment(endTime)
        : moment()
            .hour(23)
            .minute(0),
      errorMessage: '',
    }
    this.todayDate = new Date()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = this.props
    if (value !== nextProps.value) {
      this.setState({
        initialMonth: nextProps.value ? moment(nextProps.value) : moment(),
        selectedDay: nextProps.value ? moment(nextProps.value) : null,
      })
    }
  }

  checkDisabledDays = day => {
    const { disablePast } = this.props
    return disablePast && DateUtils.isPastDay(day)
  }

  handleOpenWindow = () => {
    this.setState({
      isOpen: true,
      errorMessage: '',
    })
  }

  handleCloseWindow = () => {
    const { value } = this.props
    this.setState({
      isOpen: false,
      selectedDay: value ? moment(value) : moment(),
    })
  }

  handleDayClick = (selectedDay, { disabled }) => {
    const { disablePast } = this.props
    const { startTime, endTime } = this.state
    if (disablePast && disabled) {
      return
    }

    const startHour = moment(startTime).format('HH')
    const startMinute = moment(startTime).format('mm')
    const newStartTime = moment(selectedDay)
      .hour(startHour)
      .minute(startMinute)
    const endHour = moment(endTime).format('HH')
    const endMinute = moment(endTime).format('mm')
    const newEndTime = moment(selectedDay)
      .hour(endHour)
      .minute(endMinute)
    this.setState({
      selectedDay: moment(selectedDay),
      startTime: newStartTime,
      endTime: newEndTime,
    })
  }

  handleStartTimeChange = e => {
    const { selectedDay } = this.state
    const time = e.currentTarget.value.split(':')
    const startTime = moment(selectedDay)
      .hour(time[0])
      .minute(time[1])
    this.setState({
      startTime,
    })
  }

  handleEndTimeChange = e => {
    const { selectedDay } = this.state
    const time = e.currentTarget.value.split(':')
    const endTime = moment(selectedDay)
      .hour(time[0])
      .minute(time[1])
    this.setState({
      endTime,
    })
  }

  handleConfirmed = () => {
    const { showDuration, onChange, showTime } = this.props
    const { selectedDay, startTime, endTime } = this.state

    if (moment(selectedDay).isValid()) {
      if (showDuration) {
        const startTimeMoment = moment(startTime)
        const endTimeMoment = moment(endTime)
        if (endTimeMoment.isBefore(startTimeMoment)) {
          endTimeMoment.add(1, 'd')
        }
        onChange([startTimeMoment.format(), endTimeMoment.format()])
      } else if (showTime) {
        const startTimeMoment = moment(startTime)
        onChange(startTimeMoment.format())
      } else {
        onChange(moment(selectedDay).format())
      }

      this.setState({
        isOpen: false,
      })
    } else {
      this.setState({
        errorMessage: 'Velg en dato',
      })
    }
  }

  render() {
    const {
      expanded,
      errorMessage,
      id,
      label,
      helpText,
      showTime,
      showDuration,
      value,
      actionLabel,
      disabled,
    } = this.props
    const {
      startTime,
      endTime,
      selectedDay,
      isOpen,
      errorMessage: errorMessageState,
      initialMonth,
    } = this.state

    const tmpLabel = <Label htmlFor={id}>{label}</Label>

    const tmpHelpText = <HelpText>{helpText}</HelpText>
    const tmpErrorMessage = <ErrorMessage>{errorMessage}</ErrorMessage>

    let helpTextElement = null
    if (errorMessage) {
      helpTextElement = tmpErrorMessage
    } else if (helpText) {
      helpTextElement = tmpHelpText
    }

    let showTimeText = null
    if (showTime && startTime) {
      let endTimeText = ''
      if (showDuration && endTime) {
        endTimeText = ` - ${moment(endTime).format('HH:mm')}`
      }
      showTimeText = `${moment(startTime).format('HH:mm')}${endTimeText}`
    }

    let input = null
    if (!value) {
      input = <InputLabel>{actionLabel}</InputLabel>
    } else {
      input = (
        <InputLabel>
          {moment(value).format('DD.MM.YYYY')}
          {', '}
          {showTimeText}
        </InputLabel>
      )
    }
    let time = null
    if (showTime && selectedDay) {
      const timeOptions = []
      const toTimeOptions = []
      let minutes = ''
      let hours = ''
      for (let i = 0; i < 24; i += 1) {
        hours = i < 10 ? `0${i}` : i
        for (let y = 0; y < 60; y += 15) {
          minutes = y < 10 ? `0${y}` : y
          timeOptions.push({
            name: `${hours}:${minutes}`,
            value: `${hours}:${minutes}`,
          })
        }
      }
      let fromHours = parseInt(startTime.format('HH'), 10)
      const fromMinutes = parseInt(startTime.format('mm'), 10)
      for (let i = 0; i <= 24; i += 1) {
        hours = fromHours < 10 ? `0${fromHours}` : fromHours
        fromHours += 1
        if (fromHours >= 24) {
          fromHours = 0
        }

        for (let y = i === 0 ? fromMinutes + 15 : 0; y < (i === 24 ? fromMinutes : 60); y += 15) {
          minutes = y < 10 ? `0${y}` : y
          toTimeOptions.push({
            name: `${hours}:${minutes}`,
            value: `${hours}:${minutes}`,
          })
        }
      }

      time = (
        <Time>
          <Select
            label="Fra"
            id="from-time"
            name="from-time"
            value={startTime.format('HH:mm')}
            options={timeOptions}
            onChange={this.handleStartTimeChange}
            narrow
          />
          {showDuration ? (
            <Select
              label="Til"
              id="to-time"
              name="to-time"
              value={endTime.format('HH:mm')}
              options={toTimeOptions}
              onChange={this.handleEndTimeChange}
              narrow
            />
          ) : null}
        </Time>
      )
    }
    const labelElement = label ? tmpLabel : null
    return (
      <Container isExpanded={expanded} isError={!!errorMessage}>
        {labelElement}
        <Button
          theme="input"
          aria-haspopup
          aria-controls={isOpen ? id : ''}
          wide
          disabled={disabled}
          onClick={this.handleOpenWindow}
        >
          {input}
        </Button>
        {helpTextElement}
        <Modal id={id} isOpen={isOpen} onRequestClose={this.handleCloseWindow} label="Velg dato">
          <ModalContentWrapper>
            {errorMessageState ? <Errormessage messages={[errorMessageState]} /> : null}
            <DayPicker
              showOutsideDays
              initialMonth={initialMonth.toDate()}
              selectedDays={selectedDay ? moment(selectedDay).toDate() : null}
              months={MONTHS}
              disabledDays={this.checkDisabledDays}
              weekdaysShort={WEEKDAYS_SHORT}
              weekdaysLong={WEEKDAYS_LONG}
              firstDayOfWeek={1}
              onDayClick={this.handleDayClick}
            />
            {time}
            <Actions>
              <Button type="button" theme="primary" onClick={this.handleConfirmed}>
                Lagre
              </Button>
              <Button type="button" theme="cancel" onClick={this.handleCloseWindow}>
                Avbryt
              </Button>
            </Actions>
          </ModalContentWrapper>
        </Modal>
      </Container>
    )
  }
}

DatePicker.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  actionLabel: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  showTime: PropTypes.bool,
  showDuration: PropTypes.bool,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  disablePast: PropTypes.bool,
}

DatePicker.defaultProps = {
  label: '',
  helpText: '',
  actionLabel: 'Velg dato',
  value: null,
  errorMessage: '',
  expanded: false,
  disabled: false,
  showTime: false,
  showDuration: false,
  startTime: '',
  endTime: '',
  disablePast: false,
}

export default DatePicker
