/* eslint-disable jsx-a11y/label-has-for */
import * as React from 'react'
import PropTypes from 'prop-types'

import {
  StyledRating,
  Fieldset,
  Label,
  StarLabel,
  StarInput as StyledStarInput,
  IconWrapper,
  StarWrapper,
  HelpText,
  ErrorMessage,
} from './style'

const StarInput = ({ id, value, onChange, label, helpText, errorMessage }) => {
  const stars = []
  for (let i = 5; i > 0; i -= 1) {
    stars.push(
      <StarLabel key={`label-${id}-star-${i}`} htmlFor={`${id}-star-${i}`}>
        <StyledStarInput
          key={`input-${id}-star-${i}`}
          id={`${id}-star-${i}`}
          type="radio"
          name={id}
          value={i}
          checked={value === i}
          onChange={onChange}
        />
        {i}
        <IconWrapper isFilled={value >= i}>
          <svg
            viewBox="0 0 1000 1000"
            role="presentation"
            aria-hidden="true"
            focusable="false"
            style={{
              display: 'block',
              fill: 'currentcolor',
              height: '24px',
              width: '24px',
            }}
          >
            <path d="M971.5 379.5c9 28 2 50-20 67L725.4 618.6l87 280.1c11 39-18 75-54 75-12 0-23-4-33-12l-226.1-172-226.1 172.1c-25 17-59 12-78-12-12-16-15-33-8-51l86-278.1L46.1 446.5c-21-17-28-39-19-67 8-24 29-40 52-40h280.1l87-278.1c7-23 28-39 52-39 25 0 47 17 54 41l87 276.1h280.1c23.2 0 44.2 16 52.2 40z" />
          </svg>
        </IconWrapper>
      </StarLabel>,
    )
  }

  return (
    <StyledRating>
      {label ? <Label htmlFor={id}>{label}</Label> : null}
      {helpText ? <HelpText>{helpText}</HelpText> : null}
      <Fieldset>
        <StarWrapper>{stars}</StarWrapper>
      </Fieldset>
      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </StyledRating>
  )
}

StarInput.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
}

StarInput.defaultProps = {
  label: '',
  helpText: '',
  value: 0,
  onChange: null,
  errorMessage: '',
}

export default StarInput
