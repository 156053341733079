import * as React from 'react'
import PropTypes from 'prop-types'

import { StyledUserAuthAlert } from './style'

const UserAuthAlert = React.memo(({ children }) => (
  <StyledUserAuthAlert>{children}</StyledUserAuthAlert>
))

UserAuthAlert.propTypes = {
  children: PropTypes.string.isRequired,
}

export default UserAuthAlert
