import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { validateLogin } from '../../models/Auth'

import { updateLoginFormAction, loginAction } from '../../stores/Auth/AuthActions'
import {
  getLoginForm,
  getLoginIsLoggingIn,
  getLoginHasLoggedIn,
  getLoginErrorMessages,
  getLoginHasVerifyError,
} from '../../stores/Auth/AuthReducer'

import LoginForm from './components/user-auth-login-form'

const mapStateToProps = state => ({
  form: getLoginForm(state),
  isLoggingIn: getLoginIsLoggingIn(state),
  hasLoggedIn: getLoginHasLoggedIn(state),
  hasVerifyError: getLoginHasVerifyError(state),
  serverErrorMessages: getLoginErrorMessages(state),
})

const mapDispatchToProps = dispatch => ({
  updateForm: form => {
    dispatch(updateLoginFormAction(form))
  },
  login: successUrl => {
    dispatch(loginAction(successUrl))
  },
})

class UserAuthLoginFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessages: [],
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { serverErrorMessages } = this.props
    if (serverErrorMessages !== nextProps.serverErrorMessages) {
      this.setState({
        errorMessages: nextProps.serverErrorMessages,
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { form, login, successUrl } = this.props
    const errorMessages = validateLogin(form)
    if (errorMessages.length === 0) {
      login(successUrl)
    } else {
      this.setState({
        errorMessages,
      })
    }
  }

  handleChangeEmail = e => {
    const { form, updateForm } = this.props
    updateForm({
      ...form,
      email: e.currentTarget.value,
    })
  }

  handleChangePassword = e => {
    const { form, updateForm } = this.props
    updateForm({
      ...form,
      password: e.currentTarget.value,
    })
  }

  render() {
    const {
      intro,
      errorCode,
      hideHeading,
      successUrl,
      onShowRegisterForm,
      onShowForgotPassword,
      onShowVerify,
      hasVerifyError,
      isLoggingIn,
      form,
      serverErrorMessages,
      onClose,
    } = this.props
    const { errorMessages } = this.state
    return (
      <LoginForm
        intro={intro}
        errorCode={errorCode}
        hideHeading={hideHeading}
        successUrl={successUrl}
        onShowRegisterForm={onShowRegisterForm}
        onShowForgotPassword={onShowForgotPassword}
        onShowVerify={onShowVerify}
        hasVerifyError={hasVerifyError}
        isLoggingIn={isLoggingIn}
        onSubmit={this.handleSubmit}
        email={form.email}
        onChangeEmail={this.handleChangeEmail}
        password={form.password}
        onChangePassword={this.handleChangePassword}
        errorMessages={errorMessages}
        serverError={serverErrorMessages.length > 0}
        onClose={onClose}
      />
    )
  }
}

UserAuthLoginFormContainer.propTypes = {
  login: PropTypes.func.isRequired,
  form: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
  intro: PropTypes.string,
  errorCode: PropTypes.string,
  onClose: PropTypes.func,
  hideHeading: PropTypes.bool,
  successUrl: PropTypes.string,
  onShowRegisterForm: PropTypes.func.isRequired,
  onShowForgotPassword: PropTypes.func.isRequired,
  onShowVerify: PropTypes.func.isRequired,
  isLoggingIn: PropTypes.bool.isRequired,
  hasVerifyError: PropTypes.bool.isRequired,
  serverErrorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
  ),
}

UserAuthLoginFormContainer.defaultProps = {
  intro: '',
  errorCode: '',
  hideHeading: false,
  successUrl: '/',
  serverErrorMessages: [],
  onClose: null,
}

const UserAuthLoginFormContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAuthLoginFormContainer)

export default UserAuthLoginFormContainerWrapper
