import styled from 'styled-components'
import mediaQueries from '../../../styles/mediaQueries'

export const IconWrapper = styled.span`
  padding-right: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  svg {
    height: 25px;
    width: 25px;
  }
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${mediaQueries.mobile`
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;

    button {
      width: 100%
    }
  `};
`

export const ButtonSpacer = styled.div`
  display: block;
  width: 50px;
  height: 25px;
`
