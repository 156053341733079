import styled, { css } from 'styled-components'

import gigplanetTheme from '../../styles/gigplanetTheme'

export const StyledRating = styled.div`
  max-width: 450px;
  min-width: 250px;
  padding-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const Fieldset = styled.fieldset`
  border: 0;
  border-radius: 1px;
  padding: 0;
`

export const StarWrapper = styled.div`
  margin: 5px 0 0 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  font-size: 0;
`

export const Label = styled.label`
  color: ${gigplanetTheme.component.form.label.text};
  display: block;
  font-size: 18px;
  line-height: 20px;
  padding: 10px 0 0;
`

export const StarLabel = styled.label`
  display: block;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin: 0 4px 0 0;

  &:last-child {
    margin-right: 0;
  }
`

export const ErrorMessage = styled.small`
  color: ${gigplanetTheme.component.form.shared.errorText};
  font-size: 12px;
  line-height: 1.5;
  padding-top: 5px;
`

export const HelpText = styled.small`
  color: ${gigplanetTheme.component.form.input.helpText};
  font-size: 12px;
  line-height: 15px;
`

export const IconWrapper = styled.span`
  position: absolute;
  color: ${gigplanetTheme.component.ratingStars.empty};

  ${({ isFilled }) =>
    isFilled &&
    css`
      overflow: hidden;
      color: ${gigplanetTheme.component.ratingStars.filled};
    `};
`

export const StarInput = styled.input`
  display: none;
`
