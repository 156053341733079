import * as React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { rgba } from 'polished'
import getVideoId from 'get-video-id'

import BodyImage from './BodyImage'
import getGlobals from '../../../helpers/getGlobals'
import isExternalLink from '../../../helpers/is-external-link'
import extractUrlPath from '../../../helpers/extract-url-path'
import { OpenInNewIcon } from '../../../icons'
import gigplanetTheme from '../../../styles/gigplanetTheme'
import mediaQueries from '../../../styles/mediaQueries'
import YoutubePlayer from '../../YoutubePlayer'

const RichTextWrapper = styled.div`
  h2 {
    color: ${gigplanetTheme.component.headings.h1.text};
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 2rem 0 1rem;
    text-align: left;

    ${mediaQueries.mobile`
      font-weight: 400;
    `}
  }

  h3 {
    color: ${gigplanetTheme.component.headings.h1.text};
    font-size: 16px;
    margin: 20px 0 5px;
    text-align: left;
    line-height: 1.5;
    font-weight: 400;
  }

  ul {
    margin: 0;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 0 1rem 0;
    font-weight: 400;

    &:last-child {
      margin-bottom: 0;
    }

    ${mediaQueries.mobile`
      font-size: 1rem;
      
    `}
  }

  blockquote {
    border: 0;
    margin: 0;
    padding: 15px 15px 15px 65px;

    position: relative;
    display: block;
    font-size: 1.2rem;
    line-height: 30px;

    &:after {
      content: '\\201C';
      position: absolute;
      top: 40px;
      left: 0;
      color: ${rgba(gigplanetTheme.color.primary, 0.5)};
      font-size: 100px;
      font-weight: bold;
      z-index: 2;
    }

    ${mediaQueries.mobile`
      font-size: 1.1rem;
    `}
  }

  li {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;

    ${mediaQueries.mobile`
      font-size: 0.8rem;
    `}
  }

  img {
    max-width: 100%;
  }

  a {
    color: ${gigplanetTheme.color.primary};
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`
const ExternalLink = styled.a`
  display: inline-flex;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
    margin-left: 5px;
  }
`

const InternalLink = styled(Link)``

const LinkRenderer = React.memo(({ mark, children }) => {
  // eslint-disable-next-line
  const href = mark.href
  if (!href || !children) return null
  // eslint-disable-next-line
  const isExternal = isExternalLink(href)
  if (isExternal) {
    return (
      <ExternalLink href={href} target="_blank">
        <span>{children}</span>
        <OpenInNewIcon />
      </ExternalLink>
    )
  }
  return <InternalLink to={extractUrlPath(href)}>{children}</InternalLink>
})

LinkRenderer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  mark: PropTypes.shape({
    href: PropTypes.string,
  }).isRequired,
}

const serializers = {
  marks: { link: LinkRenderer },
  types: {
    youtubeVideo: (props) => {
      const videoResult = getVideoId(props.node.url)
      if (videoResult && videoResult.id) {
        return <YoutubePlayer id={videoResult.id} />
      }
      return null
    },
    image: (props) => {
      return <BodyImage image={props.node.asset} altText={props.node.alt} />
    },
  },
}

const ArticleBody = React.memo(({ content }) => {
  return (
    <RichTextWrapper>
      <BlockContent
        serializers={serializers}
        projectId={getGlobals().SANITY_PROJECT_ID}
        dataset={getGlobals().SANITY_DATASET}
        blocks={content}
      />
    </RichTextWrapper>
  )
})

ArticleBody.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape()),
}
export default ArticleBody
