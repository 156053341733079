import * as React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import styled from 'styled-components'

import { imageUrlFor } from '../../../../helpers/sanity-image-builder'

import { Content, List, Item, Logo } from './style'

import { H2 } from '../../../../ui/landing-page-headings'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'

const StyledLink = styled.a`
  border: 0;
  text-decoration: 0;
  display: block;
`

const LogoListBlock = React.memo(
  ({ theme, anchorId = '', heading = '', useHref = false, customerList }) => {
    const [visible, setVisibility] = React.useState(false)

    const handleVisibilityChange = (isVisible) => {
      if (visible || !isVisible) {
        return
      }
      setVisibility(true)
    }

    return (
      <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
        <VisibilitySensor
          onChange={handleVisibilityChange}
          partialVisibility
          active={!visible}
          offset={{ bottom: -150 }}
        >
          <>
            {heading ? (
              <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
            ) : null}
            <Content>
              {visible ? (
                <List>
                  {(customerList || []).map((customer) => (
                    <Item key={customer.id}>
                      {customer.url && useHref ? (
                        <StyledLink href={customer.url} target="_blank">
                          <Logo
                            src={imageUrlFor(customer.logo).height(100).quality(60).url()}
                            alt={customer.name}
                            role="presentation"
                          />
                        </StyledLink>
                      ) : (
                        <Logo
                          src={imageUrlFor(customer.logo).height(100).quality(60).url()}
                          alt={customer.name}
                          role="presentation"
                        />
                      )}
                    </Item>
                  ))}
                </List>
              ) : null}
            </Content>
          </>
        </VisibilitySensor>
      </LandingPageBlockWrapper>
    )
  },
)

LogoListBlock.propTypes = {
  heading: PropTypes.string,
  customerList: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.shape({}).isRequired,
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  theme: PropTypes.oneOf(['gray', 'white']).isRequired,
  useHref: PropTypes.bool,
  anchorId: PropTypes.string,
}

export default LogoListBlock
