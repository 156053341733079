import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import getEventTypeName from '../../helpers/getEventTypeName'

import { includedOptionInGigRequestForm, getRequestStatusName } from '../../models/GigRequest'
import { RequestUpdate, ChangeNote, ChangesList, ChangeItem, ChangedFrom, ChangedTo } from './style'
import { H2 } from '../../ui/Headings'

const DATE_FORMAT = 'ddd D. MMMM YYYY'
const TIME_FORMAT = 'HH:mm'

const GigRequestTimelineRequestUpdate = ({ isArtist, changes, eventTypes }) => (
  <RequestUpdate>
    <H2>Forespørsel oppdatert</H2>
    {isArtist ? (
      <ChangeNote>Vennligst se igjennom endringene og send et nytt tilbud.</ChangeNote>
    ) : null}
    <ChangesList>
      {Object.keys(changes).map(key => {
        const change = []
        switch (key) {
          case 'eventType': {
            change.push(
              <React.Fragment key={`${key}-${getEventTypeName(changes[key].old, eventTypes)}`}>
                Type arrangement endret fra{' '}
                <ChangedFrom>{getEventTypeName(changes[key].old, eventTypes)}</ChangedFrom> til{' '}
                <ChangedTo>{getEventTypeName(changes[key].new, eventTypes)}</ChangedTo>
              </React.Fragment>,
            )
            break
          }
          case 'venue': {
            change.push(
              <React.Fragment key={`${key}-${changes[key].old}`}>
                Adresse eller navn på lokalet fra <ChangedFrom>{changes[key].old}</ChangedFrom> til{' '}
                <ChangedTo>{changes[key].new}</ChangedTo>
              </React.Fragment>,
            )
            break
          }
          case 'city':
            change.push(
              <React.Fragment key={`${key}-${changes[key].old}`}>
                Kommune, by eller sted endret fra <ChangedFrom>{changes[key].old}</ChangedFrom> til{' '}
                <ChangedTo>{changes[key].new}</ChangedTo>
              </React.Fragment>,
            )
            break
          case 'notes': {
            if (changes[key].old) {
              change.push(
                <React.Fragment key={`${key}-${changes[key].old}`}>
                  Ytterligere informasjon om arrangementet endret fra{' '}
                  <ChangedFrom>{changes[key].old}</ChangedFrom> til{' '}
                  <ChangedTo>{changes[key].new}</ChangedTo>
                </React.Fragment>,
              )
            } else {
              change.push(
                <React.Fragment key={`${key}-${changes[key].old}`}>
                  Ytterligere informasjon om arrangementet er lagt til:{' '}
                  <ChangedTo>{changes[key].new}</ChangedTo>
                </React.Fragment>,
              )
            }
            break
          }
          case 'startTime': {
            const oldStartDate = moment(changes[key].old).format(DATE_FORMAT)
            const oldStartTime = moment(changes[key].old).format(TIME_FORMAT)
            const newStartDate = moment(changes[key].new).format(DATE_FORMAT)
            const newStartTime = moment(changes[key].new).format(TIME_FORMAT)

            if (oldStartDate !== newStartDate && oldStartTime !== newStartTime) {
              change.push(
                <React.Fragment key={`${key}-${oldStartDate}-${oldStartTime}`}>
                  Fra-dato endret fra{' '}
                  <ChangedFrom>
                    {oldStartDate} kl. {oldStartTime}
                  </ChangedFrom>
                  til{' '}
                  <ChangedTo>
                    {newStartDate} kl. {newStartTime}
                  </ChangedTo>
                </React.Fragment>,
              )
              break
            } else if (oldStartDate !== newStartDate) {
              change.push(
                <React.Fragment key={`${key}-${oldStartDate}`}>
                  Fra-dato endret fra <ChangedFrom>{oldStartDate}</ChangedFrom> til{' '}
                  <ChangedTo>{newStartDate}</ChangedTo>
                </React.Fragment>,
              )
              break
            } else if (oldStartTime !== newStartTime) {
              change.push(
                <React.Fragment key={`${key}-${oldStartTime}`}>
                  Fra-klokken endret fra <ChangedFrom>Kl. {oldStartTime}</ChangedFrom> til{' '}
                  <ChangedTo>Kl. {newStartTime}</ChangedTo>
                </React.Fragment>,
              )
              break
            } else if (oldStartDate === newStartDate && oldStartTime === newStartTime) {
              return null
            }
            break
          }
          case 'endTime': {
            const oldEndDate = moment(changes[key].old).format(DATE_FORMAT)
            const oldEndTime = moment(changes[key].old).format(TIME_FORMAT)
            const newEndDate = moment(changes[key].new).format(DATE_FORMAT)
            const newEndTime = moment(changes[key].new).format(TIME_FORMAT)

            if (oldEndDate !== newEndDate && oldEndTime !== newEndTime) {
              change.push(
                <React.Fragment key={`${key}-${oldEndDate}-${oldEndTime}`}>
                  Til-dato endret fra{' '}
                  <ChangedFrom>
                    {oldEndDate} kl. {oldEndTime}
                  </ChangedFrom>
                  til{' '}
                  <ChangedTo>
                    {newEndDate} kl. {newEndTime}
                  </ChangedTo>
                </React.Fragment>,
              )
              break
            } else if (oldEndDate !== newEndDate) {
              change.push(
                <React.Fragment key={`${key}-${oldEndDate}`}>
                  Til-dato endret fra <ChangedFrom>{oldEndDate}</ChangedFrom>
                  til <ChangedTo>{newEndDate}</ChangedTo>
                </React.Fragment>,
              )
              break
            } else if (oldEndTime !== newEndTime) {
              change.push(
                <React.Fragment key={`${key}-${oldEndTime}`}>
                  Til-klokken endret fra <ChangedFrom>Kl. {oldEndTime}</ChangedFrom> til{' '}
                  <ChangedTo>Kl. {newEndTime}</ChangedTo>
                </React.Fragment>,
              )
              break
            } else if (oldEndDate === newEndDate && oldEndTime === newEndTime) {
              return null
            }
            break
          }
          case 'audienceCount':
            change.push(
              <React.Fragment key={`${key}-${changes[key].old}`}>
                Forventet antall gjester endret fra <ChangedFrom>{changes[key].old}</ChangedFrom>{' '}
                til <ChangedTo>{changes[key].new}</ChangedTo>
              </React.Fragment>,
            )
            break
          case 'included': {
            const oldSoundSystemIncluded =
              changes[key].old.indexOf(includedOptionInGigRequestForm.SOUNDSYSTEM) !== -1
            const newSoundSystemIncluded =
              changes[key].new.indexOf(includedOptionInGigRequestForm.SOUNDSYSTEM) !== -1
            const oldSceneLightsIncluded =
              changes[key].old.indexOf(includedOptionInGigRequestForm.SCENELIGHTS) !== -1
            const newSceneLightsIncluded =
              changes[key].new.indexOf(includedOptionInGigRequestForm.SCENELIGHTS) !== -1

            if (oldSoundSystemIncluded !== newSoundSystemIncluded) {
              change.push(
                <React.Fragment key={`${key}-sound-${oldSoundSystemIncluded ? 'ja' : 'nei'}`}>
                  Lydanlegg medbringes endret fra{' '}
                  <ChangedFrom>{oldSoundSystemIncluded ? 'ja' : 'nei'}</ChangedFrom> til{' '}
                  <ChangedTo>{newSoundSystemIncluded ? 'ja' : 'nei'}</ChangedTo>
                </React.Fragment>,
              )
              if (oldSceneLightsIncluded !== newSceneLightsIncluded) {
                change.push(<br key="break" />)
              }
            }
            if (oldSceneLightsIncluded !== newSceneLightsIncluded) {
              change.push(
                <React.Fragment key={`${key}-light-${oldSceneLightsIncluded ? 'ja' : 'nei'}`}>
                  Lys medbringes endret fra{' '}
                  <ChangedFrom>{oldSceneLightsIncluded ? 'ja' : 'nei'}</ChangedFrom> til{' '}
                  <ChangedTo>{newSceneLightsIncluded ? 'ja' : 'nei'}</ChangedTo>
                </React.Fragment>,
              )
            }
            break
          }
          case 'status':
            change.push(
              <React.Fragment key={`${key}-${changes[key].old}`}>
                Status endret fra{' '}
                <ChangedFrom>{getRequestStatusName(changes[key].old, isArtist)}</ChangedFrom> til{' '}
                <ChangedTo>{getRequestStatusName(changes[key].new, isArtist)}</ChangedTo>
              </React.Fragment>,
            )
            break
          default:
            return key
        }
        return <ChangeItem key={key}>{change}</ChangeItem> // eslint-disable-line react/no-danger
      })}
    </ChangesList>
  </RequestUpdate>
)

GigRequestTimelineRequestUpdate.propTypes = {
  changes: PropTypes.shape({
    eventType: PropTypes.shape({
      new: PropTypes.object.isRequired,
      old: PropTypes.object.isRequired,
    }),
    venue: PropTypes.shape({
      new: PropTypes.string.isRequired,
      old: PropTypes.string.isRequired,
    }),
    city: PropTypes.shape({
      new: PropTypes.string.isRequired,
      old: PropTypes.string.isRequired,
    }),
    notes: PropTypes.shape({
      new: PropTypes.string.isRequired,
      old: PropTypes.string.isRequired,
    }),
    startTime: PropTypes.shape({
      new: PropTypes.string.isRequired,
      old: PropTypes.string.isRequired,
    }),
    endTime: PropTypes.shape({
      new: PropTypes.string.isRequired,
      old: PropTypes.string.isRequired,
    }),
    audienceCount: PropTypes.shape({
      new: PropTypes.number.isRequired,
      old: PropTypes.number.isRequired,
    }),
    included: PropTypes.shape({
      new: PropTypes.array.isRequired,
      old: PropTypes.array.isRequired,
    }),
  }).isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line
  isArtist: PropTypes.bool,
}
GigRequestTimelineRequestUpdate.defaultProps = {
  isArtist: false,
}

export default GigRequestTimelineRequestUpdate
