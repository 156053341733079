import * as React from 'react'
import PropTypes from 'prop-types'

import { validateEmail } from '../../../models/Auth'

import { IconWrapper, Actions, ButtonSpacer } from './style'

import { Input, Errormessage } from '../../../ui/Form'
import { H1 } from '../../../ui/Headings'
import Button from '../../../ui/Button'
import Intro from '../../../ui/user-auth-intro'
import IntroWrapper from '../../../ui/user-auth-intro-wrapper'
import InputWrapper from '../../../ui/user-auth-input-wrapper'
import ErrorWrapper from '../../../ui/user-auth-error-wrapper'

import { NavigateBeforeIcon } from '../../../icons'

const UserAuthForgotPasswordForm = ({
  email,
  errorMessages,
  serverError,
  hideHeading,
  onSubmit,
  onChangeEmail,
  isSending,
  onShowLoginForm,
}) => {
  const validEmail = validateEmail(email)
  let emailErrorMessage = ''
  if (errorMessages && errorMessages.length > 0) {
    const emailIdx = errorMessages.findIndex(element => element.id === 'email')
    if (emailIdx !== -1 && (!validEmail || serverError)) {
      emailErrorMessage = errorMessages[emailIdx].message
    }
  }
  return (
    <>
      {!hideHeading ? <H1>Tilbakestill passord</H1> : null}
      <IntroWrapper>
        <Intro>
          Skriv inn e-postadressen som er tilknyttet kontoen din, og vi vil sende deg en lenke for å
          tilbakestille passordet.
        </Intro>
      </IntroWrapper>
      <form action="/v1/api/auth" method="POST" onSubmit={onSubmit}>
        <InputWrapper>
          <Input
            id="email"
            type="email"
            label="E-post"
            placeholder="Din e-post"
            required
            expanded
            value={email}
            onChange={onChangeEmail}
            errorMessage={emailErrorMessage}
            validated={validEmail}
          />
        </InputWrapper>
        {errorMessages && errorMessages.length > 0 && (serverError || !validEmail) ? (
          <ErrorWrapper>
            <Errormessage messages={errorMessages.map(message => message.message)} />
          </ErrorWrapper>
        ) : null}
        <Actions>
          <Button type="button" theme="text" onClick={onShowLoginForm}>
            <IconWrapper>
              <NavigateBeforeIcon />
            </IconWrapper>
            Tilbake
          </Button>
          <ButtonSpacer />
          <Button type="submit" theme="primary" wide loading={isSending}>
            Tilbakestill passord
          </Button>
        </Actions>
      </form>
    </>
  )
}

UserAuthForgotPasswordForm.propTypes = {
  hideHeading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onShowLoginForm: PropTypes.func.isRequired,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
    }),
  ),
  isSending: PropTypes.bool.isRequired,
  serverError: PropTypes.bool.isRequired,
}
UserAuthForgotPasswordForm.defaultProps = {
  hideHeading: false,
  errorMessages: [],
}

export default UserAuthForgotPasswordForm
