import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'

import { getJSON, postJSON } from '../../libs/data'
import { Loader, StarInput, Button } from '../../ui'
import { H1, H2 } from '../../ui/Headings'
import { Textarea } from '../../ui/Form'
import FormRow from '../FormRow'
import FormColumn from '../FormColumn'
import gigplanetTheme from '../../styles/gigplanetTheme'
import { LONG_DATE_FORMAT, REVIEW_STATUS } from '../../models/GigRequest'
import useOptions from '../../hooks/useOptions'
import getEventTypeName from '../../helpers/getEventTypeName'
import uppercaseFirstLetter from '../../helpers/uppercase-first-letter'
import mediaQueries from '../../styles/mediaQueries'
import { Errormessage } from '../../ui/Form'

const TextareaWrapper = styled.div`
  padding-top: 20px;
`
const ReviewDetail = styled.dl`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 5px 0;

  ${mediaQueries.mobile`
    font-size: 12px;
  `};
`
const ReviewDetailDescription = styled.dt`
  width: 170px;
  min-width: 170px;
  font-weight: bold;

  ${mediaQueries.mobile`
    width: 160px;
    min-width: 160px;
    margin: 0;
    flex: 0 0 auto;
  `};
`
const ReviewDetailValue = styled.dd`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ActionRow = styled.div`
  padding-top: 20px;
`

const ErrormessageWrapper = styled.div`
  padding-top: 20px;
`

const WriteReviewForm = React.memo(({ gigId, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [isSaving, setIsSaving] = React.useState(false)
  const [hasSaved, setHasSaved] = React.useState(false)
  const [notFoundGig, setNotFoundGig] = React.useState(false)
  const [data, setData] = React.useState(null)
  const [formData, setFormData] = React.useState({
    text: '',
    scores: {
      expectations: 0,
      value: 0,
      professionalism: 0,
      skill: 0,
      recommended: 0,
    },
  })
  const [errorMessage, setErrorMessage] = React.useState(null)
  const { eventTypes } = useOptions()
  React.useEffect(() => {
    console.log('fetch data')
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const result = await getJSON(`/api/v1/gig/getGig?id=${gigId}`)
        console.log('result', result)
        setData(result)
        setIsLoading(false)
      } catch (e) {
        if (e.statusCode >= 400 && e.statusCode < 500) {
          setNotFoundGig(true)
        }
        console.log('error', e.error, e.statusCode, e.response)
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  const handleSubmitForm = async e => {
    e.preventDefault()
    const tmpErrorMessages = {}
    if (!formData.scores.expectations) {
      tmpErrorMessages.expectations = 'Vennligst velg: Innfridde forventningene'
    }
    if (!formData.scores.value) {
      tmpErrorMessages.value = 'Vennligst velg: Valuta for pengene'
    }
    if (!formData.scores.professionalism) {
      tmpErrorMessages.professionalism = 'Vennligst velg: Profesjonalitet'
    }
    if (!formData.scores.skill) {
      tmpErrorMessages.skill = 'Vennligst velg: Dyktighet'
    }
    if (!formData.scores.recommended) {
      tmpErrorMessages.recommended = 'Vennligst velg: Anbefales'
    }

    if (Object.keys(tmpErrorMessages).length > 0) {
      setErrorMessage(tmpErrorMessages)
    } else {
      setErrorMessage(null)
      setIsSaving(true)
      try {
        const result = await postJSON(`/api/v1/gig/submitArtistReview`, {
          gigId,
          ...formData,
        })
        setHasSaved(true)
        setIsSaving(false)
      } catch (e) {
        setIsSaving(false)
        setErrorMessage({ serverError: 'Feil ved lagring. Prøv igjen eller kontakt support' })
      }
    }
  }
  const handleChangetext = e => {
    if (errorMessage && errorMessage.text) {
      let tmpErrorMessage = { ...errorMessage }
      if (!e.currentTarget.value) {
        tmpErrorMessage.text = 'Skriv en kommentar'
      } else if (e.currentTarget.value.length < 10) {
        tmpErrorMessage.text = 'Du må skrive en lengre kommentar'
      } else {
        delete tmpErrorMessage.text
      }

      setErrorMessage(tmpErrorMessage)
    }
    setFormData({
      ...formData,
      text: e.currentTarget.value,
    })
  }
  const handleChangeRating = e => {
    console.log('e', e.currentTarget.value)
    //return
    if (errorMessage && errorMessage[e.currentTarget.name]) {
      setErrorMessage({
        ...errorMessage,
        [e.currentTarget.name]: '',
      })
    }
    setFormData({
      ...formData,
      scores: {
        ...formData.scores,
        [e.currentTarget.name]: parseInt(e.currentTarget.value),
      },
    })
  }

  if (isLoading) {
    return <Loader text="Henter forespørsel" />
  }
  if (notFoundGig) {
    return (
      <div>
        <H1>Det har skjedd en feil</H1>
        <p>Kunne ikke finne forespørselen.</p>
        <ActionRow>
          <Button type="button" theme="secondary" onClick={onClose}>
            Gå tilbake
          </Button>
        </ActionRow>
      </div>
    )
  }
  if (data.reviewStatus !== REVIEW_STATUS.NOT_REVIEWED) {
    return (
      <div>
        <H1>Skriv en anmeldelse</H1>
        <p>Det finnes allerede en anmeldelse for denne forespørselen</p>
        <ActionRow>
          <Button type="button" theme="secondary" onClick={onClose}>
            Gå tilbake
          </Button>
        </ActionRow>
      </div>
    )
  }
  if (hasSaved) {
    return (
      <div>
        <H1>Takk for din anmeldelse</H1>
        <p>
          Din anmeldelse er nå sendt inn.
          {data.artist && data.artist.name
            ? ` Den vil hjelpe andre når de skal velge ${data.artist.name} `
            : ''}
        </p>
        <ActionRow>
          <Button type="button" theme="secondary" onClick={onClose}>
            OK
          </Button>
        </ActionRow>
      </div>
    )
  }
  return (
    <div>
      <H1>Skriv anmeldelse</H1>

      {data && data.startTime ? (
        <ReviewDetail>
          <ReviewDetailDescription>Dato:</ReviewDetailDescription>
          <ReviewDetailValue>
            {uppercaseFirstLetter(moment(data.startTime).format(LONG_DATE_FORMAT))}
          </ReviewDetailValue>
        </ReviewDetail>
      ) : null}
      {data && data.artist ? (
        <ReviewDetail>
          <ReviewDetailDescription>Artist:</ReviewDetailDescription>
          <ReviewDetailValue>{data.artist.name}</ReviewDetailValue>
        </ReviewDetail>
      ) : null}
      {data && data.gigRequest ? (
        <ReviewDetail>
          <ReviewDetailDescription>Sted:</ReviewDetailDescription>
          <ReviewDetailValue>{data.gigRequest.city}</ReviewDetailValue>
        </ReviewDetail>
      ) : null}
      {data && data.gigRequest && data.gigRequest.eventType ? (
        <ReviewDetail>
          <ReviewDetailDescription>Type arrangement:</ReviewDetailDescription>
          <ReviewDetailValue>
            {getEventTypeName(data.gigRequest.eventType, eventTypes)}
          </ReviewDetailValue>
        </ReviewDetail>
      ) : null}
      <form onSubmit={handleSubmitForm}>
        <StarInput
          id="expectations"
          name="expectations"
          label="Innfridde forventningene?"
          helpText="1 stjerne = innfridde ikke og 5 = innfridde forventningene"
          value={formData.scores.expectations}
          onChange={handleChangeRating}
          errorMessage={errorMessage && errorMessage.expectations ? errorMessage.expectations : ''}
        />

        <StarInput
          id="value"
          name="value"
          label="Var det valuta for pengene?"
          helpText="1 stjerne = Ingen valuta for pengene og 5 = valuta for pengene"
          value={formData.scores.value}
          onChange={handleChangeRating}
          errorMessage={errorMessage && errorMessage.value ? errorMessage.value : ''}
        />

        <StarInput
          id="professionalism"
          name="professionalism"
          label="Var artisten profesjonell?"
          helpText="1 stjerne = ikke profesjoneller og 5 = profesjonelle"
          value={formData.scores.professionalism}
          onChange={handleChangeRating}
          errorMessage={
            errorMessage && errorMessage.professionalism ? errorMessage.professionalism : ''
          }
        />

        <StarInput
          id="skill"
          name="skill"
          label="Var artisten dyktig?"
          helpText="1 stjerne = Ikke dyktig og 5 = dyktig"
          value={formData.scores.skill}
          onChange={handleChangeRating}
          errorMessage={errorMessage && errorMessage.skill ? errorMessage.skill : ''}
        />

        <StarInput
          id="recommended"
          name="recommended"
          label="Vil du anbefalse artisten?"
          helpText="1 stjerne = anbefales ikke og 5 = anbefales"
          value={formData.scores.recommended}
          onChange={handleChangeRating}
          errorMessage={errorMessage && errorMessage.recommended ? errorMessage.recommended : ''}
        />
        <TextareaWrapper>
          <Textarea
            label="Din vurdering"
            helpText="Har du flere detaljer om artisten og arrangementet som kan hjelpe andre i sitt valg? Skriv det her"
            value={formData.text}
            onChange={handleChangetext}
            errorMessage={errorMessage && errorMessage.text ? errorMessage.text : ''}
          />
        </TextareaWrapper>
        {errorMessage ? (
          <ErrormessageWrapper>
            <Errormessage messages={Object.keys(errorMessage).map(e => errorMessage[e])} />
          </ErrormessageWrapper>
        ) : null}
        <ActionRow>
          <Button type="submit" theme="primary" loading={isSaving}>
            {isSaving ? 'Lagrer anmeldelse' : 'Send inn'}
          </Button>
          <Button type="button" theme="cancel" onClick={onClose}>
            Avbryt
          </Button>
        </ActionRow>
      </form>
    </div>
  )
})

WriteReviewForm.propTypes = {
  gigId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default WriteReviewForm
