import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Route, Switch, withRouter } from 'react-router'
import { connect } from 'react-redux'

import { getOneEventplannerRequestAction } from '../../../../stores/gig-request-eventplanner/actions'
import {
  getOneEventplannerRequest,
  getOneEventplannerRequestHasLoaded,
  getOneEventplannerRequestIsLoading,
} from '../../../../stores/gig-request-eventplanner/reducer'
import { getEventTypes } from '../../../../stores/Options/OptionsReducer'

import MetaData from '../../../../components/MetaData'
import PageNotFound from '../../../../components/PageNotFound'
import EventplannerInboxRequest from '../../../../views/gig-request-inbox-eventplanner'

import DetailsRoute from './details'

const mapStateToProps = state => ({
  eventplannerRequest: getOneEventplannerRequest(state),
  isLoading: getOneEventplannerRequestIsLoading(state),
  hasLoaded: getOneEventplannerRequestHasLoaded(state),
  eventTypes: getEventTypes(state),
})

const mapDispatchToProps = dispatch => ({
  getOneRequest: gigRequestId => {
    dispatch(getOneEventplannerRequestAction(gigRequestId))
  },
})

class EventplannerInboxRequestRoute extends React.Component {
  componentDidMount() {
    const {
      match: { params },
      getOneRequest,
    } = this.props
    if (params.gigRequestId) {
      getOneRequest(params.gigRequestId)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: { params },
      getOneRequest,
    } = this.props

    if (params.gigRequestId !== nextProps.match.params.gigRequestId) {
      getOneRequest(nextProps.match.params.gigRequestId)
    }
  }

  render() {
    const {
      eventplannerRequest,
      isLoading,
      hasLoaded,
      eventTypes,
      match: { params },
    } = this.props

    if (hasLoaded && !{}.hasOwnProperty.call(eventplannerRequest, 'id')) {
      return <PageNotFound />
    }
    return (
      <Switch>
        <Route path="/eventplanner/inbox/:gigRequestId/:artistId" component={DetailsRoute} />
        <Route
          path="/eventplanner/inbox/:gigRequestId"
          render={() => (
            <>
              <MetaData title="Forespørsel" description="Forespørsler" nopin />
              <EventplannerInboxRequest
                eventplannerRequest={eventplannerRequest}
                eventplannerRequestWithArtist={eventplannerRequest}
                gigRequestId={params.gigRequestId}
                isLoading={isLoading}
                hasLoaded={hasLoaded}
                eventTypes={eventTypes}
              />
            </>
          )}
        />
        <Route component={PageNotFound} />
      </Switch>
    )
  }
}

EventplannerInboxRequestRoute.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      gigRequestId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  getOneRequest: PropTypes.func.isRequired,
  eventplannerRequest: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

EventplannerInboxRequestRoute.defaultProps = {}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EventplannerInboxRequestRoute)
