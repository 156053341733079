import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import gigplanetTheme from '../../styles/gigplanetTheme'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;

  ${({ expanded }) =>
    expanded &&
    css`
      width: 100%;
    `}
`

const Element = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

const HelpTextElement = styled.span`
  color: #82888a;
  display: block;
  font-size: 12px;

  ${({ checked }) =>
    checked &&
    css`
      color: ${gigplanetTheme.color.purple};
    `}
`

const LabelContainer = styled.label`
  color: ${gigplanetTheme.color.darkGray};
  background: white;
  border: 1px solid ${gigplanetTheme.color.gray};
  border-radius: 2px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  padding: 8px 10px;
  cursor: pointer;

  ${({ expanded }) =>
    expanded &&
    css`
      width: 100%;
    `}

  ${({ checked }) =>
    checked &&
    css`
      border: 2px solid ${gigplanetTheme.color.purple};
      padding: 7px 9px;
      color: ${gigplanetTheme.color.purple};
    `}
`

const Radio = ({
  label,
  name,
  id,
  value,
  onChange,
  checked,
  disabled,
  smallText,
  helpText,
  expanded,
}) => (
  <Container expanded={expanded}>
    <LabelContainer htmlFor={id} helpText={helpText} checked={checked} expanded={expanded}>
      <Element
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        aria-label={label}
      />

      {label}
      {helpText && <HelpTextElement checked={checked}>{helpText}</HelpTextElement>}
    </LabelContainer>
  </Container>
)

Radio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  smallText: PropTypes.bool,
  expanded: PropTypes.bool,
  helpText: PropTypes.string,
}

Radio.defaultProps = {
  label: '',
  name: '',
  value: '',
  onChange: null,
  checked: false,
  disabled: false,
  smallText: false,
  expanded: false,
  helpText: '',
}

export default Radio
