import styled, { css } from 'styled-components'

import mediaQueries from '../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../styles/gigplanetTheme'

export const Wrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin-top: ${gigplanetTheme.spacing.large};

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-top: 0;
    `};
  ${mediaQueries.mobile`
    margin-top: ${gigplanetTheme.spacing.small};

    ${({ noMargin }) =>
      noMargin &&
      css`
        margin-top: 0;
      `};
  `};
`

export const Placeholder = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${gigplanetTheme.color.lightGray};

  svg {
    color: #fff;
    height: 98px;
    width: 98px;
  }
`
