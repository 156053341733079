import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { includedOptionInGigRequestForm } from '../../models/GigRequest'

import {
  StyledGigRequestEventplannerEdit,
  Wrapper,
  FormWrapper,
  FormDescription,
  FormError,
  CancelEventWrapper,
  CancelText,
} from './style'

import { Input, DatePicker, Select, Textarea, Label, Radio, Errormessage } from '../../ui/Form'
import Row from '../FormRow'
import Column from '../FormColumn'
import Action from '../../ui/form-action'
import Button from '../../ui/Button'
import { H1, H3 } from '../../ui/Headings'
import ConfirmButton from '../confirm-button'

const WARNING_TIME = 180
const GigRequestEventplannerEdit = ({
  gigRequestId,
  artistId,
  venue,
  onVenueChange,
  city,
  onCityChange,
  audienceCount,
  onAudienceCountChange,
  startTime,
  endTime,
  onTimeChange,
  eventType,
  onEventTypeChange,
  notes,
  onNotesChange,
  eventTypes,
  isSending,
  hasSent,
  onSubmit,
  errorMessages,
  onIncludedChange,
  included,
  onCancelRequest,
  isCancelingRequest,
}) => {
  let eventTypesOptions = []
  if (eventType.eventType && eventTypes[eventType.eventType]) {
    eventTypesOptions = Object.keys(eventTypes[eventType.eventType].sub).map(key => ({
      value: key,
      name: eventTypes[eventType.eventType].sub[key].name,
    }))
  }

  let diff = null
  if (startTime && endTime) {
    const timeDiff = moment(endTime).diff(startTime, 'minutes')
    const hours = parseInt(timeDiff / 60, 10)
    const minutes = timeDiff % 60
    let warning = null
    let timeText = `Total spilletid: ${hours} timer`
    if (minutes > 0) {
      timeText += ` og ${minutes} minutter`
    }
    if (timeDiff > WARNING_TIME) {
      warning = <span>Normal spilletid er vanligvis maks 3 timer.</span>
      timeText += '. '
    }
    diff = (
      <>
        <span>{timeText}</span>
        {warning}
      </>
    )
  }
  let abortUrl = `/eventplanner/inbox/${gigRequestId}`
  if (artistId) {
    abortUrl = `/eventplanner/inbox/${gigRequestId}/${artistId}`
  }
  return (
    <StyledGigRequestEventplannerEdit>
      <Wrapper>
        <FormWrapper>
          <H1>Rediger forespørsel</H1>
          <FormDescription>
            <strong>Viktig: </strong>
            Når du oppdaterer forepørselen vil dette medføre at alle tilbudene blir trukket tilbake.
            Etter oppdateringen vil vi informere alle artisten(e) du har forespurt om at de skal
            sende nytt tilbud.
          </FormDescription>
          <Row>
            <Column>
              <Input
                id="venue"
                type="text"
                label="Adresse eller navn på lokalet"
                placeholder="Hus, hotell, restaurant..."
                value={venue}
                onChange={onVenueChange}
                errorMessage={errorMessages.venue}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Input
                id="city"
                type="text"
                label="Kommune, by eller sted"
                placeholder="Så beskrivende som mulig"
                value={city}
                onChange={onCityChange}
                errorMessage={errorMessages.city}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Input
                id="audienceCount"
                type="number"
                label="Forventet antall gjester"
                placeholder="Skriv ca antall"
                value={audienceCount}
                onChange={onAudienceCountChange}
                errorMessage={errorMessages.audienceCount}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <DatePicker
                id="date"
                label="Dato og tid"
                actionLabel="Velg dato og tid"
                value={startTime}
                startTime={startTime}
                endTime={endTime}
                onChange={onTimeChange}
                hasError={!!errorMessages['event-date']}
                errorMessage={errorMessages['event-date']}
                showTime
                showDuration
                disablePast
                expanded
                required
              />
              {diff}
            </Column>
          </Row>
          <Row>
            <Column>
              <Select
                label="Type arrangement"
                id="eventType"
                options={eventTypesOptions}
                value={eventType.sub}
                onChange={onEventTypeChange}
                errorMessage={errorMessages['event-type']}
                expanded
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Textarea
                id="notes"
                label="Ytterligere informasjon om arrangementet"
                placeholder="Oppgi informasjon du tror er relevant"
                value={notes}
                onChange={onNotesChange}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Label htmlFor="included-sound-yes">Artisten må stille med lydanlegg/PA</Label>
              <Radio
                label="Ja"
                name={includedOptionInGigRequestForm.SOUNDSYSTEM}
                id="included-sound-yes"
                checked={included.indexOf(includedOptionInGigRequestForm.SOUNDSYSTEM) > -1}
                value="included"
                onChange={onIncludedChange}
              />
              <Radio
                label="Nei"
                name={includedOptionInGigRequestForm.SOUNDSYSTEM}
                id="included-sound-no"
                checked={included.indexOf(includedOptionInGigRequestForm.SOUNDSYSTEM) === -1}
                value="not-included"
                onChange={onIncludedChange}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Label htmlFor="included-scenelight-yes">Artisten må stille med scenelys</Label>
              <Radio
                label="Ja"
                name={includedOptionInGigRequestForm.SCENELIGHTS}
                id="included-scenelight-yes"
                checked={included.indexOf(includedOptionInGigRequestForm.SCENELIGHTS) > -1}
                value="included"
                onChange={onIncludedChange}
              />
              <Radio
                label="Nei"
                name={includedOptionInGigRequestForm.SCENELIGHTS}
                id="included-scenelight-no"
                checked={included.indexOf(includedOptionInGigRequestForm.SCENELIGHTS) === -1}
                value="not-included"
                onChange={onIncludedChange}
              />
            </Column>
          </Row>
          {errorMessages && Object.keys(errorMessages).length > 0 ? (
            <FormError>
              <Errormessage messages={Object.keys(errorMessages).map(key => errorMessages[key])} />
            </FormError>
          ) : null}
          <Action>
            {!isCancelingRequest ? (
              <>
                <Button type="button" theme="primary" onClick={onSubmit} loading={isSending}>
                  {isSending ? 'Oppdaterer' : 'Oppdater'}
                </Button>
                <Button type="button" theme="transparent" to={abortUrl}>
                  Avbryt
                </Button>
              </>
            ) : null}
          </Action>
        </FormWrapper>

        <CancelEventWrapper>
          <H3>Avlyse arrangementet?</H3>
          <CancelText>
            Hvis du avlyser arrangementet, vil alle artistene automatisk få beskjed
          </CancelText>
          <ConfirmButton
            id="cancel-request"
            theme="abort"
            heading="Avlyse arrangementet"
            confirmMessage="Er du sikker på at du vil avlyse arrangementet?"
            confirmButtonText="Avlys"
            onConfirmed={onCancelRequest}
            renderButton={props => (
              <Button type="button" theme="abort" loading={isCancelingRequest} {...props}>
                Avlys arrangementet
              </Button>
            )}
          />
        </CancelEventWrapper>
      </Wrapper>
    </StyledGigRequestEventplannerEdit>
  )
}

GigRequestEventplannerEdit.propTypes = {
  gigRequestId: PropTypes.string.isRequired,
  artistId: PropTypes.string,
  venue: PropTypes.string.isRequired,
  onVenueChange: PropTypes.func.isRequired,
  city: PropTypes.string.isRequired,
  onCityChange: PropTypes.func.isRequired,
  audienceCount: PropTypes.number.isRequired,
  onAudienceCountChange: PropTypes.func.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  onTimeChange: PropTypes.func.isRequired,
  eventType: PropTypes.shape({
    eventType: PropTypes.string,
    sub: PropTypes.string,
  }),
  onEventTypeChange: PropTypes.func.isRequired,
  notes: PropTypes.string.isRequired,
  onNotesChange: PropTypes.func.isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isSending: PropTypes.bool.isRequired,
  hasSent: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMessages: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onIncludedChange: PropTypes.func.isRequired,
  included: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onCancelRequest: PropTypes.func.isRequired,
  isCancelingRequest: PropTypes.bool.isRequired,
}

GigRequestEventplannerEdit.defaultProps = {
  artistId: '',
}

export default GigRequestEventplannerEdit
