import * as React from 'react'
import PropTypes from 'prop-types'

import { ListItem, ToggleButton, Content, Question, IconWrapper, Answer } from './style'
import { ExpandMoreIcon } from '../../icons'
import SanityHtmlBody from '../sanity-html-body'

const DEFAULT_HEIGHT = 60

class FAQ extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      height: DEFAULT_HEIGHT,
    }
    this.contentRef = React.createRef()
  }

  componentDidMount() {
    const targetHeight = this.contentRef.current.getBoundingClientRect().height
    this.setState({
      height: targetHeight,
    })
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props
    if (prevProps.isOpen !== isOpen) {
      const targetHeight = this.contentRef.current.getBoundingClientRect().height
      // eslint-disable-next-line
      this.setState({
        open: isOpen,
        height: targetHeight,
      })
    }
  }

  toggleOpen = () => {
    const { open } = this.state
    const targetHeight = this.contentRef.current.getBoundingClientRect().height
    this.setState({
      open: !open,
      height: targetHeight,
    })
  }

  render() {
    const { isFirst, question, answer } = this.props
    const { open, height } = this.state

    return (
      <ListItem
        style={{
          height: open ? DEFAULT_HEIGHT + height : DEFAULT_HEIGHT,
        }}
        isOpen={open}
        isFirst={isFirst}
      >
        <ToggleButton type="button" onClick={this.toggleOpen} value="on">
          <Content>
            <Question>{question}</Question>
            <IconWrapper isOpen={open}>
              <ExpandMoreIcon />
            </IconWrapper>
          </Content>
        </ToggleButton>
        <Answer ref={this.contentRef} isOpen={open}>
          <SanityHtmlBody body={answer} imageOptions={{ w: 650, h: 400, fit: 'max' }} />
        </Answer>
      </ListItem>
    )
  }
}

FAQ.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpen: PropTypes.bool,
  isFirst: PropTypes.bool,
}
FAQ.defaultProps = {
  isFirst: false,
  isOpen: false,
}

export default FAQ
