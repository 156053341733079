/* eslint-disable max-len */
import * as React from 'react'
import { ContentBlock, Heading, TextLine } from './style'

const Notice = () => (
  <ContentBlock isLast>
    <Heading>Get-in og lydprøve</Heading>
    <TextLine>
      Når band/solist ankommer for å rigge utstyr (get-in) avtales direkte mellom partene. Det samme
      gjelder tidspunkt for lydprøve og når denne må være ferdig.
    </TextLine>
  </ContentBlock>
)

export default Notice
