import * as React from 'react'
import PropTypes from 'prop-types'

import ArtistMatchCardImage from './components/ArtistMatchCardImage'
import { ArtistShape, getShortSlogan } from '../../models/Artist'
import { CategoryShape } from '../../models/Category'

import { Card, Header, Info, Heading, Slogan, Members, Ratings, Stars, Reviews } from './style'

import useOptions from '../../hooks/useOptions'
import { Checkbox } from '../../ui/Form'
import RatingStars from '../../ui/RatingStars'

const ArtistMatchCard = ({ artist }) => {
  const { categories } = useOptions()
  let artistImage = {}
  let gotRatings = false
  if (artist.images && artist.images.length > 0) {
    artistImage = artist.images[0]
  }
  if (artist.rating && artist.rating.reviews > 0) {
    gotRatings = true
  }

  const id = `artist-offer-check-${artist.id}`
  const slogan = getShortSlogan(artist, categories)
  const memberCount = artist.members ? artist.members.length : 0
  let memberCountText = ''
  if (artist.artistType === 'solo') {
    memberCountText = '1 medlem'
  } else if (memberCount > 0) {
    memberCountText = memberCount === 1 ? `${memberCount} medlem` : `${memberCount} medlemmer`
  }

  return (
    <Card to={`/${artist.artistType || 'artist'}/${artist.slug}`}>
      <Header>
        <ArtistMatchCardImage artistImage={artistImage} artistName={artist.name} />
        <Info>
          <Heading>{artist.name}</Heading>
          <Slogan>{slogan || ' '}</Slogan>
          {!gotRatings ? <Members>{memberCountText}</Members> : null}
          {gotRatings ? (
            <Ratings>
              <Stars>
                <RatingStars rating={artist.rating.average} />
              </Stars>
              <Reviews>({artist.rating.reviews})</Reviews>
            </Ratings>
          ) : null}
        </Info>
      </Header>
    </Card>
  )
}

ArtistMatchCard.propTypes = {
  artist: ArtistShape.isRequired,
}

export default ArtistMatchCard
