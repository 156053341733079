import styled from 'styled-components'
import mediaQueries from '../../../styles/mediaQueries'
import gigplanetTheme from '../../../styles/gigplanetTheme'

export const LoaderWrapper = styled.div`
  border: 1px solid ${gigplanetTheme.element.default.border};
  min-width: 290px;
  padding: 30px 15px;
  width: 100%;

  ${mediaQueries.mobile`
    display: none;
  `};
`
export const ContactInfo = styled.div`
  padding-bottom: 30px;
  width: 80%;

  ${mediaQueries.tabletPortraitDown`
    width: 100%;
  `};

  ${mediaQueries.mobile`
    padding-bottom: 15px;
  `};
`

export const EmailLink = styled.a`
  color: ${gigplanetTheme.color.primary};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

export const Request = styled.div`
  border: 1px solid ${gigplanetTheme.element.default.border};
  min-width: 290px;
  padding: 30px 15px;
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Artist = styled.div`
  border: 1px solid ${gigplanetTheme.element.default.border};
  min-width: 290px;
  padding: 30px 15px;
  width: 100%;
  margin-bottom: ${gigplanetTheme.spacing.medium};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Type = styled.p`
  color: ${gigplanetTheme.color.mediumGray};
  font-weight: 300;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 0 15px;
  text-align: center;
`

export const Separator = styled.hr`
  background: ${gigplanetTheme.element.default.border};
  border: 0;
  font-size: 1px;
  height: 1px;
  margin: 30px;
  width: 80%;

  ${mediaQueries.tabletPortraitDown`
    width: 100%;
  `};

  ${mediaQueries.mobile`
    margin: 15px 30px;
    width: 100%;
    
  `};
`
export const EventType = styled.h4`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`

export const ArtistImage = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0;

  ${mediaQueries.mobile`
    padding: 15px 0;
  `};
`

export const ArtistLink = styled.div`
  display: flex;
  justify-content: center;
`

export const TimeInfo = styled.div`
  text-align: center;
  line-height: 20px;

  &:first-letter {
    text-transform: capitalize;
  }
`

export const Info = styled.div`
  text-align: center;
  line-height: 20px;
`

export const CustomerNote = styled.pre`
  text-align: center;
  line-height: 20px;
  margin: 5px 0 0;
  font-family: inherit;
  white-space: pre-wrap;
`

export const IncludedList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const IncludedItem = styled.li`
  text-align: center;
  line-height: 20px;
`

export const LinkWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`
export const Stars = styled.div`
  padding-left: 30px;
`

export const RatingCount = styled.div`
  height: 20px;
  margin-left: 10px;
  text-align: left;
  color: #373335;
  font-size: 14px;
  width: 30px;
  line-height: 20px;
`

export const StarsWrapper = styled.div`
  margin-top: 5px;
  height: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`
