import * as React from 'react'
import PropTypes from 'prop-types'

import { HeadingWrapper } from './style'

const UserAuthHeadingWrapper = ({ children }) => <HeadingWrapper>{children}</HeadingWrapper>

UserAuthHeadingWrapper.propTypes = {
  children: PropTypes.element.isRequired,
}

export default React.memo(UserAuthHeadingWrapper)
