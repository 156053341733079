import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { useSelector } from 'react-redux'

import { getSiteSettingsSelector } from '../../stores/cms-content/reducer'

import gigplanetTheme from '../../styles/gigplanetTheme'

import SiteNavigationMobileNavigation from './components/SiteNavigationMobileNavigation'
import SiteNavigationMobileContact from './components/SiteNavigationMobileContact'

const VisibilityHandler = styled.div`
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  ${({ isOpen }) =>
    !isOpen &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`

const Container = styled.section`
  background: ${gigplanetTheme.element.siteNavigation.background};
  position: fixed;
  overflow-y: scroll;
  height: calc(100% - 50px);
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
`

const Wrapper = styled.div`
  max-width: ${gigplanetTheme.measure.maxWidth};
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SiteNavigation = ({
  isOpen,
  showControlpanelButton,
  eventplannerGigRequestCount,
  artistGigRequestCount,
  onShowRequestOptions,
  showingRequestOptions,
  favoriteCount,
  showControlpanelMenu,
  showEventplannerMenu,
}) => {
  const siteSettings = useSelector(getSiteSettingsSelector)

  return (
    <VisibilityHandler isOpen={isOpen} aria-modal={isOpen} role="dialog">
      <Container>
        <Wrapper>
          <SiteNavigationMobileNavigation
            navigation={siteSettings.header.navigation}
            isMainNavOpen={isOpen}
            favoriteCount={favoriteCount}
            eventplannerGigRequestCount={eventplannerGigRequestCount}
            artistGigRequestCount={artistGigRequestCount}
            onShowRequestOptions={onShowRequestOptions}
            showingRequestOptions={showingRequestOptions}
            showControlpanelButton={showControlpanelButton}
            showControlpanelMenu={showControlpanelMenu}
            showEventplannerMenu={showEventplannerMenu}
            isLoggedIn
          />
          <SiteNavigationMobileContact settings={siteSettings} />
        </Wrapper>
      </Container>
    </VisibilityHandler>
  )
}

SiteNavigation.propTypes = {
  isOpen: PropTypes.bool,
  currentUser: PropTypes.shape({}).isRequired,
  favoriteCount: PropTypes.number.isRequired,
  showControlpanelButton: PropTypes.bool,
  eventplannerGigRequestCount: PropTypes.number.isRequired,
  artistGigRequestCount: PropTypes.number.isRequired,
  onShowRequestOptions: PropTypes.func.isRequired,
  showingRequestOptions: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool,
  showControlpanelMenu: PropTypes.bool,
  showEventplannerMenu: PropTypes.bool,
}
SiteNavigation.defaultProps = {
  showControlpanelButton: false,
}

export default React.memo(SiteNavigation)
