import * as React from 'react' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import styled, { css } from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'
import gigplanetTheme from '../../styles/gigplanetTheme'

const hideLogic = css`
  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      ${mediaQueries.mobile`
        display: none;
      `};
    `};

  ${({ hideOnTablet }) =>
    hideOnTablet &&
    css`
      ${mediaQueries.tabletPortrait`
        display: none;
      `};
      ${mediaQueries.tabletLandscape`
        display: none;
      `};
    `};

  ${({ hideOnDesktop }) =>
    hideOnDesktop &&
    css`
      ${mediaQueries.desktopUp`
        display: none;
      `};
    `};
`

const H1 = styled.h1`
  color: ${gigplanetTheme.component.headings.h1.text};
  font-size: 30px;
  font-weight: 600;
  margin: 0;

  ${mediaQueries.mobile`
    font-size: 24px;
  `};

  a {
    color: inherit;
    text-decoration: none;
  }

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `};

  ${hideLogic};
`

export { H1 }

const H2 = styled.h2`
  color: ${gigplanetTheme.component.headings.h2.text};
  font-size: 24px;
  font-weight: 600;
  margin: 0;

  ${mediaQueries.mobile`
    font-size: 20px;
  `};

  a {
    color: inherit;
    text-decoration: none;
  }

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `};

  ${hideLogic};
`

export { H2 }

const H3 = styled.h3`
  color: ${gigplanetTheme.component.headings.h2.text};
  font-size: 20px;
  font-weight: 600;
  margin: 0;

  ${mediaQueries.mobile`
    font-size: 18px;
    font-weight: 600;
  `};

  a {
    color: inherit;
    text-decoration: none;
  }

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `};

  ${hideLogic};
`

export { H3 }

const H4 = styled.h4`
  color: ${gigplanetTheme.component.headings.h2.text};
  font-size: 18px;
  font-weight: 600;
  margin: 0;

  ${mediaQueries.mobile`
    font-size: 16px;
  `};

  a {
    color: inherit;
    text-decoration: none;
  }

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `};

  ${hideLogic};
`

export { H4 }
