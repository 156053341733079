import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const ClearFix = `
::after {
  display: table;
  clear: both;
  content: '';
}
`

export const ProfileDetailsRow = styled.div`
  max-width: ${gigplanetTheme.measure.maxWidth};
  margin-left: auto;
  margin-right: auto;
  ${ClearFix};
`
