import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'
import { getJSON } from '../../libs/data'
import gigplanetTheme from '../../styles/gigplanetTheme'

import Button from '../../ui/Button'
import ArtistMatchCard from '../../components/ArtistMatchCard'

export const ArtistList = styled.ul`
  margin: -15px;
  padding: 20px 0;
  list-style: none;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: ${gigplanetTheme.measure.maxWidth};
  width: 100%;

  ${mediaQueries.mobile`
    justify-content: center;
    margin: -10px 0;
  `};
`
export const ArtistListItem = styled.li`
  padding: 15px;
  width: 310px;

  ${mediaQueries.mobile`
    padding: 10px 0;
    width: 100%;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  `};
`

export const ButtonContainer = styled.div`
  padding-top: ${gigplanetTheme.spacing.small};
  display: flex;
  flex-direction: row;

  ${mediaQueries.mobile`
    flex-direction: column;
  `};

  & > * {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    ${mediaQueries.mobile`
      margin: 5px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
  `};
  }
`

export const DeclineText = styled.p`
  margin: 0;
  line-height: 24px;
  padding-top: ${gigplanetTheme.spacing.small};
  padding-bottom: ${gigplanetTheme.spacing.small};

  ${mediaQueries.mobile`
    font-size: 14px;
    line-height: 20px;
  `};
`

const GigRequestOptionsMatchingArtists = React.memo(({ gigRequestId, artistName, artistId }) => {
  const [artists, setArtists] = React.useState(null)
  React.useEffect(() => {
    const getMatchingArtists = async () => {
      try {
        const result = await getJSON(
          `/api/v1/gigRequest/matchingArtistsForRequest?gigRequestId=${gigRequestId}&limit=4`,
        )
        setArtists(result)
      } catch (error) {
        console.error(error)
      }
    }
    getMatchingArtists()
  }, [gigRequestId])

  const gigRequestEditUrl = `/eventplanner/inbox/edit/${gigRequestId}/${artistId}`
  if (artists && artists.length > 0) {
    return (
      <>
        <DeclineText>
          Gigplanet har mange andre artister som kan spille til ditt arrangement. Velg fra artistene
          under eller finn enda flere artister i søket. Du kan også foreslå ny dato til {artistName}
          .
        </DeclineText>
        <ArtistList>
          {artists.map(item => (
            <ArtistListItem>
              <ArtistMatchCard artist={item.artist} score={item.score} />
            </ArtistListItem>
          ))}
        </ArtistList>
        <ButtonContainer>
          <Button to="/s" theme="primary">
            Finn flere artister
          </Button>
          <Button to={gigRequestEditUrl} theme="secondary">
            Foreslå ny dato
          </Button>
        </ButtonContainer>
      </>
    )
  }

  return (
    <>
      <DeclineText>
        Gigplanet har mange andre artister som kan spille til ditt arrangement. Bruk vår søk
        funksjon for å se om du kan finne alternativer. Du kan også foreslå ny dato til {artistName}
        .
      </DeclineText>
      <ButtonContainer>
        <Button to="/s" theme="primary">
          Gå til søk
        </Button>
        <Button to={gigRequestEditUrl} theme="secondary">
          Foreslå ny dato
        </Button>
      </ButtonContainer>
    </>
  )
})

GigRequestOptionsMatchingArtists.propTypes = {
  gigRequestId: PropTypes.string.isRequired,
  artistName: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,
}

export default GigRequestOptionsMatchingArtists
