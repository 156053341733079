import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  StyledGigRequestTimelineRequest,
  NewGigRequestNotice,
  DeclineReason,
  ReasonText,
  HelpDeskLink,
} from './style'

import { H2, H3 } from '../../ui/Headings'
import {
  REQUEST_STATUS,
  SHORT_DATE_FORMAT,
  getArtistDeclineReasonName,
  HIDDEN_INFO_IN_REQUEST_ARTIST_URL,
  HIDDEN_INFO_IN_REQUEST_EVENTPLANNER_URL,
  DATE_FORMAT,
} from '../../models/GigRequest'
import TextSeparator from '../../ui/TextSeparator'

const LONG_SHORT_DATE_FORMAT = 'dddd, D. MMMM YYYY'
const TIME_FORMAT = 'HH:mm'

const GigRequestTimelineRequest = React.memo(({ element, status, eventTypes, time, isArtist }) => {
  switch (status) {
    case REQUEST_STATUS.ARTIST_DECLINED: {
      return (
        <StyledGigRequestTimelineRequest>
          <H2>Forespørsel avslått</H2>
          {element && element.artistDeclineReason ? (
            <DeclineReason>{getArtistDeclineReasonName(element.artistDeclineReason)}</DeclineReason>
          ) : null}
          {element && element.artistDeclineReasonText ? (
            <ReasonText>{element.artistDeclineReasonText}</ReasonText>
          ) : null}
        </StyledGigRequestTimelineRequest>
      )
    }
    case REQUEST_STATUS.OTHER_QUOTE_ACCEPTED: {
      return (
        <StyledGigRequestTimelineRequest>
          <TextSeparator
            text={`Annen tilbyder valgt - ${moment(time).format(SHORT_DATE_FORMAT)}`}
            hoverText={moment(time).format(DATE_FORMAT)}
            uppercase
          />
        </StyledGigRequestTimelineRequest>
      )
    }
    case REQUEST_STATUS.EXPIRED: {
      return (
        <StyledGigRequestTimelineRequest>
          <TextSeparator
            text={`Forespørsel utløpt - ${moment(time).format(SHORT_DATE_FORMAT)}`}
            hoverText={moment(time).format(DATE_FORMAT)}
            uppercase
          />
        </StyledGigRequestTimelineRequest>
      )
    }
    case REQUEST_STATUS.COMPLETED: {
      return (
        <StyledGigRequestTimelineRequest>
          <TextSeparator
            text={`${isArtist ? 'Spillejobb' : 'Arrangement'} gjennomført - ${moment(time).format(
              SHORT_DATE_FORMAT,
            )}`}
            hoverText={moment(time).format(DATE_FORMAT)}
            uppercase
          />
        </StyledGigRequestTimelineRequest>
      )
    }
    case REQUEST_STATUS.CANCELLED: {
      return (
        <StyledGigRequestTimelineRequest>
          <TextSeparator
            text={`${isArtist ? 'Spillejobb' : 'Arrangement'} avlyst - ${moment(time).format(
              SHORT_DATE_FORMAT,
            )}`}
            hoverText={moment(time).format(DATE_FORMAT)}
            uppercase
          />
        </StyledGigRequestTimelineRequest>
      )
    }
    case REQUEST_STATUS.ARCHIVED: {
      return (
        <StyledGigRequestTimelineRequest>
          <TextSeparator
            text={`${isArtist ? 'Spillejobb' : 'Arrangement'} arkivert - ${moment(time).format(
              SHORT_DATE_FORMAT,
            )}`}
            hoverText={moment(time).format(DATE_FORMAT)}
            uppercase
          />
        </StyledGigRequestTimelineRequest>
      )
    }
    case REQUEST_STATUS.ACTIVE: {
      return (
        <StyledGigRequestTimelineRequest>
          <TextSeparator
            text={`${isArtist ? 'Forespørsel mottatt' : 'Forespørsel sendt'} - ${moment(
              time,
            ).format(DATE_FORMAT)}`}
            hoverText={moment(time).format(DATE_FORMAT)}
            uppercase
          />
          {isArtist ? (
            <NewGigRequestNotice>
              Du kan ikke se kontaktinformasjon før etter at spillejobben er booket.{' '}
              <HelpDeskLink href={HIDDEN_INFO_IN_REQUEST_ARTIST_URL} target="_blank">
                Lær mer
              </HelpDeskLink>
            </NewGigRequestNotice>
          ) : (
            <NewGigRequestNotice>
              Du kan ikke se kontaktinformasjonen til artisten før etter at spillejobben er booket.{' '}
              <HelpDeskLink href={HIDDEN_INFO_IN_REQUEST_EVENTPLANNER_URL} target="_blank">
                Lær mer
              </HelpDeskLink>
            </NewGigRequestNotice>
          )}
        </StyledGigRequestTimelineRequest>
      )
    }
    default:
      return null
  }
})

GigRequestTimelineRequest.propTypes = {
  element: PropTypes.shape({
    eventType: PropTypes.shape({
      eventType: PropTypes.string.isRequired,
      sub: PropTypes.string.isRequired,
    }),
    venue: PropTypes.string,
    city: PropTypes.string,
    notes: PropTypes.string,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    audienceCount: PropTypes.number,
  }),
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isArtist: PropTypes.bool,
  status: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
}
GigRequestTimelineRequest.defaultProps = {
  isArtist: false,
}

export default GigRequestTimelineRequest
