import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const IframeWrapper = styled.div`
  height: 90vh;
  width: 90vw;
  max-width: 100%;
  max-height: 100%;
  padding: ${gigplanetTheme.spacing.medium};
  overflow: auto !important;
  -webkit-overflow-scrolling: touch !important;

  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.small};
    width: 100%;
  `};
`

export const PrintQuoteIframe = styled.iframe`
  border: 0;
  height: 100%;
  width: 100%;
`
