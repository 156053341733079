import * as React from 'react'
import PropTypes from 'prop-types'

import { compose } from 'recompose'
import { connect } from 'react-redux'

import styled, { css } from 'styled-components'

import { Link } from 'react-router-dom'
import { SiteNavigation } from '../../../models/SiteNavigationSettings'
import NavBlock from './NavBlock'

import { shutdownIntercom } from './util'
import { getLogoutUrl } from '../../../models/Auth'

import UtilityNav from '../../UtilityNav'
import UserAvatar from '../../../ui/UserAvatar'
import gigplanetTheme from '../../../styles/gigplanetTheme'

import { USER_ROLE, USER_PROFILE_URL } from '../../../models/CurrentUser'
import getGlobals from '../../../helpers/getGlobals'
import { getCurrentUser } from '../../../stores/CurrentUser/CurrentUserReducer'

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
})

const mapDispatchToProps = (dispatch) => ({})

const LinkWrapper = React.forwardRef(({ to, children, ...rest }, ref) => (
  <Link to={to} {...rest} ref={ref}>
    {children}
  </Link>
))

const MainNav = styled.nav`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem 0;
  flex-wrap: wrap;
  border-bottom: 1px solid ${gigplanetTheme.color.lightMediumGray};
`
const NavChildlessHeading = styled(LinkWrapper)`
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  color: ${gigplanetTheme.color.primary};
  cursor: pointer;
  text-decoration: none;
  display: block;
  padding: 1rem 0;
`
const NavChildlessHeadingExternal = styled.a`
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  color: ${gigplanetTheme.color.primary};
  cursor: pointer;
  text-decoration: none;
  display: block;
  padding: 1rem 0;
`
const NavColumn = styled.div`
  width: 100%;
`
export const UserWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  padding: 1rem 0;
`

export const AvatarWrapper = styled.span`
  align-self: flex-start;
  display: block;
  margin-right: 10px;
`

const SiteNavigationMobileNavigation = ({
  navigation,
  isMainNavOpen,
  showControlpanelButton,
  eventplannerGigRequestCount,
  artistGigRequestCount,
  onShowRequestOptions,
  showingRequestOptions,
  favoriteCount,
  isLoggedIn,
  currentUser,
  showControlpanelMenu,
  showEventplannerMenu,
}) => {
  return (
    <div>
      <UtilityNav
        eventplannerGigRequestCount={eventplannerGigRequestCount}
        artistGigRequestCount={artistGigRequestCount}
        onShowRequestOptions={onShowRequestOptions}
        showingRequestOptions={showingRequestOptions}
        showControlpanelButton={showControlpanelButton}
        showControlpanelMenu={showControlpanelMenu}
        showEventplannerMenu={showEventplannerMenu}
        isLoggedIn={isLoggedIn}
        currentUse={currentUser}
        favoriteCount={favoriteCount}
      />
      <MainNav>
        <NavColumn>
          {!isLoggedIn ? (
            <NavChildlessHeading to="/login">Logg inn</NavChildlessHeading>
          ) : (
            <div>
              <UserWrapper>
                <AvatarWrapper>
                  <UserAvatar user={currentUser} size={30} />
                </AvatarWrapper>
                <span>
                  {currentUser.firstName ? `Logget inn som: ${currentUser.firstName}` : null}
                </span>
              </UserWrapper>

              <NavChildlessHeading to={USER_PROFILE_URL}>Rediger profil</NavChildlessHeading>

              {/* {currentUser && currentUser.role === USER_ROLE.ADMIN ? (
                <NavChildlessHeadingExternal href={getGlobals().ADMIN_SERVER_HOST}>
                  Administrasjon
                </NavChildlessHeadingExternal>
              ) : null} */}

              <NavChildlessHeadingExternal
                onClick={shutdownIntercom}
                href={getLogoutUrl()}
                title="Logg ut"
                aria-label="Logg ut"
              >
                Logg ut
              </NavChildlessHeadingExternal>
            </div>
          )}
          <NavChildlessHeading
            to="/join"
            title="Gå til registrering av artist"
            aria-label="Gå til registrering av artist"
          >
            Registrer artist
          </NavChildlessHeading>
        </NavColumn>
      </MainNav>
      <MainNav>
        {navigation.map((column) => {
          const navBlock = []
          column.columnItems.map((sourceItem) => {
            switch (sourceItem._type) {
              case 'columnHeading': {
                const item = {
                  ...sourceItem,
                  children: [],
                }
                navBlock.push(item)
                break
              }
              case 'navigationItem':
                navBlock[navBlock.length - 1].children.push(sourceItem)
                break

              default:
                break
            }
          })

          return (
            <NavColumn key={column._key}>
              {navBlock.map((item) => (
                <NavBlock key={item._key} item={item} isMainNavOpen={isMainNavOpen} />
              ))}
            </NavColumn>
          )
        })}
      </MainNav>
    </div>
  )
}

SiteNavigationMobileNavigation.propTypes = {
  navigation: SiteNavigation,
  isMainNavOpen: PropTypes.bool,
  currentUser: PropTypes.shape({}).isRequired,
  favoriteCount: PropTypes.number,
  showControlpanelButton: PropTypes.bool,
  eventplannerGigRequestCount: PropTypes.number,
  artistGigRequestCount: PropTypes.number,
  onShowRequestOptions: PropTypes.func,
  showingRequestOptions: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  showControlpanelMenu: PropTypes.bool,
  showEventplannerMenu: PropTypes.bool,
}
SiteNavigationMobileNavigation.defaultProps = {
  showControlpanelButton: false,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  React.memo(SiteNavigationMobileNavigation),
)
