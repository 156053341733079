import * as React from 'react'
import PropTypes from 'prop-types'
import Loader from '../../components/PageLoader'
import GigRequestEventplannerDetailsSidebar from '../../containers/GigRequestEventplannerDetailsSidebar'
import GigRequestRequestDetailsEventplanner from '../../containers/gig-request-details-eventplanner'
import GigReQuestInboxWrapper from '../../components/gig-request-details-wrapper'

const GigRequestInboxDetailsEventplannerView = ({
  isLoading,
  hasLoaded,
  eventplannerRequestWithArtist,
  eventTypes,
  gigRequestId,
  artistId,
}) => {
  if (isLoading || !hasLoaded || gigRequestId !== eventplannerRequestWithArtist.id) {
    return <Loader text="Laster innboks" />
  }

  return (
    <GigReQuestInboxWrapper
      renderSidebar={() => (
        <GigRequestEventplannerDetailsSidebar
          eventplannerRequest={eventplannerRequestWithArtist}
          isLoading={isLoading}
          hasLoaded={hasLoaded}
          eventTypes={eventTypes}
        />
      )}
    >
      <GigRequestRequestDetailsEventplanner gigRequestId={gigRequestId} artistId={artistId} />
    </GigReQuestInboxWrapper>
  )
}

GigRequestInboxDetailsEventplannerView.propTypes = {
  eventplannerRequestWithArtist: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  gigRequestId: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,
}

export default GigRequestInboxDetailsEventplannerView
