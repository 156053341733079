/* global window document */
import * as React from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash.throttle'
import Scroll from 'react-scroll'

import { ArtistShape } from '../../../../models/Artist'
import Buttons from './ProfileDetails.Menu.Buttons'
import GigplanetSymbol from '../../../../ui/GigplanetSymbol'

import {
  ProfileDetailsMenuPlaceholder,
  ProfileDetailsMenuContent,
  ProfileDetailsMenuContainer,
  ProfileDetailsMenuItemLogo,
  ProfileDetailsMenuItemLogoLink,
  // ProfileDetailsMenuItemLogoImage,
  ProfileDetailsMenuLinkWrapper,
  ProfileDetailsMenuLinkText,
  ProfileDetailsMenuLinkCount,
  ProfileDetailsMenuItem,
  ProfileDetailsMenuItemText,
  ProfileDetailsMenuItemLink,
} from './style'

const scrollToTop = () => {
  Scroll.animateScroll.scrollToTop({
    duration: 1000,
    delay: 0,
    smooth: 'easeInOutQuint',
  })
}

const scrollTo = (id, pathname) => {
  window.history.replaceState({}, '', `${pathname}#${id}`)
  Scroll.scroller.scrollTo(id, {
    duration: 1000,
    delay: 0,
    smooth: 'easeInOutQuint',
  })
}

class ProfileDetailsMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSticky: false,
      activeId: '',
    }
    // this.menuRef = null
    this.headings = []
    this.menuRef = React.createRef()
  }

  componentDidMount() {
    const { headings } = this.props
    this.checkScrollPositionThrottled = throttle(this.checkScrollPosition, 10, {
      leading: false,
    })
    window.addEventListener('scroll', this.checkScrollPositionThrottled)
    this.checkScrollPosition()
    this.mapHeadings(headings)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { headings } = this.props
    if (headings !== nextProps.headings || this.headings.length === 0) {
      this.mapHeadings(nextProps.headings)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScrollPositionThrottled)
  }

  checkScrollPosition = () => {
    const { activeId, isSticky } = this.state
    const { location } = this.props
    const { pathname } = location
    let rect = null
    let currentActiveId = ''
    let currentIsSticky = false
    if (this.menuRef && this.menuRef.current) {
      rect = this.menuRef.current.getBoundingClientRect()
      currentIsSticky = rect.top < 0
      if (this.headings.length > 0) {
        for (let i = this.headings.length - 1; i >= 0; i -= 1) {
          rect = this.headings[i].getBoundingClientRect()
          if (rect.top - 30 < 0 && rect.bottom > 0) {
            // To support content below heading in menu
            currentActiveId = this.headings[i].id
            break
          }
        }
      }

      if (activeId !== currentActiveId || isSticky !== currentIsSticky) {
        // its a bug here on first click - loads two times when activeId is ""
        if (currentActiveId) {
          window.history.replaceState({}, '', `${pathname}#${currentActiveId}`)
        } else {
          window.history.replaceState({}, '', `${location.pathname}`)
        }

        this.setState({
          activeId: currentActiveId,
          isSticky: currentIsSticky,
        })
      }
    }
  }

  mapHeadings = elements => {
    if (typeof document !== 'undefined') {
      this.headings = []
      let el = null
      elements.forEach(element => {
        el = document.getElementById(element.id)
        if (el) {
          this.headings.push(el)
        }
      })
    }
  }

  render() {
    // console.log('render PROPS: ', this.props)
    const { isSticky, activeId } = this.state
    const { headings, artistId, artistName, artistSlug, artistType, location } = this.props // eslint-disable-line
    const { pathname } = location

    return (
      <>
        <ProfileDetailsMenuPlaceholder ref={this.menuRef} isSticky={isSticky} />
        <ProfileDetailsMenuContent isSticky={isSticky}>
          <ProfileDetailsMenuContainer>
            <ProfileDetailsMenuItemLogo isSticky={isSticky}>
              <ProfileDetailsMenuItemLogoLink
                isSticky={isSticky}
                onClick={e => {
                  e.preventDefault()
                  scrollToTop()
                }}
              >
                <GigplanetSymbol alt="Gigplanet - Live musikk til ditt arrangement" />
              </ProfileDetailsMenuItemLogoLink>
            </ProfileDetailsMenuItemLogo>
            {headings.map(heading => {
              const text = (
                <ProfileDetailsMenuLinkWrapper isActive={activeId === heading.id}>
                  <ProfileDetailsMenuLinkText>{heading.heading}</ProfileDetailsMenuLinkText>
                  {heading.count > 0 ? (
                    <ProfileDetailsMenuLinkCount>({heading.count})</ProfileDetailsMenuLinkCount>
                  ) : null}
                </ProfileDetailsMenuLinkWrapper>
              )
              return (
                <ProfileDetailsMenuItem
                  isDisabled={heading.disabled}
                  isActive={activeId === heading.id}
                  key={`heading-${heading.id}`}
                >
                  {heading.disabled ? (
                    <ProfileDetailsMenuItemText>{text}</ProfileDetailsMenuItemText>
                  ) : (
                    <ProfileDetailsMenuItemLink
                      onClick={e => {
                        e.preventDefault()
                        scrollTo(`${heading.id}`, pathname)
                      }}
                      href={`${pathname}#${heading.id}`} /* TODO: dont rely on location.pathname here */
                    >
                      {text}
                    </ProfileDetailsMenuItemLink>
                  )}
                </ProfileDetailsMenuItem>
              )
            })}
          </ProfileDetailsMenuContainer>
          <Buttons
            artistId={artistId}
            artistName={artistName}
            artistSlug={artistSlug}
            artistType={artistType}
          />
        </ProfileDetailsMenuContent>
      </>
    )
  }
}

ProfileDetailsMenu.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      count: PropTypes.number,
      disabled: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  artistId: PropTypes.string.isRequired,
  artistName: PropTypes.string.isRequired,
  artistSlug: PropTypes.string.isRequired,
  artistType: PropTypes.string.isRequired,
  location: PropTypes.shape({}).isRequired,
  // pathname: PropTypes.string.isRequired,
}

export default ProfileDetailsMenu
