import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const ArtistList = styled.ul`
  list-style: none;
  width: 100%;
  border: 1px solid ${gigplanetTheme.element.default.border};
  margin: 0;
  padding: 0;
`

export const Artist = styled.li`
  padding: 15px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -1px;
    height: 1px;
    left: 15px;
    right: 15px;
    border-bottom: 1px solid ${gigplanetTheme.element.default.border};
  }

  &:last-child {
    &:before {
      border-bottom: 0;
    }
  }
`
