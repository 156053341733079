import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
export const Content = styled.div`
  padding: ${gigplanetTheme.spacing.large};
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.small};
  `};
`

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: ${gigplanetTheme.spacing.large};
`

export const ProgressWrapper = styled.div`
  padding: ${gigplanetTheme.spacing.large};
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const ProgressBarWrapper = styled.div``
export const ProgressTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const ProgressLabel = styled.div`
  padding: 0;
  color: ${gigplanetTheme.color.mediumGray};
  font-size: 12px;
  text-align: left;
  line-height: 20px;
  text-transform: uppercase;

  ${mediaQueries.mobile`
    display: none;
  `};
`

export const ProgressPercentage = styled.div`
  color: ${gigplanetTheme.color.mediumGray};
  font-size: 12px;
  text-align: right;
  line-height: 20px;
  text-transform: uppercase;
  padding: 0;

  ${mediaQueries.mobile`
      width: 100%;
        text-align: center;
      `};
`
