import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const StyledEventplannerInboxItem = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: ${gigplanetTheme.element.default.text};
  position: relative;
  padding-right: 40px;

  ${mediaQueries.mobile`
    flex-direction: column;
    align-items: flex-start;
    
  `};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 auto;
  width: 19%;

  ${mediaQueries.mobile`
    width: 100%;
    padding: 10px 0;
  `};

  ${({ isAvatar }) =>
    isAvatar &&
    css`
      flex-direction: row;
      align-items: center;
      max-width: 300px;
    `};

  ${({ isRequest }) =>
    isRequest &&
    css`
      flex: 0 1 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};

  ${({ isPrice }) =>
    isPrice &&
    css`
      max-width: 100px;

      ${mediaQueries.mobile`
        max-width: 100%;
      `};
    `};

  ${({ isStatus }) =>
    isStatus &&
    css`
      align-items: flex-end;
      max-width: 150px;

      ${mediaQueries.mobile`
        max-width: 100%;
        align-items: flex-start;
      `};
    `};
`

export const Requester = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > span {
    color: ${gigplanetTheme.color.purple};
    text-decoration: underline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  ${StyledEventplannerInboxItem}:hover & {
    & > span {
      text-decoration: none;
    }
  }
`

export const StatusDetails = styled.span`
  color: ${gigplanetTheme.color.mediumGray};
  font-size: 14px;
  margin-top: 2px;

  &:first-letter {
    text-transform: uppercase;
  }
`

export const JobDetails = styled.strong`
  font-weight: 300;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

export const PriceDetails = styled.strong`
  font-weight: 300;

  ${({ isQuote }) =>
    isQuote &&
    css`
      font-weight: 600;
    `};
`

export const Unread = styled.div`
  position: absolute;
  right: 0;
  border-radius: 100%;
  background: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  width: 10px;
`
