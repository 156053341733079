import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import queryString from 'query-string'

import { checkAuthDataAction } from '../../stores/Auth/AuthActions'
import { getEmail, getProvider } from '../../stores/Auth/AuthReducer'

import Layout from '../../layouts/main-layout'
import SystemMessageWrapper from '../../ui/system-message-wrapper'

import MetaData from '../../components/MetaData'
import UserAuthNoUser from '../../components/user-auth-no-user'

const mapStateToProps = state => ({
  email: getEmail(state),
  provider: getProvider(state),
})

const mapDispatchToProps = dispatch => ({
  checkAuthData: () => {
    dispatch(checkAuthDataAction())
  },
})

const NoUserRoute = React.memo(
  ({ checkAuthData, provider, email, history: { push, location } }) => {
    React.useEffect(() => {
      checkAuthData()
    }, [])

    let url = '/'
    const query = queryString.parse(location.search)
    if (query && query.url) {
      url = query.url
    }

    const handleOnClose = () => {
      push('/')
    }

    return (
      <Layout showSearch>
        <MetaData
          title="Fant ingen bruker hos Gigplanet"
          description="Ingen bruker er registrert hos Gigplanet"
          noindex
        />
        <SystemMessageWrapper>
          <UserAuthNoUser
            provider={provider}
            email={email}
            onClose={handleOnClose}
            successUrl={url}
          />
        </SystemMessageWrapper>
      </Layout>
    )
  },
)

NoUserRoute.propTypes = {
  // dispatch props
  checkAuthData: PropTypes.func.isRequired,

  // state props
  email: PropTypes.string,
  provider: PropTypes.string,

  // hoc props
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
}

NoUserRoute.defaultProps = {
  email: '',
  provider: '',
}

NoUserRoute.dispatchActions = (req, dispatch) => [dispatch(checkAuthDataAction(req))]

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NoUserRoute)
