import * as React from 'react'
import PropTypes from 'prop-types'

import { Container } from './style'

const VimeoPlayer = ({ id }) => (
  <Container>
    <iframe
      title={`Viser Vimeo video id ${id}`}
      type="text/html"
      width="720"
      height="405"
      src={`https://player.vimeo.com/video/${id}?color=8805BB`}
      frameBorder="0"
      allowFullScreen
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    />
  </Container>
)

VimeoPlayer.propTypes = {
  id: PropTypes.string.isRequired,
}

export default VimeoPlayer
