import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { trackEvent } from '../../../../libs/analytics'

import {
  fetchFrontpageArtists,
  fetchFrontpageArtistsIfNeeded,
} from '../../../../stores/Artist/ArtistActions'

import {
  getFrontpageArtists,
  isFrontpageArtistsLoading,
  isMoreFrontpageArtists,
} from '../../../../stores/Artist/ArtistReducer'

import { Container, Actions, ArtistListWrapper, ReadMoreWrapper } from './style'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import { H2 } from '../../../../ui/landing-page-headings'
import { ArtistShape } from '../../../../models/Artist'
import ArtistList from '../../../../containers/ArtistList'
import Button from '../../../../ui/Button'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'

const ARTIST_LIMIT = 6

const mapStateToProps = state => ({
  artists: getFrontpageArtists(state),
  artistsLoading: isFrontpageArtistsLoading(state),
  artistsIsMore: isMoreFrontpageArtists(state),
})

const mapDispatchToProps = dispatch => ({
  getArtists: () => {
    dispatch(fetchFrontpageArtistsIfNeeded(ARTIST_LIMIT))
  },
  getMoreArtists: offset => {
    dispatch(fetchFrontpageArtists({}, ARTIST_LIMIT, offset))
  },
})

const ArtistFeatureListBlock = React.memo(
  ({
    theme,
    anchorId,
    heading,
    showMoreButtonText,
    getArtists,
    getMoreArtists,
    artists,
    artistsIsMore,
    artistsLoading,
  }) => {
    const [frontpageArtistsOffset, setFrontpageArtistsOffset] = React.useState(0)
    React.useEffect(() => {
      getArtists()
    }, [])

    const onLoadMoreArtists = () => {
      const newOffset = frontpageArtistsOffset + ARTIST_LIMIT
      getMoreArtists(newOffset)
      trackEvent('landingpage', 'landingpage-block-artist-feature-show-more-artists', newOffset)
      setFrontpageArtistsOffset(newOffset)
    }

    return (
      <LandingPageBlockWrapper theme={theme} noPadding anchorId={anchorId}>
        <Container>
          {heading ? (
            <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
          ) : null}
          {artists && artists.length > 0 ? (
            <ArtistListWrapper>
              <ArtistList artists={artists} />
            </ArtistListWrapper>
          ) : (
            <div>Ingen artister</div>
          )}

          {artistsIsMore && showMoreButtonText ? (
            <Actions>
              <ReadMoreWrapper>
                <Button
                  size="large"
                  type="button"
                  theme="outline"
                  onClick={onLoadMoreArtists}
                  loading={artistsLoading}
                  uppercase
                >
                  {showMoreButtonText}
                </Button>
              </ReadMoreWrapper>
            </Actions>
          ) : null}
        </Container>
      </LandingPageBlockWrapper>
    )
  },
)

ArtistFeatureListBlock.propTypes = {
  heading: PropTypes.string,
  showMoreButtonText: PropTypes.string,
  artists: PropTypes.arrayOf(ArtistShape),
  artistsLoading: PropTypes.bool.isRequired,
  artistsIsMore: PropTypes.bool.isRequired,
  anchorId: PropTypes.string,
}

ArtistFeatureListBlock.defaultProps = {
  heading: '',
  showMoreButtonText: '',
  artists: [],
  anchorId: '',
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ArtistFeatureListBlock)
