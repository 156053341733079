import * as React from 'react'
import PropTypes from 'prop-types'

import { validatePassword } from '../../../models/Auth'

import { Input, Errormessage } from '../../../ui/Form'
import Button from '../../../ui/Button'
import { H1 } from '../../../ui/Headings'
import HeadingWrapper from '../../../ui/user-auth-heading-wrapper'
import InputWrapper from '../../../ui/user-auth-input-wrapper'
import ErrorWrapper from '../../../ui/user-auth-error-wrapper'
import SubmitWrapper from '../../../ui/user-auth-submit-wrapper'
import Description from '../../../ui/user-auth-description'
import Actions from '../../../ui/user-auth-actions'
import UserAuthAbort from '../../../components/user-auth-abort'

const UserAuthNewPasswordForm = ({
  password,
  errorMessages,
  hideHeading,
  onChangePassword,
  isSaving,
  onSubmit,
  onClose,
  serverError,
}) => {
  const validPassword = validatePassword(password)
  let passwordErrorMessage = '' // eslint-disable-line
  if (errorMessages && errorMessages.length > 0) {
    const passwordIdx = errorMessages.findIndex((element) => element.id === 'password')
    if (passwordIdx !== -1 && (!validPassword || serverError)) {
      passwordErrorMessage = errorMessages[passwordIdx].message
    }
  }
  return (
    <>
      {!hideHeading ? (
        <HeadingWrapper>
          <H1>Nytt passord</H1>
        </HeadingWrapper>
      ) : null}
      <Description>
        Husk at passordet må være minst 6 tegn, ikke inneholde mellomrom, ha minst 1 stor bokstav, 1
        liten bokstav og 1 tall.
      </Description>
      <form action="/v1/api/auth" method="POST" onSubmit={onSubmit}>
        <InputWrapper>
          <Input
            id="password"
            type="password"
            label="Passord"
            placeholder="Skriv inn nytt passord"
            required
            expanded
            value={password}
            onChange={onChangePassword}
            validated={validPassword}
            helpText={
              !validPassword && password
                ? 'Passord må være minst 6 tegn, ikke inneholde mellomrom, ha minst 1 stor bokstav, 1 liten bokstav og 1 tall.'
                : ''
            }
          />
        </InputWrapper>
        {errorMessages && errorMessages.length > 0 && (serverError || !validPassword) ? (
          <ErrorWrapper>
            <Errormessage messages={errorMessages.map((message) => message.message)} />
          </ErrorWrapper>
        ) : null}
        <Actions>
          <SubmitWrapper>
            <Button type="submit" theme="primary" wide loading={isSaving}>
              Lagre nytt passord
            </Button>
          </SubmitWrapper>
          <UserAuthAbort onAbort={onClose} />
        </Actions>
      </form>
    </>
  )
}

UserAuthNewPasswordForm.propTypes = {
  hideHeading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
    }),
  ),
  isSaving: PropTypes.bool.isRequired,
  serverError: PropTypes.bool.isRequired,
}

UserAuthNewPasswordForm.defaultProps = {
  hideHeading: false,
}

export default UserAuthNewPasswordForm
