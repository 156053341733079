import * as React from 'react'
import PropTypes from 'prop-types'

import PageLoader from '../../components/PageLoader'
import EmptyWrapper from '../../components/gig-request-empty-wrapper'
import Sidebar from '../../containers/gig-request-sidebar-booking-eventplanner'
import BookingForm from '../../containers/gig-request-booking-form-eventplanner'

import GigRequestInboxWrapper from '../../components/gig-request-details-wrapper'

const GigRequestBookingEventplannerView = ({
  isLoading,
  hasLoaded,
  artist,
  gigRequest,
  quote,
  quoteId,
}) => {
  if (isLoading || !hasLoaded) {
    return <PageLoader text="Laster tilbud" />
  }
  if (quote && !{}.hasOwnProperty.call(quote, 'id')) {
    return <EmptyWrapper>Fant ikke tilbudet</EmptyWrapper>
  }
  return (
    <GigRequestInboxWrapper
      renderSidebar={() => (
        <Sidebar
          artist={artist}
          gigRequest={gigRequest}
          quote={quote}
          isLoading={isLoading}
          hasLoaded={hasLoaded}
        />
      )}
    >
      <BookingForm
        quoteId={quoteId}
        gigRequestId={gigRequest.id}
        artistId={artist.id}
        gigRequest={gigRequest}
        artist={artist}
        quote={quote}
      />
    </GigRequestInboxWrapper>
  )
}

GigRequestBookingEventplannerView.propTypes = {
  quoteId: PropTypes.string.isRequired,
  artist: PropTypes.shape({}), // eslint-disable-line react/forbid-prop-types
  gigRequest: PropTypes.shape({}), // eslint-disable-line react/forbid-prop-types
  quote: PropTypes.shape({}), // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
}

GigRequestBookingEventplannerView.defaultProps = {
  artist: null,
  gigRequest: null,
  quote: null,
}

export default React.memo(GigRequestBookingEventplannerView)
