import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const Container = styled.section`
  padding: 50px;
  width: 100%;
  text-align: center;

  ${mediaQueries.mobile`
    padding: 15px;
  `};
`
