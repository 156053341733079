import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'

import { imageUrlFor } from '../../../helpers/sanity-image-builder'
import SanityImage from '../../sanity-image'
import mediaQueries, { mediaQueriesDefinitions } from '../../../styles/mediaQueries'

import { CameraIcon } from '../../../icons'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const ImageWrapper = styled.div`
  width: 100%;
  img {
    min-width: 100%;
  }
`
const Placeholder = styled.div`
  width: 100%;
  height: 202px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${gigplanetTheme.color.lightGray};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid white;

  svg {
    color: #fff;
    height: 98px;
    width: 98px;
  }
`

const MainImage = React.memo(({ image, altText }) => {
  const [visible, setVisible] = React.useState(false)

  const handleVisibilityChange = (isVisible) => {
    if (visible || !isVisible) {
      return
    }
    setVisible(true)
  }

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      active={!visible}
      offset={{ bottom: -150 }}
    >
      {visible && image ? (
        <ImageWrapper>
          <SanityImage width={200} height={40} image={image} altText={altText}>
            <source
              media={mediaQueriesDefinitions.mobileDown}
              srcSet={imageUrlFor(image).width(600).height(300)}
            />
            <source
              media={mediaQueriesDefinitions.tabletPortrait}
              srcSet={imageUrlFor(image).width(900).height(350)}
            />
            <source
              media={mediaQueriesDefinitions.tabletLandscape}
              srcSet={imageUrlFor(image).width(1000).height(400)}
            />
            <source
              media={mediaQueriesDefinitions.desktop}
              srcSet={imageUrlFor(image).width(1500).height(400)}
            />
            <source
              media={mediaQueriesDefinitions.desktopBig}
              srcSet={imageUrlFor(image).width(1800).height(400)}
            />
          </SanityImage>
        </ImageWrapper>
      ) : (
        <Placeholder>
          <CameraIcon />
        </Placeholder>
      )}
    </VisibilitySensor>
  )
})

MainImage.propTypes = {
  image: PropTypes.shape({}).isRequired,

  altText: PropTypes.string.isRequired,
}

export default MainImage
