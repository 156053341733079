import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import GigRequestQuotePrint from '../../../../../components/gig-request-quote-print'
import { getOneArtistRequest } from '../../../../../stores/GigRequestArtist/GigRequestArtistReducer'
import { getEventTypes } from '../../../../../stores/Options/OptionsReducer'
import PageLoader from '../../../../../components/PageLoader'
import AccessDenied from '../../../../../components/AccessDenied'

const mapStateToProps = state => ({
  gigRequest: getOneArtistRequest(state),
  eventTypes: getEventTypes(state),
})

const mapDispatchToProps = dispatch => ({})

class PrintSignedArtistQuoteRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    const {
      eventTypes,
      gigRequest,
      history: { location },
    } = this.props
    const query = queryString.parse(location.search)

    if (!gigRequest) return <PageLoader text="Laster kontrakt" />

    const { artist } = gigRequest
    const { quote } = gigRequest
    if (!quote || !eventTypes || !artist) return <PageLoader text="Laster kontrakt" />

    if (query.gigRequestId !== gigRequest.id || query.quoteId !== quote.id) {
      return <AccessDenied />
    }

    return (
      <GigRequestQuotePrint
        acceptedTime={quote.acceptedTime || ''}
        invoiceOrganization={quote.invoice ? quote.invoice.organization : ''}
        invoiceName={quote.invoice ? quote.invoice.name : ''}
        invoiceAddress={quote.invoice ? quote.invoice.address : ''}
        invoiceZip={quote.invoice ? quote.invoice.zip : ''}
        invoiceCity={quote.invoice ? quote.invoice.city : ''}
        invoiceReference={quote.invoice ? quote.invoice.reference : ''}
        contactName={quote.contact ? quote.contact.name : ''}
        contactEmail={quote.contact ? quote.contact.email : ''}
        contactPhone={quote.contact ? quote.contact.phone : ''}
        artistName={artist.name}
        artistContactName={artist.contactInfo ? artist.contactInfo.contactName : ''}
        artistContactPhone={artist.contactInfo ? artist.contactInfo.contactPhone : ''}
        artistContactEmail={artist.contactInfo ? artist.contactInfo.contactEmail : ''}
        artistInvoiceName={artist.invoiceInfo ? artist.invoiceInfo.invoiceName : ''}
        artistInvoiceAddress={artist.invoiceInfo ? artist.invoiceInfo.invoiceAddress : ''}
        artistInvoiceZip={artist.invoiceInfo ? artist.invoiceInfo.invoiceZip : ''}
        artistInvoiceCity={artist.invoiceInfo ? artist.invoiceInfo.invoiceCity : ''}
        artistOrganizationNumber={artist.invoiceInfo ? artist.invoiceInfo.organizationNumber : ''}
        eventTypes={eventTypes}
        eventType={gigRequest.eventType}
        audienceCount={gigRequest.audienceCount}
        venue={gigRequest.venue}
        city={gigRequest.city}
        startTime={gigRequest.startTime}
        endTime={gigRequest.endTime}
        pauseCount={quote.pauseCount}
        pauseDuration={quote.pauseDuration}
        price={quote.price}
        memberCount={quote.members || 1}
        included={gigRequest.included}
        terms={quote.terms}
      />
    )
  }
}

PrintSignedArtistQuoteRoute.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  }).isRequired,

  // state props
  quote: PropTypes.shape({}),
  gigRequest: PropTypes.shape({}),
  eventTypes: PropTypes.shape({}),
}
PrintSignedArtistQuoteRoute.defaultProps = {}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PrintSignedArtistQuoteRoute)

/*
 let printQuoteUrl = `/print/quote/?quoteId=${quoteId}&gigRequestId=${gigRequestId}&artistId=${artistId}`
    printQuoteUrl += `&invoiceOrganization=${invoice.organization}`
    printQuoteUrl += `&invoiceName=${invoice.name}`
    printQuoteUrl += `&invoiceAddress=${invoice.address}`
    printQuoteUrl += `&invoiceZip=${invoice.zip}`
    printQuoteUrl += `&invoiceCity=${invoice.city}`

    printQuoteUrl += `&contactName=${contact.name}`
    printQuoteUrl += `&contactPhone=${contact.phone}`
    printQuoteUrl += `&contactEmail=${contact.email}`

    */
