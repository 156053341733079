import * as React from 'react'
import PropTypes from 'prop-types'

import { Content, List, Item, ItemNumber, ItemHeading, ItemContent } from './style'

import { H2 } from '../../../../ui/landing-page-headings'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'

const HowItWorksBlock = React.memo(({ theme, anchorId, heading, steps }) => (
  <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
    <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
    <Content>
      <List>
        {steps.map((c, index) => (
          <Item key={c.heading}>
            <ItemNumber>{index + 1}</ItemNumber>
            <ItemHeading>{c.heading}</ItemHeading>
            <ItemContent>{c.description}</ItemContent>
          </Item>
        ))}
      </List>
    </Content>
  </LandingPageBlockWrapper>
))

HowItWorksBlock.propTypes = {
  theme: PropTypes.string.isRequired,
  anchorId: PropTypes.string,
  heading: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

HowItWorksBlock.defaultProps = {
  anchorId: '',
  heading: '',
}
export default HowItWorksBlock
