import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import mediaQueries from '../../../styles/mediaQueries'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  ${({ height }) => (height ? `height: ${height}px;` : '')}
  ${({ isLeftAlign }) =>
    isLeftAlign &&
    css`
      justify-content: flex-start;
    `};
  ${({ isPipes }) =>
    isPipes &&
    css`
      overflow: hidden;
      justify-content: flex-start;
      width: 100%;
    `}

  ${mediaQueries.tabletPortrait`
    ${({ isMobileCenter }) =>
      isMobileCenter &&
      css`
        justify-content: center;
      `}
  `};
`

const Item = styled.li`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  color: ${gigplanetTheme.element.tag.text};
  background: ${gigplanetTheme.element.tag.background};
  padding: 0 10px;
  border-radius: 4px;
  font-size: 12px;
  margin: 2px 4px 2px 0;
  text-decoration: none;
  height: 18px;

  ${mediaQueries.mobile`
    font-size: 10px;
    margin: 2px 2px 2px 0;
    padding: 0 6px;
    height: 15px;

    ${({ isLarge }) =>
      isLarge &&
      css`
        height: 22px;
        font-size: 14px;
      `}
  `};

  &:last-child {
    margin-right: 0;
  }

  ${({ isPipes }) =>
    !isPipes &&
    css`
      &:first-child {
        margin-left: 0;
      }
    `};

  ${({ isLeftAlign }) =>
    isLeftAlign &&
    css`
      margin-left: 0;
      margin-right: 4px;
    `};

  ${mediaQueries.tabletPortrait`
    ${({ isMobileCenter }) =>
      isMobileCenter &&
      css`
        margin-left: 2px;
        margin-right: 2px;
      `};
  `};

  ${({ isPipes }) =>
    isPipes &&
    css`
      background: none;
      padding: 0;
      margin: 2px 20px 2px 0px;
      font-size: 13px;
      line-height: 18px;
      position: relative;
      color: #575757;
      left: 0px;

      ${mediaQueries.mobile`
        padding: 0;
        margin: 2px 20px 2px 0px;
        font-size: 11px;
      `};

      &:after {
        content: '•';
        position: absolute;
        top: 0px;
        font-size: 10px;
        bottom: 0;
        left: -11px;
        ${mediaQueries.mobile`
          top: -2px;
        `};
      }
    `};
`

const ItemLink = styled(Link)`
  color: ${gigplanetTheme.element.tag.text};
  text-decoration: none;
`

const PublicGenresList = ({
  genresList,
  genres,
  height,
  leftAlign,
  large,
  pipes,
  mobileCenter,
}) => {
  if (!genresList || !(genres && genres.length > 0)) {
    return null
  }

  const genresListElements = genres
    .filter((genre) => genresList[genre])
    .map((genre) => (
      <Item key={genre} isLarge={large} isPipes={pipes} isLeftAlign={leftAlign}>
        {pipes ? (
          genresList[genre].name
        ) : (
          <ItemLink
            to={{
              pathname: '/s',
              search: `?genre=${genre}`,
            }}
          >
            {genresList[genre].name}
          </ItemLink>
        )}
      </Item>
    ))
  return (
    <List isPipes={pipes} isLeftAlign={leftAlign} isMobileCenter={mobileCenter} height={height}>
      {genresListElements}
    </List>
  )
}

PublicGenresList.propTypes = {
  leftAlign: PropTypes.bool,
  mobileCenter: PropTypes.bool,
  large: PropTypes.bool,
  pipes: PropTypes.bool,
  height: PropTypes.number,
  genres: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  genresList: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

PublicGenresList.defaultProps = {
  large: false,
  mobileCenter: false,
  pipes: false,
  leftAlign: false,
  height: 0,
}

export default PublicGenresList
