/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react'
import PropTypes from 'prop-types'
import { Editor, EditorState, RichUtils } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'
import Label from '../Label'

import EditorBlockStyleControls from './components/block-style-controls'
import EditorInlineStyleControls from './components/inline-style-controls'
import { StyledRichTextEditor, StyledRichText, HelpText } from './style'

const propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

const defaultValue = {
  value: '',
}

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props)
    let editorState
    const { value } = props
    if (value.trim() !== '') {
      editorState = EditorState.createWithContent(stateFromHTML(value))
      // move focus to the end.
      editorState = EditorState.moveFocusToEnd(editorState)
    } else {
      editorState = EditorState.createEmpty()
    }

    this.state = {
      editorState,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = this.props
    if (nextProps.value !== value) {
      this.setState({
        editorState: EditorState.createWithContent(stateFromHTML(nextProps.value)),
      })
    }
  }

  handleKeyCommand = command => {
    const { editorState } = this.state
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.handleChange(newState)
      return true
    }
    return false
  }

  toggleInlineStyle = inlineStyle => {
    const { editorState } = this.state
    this.handleChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  toggleBlockType = blockType => {
    const { editorState } = this.state
    this.handleChange(RichUtils.toggleBlockType(editorState, blockType))
  }

  handleChange = editorState => {
    const { onChange } = this.props
    this.setState({
      editorState,
    })
    onChange(stateToHTML(editorState.getCurrentContent()))
  }

  focus = () => {
    if (this.editor) {
      this.editor.focus()
    }
  }

  render() {
    const { helpText, label, placeholder } = this.props
    const { editorState } = this.state

    if (editorState === null) return null

    const selectionState = editorState.getSelection()
    const contentState = editorState.getCurrentContent()

    let hidePlaceholder = false
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      ) {
        hidePlaceholder = true
      }
    }

    return (
      <>
        <Label onClick={this.focus}>{label}</Label>
        <StyledRichText hasFocus={selectionState.getHasFocus()} onClick={this.focus}>
          <EditorBlockStyleControls editorState={editorState} onToggle={this.toggleBlockType} />
          <EditorInlineStyleControls editorState={editorState} onToggle={this.toggleInlineStyle} />
          <StyledRichTextEditor hidePlaceholder={hidePlaceholder} onClick={this.focus}>
            <Editor
              editorState={editorState}
              onChange={this.handleChange}
              placeholder={placeholder}
              handleKeyCommand={this.handleKeyCommand}
              ref={r => {
                this.editor = r
              }}
              spellCheck
            />
          </StyledRichTextEditor>
        </StyledRichText>
        {helpText ? <HelpText>{helpText}</HelpText> : null}
      </>
    )
  }
}

RichTextEditor.propTypes = propTypes
RichTextEditor.defaultValue = defaultValue

export default RichTextEditor
