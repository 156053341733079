import * as React from 'react'
import PropTypes from 'prop-types'

import { ArtistShape } from '../../models/Artist'
import ArtistList from '../../containers/ArtistList'
import SearchFilterContainer from '../../containers/search-filters'
import SearchPageSort from './components/SearchSortLine'
import Loader from '../../ui/Loader'
import Button from '../../ui/Button'

import {
  SearchWrapper,
  Content,
  Container,
  Filter,
  SearchResults,
  Actions,
  Results,
  Empty,
  ResultLoader,
} from './styles'

const SearchPage = ({
  artists = [],
  isMore,
  onLoadMore,
  isLoading,
  updateSearch,
  resetSearch,
  totalCount,
  sortField,
  sortDir,
  onSortChange,
  onTrackClick,
  startWithOpenFilter = false,
}) => {
  let artistListElement = null
  let loaderElement = null

  if (artists && artists.length > 0) {
    artistListElement = (
      <Results isLoading={isLoading}>
        <ArtistList
          isSearch
          artists={artists}
          isMore={isMore}
          onLoadMore={onLoadMore}
          searchView
          onTrackClick={onTrackClick}
        />
      </Results>
    )
  } else if (!isLoading) {
    artistListElement = <Empty>Kunne ikke finne noen artister som passer søket ditt</Empty>
  } else {
    loaderElement = (
      <ResultLoader>
        <Loader text="Søker etter artister" />
      </ResultLoader>
    )
  }
  return (
    <SearchWrapper>
      <Content>
        <Container>
          <Filter>
            <SearchFilterContainer
              updateSearch={updateSearch}
              resetSearch={resetSearch}
              startWithOpenFilter={startWithOpenFilter}
            />
          </Filter>
          <SearchResults>
            <SearchPageSort
              count={artists.length}
              totalCount={totalCount}
              sortField={sortField}
              sortDir={sortDir}
              onChange={onSortChange}
            />
            {artistListElement}
            {loaderElement}
            {isMore ? (
              <Actions>
                <Button type="button" theme="outline" onClick={onLoadMore} loading={isLoading}>
                  Vis flere
                </Button>
              </Actions>
            ) : null}
          </SearchResults>
        </Container>
      </Content>
    </SearchWrapper>
  )
}

SearchPage.propTypes = {
  artists: PropTypes.arrayOf(ArtistShape),
  isLoading: PropTypes.bool.isRequired,
  isMore: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  onSortChange: PropTypes.func.isRequired,
  sortField: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
  updateSearch: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  onTrackClick: PropTypes.func.isRequired,
  startWithOpenFilter: PropTypes.bool,
}

export default SearchPage
