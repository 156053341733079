import * as React from 'react'
import PropTypes from 'prop-types'

import { Container } from './style'
import Loader from '../../ui/Loader'

const PageLoader = ({ text }) => (
  <Container>
    <Loader text={text} />
  </Container>
)

PageLoader.propTypes = {
  text: PropTypes.string,
}

PageLoader.defaultProps = {
  text: '',
}

export default PageLoader
