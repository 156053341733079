import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import MetaData from '../../components/MetaData'

import VerifyEmailErrorView from '../../views/verify-email-error'

const VerifyEmailErrorRoute = ({ history, history: { location } }) => {
  let url = '/'
  const query = queryString.parse(location.search)
  if (query && query.url) {
    url = query.url
  }

  return (
    <>
      <MetaData title="Verifisering av e-post feilet" description="" noindex />
      <VerifyEmailErrorView
        successUrl={url}
        onClose={() => {
          history.push('/')
        }}
      />
    </>
  )
}

VerifyEmailErrorRoute.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      push: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
}

VerifyEmailErrorRoute.defaultProps = {}

export default compose(withRouter)(VerifyEmailErrorRoute)
