/* globals   FB, window */
import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { getPathWithDomain } from '../../../../models/Share'
import { trackEvent } from '../../../../libs/analytics'
import { FacebookIcon, TwitterIcon, LinkedinIcon, EmailOutlineIcon } from '../../../../icons'
import {
  ShareContainer,
  Heading,
  ButtonContainer,
  ShareButton,
  ShareButtonContent,
  ShareButtonLink,
} from './style'

const shareEmail = artistName => {
  trackEvent('artist-profile', 'artist-profile-share-email-button', artistName)
}

const shareFB = (url, artistName) => {
  trackEvent('artist-profile', 'artist-profile-share-facebook-button', artistName)
  if (window) {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      'Facebook',
      'menubar=no, toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600',
    )
  }
}

const shareTwitter = (text, artistName) => {
  trackEvent('artist-profile', 'artist-profile-share-twitter-button', artistName)
  if (window) {
    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURI(text)}`,
      'Twitter',
      'menubar=no, toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600',
    )
  }
}

const shareLinkedin = (url, title, summary, artistName) => {
  trackEvent('artist-profile', 'artist-profile-share-linkedin-button', artistName)
  if (window) {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${encodeURI(
        title,
      )}&summary=${encodeURI(summary)}&source=Gigplanet`,
      'Linkedin',
      'menubar=no, toolbar=no,resizable=yes,scrollbars=yes,height=400,width=600',
    )
  }
}

const ProfileDetailsShare = React.memo(({ artistName, location, ...rest }) => {
  const url = getPathWithDomain(location.pathname)
  const title = artistName
  const twitterText = `Sjekk ut ${title} her: ${url}`
  const summary = `Sjekk ut ${title} på Gigplanet`
  const emailLink = `mailto:?subject=${encodeURI(artistName)}&body=${encodeURI(twitterText)}`
  return (
    <ShareContainer>
      <Heading>Del {artistName}:</Heading>
      <ButtonContainer>
        <ShareButton
          aria-label="Del på Facebook"
          type="button"
          onClick={() => {
            shareFB(getPathWithDomain(location.pathname), artistName)
          }}
        >
          <ShareButtonContent>
            <FacebookIcon width="20" height="20" />
          </ShareButtonContent>
        </ShareButton>
        <ShareButton
          aria-label="Del på Twitter"
          type="button"
          onClick={() => {
            shareTwitter(twitterText, artistName)
          }}
        >
          <ShareButtonContent>
            <TwitterIcon width="20" height="20" />
          </ShareButtonContent>
        </ShareButton>
        <ShareButton
          type="button"
          aria-label="Del på Linkedin"
          onClick={() => {
            shareLinkedin(url, title, summary, artistName)
          }}
        >
          <ShareButtonContent>
            <LinkedinIcon width="20" height="20" />
          </ShareButtonContent>
        </ShareButton>
        <ShareButtonLink
          href={emailLink}
          aria-label="Del på epost"
          onClick={() => {
            shareEmail(artistName)
          }}
        >
          <ShareButtonContent>
            <EmailOutlineIcon width="20" height="20" />
          </ShareButtonContent>
        </ShareButtonLink>
      </ButtonContainer>
    </ShareContainer>
  )
})

ProfileDetailsShare.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  artistName: PropTypes.string.isRequired,
}

ProfileDetailsShare.defaultProps = {}

export default withRouter(ProfileDetailsShare)
