import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const VideoIcon = props => (
  <SVGWrapper width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M15.984 11.016l-6.984 3.984v-8.016zM21 17.016v-12h-18v12h18zM21 3c1.078 0 2.016 0.891 2.016 2.016l-0.047 12c0 1.078-0.891 1.969-1.969 1.969h-5.016v2.016h-7.969v-2.016h-5.016c-1.125 0-2.016-0.891-2.016-1.969v-12c0-1.125 0.891-2.016 2.016-2.016h18z" />
  </SVGWrapper>
)

export default VideoIcon
