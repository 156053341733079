import styled, { css } from 'styled-components'
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const ProfileDetailsVideoItems = styled.div`
  border-color: ${gigplanetTheme.color.lightMediumGray};
  border-bottom: 1px solid ${gigplanetTheme.color.lightMediumGray};
`
export const ProfileDetailsVideoItem = styled.li`
  display: inline-block;
  max-width: 600px;
  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }

  ${mediaQueries.mobile`
    padding: 0 ${gigplanetTheme.spacing.xxsmall};
    width: 90%;
    flex: 1 0 90%;   
  `};

  ${mediaQueries.tabletPortraitUp`
    padding: 0 ${gigplanetTheme.spacing.small};
    width: 30%;
    flex: 1 0 30%;
  `};

  ${({ hasOne }) =>
    hasOne &&
    css`
      ${mediaQueries.mobile`
        width: 100%;
      `};

      ${mediaQueries.tabletPortraitUp`
        width: 60%;
        flex: 1 0 60%;
      `};
    `};

  ${({ hasTwo }) =>
    hasTwo &&
    css`
      ${mediaQueries.tabletPortraitUp`
        width: 45%;
      `};
    `};
`
export const ProfileDetailsVideoItemsReadMore = styled.div`
  padding: 0 0 2.35765%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${mediaQueries.mobile`
    display: none;
  `};
`
export const ProfileDetailsVideoItemsReadMoreButton = styled.div`
  bottom: 50%;
  padding-right: 2.35765%;
  position: absolute;
  transform: translateY(50%);
  width: 200px;
`
export const ProfileDetailsVideoItemReadMoreListItem = styled(ProfileDetailsVideoItem)`
  ${mediaQueries.tabletPortraitUp`
    display: none;
  `};

  ${mediaQueries.mobile`
    display: inline-block;
    width: 200px;
  `};
`
