import * as React from 'react'
import PropTypes from 'prop-types'

import { ErrorWrapper } from './style'

const UserAuthErrorWrapper = ({ children }) => <ErrorWrapper>{children}</ErrorWrapper>

UserAuthErrorWrapper.propTypes = {
  children: PropTypes.element.isRequired,
}

export default React.memo(UserAuthErrorWrapper)
