/* globals window */
import * as React from 'react'
import PropTypes from 'prop-types'

import { SupportUnreadCount, FavoriteCount, ExtraSpacer, DesktopMenu } from './style'
import { showIntercom, hideIntercom } from './util'

import HeaderMenuList from '../../../ui/header-menu-list'
import HeaderMenuItem from '../../../ui/header-menu-list-item'
import HeaderMenuButton from '../../../ui/header-menu-button'
import HeaderBurgerButton from '../../../ui/header-burger-button'
import SiteNavigation from '../../../components/SiteNavigation'
import { SearchIcon, HeartEmptyIcon, HeartIconFilled } from '../../../icons'
import UserAuthModal from '../../../components/user-auth-modal'
import { ModalButton, ModalContent } from '../../../components/ModalWindow'
import Button from '../../../ui/Button'

const LoggedOutDektopMenu = React.memo(
  ({ successUrl, showSearch = false, favoriteCount, hasDarkBg, focusCallback }) => {
    const [showingLogin, setShowLogin] = React.useState(false)
    const [showingSiteNav, setShowSiteNav] = React.useState(false)

    React.useEffect(() => {
      focusCallback(showingSiteNav)
    }, [showingSiteNav])

    const openSiteNav = () => {
      setShowSiteNav(true)
    }

    const closeSiteNav = () => {
      setShowSiteNav(false)
    }

    const toggleSiteNav = () => {
      setShowSiteNav(!showingSiteNav)
    }

    const openLogin = () => {
      closeSiteNav()
      hideIntercom()
      setShowLogin(true)
    }

    const closeLogin = () => {
      setShowLogin(false)
    }

    return (
      <DesktopMenu>
        <HeaderMenuList>
          <HeaderMenuItem>
            <HeaderMenuButton
              to="/favorites"
              title="Gå til favoritter"
              aria-label="Gå til favoritter"
            >
              <HeartIconFilled height={30} width={30} />
              {favoriteCount ? <FavoriteCount>{favoriteCount}</FavoriteCount> : null}
            </HeaderMenuButton>
          </HeaderMenuItem>
          {showSearch ? (
            <HeaderMenuItem>
              <ExtraSpacer>
                <Button
                  type="button"
                  theme="primary"
                  to={{
                    pathname: '/s',
                    state: { fromHeaderMenu: true },
                  }}
                  title="Finn musikere"
                  aria-label="Finn musikere"
                >
                  Finn musikere
                </Button>
              </ExtraSpacer>
            </HeaderMenuItem>
          ) : null}

          <HeaderMenuItem>
            <>
              <ModalButton
                id="header-login-window"
                isOpen={showingLogin}
                onClick={openLogin}
                title="Logg inn"
                aria-label="Logg inn"
                renderButton={(props) => (
                  <Button {...props} type="button" theme="secondary" aria-haspopup="dialog">
                    Logg inn
                  </Button>
                )}
              />
              <ModalContent
                id="header-login-window"
                label="Logg inn"
                isOpen={showingLogin}
                onClose={closeLogin}
                hideClose
              >
                <UserAuthModal onClose={closeLogin} successUrl={successUrl} />
              </ModalContent>
            </>
          </HeaderMenuItem>
          <HeaderMenuItem onClick={toggleSiteNav}>
            <HeaderBurgerButton
              aria-label="Hovednavigasjonsmeny"
              aria-haspopup="true"
              isOpen={showingSiteNav}
              hasDarkBg={hasDarkBg}
            />
          </HeaderMenuItem>
        </HeaderMenuList>
        <SiteNavigation isOpen={showingSiteNav} closeSiteNav={closeSiteNav} />
      </DesktopMenu>
    )
  },
)

LoggedOutDektopMenu.propTypes = {
  successUrl: PropTypes.string.isRequired,
  showSearch: PropTypes.bool,
  favoriteCount: PropTypes.number.isRequired,
  hasDarkBg: PropTypes.bool,
  focusCallback: PropTypes.func,
}

export default LoggedOutDektopMenu
