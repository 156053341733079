import styled, { keyframes } from 'styled-components'

import mediaQueries from '../../../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../../../styles/gigplanetTheme'

const verticalPosition = '-80%'

export const WaveAnimation = keyframes`
  from {
    right: 0%;
  }

  to {
    right: -100%;
  }
`

export const Container = styled.div`
  position: absolute;
  right: 50%;
  top: 55%;
  width: 200%;
  height: auto;
  transform: translateY(${verticalPosition});
  animation-duration: 15s;
  animation-name: ${WaveAnimation};
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
  ${mediaQueries.tabletPortraitDown`
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  `};
  ${mediaQueries.desktop`
    top: 65%;
  `};

  @media screen and (min-width: 1400px) {
    top: 75%;
  }
`

export const Wave = styled.img`
  width: 100%;
  height: auto;
`
