// TODO: Should optimize this code!!!! (Later when got time)
export const sortRepSongByArtist = (songA, songB) => {
  const artistA = songA ? songA.artistLower : ''
  const artistB = songB ? songB.artistLower : ''
  const sA = songA ? songA.songLower : ''
  const sB = songB ? songB.songLower : ''

  if (artistA < artistB) {
    return -1
  }

  if (artistA > artistB) {
    return 1
  }

  if (sA < sB) {
    return -1
  }

  if (sA > sB) {
    return 1
  }

  return 0
}

export const sortRepSongByArtistDesc = (songA, songB) => {
  const artistA = songA ? songA.artistLower : ''
  const artistB = songB ? songB.artistLower : ''
  const sA = songA ? songA.songLower : ''
  const sB = songB ? songB.songLower : ''

  if (artistA < artistB) {
    return 1
  }

  if (artistA > artistB) {
    return -1
  }

  if (sA < sB) {
    return 1
  }

  if (sA > sB) {
    return -1
  }

  return 0
}

export const sortRepSongBySong = (songA, songB) => {
  const artistA = songA ? songA.artistLower : ''
  const artistB = songB ? songB.artistLower : ''
  const sA = songA ? songA.songLower : ''
  const sB = songB ? songB.songLower : ''

  if (sA < sB) {
    return -1
  }

  if (sA > sB) {
    return 1
  }

  if (artistA < artistB) {
    return -1
  }

  if (artistA > artistB) {
    return 1
  }

  return 0
}

export const sortRepSongBySongDesc = (songA, songB) => {
  const artistA = songA ? songA.artistLower : ''
  const artistB = songB ? songB.artistLower : ''
  const sA = songA ? songA.songLower : ''
  const sB = songB ? songB.songLower : ''

  if (sA < sB) {
    return 1
  }

  if (sA > sB) {
    return -1
  }

  if (artistA < artistB) {
    return 1
  }

  if (artistA > artistB) {
    return -1
  }

  return 0
}

export const sortRepSongByYear = (songA, songB) => {
  if (!songA || !songB) return 0
  if (songA.year < songB.year) {
    return -1
  }

  if (songA.year > songB.year) {
    return 1
  }

  return sortRepSongByArtist(songA, songB) // sort by artist, song if same year
}

export const sortRepSongByYearDesc = (songA, songB) => {
  if (!songA || !songB) return 0
  if (songA.year < songB.year) {
    return 1
  }

  if (songA.year > songB.year) {
    return -1
  }

  return sortRepSongByArtist(songA, songB) // sort by artist, song if same year
}
