import styled, { css } from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const InputSearchContainer = styled.div`
  max-width: 450px;
  min-width: 250px;
  position: relative;
  width: 100%;

  ${({ expanded }) =>
    expanded &&
    css`
      max-width: 100%;
    `}
`

export const SearchIconWrapper = styled.span`
  height: 24px;
  left: 7px;
  position: absolute;
  top: 7px;

  svg {
    height: 24px;
    width: 24px;
    fill: #82888a;
  }
`

export const ValueMarker = styled.span`
  position: absolute;
  background-color: ${gigplanetTheme.color.primary};
  border-radius: 3px;
  display: inline-block;
  height: 6px;
  width: 6px;
  top: 16px;
  right: 13px;
`

export const InputElement = styled.input`
  appearance: none;
  background-color: ${gigplanetTheme.component.form.input.background};
  border: 1px solid ${gigplanetTheme.component.form.input.border};
  border-radius: 2px;
  color: ${gigplanetTheme.component.form.input.text};
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  padding: 8px 10px 8px 36px;
  transition: border-color 0.2s ease-in-out;
  width: 100%;

  &[disabled] {
    background-color: ${gigplanetTheme.component.form.input.backgroundDisabled};
    color: ${gigplanetTheme.component.form.input.textDisabled};
  }

  &::-webkit-clear-button {
    display: none;
  }

  &::-ms-clear {
    display: none;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`
