import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Route, Switch, withRouter } from 'react-router'

import { getOneArtistRequestAction } from '../../../../stores/GigRequestArtist/GigRequestArtistActions'

import Signed from './signed'
import Preview from './preview'
import PageNotFoundRoute from '../../../404'
import MetaData from '../../../../components/MetaData'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  getRequest: (gigRequestId, artistId) => {
    dispatch(getOneArtistRequestAction(gigRequestId, artistId))
  },
})

class PrintQuoteArtistRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const {
      history: { location },
      getRequest,
    } = this.props
    const query = queryString.parse(location.search)

    if (query.gigRequestId && query.artistId) {
      getRequest(query.gigRequestId, query.artistId)
    }
  }

  render() {
    return (
      <>
        {/* <MetaData title="Kontrakt fra Gigplanet" description="" nopin /> */}
        <Switch>
          <Route path="/print/quote/artist/signed" component={Signed} />
          <Route path="/print/quote/artist/preview" component={Preview} />
          <Route component={PageNotFoundRoute} />
        </Switch>
      </>
    )
  }
}

PrintQuoteArtistRoute.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  }).isRequired,

  // dispatch props
  getRequest: PropTypes.func.isRequired,
}
PrintQuoteArtistRoute.defaultProps = {}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PrintQuoteArtistRoute)
