import * as React from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { useSelector } from 'react-redux'

import { getSiteSettingsSelector } from '../../stores/cms-content/reducer'

import gigplanetTheme from '../../styles/gigplanetTheme'

import FooterNavigation from './components/FooterNavigation'
import FooterPageRegistry from './components/FooterPageRegistry'
import FooterContact from './components/FooterContact'
import mediaQueries from '../../styles/mediaQueries'

const Container = styled.footer`
  background: ${gigplanetTheme.element.footer.background};
  clear: both;
  padding: 0 ${gigplanetTheme.spacing.xsmall};
  display: flex;
  justify-content: center;
`

const Wrapper = styled.div`
  max-width: ${gigplanetTheme.measure.maxWidth};
  display: flex;
  flex-direction: column;
  width: 100%;
`

const MadeBy = styled.div`
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: ${gigplanetTheme.element.footer.text};
  padding: 60px 0;

  ${mediaQueries.mobile`
    padding-left: ${gigplanetTheme.spacing.medium};
    padding-right: ${gigplanetTheme.spacing.medium};
    text-align: left;
  `};
`

const Footer = () => {
  const siteSettings = useSelector(getSiteSettingsSelector)
  return (
    <div>
      <Container>
        <Wrapper>
          <FooterNavigation navigation={siteSettings.footer.navigation} />
          <FooterContact settings={siteSettings} />
          <MadeBy>
            Gigplanet AS · Org.nr 914 535 832 · © {moment().format('YYYY')} Gigplanet AS – alle
            rettigheter
          </MadeBy>
        </Wrapper>
      </Container>
      <FooterPageRegistry navigation={siteSettings.footer.pageRegistry} />
    </div>
  )
}

export default React.memo(Footer)
