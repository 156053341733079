import * as React from 'react'
import PropTypes from 'prop-types'

import { CurrentUserShape } from '../../models/CurrentUser'
import {
  Container,
  ProfileFormWrapper,
  ProfileFormHeader,
  UserError,
  UserActions,
  UserActionButtons,
  UserLoading,
  UserActionSaveInfo,
  UserActionSaveInfoText,
} from './style' // , Wrapper, List, Item, EmptyMessage

import Row from '../FormRow'
import Column from '../FormColumn'
import { Input, Errormessage } from '../../ui/Form'
import Button from '../../ui/Button'
import Loader from '../../ui/Loader'
import { H1 } from '../../ui/Headings'
import ConfirmButton from '../confirm-button'
import { postJSON } from '../../libs/data'

const CurrentuserSettings = ({
  isUpdating,
  hasUpdated,
  currentUser,
  handleUserChange,
  errorMessage,
  handleSave,
}) => {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [hasDeleted, setHasDeleted] = React.useState(false)

  const handleDeleteRequest = async () => {
    setIsDeleting(true)
    try {
      const response = await postJSON('/api/v1/user/deleteRequest')
      console.log({ response })
      if (response.success) {
        setHasDeleted(true)
      }
      setIsDeleting(false)
    } catch (e) {
      setIsDeleting(false)
    }
  }
  let saveInfo = null
  if (isUpdating) {
    saveInfo = (
      <UserLoading>
        <Loader text="Lagrer&hellip;" />
      </UserLoading>
    )
  } else if (hasUpdated) {
    saveInfo = (
      <UserActionSaveInfo>
        <UserActionSaveInfoText>Lagret</UserActionSaveInfoText>
      </UserActionSaveInfo>
    )
  }
  let buttonLabel = 'Forespør sletting'
  if (isDeleting) {
    buttonLabel = 'Sender forespørsel'
  }
  if (hasDeleted) {
    buttonLabel = 'Forespørsel sendt'
  }

  return (
    <Container>
      <ProfileFormWrapper>
        <ProfileFormHeader>
          <H1>Rediger din profil</H1>
        </ProfileFormHeader>
        {hasDeleted ? (
          <UserError>
            Forespørsel sendt. Brukeren din vil bli slettet så snart vi har verifisert forespørselen
          </UserError>
        ) : (
          <>
            <Row>
              <Column>
                <Input
                  id="firstname-text"
                  label="Fornavn"
                  placeholder="Fyll ut fornavn"
                  value={currentUser.firstName ? currentUser.firstName : ''}
                  onChange={(e) => handleUserChange(e, 'firstName')}
                  expanded
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Input
                  id="middlename-text"
                  label="Mellomnavn"
                  placeholder="Fyll ut mellomnavn"
                  value={currentUser.middleName ? currentUser.middleName : ''}
                  onChange={(e) => handleUserChange(e, 'middleName')}
                  expanded
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Input
                  id="lastname-text"
                  label="Etternavn"
                  placeholder="Fyll ut etternavn"
                  value={currentUser.lastName ? currentUser.lastName : ''}
                  onChange={(e) => handleUserChange(e, 'lastName')}
                  expanded
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Input
                  id="email-text"
                  label="E-post"
                  placeholder="Fyll ut e-post"
                  value={currentUser.email ? currentUser.email : ''}
                  onChange={(e) => handleUserChange(e, 'email')}
                  expanded
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Input
                  id="phone-text"
                  label="Telefonnummer"
                  placeholder="Fyll ut telefonnummer"
                  value={currentUser.phone ? currentUser.phone : ''}
                  onChange={(e) => handleUserChange(e, 'phone')}
                  expanded
                />
              </Column>
            </Row>
            {errorMessage ? (
              <UserError>
                <Errormessage messages={[errorMessage]} />
              </UserError>
            ) : null}
            <UserActions>
              <UserActionButtons>
                <Button theme="primary" loading={isUpdating} type="save" onClick={handleSave}>
                  {isUpdating ? 'Lagrer' : 'Lagre'}
                </Button>
                <ConfirmButton
                  heading="Forespørsel om sletting"
                  theme="abort"
                  confirmMessage="Er du sikker på at du ønsker å slette brukeren?"
                  confirmButtonText="Forespør sletting"
                  onConfirmed={handleDeleteRequest}
                >
                  {isDeleting ? 'Forespør sletting' : 'Sender forespørsel'}
                </ConfirmButton>
              </UserActionButtons>
              {saveInfo}
            </UserActions>
          </>
        )}
      </ProfileFormWrapper>
    </Container>
  )
}
CurrentuserSettings.propTypes = {
  currentUser: CurrentUserShape.isRequired,
  handleUserChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isUpdating: PropTypes.bool,
  hasUpdated: PropTypes.bool,
}
CurrentuserSettings.defaultProps = {
  errorMessage: null,
  isUpdating: false,
  hasUpdated: false,
}

export default CurrentuserSettings
