import styled from 'styled-components'
import gigplanetTheme from '../../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../../styles/mediaQueries'

export const RequestInfo = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 10px 10px 0;
`

export const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 10px 10px 0;
`

export const RadioWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  > * {
    margin: 0 5px;
    width: 100%;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

export const TimeWrapper = styled.div`
  margin-top: 10px;
`
