import styled from 'styled-components'
import mediaQueries from '../../../styles/mediaQueries'
import gigplanetTheme from '../../../styles/gigplanetTheme'

export const StyledGigRequestEventplannerRequestDetails = styled.div`
  width: 100%;

  ${mediaQueries.mobile`
    order: 1;
    padding: 0;
  `};
`

export const AdminWarning = styled.div`
  width: 100%;
  padding-bottom: ${gigplanetTheme.spacing.medium};

  ${mediaQueries.mobile`
    padding-bottom: 15px;
  `};
`
export const Options = styled.div`
  padding-bottom: 15px;
`

export const Timeline = styled.div`
  padding-top: 15px;
`
