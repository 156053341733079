import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'

import { imageUrlFor } from '../../../helpers/sanity-image-builder'
import SanityImage from '../../sanity-image'
import mediaQueries, { mediaQueriesDefinitions } from '../../../styles/mediaQueries'

import { CameraIcon } from '../../../icons'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const Container = styled.div`
  width: 100%;
  margin: 1rem 0;

  picture {
    display: block;
  }
`
const ImageText = styled.span`
  color: ${gigplanetTheme.color.darkGray};
  font-size: 0.8rem;
  width: 100%;
  text-align: center;
  display: block;
  margin-top: 0.5rem;
  font-weight: 300;
`

const ImageWrapper = styled.div`
  width: 100%;

  img {
    min-width: 100%;
  }
`
const Placeholder = styled.div`
  width: 100%;
  min-height: 202px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${gigplanetTheme.color.lightGray};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid white;

  svg {
    color: #fff;
    height: 98px;
    width: 98px;
  }
`

const BodyImage = React.memo(({ image, altText }) => {
  const [visible, setVisible] = React.useState(false)

  const handleVisibilityChange = (isVisible) => {
    if (visible || !isVisible) {
      return
    }
    setVisible(true)
  }

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      active={!visible}
      offset={{ bottom: -150 }}
    >
      <Container>
        {visible && image ? (
          <ImageWrapper>
            <SanityImage width={60} image={image} altText={altText}>
              <source
                media={mediaQueriesDefinitions.mobileUp}
                srcSet={imageUrlFor(image).width(680).fit('max')}
              />
            </SanityImage>
          </ImageWrapper>
        ) : (
          <Placeholder>
            <CameraIcon />
          </Placeholder>
        )}
        {altText ? <ImageText>{altText}</ImageText> : null}
      </Container>
    </VisibilitySensor>
  )
})

BodyImage.propTypes = {
  image: PropTypes.shape({}).isRequired,

  altText: PropTypes.string.isRequired,
}

export default BodyImage
