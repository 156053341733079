import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { Wrapper } from './style'

import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'

import { getCurrentUser as getCurrentUserSelector } from '../../../../stores/CurrentUser/CurrentUserReducer'
import Button from '../../../../ui/Button'
import { ModalButton, ModalContent } from '../../../ModalWindow'
import UserAuthModal from '../../../user-auth-modal'

const artistRegisterUrl = '/join/artist'
const successUrl = '/join/artist'

const mapStateToProps = state => ({
  currentUser: getCurrentUserSelector(state),
})

const mapDispatchToProps = () => ({})

const RegisterArtistButtonBlock = React.memo(({ theme, anchorId, buttonText, currentUser }) => {
  const [showingLogin, setShowingLogin] = React.useState(false)

  const openLogin = () => {
    setShowingLogin(true)
  }

  const closeLogin = () => {
    setShowingLogin(false)
  }

  if (currentUser && {}.hasOwnProperty.call(currentUser, 'id')) {
    return (
      <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
        <Wrapper>
          <Button theme="primary" to={artistRegisterUrl}>
            {buttonText}
          </Button>
        </Wrapper>
      </LandingPageBlockWrapper>
    )
  }
  return (
    <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
      <Wrapper>
        <ModalButton
          id="join-login-window"
          isOpen={showingLogin}
          onClick={openLogin}
          theme="primary"
        >
          {buttonText}
        </ModalButton>
        <ModalContent
          id="join-login-window"
          label="Logg inn"
          isOpen={showingLogin}
          onClose={closeLogin}
          hideClose
        >
          <UserAuthModal
            intro="Du er kun et øyeblikk unna å kunne registrere artist"
            onClose={closeLogin}
            successUrl={successUrl}
            showRegister
            continueMode
          />
        </ModalContent>
      </Wrapper>
    </LandingPageBlockWrapper>
  )
})

RegisterArtistButtonBlock.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.string,
  }),
  anchorId: PropTypes.string,
}

RegisterArtistButtonBlock.defaultProps = {
  currentUser: null,
  anchorId: '',
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(RegisterArtistButtonBlock)
