let reuseableCrcTable
const makeCRCTable = () => {
  let c
  const crcTable = []
  for (let n = 0; n < 256; n++) {
    c = n
    for (let k = 0; k < 8; k++) {
      c = c & 1 ? 0xedb88320 ^ (c >>> 1) : c >>> 1 // eslint-disable-line no-bitwise
    }
    crcTable[n] = c
  }
  return crcTable
}

const crc32 = str => {
  const crcTable = reuseableCrcTable || (reuseableCrcTable = makeCRCTable())
  let crc = 0 ^ -1 // eslint-disable-line no-bitwise

  for (let i = 0; i < str.length; i++) {
    crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xff] // eslint-disable-line no-bitwise
  }

  return (crc ^ -1) >>> 0 // eslint-disable-line no-bitwise
}

const HSVtoRGB = (h, s, v) => {
  let r
  let g
  let b

  if (h && s === undefined && v === undefined) {
    s = h.s // eslint-disable-line
    v = h.v // eslint-disable-line
    h = h.h // eslint-disable-line
  }

  const i = Math.floor(h * 6)
  const f = h * 6 - i
  const p = v * (1 - s)
  const q = v * (1 - f * s)
  const t = v * (1 - (1 - f) * s)

  switch (i % 6) {
    case 0:
      r = v
      g = t
      b = p
      break
    case 1:
      r = q
      g = v
      b = p
      break
    case 2:
      r = p
      g = v
      b = t
      break
    case 3:
      r = p
      g = q
      b = v
      break
    case 4:
      r = t
      g = p
      b = v
      break
    case 5:
      r = v
      g = p
      b = q
      break
    default:
      break
  }

  return {
    r: Math.floor(r * 255),
    g: Math.floor(g * 255),
    b: Math.floor(b * 255),
  }
}

export default (uniqueId, gender) => {
  // 'uniqueId' can be anything unique for this user
  const crc = crc32(uniqueId).toString(16)
  let hash = parseInt(crc.substr(0, 4), 16) / 65536
  hash *= 180

  let hue
  let saturation = 0.55

  if (gender === 'male') {
    hue = 68 + hash
  } else if (gender === 'female') {
    hue = 248 + hash
    if (hue > 360) hue -= 360
  } else {
    hue = hash * 2
    saturation = 0.4
  }

  if (hue > 360) hue = 360
  else if (hue < 0) hue = 0

  const bgColor = HSVtoRGB(hue / 360, saturation, 0.8) // eslint-disable-line new-cap
  const fgColor = HSVtoRGB(hue / 360, saturation / 2.5, 1.0) // eslint-disable-line new-cap

  return {
    fg: `#${fgColor.r.toString(16)}${fgColor.g.toString(16)}${fgColor.b.toString(16)}`,
    bg: `#${bgColor.r.toString(16)}${bgColor.g.toString(16)}${bgColor.b.toString(16)}`,
  }
}
