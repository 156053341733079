import * as React from 'react'
import PropTypes from 'prop-types'

import {
  getArtistRequestStatusHeading,
  getEventplannerRequestStatusHeading,
  getArtistRequestStatusColor,
  getEventplannerRequestStatusColor,
} from '../../models/GigRequest'

import { Label } from './style'

const GigRequestStatusLabel = ({ requestStatus, quoteStatus, isArtist }) => {
  const statusColor = isArtist
    ? getArtistRequestStatusColor(requestStatus, quoteStatus)
    : getEventplannerRequestStatusColor(requestStatus, quoteStatus)
  const statusHeading = isArtist
    ? getArtistRequestStatusHeading(requestStatus, quoteStatus)
    : getEventplannerRequestStatusHeading(requestStatus, quoteStatus)

  return <Label color={statusColor}>{statusHeading}</Label>
}

GigRequestStatusLabel.propTypes = {
  requestStatus: PropTypes.string.isRequired,
  quoteStatus: PropTypes.string,
  isArtist: PropTypes.bool,
}
GigRequestStatusLabel.defaultProps = {
  quoteStatus: '',
  isArtist: false,
}

export default GigRequestStatusLabel
