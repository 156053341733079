import * as React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import styled from 'styled-components'

import ProfileDetailsSlider from '../../../ProfileDetailsSlider/ProfileDetailsSlider'
import Button from '../../../../ui/Button'
import {
  getImgixCroppedUrl,
  ARTIST_IMAGE_IMGIX_FORMAT_PROFILE_DESKTOP,
} from '../../../../models/Artist'

import {
  ProfileDetailsImageContainer,
  ProfileDetailsImageItem,
  ProfileDetailsImage,
  ProfileDetailsImageButton,
  ProfileDetailsImageCC,
} from './style'
import { Loader } from '../../../../ui'

const LoadingImages = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const ProfileDetailsImages = React.memo(
  ({ images, onOpenImageGallery, artistName, containerId }) => {
    const [visible, setVisible] = React.useState(false)

    const handleVisibilityChange = isVisible => {
      if (visible || !isVisible) {
        return
      }
      setVisible(true)
    }

    return (
      <VisibilitySensor
        onChange={handleVisibilityChange}
        partialVisibility
        active={!visible}
        offset={{ bottom: -150 }}
      >
        <ProfileDetailsImageContainer>
          <ProfileDetailsSlider heading="Bilder" containerId={containerId}>
            {visible ? (
              images.map((image, index) => (
                <ProfileDetailsImageItem
                  key={image.id}
                  itemScope
                  itemType="http://schema.org/ImageObject"
                >
                  <ProfileDetailsImageButton
                    type="button"
                    onClick={() => {
                      onOpenImageGallery(index + 1)
                    }}
                  >
                    <ProfileDetailsImage
                      src={getImgixCroppedUrl(
                        image.src,
                        ARTIST_IMAGE_IMGIX_FORMAT_PROFILE_DESKTOP,
                        image.crop,
                      )}
                      alt={
                        image.text
                          ? `${artistName} fotografert av ${image.text}`
                          : `Bilde nr ${index + 1} av ${artistName}`
                      }
                      itemProp="contentUrl"
                    />
                    {image.text ? (
                      <ProfileDetailsImageCC>Foto: {image.text}</ProfileDetailsImageCC>
                    ) : null}
                  </ProfileDetailsImageButton>
                  {image.text ? <meta itemProp="author" content={image.text} /> : null}
                </ProfileDetailsImageItem>
              ))
            ) : (
              <LoadingImages>
                <Loader text="Laster bilder" />
              </LoadingImages>
            )}
          </ProfileDetailsSlider>
        </ProfileDetailsImageContainer>
      </VisibilitySensor>
    )
  },
)

ProfileDetailsImages.propTypes = {
  artistName: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onOpenImageGallery: PropTypes.func.isRequired,
}

export default ProfileDetailsImages // React.memo
