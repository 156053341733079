import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import gigplanetTheme from '../../../styles/gigplanetTheme'

import { CameraIcon, VideoIcon, VolumeUpIcon } from '../../../icons'

const Container = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: -26px;
  margin-bottom: -26px;
`

const Placeholder = styled.div`
  height: 30px;
`

const Feature = styled.li`
  padding: 5px;
  font-size: 24px;
  text-align: center;
  line-height: 52px;
  width: 52px;
  height: 52px;
  border-radius: 100px;
  margin: 0 0 0 10px;
  background: ${gigplanetTheme.element.artistCard.featureBackground};
  background: ${gigplanetTheme.element.artistCard.featureGradient};

  svg {
    fill: ${gigplanetTheme.element.artistCard.feature};
  }
`

const ArtistCardFeatures = ({ gotImages, gotVideo, gotAudio }) => {
  if (!gotImages && !gotVideo && !gotAudio) {
    return <Placeholder />
  }

  return (
    <Container>
      {gotImages ? (
        <Feature>
          <CameraIcon height="24" width="24" />
        </Feature>
      ) : null}
      {gotVideo ? (
        <Feature>
          <VideoIcon height="24" width="24" />
        </Feature>
      ) : null}
      {gotAudio ? (
        <Feature>
          <VolumeUpIcon height="24" width="24" />
        </Feature>
      ) : null}
    </Container>
  )
}

ArtistCardFeatures.propTypes = {
  gotImages: PropTypes.bool,
  gotVideo: PropTypes.bool,
  gotAudio: PropTypes.bool,
}

ArtistCardFeatures.defaultProps = {
  gotImages: false,
  gotVideo: false,
  gotAudio: false,
}

export default ArtistCardFeatures
