import styled, { css } from 'styled-components'
import theme from 'styled-theming'

import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

const backgroundColor = theme('theme', {
  white: gigplanetTheme.color.white,
  gray: gigplanetTheme.color.lightGray,
  black: gigplanetTheme.color.black,
})

const desktopMargin = theme('theme', {
  white: gigplanetTheme.spacing.large,
  gray: 0,
  black: 0,
})

const desktopPadding = theme('theme', {
  white: 0,
  gray: gigplanetTheme.spacing.large,
  black: gigplanetTheme.spacing.large,
})

const mobileMargin = theme('theme', {
  white: gigplanetTheme.spacing.medium,
  gray: 0,
  black: 0,
})

const mobilePadding = theme('theme', {
  white: 0,
  gray: gigplanetTheme.spacing.medium,
  black: gigplanetTheme.spacing.medium,
})

export const Wrapper = styled.section`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${backgroundColor};

  padding: ${desktopPadding} 0;
  margin: ${desktopMargin} 0;

  ${mediaQueries.mobile`
    padding: ${mobilePadding} 0;
    margin: ${mobileMargin} 0;

    ${({ noPadding }) =>
      noPadding &&
      css`
        padding: 0;
        margin: 0;
      `}
    
  `};

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
      margin: 0;
    `}
`

export const Content = styled.div`
  padding: 0 ${gigplanetTheme.spacing.large};
  margin: 0;
  max-width: ${gigplanetTheme.measure.maxWidth};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${mediaQueries.mobile`
    padding: 0 ${gigplanetTheme.spacing.small};
    margin: 0;

    ${({ noPadding }) =>
      noPadding &&
      css`
        padding: 0;
        margin: 0;
      `}
  `};

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
      margin: 0;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: 100%;
    `}
`
