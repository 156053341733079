import styled from 'styled-components'

import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
`

export const Intro = styled.p`
  color: ${gigplanetTheme.element.frontpage.intro};
  font-size: 24px;
  line-height: 1.5;
  font-weight: 300;
  margin: 25px auto 30px;
  max-width: 560px;
  text-align: center;

  ${mediaQueries.mobile`
    font-size: 20px;
    margin: 15px 0 20px;
  `};
`

export const SearchWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mediaQueries.mobile`
    align-items: stretch;
    width: 100%;
  `};
`
