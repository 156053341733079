import * as React from 'react'
import PropTypes from 'prop-types'
import FocusTrap from 'focus-trap-react'
import styled from 'styled-components'
import noScroll from '../../libs/noScroll'
import ProfileDetailsImageGallery from '../ProfileDetailsImageGallery'
import mediaQueries from '../../styles/mediaQueries'
import { CloseIcon } from '../../icons'
import gigplanetTheme from '../../styles/gigplanetTheme'

const ImageContainer = styled.div`
  background: rgba(0, 0, 0, 0.95);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${gigplanetTheme.zIndex.imageGallery};
`
const ImageButton = styled.button`
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  width: 42px;
  height: 42px;
  color: white;
  overflow: hidden;
  z-index: ${gigplanetTheme.zIndex.imageGalleryButtons};

  svg {
    height: 42px;
    width: 42px;
  }
`
const ProfileDetailsImages = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
`

const ProfileDetailsImageGalleryFullscreen = React.memo(({ onClose, index, images }) => {
  React.useEffect(() => {
    noScroll.on()
    return () => {
      noScroll.off()
    }
  }, [])

  const handleOnNavigate = index => {}

  return (
    <FocusTrap>
      <ImageContainer>
        <ImageButton type="button" onClick={onClose}>
          <CloseIcon />
        </ImageButton>
        <ProfileDetailsImages>
          <ProfileDetailsImageGallery
            showHeader
            showFooter
            startAtIndex={index}
            images={images}
            onNavigate={handleOnNavigate}
          />
        </ProfileDetailsImages>
      </ImageContainer>
    </FocusTrap>
  )
})

ProfileDetailsImageGalleryFullscreen.propTypes = {
  index: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default ProfileDetailsImageGalleryFullscreen
