import * as React from 'react'
import PropTypes from 'prop-types'

import { Container } from './style'

import { Errormessage } from '../../../../ui/Form'

const GigRequestFormError = ({ errorMessages }) => {
  const messages = []
  if (errorMessages) {
    Object.keys(errorMessages).forEach(m => {
      messages.push(errorMessages[m])
    })
  }
  if (messages.length === 0) {
    return null
  }

  return (
    <Container>
      <Errormessage messages={messages} />
    </Container>
  )
}

GigRequestFormError.propTypes = {
  errorMessages: PropTypes.objectOf(PropTypes.string),
}

export default GigRequestFormError
