import * as React from 'react'
import PropTypes from 'prop-types'

import { Intro } from './style'

const UserAuthIntro = ({ children }) => <Intro>{children}</Intro>

UserAuthIntro.propTypes = {
  children: PropTypes.string,
}

UserAuthIntro.defaultProps = {
  children: '',
}

export default UserAuthIntro
