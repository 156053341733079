import styled from 'styled-components'

export const Content = styled.div`
  padding: 50px;
  max-width: 500px;
`

export const Spacer = styled.hr`
  margin: 15px 0;
  border: 0;
  border-top: 1px solid #dce0e0;
  height: 0;
`
export const Message = styled.p`
  font-size: 14px;
  padding: 30px 0;
  color: #82888a;
  text-align: left;
`
