import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { printCurrency } from '../../../helpers/Currency'

const Price = styled.div`
  font-size: 21px;
  line-height: 20px;
  margin: 0;
  font-weight: 300;
  text-align: center;
`
const SmallText = styled.small`
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  margin-right: 6px;
`
const PriceInnerWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const ArtistCardPrice = ({ minPrice }) => (
  <Price>
    {' '}
    {minPrice ? (
      <PriceInnerWrapper>
        <SmallText>Fra</SmallText>{' '}
        <span>
          <strong>{printCurrency(minPrice, { removeSign: true })}</strong>{' '}
          {printCurrency(undefined, { signOnly: true })}
        </span>
      </PriceInnerWrapper>
    ) : (
      <SmallText>Pris ikke oppgitt</SmallText>
    )}
  </Price>
)

ArtistCardPrice.propTypes = {
  minPrice: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

ArtistCardPrice.defaultProps = {
  minPrice: '',
}

export default ArtistCardPrice
