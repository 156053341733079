import * as React from 'react'
import PropTypes from 'prop-types'

import RichTextBlock from './components/rich-text-block'
import ControlpanelArtistBlock from './components/ControlpanelArtistBlock'
import ControlpanelEventplannerBlock from './components/ControlpanelEventplannerBlock'
import FoldableRichTextBlock from './components/foldable-rich-text-block'
import HeaderMainSearchBlock from './components/HeaderMainSearchBlock'
import HeroSectionBlock from './components/HeroSectionBlock'
import HeaderImageBlock from './components/header-image-block'
import ArtistMatchingListBlock from './components/ArtistMatchingListBlock'
import ArtistFeatureListBlock from './components/artist-feature-list-block'
import ArtistFavoriteListBlock from './components/artist-favorite-list-block'
import ArtistFilterListBlock from './components/artist-filter-list-block'
import ArtistSelectedListBlock from './components/artist-selected-list-block'
import HowItWorksBlock from './components/how-it-works-block'
import LogoListBlock from './components/logo-list-block'
import IntercomContactBlock from './components/intercom-contact-block'
import HeadingBlock from './components/heading-block'
import HeadingAndIntroBlock from './components/heading-and-intro-block'
import RegisterArtistButtonBlock from './components/register-artist-button-block'
import ButtonBlock from './components/button-block'
import ChecklistBlock from './components/checklist-block'
import FAQBlock from './components/faq-block'
import QuotesBlock from './components/QuotesBlock'
import ButtonContentBlock from './components/ButtonContentBlock'
import VideoBlock from './components/VideoBlock'
import ResponsiveImageBlock from './components/ResponsiveImageBlock'

import LandingPageWrapper from '../../ui/landing-page-wrapper'
import ImageBlock from './components/image-block'
import ArticleLatestListBlock from './components/ArticleLatestListBlock'
import ArticleSelectedListBlock from './components/ArticleSelectedListBlock'

const hasAccess = (visibility, isLoggedIn, hasFavorites, hasArtists, isEventplanner = false) => {
  if (!visibility) return true
  if (isLoggedIn && visibility.indexOf('hideForLoggedInUsers') !== -1) return false
  if (!isLoggedIn && visibility.indexOf('hideForLoggedOutUsers') !== -1) return false
  if (hasArtists && visibility.indexOf('hideForArtistUsers') !== -1) return false
  if (!hasArtists && visibility.indexOf('hideForNoArtistUsers') !== -1) return false
  if (hasFavorites && visibility.indexOf('hideForFavoriteUsers') !== -1) return false
  if (!hasFavorites && visibility.indexOf('hideForNoFavoriteUsers') !== -1) return false
  if (isEventplanner && visibility.indexOf('hideForEventplannerUsers') !== -1) return false
  if (!isEventplanner && visibility.indexOf('hideForNoEventplannerUsers') !== -1) return false

  return true
}
const LandingPageBlocks = React.memo(
  ({ blocks, isLoggedIn, hasFavorites, hasArtists, isEventplanner = false }) => {
    if (!blocks || !blocks.length === 0) {
      return null
    }

    return (
      <LandingPageWrapper>
        {(blocks || []).map((block) => {
          if (!hasAccess(block.visibility, isLoggedIn, hasFavorites, hasArtists, isEventplanner)) {
            return null
          }
          switch (block._type) {
            case 'responsiveImageBlock':
              // console.log('block.body', block.body)
              return (
                <ResponsiveImageBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  url={block.url}
                  layout={block.layout}
                  imageMobile={block.imageMobile}
                  imageTabletPortrait={block.imageTabletPortrait}
                  imageTabletLandscape={block.imageTabletLandscape}
                  imageDesktop={block.imageDesktop}
                  imageDesktopBig={block.imageDesktopBig}
                  altText={block.altText}
                />
              )
            case 'richTextBlock':
              // console.log('block.body', block.body)
              return (
                <RichTextBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  body={block.body}
                  textSize={block.textSize}
                />
              )
            case 'foldableRichTextBlock':
              return (
                <FoldableRichTextBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.heading}
                  expanded={block.expanded}
                  body={block.body}
                />
              )
            case 'imageBlock':
              return (
                <ImageBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  image={block.image}
                />
              )
            case 'videoBlock':
              return (
                <VideoBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  videoUrl={block.videoUrl}
                />
              )
            case 'heroSectionBlock':
              return (
                <HeroSectionBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  title={block.title}
                  intro={block.intro}
                  artists={block.artists}
                  textColumns={block.textColumns}
                />
              )
            case 'headerMainSearchBlock':
              return (
                <HeaderMainSearchBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  title={block.title}
                  intro={block.intro}
                />
              )

            case 'headerImageBlock':
              return (
                <HeaderImageBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  title={block.title}
                  intro={block.intro}
                  image={block.image}
                  buttonText={block.buttonText}
                  buttonUrl={block.buttonUrl}
                  secondaryButtonText={block.secondaryButtonText}
                  secondaryButtonUrl={block.secondaryButtonUrl}
                />
              )
            case 'artistFeatureListBlock':
              return (
                <ArtistFeatureListBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  showMoreButtonText={block.showMoreButton}
                />
              )
            case 'artistMatchingListBlock':
              return (
                <ArtistMatchingListBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  limit={block.limit}
                  showSearchButtonText={block.showSearchButtonText}
                />
              )
            case 'artistFavoriteListBlock':
              return (
                <ArtistFavoriteListBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  removeMargin={block.removeMargin}
                />
              )
            case 'artistFilterListBlock':
              return (
                <ArtistFilterListBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  categoryFilter={block.categoryFilter}
                  showCategoryFilter={block.showCategoryFilter}
                  locationFilter={block.locationFilter}
                  showLocationFilter={block.showLocationFilter}
                  genreFilter={block.genreFilter}
                  showGenreFilter={block.showGenreFilter}
                  eventTypeFilter={block.eventTypeFilter}
                  showEventTypeFilter={block.showEventTypeFilter}
                  limit={block.limit}
                  sortField={block.sortField}
                  showMoreButtonText={block.showMoreButtonText}
                  showSearchButtonText={block.showSearchButtonText}
                />
              )
            case 'artistSelectedListBlock':
              return (
                <ArtistSelectedListBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  artistIds={block.artistIds}
                />
              )
            case 'howItWorksBlock':
              return (
                <HowItWorksBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  steps={block.steps}
                />
              )
            case 'logoListBlock':
              return (
                <LogoListBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  useHref={block.useHref}
                  customerList={block.customerList}
                />
              )
            case 'quotesBlock':
              return (
                <QuotesBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  layout={block.layout}
                  quotesList={block.quotesList}
                />
              )
            case 'intercomContactBlock':
              return (
                <IntercomContactBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  intro={block.intro}
                  contactButtonText={block.contactButtonText}
                />
              )
            case 'buttonContentBlock':
              return (
                <ButtonContentBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  intro={block.intro}
                  buttonText={block.buttonText}
                  buttonUrl={block.buttonUrl}
                />
              )
            case 'headingAndIntroBlock':
              return (
                <HeadingAndIntroBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  intro={block.intro}
                />
              )
            case 'headingBlock':
              return (
                <HeadingBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                />
              )
            case 'controlpanelArtistBlock':
              return (
                <ControlpanelArtistBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                />
              )
            case 'controlpanelEventplannerBlock':
              return (
                <ControlpanelEventplannerBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                />
              )
            case 'registerArtistButtonBlock':
              return (
                <RegisterArtistButtonBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  buttonText={block.buttonText}
                />
              )
            case 'buttonBlock':
              return (
                <ButtonBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  buttonText={block.buttonText}
                  buttonUrl={block.buttonUrl}
                />
              )
            case 'checklistBlock':
              return (
                <ChecklistBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  items={block.listItems}
                />
              )
            case 'faqBlock':
              return (
                <FAQBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  faqs={block.faqs}
                />
              )
            case 'articleLatestListBlock':
              return (
                <ArticleLatestListBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  articles={block.articles}
                  highlightFirst={block.highlightFirst}
                />
              )
            case 'articleSelectedListBlock':
              return (
                <ArticleSelectedListBlock
                  key={block.key}
                  theme={block.theme}
                  anchorId={block.anchorId}
                  heading={block.title}
                  articles={block.articles}
                />
              )
            default:
              return null
          }
        })}
      </LandingPageWrapper>
    )
  },
)

LandingPageBlocks.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape({})),
  isLoggedIn: PropTypes.bool,
  hasFavorites: PropTypes.bool,
  hasArtists: PropTypes.bool,
}

LandingPageBlocks.defaultProps = {
  blocks: [],
  isLoggedIn: false,
  hasFavorites: false,
  hasArtists: false,
}

export default LandingPageBlocks
