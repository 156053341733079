import styled, { keyframes } from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const Container = styled.div`
  display: inline-block;
  height: 24px;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 20px;
`

export const QuestionButton = styled.button`
  background-color: #565a5c;
  border: 2px solid #565a5c;
  border-radius: 50%;
  color: #f4f4f4;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  height: 20px;
  left: 0;
  line-height: 16px;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 2px;
  width: 20px;
`
const HelpPopupTextMobile = keyframes`
  0% {
    opacity: 0;
    transform: scale(.95) translateY(-50%);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(-50%);
  }
`

const HelpPopupTextDesktop = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const HelpPopupBackdropMobile = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const Backdrop = styled.div`
  bottom: 0;
  cursor: pointer;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${gigplanetTheme.zIndex.popup};

  ${mediaQueries.mobile`
    animation: ${HelpPopupBackdropMobile} 250ms ease-in-out;
    background: rgba(51, 51, 51, .3);
  `};
`

export const Text = styled.div`
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 20px rgba(51, 51, 51, 0.3);
  color: #565a5c;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  padding: 25px;
  position: relative;
  text-align: left;
  z-index: ${gigplanetTheme.zIndex.popup + 1};
  animation: ${HelpPopupTextDesktop} 0.5s ease-in-out forwards;

  ${mediaQueries.mobile`
    animation: ${HelpPopupTextMobile} 0.5s ease-in-out forwards;
    left: 10px;
    opacity: 0;
    position: fixed;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  `};

  ${mediaQueries.tabletPortraitUp`
    bottom: 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 34px;
    margin-left: 10px;
    max-width: 100%;
    min-width: 500px;
    position: absolute;
    text-transform: none;
    transform: translateX(-50%);

    &:after {
      border-color: #fff transparent;
      border-style: solid;
      border-width: 7px 7px 0;
      bottom: -7px;
      content: '';
      display: block;
      left: 50%;
      margin-left: -7px;
      position: absolute;
      width: 0;
    }
  `};
`

export const CloseButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: 0;
  color: #565a5c;
  cursor: pointer;
  height: 34px;
  padding: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 34px;

  svg {
    fill: #565a5c;
    height: 24px;
    width: 24px;
  }
`
