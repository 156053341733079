import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import mediaQueries from '../../../../styles/mediaQueries'
import GigRequestFormWrapper from '../GigRequestFormWrapper'
import UserAuthWindow from '../../../../containers/user-auth-window'
import GigRequestFormProgress from '../GigRequestFormProgress'
import GigRequestFormHeader from '../GigRequestFormHeader'

export const Container = styled.div`
  margin: 0 auto;
  max-width: 450px;
  min-width: 290px;
  padding: 0 15px;
  width: 100%;

  ${mediaQueries.mobile`
    padding-top: 0;
  `};
`

const GigRequestFormLoginContainer = React.memo(({ onAbort, successUrl, artist }) => (
  <GigRequestFormWrapper>
    <GigRequestFormProgress step={1} stepCount={3} />
    <GigRequestFormHeader
      heading={`Kontakt ${artist ? artist.name : 'artisten'}`}
      intro={`For å sende forespørsel til ${
        artist ? artist.name : 'artisten'
      } på Gigplanet, trenger du å ha registrert en profil.`}
      artist={artist}
    />
    <Container>
      <UserAuthWindow
        hideHeading
        showRegister
        continueMode
        onClose={onAbort}
        successUrl={successUrl}
      />
    </Container>
  </GigRequestFormWrapper>
))

GigRequestFormLoginContainer.propTypes = {
  artist: PropTypes.shape({
    name: PropTypes.string,
  }),
  onAbort: PropTypes.func.isRequired,
  successUrl: PropTypes.string.isRequired,
}

export default GigRequestFormLoginContainer
