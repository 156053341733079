import styled from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'

export const RequestUpdate = styled.div`
  text-align: center;
  width: 100%;
`

export const ChangeNote = styled.p`
  font-weight: 600;
`

export const ChangesList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const ChangeItem = styled.li`
  line-height: 20px;
  font-size: 16px;
  font-weight: 300;
  ${mediaQueries.mobile`
    font-size: 14px;
  `};
`

export const ChangedFrom = styled.span`
  text-decoration: line-through;
`

export const ChangedTo = styled.span`
  font-weight: 600;
`
