import * as types from './types'
import getSanityClient from '../../libs/sanity'
import {
  getLandingPageContentSelector,
  getSystemTextSelector,
  getArticleContentSelector,
} from './reducer'

const contentBlocks = `
  blocks[] {
    ...,
    'key': _key,
    'type': _type,
    
    customerList[]->{
      'id': _id,
      logo,
      name,
      url
    },
    categories[]->{...},
    artists[visible == true]{
      ...,
      "imageMetadata": image.asset->{metadata} | {...metadata}
    },
    textColumns,
    quotesList[]->{
      'id': _id,
      name,
      quoteType,
      artistName,
      artistUrl,
      customerName,
      quoteText,
      quoteImage,
    },
    faqs[]->{
      'id': _id,
      question,
      questionType,
      answer,
    },
    articles[]->{
      ...,
      'id': _id,
      author->{
        ...
      },
      mainImage,
    },
    _type == 'articleLatestListBlock' => {
      'articles': *[_type == 'article' && count(categories[@._ref in ^.categories[]._ref]) > 0 ][0...10]{
        ...,
        'id': _id,
        author->{
          ...
        },
        mainImage,
      } | order(publishedAt desc)
    },
  }
`

const headerSettingsQuery = `"header": *[_type == "headerSettings"] {
  navigation
}[0]`

const footerSettingsQuery = `"footer": *[_type == "footerSettings"] {
  navigation,
  pageRegistry
}[0]`

const siteSettingsQuery = `"siteSettings": *[_type == "siteSettings"] {
  metaTitle,
  metaDescription,
  metaImage,
  phone,
  email, 
  facebookUrl,
  instagramUrl,
  twitterUrl,
  linkedinUrl,  
  ${headerSettingsQuery},
  ${footerSettingsQuery}
}[0]`

const landingPageQuery = `
  {
    "landingPage": *[_type == "landingPage" && (_id == $id || slug.current == $slug)] {
      'id': _id,
      'type': _type,
      slug,
      menu,
      metaTitle,
      metaDescription,
      metaImage,
      ${contentBlocks}
    }[0],
    ${siteSettingsQuery}
  }
`

const predefinedPageQuery = `
  {
    "landingPage": *[_type == $type || _id == $id] {
      'id': _id,
      'type': _type,
      menu,
      metaTitle,
      metaDescription,
      metaImage,
      ${contentBlocks}
    }[0],
    ${siteSettingsQuery}
  }
`
const articleQuery = `
  {
    "article": *[_type == "article" && (_id == $id || slug.current == $slug)] {
      'id': _id,
      'type': _type,
      title,
      intro,
      slug,
      publishedAt,
      body[]{..., "asset": asset->},
      categories[]->{...},
      author->{...},
      mainImage,
      metaTitle,
      metaDescription,
    }[0],
    "landingPage": *[_type == $type || _id == $id] {
      'id': _id,
      'type': _type,
      menu,
      metaTitle,
      metaDescription,
      metaImage,
      ${contentBlocks}
    }[0],
    ${siteSettingsQuery}
  }
`

const systemTextQuery = `*[_type == "systemText" && slug.current == $slug] {
  _id,
  _type,
  title,
  slug,
  blocks[] {
    ...,
    'key': _key,
    'type': _type,
  }
}[0]`

export const fetchOneArticleIfNeededAction = (slug, previewId) => (dispatch, getState) => {
  const content = getArticleContentSelector(getState(), slug) // slug is virtual

  if (content) {
    return null
  }

  dispatch({
    type: types.FETCH_ONE_ARTICLE_PENDING,
    payload: {
      slug,
    },
  })
  const landingPageSlug = 'articleDetails'

  return getSanityClient(!!previewId)
    .fetch(articleQuery, { slug, id: previewId || '', type: landingPageSlug })
    .then(
      (json) => {
        dispatch({
          type: types.FETCH_ONE_ARTICLE_FULFILLED,
          payload: {
            slug,
            json,
            landingPageSlug,
          },
        })
      },
      () => {
        dispatch({
          type: types.FETCH_ONE_ARTICLE_REJECTED,
          payload: {
            slug,
          },
        })
      },
    )
}

export const fetchPredefinedPageIfNeededAction =
  (type, previewId = '') =>
  (dispatch, getState) => {
    let slug = ''
    switch (type) {
      case 'homePage':
      case 'favoritePage':
      case 'joinPage':
      case 'artistDetails':
      case 'gigRequestForm':
      case 'articlesPage':
      case 'articleDetails':
        slug = type
        break
      default:
        slug = ''
    }

    if (!slug) return null

    const content = getLandingPageContentSelector(getState(), slug) // slug is virtual

    if (!content) {
      dispatch({
        type: types.FETCH_LANDING_PAGE_PENDING,
        payload: {
          slug,
        },
      })

      return getSanityClient(!!previewId)
        .fetch(predefinedPageQuery, { type, id: previewId || '' })
        .then(
          (json) => {
            dispatch({
              type: types.FETCH_LANDING_PAGE_FULFILLED,
              payload: {
                slug,
                json,
              },
            })
          },
          () => {
            dispatch({
              type: types.FETCH_LANDING_PAGE_REJECTED,
              payload: {
                slug,
              },
            })
          },
        )
    }
  }

export const fetchLandingPageIfNeededAction =
  (slug, previewId = '') =>
  (dispatch, getState) => {
    const content = getLandingPageContentSelector(getState(), slug)
    if (!content) {
      dispatch({
        type: types.FETCH_LANDING_PAGE_PENDING,
        payload: {
          slug,
        },
      })

      return getSanityClient(!!previewId)
        .fetch(landingPageQuery, { slug: previewId === '' ? slug : '', id: previewId || '' })
        .then(
          (json) => {
            dispatch({
              type: types.FETCH_LANDING_PAGE_FULFILLED,
              payload: {
                slug,
                json,
              },
            })
          },
          () => {
            dispatch({
              type: types.FETCH_LANDING_PAGE_REJECTED,
              payload: {
                slug,
              },
            })
          },
        )
    }
  }

export const fetchSystemTextIfNeededAction = (slug) => (dispatch, getState) => {
  const content = getSystemTextSelector(getState(), slug)
  if (!content) {
    dispatch({
      type: types.FETCH_SYSTEM_TEXT_PENDING,
    })

    return getSanityClient()
      .fetch(systemTextQuery, { slug })
      .then(
        (json) => {
          dispatch({
            type: types.FETCH_SYSTEM_TEXT_FULFILLED,
            payload: {
              slug,
              json,
            },
          })
        },
        () => {
          dispatch({
            type: types.FETCH_SYSTEM_TEXT_REJECTED,
          })
        },
      )
  }
}

export const fetchArticlesIfNeededAction = (slug) => (dispatch, getState) => {
  const content = getArticlesSelector(getState(), slug)
  if (!content) {
    dispatch({
      type: types.FETCH_SYSTEM_TEXT_PENDING,
    })

    return getSanityClient()
      .fetch(systemTextQuery, { slug })
      .then(
        (json) => {
          dispatch({
            type: types.FETCH_SYSTEM_TEXT_FULFILLED,
            payload: {
              slug,
              json,
            },
          })
        },
        () => {
          dispatch({
            type: types.FETCH_SYSTEM_TEXT_REJECTED,
          })
        },
      )
  }
}

export const fetchArticleIfNeededAction = (slug) => (dispatch, getState) => {
  const content = getArticlesSelector(getState(), slug)
  if (!content) {
    dispatch({
      type: types.FETCH_SYSTEM_TEXT_PENDING,
    })

    return getSanityClient()
      .fetch(systemTextQuery, { slug })
      .then(
        (json) => {
          dispatch({
            type: types.FETCH_SYSTEM_TEXT_FULFILLED,
            payload: {
              slug,
              json,
            },
          })
        },
        () => {
          dispatch({
            type: types.FETCH_SYSTEM_TEXT_REJECTED,
          })
        },
      )
  }
}
