import * as React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { makeGetSystemTextSelector } from '../../stores/cms-content/reducer'
import { fetchSystemTextIfNeededAction } from '../../stores/cms-content/actions'
import SystemTextBlocks from '../../components/system-text-blocks'

const getSystemTextSelector = makeGetSystemTextSelector()

const SanitySystemText = React.memo(({ id }) => {
  const dispatch = useDispatch()
  const systemText = useSelector(state => getSystemTextSelector(state, id))

  React.useEffect(() => {
    dispatch(fetchSystemTextIfNeededAction(id))
  }, [id])

  return <SystemTextBlocks blocks={systemText && systemText.blocks ? systemText.blocks : null} />
})

SanitySystemText.propTypes = {
  id: PropTypes.string.isRequired,
}

export default SanitySystemText
