import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import mediaQueries from '../../../styles/mediaQueries'

import { HeartIcon } from '../../../icons'
import Button from '../../../ui/Button'
import SystemDialog from '../../../components/SystemDialog'
import SanitySystemText from '../../sanity-system-text'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const RoundButton = styled.button`
  background: #fff;
  border: 0;
  border-radius: 22px;
  cursor: pointer;
  height: 38px;
  margin: 0;
  padding: 0 7px 0 7px;
  width: auto;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-top: 1px;

    path:last-child {
      stroke: #ff5e3d;
      stroke-width: 2px;
    }

    ${({ isFavorited }) =>
      isFavorited &&
      css`
        path:last-child {
          fill: #ff785d;
        }
      `}
  }
`

const ButtonContent = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;

  svg {
    margin-right: 5px;
  }

  ${mediaQueries.mobile`
    margin-left: -5px;
    margin-right: -5px;
  `};
`

const ButtonText = styled.span`
  white-space: nowrap;
`
const DialogContent = styled.div`
  h1 {
    color: ${gigplanetTheme.component.headings.h1.text};
    font-size: 30px;
    font-weight: 600;
    margin: 0;

    ${mediaQueries.mobile`
    font-size: 24px;
  `};

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  h2 {
    color: ${gigplanetTheme.component.headings.h2.text};
    font-size: 24px;
    font-weight: 600;
    margin: 0;

    ${mediaQueries.mobile`
    font-size: 20px;
  `};

    a {
      color: inherit;
      text-decoration: none;
    }
  }
  ul {
    margin: 0 0 0 20px;
    padding: 0 0 20px;
    list-style: disc;

    ${mediaQueries.mobile`
      font-size: 14px;
    `};
  }

  p {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 20px;

    ${mediaQueries.mobile`
      font-size: 14px;
    `};
  }
`

const FavoriteButton = (props) => {
  const { isFavorited, round, onToggleFavorite, showMessage } = props
  const favoriteIcon = isFavorited ? (
    <HeartIcon height="24" width="24" />
  ) : (
    <HeartIcon height="24" width="24" />
  )

  return (
    <>
      {round ? (
        <RoundButton
          type="button"
          onClick={onToggleFavorite}
          isFavorited={isFavorited}
          aria-label={isFavorited ? 'Fjern favoritt' : 'Legg til som favoritt'}
        >
          {favoriteIcon}
        </RoundButton>
      ) : (
        <Button type="button" theme="secondary" onClick={onToggleFavorite}>
          <ButtonContent>
            {favoriteIcon}
            <ButtonText>{isFavorited ? 'Lagret favoritt' : 'Lagre favoritt'}</ButtonText>
          </ButtonContent>
        </Button>
      )}

      {showMessage ? (
        <SystemDialog id="favorite-dialog-message" showOk hideClose>
          <DialogContent>
            <SanitySystemText id="favorite-popup-message" />
          </DialogContent>
        </SystemDialog>
      ) : null}
    </>
  )
}

FavoriteButton.propTypes = {
  isFavorited: PropTypes.bool.isRequired,
  round: PropTypes.bool.isRequired,
  onToggleFavorite: PropTypes.func.isRequired,
  showMessage: PropTypes.bool.isRequired,
}
FavoriteButton.defaultProps = {}

export default FavoriteButton
