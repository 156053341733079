import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Helmet } from 'react-helmet'

import { getPathWithDomain } from '../../models/MetaData'

const IMAGE_WIDTH = '1200'
const IMAGE_HEIGHT = '630'

const FB_ID = '1039615266068300'
const DEFAULT_IMAGE = '/images/gigplanet-default-sharing-image.jpg'
const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  keywords: PropTypes.string,
  type: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  nopin: PropTypes.bool,
  noindex: PropTypes.bool,
}

const defaultProps = {
  keywords: '',
  type: '',
  image: '',
  nopin: false,
  noindex: false,
}

const MetaData = ({ type, location, image, title, description, keywords, nopin, noindex }) => {
  const pageType = type || 'website'
  const url = location ? getPathWithDomain(location.pathname) : '/'
  const imageUrl = image || getPathWithDomain(DEFAULT_IMAGE)

  return (
    <Helmet key={`${title}-${description}`} defer={false}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="robots" content={noindex ? 'noindex' : 'all'} />
      <meta property="fb:app_id" content={FB_ID} />
      <meta property="og:site_name" content="Gigplanet" />
      <meta itemProp="image" content={imageUrl} />
      <meta property="og:title" content={`${title}`} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={pageType} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content={IMAGE_WIDTH} />
      <meta property="og:image:height" content={IMAGE_HEIGHT} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={`${title}`} />
      <meta name="twitter:image:src" content={imageUrl} />
      <meta name="twitter:description" content={description} />
      {nopin ? <meta name="pinterest" content="nopin" /> : null}

      <link rel="cannonical" href={url} />
      <script type="application/ld+json">
        {`
        {
          "@context": "http://schema.org",
          "@type": "Organization",
          "url": "https://www.gigplanet.no",
          "logo": "https://www.gigplanet.no/images/gp-logo.png",
          "contactPoint": [
            {
              "@type": "ContactPoint",
              "telephone": "+4790640887",
              "email": "post@gigplanet.no",
              "contactType": "sales",
              "availableLanguage": ["Norwegian", "English"]
            } , {
              "@type" : "ContactPoint",
              "telephone": "+4790640887",
              "email" : "support@gigplanet.no",
              "contactType" : "customer service",
              "availableLanguage": ["Norwegian", "English"]
            }
          ],
          "sameAs": [
            "https://www.facebook.com/gigplanet",
            "https://www.linkedin.com/company/gigplanet",
            "https://twitter.com/gigplanet"
          ]
        }
      `}
      </script>
      <script type="application/ld+json">
        {`
        {
          "@context": "http://schema.org",
          "@type": "WebSite",
          "name": "Gigplanet",
          "alternateName": "Gigplanet - Live musikk til ditt event",
          "url": "https://www.gigplanet.no"
        }
      `}
      </script>
    </Helmet>
  )
}

MetaData.propTypes = propTypes
MetaData.defaultProps = defaultProps

export default withRouter(MetaData)
