import * as React from 'react'
import { useSelector } from 'react-redux'
import {
  getCategories,
  getEventTypes,
  getArtistTypes,
  getGenres,
  getInstruments,
  getArtistMemberTypes,
  getDanceTypes,
} from '../stores/Options/OptionsReducer'

const useOptions = () => {
  const artistMemberTypes = useSelector(getArtistMemberTypes)
  const danceTypes = useSelector(getDanceTypes)
  const instruments = useSelector(getInstruments)
  const categories = useSelector(getCategories)
  const eventTypes = useSelector(getEventTypes)
  const artistTypes = useSelector(getArtistTypes)
  const genres = useSelector(getGenres)

  return {
    artistMemberTypes,
    danceTypes,
    instruments,
    categories,
    eventTypes,
    artistTypes,
    genres,
  }
}

export default useOptions
