import * as React from 'react'
import PropTypes from 'prop-types'

import { StyledContactInfo, ContactInfoLine, ContactInfoLabel, EmailLink } from './style'

import { H3 } from '../../ui/Headings'

const GigRequestResponseOptionsContactInfo = React.memo(
  ({ name, phone, email, isArtist, artistName }) => (
    <StyledContactInfo>
      <H3>Kontaktinfo til {isArtist ? 'arrangør' : artistName}</H3>
      <ContactInfoLine>
        <ContactInfoLabel>Navn: </ContactInfoLabel>
        {name}
      </ContactInfoLine>
      <ContactInfoLine>
        <ContactInfoLabel>Mobil: </ContactInfoLabel>
        {phone}
      </ContactInfoLine>
      <ContactInfoLine>
        <ContactInfoLabel>Epost: </ContactInfoLabel>
        <EmailLink href={`mailto:${email}`}>{email}</EmailLink>
      </ContactInfoLine>
    </StyledContactInfo>
  ),
)

GigRequestResponseOptionsContactInfo.propTypes = {
  isArtist: PropTypes.bool,
  name: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  artistName: PropTypes.string,
}

GigRequestResponseOptionsContactInfo.defaultProps = {
  isArtist: false,
  name: '',
  phone: '',
  email: '',
  artistName: '',
}

export default GigRequestResponseOptionsContactInfo
