import * as React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { GigRequestFormRequester } from '../../../../components/GigRequestForm'
import { getArtist } from '../../../../stores/Artist/ArtistReducer'

const ProfileDetailsGigRequestFormRequesterRoute = React.memo(() => {
  const artist = useSelector(getArtist)
  const history = useHistory()

  const handleNext = () => {
    history.push(`/${artist.artistType || 'artist'}/${artist.slug}/gigrequest/receipt`)
  }

  const handlePrevious = () => {
    history.push(`/${artist.artistType || 'artist'}/${artist.slug}/gigrequest/event`)
  }

  return <GigRequestFormRequester onNext={handleNext} onPrevious={handlePrevious} />
})

export default ProfileDetailsGigRequestFormRequesterRoute
