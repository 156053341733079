import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Input, Errormessage } from '../../../ui/Form'
import FacebookButton from '../../../ui/FacebookButton'
import GoogleButton from '../../../ui/GoogleButton'
import TextSeparator from '../../../ui/TextSeparator'
import Button from '../../../ui/Button'
import { H1 } from '../../../ui/Headings'
import UserAuthIntro from '../../../ui/user-auth-intro'
import UserAuthAlert from '../../../ui/user-auth-alert'
import UserAuthAbort from '../../../components/user-auth-abort'

const HeadingWrapper = styled.div`
  margin-bottom: 25px;
  text-align: center;
`

const Intro = styled.div`
  margin-bottom: 25px;
`

const ExternalLogin = styled.div`
  padding: 5px 0;
`
const Separator = styled.div`
  padding: 15px 0;
`

const InputWrapper = styled.div`
  padding-bottom: 10px;
`

const Forgot = styled.div`
  padding: 0 0 1rem;
  text-align: right;
`
const NewAccount = styled.div`
  padding-top: 10px;
  line-height: 20px;
  text-align: center;
`
const ActionWrapper = styled.div``

const ErrorMessageWrapper = styled.div`
  padding-bottom: 10px;
`

const Verify = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-bottom: 10px;
`

const VerifyActions = styled.div`
  padding-top: 10px;
`
const NewAccountLabel = styled.span`
  padding-right: 5px;
`

const UserAuthLoginForm = ({
  hideHeading,
  intro,
  errorCode,
  successUrl,
  onSubmit,
  email,
  onChangeEmail,
  password,
  onChangePassword,
  errorMessages,
  hasVerifyError,
  onShowVerify,
  isLoggingIn,
  onShowForgotPassword,
  onShowRegisterForm,
  onClose,
}) => (
  <>
    {!hideHeading ? (
      <HeadingWrapper>
        <H1>Logg inn</H1>
      </HeadingWrapper>
    ) : null}
    {errorCode ? <UserAuthAlert>{errorCode}</UserAuthAlert> : null}
    {intro ? (
      <Intro>
        <UserAuthIntro>{intro}</UserAuthIntro>
      </Intro>
    ) : null}

    <form action="/v1/api/auth/login" method="POST" onSubmit={onSubmit}>
      <InputWrapper>
        <Input
          id="login-email"
          name="login-email"
          type="email"
          label="E-post"
          placeholder="Din e-post"
          required
          expanded
          value={email}
          onChange={onChangeEmail}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          id="login-password"
          name="login-password"
          type="password"
          label="Passord"
          placeholder="Ditt passord"
          required
          expanded
          value={password}
          onChange={onChangePassword}
        />
      </InputWrapper>
      <Forgot>
        <Button type="button" theme="text" size="small" onClick={onShowForgotPassword}>
          Glemt passord?
        </Button>
      </Forgot>
      {errorMessages && errorMessages.length > 0 ? (
        <ErrorMessageWrapper>
          <Errormessage messages={errorMessages.map((message) => message.message)} />
        </ErrorMessageWrapper>
      ) : null}
      {hasVerifyError ? (
        <Verify>
          <Errormessage messages={['Du må verifisere kontoen']} />
          <VerifyActions>
            <Button type="button" theme="secondary" onClick={onShowVerify} wide>
              Verifiser konto
            </Button>
          </VerifyActions>
        </Verify>
      ) : null}
      <ActionWrapper>
        <Button type="submit" theme="primary" loading={isLoggingIn} wide>
          Logg inn
        </Button>
      </ActionWrapper>
    </form>

    <Separator>
      <TextSeparator text="eller" />
    </Separator>
    <ExternalLogin>
      <FacebookButton redirectUrl={successUrl} />
    </ExternalLogin>
    <ExternalLogin>
      <GoogleButton redirectUrl={successUrl} />
    </ExternalLogin>

    <TextSeparator />
    <NewAccount>
      <NewAccountLabel>Har du ikke konto?</NewAccountLabel>
      <Button
        type="button"
        theme="text"
        className="UserAuthLoginForm__button"
        onClick={onShowRegisterForm}
      >
        Registrer deg
      </Button>
    </NewAccount>
    {onClose ? <UserAuthAbort onAbort={onClose} /> : null}
  </>
)

UserAuthLoginForm.propTypes = {
  hideHeading: PropTypes.bool.isRequired,
  intro: PropTypes.string,
  errorCode: PropTypes.string,
  successUrl: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onShowForgotPassword: PropTypes.func.isRequired,
  onShowRegisterForm: PropTypes.func.isRequired,
  onShowVerify: PropTypes.func.isRequired,
  hasVerifyError: PropTypes.bool.isRequired,
  errorMessages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
    }),
  ),
  isLoggingIn: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
UserAuthLoginForm.defaultProps = {
  intro: '',
  errorCode: '',
  errorMessages: [],
}

export default UserAuthLoginForm
