import * as React from 'react'
import PropTypes from 'prop-types'

import { IntroWrapper } from './style'

const UserAuthIntroWrapper = ({ children }) => <IntroWrapper>{children}</IntroWrapper>

UserAuthIntroWrapper.propTypes = {
  children: PropTypes.element.isRequired,
}

export default UserAuthIntroWrapper
