import styled from 'styled-components'

const textColors = {
  green: 'green',
  red: 'red',
  gray: 'gray',
}
const borderColors = {
  green: 'green',
  red: 'red',
  gray: 'gray',
}

export const Label = styled.div`
  color: ${({ color }) => textColors[color]};
  border: 1px solid ${({ color }) => borderColors[color]};
  padding: 5px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  white-space: nowrap;
  display: inline-block;
`
