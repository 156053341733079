import styled, { css } from 'styled-components'

import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: ${gigplanetTheme.spacing.medium};

  ${mediaQueries.mobile`
    padding-bottom: ${gigplanetTheme.spacing.xsmall};
    
    ${({ noPadding }) =>
      noPadding &&
      css`
        padding: 0;
      `}
  `};

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}
`
