import styled from 'styled-components'
import theme from 'styled-theming'

import mediaQueries from '../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../styles/gigplanetTheme'

const itemBackgroundColor = theme('theme', {
  white: gigplanetTheme.color.lightGray,
  gray: gigplanetTheme.color.white,
})

export const Content = styled.div`
  max-width: ${gigplanetTheme.measure.maxWidth};
  width: 100%;
  margin: 0 auto;
  padding-top: ${gigplanetTheme.spacing.large};

  ${mediaQueries.mobile`
    padding-top: ${gigplanetTheme.spacing.xsmall};
  `};
`

export const List = styled.ol`
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin: 0;
  padding: 0;

  ${mediaQueries.mobile`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `};

  ${mediaQueries.tabletPortrait`
    flex-wrap: wrap;
  `};
`

export const Item = styled.li`
  background: ${itemBackgroundColor};
  margin: 0 10px;
  padding: 50px 15px;
  flex: 1 0 20%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 290px;

  ${mediaQueries.mobile`
    flex: 1 0 100%;
    margin: 10px 0;
    padding: 30px 15px;
    flex: 1 0 auto;
    width: 100%;
    max-width: 100%;
  `};

  &:first-child {
    margin-left: 0;
    margin-top: 0;

    ${mediaQueries.tabletPortrait`
      margin: 10px;
    `};
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;

    ${mediaQueries.tabletPortrait`
      margin: 10px;
    `};
  }

  ${mediaQueries.tabletPortrait`
    flex: 1 0 45%;
    margin: 10px;
  `};
`

export const ItemNumber = styled.span`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: transparent;
  line-height: 50px;
  text-align: center;
  font-size: 46px;
  font-weight: 300;
`

export const ItemHeading = styled.h3`
  margin: 15px 0;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`
export const ItemContent = styled.p`
  margin: 0;
  padding: 0 15px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  text-align: center;
  max-width: 100%;
`
