import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { printCurrency } from '../../helpers/Currency'

import {
  StyledEventplannerInboxItem,
  Column,
  Requester,
  JobDetails,
  PriceDetails,
  StatusDetails,
  Unread,
} from './style'
import ArtistAvatar from '../../ui/ArtistAvatar'
import GigRequestStatusLabel from '../gig-request-status-label'

const DATE_FORMAT = 'DD.MM.YYYY HH:mm'
const LONG_DATE_FORMAT = 'ddd D. MMM YYYY'

const EventplannerInboxItem = ({ eventTypes, request, artistInfo, booked }) => {
  let eventType = ''
  let subEventType = ''
  if (eventTypes[request.eventType.eventType] && request.eventType) {
    eventType = eventTypes[request.eventType.eventType].name

    if (eventTypes[request.eventType.eventType].sub[request.eventType.sub]) {
      subEventType = eventTypes[request.eventType.eventType].sub[request.eventType.sub].name
    }
  }

  return (
    <StyledEventplannerInboxItem to={`/eventplanner/inbox/${request.id}/${artistInfo.artist.id}`}>
      <Column isAvatar>
        <ArtistAvatar size={50} artist={artistInfo.artist} />
        <Requester>
          <span>{artistInfo.artist.name}</span>
          <span>{moment(request.createdAt).format(DATE_FORMAT)}</span>
        </Requester>
      </Column>
      <Column isRequest>
        <JobDetails>
          {eventType}, {subEventType}
        </JobDetails>
        <JobDetails>
          {request.city}, {moment(request.startTime).format(LONG_DATE_FORMAT)}
        </JobDetails>
      </Column>
      <Column isPrice>
        <PriceDetails isQuote={booked}>{booked ? 'Booket for' : 'Pristilbud'}</PriceDetails>
        <PriceDetails isQuote={artistInfo.quote}>
          {artistInfo.quote ? printCurrency(artistInfo.quote.price) : 'Ingen'}
        </PriceDetails>
      </Column>
      <Column isStatus>
        <StatusDetails>
          <GigRequestStatusLabel
            requestStatus={artistInfo.request ? artistInfo.request.artistStatus : ''}
            quoteStatus={artistInfo.quote ? artistInfo.quote.status : ''}
          />
        </StatusDetails>
        <StatusDetails>{moment(artistInfo.request.statusTime).fromNow()}</StatusDetails>
      </Column>
      {artistInfo.request && artistInfo.request.unread > 0 ? <Unread /> : null}
    </StyledEventplannerInboxItem>
  )
}

EventplannerInboxItem.propTypes = {
  request: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  artistInfo: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  booked: PropTypes.bool.isRequired,
}
EventplannerInboxItem.defaultProps = {}

export default React.memo(EventplannerInboxItem)
