import * as React from 'react'
import PropTypes from 'prop-types'

import { Container, Content } from './style'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import { H2 } from '../../../../ui/landing-page-headings'
import Button from '../../../../ui/Button'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'
import extractUrlPath from '../../../../helpers/extract-url-path'
import isExternalLink from '../../../../helpers/is-external-link'

const ButtonContentBlock = React.memo(
  ({ theme, anchorId = '', heading = '', intro = '', buttonText = '', buttonUrl = '' }) => (
    <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
      <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
      <Container>
        <Content>{intro}</Content>
        {isExternalLink(buttonUrl) ? (
          <Button href={buttonUrl} theme="primary">
            {buttonText}
          </Button>
        ) : (
          <Button to={extractUrlPath(buttonUrl)} theme="primary">
            {buttonText}
          </Button>
        )}
      </Container>
    </LandingPageBlockWrapper>
  ),
)

ButtonContentBlock.propTypes = {
  theme: PropTypes.string.isRequired,
  anchorId: PropTypes.string,
  heading: PropTypes.string,
  intro: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
}

export default ButtonContentBlock
