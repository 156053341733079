import styled, { css } from 'styled-components'
import gigplanetTheme from '../../../../../styles/gigplanetTheme'

export const Controls = styled.div`
  padding: 5px 0 5px 5px;
  user-select: none;
  display: inline-block;
  height: 34px;
`

export const StyledStyleButton = styled.span`
  color: #373335;
  cursor: pointer;
  display: inline-block;
  margin-right: 16px;
  padding: 0;
  height: 24px;
  width: 24px;

  ${({ active }) =>
    active &&
    css`
      color: ${gigplanetTheme.color.purple};
    `}
`
