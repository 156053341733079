import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

export const OuterWrapper = styled.div`
  padding: ${gigplanetTheme.spacing.medium};
  max-width: ${gigplanetTheme.measure.maxWidth};
  width: 100%;

  ${mediaQueries.mobile`
    padding: 30px 15px;
  `};
`

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  margin: 0;
  padding: 0;

  ${mediaQueries.tabletPortraitDown`
    flex-direction: column;
  `};
`

export const Sidebar = styled.div`
  order: 1;
  width: 100%;
  max-width: 320px;
  margin-right: ${gigplanetTheme.spacing.medium};

  ${mediaQueries.tabletPortraitDown`
    margin-right: 0;
    order: 2;
    align-self: center;
    margin-top: ${gigplanetTheme.spacing.small};
    max-width: 100%;
  `};
`

export const Content = styled.div`
  flex: 1 1 auto;
  order: 2;
  width: 100%;

  ${mediaQueries.tabletPortraitDown`
    order: 1;
  `};
`
