import styled, { css } from 'styled-components' // { css }
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'
import { ProfileDetailsRow } from '../../styles'

const profileDetailsEvenBG = '#fff'
const borderColor = gigplanetTheme.element.default.border
const sortColor = gigplanetTheme.color.purple

export const Container = styled.div``
export const ProfileDetailsRepertoireSection = styled.section`
  background-color: ${profileDetailsEvenBG};
  border-bottom: 1px solid ${borderColor};

  ${({ isSingle }) => isSingle && css``};
  padding: ${gigplanetTheme.spacing.medium};

  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.xsmall};
  `};
`
export const ProfileDetailsRepertoireRow = styled(ProfileDetailsRow)``

export const ProfileDetailsRepertoireOrderby = styled.ul`
  float: left;
  display: block;
  width: 100%;
  padding: 0 0 2.35765%;
  list-style: none;
  margin: 0;
  text-align: center;
  list-style: none;
  margin: 0;
  text-align: center;
  :last-child {
    margin-right: 0;
  }
  ${mediaQueries.mobile`
    text-align: left;
  `};
`
export const ProfileDetailsRepertoireOrderbyItem = styled.li`
  display: inline-block;
  padding-right: 10px;
`

export const ProfileDetailsRepertoireOrderbyHeading = styled(ProfileDetailsRepertoireOrderbyItem)`
  font-weight: 700;
`
export const ProfileDetailsRepertoireWrapper = styled.div`
  ${({ hasMore }) =>
    hasMore &&
    css`
      max-height: 150px;
      overflow-y: hidden;
      position: relative;
      :after {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.99) 80%);
        bottom: 0;
        content: '';
        display: block;
        height: 50px;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
    `};
  }
  :after {
    clear: both;
    content: '';
  }
`
export const ProfileDetailsRepertoireColumn = styled.div`
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 31.76157%;
  margin-left: 8.5298%;
  ${mediaQueries.mobile`
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    margin-left: 0;
    text-align: left;
  `};
  :first-child {
    float: left;
    margin-left: 17.05961%;
    ${({ isSingle }) =>
      isSingle &&
      css`
        display: block;
        width: 100%;
        margin: 0;
        text-align: center;
        ${mediaQueries.mobile`
          text-align: left;
        `};
      `};
    ${mediaQueries.mobile`
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
      margin-left: 0;
    `};
  }
  :last-child {
    margin-right: 0;
  }
  :after {
    clear: both;
    content: '';
  }
  ${mediaQueries.mobile`
    display: block;
    width: 100%;
    margin: 0;
  `};
`

export const ProfileDetailsRepertoireList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  ${({ isSingle }) =>
    isSingle &&
    css`
      display: inline-block;
    `};
`
export const ProfileDetailsRepertoireListItem = styled.li`
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  ${mediaQueries.mobile`
    font-size: 12px;
  `};
`
export const ProfileDetailsRepertoireOrderbyButton = styled.button`
  color: ${sortColor};
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: 16px;
  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: bold;
      text-decoration: underline;
    `};
`
export const ProfileDetailsRepertoireShowmore = styled.div`
  margin-top: 2.35765%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${mediaQueries.mobile`
    justify-content: flex-start;
  `};
`
export const ProfileDetailsRepertoireMedleys = styled.div`
  ${({ hasSpacing }) =>
    hasSpacing &&
    css`
      padding: 2.35765% 0 0;
    `};
`
export const ProfileDetailsRepertoireIntro = styled.div`
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 65.88078%;
  margin-left: 17.05961%;
  font-family: 'Halcom', Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 25px;
  text-align: left;
  ${mediaQueries.mobile`
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    margin-left: 0;
    font-size: 14px;
    line-height: 20px;
  `};
  p {
    margin: 0;
    font-size: 14px;
    ${mediaQueries.mobile`
      font-size: 12px;
    `};
  }
  ol {
    padding-left: 15px;
  }
  ul {
    padding-left: 20px;
  }
  li {
    font-size: 14px;
    ${mediaQueries.mobile`
      font-size: 12px;
    `};
  }
`
