import * as React from 'react'
import PropTypes from 'prop-types'

import { CurrentUserShape } from '../../models/CurrentUser'
import { GigRequestEventplannerShape } from '../../models/GigRequest'

import {
  StyledEventplannerDashboard,
  Wrapper,
  Content,
  RequestsWrapper,
  UserProfileWrapper,
} from './style'

import UserProfile from '../EventplannerUserProfile'
import EventplannerDashboardRequests from '../../containers/EventplannerDashboardRequests'

import { H1 } from '../../ui/Headings'

const EventplannerDashboard = ({ requests, currentUser }) => (
  <StyledEventplannerDashboard>
    <Wrapper>
      <H1 hideOnTablet hideOnDesktop>
        Dine forespørsler
      </H1>
      <Content>
        <RequestsWrapper>
          {requests && requests.length > 0 ? (
            <EventplannerDashboardRequests requests={requests} />
          ) : (
            <p>Send en forepørsel for å få opp dine arrangement her.</p>
          )}
        </RequestsWrapper>
        <UserProfileWrapper>
          <UserProfile currentUser={currentUser} />
        </UserProfileWrapper>
      </Content>
    </Wrapper>
  </StyledEventplannerDashboard>
)

EventplannerDashboard.propTypes = {
  currentUser: CurrentUserShape,
  requests: PropTypes.arrayOf(GigRequestEventplannerShape),
}

EventplannerDashboard.defaultProps = {
  currentUser: {},
}

export default EventplannerDashboard
