import styled, { css } from 'styled-components'
import gigplanetTheme from '../../../../styles/gigplanetTheme'
import mediaQueries from '../../../../styles/mediaQueries'
import { ProfileDetailsRow } from '../../styles'

const AudioItemBackground = gigplanetTheme.color.lightGray
const PlayerTextColor = gigplanetTheme.color.darkGray

export const ProfileDetailsAudioSection = styled.section`
  padding: 50px 2.35765% 2.35765%;
  background-color: #fff;
  border-bottom: 1px solid #dce0e0;
  padding: ${gigplanetTheme.spacing.medium};

  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.xsmall};
  `};
`
export const ProfileDetailsAudioRow = styled(ProfileDetailsRow)``

export const ProfileDetailsAudioItems = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${({ isMore }) =>
    isMore &&
    css`
      max-height: 200px;
      overflow-y: hidden;
      position: relative;
      &:after {
        overflow: hidden;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.99) 80%
        );
        height: 50px;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    `};
`

export const ProfileDetailsAudioItem = styled.li`
  width: 48.82117%;
  padding-bottom: ${gigplanetTheme.spacing.xsmall};

  ${mediaQueries.mobile`
    width: 100%;
  `};

  &:last-child {
    padding-bottom: 0;
  }
`
export const ProfileDetailsAudioItemContainer = styled.div`
  width: 100%;
  background-color: ${AudioItemBackground};
  border: 0;
  border-radius: 2px;
  padding: 10px;
  :focus {
    outline: 0;
  }
`
export const ProfileDetailsAudioItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 35px;
`
export const ProfileDetailsAudioItemButtons = styled.span``
export const ProfileDetailsAudioItemButton = styled.button`
  :focus {
    outline: 0 !important;
  }
  margin: 0;
  padding: 1px;
  cursor: pointer;
  color: #fff;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border: 0;
  background: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.4);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-box-shadow: 0 0 1px white;
  -webkit-box-shadow: 0 0 1px white;
  box-shadow: 0 0 1px white;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`
export const ProfileDetailsAudioItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
`
export const ProfileDetailsAudioItemContentName = styled.div`
  color: ${PlayerTextColor};
  flex: 1 0 auto;
  font-size: 14px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  padding: 4px 0;
`
export const ProfileDetailsAudioItemBar = styled.div`
  height: 24px;
  font-size: 1px;
  width: 100%;
  margin: 2px 0 -4px;
  color: #000;
  height: 1px;
  transition: height 100ms ease-in-out;
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      height: 34px;
      transition: height 250ms ease-in-out;
    `};
`
export const ProfileDetailsAudioItemBarTime = styled.div`
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const ProfileDetailsAudioItemBarTimeItem = styled.small`
  padding: 4px 0 0;
  color: #000;
`

export const ProfileDetailsAudioReadmore = styled.div`
  margin-top: 2.35765%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${mediaQueries.mobile`
    justify-content: flex-start;
  `};
`
