import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import gigplanetTheme from '../../styles/gigplanetTheme'
import trimString from '../../utils/trimString'
import mediaQueries from '../../styles/mediaQueries'

import Image from './components/Image'
import Author from './components/Author'

const ReactRouterLink = ({ to, children, isHighlighted = false, ...rest }) => (
  <Link to={to} {...rest}>
    {children}
  </Link>
)

ReactRouterLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  isHighlighted: PropTypes.bool,
}

const Container = styled(ReactRouterLink)`
  color: ${gigplanetTheme.element.artistCard.text};
  display: block;
  max-width: 25rem;
  min-width: 17rem;
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      ${mediaQueries.tabletLandscapeUp`
        flex-direction: row;
        
      `};

      ${mediaQueries.tabletPortraitUp`
        
        max-width: 100%;
      `};
    `};
`

const ContentWrapper = styled.div`
  padding-top: 1rem;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      ${mediaQueries.tabletLandscapeUp`
        padding-bottom: 1rem;
        max-width: 26rem;
        flex: 1 0 30%;
      `};
    `};
`

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  line-height: 1.5;
`

const Intro = styled.p`
  margin: 0;
  padding: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.5;
`

const ArticleListItem = React.memo(
  ({ slug, title, intro, author, mainImage, publishedAt, highlighted = false }) => {
    const htmlTitle = React.useMemo(() => {
      if (title && title.length > 85) {
        return `${trimString(title, 85)}&hellip;`
      }
      return title
    }, [title])

    const htmlIntro = React.useMemo(() => {
      if (intro && intro.length > 120) {
        return `${trimString(intro, 130)}&hellip;`
      }
      return intro
    }, [intro])
    const handleClick = () => {}

    return (
      <Container
        to={`/articles/${slug && slug.current ? slug.current : ''}`}
        onClick={handleClick}
        isHighlighted={highlighted}
      >
        <Image articleImage={mainImage} altText={title} highlighted={highlighted} />
        <ContentWrapper isHighlighted={highlighted}>
          <Title dangerouslySetInnerHTML={{ __html: htmlTitle }} />
          <Intro dangerouslySetInnerHTML={{ __html: htmlIntro }} />
          <Author authorImage={author.image} name={author.name} date={publishedAt} />
        </ContentWrapper>
      </Container>
    )
  },
)

ArticleListItem.propTypes = {
  slug: PropTypes.shape({
    current: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  author: PropTypes.shape({
    image: PropTypes.shape({}),
    name: PropTypes.string,
  }).isRequired,
  mainImage: PropTypes.shape({}).isRequired,
  publishedAt: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
}

export default ArticleListItem
