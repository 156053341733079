const formatBold = str => {
  const values = str.split('**')
  const parts = (values || []).map((val, i) => {
    return i % 2 === 0 ? val : `<strong>${val}</strong>`
  })
  return parts.join('')
}

const formatEm = str => {
  const values = str.split('*')
  const parts = (values || []).map((val, i) => {
    return i % 2 === 0 ? val : `<em>${val}</em>`
  })
  return parts.join('')
}

const formatBoth = str => {
  const values = str.split('***')
  const parts = (values || []).map((val, i) => {
    return i % 2 === 0 ? val : `<strong><em>${val}</em></strong>`
  })
  return parts.join('')
}

const formatSimpleMarkdown = str => {
  let formattedString = formatBoth(str)
  formattedString = formatBold(formattedString)
  formattedString = formatEm(formattedString)
  return formattedString
}

export default formatSimpleMarkdown
