import * as React from 'react'
import PropTypes from 'prop-types'
import { Heading, ContentBlock, TextLine } from './style'

const Contact = ({ contactName, contactPhone, contactEmail }) => (
  <ContentBlock>
    <Heading>Kontaktperson for arrangementet</Heading>
    {contactName ? <TextLine>Navn: {contactName}</TextLine> : null}
    {contactPhone ? <TextLine>Mobil: {contactPhone}</TextLine> : null}
    {contactEmail ? <TextLine>E-post: {contactEmail}</TextLine> : null}
  </ContentBlock>
)

Contact.propTypes = {
  contactName: PropTypes.string,
  contactPhone: PropTypes.string,
  contactEmail: PropTypes.string,
}
Contact.defaultProps = {
  contactName: '',
  contactPhone: '',
  contactEmail: '',
}

export default Contact
