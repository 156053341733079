import styled from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'

export const StyledUserAuthAlert = styled.div`
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
  color: #8a6d3b;
  margin: 30px 0;
  padding: 15px;
  text-align: center;
  width: 100%;

  ${mediaQueries.mobile`
    font-size: 14px;
    margin: 30px 0;
  `};
`
