import styled from 'styled-components'

import gigplanetTheme from '../../styles/gigplanetTheme'

export const Intro = styled.p`
  color: ${gigplanetTheme.element.default.heading};
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  margin: 0;
`
