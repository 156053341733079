import * as React from 'react'

import SVGWrapper from './SVGWrapper'

const PlayIcon = props => (
  <SVGWrapper width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M8.016 5.016l10.969 6.984-10.969 6.984v-13.969z" />
  </SVGWrapper>
)

export default PlayIcon
