import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import gigplanetTheme from '../../../styles/gigplanetTheme'

import RatingStars from '../../../ui/RatingStars'

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  height: 20px;
  margin: 15px 0;
`

const Stars = styled.div`
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Reviews = styled.div`
  height: 20px;
  padding-left: 5px;
  text-align: left;
  color: ${gigplanetTheme.element.artistCard.text};
  font-size: 14px;
  width: 30px;
  line-height: 20px;
`

const ArtistCardRatings = ({ artistRating }) => (
  <Container>
    <Stars>
      <RatingStars size="medium" rating={artistRating.average} />
    </Stars>
    <Reviews>({artistRating.reviews})</Reviews>
  </Container>
)

ArtistCardRatings.propTypes = {
  artistRating: PropTypes.shape({
    average: PropTypes.number,
    reviews: PropTypes.number,
  }),
}
export default ArtistCardRatings
