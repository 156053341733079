import * as React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { trackEvent } from '../../../../libs/analytics'
import {
  validateEventForm,
  GIGREQUEST_NOT_SET_ID,
  REQUEST_STATUS,
  translateServerErrorMessage,
} from '../../../../models/GigRequest'
import {
  setEventFormDataAction,
  setGigRequestIdFormDataAction,
} from '../../../../stores/GigRequest/GigRequestActions'
import * as gigRequestEventplannerActions from '../../../../stores/gig-request-eventplanner/actions'
import * as currentUserActions from '../../../../stores/CurrentUser/CurrentUserActions'

import {
  getEventFormData,
  getArtistFormData,
  getGigRequestId,
} from '../../../../stores/GigRequest/GigRequestReducer'
import {
  getMyEventplannerRequests,
  hasMyEventplannerRequestsLoaded,
} from '../../../../stores/gig-request-eventplanner/reducer'

import SystemDialog from '../../../SystemDialog'
import GigRequestFormEvent from './components/GigRequestFormEvent'
import GigRequestFormSubmitting from '../GigRequestFormSubmitting'
import { postJSON } from '../../../../libs/data'
import { Button } from '../../../../ui'

const reportError = message => {
  if (window.Intercom) {
    window.Intercom(
      'showNewMessage',
      `Det har skjedd en serverfeil når jeg skulle sende en forespørsel.
      Feilen sier: ${message}`,
    )
  }
}
const UPDATE_FORM_ACTION = 'update'

const formReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM_ACTION:
      return {
        ...state,
        ...action.payload,
      }
    default:
      throw new Error()
  }
}

const GigRequestFormEventStep = React.memo(({ onSubmit, onNext, onPrevious }) => {
  const dispatch = useDispatch()
  const eventFormData = useSelector(getEventFormData)
  const eventFormGigRequestId = useSelector(getGigRequestId)
  const artist = useSelector(getArtistFormData)
  const eventPlannerRequests = useSelector(getMyEventplannerRequests)
  const eventPlannerRequestsHasLoaded = useSelector(hasMyEventplannerRequestsLoaded)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [formState, formDispatch] = React.useReducer(formReducer, {
    eventType: {
      eventType: eventFormData.eventType.eventType,
      sub: eventFormData.eventType.sub,
    },
    gigRequestId: eventFormGigRequestId,
    audienceCount: eventFormData.audienceCount,
    startTime: eventFormData.startTime,
    endTime: eventFormData.endTime,
  })

  const [errorMessages, setErrorMessages] = React.useState(null)
  const [serverErrorMessage, setServerErrorMessage] = React.useState(null)

  const filteredGigRequestsEventplanner = React.useMemo(
    () => (eventPlannerRequests || []).filter(request => request.status === REQUEST_STATUS.ACTIVE),
    [eventPlannerRequests],
  )
  const handleGigRequestIdChange = e => {
    formDispatch({
      type: UPDATE_FORM_ACTION,
      payload: {
        gigRequestId: e.currentTarget.value,
      },
    })
    dispatch(setGigRequestIdFormDataAction(e.currentTarget.value))
    if (errorMessages && errorMessages.gigRequestId) {
      const newErrorMessages = { ...errorMessages }
      delete newErrorMessages.gigRequestId
      setErrorMessages(newErrorMessages)
    }
  }

  const handleEventTypeCategoryChange = e => {
    formDispatch({
      type: UPDATE_FORM_ACTION,
      payload: {
        eventType: {
          eventType: e.currentTarget.value,
          sub: '',
        },
      },
    })

    if (errorMessages && errorMessages['event-type']) {
      const newErrorMessages = { ...errorMessages }
      delete newErrorMessages['event-type']
      setErrorMessages(newErrorMessages)
    }
  }

  const handleEventTypeChange = e => {
    formDispatch({
      type: UPDATE_FORM_ACTION,
      payload: {
        eventType: {
          eventType: formState.eventType.eventType,
          sub: e.currentTarget.value,
        },
      },
    })

    if (errorMessages && errorMessages['event-type'] && e.currentTarget.value) {
      const newErrorMessages = { ...errorMessages }
      delete newErrorMessages['event-type']
      setErrorMessages(newErrorMessages)
    }
  }

  const handleAudienceCountChange = e => {
    formDispatch({
      type: UPDATE_FORM_ACTION,
      payload: {
        audienceCount: e.currentTarget.value,
      },
    })

    if (
      errorMessages &&
      errorMessages['audience-count'] &&
      e.currentTarget.value !== '' &&
      e.currentTarget.value > 0
    ) {
      const newErrorMessages = { ...errorMessages }
      delete newErrorMessages['audience-count']
      setErrorMessages(newErrorMessages)
    }
  }

  const handleTimeChange = time => {
    const startTime = time[0]
    const endTime = time[1]

    formDispatch({
      type: UPDATE_FORM_ACTION,
      payload: {
        startTime,
        endTime,
      },
    })

    if (errorMessages && errorMessages['event-date'] && startTime && endTime) {
      const newErrorMessages = { ...errorMessages }
      delete newErrorMessages['event-date']
      setErrorMessages(newErrorMessages)
    }
  }

  const handleNext = () => {
    const errorMessages = validateEventForm(formState)

    if (
      filteredGigRequestsEventplanner.length > 0 &&
      formState.gigRequestId === GIGREQUEST_NOT_SET_ID
    ) {
      setErrorMessages({
        gigRequestId:
          'Velg om du vil opprette nytt eller legge til artist på eksisterende arrangement',
      })
      return
    }
    if (Object.keys(errorMessages).length > 0) {
      setErrorMessages(errorMessages)
      return
    }
    setErrorMessages(null)
    dispatch(setEventFormDataAction(formState))
    onNext()
  }

  const handlePrevious = () => {
    dispatch(setEventFormDataAction(formState))
    onPrevious()
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      trackEvent('gig-request', 'gig-request-submitted-new-artists-to-gig-request', 'submitted')
      const result = await postJSON('/api/v1/gigRequest/addArtists', {
        gigRequestId: formState.gigRequestId,
        artistIds: [artist.id],
      })
      dispatch(currentUserActions.fetchMe())
      dispatch(gigRequestEventplannerActions.getMyEventplannerRequestsAction())
      dispatch(gigRequestEventplannerActions.getMyEventplannerRequestsCountAction())
      setIsSubmitting(false)
      setServerErrorMessage(null)
      onSubmit()
    } catch (error) {
      const genericError = error.statusCode === 404 ? '404 serverfeil' : ''
      setServerErrorMessage(
        error.response && error.response.message ? error.response.message : genericError,
      )
      trackEvent(
        'gig-request',
        'gig-request-server-error-add-artists-to-request',
        'addArtists',
        JSON.stringify({
          gigRequestId: formState.gigRequestId,
          artistData: {
            ...eventFormData,
          },
        }),
      )
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <GigRequestFormEvent
        artist={artist}
        filteredGigRequestsEventplanner={filteredGigRequestsEventplanner}
        gigRequestsEventplannerHasLoaded={eventPlannerRequestsHasLoaded}
        gigRequestId={formState.gigRequestId}
        onRequestIdChange={handleGigRequestIdChange}
        eventTypeCategory={formState.eventType.eventType}
        onEventTypeCategoryChange={handleEventTypeCategoryChange}
        eventType={formState.eventType.sub}
        onEventTypeChange={handleEventTypeChange}
        audienceCount={formState.audienceCount}
        onAudienceCountChange={handleAudienceCountChange}
        startTime={formState.startTime}
        endTime={formState.endTime}
        onTimeChange={handleTimeChange}
        errorMessages={errorMessages}
        isSubmitting={isSubmitting}
        onNext={handleNext}
        onSubmit={handleSubmit}
        onPrevious={handlePrevious}
      />
      {isSubmitting ? <GigRequestFormSubmitting /> : null}
      {serverErrorMessage && !isSubmitting ? (
        <SystemDialog id="gigrequest-form-server-error" heading="Feilmelding">
          <div>
            <p>{translateServerErrorMessage(serverErrorMessage)}</p>
            <Button
              type="button"
              theme="primary"
              onClick={() => {
                reportError(serverErrorMessage)
              }}
            >
              Meld fra om feil
            </Button>
          </div>
        </SystemDialog>
      ) : null}
    </>
  )
})

GigRequestFormEventStep.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default GigRequestFormEventStep
