import * as React from 'react'
import PropTypes from 'prop-types'
import { Container } from './style'

const GigReQuestEmptyWrapper = ({ children }) => <Container>{children}</Container>

GigReQuestEmptyWrapper.propTypes = {
  children: PropTypes.string,
}

GigReQuestEmptyWrapper.defaultProps = {
  children: null,
}

export default GigReQuestEmptyWrapper
