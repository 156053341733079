import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router'

import Layout from '../../layouts/main-layout'
import PageNotFound from '../../components/PageNotFound'
import MetaData from '../../components/MetaData'

const PageNotFoundRoute = ({ includeLayout }) =>
  includeLayout ? (
    <Layout showSearch>
      <MetaData
        title="Finner ikke siden"
        description="Vi kan ikke finne siden du forsøker å vise"
        noindex
      />
      <PageNotFound />
    </Layout>
  ) : (
    <PageNotFound />
  )

PageNotFoundRoute.propTypes = {
  includeLayout: PropTypes.bool,
}
PageNotFoundRoute.defaultProps = {
  includeLayout: false,
}

export default compose(withRouter)(PageNotFoundRoute)
