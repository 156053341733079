import * as React from 'react'
import PropTypes from 'prop-types'

import { StyledHeaderMenuButton, StyledHeaderMenuLink, Content } from './style'

const HeaderMenuButton = ({ children, to, href, newWindow, inDropdown, ...rest }) => {
  if (to) {
    return (
      <StyledHeaderMenuLink inDropdown={inDropdown} {...rest} to={to}>
        <Content inDropdown={inDropdown}>{children}</Content>
      </StyledHeaderMenuLink>
    )
  }
  if (href) {
    return (
      <StyledHeaderMenuButton
        as="a"
        inDropdown={inDropdown}
        href={href}
        {...(newWindow ? { target: '_blank' } : {})}
        {...(newWindow ? { rel: 'noopener noreferrer' } : {})}
        {...rest}
      >
        <Content inDropdown={inDropdown}>{children}</Content>
      </StyledHeaderMenuButton>
    )
  }
  return (
    <StyledHeaderMenuButton type="button" inDropdown={inDropdown} {...rest}>
      <Content inDropdown={inDropdown}>{children}</Content>
    </StyledHeaderMenuButton>
  )
}

HeaderMenuButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.any]).isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  href: PropTypes.string,
  inDropdown: PropTypes.bool,
  newWindow: PropTypes.bool,
}

HeaderMenuButton.defaultProps = {
  to: '',
  href: '',
  inDropdown: false,
  newWindow: false,
}

export default HeaderMenuButton
