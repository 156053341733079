import * as React from 'react'
import GigplanetSymbol from '../../../ui/GigplanetSymbol'
import GigplanetLogo from '../../../ui/GigplanetLogo'

import { StyledLogoLink, DesktopImage, MobileImage } from './style'

const HeaderLogo = ({ inverted }) => (
  <StyledLogoLink
    to="/"
    title="Gigplanet - Gå til forsiden"
    role="presentation"
    description="Gå til forsiden"
    inverted={inverted ? 1 : 0}
  >
    <DesktopImage>
      <GigplanetLogo alt="Gigplanet - Live musikk til ditt arrangement" inverted={inverted} />
    </DesktopImage>
    <MobileImage>
      <GigplanetLogo alt="Gigplanet - Live musikk til ditt arrangement" inverted={inverted} />
    </MobileImage>
  </StyledLogoLink>
)

export default HeaderLogo
