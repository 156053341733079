import styled, { css } from 'styled-components'
import mediaQueries from '../../styles/mediaQueries'

export const StyledHeaderMenuItem = styled.li`
  margin: 0;
  text-align: center;
  text-decoration: none;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  ${mediaQueries.mobile`
    height: 50px;
  `};

  ${({ inDropdown }) =>
    inDropdown &&
    css`
      padding: 0;
      margin: 0;
      height: 50px;
    `};
`
