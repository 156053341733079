const validatePassword = password => {
  if (!password) {
    return false
  }

  // to short
  if (password.length < 6) {
    return false
  }

  // no space
  if (!/^\S*$/.test(password)) {
    return false
  }
  // one number
  if (!/[0-9]/.test(password)) {
    return false
  }

  // one lowercase
  if (!/[a-z]/.test(password)) {
    return false
  }

  // one uppercase
  if (!/[A-Z]/.test(password)) {
    return false
  }

  return true
}

export default validatePassword
