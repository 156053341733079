import * as React from 'react'

import PropTypes from 'prop-types'
import { StyledStyleButton } from './style'

class EditorStyleButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.onToggle = this.onToggle.bind(this)
  }

  onToggle(e) {
    e.preventDefault()
    const { onToggle, style } = this.props
    onToggle(style)
  }

  render() {
    const { active, icon, label } = this.props
    return (
      <StyledStyleButton
        tabIndex="-1"
        onMouseDown={this.onToggle}
        aria-label={label}
        role="button"
        active={active}
      >
        {icon}
      </StyledStyleButton>
    )
  }
}

EditorStyleButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  style: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  icon: PropTypes.element.isRequired,
}

export default EditorStyleButton
