import * as React from 'react'
import PropTypes from 'prop-types'

import { Container, HeadingsWrapper, Intro, AvatarWrapper } from './style'

import { H1 } from '../../../../ui/Headings'
import ArtistAvatar from '../../../../ui/ArtistAvatar'

const GigRequestFormHeader = React.memo(({ heading, intro, artist }) => (
  <Container>
    <AvatarWrapper>{artist ? <ArtistAvatar artist={artist} size={96} /> : null}</AvatarWrapper>

    <HeadingsWrapper>
      <H1>{heading}</H1>
    </HeadingsWrapper>
    {intro ? <Intro>{intro}</Intro> : null}
  </Container>
))

GigRequestFormHeader.propTypes = {
  artist: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
        crop: PropTypes.shape({}),
      }),
    ),
  }),
  heading: PropTypes.string.isRequired,
  intro: PropTypes.string,
}

GigRequestFormHeader.defaultProps = {
  intro: '',
}

export default GigRequestFormHeader
