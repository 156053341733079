import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getCategories } from '../../stores/Options/OptionsReducer'

import GigRequestEventplannerDetailsSidebar from './components/GigRequestEventplannerDetailsSidebar'

const mapStateToProps = state => ({
  categories: getCategories(state),
})

const GigRequestEventplannerDetailsSidebarContainer = ({
  eventplannerRequest,
  isLoading,
  hasLoaded,
  eventTypes,
  categories,
}) => (
  <GigRequestEventplannerDetailsSidebar
    eventplannerRequest={eventplannerRequest}
    isLoading={isLoading}
    hasLoaded={hasLoaded}
    eventTypes={eventTypes}
    categories={categories}
  />
)

GigRequestEventplannerDetailsSidebarContainer.propTypes = {
  categories: PropTypes.object.isRequired, // eslint-disable-line  react/forbid-prop-types
  eventplannerRequest: PropTypes.object.isRequired, // eslint-disable-line  react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line  react/forbid-prop-types
}

GigRequestEventplannerDetailsSidebarContainer.defaultProps = {}

export default connect(mapStateToProps)(GigRequestEventplannerDetailsSidebarContainer)
