import * as React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { trackEvent } from '../../../../libs/analytics'

import {
  fetchFrontpageArtists,
  fetchFrontpageArtistsIfNeeded,
  fetchMatchingArtistsAction,
} from '../../../../stores/Artist/ArtistActions'

import {
  getFrontpageArtists,
  isFrontpageArtistsLoading,
  isMoreFrontpageArtists,
  getArtist,
  getMatchingArtists,
  hasMatchingArtistsLoaded,
  isMatchingArtistsLoading,
} from '../../../../stores/Artist/ArtistReducer'

import { Container, Actions, ArtistListWrapper, ReadMoreWrapper } from './style'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import { H2 } from '../../../../ui/landing-page-headings'
import { ArtistShape } from '../../../../models/Artist'
import ArtistList from '../../../../containers/ArtistList'
import Button from '../../../../ui/Button'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'

const ArtistMatchingListBlock = React.memo(
  ({ theme, anchorId = '', heading = '', limit, showSearchButtonText = '' }) => {
    const dispatch = useDispatch()
    const artist = useSelector(getArtist)
    const artists = useSelector(getMatchingArtists)
    const isLoading = useSelector(isMatchingArtistsLoading)
    const hasLoaded = useSelector(hasMatchingArtistsLoaded)
    React.useEffect(() => {
      if (artist) {
        dispatch(fetchMatchingArtistsAction(artist.id, limit))
      }
    }, [artist])

    if (!artists || artists.length === 0) {
      return null
    }

    const handleSearchButtonClick = () => {
      trackEvent(
        'artist-profile',
        'artist-profile-clicked-matching-artist-search',
        showSearchButtonText,
      )
    }

    return (
      <LandingPageBlockWrapper theme={theme} noPadding anchorId={anchorId}>
        <Container>
          {heading ? (
            <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
          ) : null}
          <ArtistListWrapper>
            <ArtistList artists={artists} />
          </ArtistListWrapper>
          {showSearchButtonText ? (
            <Actions>
              <ReadMoreWrapper>
                <Button
                  to="/s"
                  size="large"
                  type="button"
                  theme="outline"
                  onClick={handleSearchButtonClick}
                >
                  {showSearchButtonText}
                </Button>
              </ReadMoreWrapper>
            </Actions>
          ) : null}
        </Container>
      </LandingPageBlockWrapper>
    )
  },
)

ArtistMatchingListBlock.propTypes = {
  theme: PropTypes.string,
  heading: PropTypes.string,
  limit: PropTypes.number.isRequired,
  showSearchButtonText: PropTypes.string,
  anchorId: PropTypes.string,
}

export default ArtistMatchingListBlock
