import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { CurrentUserShape } from '../../models/CurrentUser'
import { getCurrentUser } from '../../stores/CurrentUser/CurrentUserReducer'
import { getOneEventplannerRequestForArtist } from '../../stores/gig-request-eventplanner/reducer'
import { EVENTPLANNER_RESPONSE_OPTIONS } from '../../models/GigRequest'

import ResponseOptions from './components/gig-request-response-options'

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  eventplannerRequest: getOneEventplannerRequestForArtist(state),
})

const mapDispatchToProps = dispatch => ({})

class GigRequestArtistResponseOptionsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeId: '',
      formIsDirty: false,
    }
  }

  handleCompletedAction = () => {
    this.setState({
      activeId: '',
      formIsDirty: false,
    })
  }

  handleShowDeclineQuote = e => {
    e.preventDefault()
    this.setState({
      activeId: EVENTPLANNER_RESPONSE_OPTIONS.DECLINE_QUOTE,
      formIsDirty: false,
    })
  }

  handleMarkFormAsDirty = () => {
    this.setState({
      formIsDirty: true,
    })
  }

  render() {
    const {
      eventplannerRequest,
      currentUser,
      gigRequestId,
      artistId,
      gigRequest,
      eventTypes,
    } = this.props
    const { activeId, formIsDirty } = this.state

    if (!eventplannerRequest) return null
    const artistQuote =
      eventplannerRequest && eventplannerRequest.artist && eventplannerRequest.artist.quote
        ? eventplannerRequest.artist.quote
        : null
    const printQuoteUrl = artistQuote
      ? `/print/quote/eventplanner/signed/?quoteId=${artistQuote.id}&gigRequestId=${gigRequestId}&artistId=${artistId}`
      : ''
    return (
      <ResponseOptions
        gigRequestId={gigRequestId}
        artistId={artistId}
        gigRequest={gigRequest}
        eventTypes={eventTypes}
        currentUser={currentUser}
        artist={
          eventplannerRequest && eventplannerRequest.artist ? eventplannerRequest.artist : null
        }
        artistRequest={
          eventplannerRequest && eventplannerRequest.artist && eventplannerRequest.artist.request
            ? eventplannerRequest.artist.request
            : null
        }
        artistQuote={artistQuote}
        lastActivityStatus={
          eventplannerRequest && eventplannerRequest.artist && eventplannerRequest.artist.request
            ? eventplannerRequest.artist.request.lastActivityStatus
            : null
        }
        activeId={activeId}
        onShowDeclineQuote={this.handleShowDeclineQuote}
        onCompletedAction={this.handleCompletedAction}
        formIsDirty={formIsDirty}
        onMarkFormAsDirty={this.handleMarkFormAsDirty}
        printQuoteUrl={printQuoteUrl}
      />
    )
  }
}

GigRequestArtistResponseOptionsContainer.propTypes = {
  // normal props
  gigRequestId: PropTypes.string.isRequired,
  artistId: PropTypes.string.isRequired,
  gigRequest: PropTypes.shape({}).isRequired,
  eventTypes: PropTypes.shape({}).isRequired,

  // state props
  currentUser: CurrentUserShape.isRequired,
  eventplannerRequest: PropTypes.shape({
    artist: PropTypes.shape({
      request: PropTypes.shape({
        lastActivityStatus: PropTypes.string,
      }),
      quote: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }).isRequired, // eslint-disable-line  react/forbid-prop-types
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(GigRequestArtistResponseOptionsContainer)
