import styled from 'styled-components'

import gigplanetTheme from '../../../../styles/gigplanetTheme'

export const Placeholder = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${gigplanetTheme.color.lightGray};

  svg {
    color: #fff;
    height: 98px;
    width: 98px;
  }
`
