/* globals Intercom */
import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import GigplanetLogo from '../ui/GigplanetLogo'
import Button from '../ui/Button'
import isServer from '../helpers/is-server'

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 15px;
  max-width: 210mm;
  width: 100%;
  z-index: 1;

  @media print {
    margin: 0;
    padding: 0;
    width: 210mm;
    height: 297mm;
    max-height: 297mm;
  }
`

const HeaderSpace = styled.div`
  display: none;

  @media print {
    display: block;
    height: 60px;
  }
`
const FooterSpace = styled.div`
  display: none;

  @media print {
    display: block;
    height: 0;
  }
`

const Header = styled.header`
  height: 60px;
  width: 100%;
  z-index: 4;

  svg {
    height: 40px;
    width: 140px;
  }

  @media print {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0px;

    svg {
      height: 40px;
      width: 140px;
    }
  }
`

const Content = styled.main`
  position: relative;
  z-index: 3;

  @media print {
    page-break-after: auto;
  }
`

const PrintButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  @media print {
    display: none;
    height: 0;
    padding-bottom: 0;
  }
`
const Table = styled.table`
  width: 100%;

  @media print {
    width: 100%;
  }
`
export const WaterMarkWrapper = styled.div`
  position: absolute;
  top: 250px;
  max-width: 210mm;
  width: 100%;
  text-align: center;
  z-index: 2;

  @media print {
    position: fixed;
    top: 250px;
    width: 210mm;
    text-align: center;
  }
`

export const WaterMark = styled.img`
  max-width: 100%;
`

const PrintLayout = React.memo(({ children, isPreview }) => {
  React.useEffect(() => {
    if (!isServer() && window && window.Intercom) {
      Intercom('hide')
    }
  })

  return (
    <Wrapper>
      <Header>
        <GigplanetLogo alt="Gigplanet - Live musikk til ditt arrangement" />
      </Header>
      {isPreview ? (
        <WaterMarkWrapper>
          <WaterMark src="/images/preview-watermark.png" />
        </WaterMarkWrapper>
      ) : null}
      <Table>
        <thead>
          <tr>
            <td>
              <HeaderSpace>&nbsp;</HeaderSpace>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <PrintButtonWrapper>
                <Button
                  type="button"
                  theme="primary"
                  onClick={() => {
                    window.print()
                  }}
                >
                  Skriv ut
                </Button>
              </PrintButtonWrapper>
              <Content>{children}</Content>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <FooterSpace>&nbsp;</FooterSpace>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Wrapper>
  )
})

PrintLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  isPreview: PropTypes.bool,
}
PrintLayout.defaultProps = {
  isPreview: false,
}

export default PrintLayout
