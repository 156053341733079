import * as React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import { Wrapper, Content } from './style'
import LandingPageAnchor from '../landing-page-anchor'

const LandingPageBlockWrapper = React.memo(
  ({ children, theme, anchorId, noPadding, fullWidth }) => (
    <ThemeProvider theme={{ theme }}>
      <Wrapper noPadding={noPadding}>
        {anchorId ? <LandingPageAnchor id={anchorId} /> : null}
        <Content noPadding={noPadding} fullWidth={fullWidth}>
          {children}
        </Content>
      </Wrapper>
    </ThemeProvider>
  ),
)

LandingPageBlockWrapper.propTypes = {
  theme: PropTypes.oneOf(['white', 'gray', 'black']).isRequired,
  anchorId: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  noPadding: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

LandingPageBlockWrapper.defaultProps = {
  noPadding: false,
  fullWidth: false,
  anchorId: '',
}

export default LandingPageBlockWrapper
