import styled from 'styled-components'
// import gigplanetTheme from '../../../../styles/gigplanetTheme'

export const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.35765%;
  /* $background-color: $gigplanet-border-color;
  $icon-color: $text-color;
  @include pad(); */
`
export const Heading = styled.h5`
  margin: 0 5px 0 0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
export const ShareButton = styled.button`
  background: #dce0e0;
  border: 0;
  color: #373335;
  text-decoration: none;
  font-size: 20px;
  padding: 0;
  margin: 5px;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  cursor: pointer;
`
export const ShareButtonLink = styled.a`
  background: #dce0e0;
  border: 0;
  color: #373335;
  text-decoration: none;
  font-size: 20px;
  padding: 0;
  margin: 5px;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  cursor: pointer;
`
export const ShareButtonContent = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
`
