import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router'

import { CurrentUserShape } from '../../../models/CurrentUser'
import { getCurrentUser } from '../../../stores/CurrentUser/CurrentUserReducer'

import Layout from '../../../layouts/main-layout'
import AccessDenied from '../../../components/AccessDenied'
import MetaData from '../../../components/MetaData'

import ArtistRoute from './artist'
import EventplannerRoute from './eventplanner'
import PageNotFoundRoute from '../../404'

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
})

const PrintQuoteRoute = ({ currentUser }) => {
  if (!{}.hasOwnProperty.call(currentUser, 'id')) {
    return (
      <Layout>
        <MetaData
          title="Ingen tilgang"
          description="Man må logge inn for å få tilgang til arrangementer hos Gigplanet"
          nopin
          noindex
        />
        <AccessDenied />
      </Layout>
    )
  }
  return (
    <>
      {/* <MetaData title="Kontrakt fra Gigplanet" description="" nopin /> */}
      <Switch>
        <Route path="/print/quote/artist" component={ArtistRoute} />
        <Route path="/print/quote/eventplanner" component={EventplannerRoute} />
        <Route component={PageNotFoundRoute} />
      </Switch>
    </>
  )
}

PrintQuoteRoute.propTypes = {
  currentUser: CurrentUserShape,
}

PrintQuoteRoute.defaultProps = {
  currentUser: {},
}

export default compose(
  withRouter,
  connect(mapStateToProps),
)(PrintQuoteRoute)
