import * as React from 'react'
import PropTypes from 'prop-types'

import { Container } from './style'
// &showinfo=0
const YoutubePlayer = ({ id }) => (
  <Container>
    <iframe
      title={`Youtube viser id ${id}`}
      type="text/html"
      width="720"
      height="405"
      src={`https://www.youtube.com/embed/${id}?modestbranding=1&rel=0`}
      frameBorder="0"
      allowFullScreen
    />
  </Container>
)

YoutubePlayer.propTypes = {
  id: PropTypes.string.isRequired,
}

export default YoutubePlayer
