import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import generateColorCode from '../../helpers/generateColorCode'

const StyledUserAvatar = styled.div`
  border-radius: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none !important;
  text-transform: uppercase;
`

const UserImage = styled.img`
  border-radius: 100%;
`

const UserAvatar = ({ user, size }) => {
  if (user.avatar) {
    const avatarUrl = `${user.avatar}?w=${size * 2}`
    return (
      <StyledUserAvatar
        style={{
          height: `${size}px`,
          width: `${size}px`,
          lineHeight: `${size}px`,
          minWidth: `${size}px`,
          minHeight: `${size}px`,
        }}
      >
        <UserImage
          style={{
            height: `${size}px`,
            width: `${size}px`,
          }}
          src={avatarUrl}
          alt={user.firstName}
        />
      </StyledUserAvatar>
    )
  }
  const name = user.firstName + user.lastName || ''
  const colors = generateColorCode(name)
  return (
    <StyledUserAvatar
      style={{
        backgroundColor: colors.bg,
        color: colors.fg,
        height: `${size}px`,
        width: `${size}px`,
        lineHeight: `${size}px`,
        minWidth: `${size}px`,
        minHeight: `${size}px`,
      }}
    >
      {user.firstName.charAt(0)}
    </StyledUserAvatar>
  )
}

UserAvatar.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
  size: PropTypes.number,
}
UserAvatar.defaultProps = {
  size: 100,
}

export default UserAvatar
