import styled, { css } from 'styled-components'
import mediaQueries from '../../../styles/mediaQueries'
import gigplanetTheme from '../../../styles/gigplanetTheme'

export const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const Header = styled.header`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 30px;
  text-align: right;
  width: 100%;

  ${mediaQueries.tabletPortraitDown`
    margin: 0;
  `};

  ${({ open }) =>
    open &&
    css`
      ${mediaQueries.tabletPortraitDown`
        display: flex;
      `};
    `};
`

export const Heading = styled.h3`
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
  margin: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  ${mediaQueries.tabletPortraitDown`
    display: none;
  `};
`

export const HeadingText = styled.span``

export const HeadingCount = styled.span`
  background: ${gigplanetTheme.color.primary};
  border-radius: 10px;
  color: ${gigplanetTheme.color.white};
  display: block;
  height: 18px;
  width: 18px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-left: 5px;
`
export const ToggleFilter = styled.div`
  display: none;

  ${mediaQueries.tabletPortraitDown`
    display: block;
  `};
`

export const FilterInputWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-top: 0;
  max-width: ${gigplanetTheme.measure.maxWidth};
  width: 100%;

  ${mediaQueries.tabletPortraitDown`
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 15px;
    display: none;
  `};

  ${({ open }) =>
    open &&
    css`
      ${mediaQueries.tabletPortraitDown`
        display: flex;
      `};
    `};
`
export const NameWrapper = styled.div`
  width: 100%;
`

export const SelectWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`
