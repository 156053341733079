import * as React from 'react'
import PropTypes from 'prop-types'

import { Content, Spacer, Message } from './style'

import { ModalButton, ModalContent } from '../ModalWindow'
import { H1 } from '../../ui/Headings'
import FormAction from '../../ui/form-action'
import Button from '../../ui/Button'

class ConfirmButton extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showingWindow: false,
    }
  }

  handleOpenConfirmWindow = () => {
    this.setState({
      showingWindow: true,
    })
  }

  handleCloseConfirmWindow = () => {
    this.setState({
      showingWindow: false,
    })
  }

  handleConfirm = () => {
    const { onConfirmed } = this.props
    this.setState({
      showingWindow: false,
    })

    if (onConfirmed) {
      onConfirmed()
    }
  }

  render() {
    const {
      id,
      children,
      heading,
      confirmMessage,
      theme,
      renderButton,
      confirmButtonText,
    } = this.props
    const { showingWindow } = this.state
    return (
      <>
        <ModalButton
          id={id}
          theme={theme}
          onClick={this.handleOpenConfirmWindow}
          isOpen={showingWindow}
          renderButton={renderButton}
        >
          {children}
        </ModalButton>
        <ModalContent
          id={id}
          isOpen={showingWindow}
          onClose={this.handleCloseConfirmWindow}
          hideClose
          label={heading}
        >
          <Content>
            <H1>{heading}</H1>
            <Spacer />
            <Message>{confirmMessage}</Message>
            <FormAction>
              <Button type="button" theme="abort" onClick={this.handleConfirm}>
                {confirmButtonText || 'Bekreft'}
              </Button>
              <Button type="button" theme="cancel" onClick={this.handleCloseConfirmWindow}>
                Avbryt
              </Button>
            </FormAction>
          </Content>
        </ModalContent>
      </>
    )
  }
}

ConfirmButton.propTypes = {
  id: PropTypes.string.isRequired,
  theme: PropTypes.string,
  heading: PropTypes.string.isRequired,
  confirmMessage: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string,
  onConfirmed: PropTypes.func.isRequired,
  children: PropTypes.node,
  renderButton: PropTypes.func,
}

ConfirmButton.defaultProps = {
  renderButton: null,
  theme: '',
  confirmButtonText: '',
}

export default ConfirmButton
