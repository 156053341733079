import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import { trackEvent } from '../../../../libs/analytics'

import { fetchFavoriteArtistsAction } from '../../../../stores/Artist/ArtistActions'

import {
  getFavoriteArtists,
  isFavoriteArtistsLoading,
} from '../../../../stores/Artist/ArtistReducer'

import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'
import { H2 } from '../../../../ui/landing-page-headings'
import ArticleCard from '../../../ArticleCard'
import mediaQueries from '../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../styles/gigplanetTheme'

export const ArticleList = styled.ul`
  margin: 2rem 0 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  max-width: ${gigplanetTheme.measure.maxWidth};
  width: 100%;

  ${mediaQueries.tabletLandscape`
    justify-content: center;
  `};

  ${mediaQueries.tabletPortrait`
    justify-content: center;
  `};

  ${mediaQueries.mobile`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
  `};
`

export const ArticleItemWrapper = styled.li`
  padding: ${gigplanetTheme.spacing.small};
  flex: 1 0 auto;
  max-width: 26rem;
  min-width: 18.5rem;

  ${mediaQueries.mobile`
    max-width: 26rem;
    min-width: 20rem;
    padding: 0 0.5rem 0.5rem;
    width: 100%;
    flex: 1 0 100%;

    &:last-child {
      padding-bottom: 0;
    }
 `};

  ${mediaQueries.tabletPortrait`
   flex: 1 0 50%;
   
   ${({ highlighted }) =>
     highlighted &&
     css`
       flex: 1 0 100%;
     `};
 `};

  ${mediaQueries.tabletLandscapeUp`
   flex: 1 0 30%;
   
   ${({ highlighted }) =>
     highlighted &&
     css`
       flex: 1 0 100%;
     `};
 `};

  ${({ highlighted }) =>
    highlighted &&
    css`
      width: 100%;
      max-width: 100%;
    `};
`

const ArticleLatestListBlock = React.memo(
  ({ theme, anchorId = '', heading = '', articles, highlightFirst = false }) => {
    return (
      <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
        {heading ? (
          <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
        ) : null}
        {articles && articles.length > 0 ? (
          <ArticleList>
            {articles.map((article, index) => (
              <ArticleItemWrapper
                key={article.id}
                highlighted={Boolean(index === 0 && highlightFirst)}
              >
                <ArticleCard
                  slug={article.slug}
                  title={article.title}
                  intro={article.intro}
                  author={article.author}
                  mainImage={article.mainImage}
                  publishedAt={article.publishedAt}
                  highlighted={Boolean(index === 0 && highlightFirst)}
                />
              </ArticleItemWrapper>
            ))}
          </ArticleList>
        ) : (
          <div>Ingen artikler</div>
        )}
      </LandingPageBlockWrapper>
    )
  },
)

ArticleLatestListBlock.propTypes = {
  theme: PropTypes.string.isRequired,
  anchorId: PropTypes.string,
  heading: PropTypes.string,
  articles: PropTypes.arrayOf(PropTypes.shape({})),
  highlightFirst: PropTypes.bool,
}

export default ArticleLatestListBlock
