import * as React from 'react'
import PropTypes from 'prop-types'

import { Select } from '../../../ui/Form'
import InputSearch from '../../../components/input-search'
import SearchFiltersSelectedElement from '../../../components/search-filter-selected-element'
import {
  Container,
  Heading,
  Header,
  HeadingText,
  HeadingCount,
  ToggleFilter,
  FilterInputWrapper,
  NameWrapper,
  SelectWrapper,
} from './style'
import Button from '../../../ui/Button'
import PriceFilter from './PriceFilter'

class SearchFilters extends React.PureComponent {
  constructor(props) {
    super(props)
    const { startWithOpenFilter } = props
    this.state = {
      showingFilter: startWithOpenFilter,
    }
  }

  handleArtistTypeChange = e => {
    const { onArtistTypeChange } = this.props
    e.preventDefault()
    onArtistTypeChange(e.currentTarget.value)
  }

  handleArtistTypeReset = e => {
    const { onArtistTypeChange } = this.props
    e.preventDefault()
    onArtistTypeChange('')
  }

  handleCategoryChange = e => {
    const { onCategoryChange } = this.props
    e.preventDefault()
    onCategoryChange(e.currentTarget.value)
  }

  handleCategoryReset = e => {
    const { onCategoryChange } = this.props
    e.preventDefault()
    onCategoryChange('')
  }

  handlePerformLocationChange = e => {
    const { onPerformLocationChange } = this.props
    e.preventDefault()
    onPerformLocationChange(e.currentTarget.value)
  }

  handlePerformLocationReset = e => {
    const { onPerformLocationChange } = this.props
    e.preventDefault()
    onPerformLocationChange('')
  }

  handleGenreChange = e => {
    const { onGenreChange } = this.props
    e.preventDefault()
    onGenreChange(e.currentTarget.value)
  }

  handleGenreReset = e => {
    const { onGenreChange } = this.props
    e.preventDefault()
    onGenreChange('')
  }

  handleEventTypeChange = e => {
    const { onEventTypeChange } = this.props
    e.preventDefault()
    onEventTypeChange(e.currentTarget.value)
  }

  handleEventTypeReset = e => {
    const { onEventTypeChange } = this.props
    e.preventDefault()
    onEventTypeChange('')
  }

  toggleFilter = () => {
    const { showingFilter } = this.state
    this.setState({
      showingFilter: !showingFilter,
    })
  }

  render() {
    const {
      artistType,
      category,
      categories,
      filters,
      performLocation,
      eventType,
      genre,
      genres,
      name,
      counties,
      eventTypes,
      onResetSearch,
      onNameChange,
      priceFrom,
      priceTo,
      onPriceFromChange,
      onPriceToChange,
    } = this.props
    const { showingFilter } = this.state
    let filterCount = 0
    if (artistType) {
      filterCount += 1
    }
    if (category) {
      filterCount += 1
    }
    if (performLocation) {
      filterCount += 1
    }
    if (eventType) {
      filterCount += 1
    }
    if (genre) {
      filterCount += 1
    }
    if (name) {
      filterCount += 1
    }

    /*
    const artistTypeFilter = {};
    if (this.props.filters.genres) {
      this.props.filters.artistTypes.forEach((e) => {
        artistTypeFilter[e] = true;
      });
    }
    const artistTypeOptions = Object.keys(this.props.artistTypes).map(artistType => ({
      value: artistType,
      name: this.props.artistTypes[artistType].name,
      disabled: !this.props.artistType && !artistTypeFilter[artistType],
    }));
    */

    const categoryOptions = []
    let categoryValue = ''
    const categoryFilter = {}

    if (filters.categories) {
      filters.categories.forEach(e => {
        categoryFilter[e] = true
      })
    }
    Object.keys(categories).forEach(index => {
      const options = []
      Object.keys(categories[index].sub).forEach(subIndex => {
        if (categories[index].sub[subIndex].hasArtists) {
          options.push(
            <option
              key={`${index}-${subIndex}`}
              value={subIndex}
              disabled={!category && !categoryFilter[subIndex]}
            >
              {categories[index].sub[subIndex].name}
              {!category && !categoryFilter[subIndex] ? ' (Ingen treff)' : ''}
            </option>,
          )
          if (category === subIndex) {
            categoryValue = categories[index].sub[subIndex].name
          }
        }
      })
      if (options.length > 0) {
        categoryOptions.push(
          <optgroup key={index} label={categories[index].name}>
            {options}
          </optgroup>,
        )
      }
    })

    const performLocationOptions = []
    let performLocationValue = ''
    const locationsFilter = {}
    if (filters.locations) {
      filters.locations.forEach(e => {
        locationsFilter[e] = true
      })
    }
    ;(counties || []).forEach(county => {
      let countyName = county.name
      if (!performLocation && !locationsFilter[county.id]) {
        countyName += ' (Ingen treff)'
      }
      performLocationOptions.push({
        value: county.id,
        name: countyName,
        disabled: !performLocation && !locationsFilter[county.id],
      })
      if (performLocation === county.id) {
        performLocationValue = county.name
      }
    })

    const genreOptions = []
    let genreValue = ''
    const genresFilter = {}
    if (filters.genres) {
      filters.genres.forEach(e => {
        genresFilter[e] = true
      })
    }
    Object.keys(genres).forEach(currentGenre => {
      let genresName = genres[currentGenre].name
      if (!genre && !genresFilter[currentGenre]) {
        genresName += ' (Ingen treff)'
      }
      genreOptions.push({
        value: currentGenre,
        name: genresName,
        disabled: !genre && !genresFilter[currentGenre],
      })
      if (genre === currentGenre) {
        genreValue = genres[genre].name
      }
    })

    const eventTypeOptions = []
    let eventTypeValue = ''
    const eventTypesFilter = {}
    if (filters.eventTypes) {
      filters.eventTypes.forEach(e => {
        eventTypesFilter[e] = true
      })
    }

    Object.keys(eventTypes).forEach(index => {
      const options = []
      Object.keys(eventTypes[index].sub).forEach(key => {
        options.push(
          <option key={key} value={key} disabled={!eventType && !eventTypesFilter[key]}>
            {eventTypes[index].sub[key].name}
            {!eventType && !eventTypesFilter[key] ? ' (ingen treff)' : ''}
          </option>,
        )
        if (eventType === key) {
          eventTypeValue = eventTypes[index].sub[key].name
        }
      })
      eventTypeOptions.push(
        <optgroup label={eventTypes[index].longName} key={index}>
          {options}
        </optgroup>,
      )
    })

    return (
      <Container>
        <Header open={showingFilter}>
          <Heading>
            <HeadingText>Filter</HeadingText>
            {filterCount > 0 ? <HeadingCount>{filterCount}</HeadingCount> : null}
          </Heading>
          <ToggleFilter>
            <Button type="button" theme="outline" onClick={this.toggleFilter}>
              <HeadingText>{showingFilter ? 'Skjul filter' : 'Vis filter'}</HeadingText>
              {filterCount > 0 ? <HeadingCount>{filterCount}</HeadingCount> : null}
            </Button>
          </ToggleFilter>
          {filterCount > 0 ? (
            <Button type="button" theme="text" onClick={onResetSearch}>
              Nullstill søk
            </Button>
          ) : null}
        </Header>
        <FilterInputWrapper open={showingFilter}>
          <NameWrapper>
            <InputSearch
              id="filter-search-artist-type"
              name="filter-search-artist-type"
              value={name}
              onChange={onNameChange}
              placeholder="Søk etter artistnavn"
              showValueMarker={!!name} // eslint-disable-line
              expanded
            />
          </NameWrapper>
          <SelectWrapper>
            {eventType ? (
              <SearchFiltersSelectedElement
                id="frontpage-search-event-type"
                label="Type arrangement"
                value={eventTypeValue}
                onReset={this.handleEventTypeReset}
              />
            ) : (
              <Select
                id="frontpage-search-event-type"
                name="frontpage-search-event-type"
                label="Type arrangement"
                value={eventType}
                onChange={this.handleEventTypeChange}
                actionLabel="Vis alle arrangementtyper"
                expanded
                showValueMarker={!!eventType}
              >
                {eventTypeOptions}
              </Select>
            )}
          </SelectWrapper>
          <SelectWrapper>
            {performLocation ? (
              <SearchFiltersSelectedElement
                id="frontpage-search-location"
                label="Sted"
                value={performLocationValue}
                onReset={this.handlePerformLocationReset}
              />
            ) : (
              <Select
                id="frontpage-search-location"
                name="frontpage-search-location"
                options={performLocationOptions}
                label="Sted"
                value={performLocation}
                onChange={this.handlePerformLocationChange}
                actionLabel="Vis alle steder"
                expanded
                showValueMarker={!!performLocation}
              />
            )}
          </SelectWrapper>
          <PriceFilter
            key={showingFilter}
            priceFrom={priceFrom}
            onPriceFromChange={onPriceFromChange}
            priceTo={priceTo}
            onPriceToChange={onPriceToChange}
          />
          <SelectWrapper>
            {category ? (
              <SearchFiltersSelectedElement
                id="frontpage-search-category"
                label="Kategori"
                value={categoryValue}
                onReset={this.handleCategoryReset}
              />
            ) : (
              <Select
                id="frontpage-search-category"
                name="frontpage-search-category"
                label="Kategori"
                value={category}
                onChange={this.handleCategoryChange}
                actionLabel="Vis alle kategorier"
                expanded
                showValueMarker={!!category}
              >
                {categoryOptions}
              </Select>
            )}
          </SelectWrapper>

          <SelectWrapper>
            {genre ? (
              <SearchFiltersSelectedElement
                id="frontpage-search-genre"
                label="Sjanger"
                value={genreValue}
                onReset={this.handleGenreReset}
              />
            ) : (
              <Select
                id="frontpage-search-genre"
                name="frontpage-search-genre"
                options={genreOptions}
                label="Sjanger"
                value={genre}
                onChange={this.handleGenreChange}
                actionLabel="Vis alle sjangere"
                expanded
                showValueMarker={!!genre}
              />
            )}
          </SelectWrapper>
        </FilterInputWrapper>
      </Container>
    )
  }
}

SearchFilters.propTypes = {
  filters: PropTypes.shape({
    artistTypes: PropTypes.array,
    categories: PropTypes.array,
    locations: PropTypes.array,
    genres: PropTypes.array,
    eventTypes: PropTypes.array,
  }),
  categories: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  category: PropTypes.string,
  onCategoryChange: PropTypes.func.isRequired,
  artistType: PropTypes.string,
  onArtistTypeChange: PropTypes.func.isRequired,
  counties: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  performLocation: PropTypes.string,
  onPerformLocationChange: PropTypes.func.isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  eventType: PropTypes.string,
  onEventTypeChange: PropTypes.func.isRequired,
  onResetSearch: PropTypes.func.isRequired,
  name: PropTypes.string,
  onNameChange: PropTypes.func.isRequired,
  genres: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  genre: PropTypes.string,
  onGenreChange: PropTypes.func.isRequired,
  startWithOpenFilter: PropTypes.bool,
  priceFrom: PropTypes.number,
  onPriceFromChange: PropTypes.func.isRequired,
  priceTo: PropTypes.number,
  onPriceToChange: PropTypes.func.isRequired,
}

SearchFilters.defaultProps = {
  name: '',
  artistType: '',
  eventTypes: {}, // eslint-disable-line react/default-props-match-prop-types
  totalCount: 0, // eslint-disable-line react/default-props-match-prop-types
  filters: {},
  startWithOpenFilter: false,
  priceFrom: 0,
  priceTo: 10000,
}

export default SearchFilters
