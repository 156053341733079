import * as React from 'react'
import PropTypes from 'prop-types'

import UserAuthWindow from '../../containers/user-auth-window'
import { Container } from './style'

const UserAuthModalWrapper = ({
  intro,
  onClose,
  hideHeading,
  successUrl,
  showRegister,
  continueMode,
  showNewPassword,
  showForgotPassword,
  showVerify,
}) => (
  <Container>
    <UserAuthWindow
      intro={intro}
      onClose={onClose}
      hideHeading={hideHeading}
      successUrl={successUrl}
      showRegister={showRegister}
      continueMode={continueMode}
      showNewPassword={showNewPassword}
      showForgotPassword={showForgotPassword}
      showVerify={showVerify}
    />
  </Container>
)

UserAuthModalWrapper.propTypes = {
  intro: PropTypes.string,
  onClose: PropTypes.func,
  hideHeading: PropTypes.bool,
  successUrl: PropTypes.string,
  showRegister: PropTypes.bool,
  continueMode: PropTypes.bool,
  showNewPassword: PropTypes.bool,
  showForgotPassword: PropTypes.bool,
  showVerify: PropTypes.bool,
}

UserAuthModalWrapper.defaultProps = {
  hideHeading: false,
  intro: '',
  successUrl: '/',
  onClose: null,
  showRegister: false,
  continueMode: false,
  showNewPassword: false,
  showForgotPassword: false,
  showVerify: false,
}

export default UserAuthModalWrapper
