/* eslint-disable jsx-a11y/no-autofocus */
import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Label } from '../Form'
import { CheckMarkCircleIcon } from '../../icons'
import gigplanetTheme from '../../styles/gigplanetTheme'

const Container = styled.div`
  max-width: 450px;
  min-width: 70px;
  position: relative;
  width: 100%;
  text-align: left;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      max-width: 100%;
    `};
`

const Wrapper = styled.div`
  white-space: nowrap;
  display: flex;
  position: relative;
`

const NotRequired = styled.span`
  font-size: 12px;
  margin-left: 10px;
`
const LabelText = styled.span``

const InputElement = styled.input`
  flex: 1 0 auto;
  appearance: none;
  background-color: ${gigplanetTheme.component.form.input.background};
  border: 1px solid ${gigplanetTheme.component.form.input.border};
  border-radius: 2px;
  color: ${gigplanetTheme.component.form.input.text};
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  padding: 8px 10px 8px 30px;
  transition: border-color 0.2s ease-in-out;
  width: 100%;

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid ${gigplanetTheme.component.form.shared.errorText};
    `};

  ${({ isValidated }) =>
    isValidated &&
    css`
      padding-right: 38px;
    `};

  &[disabled] {
    background-color: ${gigplanetTheme.component.form.input.backgroundDisabled};
    color: ${gigplanetTheme.component.form.input.textDisabled};
  }
`

const ValidatedWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 38px;
  width: 34px;
  line-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${gigplanetTheme.component.form.input.validated};
    height: 24px;
    width: 24px;
  }
`

const HelpText = styled.small`
  color: ${gigplanetTheme.component.form.input.helpText};
  font-size: 12px;
  line-height: 15px;
`
const InputContainer = styled.div`
  position: relative;
  width: 100%;
`

const Prefix = styled.label`
  color: ${gigplanetTheme.component.form.input.text};
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  padding: 8px 10px;
  position: absolute;
  left: 0px;
  top: 1px;
  cursor: text;
`

const ErrorMessage = styled.small`
  color: ${gigplanetTheme.component.form.shared.errorText};
  font-size: 12px;
  line-height: 15px;
`

const CurrencyInput = React.forwardRef(
  (
    {
      label,
      hideLabel,
      helpText,
      placeholder,
      id,
      name,
      value,
      errorMessage = '',
      onChange,
      onBlur,
      onFocus,
      onKeyDown,
      labelPositioning,
      max,
      min,
      expanded,
      disabled,
      required,
      autoFocus = false,
      postfix,
      labelHelp = '',
      validated = false,
      showValueMarker = false,
      showNotRequired = false,
    },
    ref,
  ) => {
    let typeText = 'number'

    const tmpLabel = (
      <Label
        htmlFor={id}
        labelHelp={labelHelp}
        hide={hideLabel}
        showValueMarker={showValueMarker}
        position={labelPositioning}
      >
        <LabelText>{label}</LabelText>
        {!required && showNotRequired ? <NotRequired>(Valgfritt)</NotRequired> : null}
      </Label>
    )

    const tmpHelpText = <HelpText>{helpText}</HelpText>
    const tmpErrorMessage = <ErrorMessage>{errorMessage}</ErrorMessage>

    const tmpValidated = (
      <ValidatedWrapper>
        <CheckMarkCircleIcon />
      </ValidatedWrapper>
    )

    const validatedElement = validated ? tmpValidated : null
    let helpTextElement = null
    if (errorMessage) {
      helpTextElement = tmpErrorMessage
    } else if (helpText) {
      helpTextElement = tmpHelpText
    }

    const labelElement = label ? tmpLabel : null
    return (
      <Container isExpanded={expanded}>
        {labelPositioning !== 'left' ? labelElement : null}
        <Wrapper>
          {labelPositioning === 'left' ? labelElement : null}
          <InputContainer>
            <Prefix htmlFor={id}>Kr</Prefix>
            <InputElement
              type={typeText}
              ref={ref || null}
              id={id}
              name={name}
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              required={required}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              readOnly={disabled}
              autoFocus={autoFocus}
              hasError={!!errorMessage}
              isValidated={validated}
              max={max}
              min={min}
            />
          </InputContainer>
          {validatedElement}
        </Wrapper>
        {helpTextElement}
      </Container>
    )
  },
)

CurrencyInput.propTypes = {
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  helpText: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  labelPositioning: PropTypes.string,
  maxLength: PropTypes.number,
  maxTmpLength: PropTypes.number,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  postfix: PropTypes.string,
  labelHelp: PropTypes.string,
  validated: PropTypes.bool,
  showValueMarker: PropTypes.bool,
  showNotRequired: PropTypes.bool,
}

export default CurrencyInput
