import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const StyledAdminBannerWarning = styled.div`
  background: ${gigplanetTheme.color.orange};
  width: 100%;
  text-align: center;
  font-weight: 600;
  padding: ${gigplanetTheme.spacing.medium};

  ${mediaQueries.mobile`
    padding: ${gigplanetTheme.spacing.small};
  `}
`
