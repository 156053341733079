import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes, css } from 'styled-components'
import FocusTrap from 'focus-trap-react'
import Div100vh from 'react-div-100vh'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import media from '../../styles/mediaQueries'

import { CloseIcon } from '../../icons'
import { Portal } from '../'
import gigplanetTheme from '../../styles/gigplanetTheme'

const enterBackdropAnimation = keyframes`
  0%   {opacity: 0; }
  100% {opacity: 1; }
`

const enterAnimation = keyframes`
  0%   {opacity: .3; transform: scale(.5) }
  100% {opacity: 1; transform: scale(1)}
`
const ModalRoot = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: ${gigplanetTheme.zIndex.modalWindow};

  ${({ isToolbarHidden }) =>
    !isToolbarHidden &&
    css`
      ${media.mobileDown`
        padding-bottom: 8rem;
      `};
    `};

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      flex: 1 1 auto;
    `};
`

const Backdrop = styled.div`
  background: rgba(0, 0, 0, 0.2);
  animation-name: ${enterBackdropAnimation};
  animation-duration: 200ms;
  animation-timing-function: cubic-bezier(0, 0, 0, 1);
  animation-iteration-count: once;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
`

const StyledModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 0;
  border-radius: 6px;
  padding: 0;
  animation-name: ${enterAnimation};
  animation-duration: 200ms;
  animation-timing-function: cubic-bezier(0, 0, 0, 1);
  animation-iteration-count: once;
  transform-origin: center;
  box-sizing: border-box;
  max-width: 880px;

  max-height: calc(100% - 8rem);
  // min-height: calc(100% - 8rem);
  background: ${gigplanetTheme.color.white};
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.1), 0 9px 46px 8px rgba(0, 0, 0, 0.08),
    0 11px 15px -7px rgba(0, 0, 0, 0.02);

  ${media.mobileDown`
    max-height: calc(100% - 2rem);
    max-width: calc(100% - 2rem);
    width: 100%;
    ${({ fullscreen }) =>
      fullscreen &&
      css`
        min-height: calc(100% - 2rem);
      `};
  `};

  ${media.desktopUp`
    ${({ fullscreen }) =>
      fullscreen &&
      css`
        width: 100%;
        max-width: 1200px;
        min-height: calc(100% - 2rem);
      `};
  `};
`

const ModalContentRoot = styled.div`
  position: relative;
  display: flex;
  border-radius: 0 0 6px 6px;
  outline: none;
  padding: 2rem;
  color: #222222;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);

  ${media.mobileDown`
    padding: 1rem;
    ${({ fullscreen }) =>
      fullscreen &&
      css`
        min-width: calc(100% - 1rem);
        min-height: calc(100% - 1rem);
        // max-width: calc(100% - 2rem);
        max-height: calc(100% - 1rem);
      `};
  `};

  ${media.tabletPortraitUp`
    ${({ fullscreen }) =>
      fullscreen &&
      css`
        min-width: calc(100% - 2rem);
        min-height: calc(100% - 2rem);
        // max-width: calc(100% - 2rem);
        max-height: calc(100% - 2rem);
      `};
  `}

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      flex: 1 1 auto;
    `};
`

const ModalContent = styled.div`
  position: relative;
  padding: 0;
  min-width: 18rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: visible;
  -webkit-overflow-scrolling: touch;
  height: 100%;

  ${media.mobile`
    min-width: 16rem;
  `};

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      flex: 1 1 auto;
      height: auto;
    `};
`

const Header = styled.header`
  position: relative;
  height: 3.5rem;
  min-height: 3.5rem;
  line-height: 3.5rem;
  background-color: #fff;
  border-radius: 6px 6px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  &:after {
    content: '';
    width: 100%;
    background: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, 0.04) 75%, rgba(0, 0, 0, 0.12));
    height: 0.25em;
    top: 3.375em;
    position: absolute;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
    transition: opacity 0.2s linear;
  }

  ${media.mobileDown`
    padding: 0 1rem;
  `};
`

const CloseButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
  height: 2rem;
  width: 2rem;

  svg {
    fill: ${gigplanetTheme.color.black};
    width: 1.5rem;
    height: 1.5rem;

    ${media.tabletPortraitUp`
      width: 2rem;
      height: 2rem;
    `}
  }
`

const Heading = styled.h1`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 0 1rem 0 0;
  margin: 0;
  font-weight: bold;

  ${media.tabletPortraitUp`
    font-size: 1.5rem;
    line-height: 1.5;
    margin: 0;
  `};
`

const Modal = ({ id, isOpen, label, onRequestClose, children, fullscreen = false }) => {
  const scrollRef = React.useRef(null)
  const [isToolbarHidden, setIsToolbarHidden] = React.useState(false)
  const mountingRef = React.useCallback((node) => {
    if (node !== null) {
      disableBodyScroll(scrollRef.current)
    } else {
      enableBodyScroll(scrollRef.current)
    }
  }, [])

  React.useEffect(() => {
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isOpen])

  if (!isOpen) {
    return null
  }

  return (
    <Portal>
      <Div100vh
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          zIndex: gigplanetTheme.zIndex.modalWindow,
        }}
      >
        <Backdrop />
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true,
            onDeactivate: () => {
              // To ensure we are not running close twice
              if (isOpen) {
                onRequestClose()
              }
            },
          }}
        >
          <StyledModal
            aria-label={label}
            id={id}
            role="dialog"
            fullscreen={fullscreen}
            tabIndex="-1"
            ref={mountingRef}
          >
            <Header>
              <Heading>{label}</Heading>
              <CloseButton onClick={onRequestClose} aria-label="Lukk">
                <CloseIcon />
              </CloseButton>
            </Header>
            <ModalContentRoot fullscreen={fullscreen}>
              <ModalContent ref={scrollRef} fullscreen={fullscreen}>
                {children}
              </ModalContent>
            </ModalContentRoot>
          </StyledModal>
        </FocusTrap>
      </Div100vh>
    </Portal>
  )
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  onRequestClose: PropTypes.func,
}

export default Modal
