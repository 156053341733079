import * as React from 'react'
import styled from 'styled-components'

import { imageUrlFor } from '../../../../../helpers/sanity-image-builder'
import { mediaQueriesDefinitions } from '../../../../../styles/mediaQueries'

import { CameraIcon } from '../../../../../icons'
import SanityImage from '../../../../sanity-image'
import gigplanetTheme from '../../../../../styles/gigplanetTheme'

const Placeholder = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${gigplanetTheme.color.gray};
  border-radius 3px;

  svg {
    color: ${gigplanetTheme.color.darkGray};
    height: 98px;
    width: 98px;
  }
`

const Image = React.memo(
  ({
    visible,
    altText,
    imageMobile,
    imageTabletPortrait,
    imageTabletLandscape,
    imageDesktop,
    imageDesktopBig,
  }) => (
    <>
      {visible ? (
        <SanityImage image={imageMobile} altText={altText}>
          <source media={mediaQueriesDefinitions.mobile} srcSet={imageUrlFor(imageMobile)} />
          <source
            media={`${mediaQueriesDefinitions.mobile} and (min-resolution: 2dppx)`}
            srcSet={imageUrlFor(imageMobile).dpr(2)}
          />
          <source
            media={mediaQueriesDefinitions.tabletPortrait}
            srcSet={imageUrlFor(imageTabletPortrait)}
          />
          <source
            media={`${mediaQueriesDefinitions.tabletPortrait} and (min-resolution: 2dppx)`}
            srcSet={imageUrlFor(imageTabletPortrait).dpr(2)}
          />
          <source
            media={mediaQueriesDefinitions.tabletLandscape}
            srcSet={imageUrlFor(imageTabletLandscape)}
          />
          <source
            media={`${mediaQueriesDefinitions.tabletLandscape} and (min-resolution: 2dppx)`}
            srcSet={imageUrlFor(imageTabletLandscape).dpr(2)}
          />
          <source media={mediaQueriesDefinitions.desktop} srcSet={imageUrlFor(imageDesktop)} />
          <source
            media={`${mediaQueriesDefinitions.desktop} and (min-resolution: 2dppx)`}
            srcSet={imageUrlFor(imageDesktop).dpr(2)}
          />
          <source
            media={mediaQueriesDefinitions.desktopBig}
            srcSet={imageUrlFor(imageDesktopBig)}
          />
          <source
            media={`${mediaQueriesDefinitions.desktopBig} and (min-resolution: 2dppx)`}
            srcSet={imageUrlFor(imageDesktopBig).dpr(2)}
          />
        </SanityImage>
      ) : (
        <Placeholder>
          <CameraIcon />
        </Placeholder>
      )}
    </>
  ),
)

export default Image
