import * as React from 'react'
import PropTypes from 'prop-types'

import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'

import { RichTextContainer, RichTextWrapper } from './style'

import SanityHtmlBody from '../../../sanity-html-body'

import { H2 } from '../../../../ui/landing-page-headings'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import LandingpageHeadingWrapper from '../../../../ui/landing-page-heading-wrapper'

const RichTextBlock = React.memo(({ theme, anchorId, heading, body, textSize }) => (
  <LandingPageBlockWrapper theme={theme} anchorId={anchorId}>
    {heading ? (
      <LandingpageHeadingWrapper>
        <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
      </LandingpageHeadingWrapper>
    ) : null}
    <RichTextContainer>
      <RichTextWrapper textSize={textSize}>
        <SanityHtmlBody body={body} imageOptions={{ w: 650, h: 400, fit: 'max' }} />
      </RichTextWrapper>
    </RichTextContainer>
  </LandingPageBlockWrapper>
))

RichTextBlock.propTypes = {
  theme: PropTypes.string.isRequired,
  heading: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  textSize: PropTypes.oneOf(['large', 'small']).isRequired,
  anchorId: PropTypes.string,
}

RichTextBlock.defaultProps = {
  heading: '',
  anchorId: '',
}

export default RichTextBlock
