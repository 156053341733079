import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'

import { getImgixCroppedUrl, ARTIST_IMAGE_IMGIX_FORMAT_LIST_DESKTOP } from '../../../models/Artist'

import { CameraIcon } from '../../../icons'
import gigplanetTheme from '../../../styles/gigplanetTheme'

const Image = styled.div`
  position: relative;
  width: 100%;
  height: 225px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  ${({ isSmall }) =>
    isSmall &&
    css`
      height: 70px;
      width: 80px;
      min-width: 100px;
    `};
`
const Placeholder = styled.div`
  width: 100%;
  height: 225px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${gigplanetTheme.color.lightGray};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  svg {
    color: #fff;
    height: 98px;
    width: 98px;
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      height: 70px;
      width: 80px;
      min-width: 100px;

      & svg {
        height: 48px;
        width: 48px;
      }
    `};
`

const ArtistCardImage = React.memo(({ artistImage }) => {
  const [visible, setVisible] = React.useState(false)

  const handleVisibilityChange = isVisible => {
    if (visible || !isVisible) {
      return
    }
    setVisible(true)
  }

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      active={!visible}
      offset={{ bottom: -150 }}
    >
      {visible && artistImage && artistImage.src ? (
        <Image
          style={{
            backgroundImage: `url(${getImgixCroppedUrl(
              artistImage.src,
              ARTIST_IMAGE_IMGIX_FORMAT_LIST_DESKTOP,
              artistImage.crop,
            )})`,
          }}
        />
      ) : (
        <Placeholder>
          <CameraIcon />
        </Placeholder>
      )}
    </VisibilitySensor>
  )
})

ArtistCardImage.propTypes = {
  artistImage: PropTypes.shape({
    src: PropTypes.string,
    crop: PropTypes.object,
  }).isRequired,
}

export default ArtistCardImage
