import styled, { css } from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'
import { H2 } from '../../ui/Headings'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const StyledInfo = styled.div`
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
  color: #8a6d3b;
  margin-bottom: 15px;
  padding: 15px;
  text-align: left;
  width: 100%;
  line-height: 24px;

  ${mediaQueries.mobile`
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  `};
`

export const Congrats = styled.p`
  text-align: center;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${gigplanetTheme.color.darkGray};
  font-weight: bold;
`

export const StyledGigRequestTimelineQuote = styled.div`
  text-align: center;
  width: 100%;

  ${({ isDeclined }) =>
    isDeclined &&
    css`
      ${H2} {
        color: red;
      }
    `};
`

export const InvalidatedNote = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  margin: 5px 0 0 0;
`

export const DeclinedReason = styled.p`
  font-weight: 600;
  line-height: 24px;
`

export const WithdrawnReason = styled.p`
  font-weight: 600;
  line-height: 24px;
`
export const ReasonText = styled.p`
  line-height: 24px;
`

export const ExpiresText = styled.p`
  font-weight: 600;
  line-height: 24px;
`

export const QuoteDetails = styled.p`
  margin: 0 0 15px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  ${mediaQueries.mobile`
    font-size: 14px;
    line-height: 16px;
  `};
`

export const TermsText = styled.pre`
  font-weight: 300;
  margin: 5px 0 0 0;
  font-size: 16px;
  line-height: 24px;
  font-family: inherit;
  white-space: pre-wrap;

  ${mediaQueries.mobile`
    font-size: 16px;
  `};
`
