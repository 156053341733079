import * as React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { createFocusTrap } from 'focus-trap'

import gigplanetTheme from '../../../styles/gigplanetTheme'
import noScroll from '../../../libs/noScroll'

import { CloseIcon } from '../../../icons'

const Container = styled.div`
  position: relative;
  z-index: ${gigplanetTheme.zIndex.dropdown};
`
const Backdrop = styled.div`
  background-color: rgba(255, 255, 255, 0.3);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: ${gigplanetTheme.zIndex.dropdown};
  outline: none;
`

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -100px;
  background: #fff;
  min-width: 200px;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: ${gigplanetTheme.zIndex.dropdown + 1};
  outline: none;

  ${({ isRight }) =>
    isRight &&
    css`
      left: auto;
      right: 0;
      margin-left: 0;
    `};
`

const CloseButton = styled.button`
  display: none;

  svg {
    height: 25px;
    width: 25px;
  }
`

class Content extends React.Component {
  constructor(props) {
    super(props)
    this.menu = React.createRef()
    this.content = React.createRef()
    this.focusTrap = null
    this.state = {}
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props
    if (prevProps.isOpen === false && isOpen === true) {
      this.handleOpen()
    }

    if (prevProps.isOpen === true && isOpen === false) {
      this.handleClose()
    }
  }

  componentWillUnmount() {
    this.handleClose()
  }

  // Focus the menu when it opens and prevent the document from scrolling.
  handleOpen = () => {
    const { onClose } = this.props
    if (this.menu && this.menu.current) {
      this.focusTrap = createFocusTrap(this.menu.current, {
        onDeactivate: onClose,
      })
    }

    noScroll.on()
    this.focusTrap.activate()
    if (this.content && this.content.current) {
      this.content.current.focus()
    }
  }

  // Make the document scrollable again when the menu closes.
  handleClose = () => {
    if (this.focusTrap) {
      this.focusTrap.deactivate()
    }
    noScroll.off()
  }

  handleClick = () => {
    this.closeMenu()
  }

  closeMenu = () => {
    const { isOpen, onClose } = this.props
    if (isOpen) {
      this.handleClose()
    }

    onClose()
  }

  renderContent = () => {
    const { rightSide, isOpen, id, label, children } = this.props

    if (isOpen === false) {
      return null
    }

    return (
      // eslint-disable-next-line
      <Container key="menu" ref={this.menu} onClick={this.handleClick}>
        <Backdrop />
        <ContentWrapper
          isRight={rightSide}
          id={id}
          ref={this.content}
          aria-label={label}
          tabIndex="-1"
        >
          <CloseButton
            type="button"
            aria-controls={id}
            aria-expanded={isOpen}
            aria-label="Close menu"
          >
            <CloseIcon />
          </CloseButton>
          {children}
        </ContentWrapper>
      </Container>
    )
  }

  render() {
    return this.renderContent()
  }
}
Content.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  rightSide: PropTypes.bool,
  children: PropTypes.node,
}

export default Content
