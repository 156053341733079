import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    padding: 30px 50px;
  }

  a {
    padding: 30px 50px;
  }
`
