import styled, { keyframes, css } from 'styled-components'
import mediaQueries from '../../../styles/mediaQueries'
import gigplanetTheme from '../../../styles/gigplanetTheme'

export const MenuItems = styled.ul``

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.1); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.1); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
`

export const SupportUnreadCount = styled.span`
  animation: ${pulseAnimation} 2s linear 0s infinite;
  background-color: red;
  border: 0;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  position: absolute;
  right: 6px;
  text-align: center;
  top: 16px;
  width: 18px;

  ${({ inDropdown }) =>
    inDropdown &&
    css`
      top: -6px;
      right: -16px;
    `};
`
export const FavoriteCount = styled.span`
  background-color: ${gigplanetTheme.color.primary};
  border: 0;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  height: 18px;
  position: absolute;
  right: 12px;
  text-align: center;
  top: 16px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQueries.mobile`
    font-size: 11px;
    line-height: 10px;
    height: 14px;
    width: 14px;
    right: 5px;
    top: 10px;
  `};
`

export const ButtonInnerContent = styled.span`
  position: relative;
`

export const ExtraSpacer = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  ${({ inDropdown }) =>
    inDropdown &&
    css`
      padding-left: 10px;
      padding-top: 10px;
      width: 100%;
    `};
`

export const DesktopMenu = styled.div`
  ${mediaQueries.mobile`
    display: none;
  `}
`

export const MobileMenu = styled.div`
  display: none;
  width: 100%;
  justify-content: flex-end;
  margin-left: 10px;
  margin-right: -10px /* to compensate for the 10px padding in header */;

  ${mediaQueries.mobile`
    display: flex;
  `};
`
