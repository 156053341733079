import styled from 'styled-components'
import { math } from 'polished'

import mediaQueries from '../../../../styles/mediaQueries'
import gigplanetTheme from '../../../../styles/gigplanetTheme'

export const Content = styled.div`
  overflow: auto;
  max-width: ${gigplanetTheme.measure.maxWidth};
  width: 100%;
  margin: 0 auto;

  margin-top: ${math(`${gigplanetTheme.spacing.large} - 2rem`)};
  margin-bottom: -2rem;

  ${mediaQueries.mobile`
    margin-top: ${gigplanetTheme.spacing.xsmall};
    margin-bottom: 0;
    height: 3.75rem;
  `};
`

export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;

  ${mediaQueries.mobile`
    height: 70px;
    justify-content: flex-start;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    transform: translate3d(0, 0, 0);
  `};

  ${mediaQueries.tabletPortrait`
    justify-content: flex-start;
  `};
`

export const Item = styled.li`
  flex: 1 0 20%;
  margin: 0;
  padding: 30px 10px;
  text-align: center;

  ${mediaQueries.tabletPortrait`
    flex: 1 0 33%;
  `};

  ${mediaQueries.tabletLandscape`
    flex: 1 0 25%;
  `};

  ${mediaQueries.mobile`
    padding: 0 10px;

    &:first-child: {
      padding-left: 0;
    }
    
    &:last-child: {
      padding-right: 0;
    }
  `};
`

export const Logo = styled.img`
  max-height: 50px;
  max-width: 150px;
`
