import styled, { css } from 'styled-components'

import mediaQueries from '../../styles/mediaQueries'
import gigplanetTheme from '../../styles/gigplanetTheme'

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  max-width: ${gigplanetTheme.measure.maxWidth};
  width: 100%;

  ${mediaQueries.mobile`
    justify-content: center;
  `};

  ${({ isSearch }) =>
    isSearch &&
    css`
      padding: 0;
      justify-content: flex-start;
    `};
`

export const Item = styled.li`
  padding: ${gigplanetTheme.spacing.small};
  flex: 1 0 auto;
  align-self: stretch;
  max-width: 430px;
  min-width: 300px;

  ${mediaQueries.mobile`
    max-width: 420px;
    min-width: 320px;
    padding: 0 10px 10px;
    width: 100%;
    flex: 1 0 100%;

    &:last-child {
      padding-bottom: 0;
    }
  `};

  ${mediaQueries.tabletPortrait`
    flex: 1 0 50%;
  `};

  ${mediaQueries.tabletLandscapeUp`
    flex: 1 0 30%;
  `};

  ${({ isSearch }) =>
    isSearch &&
    css`
      max-width: 420px;
      min-width: 300px;
    `};
`
