import * as React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { fetchJoinArtistsAction } from '../../../../stores/Artist/ArtistActions'

import { getJoinArtists } from '../../../../stores/Artist/ArtistReducer'

import { Container, ArtistListWrapper } from './style'
import LandingPageBlockWrapper from '../../../../ui/landing-page-block-wrapper'
import { H2 } from '../../../../ui/landing-page-headings'
import { ArtistShape } from '../../../../models/Artist'
import ArtistList from '../../../../containers/ArtistList'
import formatSimpleMarkdown from '../../../../helpers/format-simple-markdown'

const ArtistSelectedListBlock = React.memo(
  ({ theme, anchorId = '', heading = '', artistIds, getArtists }) => {
    const dispatch = useDispatch()
    const artists = useSelector(getJoinArtists)

    const fetchArtistIds = React.useMemo(() => {
      return (artistIds || []).map(id => id.artistId)
    }, [artistIds])

    React.useEffect(() => {
      dispatch(fetchJoinArtistsAction(fetchArtistIds))
    }, [])

    /**
     * We need to ensure the sort order from artistIds
     */
    const sortedArtists = React.useMemo(() => {
      if (!artists) return null
      const array = [...artists]
      array.sort((a, b) => {
        const A = a.id
        const B = b.id
        if (fetchArtistIds.indexOf(A) > fetchArtistIds.indexOf(B)) {
          return 1
        } else {
          return -1
        }
      })
      return array
    }, [artists])

    return (
      <LandingPageBlockWrapper theme={theme} noPadding anchorId={anchorId}>
        <Container>
          {heading ? (
            <H2 dangerouslySetInnerHTML={{ __html: formatSimpleMarkdown(heading) }} />
          ) : null}
          {sortedArtists && sortedArtists.length > 0 ? (
            <ArtistListWrapper>
              <ArtistList artists={sortedArtists} />
            </ArtistListWrapper>
          ) : (
            <div>Ingen artister</div>
          )}
        </Container>
      </LandingPageBlockWrapper>
    )
  },
)

ArtistSelectedListBlock.propTypes = {
  heading: PropTypes.string,
  theme: PropTypes.string,
  anchorId: PropTypes.string,
}

export default ArtistSelectedListBlock
