import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import mediaQueries from '../../../styles/mediaQueries'
import gigplanetTheme from '../../../styles/gigplanetTheme'

export const HeaderWrapper = styled.div`
  z-index: ${gigplanetTheme.zIndex.header};
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  position: relative;
  min-height: 70px;
  transition: background-color 0.15s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: ${gigplanetTheme.element.siteNavigation.background};
    `}

  ${mediaQueries.tabletPortraitDown`
    min-height: 50px;
    padding: 0 10px;
  `};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${gigplanetTheme.element.default.border};
  }

  ${({ inverted }) =>
    inverted &&
    css`
      position: absolute;
      width: 100%;
      &:after {
        display: none;
      }
    `}
`

export const StyledLogoLink = styled(Link)`
  z-index: 2;
  color: ${gigplanetTheme.color.primary};
  &:hover {
    color: #924dff;
  }
  ${({ inverted }) =>
    inverted &&
    css`
      color: #fff;
      &:hover {
        color: #ddd;
      }
    `}
`

export const DesktopImage = styled.div`
  height: 40px;
  display: block;

  ${mediaQueries.tabletPortraitDown`
    height: 30px;
  `};

  ${mediaQueries.mobile`
    display: none;
  `};

  svg {
    height: 40px;
    width: 130px;
    ${mediaQueries.tabletPortraitDown`
      height: 30px;
    `};

    ${mediaQueries.mobile`
      display: none;
    `};

    ${({ isRound }) =>
      isRound &&
      css`
        width: 40px;

        ${mediaQueries.tabletPortraitDown`
          width: 30px;
        `};
      `};
  }
`

export const MobileImage = styled.div`
  display: none;

  ${mediaQueries.mobile`
    display: block;
  `};

  svg {
    height: 30px;
    width: 110px;
  }
`
