import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  QuoteShape,
  QUOTE_STATUS,
  TIME_FORMAT,
  getIncludedOptionSentence,
} from '../../models/GigRequest'
import { printCurrency } from '../../helpers/Currency'

import {
  StyledGigRequestTimelineQuote,
  InvalidatedNote,
  ExpiresText,
  QuoteDetails,
  QuoteDetailsLabel,
  QuoteDetailsLine,
  QuoteTerms,
} from './style'

import getPlaytimeText from '../../helpers/get-playtime-text'
import uppercaseFristLetter from '../../helpers/uppercase-first-letter'

const GigRequestResponseOptionsQuoteDetails = ({ quote, gigRequest, eventTypes, isArtist }) => {
  // const includedText = getIncludedOptionSentence(included)

  const pauseText = quote.pauseCount === 1 ? '1 pause' : `${quote.pauseCount} pauser`
  const pauseDurationText = quote.pauseCount > 0 ? ` á ${quote.pauseDuration} minutter` : ''

  return (
    <StyledGigRequestTimelineQuote
      isPending={quote.status === QUOTE_STATUS.PENDING}
      isDeclined={quote.status === QUOTE_STATUS.DECLINED}
      isExpired={quote.status === QUOTE_STATUS.EXPIRED}
      isInvalidated={quote.status === QUOTE_STATUS.INVALIDATED}
      isAccepted={quote.status === QUOTE_STATUS.ACCEPTED}
      isWithdrawn={quote.status === QUOTE_STATUS.WITHDRAWN}
      isReplaced={quote.status === QUOTE_STATUS.REPLACED}
    >
      {quote.status === QUOTE_STATUS.INVALIDATED && !isArtist ? (
        <InvalidatedNote>
          Du har endret din forespørsel og mottatt tilbud utgår derfor. Artisten sender nytt tilbud.
        </InvalidatedNote>
      ) : null}
      {quote.expiresTime &&
      moment().isBefore(quote.expiresTime) &&
      quote.status === QUOTE_STATUS.PENDING ? (
        <ExpiresText>Må besvares innen {moment().to(moment(quote.expiresTime), true)}</ExpiresText>
      ) : null}
      <QuoteDetails>
        <QuoteDetailsLine>
          <QuoteDetailsLabel>Pris: </QuoteDetailsLabel>
          {printCurrency(quote.price)}
        </QuoteDetailsLine>
        <QuoteDetailsLine>
          <QuoteDetailsLabel>Antall medlemmer: </QuoteDetailsLabel>
          {quote.members}
        </QuoteDetailsLine>
        <QuoteDetailsLine>
          <QuoteDetailsLabel>Ca. konsert start: </QuoteDetailsLabel>
          {moment(gigRequest.startTime).format(TIME_FORMAT)}
        </QuoteDetailsLine>
        <QuoteDetailsLine>
          <QuoteDetailsLabel>Ca. konsert slutt: </QuoteDetailsLabel>
          {moment(gigRequest.endTime).format(TIME_FORMAT)}
        </QuoteDetailsLine>

        <QuoteDetailsLine>
          <QuoteDetailsLabel>Total spilletid: </QuoteDetailsLabel>
          {getPlaytimeText(gigRequest.startTime, gigRequest.endTime)}
          {quote.pauseCount ? ` inkl. ${pauseText} ${pauseDurationText}` : ''}
        </QuoteDetailsLine>
      </QuoteDetails>
      <QuoteDetails>
        <QuoteDetailsLine>
          <QuoteDetailsLabel>Tilbud inkluderer: </QuoteDetailsLabel>
          {uppercaseFristLetter(getIncludedOptionSentence(gigRequest.included))}
        </QuoteDetailsLine>
        <QuoteDetailsLine>
          <QuoteDetailsLabel>Tilleggsopplysninger: </QuoteDetailsLabel>
          {quote.terms ? <QuoteTerms>{quote.terms}</QuoteTerms> : 'Ingen tilleggsopplysninger'}
        </QuoteDetailsLine>
      </QuoteDetails>
    </StyledGigRequestTimelineQuote>
  )
}

GigRequestResponseOptionsQuoteDetails.propTypes = {
  quote: QuoteShape.isRequired,
  gigRequest: PropTypes.shape({}).isRequired,
  eventTypes: PropTypes.shape({}).isRequired,
  isArtist: PropTypes.bool,
}

GigRequestResponseOptionsQuoteDetails.defaultProps = {
  isArtist: false,
}

export default GigRequestResponseOptionsQuoteDetails
