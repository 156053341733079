import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Route, Switch, withRouter } from 'react-router'

import { CurrentUserShape } from '../../../models/CurrentUser'

import { getCurrentUser } from '../../../stores/CurrentUser/CurrentUserReducer'
import {
  getMyEventplannerRequests,
  isMyEventplannerRequestsLoading,
  hasMyEventplannerRequestsLoaded,
} from '../../../stores/gig-request-eventplanner/reducer'
import {
  getMyEventplannerRequestsAction,
  getMyEventplannerRequestsCountAction,
} from '../../../stores/gig-request-eventplanner/actions'
import { getEventTypes } from '../../../stores/Options/OptionsReducer'

import MetaData from '../../../components/MetaData'
import EventplannerInbox from '../../../components/EventplannerInbox'

import RequestRoute from './request'
import RequestEditRoute from './edit'
import PageNotFoundRoute from '../../../components/PageNotFound'

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  eventplannerRequests: getMyEventplannerRequests(state),
  isLoading: isMyEventplannerRequestsLoading(state),
  hasLoaded: hasMyEventplannerRequestsLoaded(state),
  eventTypes: getEventTypes(state),
})

const mapDispatchToProps = dispatch => ({
  getMyRequests: () => {
    dispatch(getMyEventplannerRequestsCountAction())
    dispatch(getMyEventplannerRequestsAction())
  },
})

class EventplannerInboxRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { getMyRequests } = this.props
    getMyRequests()
  }

  render() {
    const { eventplannerRequests, isLoading, hasLoaded, eventTypes, currentUser } = this.props
    return (
      <>
        <Switch>
          <Route
            path="/eventplanner/inbox/edit/:gigRequestId/:artistId"
            component={RequestEditRoute}
          />
          <Route path="/eventplanner/inbox/edit/:gigRequestId" component={RequestEditRoute} />
          <Route path="/eventplanner/inbox/:gigRequestId" component={RequestRoute} />
          <Route
            path="/eventplanner/inbox"
            render={() => (
              <>
                <MetaData
                  title={`Innboks til ${currentUser.name}`}
                  description="Innboks med forespørsler"
                  nopin
                />
                <EventplannerInbox
                  requests={eventplannerRequests}
                  isLoading={isLoading}
                  hasLoaded={hasLoaded}
                  eventTypes={eventTypes}
                />
              </>
            )}
          />
          <Route component={PageNotFoundRoute} />
        </Switch>
      </>
    )
  }
}

EventplannerInboxRoute.propTypes = {
  currentUser: CurrentUserShape.isRequired,
  eventplannerRequests: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  eventTypes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  getMyRequests: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EventplannerInboxRoute)
